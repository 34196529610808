import { Card, Col, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from 'react';
import ScrollToTop from "../../../../../../components/ScrollToTop";
import SigningComponent from '../../../../../../components/SigningComponent';
import RequestAttestationTemplate from "./RequestAttestationTemplate";

export default function RequestAttestationReview(props) {
    
  const {
    formField: {
    },
    user,
    forwardedRef,
    uniqueRef,
    product, 
    manufacturer,
    platform, 
    organisation,
    site, 
    productDetail,
    attestationData,
    generatedSADocument,
    certificateNumber
  } = props;

  const [isPdfGenerating, setPdfGenerating] = useState(false);
  //Check is PDF is being generated in backround, ready for signing
  useEffect(()=>{ 
    generatedSADocument==""? setPdfGenerating(true) : setPdfGenerating(false) 
  },[generatedSADocument]);

    return (
        <Row>
            <Col xl={11}>
                <ScrollToTop/>
                <RequestAttestationTemplate 
                    user={user}
                    values={user}
                    forwardedRef={forwardedRef}
                    uniqueRef={uniqueRef}
                    product={product}
                    manufacturer={manufacturer}
                    platform={platform}
                    organisation={organisation}
                    site={site}
                    productDetail={productDetail}
                    attestationData={attestationData}
                    certificateNumber={certificateNumber}
                />

                <Card border="light" className="bg-white shadow-sm mb-4 p-3">
                    <Card.Body>
                        <h4>Review Manufacturer Declaration Document</h4>
                        <p>
                            Now you have completed the Support Attestation Request, click the button below to review and sign the document.<br />
                            Please Note: Clicking the button below will redirect you to our secure document signing provider.
                        </p>
                        <SigningComponent
                            document={generatedSADocument}
                            ready={isPdfGenerating}
                            buttonText = "Review & Sign Supplier Declaration"
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
  );
}
