export default {
  formId: 'attestationRequestForm',
  formField: {
    traceability: {
      name: 'traceability',
      label: 'Procedure for Traceability',
      sublabel: 'The batch information on the support attestation should match the information available when inspecting the physical product (e.g. on the product label)',
      requiredErrorMsg: 'Traceability is required'
    },
    finalConsumer: {
      name: 'finalConsumer',
      label: 'Final consumer',
      requiredErrorMsg: 'Final consumer is required'
    },
    customUniqueRef: {
      name: 'customUniqueRef',
      label: 'Our reference number',
      requiredErrorMsg: 'Reference number is required'
    },
    vetId: {
      name: 'vetId',
      label: 'Select a Vet to review the Attestation Request',
      requiredErrorMsg: 'vet is required'
    },
    productionDate: {
      name: 'productionDate',
      label: 'were produced',
      requiredErrorMsg: 'Select a date for production'
    },
    productionDateSingle: {
      name: 'productionDateSingle',
      label: 'Production date',
      requiredErrorMsg: 'Select a date for production date'
    },
    productionDateMultipleStart: {
      name: 'productionDateMultipleStart',
      label: 'Production date (start)',
      requiredErrorMsg: 'Select a date for production start'
    },
    productionDateMultipleEnd: {
      name: 'productionDateMultipleEnd',
      label: 'Production date (end)',
      requiredErrorMsg: 'Select a date for production end'
    },
    batchNumberOptions: {
      name: 'batchNumberOptions',
      label: 'Batch number options',
      requiredErrorMsg: 'Batch numbers options are required'
    },
    batchNumbers: {
      name: 'batchNumbers',
      label: 'Selected batch numbers',
      requiredErrorMsg: 'Batch numbers are required'
    },
    batchFormat: {
      name: 'batchFormat',
      label: 'Batch format',
      requiredErrorMsg: 'Batch format is required'
    },
    batchRangeTo: {
      name: 'batchRangeTo',
      label: 'Batch number to',
      requiredErrorMsg: 'Batch number to is required'
    },
    batchRangeFrom: {
      name: 'batchRangeFrom',
      label: 'Batch number from',
      requiredErrorMsg: 'Batch number from is required'
    },
    test: {
      name: 'test',
      label: 'test',
      requiredErrorMsg: 'test is required'
    },
    decision: {
      name: 'decision',
      label: 'Accept or Reject this Support Attestation Request?',
      requiredErrorMsg: 'decision is required'
    },
    declaration: {
      name: 'declaration',
      label: '',
      requiredErrorMsg: 'declaration is required'
    },
    rejectReason: {
      name: 'rejectReason',
      label: 'Reason for rejection',
      requiredErrorMsg: 'reject reason required'
    },
    signature: {
      name: 'signature',
      label: 'signature',
      requiredErrorMsg: 'signature required'
    },
    isGEFS: {
      name: 'isGEFS',
      label: 'isGEFS',
      requiredErrorMsg: 'isGEFS required'
    },
    containsDairy: {
      name: 'containsDairy',
      label: 'containsDairy',
      requiredErrorMsg: 'isGEFS required'
    },
    signedRequestUrl: {
      name: 'signedRequestUrl',
      label: 'Signed Request Url',
      requiredErrorMsg: ''
    },
    documentId: {
      name: 'documentId',
      label: 'Document ID',
      requiredErrorMsg: ''
    },
  }
};
  
