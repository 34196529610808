import { faCheckCircle, faEdit, faReply, faShare, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { getTimeSpanText } from '../pages/components/common/DateFormatting';
import ActivityService from '../pages/services/ActivityService';
import { NoResults } from './Tables/NoResults';

export default (props) => {
  const [activity, setActivity] = useState([]);

  const styles  ={ 
    create:{
      icon: faStar,
      variant: "black",
      color: "black"
    },
    update:{
      icon: faEdit,
      variant: "black",
      color: "black"
    },
    request:{
      icon: faShare,
      variant: "black",
      color: "black"
    },
    respond:{
      icon: faReply,
      variant: "black",
      color: "black"
    },
    default:{
      icon: faEdit,
      variant: "black",
      color: "black"
    },
  };


  const Activity = (props) => {
    const { title, description, action, url, dateStamp} = props;
    
    var selectedStyle = 
    action=="Create" ?  selectedStyle = styles.create 
    : action=="Update" ?  selectedStyle = styles.update
    : action=="Request" ?  selectedStyle = styles.request
    : action=="Response" ?  selectedStyle = styles.respond
    : styles.default;


    return (

      <div class="list-group-item border-0 px-0">
      <div class="row ps-lg-1">
        <div class="col-auto px-0">
          <div class="icon-shape icon-xs icon-shape-purple rounded">
            <FontAwesomeIcon icon={selectedStyle.icon} className="" />
          </div>
        </div>
        <div class="col ms-n2 mb-1">
          <h3 class="fs-7 fw-bold mb-1 mt-2">
            {title}
          </h3>
          <p class="mb-1 fs-7">
            {description}
          </p>
          <div class="d-flex align-items-center">
            <span class="small">{getTimeSpanText(dateStamp)}
            </span>
          </div>
        </div>
      </div>
    </div>
    );
  };

useEffect(() => {
  ActivityService.getLatest()
    .then(x => {
      setActivity(x.data)
    })
    .catch(error => {
    });
  }, []);

  return (
    <Card border="light" className="shadow-sm notification-card border-0 shadow">
      <Card.Body>
        <h5 className="mb-2 text-title fw-bold">My Recent Activity</h5>
          <div class="list-group list-group-flush list-group-timeline">
            {activity?.length == 0 ?
            (
              <NoResults name="recent activity"/>
            )
            :
            activity?.map((item, index) => (  
              <Activity 
                {...item}
              />
            ))} 
        </div>
    </Card.Body>
    </Card>
  );
};