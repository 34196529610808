import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from "@themesberg/react-bootstrap";
import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import FormController from '../../../../common/FormController';

const DailyAttestationPoultryFields = (props) => {
  const { values: formValues, setFieldValue } = useFormikContext();
  const {
    index: productIndex,
    farmPost,
    slaughterDate,
    slaughterDates,
    poultryCuttingPlantApprovalNumbers,
    abattoirApprovalNumbers,
    poultryMeatArrivalDate,
    birdFluCheck,
    attestationData
  } = props;
  const [hasBirdFluChecks, setBirdFluChecks] = useState(false);

  //Function > Bird Flu Check - Check forms for POU AND GB/XI
  useEffect(() => {
    if(attestationData!==null && attestationData !== undefined){
      
     var groupToCheck = "POU"

      //Composite 8350 Check
      var composite8350Species = attestationData[8350]?.meatData?.speciesList;
      var poultryInComposite8350 = IsInSpeciesGroup(composite8350Species, groupToCheck);
      if(poultryInComposite8350){
      var composite8350SpeciesCountries = GetCountriesFromSpeciesList(composite8350Species, groupToCheck);
      CheckBirdFluCountry(composite8350SpeciesCountries, ["GB", "GB-0","GB-1","GB-2","XI"]);
      }
      console.info("poultryInComposite8350: ", poultryInComposite8350);
      console.info("composite8350Species: ", composite8350Species);

      //Meat 8384 Check
      var meat8384Species = attestationData[8384]?.speciesList;
      var poultryInMeat8384 = IsInSpeciesGroup(meat8384Species, groupToCheck);
      if(poultryInMeat8384){
      var meat8384CountryOfOrigin = attestationData[8384]?.animal_2_1_zone;
      CheckBirdFluCountry(meat8384CountryOfOrigin,["GB-1","XI"] )
      }
      console.info("poultryInMeat8384: ", poultryInMeat8384);
      console.info("meat8384Species: ", meat8384Species);


      //Meat Prep 8383 Check
      var meat8383Species = attestationData[8383]?.speciesList;
      var poultryInMeat8383 = IsInSpeciesGroup(meat8383Species, groupToCheck);
      if(poultryInMeat8383){
      var meat8383CountryOfOrigin = attestationData[8383]?.animal_2_1_zone;
      CheckBirdFluCountry(meat8383CountryOfOrigin, ["GB", "GB-0","GB-1","GB-2","XI"] )
      }
      console.info("poultryInMeat8383: ", poultryInMeat8383);
      console.info("meat8383Species: ", meat8383Species);
    }
  },[attestationData]);

  //Function - Set Bird Flu Check true if restricted countries are found
  const CheckBirdFluCountry = (countries, regions) => {
    if(countries?.length > 0){
      var isRestrictedCountry = hasRestrictedCountry(countries, regions);
      if(isRestrictedCountry){
        setBirdFluChecks(true);
        console.info("setBirdFluChecks: ", true);
      }
    return;
    }
  }

  //Function - Return countries list from the SpeciesList object
  const GetCountriesFromSpeciesList = (speciesList, groupName) => {
    let countries = [];
    if(speciesList?.length > 0){
      var findGroup = speciesList.find(x=> x.speciesGroupName == groupName);
      if(findGroup !== null && findGroup !== undefined)
      {
        findGroup.speciesData.forEach(item => {
          countries.push(item.countryOfOrigin);
        });
        return countries[0];
      }
    }
    return null;
  }

  //Function - Returns true if SpeciesList contains an inputted species group name
  const IsInSpeciesGroup = (speciesList, groupName) => {
    if(speciesList?.length > 0){
      var findGroup = speciesList.find(x=> x.speciesGroupName == groupName);
      if(findGroup !== null && findGroup!= undefined){
        return true;
      };
    }
    return false;
  }

  //Function - Returns true if countries contains restricted regions
  const hasRestrictedCountry = (countries, region) => {
    if(countries?.length > 0){
      var found = countries.find(x => region.includes(x.isoCode));
      if(found!= undefined && found != null){
        return true;
      }
    }
    return false;
  }

  //Set Form Variables for Bird Flu
  useEffect(() => {
    if(hasBirdFluChecks){
      setFieldValue(`dailyAttestationData.${productIndex}.${birdFluCheck.name}`, true);
      if(formValues.dailyAttestationData[productIndex]?.slaughterDates==['']){
        setFieldValue(`dailyAttestationData.${productIndex}.${slaughterDates.name}`, null);
      }
      console.info("setFieldValue Bird flu" + true);
    }
  }
  ,[hasBirdFluChecks]);

  return (
    hasBirdFluChecks &&
    (
     <>
      <h5 className="mt-5">This product contains poultry, please complete these additional fields:</h5>
        <Col md={12} lg={12}>
          <FieldArray
              name={`dailyAttestationData.${productIndex}.${slaughterDates.name}`}
              render={({ insert, remove, push }) => (
                <Row key={12 + productIndex}>
                  <Col md={6} lg={4}>
                      {formValues.dailyAttestationData[productIndex].slaughterDates.length > 0 ?(
                          formValues.dailyAttestationData[productIndex].slaughterDates?.map((x, dateIndex) => (
                              <Row key={1 + dateIndex}>
                                  <Col md={8} lg={10}>
                                    <FormController
                                        label={formValues.dailyAttestationData[productIndex].slaughterDates.length ===1 ? 
                                          `${slaughterDate.label}`
                                          : `${slaughterDate.label}` + " " + (1 + dateIndex)
                                        }
                                        col={12}
                                        isrequired="true"
                                        control="dateInput"
                                        name={`dailyAttestationData.${productIndex}.${slaughterDates.name}.${dateIndex}`}
                                    />
                                  </Col>
                                  <Col md={4} lg={2} className="mt-5">
                                      {(dateIndex > 0 &&
                                        <Button 
                                          variant="link" 
                                          size="md"
                                          onClick={() => remove(dateIndex)}
                                        >   
                                          <FontAwesomeIcon icon={faMinusSquare} className="me-2 text-danger" />
                                        </Button>
                                      )}
                                  </Col>
                              </Row>
                          )
                        )
                      )
                      :
                      (
                        insert('')
                      )}
                  </Col>
                  <Col md={6} lg={4} className="mt-5">
                    <Button variant="light"  onClick={() => push("")}>
                        Add more dates
                    </Button>
                  </Col>
                </Row>
              )}
          />

        </Col>


        <Col md={12}>
          <FormController
              control="input"
              type="text"
              label={farmPost.label}
              name={`dailyAttestationData.${productIndex}.${farmPost.name}`}
              col={12}
              isrequired="true"
          />
        </Col>

        <Col md={6}>
          <FormController
              control="input"
              type="text"
              label={poultryCuttingPlantApprovalNumbers.label}
              name={`dailyAttestationData.${productIndex}.${poultryCuttingPlantApprovalNumbers.name}`}
              col={12}
              isrequired="false"
          />
        </Col>
        <Col md={6}>
          <FormController
              control="input"
              type="text"
              label={abattoirApprovalNumbers.label}
              name={`dailyAttestationData.${productIndex}.${abattoirApprovalNumbers.name}`}
              col={12}
              isrequired="true"
          />
        </Col>
        <Col md={6}>
          <FormController
              control="dateInput"
              type="text"
              label={poultryMeatArrivalDate.label}
              name={`dailyAttestationData.${productIndex}.${poultryMeatArrivalDate.name}`}
              col={12}
              isrequired="false"
          />
        </Col>
      </>
    )
  )
};

export default DailyAttestationPoultryFields;
