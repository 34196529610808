import { Button, Tab, Tabs } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from 'react';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Routes } from "../../../../routes";
import CertificateAssignedService from '../../../services/CertificateAssignedService';
import EhcGenerateService from '../../../services/EhcGenerateService';
import FormSubmission from "../../common/FormSubmission";
import SingleCertificateForm from './Forms/SingleCertificateForm';
import CompoundCertificateForm from './Forms/CompoundCertificateForm';
import { updateData } from "./Functions/EditorFunctions";
import EhcDataService from "../../../services/EhcDataService";


const CertificateEditor = (props) => {
    const [title] = useState("Export Health Certificate Editor");
    const [consignmentId, setConsignmentId] = useState('');
    const [languageCode, setLanguageCode] = useState('');
    const [consignmentContentsId] = useState(props.match.params.consignmentContentsId);
    const [data, setData] = useState('');
    const [updating, setUpdating] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isCompound, setIsCompound] = useState(false);
    const [ready, setReady] = useState(false);
    const [certificateId, setCertificateId] = useState('');
    const [valuesReady, setValuesReady] = useState(false);
    const [errors, setErrors] = useState(null);
    const [config, setConfig] = useState([]);
    const [formData, setFormData] = useState([]);
    const [updatedPdf, setUpdatedPdf] = useState('');
    const [initialSavedData] = useState([]);

    const breadcrumbData = {
        item1 : {name:'Certificates', link: Routes.CertificateListingPage.path},
        item2 : {name: "Export Health Certificate Editor", link: '#'}
    };

    function retrieveFormInitialData() {
    let assignedCerts = [];
    let counter = 0;

    const getCertificateAssigned = new Promise((resolve, reject) => {
        resolve(CertificateAssignedService.getByConsignmentContentsId(consignmentContentsId, certificateId)
            .then((response) => {
                assignedCerts = response.data;
                let formApiData = [];
                setConsignmentId(assignedCerts[0]?.consignmentContents?.consignmentId)
                setLanguageCode(assignedCerts[0]?.certificate?.certificateLanguage.code)

                //Loop through each assigned certificate
                assignedCerts.map((x,i)=>{
                    let certificateId = x.certificateId;
                    let certificateNumber =  x.certificate.certificateNumber;
                    let filename =  x.document.filename;

                    //Form data object to populate
                    let obj = {
                        certificateId: certificateId,
                        fields: {},
                        lineStamps: {},
                        textStamps: {},
                        certificateGroups: [],
                        languageChangePageNumber: 0,
                        filename: filename,
                        config: []
                    }

                    const getfields = new Promise((resolve, reject) => {
                        resolve(EhcDataService.getLatestCertificateData(certificateId, certificateNumber)
                            .then((response) => {
                                var savedCertificateData = response.data;
                                console.info(certificateNumber + "data");
                                console.info(savedCertificateData);
                                //Fields
                                obj.certificateGroups = savedCertificateData.certificateGroups;
                                obj.languageChangePageNumber = savedCertificateData.languageConfig.languageChangePageNumber;
                                obj.config = savedCertificateData;

                                var formFields = {}
                                response.data.fields.forEach((x,i)=>{  
                                    //Set final consumer check values
                                    if(x.fieldName.startsWith("Final consumer YES")){
                                        var number = x.fieldName.slice(-1);
                                        var formFieldName = "Finalconsumer" + number;
                                        if(x.value?.toLowerCase()=="true"){
                                            formFields[formFieldName] = true;
                                        }
                                        else{
                                            formFields[formFieldName] = false;
                                        }
                                    }
    
                                    //Regular - set booleans if found as strings
                                    else{
                                        if(x.value === "True")
                                        {              
                                            x.value = true;
                                        }
                                        if(x.value === "False")
                                        {
                                            x.value = false;
                                        }
                                    }
                                    formFields[x.fieldName?.replaceAll(' ', '')] = x.value;
                                })
            
                                obj.fields= formFields;

                                //Text stamps
                                    
                                var textStamps = {};
                                response.data.textStamps.forEach((x,i)=>{
                                    textStamps[x.fieldName?.replaceAll(' ', '')] = x.value;
                                    textStamps[x.fieldName?.replaceAll(' ', '') + "Top"] = x.top;
                                    textStamps[x.fieldName?.replaceAll(' ', '') + "Width"] = x.width;
                                    textStamps[x.fieldName?.replaceAll(' ', '') + "Height"] = x.height;
                                    textStamps[x.fieldName?.replaceAll(' ', '') + "Left"] = x.left;
                                    textStamps[x.fieldName?.replaceAll(' ', '') + "MarginTop"] = x.marginTop;
    
                                    if(x.stampStyle?.toString().toLowerCase()==="overflow"){
                                        var linkedfield =  response.data.textStamps.find(y=>y.fieldName==x.linkedFieldName);
                                        if(linkedfield?.value !==null && linkedfield?.value !== undefined){
                                            textStamps[linkedfield.fieldName?.replaceAll(' ', '')] = linkedfield.value + ", " + x.value;
                                        }
                                    }
                                });
    
                                obj.textStamps = textStamps;
            
                                //Line stamps
                                var lineStamps = {};
                                response.data.lines.forEach((x,i)=>{
                                    //Reverse checkbox values from API for better UI
                                    if(x.value?.toString().toLowerCase()==="true"){
                                        lineStamps[x.fieldName?.replaceAll(' ', '')] = true;
                                    }
                                    else{
                                        lineStamps[x.fieldName?.replaceAll(' ', '')] = false;
                                    } 
                                    lineStamps[x.fieldName?.replaceAll(' ', '') + "DisableChildGroupsIfFalse"] = x.disableChildGroupsIfFalse;
                                    lineStamps[x.fieldName?.replaceAll(' ', '') + "LinkedFieldName"] = x.linkedFieldName;
                                    lineStamps[x.fieldName?.replaceAll(' ', '') + "Top"] = x.top;
                                    lineStamps[x.fieldName?.replaceAll(' ', '') + "Width"] = x.width;
                                    lineStamps[x.fieldName?.replaceAll(' ', '') + "Height"] = x.height;
                                    lineStamps[x.fieldName?.replaceAll(' ', '') + "Left"] = x.left;
                                    lineStamps[x.fieldName?.replaceAll(' ', '') + "MarginTop"] = x.marginTop;
                                });
                                obj.lineStamps= lineStamps;
                                counter ++;
            
                            })
                            .catch((e) => {
                                console.log(e);
                                counter++;
                            })
                        );
                    });


                    Promise.all([getfields]).then((responses) => {
                        formApiData.push(obj);

                        if(assignedCerts.length === counter){
                            setFormData(formApiData);
                            setValuesReady(true);
                            //Set data from all assigned certificates
                            setReady(true);
                        }
                    });
                })



            })
            .catch((e) => {
                console.log(e);
            })
        );
    });
    
    Promise.all([ getCertificateAssigned]).then((responses) => {
        setConfig(assignedCerts)
        setData(assignedCerts)
        });
    }

    //Run when form data has been submitted
    const onUpdateCompoundOnly = async (values) => {
        setUpdating(true);
        setTimeout(async() => {
            var generateCompound = await generateCompoundCertificate(consignmentContentsId,languageCode, values.totalPages,  data.length)
            var generateBytes = generateCompound?.response?.data;
           // if(generateBytes !== null && generateBytes !== undefined){
                setUpdating(false);
                retrieveFormInitialData();
           // } 
        }, 1000);
    }

    //Run when form data has been submitted
    const onUpdated = async (values, config, certificateId) => {

        setUpdating(true);
        setTimeout(async() => {
            let certificateGroups = values.certificateGroups
            //Get config form and values prepared for submission
            var res = updateData(values, config, certificateGroups, languageCode);
            var languageConfig = res.languageConfig;
            //var languageCode = languageConfig.code;
            //Generate certificate on the certificate app
            await EhcGenerateService.updateCertficate(consignmentContentsId,languageCode, certificateId, res)
            .then(async response => {
                var totalPages = values.totalPages;
                //Update Pdf Preview
                var bytes = response.data;
                setUpdatedPdf(`data:application/pdf;base64,${bytes.value}`);
                // setDocumentPath(`data:application/pdf;base64,${bytes?.value}`);
                if(isCompound){
                    var generateCompound = await generateCompoundCertificate(consignmentContentsId,languageCode, totalPages, data.length)
                    var generateBytes = generateCompound?.response?.data;
                }
                else{
                    retrieveFormInitialData();
                    setUpdating(false);
                }
            })
            .catch(e => {
                setErrors(e.response.data);
                console.log(e);
                setUpdating(false);
            });
        }, 1000);
    }

    const generateCompoundCertificate = async (consignmentContentsId,languageCode, totalPages) => {
        //Generate Compound certificate on the certificate app
        await EhcGenerateService.generateCompoundCertificate(consignmentContentsId,languageCode,totalPages, data.length)
        .then(response => {
            //Update Pdf Preview
            var bytes = response.data;
            setUpdatedPdf(`data:application/pdf;base64,${bytes.value}`);
            setUpdating(false);;
        })
        .catch(e => {
            alert("catch");
            setErrors(e.response.data);
            console.log(e);
            setUpdating(false);
        });
    }

    useEffect(() => {
        retrieveFormInitialData();
    },[]);


    useEffect(() => {
        if(data.length> 1){
            setIsCompound(true);
        };
    },[data]);
  

    return (
        <div className="editor" style={{ overflowX: 'auto' }}>
            <BreadcrumbNav data={breadcrumbData}/>

            <Button  
                variant="light"
                className='float-end'
                disabled={updating}
                onClick={() => {window.location = Routes.ConsignmentContentsPage.path.replace(":id", consignmentId)}}
            >
                {"Return to consignment"}
            </Button>
            
            <h2 className="mb-3">{title}</h2>

            {formSubmitted ?
                <FormSubmission
                    errors={errors}
                    title ="Certificate Editor"
                    message = "This certificates has been successfully added to the site." 
                    successButtonUrl = {Routes.ConsignmentContentsPage.path.replace(":id", consignmentId)}
                    successButtonName = "Back to Certificates"
                />
            : 
                valuesReady &&
                <>
                    {isCompound? 

                        //Compound Certificate
                        <Tabs size="sm"
                            defaultActiveKey="certCompound"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey={"certCompound"} title={"Compound - Full"}>
                                 <CompoundCertificateForm 
                                    isCompound={true}
                                    disableTools={true}
                                    certificateGroups={null}
                                    updating={updating}
                                    formData={formData}
                                    data={data}
                                    setData={setData}
                                    onUpdated={onUpdateCompoundOnly}
                                    updatedPdf={updatedPdf}
                                    values={null}
                                    initialSavedData={initialSavedData}
                                    consignmentContentsId={consignmentContentsId}
                                    certificateId={formData[0].certificateId}
                                /> 
                            </Tab>
                            {data.map(x=>
                                <Tab eventKey={"cert" + x.certificateId} title={ x.certificate.certificateNumber}>
                                    <SingleCertificateForm 
                                    isCompound={true}
                                        consignmentContentsId={consignmentContentsId}
                                        certificateNumber={x.certificateNumber}
                                        certificateId={x.certificateId}
                                        data={x}
                                        formData={formData.filter(y=> y.certificateId == x.certificateId)[0]? formData.filter(y=> y.certificateId == x.certificateId)[0] : null}
                                        languageChangePageNumber={formData.filter(y=> y.certificateId == x.certificateId)[0]?.languageChangePageNumber}
                                        certificateGroups={formData.filter(y=> y.certificateId == x.certificateId)[0]?.certificateGroups}
                                        updatedPdf={updatedPdf}
                                        ready={valuesReady}
                                        updating={updating}
                                        setData={setData}
                                        setUpdating={setUpdating}
                                        onUpdated={onUpdated}
                                    />
                                </Tab>
                            )}
                        </Tabs>

                    
                    //Single Certificate / Combined
                    :
                        <SingleCertificateForm 
                            isCompound={false}
                            consignmentContentsId={consignmentContentsId}
                            certificateNumber={formData[0].certificateNumber}
                            certificateId={formData[0].certificateId}
                            data={data}
                            ready={valuesReady}
                            formData={formData[0]}
                            languageChangePageNumber={formData[0].languageChangePageNumber}
                            certificateGroups={formData[0].certificateGroups}
                            updatedPdf={updatedPdf}
                            updating={updating}
                            setUpdatedPdf={setUpdatedPdf}
                            setData={setData}
                            setUpdating={setUpdating}
                            onUpdated={onUpdated}
                        />
                    }
                </>                                   
            }
        </div>
    );
};

export default CertificateEditor;

