import React from 'react';
import { Col, Row } from "@themesberg/react-bootstrap";
import * as Dairy8468AttestationLabels from "../../Products/Dairy/DairyAttestation/Dairy8468AttestationLabels";
import * as Fishery8361AttestationLabels from "../../Products/Fishery/FisheryAttestation/Fishery8361AttestationLabels";
import * as Meat8384AttestationLabels from "../../Products/Meat/MeatAttestation/Meat8384AttestationLabels";
import * as Composite8350AttestationLabels from "../../Products/Composite/CompositeAttestation/Composite8350AttestationLabels"
import * as MeatPreparation8383AttestationLabels from "../../Products/MeatPreparation/MeatPreparationAttestation/MeatPreparation8383AttestationLabels"
import * as HighlyRefined8399AttestationLabels from "../../Products/HighlyRefined/HighlyRefinedAttestation/HighlyRefined8399AttestationLabels"
import * as Gelatine8390AttestationLabels from "../../Products/Gelatine/GelatineAttestation/Gelatine8390AttestationLabels"
import * as Collagen8396AttestationLabels from "../../Products/Collagen/CollagenAttestation/Collagen8396AttestationLabels"
import * as Honey8391AttestationLabels from "../../Products/Honey/HoneyAttestation/Honey8391AttestationLabels"
import * as VitaminD8392AttestationLabels from "../../Products/VitaminD/VitaminDAttestation/VitaminD8392AttestationLabels"
import * as WildGame8367AttestationLabels from "../../Products/WildGame/WildGameAttestation/WildGame8367AttestationLabels"
import * as DogChews8325AttestationLabels from "../../Products/DogChews/DogChewsAttestation/DogChews8325AttestationLabels"
import * as Casing8393AttestationLabels from "../../Products/Casing/CasingAttestation/Casing8393AttestationLabels"
import * as Porcine8370AttestationLabels from "../../Products/Porcine/PorcineAttestation/Porcine8370AttestationLabels"
import * as Ovine8369AttestationLabels from "../../Products/Ovine/OvineAttestation/Ovine8369AttestationLabels"
import * as Bovine8368AttestationLabels from "../../Products/Bovine/BovineAttestation/Bovine8368AttestationLabels"
import * as Poultry8371AttestationLabels from "../../Products/Poultry/PoultryAttestation/Poultry8371AttestationLabels"
import * as Egg8360AttestationLabels from "../../Products/Egg/EggAttestation/Egg8360AttestationLabels"

export default function AttestationCertificateForms(props) {

    const {
        certificateNumber,
        attestationData,
        setFieldValue,
        productionDateChoice,
        productionDate,
        productionDateMultipleEnd,
        productionDateSingle,
        productionDateMultipleStart,
        isReadOnly
    } = props;

    function objectIsEmpty(obj) {
        for (let key in obj) {
            return false;
        }
        return true;
    }

    return (
        <>
            {/* //Form 8350 - Composite  */}
            {certificateNumber?.includes("8350") && !objectIsEmpty(attestationData[8350]) && (
                <Row className="mt-3">
                    {certificateNumber?.length > 1 &&
                        <h5>Composite 8350 Information</h5>
                    }
                    <Col md={12}>
                        <Composite8350AttestationLabels.HealthInformation
                            attestationData={attestationData[8350]}
                            isReadOnly={isReadOnly}
                        />
                        <Composite8350AttestationLabels.CompositeGroupsAttestationContent
                            attestationData={attestationData[8350]}
                            setFieldValue={setFieldValue}
                            productionDateChoice={productionDateChoice}
                            productionDate={productionDate}
                            productionDateMultipleEnd={productionDateMultipleEnd}
                            productionDateSingle={productionDateSingle}
                            productionDateMultipleStart={productionDateMultipleStart}
                            isReadOnly={isReadOnly}
                        />
                    </Col>
                </Row>

            )}


            {/* //Form 8354 - Dairy  */}
            {certificateNumber?.includes("8468") && !objectIsEmpty(attestationData[8468]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Dairy 8468 Details</h5>
                    }
                    <Dairy8468AttestationLabels.AttestationContent
                        attestationData={attestationData[8468]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}

            {/* //Form 8361 - Fishery  */}
            {certificateNumber?.includes("8361") && !objectIsEmpty(attestationData[8361]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Fishery 8361 Details</h5>
                    }
                    <Fishery8361AttestationLabels.AttestationContent
                        attestationData={attestationData[8361]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}

            {/* //Form 8384 - Meat  */}
            {certificateNumber?.includes("8384") && !objectIsEmpty(attestationData[8384]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Meat 8384 Details</h5>
                    }
                    <Meat8384AttestationLabels.AttestationContent
                        attestationData={attestationData[8384]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}


            {/* //Form 8383 - Meat Prep */}
            {certificateNumber?.includes("8383") && !objectIsEmpty(attestationData[8383]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Meat Preperation 8383 Details</h5>
                    }
                    <MeatPreparation8383AttestationLabels.AttestationContent
                        attestationData={attestationData[8383]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}

            {/* //Form 8399 - Highly Refined */}
            {certificateNumber?.includes("8399") && !objectIsEmpty(attestationData[8399]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Highly Refined 8399 Details</h5>
                    }
                    <HighlyRefined8399AttestationLabels.AttestationContent
                        attestationData={attestationData[8399]}
                    />
                </>
            )}

            {/* //Form 8390 - Gelatine */}
            {certificateNumber?.includes("8390") && !objectIsEmpty(attestationData[8390]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Gelatine 8390 Details</h5>
                    }
                    <Gelatine8390AttestationLabels.AttestationContent
                        attestationData={attestationData[8390]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}
            {/* //Form 8367 - Wild Game */}
            {certificateNumber?.includes("8367") && !objectIsEmpty(attestationData[8367]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Wild Game 8367 Details</h5>
                    }
                    <WildGame8367AttestationLabels.AttestationContent
                        attestationData={attestationData[8367]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}
            {/* //Form 8370 - Porcine */}
            {certificateNumber?.includes("8370") && !objectIsEmpty(attestationData[8370]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Porcine 8370 Details</h5>
                    }
                    <Porcine8370AttestationLabels.AttestationContent
                        attestationData={attestationData[8370]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}
            {/* //Form 8369 - Ovine */}
            {certificateNumber?.includes("8369") && !objectIsEmpty(attestationData[8369]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Ovine 8369 Details</h5>
                    }
                    <Ovine8369AttestationLabels.AttestationContent
                        attestationData={attestationData[8369]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}
            {/* //Form 8368 - Bovine */}
            {certificateNumber?.includes("8368") && !objectIsEmpty(attestationData[8368]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Bovine 8368 Details</h5>
                    }
                    <Bovine8368AttestationLabels.AttestationContent
                        attestationData={attestationData[8368]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}
            {/* //Form 8371 - Poultry */}
            {certificateNumber?.includes("8371") && !objectIsEmpty(attestationData[8371]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Poultry 8371 Details</h5>
                    }
                    <Poultry8371AttestationLabels.AttestationContent
                        attestationData={attestationData[8371]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}
            {/* //Form 8325 - DogChews */}
            {certificateNumber?.includes("8325") && !objectIsEmpty(attestationData[8325]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Dog Chews 8325 Details</h5>
                    }
                    <DogChews8325AttestationLabels.AttestationContent
                        attestationData={attestationData[8325]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}

            {/* //Form 8393 - Casing */}
            {certificateNumber?.includes("8393") && !objectIsEmpty(attestationData[8393]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Casing 8393 Details</h5>
                    }
                    <Casing8393AttestationLabels.AttestationContent
                        attestationData={attestationData[8393]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}
            {/* //Form 8396 - Collagen */}
            {certificateNumber?.includes("8396") && !objectIsEmpty(attestationData[8396]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Collagen 8396 Details</h5>
                    }
                    <Collagen8396AttestationLabels.AttestationContent
                        attestationData={attestationData[8396]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>
            )}

            {/* //Form 8391 - Honey */}
            {certificateNumber?.includes("8391") && !objectIsEmpty(attestationData[8391]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Honey 8391 Details</h5>
                    }
                    <Honey8391AttestationLabels.AttestationContent
                        attestationData={attestationData[8391]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>

            )}
            {/* //Form 8392 - Vitamin D */}
            {certificateNumber?.includes("8392") && !objectIsEmpty(attestationData[8392]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Vitamin D 8392 Details</h5>
                    }
                    <VitaminD8392AttestationLabels.AttestationContent
                        attestationData={attestationData[8392]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>

            )}
            {/* //Form 8360 - Egg */}
            {certificateNumber?.includes("8360") && !objectIsEmpty(attestationData[8360]) && (
                <>
                    {certificateNumber?.length > 1 &&
                        <h5>Egg 8360 Details</h5>
                    }
                    <Egg8360AttestationLabels.AttestationContent
                        attestationData={attestationData[8360]}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isReadOnly={isReadOnly}
                    />
                </>

            )}
        </>

    )
}