import React, { useState, useEffect, useRef } from 'react';
import { Routes } from "../../../../../routes";
import BreadcrumbNav from "../../../../../components/BreadcrumbNav";
import {getUserInfo} from "../../../../../auth/authorisation";
import { Formik, Form} from 'formik';
import Stepper from "../../../../../components/Stepper";
import { Button,Alert, Col, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import moment from "moment"
import ResponseAttestation from "./Forms/ResponseAttestationForm";
import ResponseAttestationReview from "./Forms/ResponseAttestationReview";
import validationSchema from './Models/GEFSValidation';
import formModel from './Models/GEFSFormModel';
import formInitialValues from './Models/GEFSFormValues';
import AttestationService from "../../../../services/AttestationService"
import FormSubmission from "../../../common/FormSubmission";
import ActivityService from '../../../../services/ActivityService';

const steps = ['Attestation Details', 'Review & Sign'];
const { formId, formField } = formModel;

function _renderStepContent(step, setFieldValue, setFieldTouched, initialInspectionDate,  touched, errors, productId, user, values, childRef, attestationHtml, submitHandler, isSubmitting,uniqueRef,isCustomUrn, product, manufacturer, platform, vets, organisation, site, productDetail, attestationData,attestationRequestData,generatedSADocument) {
    switch (step) {
      case 0:
        return <ResponseAttestation 
          formField={formField}
          setFieldValue={setFieldValue} 
          setFieldTouched={setFieldTouched} 
          initialInspectionDate={initialInspectionDate}
          touched={touched} errors={errors} 
          productId={productId} 
          user={user}
          values={values}
          uniqueRef={uniqueRef}
          isCustomUrn={isCustomUrn}
          product={product}
          manufacturer={manufacturer}
          platform={platform}
          vets={vets}
          organisation={organisation}
          site={site}
          productDetail={productDetail}
          attestationData={attestationData}
          attestationRequestData={attestationRequestData}
        />;
        case 1:
          return <ResponseAttestationReview 
          attestationHtml={attestationHtml}
          formField={formField}
          setFieldValue={setFieldValue} 
          setFieldTouched={setFieldTouched} 
          touched={touched} errors={errors} 
          productId={productId} 
          user={user}
          values={values}
          submitHandler={submitHandler}
          uniqueRef={uniqueRef}
          isSubmitting={isSubmitting}
          forwardedRef={childRef}
          product={product}
          manufacturer={manufacturer}
          platform={platform}
          vets={vets}
          organisation={organisation}
          site={site}
          productDetail={productDetail}
          attestationData={attestationData}
          generatedSADocument={generatedSADocument}
          />
      default:
        return <div>Not Found</div>;
    }
  }

const ResponseAttestationPage = (props) => {
  
  const [errors, setErrors] = useState(null);
  const childRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0); 
  const [successButtonInfo, setSuccessButtonInfo] = useState('');
  const [attestationHtml, setAttestationHtml] = useState(''); 
  const currentValidationSchema = validationSchema[activeStep]; 
  const isLastStep = activeStep === steps.length - 1;
  const [attestationId] = useState(props.match.params.id);
  const [user, setUser] = useState('');
  const [productId, setProductId] = useState('');
  const [attestationRequestData, setAttestationRequestData] = useState('');
  const [productUrl, setProductUrl] = useState('');
  const [uniqueRef, setUniqueRef] = useState('');
  const [isCustomUrn, setIsCustomUrn] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [product, setProduct] = useState({});
  const [manufacturer, setManufacturer] = useState({});
  const [platform, setPlatform] = useState([]);;
  const [vets, setVets] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [site, setSite] = useState({});
  const [productDetail, setProductDetail] = useState({});
  const [attestationData, setattestationData] = useState({});
  const [initialInspectionDate, setInitialInspectionDate] = useState('');
  const [uploadedSADocument, setUploadedSADocument] = useState('');
  const [generatedSADocument, setGeneratedSADocument] = useState('');

  useEffect(()=>{ 
    getUserInfo()
      .then(x => {
        setUser(x);
    });

  AttestationService.getById(attestationId)
    .then((response) => {
        setUniqueRef(response.data.attestationDetails?.uniqueCode);
        formField.uniqueRef = response.data.attestationDetails?.uniqueCode;
        var res = response.data;
        setAttestationRequestData(res.attestationRequest);
        var productJson = JSON.parse(res.attestationDetails.productJson);
        setInitialInspectionDate(moment(res.attestationRequest.createdDate).format("MMMM D, YYYY"));
        setProduct(productJson.product);
        console.info(JSON.stringify(productJson));
        setProductId(productJson.product.id);
        setProductUrl(Routes.Product.path.replace(":id","") + productJson.product.id);
        setSuccessButtonInfo({url:(Routes.SupportAttestations.path), name:"Back to Support Attestations"});
        formInitialValues.isGEFS = productJson.product.isGEFS;
        setProductDetail(productJson.productDetail);
        var certNumber = productJson.productDetail.certificateNumber;
        if(certNumber =="8350"){
          setattestationData(productJson.attestationData);
        }
        setManufacturer(productJson.manufacturer);
        setSite(productJson.site);
        setPlatform(productJson.platform);
        setOrganisation(productJson.organisation);
            
    })
    .catch(e => {console.log(e);});
    },[]);

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    let submitData = values;
    submitData.attestationId = attestationId;
    submitData.filename = values.signedRequestUrl;
    submitData.documentSigningId = values.documentId;
    submitData.decision = values.decision;
    submitData.inspectionDate = values.dateInspection;
    submitData.isFirstInspection = values.firstInspection;
    submitData.changesDescription = values.changesDescription;
    submitData.changesStatement = values.changeStatement;

    console.info(JSON.stringify(submitData));

    AttestationService.createResponse(submitData)
    .then(async (response) => {
      //Create Activity Log
      var id = await  response.data?.attestationId
      await ActivityService.log(
        'Responded to an attestation', 
        'A GEFS attestation has been responded to for ' + product.productName + ' with URN ' + uniqueRef,
        'Attestation', 
        'Response',
        values.signedRequestUrl,
        id
      );
     })
    .catch((e) => {
      setErrors(e.response.data);
      console.log(e);}
    );

    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    setSubmitAttempted(false);
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
  
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }

    //Set Doc HTML for export to PDF
    if(activeStep==0){
      AttestationService.createSA(childRef.current.outerHTML)
      .then((response) => {
          setGeneratedSADocument(response.data);
        });
    }  
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const title = "Attestation Request - Vet Declaration";
  const breadcrumbData = {
      item1 : {name:'Support Attestations', link: Routes.SupportAttestations.path},
      item2 : {name: product.productName, link: Routes.Product.path.replace(":id",productId)},
      item3 : {name:'Attestation Response', link: '#'},
  };
  
  return (
    <div className="row">
        <BreadcrumbNav data={breadcrumbData}/>
        <h2>{title}</h2>
                
        {activeStep !== steps.length &&(
          <Row>
            <Col xl={10}>
              <Stepper steps={steps} activeStep={activeStep} />
            </Col>
        </Row>
        )}

        {activeStep === steps.length ? (
          <FormSubmission
            errors={errors}
            title ={"Attestation Response Sent"}
            message = {"The attestation response has been sent and the attestation for this product is now valid."}
            successButtonUrl = {Routes.SupportAttestations.path}
            successButtonName = {"Back to support attestations"}
          />
        ) 
        :
        (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ isSubmitting, setFieldValue,  setFieldTouched, touched, errors, values}) => (
              <Form id={formId}>
                {_renderStepContent(activeStep, setFieldValue, setFieldTouched, initialInspectionDate, touched, errors, productId, user, values, childRef, attestationHtml, _handleSubmit, isSubmitting, uniqueRef, isCustomUrn,
                  product, 
                  manufacturer,
                  platform, 
                  vets, 
                  organisation,
                  site, 
                  productDetail,
                  attestationData,
                  attestationRequestData,
                  generatedSADocument)}
                  
                {(Object.keys(errors).length > 0 && submitAttempted) &&
                  <Alert
                    className="mt-2"
                    variant="danger"
                    col="sm"
                    >
                    <div className="d-flex justify-content-start">
                      <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                      Some errors were found, check the form above for any missing or incomplete information.
                    </div>
                  </Alert>
                } 

                <div className={"d-inline"}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} variant="light" className="mx-3">
                      Back
                    </Button>
                  )}
                  <div className="d-inline">
                    <Button
                      onClick={() => { setSubmitAttempted(true) }}
                      disabled={isSubmitting}
                      type="submit"
                      variant="primary"
                      className={"btn-md"}
                      >                      
                      {activeStep== 0 ? 'Continue' :''}
                      {activeStep== 1 ? 'Complete Attestation' :'' }
                
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        </div>
    );
};

export default ResponseAttestationPage;