import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useRef, useState } from "react";
import { useTable } from "react-table";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { StorageSupplierDeclarationTable } from "../../../components/Tables/StorageSupplierDeclarationTable";
import { Routes } from "../../../routes";
import FilterBar from '../../filters/FilterBar';
import Paging from "../common/Paging";
import StorageDeclarationService from '../../services/StorageDeclarationService';
import { SupplierStorageFilterConfig } from '../../filters/configs/SupplierStorageFilterConfig';
import CountryService from '../../services/CountryService';
import ManufacturerDataService from '../../services/ManufacturerService';
import SiteDataService from '../../services/SiteService';

const StorageSupplierDeclarationListingPage = (props) => {
    const [storageDeclarationRequests, setStorageDeclarationRequests] = useState([]);
    const [count, setCount] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const breadcrumbData = {
            item1 : {name:'Supplier Declarations', link: Routes.StorageSupplierDeclarationListingPage.path},
            item2 : {name:'View All', link: 'View All'},
        };
    const productRef = useRef();
    productRef.current = storageDeclarationRequests;
    const [pageSize, setPageSize] = React.useState(10);
    const [activePage, setActivePage] = React.useState(1);
    const [ready, setReady] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [countries, setCountries] = useState([]);
    const [sites, setSites] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const { totalPages = count, size = "md", withIcons = false, disablePrev = false } = props;
    const orderByOptions = ["Last updated", "A-Z", "Z-A"]

    const onPrevItem = () => {
      const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
      setActivePage(prevActiveItem);
    };
    
    const onPageNumberClick = (number) => {
        setActivePage(number);
    };
  
    const onNextItem = (totalPages) => {
      const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
      setActivePage(nextActiveItem);
    };

    productRef.current = storageDeclarationRequests;

    
    //End - Paging Functions

    const getDeclarations = (params) => {
        setLoaded(false);
        StorageDeclarationService.getAllPaged(params)
        .then((response) => {
            const { data, totalPages, totalRecords} = response.data;
            setTotalItems(totalRecords);
            console.info("the resp...." + JSON.stringify(response));
            setStorageDeclarationRequests(data);
            console.log(response.data);
            setLoaded(true);
            setReady(true);
        })
        .catch((e) => {
            setLoaded(true);
            console.log(e);
        });
    }

       
    //Submit
    const handleFilterSubmit= (params) => {
        setSubmitting(true);
        getDeclarations(params)
        setSubmitting(false);
    }

    const handleFilterChange = (values) => {
        setSubmitting(true);
        setSubmitting(false);
    }

    const openDeclaration = (rowIndex) => {
        const id = productRef.current[rowIndex].id;
        props.history.push("/product/" + id);
    };
    const handlePageSizeChange = (value) => {
        setPageSize(value.value);
    };


    
    //On load - Get list data
    useEffect(() => {
        const getSiteData = new Promise((resolve, reject) => {
            resolve(SiteDataService.getAll()
            .then(response => {
                const options = response.data.map(d => ({
                    "value": d.id,
                    "label": d.siteName
                })).sort((a, b) => a.label.localeCompare(b.label));
                setSites(options);      
            })
            .catch(e => {
                console.log(e);
                    })
                );
            });
        const getManufacturerData = new Promise((resolve, reject) => {
            resolve(ManufacturerDataService.getAll()
            .then(response => {
                const options = response.data.map(d => ({
                    "value": d.id,
                    "label": d.manufacturerName
                })).sort((a, b) => a.label.localeCompare(b.label));
                setManufacturers(options);  
            })
            .catch(e => {
                console.log(e);
                    })
                );
            });
    
        const getCountryData = new Promise((resolve, reject) => {
            resolve(CountryService.getEUAndUKCountries()
            .then(response => {
                const options = response.data.map(d => ({
                    "value": d.id,
                    "label": d.countryName
                })).sort((a, b) => a.label.localeCompare(b.label));
                setCountries(options);  
            })
            .catch(e => {
                console.log(e);
                    })
                );
            });
    
        Promise.all([getSiteData, getManufacturerData, getCountryData])
            .then((responses) => {
                setReady(true);

        } );
    }, []);

    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            <h2 id="tabelLabel" >Supplier Declarations</h2>
            <div className="col-md-12 list">
                <Row className="mt-2">
                    <Col>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <FilterBar
                                            loaded={loaded}
                                            setLoaded={setLoaded}
                                            ready={ready} 
                                            hasSearch={true}
                                            defaultFilterValues={SupplierStorageFilterConfig(manufacturers, sites, countries)}
                                            activePage={activePage}
                                            pageSize={pageSize}
                                            orderByOptions={orderByOptions}
                                            setActivePage={setActivePage}
                                            handleChange={handleFilterChange}
                                            handleSubmit={handleFilterSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <StorageSupplierDeclarationTable 
                                    loaded={loaded} 
                                    storageDeclarationRequests={storageDeclarationRequests}
                                />
                                <Paging
                                        activePage={activePage}
                                        col={size}
                                        pageSize={pageSize}
                                        totalPages={totalPages}
                                        totalItems={totalItems}
                                        handleChange={handlePageSizeChange}
                                        onPrevItem={onPrevItem}
                                        onNextItem={onNextItem}
                                        onPageNumberClick={onPageNumberClick}
                                        withIcons={withIcons}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default StorageSupplierDeclarationListingPage;