import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, Row } from "@themesberg/react-bootstrap";
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { getUserInfo } from "../../../../auth/authorisation";
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import Stepper from "../../../../components/Stepper";
import { Routes } from "../../../../routes";
import AttestationService from "../../../services/AttestationService";
import DailyAttestationService from "../../../services/DailyAttestationService";
import ManufacturerDataService from "../../../services/ManufacturerService";
import DailyAttestationForm from "./Forms/DailyAttestationForm";
import DailyAttestationProductSelect from "./Forms/DailyAttestationProductSelect";
import DailyAttestationReview from "./Forms/DailyAttestationReview";
import DailyAttestationSuccess from './Forms/DailyAttestationSuccess';
import formModel from './Forms/Models/DailyAttestationModel';
import validationSchema from './Forms/Models/DailyAttestationValidation';
import formInitialValues from './Forms/Models/DailyAttestationValues';
import { AbattoirEstablishmentValues, DairyEstablishmentValues, MeatEstablishmentValues } from './Forms/FormComponents/DairyAttestationHelperText';
import ActivityService from '../../../services/ActivityService';

const steps = ['Product Selection', 'Attestation Details', 'Review & Sign'];
const { formId, formField } = formModel;

function _renderStepContent(
  step,
  uniqueRef,
  cloneData,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  dailyAttestationValues,
  user,
  values,
  manufacturerRestricted,
  childRef,
  generatedSADocument,
  manufacturers
  ) {
  switch (step) {
    case 0:
      return <DailyAttestationProductSelect
      formField={formField}
      cloneData ={cloneData}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      touched={touched} errors={errors}
      user={user}
      values={values}
      manufacturers={manufacturers}
      manufacturerRestricted={manufacturerRestricted}
      />;
      case 1:
        return <DailyAttestationForm
        formField={formField}
        uniqueRef={uniqueRef}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        touched={touched} errors={errors}
        user={user}
        values={values}
        dailyAttestationValues={dailyAttestationValues}
      />;
      case 2:
        return <DailyAttestationReview
        formField={formField}
        uniqueRef={uniqueRef}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        touched={touched} errors={errors}
        user={user}
        forwardedRef={childRef}
        values={values}
        dailyAttestationValues={dailyAttestationValues}
        manufacturerRestricted={manufacturerRestricted}
        generatedSADocument={generatedSADocument}
      />;
    default:
      return <div>Not Found</div>;
  }
}

const DailyAttestationAddPage = (props) => {
  const {cloneData} = props;
  const childRef = useRef(null);
  const [uniqueRef, setUniqueRef] = useState('');
  const [dailyAttestationValues, setDailyAttestationValues] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [signedRequestUrl, setSignedRequestUrl] = useState('');
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const backPageUrl = Routes.AttestationDashboard.path;
  const [manufacturerRestricted, setManufacturerRestricted] = useState({});
  const [manufacturers, setManufacturers] = useState(null);
  const [generatedSADocument, setGeneratedSADocument] = useState('');
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [user, setUser] = useState('');
  const [errors, setErrors] = useState(null);

  //Function - Generate a URN for this Daily Attestation
  function _handleGenerateUrn(manufacturerId) {
   DailyAttestationService.generateUniqueReferenceNumber(manufacturerId)
    .then((response) => {
      setUniqueRef(response.data);
      })
    .catch((e) => {console.log(e);});
  }

  useEffect(()=>{
    //Get User Data / Get New URN
    getUserInfo().then(x => {
      setUser(x);
      getManufacturerRestrictedData(x.manufacturerId);
      getAllManufacturers();
    });
   },[]);

  async function getManufacturerRestrictedData(manufacturerId){
    if(manufacturerId.length > 0){
      manufacturerId = manufacturerId[0];
    }
    ManufacturerDataService.getById(manufacturerId)
      .then((response) => {
        setManufacturerRestricted(response.data);
    })
    .catch((e) => {
        console.log(e);
    });
  }
  async function getAllManufacturers(){
    ManufacturerDataService.getAll()
    .then((response) => {
        const options = response.data.map(d => ({
            "value": d.id,
            "label": d.manufacturerName
          })).sort((a, b) => a.label.localeCompare(b.label));
          setManufacturers(options);
    })
    .catch((e) => {
        console.log(e);
    });
}
  const PrettyPrintJson = ({data}) => (<div><pre>{
    JSON.stringify(data, null, 2) }</pre></div>
  );

  //Function - Submit form
  async function _submitForm(values, actions) {
    actions.setSubmitting(true);
    await _sleep(1000);

    let submitData = values;
    submitData.uniqueCode = uniqueRef;
    submitData.productsSelected = null;
    submitData.filename = values.signedRequestUrl;
    submitData.documentSigningId = values.documentId;
    DailyAttestationService.create(submitData)
    .then(async (response) => {
      //Create Activity Log

      await ActivityService.log(
        'Added a daily attestation',
        'A daily attestation has been added with tracking URN ' + uniqueRef,
        'Daily Attestation',
        'Create',
        Routes.DailyAttestationAddPage.path.replace(":id", response.data.id),
        response.data.id
      );
    })
    .catch((e) => {
      setErrors(e.response.data);
      console.info("error");
      console.info(e);}
    );
    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  }

  //Function - Sets the array initial values for daily attestation data from the products selected

  function _handleDailyAttestationValues(values, actions) {
    let result = [];
    values.productsSelected.forEach((selected, index) => {
      //Find matching values if cloned
      let apiValues = null;

      const matchFound = cloneData?.dailyAttestationData.find(x=>x.attestationId == selected.id);
      if(matchFound != undefined){
        apiValues = matchFound;
      }

      console.info("selected")
      console.info(selected)

      //Initial Daily Attestation Item values
      let item= {
        isPOU: false,
        certificateNumbers: selected.certificateNumberList,
        attestationData: selected.attestationData,
        productDetails: selected.productDetails,
        attestationDetails: selected.attestationDetails,
        productId: selected.productId,
        uniqueCode: selected.uniqueCode,
        attestationId:  selected.id,
        productName: selected.productName + " (EAN: " + selected.ean + ")",
        ean: selected.ean,
        validDates: selected.validDates,
        validTo: selected.validTo,
        dateCollectionProductionStart: apiValues?.dateCollectionProductionStart ?? '',
        dateCollectionProductionEnd: apiValues?.dateCollectionProductionEnd ?? '',
        dateCollectionProductionSingle: apiValues?.dateCollectionProductionSingle ?? '',
        isDateCollectionProductionMulti: apiValues?.isDateCollectionProductionMulti ?? null,
        attestationBatchNumbers: selected.batchNumbers,
        hasBatches: selected?.batches?.length > 0 ? true : false,
        batches: selected?.batches ?? [],
        batchNumberRange: [],
        batchNumbers: apiValues?.batchNumbers ?? '',
        numberOfPackages:  apiValues?.numberOfPackages ?? 0,
        abattoirs:  apiValues?.abattoirs ?? '',
        establishmentsSlaughterhouses:  AbattoirEstablishmentValues(selected.attestationData) ?? [],
        establishmentsDairy:  DairyEstablishmentValues(selected.attestationData) ?? [],
        establishmentsMeat:  MeatEstablishmentValues(selected.attestationData) ?? [],
        weightType: selected.productDetails.weightType,
        netWeight:  selected.productDetails.netWeight,
        grossWeight:  selected.productDetails.grossWeight,
        farmPost:  apiValues?.farmPost ?? '',
        slaughterDates:  apiValues?.slaughterDates ??[],
        order:  apiValues?.order ?? 0,
        poultryCuttingPlantApprovalNumbers:  apiValues?.poultryCuttingPlantApprovalNumbers ?? '',
        abattoirApprovalNumbers:  apiValues?.abattoirApprovalNumbers ?? '',
        poultryMeatArrivalDate:  apiValues?.poultryMeatArrivalDate ?? '',
        dairyEstablishments:  apiValues?.dairyEstablishments ?? '',
        meatEstablishments:  apiValues?.meatEstablishments ?? '',
        notes: apiValues?.notes ?? '',
        dateCollectionProduction: apiValues?.dateCollectionProduction?? '',
        originAnimal: apiValues?.originAnimal ?? ''
      }

      result.push(item);

    });
    setDailyAttestationValues(result);
  }



  //Function - Submit Functions

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function _handleSubmit(values, actions) {
    setSubmitAttempted(false);
    if (isLastStep)
    {
      _submitForm(values, actions);
    }
    else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }

    //Step 2 - Set Attestation Data
    if(activeStep==0){
        _handleGenerateUrn(values.manufacturerId);
        _handleDailyAttestationValues(values, actions);
    }
    //Step 3 - Create Document
    if(activeStep==1){
      AttestationService.createSA(childRef.current.outerHTML)
        .then((response) => {
          setGeneratedSADocument(response.data);
      });
    }
    //Step 3 - Success Page - Pass doc url
    if(activeStep==2){
        setSignedRequestUrl(values.signedRequestUrl);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  //Form Start

  const title = "Daily Attestation - Supplier Declaration";
  const breadcrumbData = {
      item1 : {name:'Attestations', link: Routes.AttestationDashboard.path},
      item2 : {name:'Daily Attestation', link: '#'},
  };

  return (
      <div className="row">
          <BreadcrumbNav data={breadcrumbData}/>
          <h2>{title}</h2>

          {/* //Stepper */}
          {activeStep !== steps.length &&(
            <Row>
              <Col xl={10}>
                <Stepper steps={steps} activeStep={activeStep} />
                {cloneData!==undefined &&(
                <Alert
                    className="mt-2 mb-0"
                    variant="light"
                    size="sm"
                >
                  <div className="d-flex justify-content-start">
                      <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                      This daily attestation has been cloned from a previous version. Reference: {cloneData?.uniqueCodeClone} .
                  </div>
                </Alert>
                )}
              </Col>
            </Row>
          )}

          {/* //Step - Form Complete */}
          {activeStep === steps.length ? (
            <DailyAttestationSuccess
                errors={errors}
                title ={"Daily Attestation Created"}
                message = {`<p>The daily attestation has been completed.</p>
                            <p>You can access a copy of the daily attestation any time on the Attestations page.</p>
                            <p>The document can also be downloaded here:</p>
                          `}
                signedRequestUrl={signedRequestUrl}
                successButtonUrl = {backPageUrl}
                successButtonName = {"Back to Attestations"}
            />
          )
          :
          (
            <Formik
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}
              validateOnChange={true}
              validateOnBlur={false}
              enableReinitialize={true}
            >
          {/* //Step - Main Content */}
              {({ isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  touched,
                  errors,
                  values
              }) => (
                <Form id={formId}>

                  {/* //Main Stepped Form */}
                  {_renderStepContent(
                      activeStep,
                      uniqueRef,
                      cloneData,
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      dailyAttestationValues,
                      user,
                      values,
                      manufacturerRestricted,
                      childRef,
                      generatedSADocument,
                      manufacturers
                  )}

                  {/* //Errors */}
                  {(Object.keys(errors).length > 0 && submitAttempted) &&
                      <Alert
                        className="mt-2"
                        variant="danger"
                        size="sm"
                      >

                        <div className="d-flex justify-content-start">
                          <FontAwesomeIcon icon={faBullhorn} className="me-3" />

                          Some errors were found, check the form above for any missing or incomplete information.
                        </div>
                      </Alert>
                  }

                  {/* //Back Button */}
                  <div className={"d-inline"}>
                    {activeStep !== 0 && (
                      <Button onClick={_handleBack} variant="light" className="mx-3">
                        Back
                      </Button>
                    )}

                    {/* <PrettyPrintJson data={values}/> */}

                  {/* //Continue / Submit Button */}
                    <div className="d-inline">
                      <Button
                        onClick={() => { setSubmitAttempted(true) }}
                        disabled={isSubmitting}
                        type="submit"
                        variant="primary"
                        className={"btn-md"}
                      >
                        {activeStep< 2 ? 'Continue' :''}
                        {activeStep== 2 ? 'Complete' :'' }
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
      </div>
    );
};

export default DailyAttestationAddPage;