//Renders and formats html in English and a translated language 
export const MultiLangText = (props) =>{
    const createMarkup = (html) => ({ __html: html });
    const { className, engValue, translatedValue, delimitterType, languageCode} = props;

    return (
        <>
            {/* English value */}
            <span 
                className={className} 
                dangerouslySetInnerHTML={
                    createMarkup(engValue)
                }
            />
            {languageCode !== undefined && languageCode!=="en" && languageCode!=="" &&
            <>
                <Delimitter type={delimitterType} /> {/* Delimitter - to position to translation */}
                {/* Translated value */}
                <span 
                    className={`text-muted text-italic ${className}`}
                    dangerouslySetInnerHTML={
                        createMarkup(translatedValue)
                    }
                />
            </>
            }
        </>
    )
}

//Delimiter function to format the position of text
export const Delimitter = ({type}) =>{
    switch (type) {
        case "inline":
            return (<>&nbsp;/&nbsp;</>)
            break;
    
        case "newline":
            return (<><br/></>)
            break;
        default:
            return null
            break;
    };
}