import *  as baseService  from "./BaseService";

const getAllPaged = (params) => {
    return baseService.getApiDataWithParams("/platform/GetAllPaged", params);
};

const getAll = (params) => {
    return baseService.getApiData("/platform/GetAll");
};
const getById = (id) => {
    return baseService.getApiData("/platform/GetById?id=" + id);
};
const getWithEstablishmentNumberType = (id) => {
    return baseService.getApiData("/platform/getByIdWithEstablishmentType?id=" + id);
};
const create = (data) => {
    return baseService.postApiData("/platform/Create", data);
};
const update = (id, data) => {
    return baseService.putApiData("/platform/update?id=" + id, data);
};
const PlatformService = {
    getAll,
    getAllPaged,
    getById,
    getWithEstablishmentNumberType,
    create,
    update
};

export default PlatformService;