export default {
  formId: 'dailyAttestationForm',
  formField: {
    deliveryDate: {
      name: 'deliveryDate',
      label: 'Delivery Date to Export Depot',
      requiredErrorMsg: 'Delivery Date required '
    },
    // name: {
    //   name: 'name',
    //   label: 'Name / reference number for this daily attestation',
    //   placeholder: 'Name / reference number',
    //   sublabel: "Use a short name for reference, this will appear on the previous attestations page for later reference.",
    //   requiredErrorMsg: 'A name or reference number must be entered',
    //   maxLengthErrorMessage: 'A maximum of 50 characters must be used'
    // },
    requireManufacturer: {
      name: 'requireManufacturer',
    },
    manufacturerName: {
      name: 'manufacturerName',
      label: 'Select a manufacturerName',
      requiredErrorMsg: 'A manufacturerName must be selected'
    },
    manufacturer: {
      name: 'manufacturer',
      label: 'Select a manufacturer',
      requiredErrorMsg: 'A manufacturer must be selected'
    },
    manufacturerId: {
      name: 'manufacturerId',
      label: 'manufacturerId',
      requiredErrorMsg: 'manufacturerId must be selected'
    },
    productsSelected: {
      name: 'productsSelected',
      label: 'Product / Attestations selected',
      requiredErrorMsg: 'At least one product must be selected'
    },
    signedRequestUrl: {
      name: 'signedRequestUrl',
      label: 'Signed Request Url',
      requiredErrorMsg: ''
    },
    documentId: {
      name: 'documentId',
      label: 'Document ID',
      requiredErrorMsg: ''
    },
    dailyAttestationData:{
      name: 'dailyAttestationData',
      label: 'dailyAttestationData',
      requiredErrorMsg:""
    },
    trackingRef: {
      name: 'trackingRef',
      label:"Tracking Document Number",
      requiredErrorMsg: 'trackingRef'
    },

    //dailyAttestationData Array Fields
    birdFluCheck: {
      name: 'birdFluCheck',
      label:"birdFluCheck",
      requiredErrorMsg: 'birdFluCheck'
    },
    order: {
      name: 'order',
      label: 'order',
      requiredErrorMsg: 'Order required '
    },
    uniqueCode: {
      name: 'uniqueCode',
      label:"Support Health Attestation",
      requiredErrorMsg: 'uniqueCode'
    },
    productName: {
      name: 'productName',
      label:"Product name",
      requiredErrorMsg: 'productName'
    },
    batchNumberRange: {
      name: 'batchNumberRange',
      label:"Batch numbers",
      requiredErrorMsg: 'Batch number selection required'
    },
    batchNumbers: {
      name: 'batchNumbers',
      label:"Batch numbers",
      requiredErrorMsg: 'Batch numbers required'
    },
    attestationId: {
      name: 'attestationId',
      label:"attestationId",
      requiredErrorMsg: 'attestationId required'
    },
    netWeight: {
      name: 'netWeight',
      label:"Net weight (kg)",
      requiredErrorMsg: 'Net weight required, enter a decimal to a maximum of 3 decimal places (e.g 10.000)'
    },
    netWeightTotal: {
      name: 'netWeightTotal',
      label:"Net weight total (kg)",
      requiredErrorMsg: 'Net weight per quantity required'
    },
    grossWeight: {
      name: 'grossWeight',
      label:"Gross weight (kg)",
      requiredErrorMsg: 'Gross weight required, enter a decimal to a maximum of 3 decimal places (e.g 10.000)'
    },
    grossWeightTotal: {
      name: 'grossWeightTotal',
      label:"Gross weight total (kg)",
      requiredErrorMsg: 'Gross weight per quantity required'
    },
    commercialNumber: {
      name: 'commercialNumber',
      label: 'Invoice / PO / Delivery number',
      requiredErrorMsg: 'Invoice / PO / Delivery number required '
    },
    numberOfPackages: {
      name: 'numberOfPackages',
      label: 'Number of packages',
      requiredErrorMsg: 'Number of packages must be a minimum of 1'
    },
    numberOfCases: {
      name: 'numberOfCases',
      label: 'Number of cases',
      requiredErrorMsg: 'Number of cases must be a minimum of 1'
    },
    numberOfUnits: {
      name: 'numberOfUnits',
      label: 'Number of units',
      requiredErrorMsg: 'Number of units must be a minimum of 1'
    },
    establishmentsSlaughterhouses: {
      name: 'establishmentsSlaughterhouses',
      label: 'Actual approval number of slaughterhouse(s)',
      requiredErrorMsg: 'Actual approval number of slaughterhouse(s) required'
    },
    establishmentsCuttingPlants: {
      name: 'establishmentsCuttingPlants',
      label: 'Actual approval number of cutting plant(s)',
      requiredErrorMsg: 'Actual approval number of cutting plant(s) required'
    },
    establishmentsDairy: {
      name: 'establishmentsDairy',
      label: 'Actual approval number of dairy establishment(s)',
      requiredErrorMsg: 'Actual approval number of dairy establishment(s) required'
    },
    establishmentsMeat: {
      name: 'establishmentsMeat',
      label: 'Actual approval number of meat product establishment(s)',
      requiredErrorMsg: 'Actual approval number of meat product establishment(s) required'
    },
    abattoirs: {
      name: 'abattoirs',
      label: 'Actual approval number of slaughterhouse(s)',
      requiredErrorMsg: 'Actual approval number of slaughterhouse(s) required',
      sublabel: '(if applicable)'
    },
    dairyEstablishments: {
      name: 'dairyEstablishments',
      label: 'Actual approval number of dairy establishment',
      sublabel: '(if applicable)',
      requiredErrorMsg: 'Actual approval number of dairy establishment required '
    },
    meatEstablishments: {
      name: 'meatEstablishments',
      label: 'Actual approval number of meat establishment',
      sublabel: '(if applicable)',
      requiredErrorMsg: 'Actual approval number of meat establishment required '
    },
    // cuttingPlantApprovalNumbers: {
    //   name: 'cuttingPlantApprovalNumbers',
    //   label: 'Approval number of cutting plant(s)',
    //   requiredErrorMsg: 'Approval number of cutting plant(s) required '
    // },
    farmPostEgg: {
      name: 'farmPost',
      label: 'Post code of farm where eggs were collected only for UK sites',
      requiredErrorMsg: 'Post of farm egg came from required '
    },
    farmPost: {
      name: 'farmPost',
      label: 'Post code of farm poultry bird came from',
      requiredErrorMsg: 'Post of farm poultry bird came from required '
    },
    slaughterDates: {
      name: 'slaughterDates',
      label: 'Dates of slaughter',
      requiredErrorMsg: 'At least one date of slaughter required '
    },
    slaughterDate: {
      name: 'slaughterDate',
      label: 'Date of slaughter',
      requiredErrorMsg: 'Date of Slaughter required '
    },
    poultryCuttingPlantApprovalNumbers: {
      name: 'poultryCuttingPlantApprovalNumbers',
      label: 'Approval number of cutting plant(s)',
      requiredErrorMsg: 'Approval number of cutting plant(s) required '
    },
    abattoirApprovalNumbers: {
      name: 'abattoirApprovalNumbers',
      label: 'Approval number of slaughterhouse(s)',
      requiredErrorMsg: 'Approval number of slaughterhouse(s) required '
    },
    poultryMeatArrivalDate: {
      name: 'poultryMeatArrivalDate',
      label: 'Date of poultry meat arrived at cutting plant',
      requiredErrorMsg: 'Date of poultry meat arrived at cutting plant required '
    },
    originAnimal: {
      name: 'originAnimal',
      label: 'Origin of animal',
      requiredErrorMsg: 'Origin of animal required'
    },
    isDateCollectionProductionMulti: {
      name: 'isDateCollectionProductionMulti',
      label: 'Date of production:',
      requiredErrorMsg: 'Date of production is required'
    },
    dateCollectionProductionStart: {
      name: 'dateCollectionProductionStart',
      label: null,
      requiredErrorMsg: 'Date of production is required'
    },
    dateCollectionProductionEnd: {
      name: 'dateCollectionProductionEnd',
      label: null,
      requiredErrorMsg: 'Date of production is required'
    },
    dateCollectionProductionSingle: {
      name: 'dateCollectionProductionSingle',
      label: null,
      requiredErrorMsg: 'Date of production is required'
    },
    dateCollectionProduction: {
      name: 'dateCollectionProduction',
      label: "Date of production",
      requiredErrorMsg: 'Date of production is required'
    },
    validTo: {
      name: 'validTo',
      label: null,
      requiredErrorMsg: "validTo is required"
    },
    notes: {
      name: 'notes',
      label: 'Notes',
      requiredErrorMsg: 'Notes required '
    }
  }
};
  
