import moment from "moment"
import { useFormikContext } from 'formik';

export const SetProductsSelected = (item) => {
  const { values: formValues } = useFormikContext();
  formValues.productsSelected.push(item);
};


export const ReadDataToProductItem = (id, certificateNumberList, dailyAttestationItemId, attestationRequest, batchNumberRange, batchNumbers, attestationDetails, validFrom, validTo) => {
 return {
    id: id,
    certificateNumbers: certificateNumberList,
    dailyAttestationItemId: dailyAttestationItemId,
    productId: JSON.parse(attestationDetails.productJson).product?.id,
    attestationId: id,
    ean: JSON.parse(attestationDetails.productJson).product?.ean,
    batchNumbers: batchNumbers, 
    batches: batchNumberRange, 
    uniqueCode: attestationDetails?.uniqueCode, 
    productName: JSON.parse(attestationDetails.productJson).product?.productName, 
    productDetails: JSON.parse(attestationDetails.productJson).productDetail,
    attestationData: JSON.parse(attestationDetails.productJson).attestationData,
    certificateNumber: JSON.parse(attestationDetails.productJson).product?.productDetails?.certificateNumber,
    validDates: moment(validFrom).format("DD/MM/YYYY"),
    validTo: moment(validTo).format("DD/MM/YYYY"),
    attestationDetails: attestationDetails, 
  }

};
