import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Modal, Row, Spinner } from "@themesberg/react-bootstrap";
import { addDays, format, isAfter, isValid, parse, addMonths } from 'date-fns';
import { Form, Formik } from 'formik';
import debounce from 'lodash.debounce';
import React, { useState } from 'react';
import * as Yup from 'yup';
import AttestationService from "../../../services/AttestationService";
import FormController from "../../common/FormController";
import { checkYearLength, isJulianCodeWithPrefix, isNumeric, isNumericWithPrefix, isThreeDigitJulianDay, isValidYearAndJulianDay, parseDate, permittedDateFormats } from "./BatchFormatChecks";


const AttestationBatchRange = ({ setFieldValue, hasBatchRange, batchNumbers, batchNumberOptions, batchFormat, batchRangeFrom, batchRangeTo }) => {
  const [showModal, setShowModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [lookupComplete, setLookupComplete] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
  const [formatFound, setSelectedFormatFound] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [batchType, setBatchType] = useState('Custom');
  const [result, setResult] = useState(null);

  //Submit batches to parent form
  async function onSubmit(values, { resetForm}) {
    setSubmitting(true)
    let batchFirst, batchLast;
    let apiBatchFirst, apiBatchLast;
    setFieldValue(batchNumbers.name, null);
    setFieldValue(batchFormat.name, null);
    setFieldValue(batchRangeFrom.name, '');
    setFieldValue(batchRangeTo.name, '');
    switch (batchType) {
        case  "YYjulian":
            setSelectedFormat("YYjulian");
            batchFirst = values.yyJulianBatchFirst;
            batchLast = values.yyJulianBatchLast;
            apiBatchFirst = values.yyJulianBatchFirst;
            apiBatchLast = values.yyJulianBatchLast;
            break;
        case "Julian":
            setSelectedFormat("JJJ");
            batchFirst = values.julianBatchFirst;
            batchLast = values.julianBatchLast;
            apiBatchFirst = values.julianBatchFirst;
            apiBatchLast = values.julianBatchLast;
            break;
        case "JulianPrefix":
            setSelectedFormat("JJJ");
            batchFirst = values.julianPrefixBatchFirst;
            batchLast = values.julianPrefixBatchLast;
            apiBatchFirst = values.julianPrefixBatchFirst;
            apiBatchLast = values.julianPrefixBatchLast;
            break;
        case "Numeric":
            batchFirst = values.numericBatchFirst;
            batchLast = values.numericBatchLast;
            apiBatchFirst = values.numericBatchFirst;
            apiBatchLast = values.numericBatchLast;
            break;
        case "NumericPrefix":
            batchFirst = values.numericPrefixBatchFirst;
            batchLast = values.numericPrefixBatchLast;
            apiBatchFirst = values.numericPrefixBatchFirst;
            apiBatchLast = values.numericPrefixBatchLast;
            break;
        case "Date":
            batchFirst = format(values.dateBatchFirst,selectedFormat);
            batchLast = format(values.dateBatchLast,selectedFormat);
            apiBatchFirst = format(values.dateBatchFirst,  "yyyy-MM-dd");
            apiBatchLast = format(values.dateBatchLast,  "yyyy-MM-dd");
            break;
        default: // Assuming this is for 'Custom' or any other type
            batchFirst = values.customBatchFirst;
            batchLast = values.customBatchLast;
        break;
    }

    if(batchType!="Custom"){
        const fullBatchNumbers = await processBatchRange(batchType, selectedFormat, apiBatchFirst, apiBatchLast);
        setFieldValue(batchNumbers.name, fullBatchNumbers);
    }
    //Get full batch range from API + update form fields
    setFieldValue(batchFormat.name, selectedFormat);
    setFieldValue(batchRangeFrom.name, batchFirst);
    setFieldValue(batchRangeTo.name, batchLast);

    setSubmitting(false)
    handleCloseModal();
  }


  //Close batch range modal
  const handleCustomChange = (setFieldValue) => {
    setBatchType("Custom")
    setFieldValue(batchFormat.name, "Custom");
    setFieldValue('batchType', "Custom");
  }

  //Close batch range modal
  const handleCloseModal = () => {
    setSelectedFormatFound(false);
    setSelectedFormat(null);
    setLookupComplete(false);
    setShowModal(false);
  }
  
  //Get all batch numbers in range from API
  const processBatchRange = async(batchType, batchFormat, batchRangeFrom, batchRangeTo) => {
    var res = await AttestationService.processBatchNumbers(batchType, batchFormat, batchRangeFrom, batchRangeTo)
    return await res.data;
  };


  //Remove ordinal suffix from date string
  const removeOrdinalSuffix = (dateStr) => {
    return dateStr.replace(/(\d+)(st|nd|rd|th)/g, '$1');
  };

  //Update batch format check when user types first batch number
  const handleFindFormatChange = (event, setFieldValue) => {
    setProcessing(true);
    const value = event.target.value;
    if(value.length <3){
        return;
    }
    debounceUpdate(value, setFieldValue);
  };

  
  function isValidDate(value, dateFormat) {
    // Use regular expressions to identify different components
    const ordinalDayRegex = /\b(1st|2nd|3rd|[4-9]th|[1-2][0-9]th|3[0-1]st)\b/;
    const monthNameRegex = /\b(Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\b/;
    const yearRegex = /\b(\d{2}|\d{4})\b/;
    const dayRegex = /\b([1-9]|[1-2][0-9]|3[0-1])\b/;
    const monthRegex = /\b([1-9]|1[0-2])\b/;

    // Extract components based on regex and format
    let dayMatch = value.match(dateFormat.includes('do') ? ordinalDayRegex : dayRegex);
    let monthMatch = value.match(dateFormat.includes('MMM') ? monthNameRegex : monthRegex);
    let yearMatch = value.match(yearRegex);

    // Verify each component is found and matches the format requirements
    if ((dateFormat.includes('do') && !dayMatch) ||
        (dateFormat.includes('MMM') && !monthMatch) ||
        !yearMatch ||
        (dateFormat.includes('dd') && dayMatch && dayMatch[0].length !== 2) ||
        (dateFormat.includes('MM') && monthMatch && monthMatch[0].length !== 2)) {
        return false;
    }

    // Attempt to parse the date
    let cleanedInput = value.replace(/(st|nd|rd|th)/, ''); // Clean ordinal suffixes for parsing
    const parsedDate = parse(cleanedInput, dateFormat, new Date());
    return isValid(parsedDate);
  }




  const debounceUpdate = debounce((value, setFieldValue) => {
    setSelectedFormatFound(false);
    setSelectedFormat(null);
    setLookupComplete(true);

    let formatUsed = '';
    let batchType = '';

    const monthFormats = ['MMM yyyy', 'MMM yy','MMMM yyyy', 'MMMM yy',];

    // Check for months only
    for (const monthFormat of monthFormats) {
        const parsedDate = parse(value, monthFormat, new Date());
        if (isValid(parsedDate)) {
            formatUsed = monthFormat;
            batchType = "Date";
            setShowMonthYearPicker(true);
            break
        }
    }


    if(batchType!="Date"){
        // Check for inputted format
        for (let dateFormat of permittedDateFormats) {
            if (isValidDate(value, dateFormat)) {
                formatUsed = dateFormat;
                batchType = "Date";
                setShowMonthYearPicker(formatUsed === 'MMM yyyy' || formatUsed === 'MMM yy');
                break;
            }
        }

        if(batchType!="Date"){
        if (isValidYearAndJulianDay(value)) {
            formatUsed = "Year / Julian code (YY/JJJ)";
            batchType = "YYjulian";
        }
        else if (isThreeDigitJulianDay(value)) {
            formatUsed = "Julian - Three digits (JJJ)";
            batchType = "Julian";
        }
        else if (isJulianCodeWithPrefix(value)) {
            const prefix = value.match(/^\D+/g)?.[0] || '';
            formatUsed = "Julian - " + prefix + " and three digits (JJJ)";
            batchType = "JulianPrefix";
        }
        else if (isNumeric(value)) {
            formatUsed = "Numeric";
            batchType = "Numeric";
        }
        else if (isNumericWithPrefix(value)) {
            formatUsed = "Prefix with numbers";
            batchType = "NumericPrefix";
        }
        else{
            formatUsed = "Custom";
            batchType = "Custom";
        }
    }
    }

    //Set batch format
    if (formatUsed) {
        setFieldValue("format",formatUsed)
        setFieldValue("batchType",batchType)
        setBatchType(batchType);
        setSelectedFormat(formatUsed);
        setSelectedFormatFound(true);
    } else {
        setFieldValue("format","Custom")
        setFieldValue("batchType","Custom")
        setSelectedFormat("Custom");
        setBatchType("Custom");
    }
    setProcessing(false);
}, 1000);


const validationSchema = Yup.object().shape({
        batchType: Yup.string().required('Batch type is required'),
        //Validate - Dates
        dateBatchFirst: Yup.string()
            .when('batchType', {
                is: 'Date',
                then: Yup.string().required('Date is required').test(
                    'is-valid-date',
                    'Invalid date format',
                    (value) => parseDate(value) !== null
                ),
                otherwise: Yup.string().notRequired(),
        }), 
        dateBatchLast: Yup.string()
        .when(['batchType', 'format', 'dateBatchFirst'], (batchType, dateFormat, dateBatchFirst, schema) => {
            return batchType === 'Date' ? schema.required('Date is required').test(
                'is-appropriate-date-range',
                'Must be later than the first date and within 30 days',
                function(value) {
                    const firstDate = parseDate(dateBatchFirst, dateFormat);
                    const lastDate = parseDate(value, dateFormat);

                    if (!firstDate || !lastDate || !isAfter(lastDate, firstDate)) {
                        return false;
                    }

                    if (['MMM yyyy', 'MMM yy'].includes(dateFormat)) {
                        // Check if lastDate is within one month from firstDate
                        const oneMonthAfterFirstDate = addMonths(firstDate, 1);
                        return isAfter(oneMonthAfterFirstDate, lastDate) || 
                               oneMonthAfterFirstDate.toDateString() === lastDate.toDateString();
                    } else {
                        // Check if lastDate is within 30 days from firstDate
                        const thirtyDaysAfterFirstDate = addDays(firstDate, 30);
                        return isAfter(thirtyDaysAfterFirstDate, lastDate) || 
                               thirtyDaysAfterFirstDate.toDateString() === lastDate.toDateString();
                    }
                }
            ) : schema.notRequired();
        }),
        //Validate - Number prefix
        numericPrefixBatchFirst: Yup.string()
            .when('batchType', {
                is: 'NumericPrefix',
                then: Yup.string().required('Value is required').matches(/^\D*\d+$/, 'Must contain a number with any text prefix'),
                otherwise: Yup.string().notRequired(),
        }),
        numericPrefixBatchLast: Yup.string()
        .when('batchType', {
            is: 'NumericPrefix',
            then: Yup.string().required('Value is required').test(
                'is-valid-and-within-range',
                'Must be a greater number than the first value and within 30 numbers',
                function(value) {
                    // Check if 'value' and 'numericPrefixBatchFirst' are strings
                    if (typeof value !== 'string' || typeof this.parent.numericPrefixBatchFirst !== 'string') {
                        return this.createError({ message: 'Invalid input: Value must be a string' });
                    }
    
                    const firstNumMatch = this.parent.numericPrefixBatchFirst.match(/\d+/);
                    const lastNumMatch = value.match(/\d+/);
                    // Ensures both values contain numbers after confirming both are strings
                    if (!firstNumMatch || !lastNumMatch) {
                        return this.createError({ message: 'Both values must contain a numeric part' });
                    }
    
                    const firstNum = parseInt(firstNumMatch[0], 10);
                    const lastNum = parseInt(lastNumMatch[0], 10);
    
                    // Check if lastNum is greater than firstNum and within 30 numbers
                    if (!(lastNum > firstNum && lastNum <= firstNum + 30)) {
                        return this.createError({ message: 'Must be a greater number than the first value and within 30 numbers' });
                    }
    
                    return true; // If all checks pass, return true
                }
            ),
            otherwise: Yup.string().notRequired(),
        }),
        //Validate - Numbers
        numericBatchFirst: Yup.number()
            .when('batchType', {
                is: 'Numeric',
                then: Yup.number().required('Number is required'),
                otherwise: Yup.number().notRequired(),
        }),
        numericBatchLast: Yup.number()
            .when('batchType', {
                is: 'Numeric',
                then: Yup.number()
                    .required('Number is required')
                    .moreThan(
                        Yup.ref('numericBatchFirst'),
                        'Must be greater than the first number'
                    )
                    .test(
                        'max-distance',
                        'Must be within 30 numbers of the first number',
                        function(value) {
                            const { numericBatchFirst } = this.parent;
                            return numericBatchFirst == null || value == null || (value - numericBatchFirst) <= 30;
                        }
                    ),
                otherwise: Yup.number().notRequired(),
        }),
        customBatchFirst: Yup.string()
            .when('batchType', {
                is: 'Custom',
                then: Yup.string().required('Custom batch first value is required'),
                otherwise: Yup.string().notRequired(),
            }),
        customBatchLast: Yup.string()
            .when('batchType', {
                is: 'Custom',
                then: Yup.string().required('Custom batch last value is required'),
                otherwise: Yup.string().notRequired(),
        }),
        julianBatchFirst: Yup.string()
        .when('batchType', {
            is: 'Julian',
            then: Yup.string().matches(/^(00[1-9]|0[1-9][0-9]|[1-9][0-9][0-9]|[1-2][0-9]{2}|3[0-5][0-9]|36[0-6])$/, 'Must be a 3 digit number between 001 and 366'),
            otherwise: Yup.string().notRequired(),
        }),                
        julianBatchLast: Yup.string()
            .when('batchType', {
                is: 'Julian',
                then: Yup.string().test(
                    'is-within-30-days-of-julianBatchFirst',
                    'Must be within 30 days of first batch number',
                    function(value) {
                        const firstValue = parseInt(this.parent.julianBatchFirst, 10);
                        const lastValue = parseInt(value, 10);
                        return lastValue > firstValue && lastValue <= firstValue + 30;
                    }
                ),
                otherwise: Yup.string().notRequired(),
        }),
        julianPrefixBatchFirst: Yup.string()
            .when('batchType', {
                is: 'JulianPrefix',
                then: Yup.string().required('First value is required'),
                otherwise: Yup.string().notRequired(),
            }),
        julianPrefixBatchLast: Yup.string()
            .when('batchType', {
                is: 'JulianPrefix',
                then: Yup.string().required('Last value is required').test(
                    'is-valid-julian-and-within-range',
                    'Must be within 30 days of the first value',
                    function(value) {
                        // Safely extract Julian date from a string, return null if input is undefined or pattern doesn't match
                        const extractJulianDate = (str) => {
                            if (typeof str !== 'string') return null; // Handle undefined or non-string inputs
                            const match = str.match(/\d+$/); // Assumes Julian date is at the end of the string
                            return match ? parseInt(match[0], 10) : null;
                        };
        
                        const firstJulianDate = extractJulianDate(this.parent.julianPrefixBatchFirst);
                        const lastJulianDate = extractJulianDate(value);
        
                        // Check if either extracted date is null (indicating an undefined input or no match)
                        if (firstJulianDate === null || lastJulianDate === null) {
                            // Using createError to specify the error condition explicitly
                            return this.createError({ message: 'Invalid Julian date format' });
                        }
        
                        const isWithinRange = lastJulianDate > firstJulianDate && lastJulianDate <= firstJulianDate + 30;
        
                        return isWithinRange || this.createError({ message: 'Must be within 30 days of the first value' });
                    }
                ),
                otherwise: Yup.string().notRequired(),
            }),
            // yyJulianBatchFirst: Yup.string()
            // .when('batchType', {
            //     is: 'YYjulian',
            //     then: Yup.string().matches(/^\d{2}\/(00[1-9]|0[1-9][0-9]|[1-9][0-9][0-9]|[1-2][0-9]{2}|3[0-5][0-9]|36[0-6])$/, 'Must be in format YY/DDD where DDD is a number between 001 and 366'),
            //     otherwise: Yup.string().notRequired(),
            // }),
        
            // yyJulianBatchLast: Yup.string()
            //     .when('batchType', {
            //         is: 'YYjulian',
            //         then: Yup.string().test(
            //             'is-within-30-days-of-yyJulianBatchFirst',
            //             'Must have a Julian number within 30 days of first batch number',
            //             function(value) {
            //                 const extractJulianDay = (str) => {
            //                     const match = str.match(/^\d{2}\/(\d{3})$/);
            //                     return match ? parseInt(match[1], 10) : null;
            //                 };
            
            //                 const firstNum = extractJulianDay(this.parent.yyJulianBatchFirst);
            //                 const lastNum = extractJulianDay(value);
            
            //                 if (firstNum === null || lastNum === null) {
            //                     return false; // Invalid format
            //                 }
            
            //                 return lastNum >= firstNum && lastNum <= firstNum + 30;
            //             }
            //         ),
            //         otherwise: Yup.string().notRequired(),
            //     }),
        
        //     julianPrefixBatchFirst: Yup.string()
        //     .when('batchType', {
        //         is: 'JulianPrefix',
        //         then: Yup.string().matches(/^\D*(00[1-9]|0[1-9][0-9]|[1-9][0-9][0-9]|[1-2][0-9]{2}|3[0-5][0-9]|36[0-6])$/, 'Must have a prefix followed by a 3 digit number between 001 and 366'),
        //         otherwise: Yup.string().notRequired(),
        //     }),
                                
        // julianPrefixBatchLast: Yup.string()
        //     .when('batchType', {
        //         is: 'JulianPrefix',
        //         then: Yup.string().test(
        //             'is-within-30-days-of-julianBatchFirst',
        //             'Must have a Julian number within 30 days of first batch number',
        //             function(value) {
        //                 const extractJulianDay = (str) => {
        //                 const match = str.match(/^\D*(\d{3})$/);
        //                 return match ? parseInt(match[1], 10) : null;
        //             };
        //                 const firstNum = extractJulianDay(this.parent.julianBatchFirst);
        //                 const lastNum = extractJulianDay(value);
    
        //                 if (firstNum === null || lastNum === null) {
        //                     return false; // Invalid format
        //                 }
    
        //                 return lastNum >= firstNum && lastNum <= firstNum + 30;
        //             }
        //         ),
        //         otherwise: Yup.string().notRequired(),
        //     }),
        
        
})
        
  
  return (
    <>
        {/* Button - Open Modal */}
        {!hasBatchRange &&
            <Button 
                variant="primary" 
                size={"sm"}
                className="d-inline mt-2 me-3 d-block" 
                onClick={() => setShowModal(true)}
            >
                <FontAwesomeIcon icon={faPlus}/>&nbsp;Add batch range
            </Button>
        }

        {/* Button - Change Modal */}
        {hasBatchRange &&
        <Button 
            variant="primary" 
            size={"sm"}
            className="d-inline mt-2 me-3 d-block" 
            onClick={() => setShowModal(true)}
        >
            <FontAwesomeIcon icon={faEdit}/>&nbsp;Change batch range
        </Button>
        }

        {/* Modal - Batch Range  */} 
        <Modal size="md" as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal}>
            <Modal.Header className="px-5">
                <Modal.Title className="h5 mt-2">
                    <span>Add Batch Number Range</span>
                </Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
            </Modal.Header>
            <Modal.Body  className="px-5">
                <Formik 
                    initialValues={{ 
                        batchType: '',
                        format: '',
                        customBatchFirst : '', 
                        customBatchLast: '', 
                        dateBatchFirst: '' , 
                        dateBatchLast: ''  ,
                        julianBatchFirst: ''  ,
                        julianBatchLast: ''  ,
                        yyJulianBatchFirst: ''  ,
                        yyJulianBatchLast: ''  ,
                        julianPrefixBatchFirst: ''  ,
                        julianPrefixBatchLast: ''  ,
                        numericPrefixBatchFirst: ''  ,
                        numericPrefixBatchLast: '',
                        numericBatchFirst: ''  ,
                        numericBatchLast: ''  
                    }} 
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                {(formik) => (
                <Form>
                    <Row className="my-3">
                        <Col md={12}>
                            {/* Format lookup */}
                            <Row>
                                <Col md={8}>
                                    <FormController
                                        control="input"
                                        label={"Start typing the first batch number"}
                                        name={"findFormat"}
                                        onChange={(e) => handleFindFormatChange(e,formik.setFieldValue)}
                                        className="mb-1"
                                    />
                                </Col>
                                <Col md={1}className="pt-2">
                                    {processing &&
                                        <Spinner animation="border" variant="primary"  size="sm" className="me-1 mt-5" />
                                    }
                                </Col>
                            </Row>
                            {/* Completed lookup */}
                            {lookupComplete == true && 
                                formatFound == false && 
                                (<>
                                    {/* No batch format found */}
                                    <p>We couldn't find the format of your batch number amongst common formats. Type these manually in the fields.</p>
                                    <div className="d-block text-italic text-gray fs-7 mb-3">Note that custom batch ranges are not searchable and need to be typed in manually on daily attestations</div>
                                    
                                    <BatchTypeCustom
                                        batchRangeFromValue={formik.values.customBatchFirst} 
                                        batchRangeToValue={formik.values.customBatchLast} 
                                        errors={formik.errors}
                                    />
                                </>
                                )}
                                {formatFound == true && 
                                (
                                <>
                                    {/* Batch format found */}
                                    <p className="text-italic">We detected the batch range format is <span className="fw-bold text-success">{selectedFormat}</span></p>

                                    {lookupComplete &&
                                        <Button onClick={()=>handleCustomChange(formik.setFieldValue)} variant="link" size="sm" className="d-block mb-2 p-0">Not the correct format? Add custom range instead.</Button>
                                    }

                                    {/* Date - Batch range entry */}
                                    {formik.values.batchType =='Date' && (
                                        <BatchTypeDate
                                            batchRangeFromName="dateBatchFirst"
                                            batchRangeFromValue={formik.values.dateBatchFirst} 
                                            batchRangeToName="dateBatchLast"
                                            batchRangeToValue={formik.values.dateBatchLast} 
                                            showMonthYearPicker={showMonthYearPicker}
                                            dateFormat={selectedFormat}
                                            errors={formik.errors}
                                        />
                                    )}

                                    {/* Julian code - Batch range entry */}
                                    {formik.values.batchType =='Julian' && (
                                        <BatchType
                                            batchRangeFromName="julianBatchFirst"
                                            batchRangeFromValue={formik.values.julianBatchFirst} 
                                            batchRangeToName="julianBatchLast"
                                            batchRangeToValue={formik.values.julianBatchLast} 
                                            errors={formik.errors}
                                        />
                                    )}

                                    {/* Julian code - Batch range entry */}
                                    {formik.values.batchType =='YYjulian' && (
                                        <BatchType
                                            batchRangeFromName="yyJulianBatchFirst"
                                            batchRangeFromValue={formik.values.yyJulianBatchFirst} 
                                            batchRangeToName="yyJulianBatchLast"
                                            batchRangeToValue={formik.values.yyJulianBatchLast} 
                                            errors={formik.errors}
                                        />
                                    )}


                                    {/* Julian Prefix  - Batch range entry */}
                                    {formik.values.batchType =='JulianPrefix' && (
                                        <BatchType
                                            batchRangeFromName="julianPrefixBatchFirst"
                                            batchRangeFromValue={formik.values.julianPrefixBatchFirst} 
                                            batchRangeToName="julianPrefixBatchLast"
                                            batchRangeToValue={formik.values.julianPrefixBatchLast} 
                                            errors={formik.errors}
                                        />
                                    )}

                                    {/* Numeric code range entry */}
                                    {formik.values.batchType =='Numeric' && (
                                        <BatchType
                                            batchRangeFromName="numericBatchFirst"
                                            batchRangeFromValue={formik.values.numericBatchFirst} 
                                            batchRangeToName="numericBatchLast"
                                            batchRangeToValue={formik.values.numericBatchLast}
                                            errors={formik.errors}
                                        />
                                    )}

                                    {/* Numeric code range entry */}
                                    {formik.values.batchType =='NumericPrefix' && (
                                        <BatchType
                                            batchRangeFromName="numericPrefixBatchFirst"
                                            batchRangeFromValue={formik.values.numericPrefixBatchFirst} 
                                            batchRangeToName="numericPrefixBatchLast"
                                            batchRangeToValue={formik.values.numericPrefixBatchLast} 
                                            errors={formik.errors}
                                        />
                                    )}
                                    {/* Custom range entry */}
                                    {formik.values.batchType =='Custom' && (
                                        <BatchTypeCustom
                                            batchRangeFromValue={formik.values.customBatchFirst} 
                                            batchRangeToValue={formik.values.customBatchLast} 
                                            errors={formik.errors}
                                        />
                                    )}
                                </>)
                            }

                            <div className='mt-5'>
                                <Button onClick={handleCloseModal} variant="light" className="d-inline me-2">Cancel</Button>
                                <Button type="submit" disabled={!lookupComplete} className="d-inline">
                                    
                                {isSubmitting ? 
                                    <Spinner animation="border" size="sm" className="me-2" />
                                    :
                                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                                    }
                                    Confirm batch range
                                </Button>
                            </div>
                        </Col>
                    </Row>
        </Form>
        )}
                </Formik>
            </Modal.Body>
        </Modal>
    </>
  );
};

export default AttestationBatchRange;


//Batch range entry for numeric / julian / custom formats
const BatchType = (props) => {
    const { 
        batchRangeFromName, 
        batchRangeFromValue, 
        batchRangeToName,
        batchRangeToValue,
        errors
    } = props;
    return (
        <Row>
            <Col md={6}>
                <FormController
                    control="input"
                    label="First batch number"
                    name={batchRangeFromName}
                    className="mb-1"
                />
            </Col>
            <Col md={6}>
                <FormController
                    control="input"
                    label="Last batch number"
                    name={batchRangeToName}
                    className="mb-1"
                />
            </Col>
            {batchRangeToValue && batchRangeFromValue &&  errors.length <=0 && (
                <Col md={12}>
                    <div className="mt-4 fw-bold">
                        Batch numbers: {batchRangeFromValue} to {batchRangeToValue}
                    </div>
                </Col>
            )}
        </Row>
    )
}

//Batch range entry for date formats
const BatchTypeDate = (props) => {
    const { 
        batchRangeFromName, 
        batchRangeFromValue, 
        batchRangeToName,
        batchRangeToValue,
        dateFormat,
        showMonthYearPicker,
        errors
    } = props;
    return (
        <Row>
            <Col md={6}>
                <FormController
                    control="dateInput"
                    label="First batch date"
                    name={batchRangeFromName}
                    className="mb-1"
                    dateFormat={dateFormat}
                    showMonthYearPicker={showMonthYearPicker} //jan 2023 / jan 23 etc
                />
            </Col>
            <Col md={6}>
                <FormController
                    control="dateInput"
                    label="Last batch date"
                    name={batchRangeToName}
                    className="mb-1"
                    dateFormat={dateFormat}
                    showMonthYearPicker={showMonthYearPicker} //jan 2023 / jan 23 etc
                />
            </Col>
            {batchRangeToValue && batchRangeFromValue &&  errors.length <=0 && (
                <Col md={12}>
                    <div className="mt-4 fw-bold">
                        Batch numbers: {format(batchRangeFromValue, dateFormat)} to {format(batchRangeToValue, dateFormat)}
                    </div>
                </Col>
            )}
        </Row>
    )
}
//Batch range entry for date formats
const BatchTypeCustom = (props) => {
    const { 
        batchRangeFromValue,
        batchRangeToValue,
        errors
    } = props;
    return (
        <Row>
            <Col md={6}>
                <FormController
                    control="input"
                    label="First batch number (custom)"
                    name="customBatchFirst"
                    className="mb-1"
                />
            </Col>
            <Col md={6}>
                <FormController
                    control="input"
                    label="Last batch number (custom)"
                    name="customBatchLast"
                />
            </Col>
            {batchRangeToValue && batchRangeFromValue &&  errors.length <=0 && (
                <Col md={12}>
                    <div className="mt-4 fw-bold">
                        Custom batch range: {batchRangeFromValue} to {batchRangeToValue}
                    </div>
                </Col>
            )}
        </Row>
    )
}