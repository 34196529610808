import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Button, Row, Col, Card, Modal, Toast } from '@themesberg/react-bootstrap';
import moment from "moment-timezone";
import CertificateBankService from '../../services/CertificateBankService';
import BlobService from '../../services/BlobService';
import CertificateAssignedService from '../../services/CertificateAssignedService';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const CertificateViewPage = (props) => {
    const history = useHistory();
    const [id] = useState(props.match.params.id);
    const [title] = useState("View Certificate");
    const [certificateName, setCertificateName] = useState('');
    const [apiValues, setApiValues] = useState({});
    const [certificateAssigned, setCertificateAssigned] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const openBlobPdf = (filename) => {
        BlobService.openBlobPdf(filename);
    }

    useEffect(() => {
        CertificateBankService.getById(id)
            .then(response => {
                setCertificateName(response.data?.certificateName);
                setApiValues(response.data);
            })
            .catch(e => {
                console.log(e);
            });

        CertificateAssignedService.getByCertificateId(id)
            .then(response => {
                setCertificateAssigned(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }, [id]);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const _handleRemove = (id) => {
        CertificateBankService.remove(id)
            .then(response => {
                setShowToast(true);
                setTimeout(() => {
                    history.push(Routes.CertificateListingPage.path);
                }, 2000);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const confirmRemove = () => {
        handleCloseModal();
        _handleRemove(id);
    };

    const breadcrumbData = {
        item1: { name: 'Certificates', link: Routes.CertificateListingPage.path },
        item2: { name: certificateName, link: '#' }
    };

    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>
            <Row className="">
                <Col md={12} xl={12}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={12} sm={6} lg={10}>
                                    <ul className="list-unstyled">
                                        <li>
                                            <p>
                                                <small className="d-block">Certificate Name:</small>
                                                <label>{apiValues.certificateName !== null && apiValues.certificateName !== "" ? (apiValues.certificateName) : ("N/A")}</label>
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <small className="d-block">Certificate Number:</small>
                                                <label>{apiValues?.certificateNumber !== null && apiValues?.certificateNumber !== "" ? (apiValues?.certificateNumber) : ("N/A")}</label>
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <small className="d-block">Status</small>
                                                <label>{apiValues.active == 1 ? "Used" : ("Available")}</label>
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <small className="d-block">Created on {moment(apiValues.createdDate).format("Do MMM YYYY")}</small>
                                            </p>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xs={12} sm={12} lg={2} className="text-end">
                                    <div className="btn-panel right">
                                        <Button variant="danger" onClick={handleShowModal}>
                                            Remove
                                            <FontAwesomeIcon icon={faTrash} className="dropdown-item-icon" />
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                {apiValues !== null &&
                    <Col md={6} className="my-3">
                        <Card border="light" className="shadow-sm">
                            <Card.Header className=" d-flex justify-content-between">
                                <h5 className="mb-0">Original Certificate</h5>
                            </Card.Header>
                            <Card.Body>
                                <span>View the original uploaded certificate PDF.</span>
                                <div>
                                    <div className="d-flex align-items-start pt-3 me-5">
                                        <div className="d-block">
                                            <Button variant="secondary" onClick={() => openBlobPdf(apiValues.filename)}>
                                                View Original Certificate
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                }
                {certificateAssigned != "" &&
                    <Col md={8} className="my-3">
                        <Card border="light" className="shadow-sm">
                            <Card.Header className=" d-flex justify-content-between">
                                <h5 className="mb-0">Assigned Certificate</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={12}>
                                        <span>View the assigned certificate PDF.</span>
                                        <div>
                                            <div className="d-flex align-items-start pt-3 me-5">
                                                <div className="d-block">
                                                    <Button variant="primary" onClick={() => { openBlobPdf(certificateAssigned[0]?.document?.filename) }}>
                                                        View Assigned Certificate
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12} className="mt-5">
                                        <span>View assigned consignment Name: ({certificateAssigned[0]?.consignmentContents?.consignment?.consignmentName})</span>
                                        <div>
                                            <div className="d-flex align-items-start pt-3 me-5">
                                                <div className="d-block">
                                                    <Button variant="light" href={Routes.ConsignmentContentsPage.path.replace(":id", certificateAssigned[0]?.consignmentContents?.consignment?.id)}>
                                                        View Consignment
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                }
            </Row>
            <Row className="">
                <Col>
                    <div className="btn-panel left">
                        <Button variant="light" href={Routes.CertificateListingPage.path}>
                            Back to Certificates
                        </Button>
                    </div>
                </Col>
            </Row>

            {/* //Confirmation Modal */}
            <Modal size="md" as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title className="h6">Are you sure you want to remove?</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-end">
                        <Button variant="secondary" onClick={handleCloseModal} className="me-2">Cancel</Button>
                        <Button variant="danger" onClick={confirmRemove}>Remove</Button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Success Toast */}
            <div className="toast-container">
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide className={`toast-${document.documentElement.classList.contains('theme-dark') ? 'dark' : 'light'}`}>
                    <Toast.Header className={`toast-header-${document.documentElement.classList.contains('theme-dark') ? 'dark' : 'light'}`}>
                        <strong className="me-auto">Success</strong>
                    </Toast.Header>
                    <Toast.Body className={`toast-body-${document.documentElement.classList.contains('theme-dark') ? 'dark' : 'light'}`}>You have removed: {certificateName}</Toast.Body>
                </Toast>
            </div>
        </div>
    );
}
export default CertificateViewPage;