import React  from 'react';
import {Card, Col, Row, Image } from "@themesberg/react-bootstrap";
 import * as AttestationContent from "../../../Common/AttestationContent";
import { useFormikContext } from 'formik';
import * as Composite8350AttestationLabels from "../../../../Products/Composite/CompositeAttestation/Composite8350AttestationLabels";
import Logo from "../../../../../../assets/img/logo.PNG";

export default function ResponseAttestationTemplate(props) {
    
  const { values: formValues } = useFormikContext();
  const {
    user,
    values,
    forwardedRef,
    uniqueRef,
    product, 
    manufacturer,
    setFieldValue,
    site, 
    speciesList, 
    attestationData,
  } = props;

  return (
    <Card ref={forwardedRef} border="light" className="d-none d-print-blockbg-white shadow-sm mb-4 p-3">
        <Card.Body>
            <Image src={Logo} className="no-print noprint mt-4 mb-5" width="200" />
            <div className="attestation-title text-center">
                <h2>Section II Veterinary Declaration</h2>
                <p>Based on Supplier Declaration:<br />Unique Reference Number: {uniqueRef}</p>
            </div>
            <AttestationContent.VetDeclaration
                isReadOnly={true}
                uniqueRef={uniqueRef}
                firstInspection={formValues.firstInspection}
                changes={formValues.changes}
                changeStatement={formValues.changeStatement}
                changesDescription={formValues.changesDescription}
                site={site}
            />
                    
            {!formValues.isGEFS&&(
                <>
                    <h4 className="mt-3">Information from Part I</h4>
                    <AttestationContent.SectionAProductInfo
                        product={product}
                    />
                </>
            )}
    
            <Row className="mt-3">
                <Col md={12}>
                   
                    <AttestationContent.AttestationResponseVetInspection
                        isReadOnly={true}
                        site={site}
                    />
                    
                    <AttestationContent.VetAuthorisedBy 
                        user={user}  
                        manufacturer={manufacturer}  
                        hasSignature={true}
                    />
                </Col>
            </Row>
          
        </Card.Body>
    </Card>
  );
}
