import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { Routes } from "../../../../routes";
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Formik, Form, useFormikContext} from 'formik';
import FormSubmission from "../../common/FormSubmission";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave,faPaintRoller, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col,Row, Button, Alert, Card, Modal, Container} from '@themesberg/react-bootstrap';
import ConsignmentContentsService from '../../../services/ConsignmentContentsService';
import ConsignmentService from '../../../services/ConsignmentService';
import * as Yup from 'yup';
import ConsignmentSummary from "../ConsignmentSummary"
import ConsignmentCertificatePanel from "./ConsignmentContentsCertificatePanel"
import ConsignmentContentsTable from "./ConsignmentContentsTable"
import Preloader from '../../../../components/Preloader';
import ConsignmentContentsSelectModal from "./ConsignmentContentsSelectModal"
import { SaveConsignmentContents } from './Functions/ContentsFunctions';
import PermissionCheck from '../../../../auth/PermissionCheck';
import EhcGenerateService from '../../../services/EhcGenerateService';

const ConsignmentContentsPage = (props) => {
    const guid = uuid();
    const [isApproved, setApproved] = useState(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [combineMasterId, setCombineMasterId] = useState(null);
    const [combineSelections, setCombineSelections] = useState([]);
    const [combineSavedSelections, setCombineSavedSelections] = useState([]);
    const [combineUniqueSelections, setCombineUniqueSelections] = useState([]);
    const [combineCertificateNumber, setCombineCertificateNumber] = useState('')
    const [languageCode, setLanguageCode] = useState('')
    const [consignmentName, setConsignmentName] = useState('');
    const [consignment, setConsignment] = useState([]);
    const [consignmentId] = useState(props.match.params.id);
    const [isComplete, setComplete] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [noMatchesFound, setNoMatchesFound] = useState(false);
    const [editing, setEditing] = useState(false);
    const [ready, SetReady] = useState(false);
    const [isFormCompleted, setFormCompleted] = useState(false);
    const [isFormChanged, setFormChanged] = useState(false);
    const [isGenerated, setGenerated] = useState(false);
    const [isGenerateReady, setGenerateReady] = useState(false);
    const [isCombineMode, setCombineMode] = useState(false);
    const [combinePreviousSelections, setCombinePreviousSelections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [apiDailyAttestationValues, setApiDailyAttestationValues] = useState([]);
    const [apiNonGEFSValues, setApiNonGEFSValues] = useState([]);
    const [errors, setErrors] = useState(null);
    const [title, setTitle] = useState(consignmentName);
    const formRef = useRef();

    const breadcrumbData = {
        item1 : {name:'Consignments', link: Routes.ConsignmentListingPage.path},
        item2 : {name: consignmentName, link: '#'}
    };

    //Form values
    const initialValues = {
        showStorageDeclaration: false,
        showNonGEFS: false,
        contentsSelected: apiDailyAttestationValues?? [],
        nonGEFSSelected: apiNonGEFSValues?? [],
        combineSelections: []
    }

    //Validation
    let validationMaxLength = "Exceeded maximum length allowed for this field";
    const validationSchema = Yup.object().shape({
        contentsSelected: Yup.string().required("Must enter products").max(100, validationMaxLength)
    });

    //Modal - Close - Daily Attestations
    const handleCloseModal = (data) => {
        setShowModalAdd(false);
        setApiDailyAttestationValues(data);
    }

    //Save Combinations
    function _handleSaveCombined(e) {
        e.preventDefault()
        if(hasMinimumCombinations){
        setCombineSavedSelections(combineSelections);
       // setLoading(true)
        setCombineMode(false);
        if (formRef.current) {
          formRef.current.handleSubmit()
        }
            setCombinePreviousSelections([]);
            setCombineCertificateNumber(0);
            setCombineMasterId(null);
            setGenerateReady(true);
            setLoading(false);
            setCombineUniqueSelections([]);
        }
       // _handleExitCombineCertificateMode();
    }
     //RollBack Combinations
const _handleRollBack = async () => {
    for (const item of apiDailyAttestationValues) {
        const id = item.id;
        try {
            // Sequential fetch requests to populate certificates
            const response = await EhcGenerateService.deleteGeneratedCertificates(id);
            const result = await response.data;
            console.log("Rollback successful for ID:", id);
        } catch (error) {
            console.error("Error during rollback for ID:", id, error);
            // Handle the error appropriately
        }
    }
    window.location.reload();
};

    //Submit
    async function onSubmit(values, { setSubmitting, resetForm }) {

        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        
        //Submit data to services
        try{
            //Main function to generate certs
            SaveConsignmentContents(values, combineSelections, consignmentId);
        
            //Set form state
            setFormSubmitted(true);
            setSubmitting(false);
            setFormChanged(false);
        }
        catch(e){
            console.log("error");
            console.log(e);
            setFormSubmitted(true);
            setSubmitting(false);
            setFormChanged(false);
        }
    }
    

    //Alert for Save Changes update
    useEffect(() => {
        if(formSubmitted){
            setLoading(true)
            setEditing(true);
            setTimeout(() => {
                fetchListData();
                setFormSubmitted(false);
              }, 3000);
        }
    }, [formSubmitted]);

    //Get Consignments and contents data
    async function fetchListData() {
        setCombineSavedSelections([]);
        setCombineSelections([]);
        try {
            setLoading(true)
            const getConsignmentData = new Promise((resolve, reject) => {
                resolve(ConsignmentService.getById(consignmentId)
                    .then((response) => {
                        setConsignment(response.data);
                        setConsignmentName(response.data.consignmentName);
                        setLanguageCode(response.data.certificateLanguage?.code);
                        setTitle(response.data.consignmentName);
                        setApproved(response.data.approved);
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        setLoading(false);
                    })
                );
            });

            const getConsignmentContentsData = new Promise((resolve, reject) => {
                resolve(ConsignmentContentsService.getAllWithAttestationsByConsignmentId(consignmentId)
                    .then(contentsResponse => {
                        var list = [];
                        contentsResponse.data.forEach(x=> {
                            var item = {};
                            console.info(x);
                            if(x.isStorageDeclaration){
                                //Storage Declaration model
                                item = {
                                    id:  x.id,
                                    isGEFS: x.isGEFS,
                                    isStorageDeclaration: x.isStorageDeclaration,
                                    isCompound: x.isCompound,
                                    compound: x.compound,
                                    commodityCode: x.commodityCode,
                                    netWeight: x.netWeight,
                                    grossWeight: x.grossWeight,
                                    productName: x.relatedStorageDeclaration[0]?.product?.productName,
                                    dailyAttestationId:  x.relatedStorageDeclaration[0]?.id,
                                    uniqueCode: x.relatedStorageDeclaration[0]?.uniqueCode,
                                    productJson: x.contentsStorage[0]?.storageExporterDeclaration?.storageSupplierDeclaration?.productJson,
                                    manufacturerName: x.relatedStorageDeclaration[0]?.manufacturer?.manufacturerName,
                                    deliveryDate: null,
                                    numberOfPackages: x.numberOfPackages ?? x.contentsStorage[0]?.storageExporterDeclaration?.storageSupplierDeclaration?.numberPackages,
                                    dailyAttestationItems: x.relatedStorageDeclaration[0],
                                    compound: x.compound,
                                    attestation:  x.relatedStorageDeclaration[0].storageExporterDeclaration,
                                    certificates: x.certificates,
                                    certificateNumbers: [x.relatedStorageDeclaration[0]?.certificateNumber?.number.toString()],
                                    documents: x.documents,
                                    consignmentContentsId: x.id,
                                    dateCollectionProduction: null,
                                    combinations: x.consignmentContentsCombinations,
                                    certificateFilename: x.certificateFilename
                                };
                                
                            }
                            else{
                                //Main model which is used for all contents items
                                item = {
                                    id:  x.id,
                                    isGEFS: x.isGEFS,
                                    isStorageDeclaration: x.isStorageDeclaration,
                                    isCompound: x.isCompound,
                                    compound: x.compound,
                                    commodityCode: x.commodityCode != null ? x.commodityCode : JSON.parse(x.relatedAttestation[0]?.attestationDetails?.productJson).product?.productDetails?.commodityHscode,
                                    netWeightTotal: x.netWeightTotal != null ? x.netWeightTotal :   x.isGEFS == true ? JSON.parse(x.relatedAttestation[0]?.attestationDetails?.productJson).product?.productDetails?.netWeight.toFixed(3) :  JSON.parse(x.relatedAttestation[0]?.attestationDetails?.productJson).product?.productDetails?.netWeight.toFixed(3),
                                    grossWeightTotal: x.grossWeightTotal != null ? x.grossWeightTotal :   x.isGEFS == true ? JSON.parse(x.relatedAttestation[0]?.attestationDetails?.productJson).product?.productDetails?.grossWeight.toFixed(3) :  JSON.parse(x.relatedAttestation[0]?.attestationDetails?.productJson).product?.productDetails?.grossWeight.toFixed(3),
                                    productName: JSON.parse(x.relatedAttestation[0]?.attestationDetails?.productJson).product?.productName,
                                    dailyAttestationId:  x.isGEFS == true ? x.contentsGEFS[0]?.dailyAttestationId : x.contentsNonGEFS[0]?.attestation?.id,
                                    uniqueCode: x.relatedAttestation[0]?.attestationDetails?.uniqueCode,
                                    productJson:  x.isGEFS == true ? x.contentsGEFS[0]?.dailyAttestationItem?.attestation?.attestationDetails?.productJson : x.contentsNonGEFS[0]?.attestation?.attestationDetails?.productJson,
                                    manufacturerName:  x.relatedAttestation[0]?.manufacturer?.manufacturerName,
                                    deliveryDate: x.deliveryDate,
                                    numberOfPackages: x.numberOfPackages != null ? x.numberOfPackages :  x.isGEFS == true ? x.contentsGEFS[0]?.dailyAttestationItem?.numberOfPackages : x.contentsNonGEFS[0]?.attestation?.numberOfPackages,
                                    dailyAttestationItems:  x.isGEFS == true ? x.contentsGEFS[0]?.dailyAttestationItem : x.contentsNonGEFS[0]?.attestation,
                                    compound: x.compound,
                                    attestation:  x.isGEFS == true ? x.contentsGEFS[0]?.dailyAttestationItem?.attestation : x.contentsNonGEFS[0]?.attestation,
                                    certificates: x.certificates,
                                    certificateNumbers:  x.relatedAttestation[0]?.certificateNumberList,
                                    documents: x.documents,
                                    consignmentContentsId: x.id,
                                    dateCollectionProduction: null,
                                    combinations: x.consignmentContentsCombinations,
                                    certificateFilename: x.certificateFilename
                                };
                            }
                            list.push(item);
                            if( x.consignmentContentsCombinations?.length > 0 ){
                                setCombineSavedSelections(current => [...current, x.consignmentContentsCombinations[0]]);
                                setCombineSelections(current => [...current, x.consignmentContentsCombinations[0]]);
                            }
                        });
                        if(list?.length>0){
                            setEditing(true);
                            setGenerateReady(true);
                        }
                        setApiDailyAttestationValues(list);
                    })
                );
            });

            Promise.all([ getConsignmentData, getConsignmentContentsData ]).then((responses) => {
            SetReady(true);
            setLoading(false);
        });
        } catch (e) {
            console.error(e);
        }
    };

    //Get Consignment data
    useEffect(() => {
        fetchListData();
    }, []);


    useEffect(() => {
        if(isFormChanged){
            setGenerateReady(false);
        }
    }, [isFormChanged]);


    //Cancel combine Mode
    const _handleExitCombineCertificateMode = () => {
        setCombineSelections(combinePreviousSelections);
        setCombinePreviousSelections([]);
        setCombineCertificateNumber(0);
        setCombineMasterId(null);
        setCombineMode(false);
        setNoMatchesFound(false);
        setCombineUniqueSelections([]);
    }


    //Listener for form value changes
    const ChangeFormValues = () => {
        const { values } = useFormikContext();
            useEffect(() => {
                if(values.contentsSelected?.length>0){
                    if (values.contentsSelected?.filter(x=>x.certificates?.length > 0).length > 0) {
                        setGenerated(true);
                    }
                }
            }, [values.contentsSelected]
            );
        return null;
    };

    const PrettyPrintJson = ({data}) => (<div><pre>{
        JSON.stringify(data, null, 2) }</pre></div>
      );

    const hasMinimumCombinations = () => {
        var count = combineSelections.filter(x=> x.consignmentContentsMasterId == combineMasterId)?.length;
        if(count >= 2){
            return true;
        }
        return false;
    }
    return (
        <div>
        <BreadcrumbNav data={breadcrumbData}/>
        <h2>{title}</h2>
        
        {isFormCompleted ?
            <FormSubmission
                errors={errors}
                title ="Consignment Complete"
                message = "This consignment and related certificates have been succesfully added to the site and now cannot be amended, you can view the completed consignment on the consignments page."
                successButtonUrl = {Routes.ConsignmentListingPage.path}
                successButtonName = "Back to Consignments"
            />
        :
            <>
            <Formik 
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                //  validationSchema={validationSchema}
                    enableReinitialize={true}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                {(props, isSubmitting, setFieldValue) => (

                    <Col md={11}>
                        <Form>
                            <div className="row">
                                <div className="col-md-6 d-flex align-items-stretch">
                                    <ConsignmentSummary
                                        isApproved={isApproved}
                                        consignmentId={consignmentId}
                                        consignment={consignment}
                                    />
                                </div>
                                <div className="col-md-6 d-flex align-items-stretch">
                                    <ConsignmentCertificatePanel
                                        fetchListData={fetchListData}
                                        isApproved={isApproved}
                                        consignmentId={consignmentId}
                                        consignment={consignment}
                                        isGenerated={isGenerated}
                                        isGenerateReady={isGenerateReady}
                                        setFormCompleted={setFormCompleted}
                                        setGenerated={setGenerated}
                                        isComplete={isComplete}
                                        setComplete={setComplete}
                                        contentsSelected={props.values.contentsSelected}
                                        languageCode={languageCode}
                                    />
                                </div>
                            </div>
                            <Row>
                                <Col md={12}>
                                    <Card border="light" className="bg-white shadow-sm mb-4">
                                        <Card.Header>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h4 className="h4 text-title mb-0" id="tabelLabel" >Consignment Contents</h4>
                                                </div>  
                                                
                                                <div className="text-end"> 
                                                    <PermissionCheck errorProps={{ disabled: true }} requiresRole={["admin"]}>
                                                        <div className="d-inline ms-2">
                                                            <Button
                                                                variant="secondary"
                                                                onClick={() => _handleRollBack()}
                                                                size="sm"
                                                                type="button"
                                                            >
                                                                <FontAwesomeIcon icon={faPaintRoller} className="me-2"  size="sm"/>Roll Back
                                                            </Button>
                                                        </div>
                                                    </PermissionCheck>   

                                                    {isCombineMode ?
                                                    <div className="d-inline">
                                                        <div className="d-inline ms-2">
                                                            <Button
                                                                onClick={()=> _handleExitCombineCertificateMode()}
                                                                variant="light"
                                                                type="button"
                                                                disabled={!isFormChanged || props.values.contentsSelected.length <= 0 || isSubmitting}
                                                                size="sm mx-3" >
                                                                    <FontAwesomeIcon icon={faTimes} className="me-2"  size="sm"/>
                                                                {isSubmitting ? "Please wait..." : "Exit Combine Mode"}</Button>
                                                        </div>
                                                        <div className="d-inline ms-2">
                                                            <Button
                                                                variant="secondary"
                                                                onClick={(e)=> _handleSaveCombined(e)}
                                                                disabled={!isFormChanged 
                                                                            || !hasMinimumCombinations()
                                                                            || isSubmitting}
                                                                size="sm"
                                                                type="button"
                                                            >
                                                                <FontAwesomeIcon icon={faSave} className="me-2"  size="sm"/>
                                                                {isSubmitting ? "Please wait..." : "Save Combine"}</Button>
                                                        </div>
                                                    </div>
                                                :
                                                !isApproved &&
                                                    <div className="d-inline ms-2">
                                                        <Button
                                                            variant="primary"
                                                            disabled={!isFormChanged || isSubmitting}
                                                            type="submit"
                                                            size="sm"
                                                        >
                                                            <FontAwesomeIcon icon={faSave} className="me-2"  size="sm"/>
                                                            {isSubmitting ? "Please wait..." : "Save content changes"}</Button>
                                                    </div>
                                                }
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            {!isApproved &&
                                            <>
                                                <label>Select products from daily attestations to attach to this consignment</label>
                                                <p><strong>{ props.values.contentsSelected?.length}</strong> item(s) selected.</p>
                                                {formSubmitted === true &&(
                                                    <Alert variant="success">
                                                        Updated changes successfully.
                                                    </Alert>
                                                )}
                                            </>
                                            }
                                             <Preloader component={true} show={isSubmitting || loading} />
{/*                                             
                                            <Row>
                                                <Col><PrettyPrintJson data={combineSelections}/>
                                                </Col>
                                                <Col><PrettyPrintJson data={combineSavedSelections}/>
                                                </Col>
                                            </Row> */}
                                            {props.values.contentsSelected?.length > 0 && !loading && !isSubmitting &&
                                                <ConsignmentContentsTable
                                                    setCombinePreviousSelections={setCombinePreviousSelections}
                                                //setSelectionValues={e=> props.setFieldValue("dailyAttestationsSelected", e)}
                                                    setCombineSelectionValues={props.setFieldValue}
                                                    setCombineUniqueSelections={setCombineUniqueSelections}
                                                    combineUniqueSelections={combineUniqueSelections}
                                                    setFormSubmitted={setFormSubmitted}
                                                    combineSavedSelections={combineSavedSelections}
                                                    isApproved={isApproved}
                                                    setSelections={setApiDailyAttestationValues}
                                                    selections={props.values.contentsSelected}
                                                    setFormChanged={setFormChanged}
                                                    isCombineMode={isCombineMode}
                                                    setCombineMode={setCombineMode}
                                                    combineSelections={combineSelections}
                                                    setCombineSelections={setCombineSelections}
                                                    combineCertificateNumber={combineCertificateNumber}
                                                    setCombineCertificateNumber={setCombineCertificateNumber}
                                                    combineMasterId={combineMasterId}
                                                    setCombineMasterId={setCombineMasterId}
                                                    setNoMatchesFound={setNoMatchesFound}
                                                    noMatchesFound={noMatchesFound}
                                                />
                                            }

                                            {!isApproved &&
                                            <>
                                                <Button onClick={() => setShowModalAdd(true)} size={"sm"} variant="light" className="text-dark my-4 ">
                                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                                    {props.values.contentsSelected?.length <=0?<span>Select items</span>:<span>Change Selections</span>}
                                                </Button>

                                                {/* //Modal - Start*/}
                                                <Modal size="fullscreen" as={Modal.Dialog} centered show={showModalAdd} onHide={()=> handleCloseModal(props.values.contentsSelected)}>
                                                    <Modal.Header>
                                                        <Modal.Title className="h5 my-2 text-title">Select products on this consignment</Modal.Title>
                                                        <Button variant="close" aria-label="Close" onClick={()=>handleCloseModal(props.values.contentsSelected)} />
                                                    </Modal.Header><i className="fa-solid fa-xmark"></i>
                                                    <Modal.Body>
                                                        <ConsignmentContentsSelectModal
                                                            showNonGEFS={props.values.showNonGEFS}
                                                            showStorageDeclaration={props.values.showStorageDeclaration}
                                                            enableNonGEFS={true}
                                                            enableCompound={false}
                                                            onChange={props.setFieldValue}
                                                            showExtraOptions={true}
                                                            contentsSelected={props.values.contentsSelected}
                                                            setFormChanged={setFormChanged}
                                                        />

                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button
                                                            onClick={() => handleCloseModal(props.values.contentsSelected)}
                                                            variant="primary"
                                                            size="md"
                                                            className="mx-auto"
                                                            >
                                                            Confirm Product Selection
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </>
                                            }
                                        </Card.Body>
                                    </Card>

                                    <div className="btn-panel left">
                                        <Button variant="light" href="/consignments" className="d-inline-block">
                                            Back to Consignments
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <ChangeFormValues />
                        </Form>
                    </Col>
                )}
            </Formik >
        </>}

    </div>
    );
};

export default ConsignmentContentsPage;
