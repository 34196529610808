import React  from 'react';
import {Badge, Row, Col} from '@themesberg/react-bootstrap';
import EllipsisTooltip from '../../../../../components/EllipsisTooltip';
import moment from 'moment';


const SelectionRowContent = (props) => {
    const {
        isCombined,
        isGEFS,
        isStorageDeclaration, 
        isCompound,
        certificateNumbers,
        numberOfPackages=null,
        commodityCode,
        productName,
        uniqueCode,
        batchNumbers, 
        dateCollectionProduction
    } = props;
    
    
    return(
        <Row>  
            <Col md={12} className="text-truncate">
               
            <label className="d-block">
                <EllipsisTooltip text={productName}/>
            </label>

                <small className="d-block">Type:&nbsp;
                    {isStorageDeclaration?
                            <Badge className="rounded px-1 py-1 bg-primary">Storage</Badge>
                        :
                        (
                            isGEFS?
                                <Badge className="rounded px-1 py-1 bg-tertiary">GEFS</Badge>
                            :
                                <Badge className="rounded px-1 py-1 bg-warning">Non-GEFS</Badge>
                        )
                    }
                </small>
               <small className="d-block">
                    Certificate number(s):&nbsp; 
                    {certificateNumbers}
                    {isCompound &&
                        <Badge className="rounded px-1 py-1 mx-2 bg-light text-dark d-inline">Compound</Badge>
                    }
               </small>
               {isCombined &&
                    <small className="d-block">Number of packages: {numberOfPackages}</small>
               }
                <small className="d-block">Commodity code: {commodityCode}</small>
             
             
                <small className="d-block">
                    {isStorageDeclaration? "Storage Declaration" : "Support Attestation"} Unique Code:&nbsp;
                     {uniqueCode}</small>
               
                <small className="d-block text-truncate">Batch numbers:&nbsp;
                    <EllipsisTooltip className="d-inline " text={batchNumbers}/>
                 </small>
             
             
                {dateCollectionProduction !==undefined && dateCollectionProduction!==null &&
                    <small className="d-block">Collection / Production Date: {dateCollectionProduction}</small>
                }
              
            </Col>         
        </Row>
    )

    
};

export default SelectionRowContent