import *  as baseService  from "./BaseService";

const getSiteHealthMarkResult = (data) => {
    return baseService.postApiData("/ai/GetSiteHealthMarkResult", data);
};
const getSealNumberResult = (data) => {
    return baseService.postApiData("/ai/GetSealNumberResult", data);
};
const getContainerNumberResult = (data) => {
    return baseService.postApiData("/ai/GetContainerNumberResult", data);
};

const AIService = {
    getSiteHealthMarkResult,
    getSealNumberResult,
    getContainerNumberResult,
};

export default AIService;