import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import ProductDataService from "../../../services/ProductService";
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import moment from "moment-timezone";
import { Formik, Form, FieldArray, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import ProductPorcineService from "../../../services/ProductPorcineService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ZoneService from "../../../services/ZoneService";
import TemperatureService from "../../../services/TemperatureService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import * as Porcine8370Labels from "./Porcine8370Labels"
import { Col, Row, Button, Card, Alert } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faLeaf, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import ActivityService from '../../../services/ActivityService';


const Porcine8370AddPage = (props) => {

    const [isSubmitting,  setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [productDetail, setProductDetail] = useState([]);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [storageRequired, SetStorageRequired] = useState(false);
    const [zones, SetZones] = useState([]);
    const [memberStates, SetMemberStates] = useState([]);
    const [dateRequired, SetDateRequired] = useState(false);
    const [productDetailCountry, SetCountry] = useState([]);
    const [specieslist, SetSpecies] = useState([]);

    const [apiValues, SetApiValues] = useState([]);


    let title = (editing == true ? "Edit " : "New") + " Porcine Information (#8370-For Porcine only)"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Porcine 8370', link: Routes.Porcine8370AddPage.path },
    };
    const initialValues = {
        productId: props.match.params.id,
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
        treatmentType: apiValues?.treatmentType ?? '',
        meat_MincedMeat: apiValues?.meat_MincedMeat ?? '',
        storageRequired: false,
        storageDates: apiValues?.storageDates ?? [],
        health_1_3_A: apiValues?.health_1_3_A ?? false,
        health_1_3_B: apiValues?.health_1_3_B ?? false,
        health_1_3_C: apiValues?.health_1_3_C ?? false,
        health_1_4: apiValues?.health_1_4 ?? false,
        health_1_6_statements: apiValues?.health_1_6_statements ?? 0,
        health_1_11: apiValues?.health_1_11 ?? false,
        animal_2_1_B_statements: apiValues?.animal_2_1_B_statements ?? 0,
        animal_2_1_B_date: apiValues?.animal_2_1_B_date ?? null,
        animal_2_1_C_statements: apiValues?.animal_2_1_C_statements ?? 0,
        animal_2_1_C_date: apiValues?.animal_2_1_C_date ?? null,
        animal_2_2_statements: apiValues?.animal_2_2_statements ?? 0,
        animal_2_2_B_date: apiValues?.animal_2_2_B_date ?? null,
        animal_2_2_C_date: apiValues?.animal_2_2_C_date ?? null,
        animal_2_4_D: apiValues?.animal_2_4_D ?? false,
        animal_2_4DA: apiValues?.animal_2_4DA ?? null,
        animal_2_4DB: apiValues?.animal_2_4DB ?? null,
        animal_2_4DC: apiValues?.animal_2_4DC ?? null,
        animal_2_6_statements: apiValues?.animal_2_6_statements ?? 0,
        animal_2_1_zone: apiValues?.animal_2_1_zone ?? [],
        animal_2_2_zone: apiValues?.animal_2_2_zone ?? [],
        animal_2_2_memberState: apiValues?.animal_2_2_memberState ?? [],
        dateRequired: false,
        slaugherhouses: apiValues?.slaugherhouses ?? '',
        cuttingPlant: apiValues?.cuttingPlant ?? '',
        idenitificationMarks: apiValues?.idenitificationMarks ?? '',
        dateOfProduction: apiValues?.dateOfProduction ?? '',
        coldstore: apiValues?.coldstore ?? '',
        porcineCarcaseWhole: apiValues?.porcineCarcaseWhole ?? false,
        porcineCarcaseSide: apiValues?.porcineCarcaseSide ?? false,
        porcineCarcaseQuarters: apiValues?.porcineCarcaseQuarters ?? false,
        porcineCuts: apiValues?.porcineCuts ?? false,
        porcineOffal: apiValues?.porcineOffal ?? false,
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";

    const treatments = [
        { value: "", key: "" },
        { value: "De-boned", key: "De-boned" },
        { value: "Bone in", key: "Bone in" },
        { value: "Matured", key: "Matured" },
        { value: "Bone in & Matured", key: "Bone in & Matured" },
    ]
    const meats = [
        { value: "", key: "" },
        { value: "meat", key: "meat" },
        { value: "minced meat", key: "minced meat" },
    ]


    //Validation
    const validationSchema = Yup.object().shape({
        meat_MincedMeat: Yup.string().required(validationMsgRequired),
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        animal_2_1_zone: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        storageDates: Yup.array().nullable()
            .when("storageRequired", {
                is: true,
                then:
                    Yup.array().of(Yup.object().shape({
                        storageDate: Yup.string().nullable().required(validationMsgRequired)
                    })
                    ).min(1, validationMsgAtLeastOne)
            }),
        health_1_6_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_1_B_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_1_C_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_2_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_6_statements: Yup.number().min(1, validationMsgAtLeastOne),
        health_1_3_A: Yup.bool()
            .test('health_1_3_A', validationMsgAtLeastOne, function (x) {
                return (!this.parent.health_1_3_A && !this.parent.health_1_3_B && !this.parent.health_1_3_C)
                    ? false : true;
            }),
        health_1_3_B: Yup.bool()
            .test('health_1_3_B', validationMsgAtLeastOne, function (x) {
                return (!this.parent.health_1_3_A && !this.parent.health_1_3_B && !this.parent.health_1_3_C)
                    ? false : true;
            }),
        health_1_3_C: Yup.bool()
            .test('health_1_3_C', validationMsgAtLeastOne, function (x) {
                return (!this.parent.health_1_3_A && !this.parent.health_1_3_B && !this.parent.health_1_3_C)
                    ? false : true;
            }),
        animal_2_1_B_date: Yup.string().nullable()
            .test('animal_2_1_B_date', validationMsgRequired, function (x) {
                return (this.parent.animal_2_1_B_statements == 2 && x === null)
                    ? false : true;
            }),
        animal_2_1_C_date: Yup.string().nullable()
            .test('animal_2_1_C_date', validationMsgRequired, function (x) {
                return (this.parent.animal_2_1_C_statements == 2 && x === null)
                    ? false : true;
            }),
        animal_2_2_B_date: Yup.string().nullable()
            .test('animal_2_2_B_date', validationMsgRequired, function (x) {
                return (this.parent.animal_2_2_statements == 2 && x === null)
                    ? false : true;
            }),
        animal_2_2_C_date: Yup.string().nullable()
            .test('animal_2_2_C_date', validationMsgRequired, function (x) {
                return (this.parent.animal_2_2_statements == 3 && x === null)
                    ? false : true;
            }),
        animal_2_2_zone: Yup.array()
            .test('animal_2_2_zone', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 2 && x.length === 0)
                    ? false : true;
            }),
        animal_2_2_memberState: Yup.array()
            .test('animal_2_2_memberState', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 3 && x.length === 0)
                    ? false : true;
            }),
        animal_2_4DA: Yup.string().nullable()
            .test('animal_2_4DA', validationMsgRequired, function (x) {
                return (this.parent.animal_2_4DB == null && this.parent.animal_2_4DC == null && x === null)
                    ? false : true;
            }),
        animal_2_4DB: Yup.string().nullable()
            .test('animal_2_4DB', validationMsgRequired, function (x) {
                return (this.parent.animal_2_4DC != null && x === null)
                    ? false : true;
            }),
        animal_2_4DC: Yup.string().nullable()
            .test('animal_2_4DC', validationMsgRequired, function (x) {
                return (this.parent.animal_2_4DB != null && x === null)
                    ? false : true;
            }),
        slaugherhouses: Yup.string().required(validationMsgRequired),
        idenitificationMarks: Yup.string().required(validationMsgRequired),
        porcineCarcaseQuarters: Yup.bool().test('porcineCarcaseQuarters', validationMsgAtLeastOne, function (x) {
            return (this.parent.porcineCarcaseQuarters || this.parent.porcineCarcaseSide || this.parent.porcineCarcaseWhole || this.parent.porcineCuts || this.parent.porcineOffal)
        }),
        porcineCarcaseSide: Yup.bool().test('porcineCarcaseSide', validationMsgAtLeastOne, function (x) {
            return (this.parent.porcineCarcaseQuarters || this.parent.porcineCarcaseSide || this.parent.porcineCarcaseWhole || this.parent.porcineCuts || this.parent.porcineOffal)
        }),
        porcineCarcaseWhole: Yup.bool().test('porcineCarcaseWhole', validationMsgAtLeastOne, function (x) {
            return (this.parent.porcineCarcaseQuarters || this.parent.porcineCarcaseSide || this.parent.porcineCarcaseWhole || this.parent.porcineCuts || this.parent.porcineOffal)
        }),
        porcineCuts: Yup.bool().test('porcineCuts', validationMsgAtLeastOne, function (x) {
            return (this.parent.porcineCarcaseQuarters || this.parent.porcineCarcaseSide || this.parent.porcineCarcaseWhole || this.parent.porcineCuts || this.parent.porcineOffal)
        }),
        porcineOffal: Yup.bool().test('porcineOffal', validationMsgAtLeastOne, function (x) {
            return (this.parent.porcineCarcaseQuarters || this.parent.porcineCarcaseSide || this.parent.porcineCarcaseWhole || this.parent.porcineCuts || this.parent.porcineOffal)
        }),
    });

    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            console.log(submitData);

            if (apiValues.length === 0) {
                ProductPorcineService.create(submitData)
                    .then(async response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Porcine 8370',
                            8370,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            else {
                ProductPorcineService.update(productId, values)
                    .then(async response => {
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Porcine 8370',
                            8370,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            console.log(JSON.stringify(values, null, 2));
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();

        useEffect(() => {
            setFieldValue('storageRequired', storageRequired);
            if (values.meat_MincedMeat == "minced meat") {
                setFieldValue('health_1_4', true);
            }
            else {
                setFieldValue('health_1_4', false);
            }
            if (values.meat_MincedMeat != "") {
                Porcine8370Labels.health_1_6_statements[1].key = "the packages of <strong>" + values.meat_MincedMeat + "</strong> have been marked with an identification mark in accordance with Section I of Annex II to Regulation (EC) No 853/2004;";
            }
            else {
                Porcine8370Labels.health_1_6_statements[1].key = "the packages of [meat] [minced meat] have been marked with an identification mark in accordance with Section I of Annex II to Regulation (EC) No 853/2004;";
            }
            if (values?.animal_2_1_B_statements == 2 && values.animal_2_1_B_date != null) {
                Porcine8370Labels.animal_2_1_B_statements[1].key = "in which <strong><u>foot and mouth disease</u></strong> has not been reported since " + moment(values?.animal_2_1_B_date).format("DD/MM/YYYY") + "."
            }
            else {
                Porcine8370Labels.animal_2_1_B_statements[1].key = "in which <strong><u>foot and mouth disease</u></strong> has not been reported since ___/___/____ .";
            }
            if (values?.animal_2_1_C_statements == 2 && values.animal_2_1_C_date != null) {
                Porcine8370Labels.animal_2_1_C_statements[1].key = "in which <strong><u>classical swine fever</u></strong> has not been reported since " + moment(values?.animal_2_1_C_date).format("DD/MM/YYYY") + " and vaccination against this disease has not been carried out during a period of 12 months before the date of slaughter of the animals from which the fresh meat was obtained";
            }
            else {
                Porcine8370Labels.animal_2_1_C_statements[1].key = "in which <strong><u>classical swine fever</u></strong> has not been reported since ___/___/____  and vaccination against this disease has not been carried out during a period of 12 months before the date of slaughter of the animals from which the fresh meat was obtained";
            }
            if (!values?.animal_2_2_zone.length == 0 && values.animal_2_2_B_date != null) {
                Porcine8370Labels.animal_2_2_statements[1].key = "have been introduced on " + moment(values?.animal_2_2_B_date).format("DD/MM/YYYY") + " into the zone referred to under point II.2.1., from the zone with code <strong><u>" + values?.animal_2_2_zone?.map(x => x.isoCode).join(", ") + "</u></strong> that at that date was authorised for the entry of fresh meat of porcine animals into the Union and where they have remained since birth, or for at least 3 months before slaughter.";
            }
            else if (values?.animal_2_2_zone.length == 0 && values.animal_2_2_B_date != null) {
                Porcine8370Labels.animal_2_2_statements[1].key = "have been introduced on " + moment(values?.animal_2_2_B_date).format("DD/MM/YYYY") + " into the zone referred to under point II.2.1., from the zone with code ___ - __ that at that date was authorised for the entry of fresh meat of porcine animals into the Union and where they have remained since birth, or for at least 3 months before slaughter.";
            }
            else if (!values?.animal_2_2_zone.length == 0 && values.animal_2_2_B_date == null) {
                Porcine8370Labels.animal_2_2_statements[1].key = "have been introduced on ___/___/____ into the zone referred to under point II.2.1., from the zone with code <strong><u>" + values?.animal_2_2_zone?.map(x => x.isoCode).join(", ") + "</u></strong> that at that date was authorised for the entry of fresh meat of porcine animals into the Union and where they have remained since birth, or for at least 3 months before slaughter.";
            }
            else {
                Porcine8370Labels.animal_2_2_statements[1].key = "have been introduced on ___/___/____ into the zone referred to under point II.2.1., from the zone with code ___ - __ that at that date was authorised for the entry of fresh meat of porcine animals into the Union and where they have remained since birth, or for at least 3 months before slaughter.";
            }
            if (!values?.animal_2_2_memberState.length == 0 && values.animal_2_2_C_date != null) {
                Porcine8370Labels.animal_2_2_statements[2].key = "have been introduced on " + moment(values?.animal_2_2_C_date).format("DD/MM/YYYY") + " into the zone referred to under point II.2.1., from the Member State with ISO code <strong><u>" + values?.animal_2_2_memberState?.map(x => x.isoCode).join(", ") + "</u></strong> and vaccination against this disease has not been carried out during a period of 12 months before the date of slaughter of the animals from which the fresh meat was obtained";
            }
            else if (values?.animal_2_2_memberState.length == 0 && values.animal_2_2_C_date != null) {
                Porcine8370Labels.animal_2_2_statements[2].key = "have been introduced on " + moment(values?.animal_2_2_C_date).format("DD/MM/YYYY") + " into the zone referred to under point II.2.1., from the Member State with ISO code _____ and vaccination against this disease has not been carried out during a period of 12 months before the date of slaughter of the animals from which the fresh meat was obtained";
            }
            else if (!values?.animal_2_2_memberState.length == 0 && values.animal_2_2_C_date == null) {
                Porcine8370Labels.animal_2_2_statements[2].key = "have been introduced on ___/___/____ into the zone referred to under point II.2.1., from the Member State with ISO code <strong><u>" + values?.animal_2_2_memberState?.map(x => x.isoCode).join(", ") + "</u></strong> and vaccination against this disease has not been carried out during a period of 12 months before the date of slaughter of the animals from which the fresh meat was obtained";
            }
            else {
                Porcine8370Labels.animal_2_2_statements[2].key = "have been introduced on ___/___/____ into the zone referred to under point II.2.1., from the Member State with ISO code _____ and vaccination against this disease has not been carried out during a period of 12 months before the date of slaughter of the animals from which the fresh meat was obtained";
            }
            if (values.animal_2_4DA != null) {
                setFieldValue('animal_2_4DB', null);
                setFieldValue('animal_2_4DC', null);
            }
            if (values.animal_2_4DB != null && values.animal_2_4DC != null) {
                setFieldValue('animal_2_4DA', null);
            }

        }, [values]);

        return null;
    };
    useEffect(() => {
        async function fetchListData() {
            if (!ready) {
                try {
                    const populateProductDetailData = new Promise((resolve, reject) => {
                        resolve(
                            ProductDataService.getProductDetailByProductId(productId)
                                .then(response => {
                                    var res = response.data;
                                    console.log(res);
                                    setProductDetail(res);
                                    //Get Temperature Name
                                    if (res.temperatureId != null) {
                                        TemperatureService.getById(res.temperatureId)
                                            .then(response => {
                                                var res = response.data;
                                                // setTemperature(res.temperatureName);
                                                if (res.temperatureName.includes("Frozen")) {
                                                    SetStorageRequired(true);
                                                }
                                                else {
                                                    SetStorageRequired(false);
                                                }
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            });
                                    }
                                    if (res.countryOriginId != null) {
                                        ZoneService.getById(res.countryOriginId)
                                            .then((response) => {
                                                SetCountry(response.data);
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            })
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                })
                        );
                    });

                    Promise.all([populateProductDetailData]).then((responses) => {
                        setReady(true);
                    });
                } catch (e) {
                    console.error(e);
                }
            };

        }
        fetchListData();
    }, [ready]);

    useEffect(() => {
        ProductPorcineService.getByProductId(productId)
            .then(response => {
                if (response.data == "") {
                    return;
                }
                console.log(response.data);
                SetApiValues(response.data);
                setEditing(true);
                setReady(true);
                console.log("init values:" + apiValues);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductService.getById(productId)
            .then(response => {
                SetProduct(response.data.data);
                setIsGEFS(response.data.data.isGEFS);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductScientificSpeciesDataService
            .getOnlyPorcine()
            .then((_speciesResponse) => {
                SetSpecies(_speciesResponse.data);
            })
            .finally(() => {
            });
        ZoneService.getAllByGroup("ALL_FRESH_MEAT_UNGULATES_POR")
            .then((_zonesResponse) => {
                const options = _zonesResponse.data.map(d => ({
                    "id": d.id,
                    "displayName": d.displayName,
                    "countryName": d.countryName,
                    "regionName": d.regionName,
                    "isoCode": d.isoCode,
                })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                const firstItem = "GB-0";
                options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                SetZones(options);
            }).catch((e) => {
                console.log(e);
            });
        ZoneService
            .getAllByGroup("ALL_MEMBER_STATES")
            .then((_countryResponse) => {
                const options = _countryResponse.data.map(d => ({
                    "id": d.id,
                    "displayName": d.displayName,
                    "countryName": d.countryName,
                    "regionName": d.regionName,
                    "isoCode": d.isoCode,
                })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                const firstItem = "GB";
                options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                SetMemberStates(options);
            }).catch((e) => {
                console.log(e);
            });

    }, []);

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);


    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <>
                    <SubmissionPage
                        productId={productId}
                        attestationId={attestationId}
                        isGEFS={isGEFS}
                        errors={errors}
                        title={(editing ? "Edited" : "Completed") + " Porcine 8370 Form"}
                        message={`Porcine information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                    />

                </>

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        {/* <PrettyPrintJson data={props.values} /> */}
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        <FormController
                                            control="select"
                                            label="Meat Category"
                                            name="meat_MincedMeat"
                                            isrequired="true"
                                            options={meats}
                                            col={3}
                                        />
                                        <h4 className="mt-5">Health Attestation</h4>

                                        I hereby certify that the Porcine described in this product was produced in accordance with these
                                        requirements, in particular that it derives:

                                        <ul className="list-unstyled list-clauses" >
                                            <li className="mt-4">
                                                <FormController
                                                    control="checkbox"
                                                    label={Porcine8370Labels.health_1_3_A.label}
                                                    name="health_1_3_A"
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <li className="mt-4">
                                                <FormController
                                                    control="checkbox"
                                                    label={Porcine8370Labels.health_1_3_B.label}
                                                    name="health_1_3_B"
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <li className="mt-4">
                                                <FormController
                                                    control="checkbox"
                                                    label={Porcine8370Labels.health_1_3_C.label}
                                                    name="health_1_3_C"
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br />
                                            <li>
                                                <FormController
                                                    control="checkbox"
                                                    label={Porcine8370Labels.health_1_4.label}
                                                    name="health_1_4"
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <li>
                                                <FormController
                                                    control="radio"
                                                    label=""
                                                    name="health_1_6_statements"
                                                    options={Porcine8370Labels.health_1_6_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br></br>
                                            <label><span className='text-danger'>Please only select if final destination is Sweden or Finland</span></label>
                                            <li>
                                                <FormController
                                                    control="checkbox"
                                                    label={Porcine8370Labels.health_1_11.label}
                                                    name="health_1_11"
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <h4 className="mt-5">Animal Health Attestation</h4>
                                            <li>
                                                <span className='required'>Fresh meat has been obtained in the <strong>zone(s) </strong>with code(s):</span>
                                                <MultiSelectField
                                                    id='meat'
                                                    name='animal_2_1_zone'
                                                    placeholder="Select fields"
                                                    options={zones}
                                                    defaultValues={props.values.animal_2_1_zone}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.displayName}
                                                    value={props.values.animal_2_1_zone}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.animal_2_1_zone}
                                                    error={props.errors.animal_2_1_zone}
                                                    isClearable={true}
                                                    col={6}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_B_statements"
                                                option={Porcine8370Labels.animal_2_1_B_statements[0]}
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_B_statements"
                                                option={Porcine8370Labels.animal_2_1_B_statements[1]}
                                                readOnly={readOnly}
                                            />
                                            {(props.values.animal_2_1_B_statements == 2) && (
                                                <FormController
                                                    label="on"
                                                    control="dateInput"
                                                    name="animal_2_1_B_date"
                                                    value={props.values?.animal_2_1_B_date ? moment(props.values?.animal_2_1_B_date).format("DD/MM/YYYY") : ""}
                                                    col={3}
                                                />
                                            )}
                                            <hr />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_C_statements"
                                                option={Porcine8370Labels.animal_2_1_C_statements[0]}
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_C_statements"
                                                option={Porcine8370Labels.animal_2_1_C_statements[1]}
                                                readOnly={readOnly}
                                            />
                                            {(props.values.animal_2_1_C_statements == 2) && (
                                                <FormController
                                                    label="on"
                                                    control="dateInput"
                                                    name="animal_2_1_C_date"
                                                    value={props.values?.animal_2_1_C_date ? moment(props.values?.animal_2_1_C_date).format("DD/MM/YYYY") : ""}
                                                    col={3}
                                                />
                                            )}
                                            <hr />

                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_2_statements"
                                                option={Porcine8370Labels.animal_2_2_statements[0]}
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_2_statements"
                                                option={Porcine8370Labels.animal_2_2_statements[1]}
                                                readOnly={readOnly}
                                            />
                                            {(props.values.animal_2_2_statements == 2) && (
                                                <li>
                                                    <FormController
                                                        label="on"
                                                        control="dateInput"
                                                        name="animal_2_2_B_date"
                                                        value={props.values?.animal_2_2_B_date ? moment(props.values?.animal_2_2_B_date).format("DD/MM/YYYY") : ""}
                                                        col={3}
                                                    />
                                                    <br></br>

                                                    <span className='required'>from the <strong>zone </strong>with code:</span>

                                                    <MultiSelectField
                                                        id='meat'
                                                        name='animal_2_2_zone'
                                                        placeholder="Select fields"
                                                        options={zones}
                                                        defaultValues={props.values.animal_2_2_zone}
                                                        getOptionValue={option => option.id}
                                                        getOptionLabel={option => option.displayName}
                                                        value={props.values.animal_2_2_zone}
                                                        isMulti={true}
                                                        onChange={props.setFieldValue}
                                                        onBlur={props.setFieldTouched}
                                                        touched={props.touched.animal_2_2_zone}
                                                        error={props.errors.animal_2_2_zone}
                                                        isClearable={true}
                                                        col={3}
                                                        isrequired="true"
                                                        backspaceRemovesValue={true}
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                            )}
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_2_statements"
                                                option={Porcine8370Labels.animal_2_2_statements[2]}
                                                readOnly={readOnly}
                                            />
                                            {(props.values.animal_2_2_statements == 3) && (
                                                <li>
                                                    <FormController
                                                        label="on"
                                                        control="dateInput"
                                                        name="animal_2_2_C_date"
                                                        value={props.values?.animal_2_2_C_date ? moment(props.values?.animal_2_2_C_date).format("DD/MM/YYYY") : ""}
                                                        col={3}
                                                    />
                                                    <br></br>

                                                    <span className='required'>from the <strong>Member State </strong>with ISO code:</span>

                                                    <MultiSelectField
                                                        id='meat'
                                                        name='animal_2_2_memberState'
                                                        placeholder="Select fields"
                                                        options={memberStates}
                                                        defaultValues={props.values.animal_2_2_memberState}
                                                        getOptionValue={option => option.id}
                                                        getOptionLabel={option => option.displayName}
                                                        value={props.values.animal_2_2_memberState}
                                                        isMulti={true}
                                                        onChange={props.setFieldValue}
                                                        onBlur={props.setFieldTouched}
                                                        touched={props.touched.animal_2_2_memberState}
                                                        error={props.errors.animal_2_2_memberState}
                                                        isClearable={true}
                                                        col={3}
                                                        isrequired="true"
                                                        backspaceRemovesValue={true}
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                            )}
                                            <hr />
                                            <br></br>
                                            <label className="mt-3 mb-3 required">have been slaughtered:</label>
                                            {!props.values.animal_2_4DB && !props.values.animal_2_4DC && (
                                                <FormController
                                                    label="on"
                                                    control="dateInput"
                                                    name="animal_2_4DA"
                                                    value={props.values?.animal_2_4DA ? moment(props.values?.animal_2_4DA).format("DD/MM/YYYY") : ""}
                                                    col={2}
                                                />

                                            )}
                                            <br></br>
                                            {!props.values.animal_2_4DA && !props.values.animal_2_4DB && !props.values.animal_2_4DC && (
                                                <p>or:</p>
                                            )}
                                            {!props.values.animal_2_4DA && (

                                                <Row>
                                                    <Col sm={6} lg={5} xxl={2} >
                                                        <FormController
                                                            label="between"
                                                            control="dateInput"
                                                            name="animal_2_4DB"
                                                            value={props.values?.animal_2_4DB ? moment(props.values?.animal_2_4DB).format("DD/MM/YYYY") : ""}
                                                            col={11}
                                                        />
                                                    </Col>

                                                    <Col sm={6} lg={5} xxl={2}>
                                                        <FormController
                                                            label="and"
                                                            control="dateInput"
                                                            name="animal_2_4DC"
                                                            value={props.values?.animal_2_4DC ? moment(props.values?.animal_2_4DC).format("DD/MM/YYYY") : ""}
                                                            col={10}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}

                                            <li>
                                                <FormController
                                                    control="radio"
                                                    label="has been strictly segregated from fresh meat not complying with the animal health requirements for the entry into the Union of fresh meat of
                                                    porcine animals throughout the operations of slaughter, cutting and until:"
                                                    name="animal_2_6_statements"
                                                    options={Porcine8370Labels.animal_2_6_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br></br>
                                            <li>
                                                <MultiSelectField
                                                    id='productScientificSpecies'
                                                    name='productScientificSpecies'
                                                    label="Species"
                                                    placeholder="Select fields"
                                                    options={specieslist}
                                                    defaultValues={props.values?.productScientificSpecies}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                                    value={props.values.productScientificSpecies}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.productScientificSpecies}
                                                    error={props.errors.productScientificSpecies}
                                                    isClearable={true}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    col={4}
                                                />
                                            </li>
                                            <label className="mt-3 mb-3 required">Nature of Commodity</label>
                                            {props.errors?.porcineCarcasewhole && (props.touched?.porcineCarcasewhole
                                                || props.touched?.porcineCarcaseside
                                                || props.touched?.porcineCarcasequarters
                                                || props.touched?.porcineCuts
                                                || props.touched?.porcineOffal) &&
                                                (<span className="invalid-feedback d-block mb-3">{props.errors.porcineCarcasewhole}</span>)
                                            }


                                            <FormController
                                                control="checkbox"
                                                label="Carcase-whole"
                                                name="porcineCarcaseWhole"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Carcase-side"
                                                name="porcineCarcaseSide"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Carcase-quarters"
                                                name="porcineCarcaseQuarters"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Cuts"
                                                name="porcineCuts"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Offal"
                                                name="porcineOffal"
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="select"
                                                label="Treatment Type"
                                                name="treatmentType"
                                                options={treatments}
                                                col={3}
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="EU approval numbers of Slaugherhouses"
                                                name="slaugherhouses"
                                                isrequired="true"
                                                options={treatments}
                                                col={3}
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="EU approval number of Cutting plants"
                                                name="cuttingPlant"
                                                options={treatments}
                                                col={3}
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Idenitification marks"
                                                name="idenitificationMarks"
                                                isrequired="true"
                                                options={treatments}
                                                col={3}
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Date of production"
                                                name="dateOfProduction"
                                                options={treatments}
                                                col={3}
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Coldstore"
                                                name="coldstore"
                                                options={treatments}
                                                col={3}
                                            />
                                            {(storageRequired) && (
                                                <li>
                                                    <label className={storageRequired ? "required pt-3" : " pt-3"}>Date frozen <span className='text-gray-600 text-italic'>As per Notes Box reference 1.27 of EHC 8370</span></label>

                                                    <FieldArray
                                                        name="storageDates"
                                                        render={({ insert, remove, push }) => (
                                                            <div>
                                                                {props.values?.storageDates?.length > 0 &&
                                                                    props.values?.storageDates?.map((x, index) => (
                                                                        <Row key={1 + index}>
                                                                            <Col md={3} lg={2}>
                                                                                <FormController
                                                                                    label={`Freeze Date: ${1 + index}`}
                                                                                    isrequired={storageRequired ? "true" : "false"}
                                                                                    control="dateInput"
                                                                                    name={`storageDates[${index}].storageDate`}
                                                                                    value={props.values?.storageDates?.[index].storageDate ? moment(props.values?.storageDates?.[index].storageDate).format("DD/MM/YYYY") : ""}
                                                                                />
                                                                            </Col>
                                                                            <Col md={3} lg={2} className="mt-5">
                                                                                {(index > 0 &&
                                                                                    <Button variant="outline-danger" disabled={readOnly} onClick={() => remove(index)}>
                                                                                        <FontAwesomeIcon icon={faTimes} className="mx-2" />
                                                                                    </Button>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    ))}
                                                                <Button variant="light" className="mt-3 mb-2" onClick={() => push({ storageDate: "" })}>
                                                                    Add a Freeze Date
                                                                </Button>
                                                            </div>
                                                        )}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                        {/* <PrettyPrintJson data={props.values} />   */}
                                        {/* <PrettyPrintJson data={props.errors} />   */}
                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default Porcine8370AddPage;