import * as WildGame8367Labels from "../WildGame8367Labels"
import { findArrayElementByFieldNameAndValue, findArrayElementByValue } from "../../../Attestations/Common/AttestationFunctions";
import moment from "moment-timezone";
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
export const AttestationContent = (props) => {
    const { attestationData } = props;
    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);
    { WildGame8367Labels.animal_2_1_statements[1].key = "in which foot and mouth disease has not been reported since " + moment(attestationData?.animal_2_1_date).format("DD/MM/YYYY" + ";") };
    return (
        <>
            <h5>II. Health Attestation</h5>
            <>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned, declare that I am aware of the relevant requirements of Regulation (EC) No 999/2001 of the European Parliament and of
                    the Council, Regulation (EC) No 178/2002 of the European Parliament and of the Council, Regulation (EC) No 852/2004 of the European Parliament and of the
                    Council, Regulation (EC) No 853/2004 of the European Parliament and of the Council, Regulation (EU) 2017/625 of the European Parliament and of the Council,
                    Commission Delegated Regulation (EU) 2019/624 and Commission Implementing Regulation (EU) 2019/627 and hereby certify that the fresh meat(2) of wild
                    animals of the family Bovidae (other than domestic bovine, ovine and caprine animals), wild camelid animals and wild cervid animals, described in Part I was
                    produced in accordance with those requirements, in particular that:
                </p>
                <ol className="attestation-II-1">
                    <li>
                        the meat comes from (an) establishment(s) applying general hygiene requirements and implementing a programme based on hazard analysis and critical
                        control points (HACCP) principles in accordance with Article 5 of Regulation (EC) No 852/2004, regularly audited by the competent authorities, and being
                        listed as an EU approved establishment;
                    </li>
                    <li>
                        the meat has been obtained in compliance with the conditions set out in Section IV, Chapters I and II of Annex III to Regulation (EC) No 853/2004, and
                        in particular:
                        (i) before skinning, it has been stored and handled separately from other food and not been frozen;
                        and
                        (ii) after skinning, it has undergone a final inspection as referred to in point II.1.3;
                    </li>
                    <li>
                        the meat has been found fit for human consumption following a post-mortem inspection carried out in accordance with Articles 8, 10, 12 to 15, 28, 29. 33,
                        34 and 37 of Implementing Regulation (EU) 2019/627 and Articles 7 and 8 of Delegated Regulation (EU) 2019/624;
                    </li>
                    {
                        attestationData.health_1_4_statements > 0 &&
                        <li>
                            {findArrayElementByFieldNameAndValue(WildGame8367Labels.health_1_4_statements, 'health_1_4_statements', attestationData.health_1_4_statements)}
                        </li>
                    }
                    <li>
                        the meat satisfies the relevant criteria laid down in Commission Regulation (EC) No 2073/2005;
                    </li>
                    <li>
                        the guarantees covering live animals and products thereof provided by the residue plans submitted in accordance with Article 29 of Council
                        Directive 96/23/EC, are fulfilled and the concerned animals and products are listed in Commission Decision 2011/163/EU for the concerned
                        country of origin;
                    </li>
                    <li>
                        with regard to Chronic Wasting Disease (CWD):
                        <br></br>
                        {attestationData.health_1_7 ? (
                            <li>{ReactHtmlParser(WildGame8367Labels.health_1_7.label)}</li>
                        ) :
                            (
                                <li>N/A</li>
                            )}

                    </li>
                    <li>
                        the meat has been stored and transported in accordance with the relevant requirements in Section I, Chapter VII, of Annex III to Regulation (EC)
                        No 853/2004.
                    </li>
                </ol>
                <h6>II.2 Animal health attestation</h6>
                <p>I, the undersigned, hereby certify that the <strong>fresh meat</strong> described in Section A.2:</p>
                <ol type="1" className="attestation-II-2">
                    <li>
                        <strong><u></u></strong>
                        has been obtained in the <strong>zone(s)</strong> with code(s): <strong><u>{attestationData.animal_2_1_zone?.map(x => x.isoCode).join(", ")}</u></strong> which, at the date of issue of this support attestation is/are authorised for entry into the
                        Union of  <strong>fresh meat of wild animals of the family Bovidae (other than bovine, ovine and caprine animals), wild camelid animals and wild
                            cervid animals and </strong>, listed in Part 1 of Annex XIII to Commission Implementing Regulation (EU) 2021/404 and:
                        <ul>
                            <li>
                                in which infection with rinderpest virus has not been reported for a period of 12 months before the date of [slaughter](1) [killing](1)
                                of the animals from which the fresh meat was obtained, and during the same period vaccination against this disease has not been
                                carried out; and
                            </li>
                            {attestationData.animal_2_1_statements > 0 &&
                                <li>
                                    {findArrayElementByFieldNameAndValue(WildGame8367Labels.animal_2_1_statements, 'animal_2_1_statements', attestationData.animal_2_1_statements)}
                                </li>
                            }
                        </ul>
                    </li>
                    <li>
                        has been obtained <strong>from animals</strong> killed: on {moment(attestationData.animal_2_2AA).format("Do MMMM YYYY")} 
                        {attestationData.animal_2_2A ? (
                        <li>
                            between {moment(attestationData.animal_2_2AB).format("Do MMMM YYYY")} and {moment(attestationData.animal_2_2AC).format("Do MMMM YYYY")}
                        </li>
                      ) :
                      (
                          <li></li>
                      )}
                        
                  
                      
                    </li>
                    <li>
                        has been obtained <strong>in a game handling establishment</strong> in and around which foot and mouth disease and infection with rinderpest virus have not been
                        reported in an area of 10 km radius for a 30 day period prior to the date of killing.
                    </li>
                    {attestationData.animal_2_4_statements > 0 &&
                        <li>
                            has been strictly segregated from fresh meat not complying with the animal health requirements for the entry into the Union of fresh meat of wild
                            animals of the family Bovidae (other than bovine, ovine and caprine animals), wild camelid animals and wild cervid animals throughout the operations
                            of cutting and until:
                            {findArrayElementByFieldNameAndValue(WildGame8367Labels.animal_2_4_statements, 'animal_2_4_statements', attestationData.animal_2_4_statements)}
                        </li>
                    }
                    {attestationData.animal_2_5 ? (
                        <li>
                            {ReactHtmlParser(WildGame8367Labels.animal_2_5.label)}
                            <ul>
                                {attestationData.animal_2_5_A ? (
                                    <li>{ReactHtmlParser(WildGame8367Labels.animal_2_5_A.label)}</li>
                                ) :
                                    (
                                        <li>N/A</li>
                                    )}


                                {attestationData.animal_2_5_B ? (
                                    <li>{ReactHtmlParser(WildGame8367Labels.animal_2_5_B.label)}</li>
                                ) :
                                    (
                                        <li>N/A</li>
                                    )}
                            </ul>
                        </li>
                    ) :
                        (
                            <li>N/A</li>
                        )}
                    {/* <li>
                        contains only fresh meat complying with all the animal health requirements for entry into the Union of fresh meat laid down in the relevant
                        model certificate, and therefore eligible to enter into the Union as such, of the following species: <strong><u>{attestationData.speciesList.map(x => x.speciesGroupName).join(", ")}</u></strong>
                    </li> */}
                </ol>
                <h6>II.3 Animal welfare attestation</h6>
                <p>I, the undersigned, hereby certify, that the Meat preparation described in Section A.2 derive from animals which have been treated in the slaughterhouse
                    in accordance with the requirements of the Union legislation on the protection of animals at the time of killing or at least equivalent requirements.</p>

                {/* <PrettyPrintJson data={attestationData} >  </PrettyPrintJson> */}
                {/* <PrettyPrintJson data={compositeInfo} >  </PrettyPrintJson>
            <PrettyPrintJson data={speciesList} >  </PrettyPrintJson>  */}
            </>
        </>
    )
}

