import *  as baseService  from "./BaseService";

const getAll = () => {
    return baseService.getApiData("/Visibility/getAll");
};
const getByName = (name) => {
    return baseService.getApiData("/Visibility/getByName?name=" + name);
};
const VisibilityService = {
    getByName,
    getAll,
};

export default VisibilityService;