import * as Fishery8361Labels from "../Fishery8361Labels"
import { findArrayElementByFieldName, findArrayElementByFieldNameAndValue } from "../../../Attestations/Common/AttestationFunctions";
import React from 'react';

export const AttestationContent = (props) => {
    const { attestationData } = props;

    return (
        attestationData !== undefined &&
        <>

            <>
                <h5>II. Health Attestation</h5>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned, declare that I am aware of the relevant requirements of Regulation (EU) 2019/6 of the European Parliament and of the Council
                    and Commission Delegated Regulation (EU) 2023/905 and hereby certify that the fishery products of aquaculture origin described in Part I were
                    produced in accordance with these requirements, and in particular that the aquacultured animals from which the products have been derived have
                    not been administered antimicrobial medicinal products for growth promotion or yield increase or antimicrobial medicinal products containing an
                    antimicrobial that is included in the list of antimicrobials reserved for the treatment of certain infections in humans laid down in Commission
                    Implementing Regulation (EU) 2022/1255 as set out in Article 3 of Delegated Regulation (EU) 2023/905 and originate from a third country or region
                    thereof listed in accordance with Article 5(2) of Delegated Regulation (EU) 2023/905.
                </p>
                <ol type='a'>
                    <li> have been obtained in the region(s) or country(ies) <strong><u>{attestationData.fisheryCountryOrigin?.map(x => x.isoCode).join(", ")}</u></strong> which, at the date of issue of this animal
                        health/official certificate is/are authorised for the entry into the Union of fishery products and in
                        Annex IX to Commission Implementing Regulation (EU) 2021/405;
                    </li>
                    <li>
                        come from (an) establishment(s) applying general hygiene requirements and implementing a programme based on the hazard analysis and critical control points (HACCP) principles in accordance with Article 5 of Regulation (EC) No 852/2004, regularly audited by the competent
                        authorities, and being listed as an EU approved establishment; </li>
                    <li> have been caught and handled on board vessels, landed, handled and where appropriate prepared, processed, frozen and thawed hygienically
                        in compliance with the requirements laid down in Section VIII, Chapters I to IV, of Annex III to Regulation (EC) No 853/2004; </li>
                    <li> have not been stored in holds, tanks or containers used for other purposes than the production and/or storage of fishery products;</li>
                    <li> satisfy the health standards laid down in Section VIII, Chapter V, of Annex III to Regulation (EC) No 853/2004 and the criteria laid down in
                        Commission Regulation (EC) No 2073/2005;</li>
                    <li> have been packaged, stored and transported in compliance with Section VIII, Chapters VI to VIII, of Annex III to Regulation (EC) No 853/2004;</li>
                    <li> have been marked in accordance with Section I of Annex II to Regulation (EC) No 853/2004;</li>
                    <li> fulfill the guarantees covering live animals and products thereof, if of aquaculture origin, provided by the control plan submitted in accordance with Article 6(2) of Commission Delegated Regulation
                        (EU) 2022/2292 and the concerned animals and products are listed in Annex -I to Implementing Regulation (EU) 2021/405 for the concerned third country or territory;</li>
                    <li> for the live animals from wild catch and products thereof monitoring arrangements are in place to control compliance with the Union legislation on contaminants, in accordance with Commission
                        Regulation (EU) 2023/915 on maximum levels for certain contaminants in food and on pesticide residues and in accordance with Regulation (EC) No 396/2005 of the European Parliament and of
                        the Council on maximum residue levels of pesticides in or on food and feed of plant and animal origin;
                    </li>
                    <li> have satisfactorily undergone the official controls laid down in Articles 67 to 71 of Commission Implementing Regulation (EU) 2019/627.</li>
                </ol>
            </>
        </>
    )

}

