import React, { useEffect, useState, useRef } from 'react';
import ManufacturerDataService from "../../services/ManufacturerService";
import moment from "moment";
import { Card, Button, Col, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit} from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../../routes";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import ManufacturerInvite from "./ManufacturerInvite";
import ManufacturerDataAdminInvite from "./ManufacturerDataAdminInvite";
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes} from '../../../auth/PermissionsMap';

const ManufacturerViewPage  = (props) => {
    const [manufacturerId] = useState(props.match.params.id);
    const [manufacturer, setManufacturer] = useState([]);

    async function populateManufacturerData() {
        ManufacturerDataService.getById(manufacturerId)
        .then(response => {
            setManufacturer(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    }

    const openManufacturer = () => {
        props.history.push("/manufacturer/edit/" + manufacturerId);
    };

    useEffect(() => {
        populateManufacturerData();
    },[]);
    
    return (
        <div>
            <BreadcrumbNav 
                data={{
                    item1 : {name:'Manufacturers', link: Routes.ManufacturersListingPage.path},
                    item2 : {name: "Viewing " + manufacturer.manufacturerName}}
                }
            />
            <Row>
                <Col >
                    <h2 id="tabelLabel" >{manufacturer.manufacturerName}</h2>
                </Col>
            </Row>
            <Row className="">
                <Col md={8} xl={8}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col>
                                    <h4 className="mb-0">Manufacturer Details</h4>
                                </Col>
                                <Col className="text-end" md={2}>
                                    <Button onClick={openManufacturer} variant="light" size="sm"> 
                                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                                        <span>Edit</span>
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <ul className="list-unstyled">
                                <li>
                                    <p>
                                        <small className="d-block">Manufacturer Name:</small>
                                        <label>{manufacturer.manufacturerName!==null && manufacturer.manufacturerName!=="" ?(manufacturer.manufacturerName):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Supplier Code:</small>
                                        <label>{manufacturer.supplierCode!==null && manufacturer.supplierCode!==""?(manufacturer.supplierCode):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Address Line 1:</small>
                                        <label>{manufacturer.address1!==null && manufacturer.address1!==""?(manufacturer.address1):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Address Line 2:</small>
                                        <label>{manufacturer.address2!==null && manufacturer.address2!==""?(manufacturer.address2):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Town / City:</small>
                                        <label>{manufacturer.townCity!==null && manufacturer.townCity!==""?(manufacturer.townCity):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Postcode:</small>
                                        <label>{manufacturer.postcode!==null && manufacturer.postcode!==""?(manufacturer.postcode):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="text-gray-600 text-italic">Created on {moment(manufacturer.createdDate).format("Do MMM YYYY")}</small>
                                    </p>
                                </li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} xl={4}>
                    <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.invite.manufactureradmins]}>
                        <ManufacturerDataAdminInvite manufacturerName={manufacturer.manufacturerName} manufacturerId={manufacturerId}/>
                    </PermissionsCheck>
                    <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.invite.manufacturers]}>
                        <ManufacturerInvite manufacturerName={manufacturer.manufacturerName} manufacturerId={manufacturerId}/>
                    </PermissionsCheck>
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <div className="btn-panel left">
                        <Button variant="light" href="/manufacturers">
                            Back to Manufacturers
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )

}
export default ManufacturerViewPage;