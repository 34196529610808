import { Badge, Card, Table, Tab, Tabs } from '@themesberg/react-bootstrap';
import moment from "moment";
import React from "react";
import { Link, useHistory } from 'react-router-dom';
import Preloader from "../Preloader";
import { NoResults } from "./NoResults";
import { Routes } from "../../routes";
import BlobService from "../../pages/services/BlobService";

export const StorageExporterDeclarationTable = (props) => {
  const { storageDeclarationRequests, loaded=false } = props;
  const TableRow = (props) => {
    const {id, storageSupplierDeclaration, batches, signedDocument, product, manufacturer, uniqueCode, createdDate, productJson, validTo } = props;
    
    const openBlobPdf = (filename) => {
      BlobService.openBlobPdf(filename);
    }
    const getSADate = (date) => {
      var newDate = moment(date).format("Do MMM YYYY");
      if (newDate === "Invalid date") {
          return "";
      }
      return newDate;
    }
    const getDateTime = (date) => {
      var newDate = moment(date).format("DD/M/YY HH:mm");
      if (newDate === "Invalid date") {
          return "";
      }
      return newDate;
    }
    return (
      <tr>
        <td>
          <Card.Link 
            onClick={()=>openBlobPdf(signedDocument?.filename)}
            className="fw-normal text-black">
            <div className='mb-1'>{product?.productName}</div>
            <div className='mb-1'>{uniqueCode}</div>
          </Card.Link>
        </td>
        <td> 
          <span className="fw-normal">
            {JSON.parse(storageSupplierDeclaration?.productJson).manufacturer?.manufacturerName}
          </span>
        </td>
        <td> 
          <span className="fw-normal">
          {batches}
          </span>
        </td>
        <td> 
          <span className="fw-normal">
           {getDateTime(createdDate)}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Preloader component={true} show={!loaded} />
      <Table hidden={!loaded || storageDeclarationRequests==0} hover className="user-table align-items-center">
        <thead>
          <tr>
            <th className="border-bottom">Product</th>
            <th className="border-bottom">Manufacturer</th>
            <th className="border-bottom">Batches</th>
            <th className="border-bottom">Created date</th>
          </tr>
        </thead>
        <tbody>
            {storageDeclarationRequests?.map(t => <TableRow key={`transaction-${t.id}`} {...t} />)}

          
        </tbody>
      </Table>
        {loaded && storageDeclarationRequests?.length == 0 &&(
          <NoResults name="storage declarations"/>
        )}
      </>
  );
};

export default function Td({ children, to, id }) {
  // Conditionally wrapping content into a link
  const ContentTag = to ? Link : 'div';
  
  const history = useHistory();
  const _viewStorageDeclarationRequest = (filename) => {
    history.push(Routes.Product.path.replace(":id", id));
  };
  return (
    <td style={{ cursor: "pointer", color:"text-dark" }} onClick={()=>_viewStorageDeclarationRequest(id)}>
      <ContentTag className="text-dark"  to={to}>{children}</ContentTag>
    </td>
  );
}