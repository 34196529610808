export const ScraperFilterConfig = () => {
return [
    {
        hasMenu: true,
        title: "Countries",
        name: "countryCode", 
        defaultValue:"GB",
        data: [
            {"value": "AD", "label": "Andorra", "group": "countries"},
            {"value": "AE", "label": "United Arab Emirates", "group": "countries"},
            {"value": "AF", "label": "Afghanistan", "group": "countries"},
            {"value": "AG", "label": "Antigua and Barbuda", "group": "countries"},
            {"value": "AI", "label": "Anguilla", "group": "countries"},
            {"value": "AL", "label": "Albania", "group": "countries"},
            {"value": "AM", "label": "Armenia", "group": "countries"},
            {"value": "AN", "label": "Netherlands Antilles", "group": "countries"},
            {"value": "AO", "label": "Angola", "group": "countries"},
            {"value": "AQ", "label": "Antarctica", "group": "countries"},
            {"value": "AR", "label": "Argentina", "group": "countries"},
            {"value": "AS", "label": "American Samoa", "group": "countries"},
            {"value": "AT", "label": "Austria", "group": "countries"},
            {"value": "AU", "label": "Australia", "group": "countries"},
            {"value": "AW", "label": "Aruba", "group": "countries"},
            {"value": "AX", "label": "Åland Islands", "group": "countries"},
            {"value": "AZ", "label": "Azerbaijan", "group": "countries"},
            {"value": "BA", "label": "Bosnia and Herzegovina", "group": "countries"},
            {"value": "BB", "label": "Barbados", "group": "countries"},
            {"value": "BD", "label": "Bangladesh", "group": "countries"},
            {"value": "BE", "label": "Belgium", "group": "countries"},
            {"value": "BF", "label": "Burkina Faso", "group": "countries"},
            {"value": "BG", "label": "Bulgaria", "group": "countries"},
            {"value": "BH", "label": "Bahrain", "group": "countries"},
            {"value": "BI", "label": "Burundi", "group": "countries"},
            {"value": "BJ", "label": "Benin", "group": "countries"},
            {"value": "BL", "label": "Saint Barthélemy", "group": "countries"},
            {"value": "BM", "label": "Bermuda", "group": "countries"},
            {"value": "BN", "label": "Brunei Darussalam", "group": "countries"},
            {"value": "BO", "label": "Bolivia (Plurinational State of)", "group": "countries"},
            {"value": "BQ", "label": "Bonaire, Sint Eustatius and Saba", "group": "countries"},
            {"value": "BR", "label": "Brazil", "group": "countries"},
            {"value": "BS", "label": "Bahamas", "group": "countries"},
            {"value": "BT", "label": "Bhutan", "group": "countries"},
            {"value": "BV", "label": "Bouvet Island", "group": "countries"},
            {"value": "BW", "label": "Botswana", "group": "countries"},
            {"value": "BY", "label": "Belarus", "group": "countries"},
            {"value": "BZ", "label": "Belize", "group": "countries"},
            {"value": "CA", "label": "Canada", "group": "countries"},
            {"value": "CC", "label": "Cocos (Keeling) Islands", "group": "countries"},
            {"value": "CD", "label": "Congo, Democratic Republic of the", "group": "countries"},
            {"value": "CF", "label": "Central African Republic", "group": "countries"},
            {"value": "CG", "label": "Congo", "group": "countries"},
            {"value": "CH", "label": "Switzerland", "group": "countries"},
            {"value": "CI", "label": "Côte d'Ivoire", "group": "countries"},
            {"value": "CK", "label": "Cook Islands", "group": "countries"},
            {"value": "CL", "label": "Chile", "group": "countries"},
            {"value": "CM", "label": "Cameroon", "group": "countries"},
            {"value": "CN", "label": "China", "group": "countries"},
            {"value": "CO", "label": "Colombia", "group": "countries"},
            {"value": "CR", "label": "Costa Rica", "group": "countries"},
            {"value": "CU", "label": "Cuba", "group": "countries"},
            {"value": "CV", "label": "Cabo Verde", "group": "countries"},
            {"value": "CW", "label": "Curaçao", "group": "countries"},
            {"value": "CX", "label": "Christmas Island", "group": "countries"},
            {"value": "CY", "label": "Cyprus", "group": "countries"},
            {"value": "CZ", "label": "Czechia", "group": "countries"},
            {"value": "DE", "label": "Germany", "group": "countries"},
            {"value": "DJ", "label": "Djibouti", "group": "countries"},
            {"value": "DK", "label": "Denmark", "group": "countries"},
            {"value": "DM", "label": "Dominica", "group": "countries"},
            {"value": "DO", "label": "Dominican Republic", "group": "countries"},
            {"value": "DZ", "label": "Algeria", "group": "countries"},
            {"value": "EC", "label": "Ecuador", "group": "countries"},
            {"value": "EE", "label": "Estonia", "group": "countries"},
            {"value": "EG", "label": "Egypt", "group": "countries"},
            {"value": "EH", "label": "Western Sahara", "group": "countries"},
            {"value": "ER", "label": "Eritrea", "group": "countries"},
            {"value": "ES", "label": "Spain", "group": "countries"},
            {"value": "ET", "label": "Ethiopia", "group": "countries"},
            {"value": "FI", "label": "Finland", "group": "countries"},
            {"value": "FJ", "label": "Fiji", "group": "countries"},
            {"value": "FK", "label": "Falkland Islands (Malvinas)", "group": "countries"},
            {"value": "FM", "label": "Micronesia (Federated States of)", "group": "countries"},
            {"value": "FO", "label": "Faroe Islands", "group": "countries"},
            {"value": "FR", "label": "France", "group": "countries"},
            {"value": "GA", "label": "Gabon", "group": "countries"},
            {"value": "GB", "label": "Great Britain", "group": "countries"},
            {"value": "GD", "label": "Grenada", "group": "countries"},
            {"value": "GE", "label": "Georgia", "group": "countries"},
            {"value": "GF", "label": "French Guiana", "group": "countries"},
            {"value": "GG", "label": "Guernsey", "group": "countries"},
            {"value": "GH", "label": "Ghana", "group": "countries"},
            {"value": "GI", "label": "Gibraltar", "group": "countries"},
            {"value": "GL", "label": "Greenland", "group": "countries"},
            {"value": "GM", "label": "Gambia", "group": "countries"},
            {"value": "GN", "label": "Guinea", "group": "countries"},
            {"value": "GP", "label": "Guadeloupe", "group": "countries"},
            {"value": "GQ", "label": "Equatorial Guinea", "group": "countries"},
            {"value": "GR", "label": "Greece", "group": "countries"},
            {"value": "GS", "label": "South Georgia and the South Sandwich Islands", "group": "countries"},
            {"value": "GT", "label": "Guatemala", "group": "countries"},
            {"value": "GU", "label": "Guam", "group": "countries"},
            {"value": "GW", "label": "Guinea-Bissau", "group": "countries"},
            {"value": "GY", "label": "Guyana", "group": "countries"},
            {"value": "HK", "label": "Hong Kong", "group": "countries"},
            {"value": "HM", "label": "Heard Island and McDonald Islands", "group": "countries"},
            {"value": "HN", "label": "Honduras", "group": "countries"},
            {"value": "HR", "label": "Croatia", "group": "countries"},
            {"value": "HT", "label": "Haiti", "group": "countries"},
            {"value": "HU", "label": "Hungary", "group": "countries"},
            {"value": "ID", "label": "Indonesia", "group": "countries"},
            {"value": "IE", "label": "Republic of Ireland", "group": "countries"},
            {"value": "IL", "label": "Israel", "group": "countries"},
            {"value": "IM", "label": "Isle of Man", "group": "countries"},
            {"value": "IN", "label": "India", "group": "countries"},
            {"value": "IO", "label": "British Indian Ocean Territory", "group": "countries"},
            {"value": "IQ", "label": "Iraq", "group": "countries"},
            {"value": "IR", "label": "Iran (Islamic Republic of)", "group": "countries"},
            {"value": "IS", "label": "Iceland", "group": "countries"},
            {"value": "IT", "label": "Italy", "group": "countries"},
            {"value": "JE", "label": "Jersey", "group": "countries"},
            {"value": "JM", "label": "Jamaica", "group": "countries"},
            {"value": "JO", "label": "Jordan", "group": "countries"},
            {"value": "JP", "label": "Japan", "group": "countries"},
            {"value": "KE", "label": "Kenya", "group": "countries"},
            {"value": "KG", "label": "Kyrgyzstan", "group": "countries"},
            {"value": "KH", "label": "Cambodia", "group": "countries"},
            {"value": "KI", "label": "Kiribati", "group": "countries"},
            {"value": "KM", "label": "Comoros", "group": "countries"},
            {"value": "KN", "label": "Saint Kitts and Nevis", "group": "countries"},
            {"value": "KP", "label": "Korea (Democratic People's Republic of)", "group": "countries"},
            {"value": "KR", "label": "South Korea", "group": "countries"},
            {"value": "KW", "label": "Kuwait", "group": "countries"},
            {"value": "KY", "label": "Cayman Islands", "group": "countries"},
            {"value": "KZ", "label": "Kazakhstan", "group": "countries"},
            {"value": "LA", "label": "Lao People's Democratic Republic", "group": "countries"},
            {"value": "LB", "label": "Lebanon", "group": "countries"},
            {"value": "LC", "label": "Saint Lucia", "group": "countries"},
            {"value": "LI", "label": "Liechtenstein", "group": "countries"},
            {"value": "LK", "label": "Sri Lanka", "group": "countries"},
            {"value": "LR", "label": "Liberia", "group": "countries"},
            {"value": "LS", "label": "Lesotho", "group": "countries"},
            {"value": "LT", "label": "Lithuania", "group": "countries"},
            {"value": "LU", "label": "Luxembourg", "group": "countries"},
            {"value": "LV", "label": "Latvia", "group": "countries"},
            {"value": "LY", "label": "Libya", "group": "countries"},
            {"value": "MA", "label": "Morocco", "group": "countries"},
            {"value": "MC", "label": "Monaco", "group": "countries"},
            {"value": "MD", "label": "Moldova, Republic of", "group": "countries"},
            {"value": "ME", "label": "Montenegro", "group": "countries"},
            {"value": "MF", "label": "Saint Martin (French part)", "group": "countries"},
            {"value": "MG", "label": "Madagascar", "group": "countries"},
            {"value": "MH", "label": "Marshall Islands", "group": "countries"},
            {"value": "MK", "label": "North Macedonia", "group": "countries"},
            {"value": "ML", "label": "Mali", "group": "countries"},
            {"value": "MM", "label": "Myanmar", "group": "countries"},
            {"value": "MN", "label": "Mongolia", "group": "countries"},
            {"value": "MO", "label": "Macao", "group": "countries"},
            {"value": "MP", "label": "Northern Mariana Islands", "group": "countries"},
            {"value": "MQ", "label": "Martinique", "group": "countries"},
            {"value": "MR", "label": "Mauritania", "group": "countries"},
            {"value": "MS", "label": "Montserrat", "group": "countries"},
            {"value": "MT", "label": "Malta", "group": "countries"},
            {"value": "MU", "label": "Mauritius", "group": "countries"},
            {"value": "MV", "label": "Maldives", "group": "countries"},
            {"value": "MW", "label": "Malawi", "group": "countries"},
            {"value": "MX", "label": "Mexico", "group": "countries"},
            {"value": "MY", "label": "Malaysia", "group": "countries"},
            {"value": "MZ", "label": "Mozambique", "group": "countries"},
            {"value": "NA", "label": "Namibia", "group": "countries"},
            {"value": "NC", "label": "New Caledonia", "group": "countries"},
            {"value": "NE", "label": "Niger", "group": "countries"},
            {"value": "NF", "label": "Norfolk Island", "group": "countries"},
            {"value": "NG", "label": "Nigeria", "group": "countries"},
            {"value": "NI", "label": "Nicaragua", "group": "countries"},
            {"value": "NL", "label": "Netherlands", "group": "countries"},
            {"value": "NO", "label": "Norway", "group": "countries"},
            {"value": "NP", "label": "Nepal", "group": "countries"},
            {"value": "NR", "label": "Nauru", "group": "countries"},
            {"value": "NU", "label": "Niue", "group": "countries"},
            {"value": "NZ", "label": "New Zealand", "group": "countries"},
            {"value": "OM", "label": "Oman", "group": "countries"},
            {"value": "PA", "label": "Panama", "group": "countries"},
            {"value": "PE", "label": "Peru", "group": "countries"},
            {"value": "PF", "label": "French Polynesia", "group": "countries"},
            {"value": "PG", "label": "Papua New Guinea", "group": "countries"},
            {"value": "PH", "label": "Philippines", "group": "countries"},
            {"value": "PK", "label": "Pakistan", "group": "countries"},
            {"value": "PL", "label": "Poland", "group": "countries"},
            {"value": "PM", "label": "Saint Pierre and Miquelon", "group": "countries"},
            {"value": "PN", "label": "Pitcairn", "group": "countries"},
            {"value": "PR", "label": "Puerto Rico", "group": "countries"},
            {"value": "PS", "label": "Palestine, State of", "group": "countries"},
            {"value": "PT", "label": "Portugal", "group": "countries"},
            {"value": "PW", "label": "Palau", "group": "countries"},
            {"value": "PY", "label": "Paraguay", "group": "countries"},
            {"value": "QA", "label": "Qatar", "group": "countries"},
            {"value": "RE", "label": "Réunion", "group": "countries"},
            {"value": "RO", "label": "Romania", "group": "countries"},
            {"value": "RS", "label": "Serbia", "group": "countries"},
            {"value": "RU", "label": "Russian Federation", "group": "countries"},
            {"value": "RW", "label": "Rwanda", "group": "countries"},
            {"value": "SA", "label": "Saudi Arabia", "group": "countries"},
            {"value": "SB", "label": "Solomon Islands", "group": "countries"},
            {"value": "SC", "label": "Seychelles", "group": "countries"},
            {"value": "SD", "label": "Sudan", "group": "countries"},
            {"value": "SE", "label": "Sweden", "group": "countries"},
            {"value": "SG", "label": "Singapore", "group": "countries"},
            {"value": "SH", "label": "Saint Helena, Ascension and Tristan da Cunha", "group": "countries"},
            {"value": "SI", "label": "Slovenia", "group": "countries"},
            {"value": "SJ", "label": "Svalbard and Jan Mayen", "group": "countries"},
            {"value": "SK", "label": "Slovakia", "group": "countries"},
            {"value": "SL", "label": "Sierra Leone", "group": "countries"},
            {"value": "SM", "label": "San Marino", "group": "countries"},
            {"value": "SN", "label": "Senegal", "group": "countries"},
            {"value": "SO", "label": "Somalia", "group": "countries"},
            {"value": "SR", "label": "Suriname", "group": "countries"},
            {"value": "SS", "label": "South Sudan", "group": "countries"},
            {"value": "ST", "label": "Sao Tome and Principe", "group": "countries"},
            {"value": "SV", "label": "El Salvador", "group": "countries"},
            {"value": "SX", "label": "Sint Maarten (Dutch part)", "group": "countries"},
            {"value": "SY", "label": "Syrian Arab Republic", "group": "countries"},
            {"value": "SZ", "label": "Eswatini", "group": "countries"},
            {"value": "TC", "label": "Turks and Caicos Islands", "group": "countries"},
            {"value": "TD", "label": "Chad", "group": "countries"},
            {"value": "TF", "label": "French Southern Territories", "group": "countries"},
            {"value": "TG", "label": "Togo", "group": "countries"},
            {"value": "TH", "label": "Thailand", "group": "countries"},
            {"value": "TJ", "label": "Tajikistan", "group": "countries"},
            {"value": "TK", "label": "Tokelau", "group": "countries"},
            {"value": "TL", "label": "Timor-Leste", "group": "countries"},
            {"value": "TM", "label": "Turkmenistan", "group": "countries"},
            {"value": "TN", "label": "Tunisia", "group": "countries"},
            {"value": "TO", "label": "Tonga", "group": "countries"},
            {"value": "TR", "label": "Turkey", "group": "countries"},
            {"value": "TT", "label": "Trinidad and Tobago", "group": "countries"},
            {"value": "TV", "label": "Tuvalu", "group": "countries"},
            {"value": "TW", "label": "Taiwan, Province of China", "group": "countries"},
            {"value": "TZ", "label": "Tanzania, United Republic of", "group": "countries"},
            {"value": "UA", "label": "Ukraine", "group": "countries"},
            {"value": "UG", "label": "Uganda", "group": "countries"},
            {"value": "UM", "label": "United States Minor Outlying Islands", "group": "countries"},
            {"value": "US", "label": "United States of America", "group": "countries"},
            {"value": "UY", "label": "Uruguay", "group": "countries"},
            {"value": "UZ", "label": "Uzbekistan", "group": "countries"},
            {"value": "VA", "label": "Holy See", "group": "countries"},
            {"value": "VC", "label": "Saint Vincent and the Grenadines", "group": "countries"},
            {"value": "VE", "label": "Venezuela (Bolivarian Republic of)", "group": "countries"},
            {"value": "VG", "label": "Virgin Islands (British)", "group": "countries"},
            {"value": "VI", "label": "Virgin Islands (U.S.)", "group": "countries"},
            {"value": "VN", "label": "Viet Nam", "group": "countries"},
            {"value": "VU", "label": "Vanuatu", "group": "countries"},
            {"value": "WF", "label": "Wallis and Futuna", "group": "countries"},
            {"value": "WS", "label": "Samoa", "group": "countries"},
            {"value": "XI", "label": "Northern Ireland", "group": "countries"},
            {"value": "XK", "label": "Kosovo", "group": "countries"},
            {"value": "YE", "label": "Yemen", "group": "countries"},
            {"value": "YT", "label": "Mayotte", "group": "countries"},
            {"value": "ZA", "label": "South Africa", "group": "countries"},
            {"value": "ZM", "label": "Zambia", "group": "countries"},
            {"value": "ZW", "label": "Zimbabwe", "group": "countries"}
        ],        
        hasSearch: true,
        isFilter: true
    },
    {
        hasMenu: true,
        title: "Classifications",
        name: "classificationCode", 
        data: [
                {"value": "CAS", "label": "Treated stomachs, bladders and intestines: casings only", "group": "products"},
                {"value": "COL", "label": "Collagen", "group": "products"},
                {"value": "EEP", "label": "Eggs and egg products (XI)", "group": "products"},
                {"value": "EPP", "label": "Eggs and egg products", "group": "products"},
                {"value": "FAT", "label": "Rendered animal fats and greaves", "group": "products"},
                {"value": "FFP", "label": "Fishery products", "group": "products"},
                {"value": "FLS", "label": "Frogs' legs and snails", "group": "products"},
                {"value": "GEL", "label": "Gelatine", "group": "products"},
                {"value": "GEN", "label": "General activity establishment - non-EU", "group": "products"},
                {"value": "GM", "label": "Meat of farmed game", "group": "products"},
                {"value": "HRP", "label": "Highly refined products", "group": "products"},
                {"value": "LBM", "label": "Live bivalve molluscs", "group": "products"},
                {"value": "MM", "label": "Minced meat, meat preparations and mechanically separated meat", "group": "products"},
                {"value": "MMP", "label": "Raw milk, dairy products, colostrum and colostrum-based products", "group": "products"},
                {"value": "PM", "label": "Meat from poultry and lagomorphs", "group": "products"},
                {"value": "RCG", "label": "Raw materials for the production of gelatine and collagen", "group": "products"},
                {"value": "RM", "label": "Meat of domestic ungulates", "group": "products"},
                {"value": "RPM", "label": "Meat products", "group": "products"},
                {"value": "SPR", "label": "Sprouts", "group": "products"},
                {"value": "TCG", "label": "Treated raw material for the production of gelatine and collagen", "group": "products"},
                {"value": "WM", "label": "Wild game meat", "group": "products"}
        ],
        hasSearch: false,
        isFilter: true
    }
  ] 
};