import * as Poultry8371Labels from "../Poultry8371Labels"
import { findArrayElementByFieldNameAndValue } from "../../../Attestations/Common/AttestationFunctions";
import moment from "moment-timezone";
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
export const AttestationContent = (props) => {
    const { attestationData } = props;

    const extractIsoCodes = (values) => {
        const isoCodes = [];
        if (values?.animal_2_1_Zone_FarmOfOrigin)
            isoCodes.push(`Farm of origin: ${values.animal_2_1_Zone_FarmOfOrigin.map(x => x.isoCode).join(', ')}`);
        if (values?.animal_2_1_Zone_Hatchery)
            isoCodes.push(`Hatchery: ${values.animal_2_1_Zone_Hatchery.map(x => x.isoCode).join(', ')}`);
        if (values?.animal_2_1_Zone_Slaughterhouse)
            isoCodes.push(`Slaughterhouse of origin: ${values.animal_2_1_Zone_Slaughterhouse.map(x => x.isoCode).join(', ')}`);
        return isoCodes.join(', ');
    };


    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);
    return (
        <>
            <h5>II. Health Attestation</h5>
            <>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned registered veterinarian, declare that I am aware of the relevant requirements of Regulation (EC) No 178/2002 of the
                    European Parliament and of the Council, Regulation (EC) No 852/2004 of the European Parliament and of the Council, Regulation (EC)
                    No 853/2004 of the European Parliament and of the Council, Regulation (EU) 2017/625 of the European Parliament and of the Council,
                    Commission Delegated Regulation (EU) 2019/624 and Commission Implementing Regulation (EU) 2019/627 and hereby certify that the
                    fresh meat(1) of poultry other than ratites described in Part I has been obtained in accordance with these requirements, and in particular
                    that:
                </p>
                <ol className="attestation-II-1">
                    <ol type="a" >
                        <li>
                            the meat comes from (an) establishment(s) applying general hygiene requirements and implementing a programme based on the hazard analysis
                            and critical control points (HACCP) principles in accordance with Article 5 of Regulation (EC) No 852/2004, regularly audited by the competent
                            authorities, and being listed as an Union approved establishment;
                        </li>
                        <li>
                            the meat has been produced in compliance with the conditions set out in Sections II and V of Annex III to Regulation (EC) No 853/2004;                    </li>
                        <li>
                            the meat has been found fit for human consumption following ante-mortem and post-mortem inspections carried out in
                            accordance with Articles 8 to 14, 25, 33, 35 to 38 of Implementing Regulation (EU) 2019/627 and Articles 3, 5 to 8 of Delegated
                            Regulation (EU) 2019/624;
                        </li>
                        <li>
                            the meat has been marked with an identification mark in accordance with Section I of Annex II to Regulation (EC) No
                            853/2004;
                        </li>
                        <li>
                            the meat satisfies the relevant criteria laid down in Commission Regulation (EC) No 2073/2005;
                        </li>
                        <li>
                            the guarantees covering live animals and products thereof provided by the residue plans submitted in accordance with Article
                            6(2) of Commission Delegated Regulation (EU) 2022/2292 are fulfilled and the concerned animals and products are listed in
                            Annex -I to Commission Implementing Regulation (EU) 2021/405 for the concerned third country or territory;
                        </li>
                        {attestationData.health_1_H ? (
                            <li>{ReactHtmlParser(Poultry8371Labels.health_1_H.label)}</li>
                        ) :
                            (
                                <li>N/A</li>
                            )}
                    </ol>
                </ol>

                <h6>II.2 Animal health attestation</h6>
                <p>I, the undersigned registered veterinarian, hereby certify, that the <strong>fresh meat of poultry other than ratites</strong> described in this support attestation:</p>
                <ol type="1" className="attestation-II-2">
                    <li>
                        <strong><u></u></strong>
                        has been obtained in the <strong>zone(s)</strong> with code(s): <strong><u>{extractIsoCodes(attestationData)}</u></strong> which, at the date of issue of this support attestation:
                        <ol type="a" >
                            <li>
                                is authorised and listed in Part 1 of Annex XIV to Commission Implementing Regulation (EU) 2021/404 for the entry into the Union
                                of fresh meat of poultry other than ratites;
                            </li>
                            <li>
                                carries out a disease surveillance programme for highly pathogenic avian influenza in accordance with Article 141, point (a), of
                                Commission Delegated Regulation (EU) 2020/692;
                            </li>
                            <li>
                                is considered free from highly pathogenic avian influenza in accordance with Article 38 of Delegated Regulation (EU) 2020/692;
                            </li>
                            <li>
                                is considered free from infection with Newcastle disease virus in accordance with Article 39 of Delegated Regulation (EU) 2020/692;
                            </li>
                        </ol>
                    </li>
                    <li>
                        has been obtained in the zone referred to in point II.2.1, in which:
                        <ol type="a" >
                            {
                                attestationData.animal_2_2_A_statements > 0 &&
                                <li>
                                    {findArrayElementByFieldNameAndValue(Poultry8371Labels.animal_2_2_A_statements, 'animal_2_2_A_statements', attestationData.animal_2_2_A_statements)}
                                </li>
                            }
                            {
                                attestationData.animal_2_2_B_statements > 0 &&
                                <li>
                                    {findArrayElementByFieldNameAndValue(Poultry8371Labels.animal_2_2_B_statements, 'animal_2_2_B_statements', attestationData.animal_2_2_B_statements)}
                                </li>
                            }
                        </ol>
                    </li>
                    <li>
                        has been obtained <u>from animals coming from establishments:</u>
                        <ol type="a" >
                            <li>
                                registered by and under the control of the competent authority of the country or territory of origin and have a system in place to
                                maintain and to keep records, in accordance with Article 8 of Delegated Regulation (EU) 2020/692;
                            </li>
                            <li>
                                which receive regular animal health visits from a veterinarian for the purpose of the detection of, and information on,
                                signs indicative of the occurrence of diseases, including the relevant listed diseases referred to in Annex I to Delegated
                                Regulation (EU) 2020/692 relevant for the species and emerging diseases;
                            </li>
                            <li>
                                in and around which, within an area of 10 km radius, including, where appropriate, the territory of a neighbouring
                                country, there has been no outbreak of highly pathogenic avian influenza or infection with Newcastle disease virus
                                during the period of at least 30 days prior to the date of slaughter of the poultry;
                            </li>
                            <li>
                                which, at the time of their slaughter, were not subject to national restriction measures for animal health reasons,
                                including the listed diseases referred to in Annex I to Delegated Regulation (EU) 2020/692 relevant for the species
                                and emerging diseases;
                            </li>
                        </ol>
                    </li>
                    <li>
                        has been obtained <u>from animals that:</u>
                        <ol type="a" >
                            {
                                attestationData.animal_2_4_A_statements > 0 &&
                                <li>
                                    {findArrayElementByFieldNameAndValue(Poultry8371Labels.animal_2_4_A_statements, 'animal_2_4_A_statements', attestationData.animal_2_4_A_statements)}
                                </li>
                            }
                            <ol type="i" >
                                {
                                    attestationData.animal_2_4_A_a_statements > 0 &&
                                    <li>
                                        have remained in {findArrayElementByFieldNameAndValue(Poultry8371Labels.animal_2_4_A_a_statements, 'animal_2_4_A_a_statements', attestationData.animal_2_4_A_a_statements)}
                                    </li>
                                }
                            </ol>
                            {
                                attestationData.animal_2_4_B_statements > 0 &&
                                <li>
                                    {findArrayElementByFieldNameAndValue(Poultry8371Labels.animal_2_4_B_statements, 'animal_2_4_B_statements', attestationData.animal_2_4_B_statements)}
                                </li>
                            }
                            {
                                attestationData.animal_2_4_C_statements > 0 &&
                                <li>
                                    {findArrayElementByFieldNameAndValue(Poultry8371Labels.animal_2_4_C_statements, 'animal_2_4_C_statements', attestationData.animal_2_4_C_statements)}
                                </li>
                            }
                            <li>
                                did not show symptoms of transmissible diseases at the time of slaughter;
                            </li>
                            <li>
                                were dispatched directly from their establishment of origin to the slaughterhouse;
                            </li>
                            <li>
                                during their transport to the slaughterhouse:
                                <ol type="i" >
                                    {
                                        attestationData.animal_2_4_F_statements > 0 &&
                                        <li>
                                            {findArrayElementByFieldNameAndValue(Poultry8371Labels.animal_2_4_F_statements, 'animal_2_4_F_statements', attestationData.animal_2_4_F_statements)}
                                        </li>
                                    }
                                    <li>
                                        did not come in contact with animals of a lower health status;
                                    </li>
                                </ol>
                            </li>
                            <li>
                                have been dispatched from their establishment of origin to an approved slaughterhouse in means of transport:
                                <ol type="i" >
                                    <li>
                                        which is constructed in such a way that the birds cannot escape or fall out;
                                    </li>
                                    <li>
                                        in which visual inspection of the space where animals are kept is possible;
                                    </li>
                                    <li>
                                        from which the escape of animal excrements, litter, feed or feathers is prevented or minimised;
                                    </li>
                                    <li>
                                        which was cleaned and disinfected with a disinfectant authorised by the competent authority of the third country or territory
                                        of dispatch, and dried or allowed to dry immediately before every loading of animals intended for entry into the Union;
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span className="no-wrap">
                            has been obtained <strong>from birds</strong> which have been slaughtered on <strong>{moment(attestationData.animal_2_5_A).format("Do MMMM YYYY")} </strong>
                            {attestationData.animal_2_5_B && attestationData.animal_2_5_C ? (
                                <>
                                    between <strong>{moment(attestationData.animal_2_5_B).format("Do MMMM YYYY")}</strong> and <strong>{moment(attestationData.animal_2_5_C).format("Do MMMM YYYY")}</strong>
                                </>
                            ) :
                                (
                                    <br></br>
                                )
                            }
                        </span>
                    </li>
                    <li>
                        has not been obtained <strong>from birds</strong> which have been slaughtered under a national programme for the eradication of diseases;
                    </li>
                    <li>
                        has been obtained in a slaughterhouse:
                        <ol type="a" >
                            <li>
                                which at the time of slaughter, was not under restrictions due to an outbreak of highly pathogenic avian influenza or infection with Newcastle disease virus or under official restrictions under national legislation for animal health reasons;
                            </li>
                            <li>
                                within a 10 km radius of which, including, where appropriate, the territory of a neighbouring country, there has been no outbreak of
                                highly pathogenic avian influenza or infection with Newcastle disease virus during the period of at least 30 days prior to the date of
                                slaughter;
                            </li>
                        </ol>
                    </li>
                    <li>
                        has been strictly segregated from fresh meat not complying with the animal health requirements for the entry into the Union of fresh meat of
                        poultry other than ratites throughout the operations of slaughter, cutting and until:
                        <ol type="a" >                            {
                            attestationData.animal_2_8_statements > 0 &&
                            <li>
                                {findArrayElementByFieldNameAndValue(Poultry8371Labels.animal_2_8_statements, 'animal_2_8_statements', attestationData.animal_2_8_statements)}
                            </li>
                        }
                        </ol>
                    </li>
                    <li>
                        is dispatched to the Union:
                        <ul>

                            <li>
                                in a means of transport designed, constructed and maintained in such condition that the health status of the products will not be
                                jeopardised during the transport to the Union;
                            </li>
                            <li>
                                separated <strong>from birds</strong> and products of animal origin not complying with the relevant animal health requirements for entry into the
                                Union provided for in Delegated Regulation (EU) 2020/692;
                            </li>
                        </ul>
                    </li>
                    {attestationData.animal_2_10 ? (
                        <li>{ReactHtmlParser(Poultry8371Labels.animal_2_10.label)}</li>
                    ) :
                        (
                            <li>N/A</li>
                        )}
                </ol>
                <h6>II.3 Animal welfare attestation</h6>
                <p>I, the undersigned registered veterinarian, hereby certify, that the meat described in Section A.2 derives from animals which have been treated in the slaughterhouse in
                    accordance with the requirements of the Union legislation on the protection of animals at the time of killing or at least equivalent requirements.</p>
                {/* 
                <PrettyPrintJson data={attestationData} >  </PrettyPrintJson>
                <PrettyPrintJson data={compositeInfo} >  </PrettyPrintJson>
            <PrettyPrintJson data={speciesList} >  </PrettyPrintJson>  */}
            </>
        </>
    )
}

