import *  as baseService  from "./BaseService";

const getAll = () => {
    return baseService.getApiData("/CertificateBank/getall");
};

const getById = (id) => {
    return baseService.getApiData("/CertificateBank/GetById?id=" + id);
};

const upload = (data) => {
    return baseService.postApiData("/CertificateBank/UploadCertificatesAsZip", data);
};

const getAllPaged = (params) => {
    return baseService.getApiDataWithParams("/CertificateBank/GetAllPaged", params);
};
const remove = (id) => {
    return baseService.deleteApiData("/CertificateBank/Delete?id=" + id);
};

const removeByCertificateId = (certificateId) => {
    return baseService.deleteApiData("/CertificateAssigned/DeleteByCertificateId?certificateId=" + certificateId);
};


const CertificateBankService = {
    getAll,
    getById,
    upload,
    getAllPaged,
    remove,
    removeByCertificateId
};

export default CertificateBankService;