import React, { useState, useEffect, useRef } from 'react';
import { Routes } from "../../../../../routes";
import BreadcrumbNav from "../../../../../components/BreadcrumbNav";
import { getUserInfo } from "../../../../../auth/authorisation";
import { Formik, Form } from 'formik';
import Stepper from "../../../../../components/Stepper";
import { Button, Alert, Col, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import AttestationService from "../../../../services/AttestationService"
import RequestAttestation from "./Forms/RequestAttestationForm";
import RequestAttestationReview from "./Forms/RequestAttestationReview";
import validationSchema from './Forms/Models/GEFSValidation';
import formModel from './Forms/Models/GEFSModel';
import formInitialValues from './Forms/Models/GEFSValues';
import VetsService from "../../../../services/VetService"
import CertificateNumberService from "../../../../services/CertificateNumberService";
import SiteDataService from "../../../../services/SiteService"
import ProductDataService from "../../../../services/ProductService"
import OrganisationService from '../../../../services/OrganisationService';
import ManufacturerDataService from "../../../../services/ManufacturerService";
import PlatformDataService from "../../../../services/PlatformService";
import { GetCertificateNumbers, GetCertificateFormData, GetCertificateVersions } from '../../../CertificateForms/CertificateFormFunctions';
import FormSubmission from "../../../common/FormSubmission";
import AttestationAlerts from "../../Common/AttestationAlerts";
import ActivityService from '../../../../services/ActivityService';

const steps = ['Attestation Details', 'Review & Sign'];
const { formId, formField } = formModel;

function _renderStepContent(step, setFieldValue, setFieldTouched, touched, errors, productId, user, values, childRef, submitHandler, isSubmitting, uniqueRef, isCustomUrn, product, manufacturer, platform, vets, organisation, site, productDetail, attestationData, generatedSADocument, certificateNumber) {
  switch (step) {
    case 0:
      return <RequestAttestation
        formField={formField}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        touched={touched} errors={errors}
        productId={productId}
        user={user}
        values={values}
        uniqueRef={uniqueRef}
        isCustomUrn={isCustomUrn}
        product={product}
        manufacturer={manufacturer}
        platform={platform}
        vets={vets}
        organisation={organisation}
        site={site}
        productDetail={productDetail}
        attestationData={attestationData}
        certificateNumber={certificateNumber}
        isGEFS={true}
      />;
    case 1:
      return <RequestAttestationReview
        formField={formField}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        touched={touched} errors={errors}
        productId={productId}
        user={user}
        values={values}
        submitHandler={submitHandler}
        uniqueRef={uniqueRef}
        isSubmitting={isSubmitting}
        forwardedRef={childRef}
        product={product}
        manufacturer={manufacturer}
        platform={platform}
        vets={vets}
        organisation={organisation}
        site={site}
        productDetail={productDetail}
        attestationData={attestationData}
        generatedSADocument={generatedSADocument}
        certificateNumber={certificateNumber}
        isGEFS={true}
      />;
    default:
      return <div>Not Found</div>;
  }
}

const RequestAttestationPage = (props) => {

  const childRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isVersionOutdated, setVersionOutdated] = useState(false);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [productId] = useState(props.match.params.id);
  const [manufacturerId, setManufacturerId] = useState(null);
  const productUrl = Routes.Product.path.replace(":id", "") + productId;
  const [uniqueRef, setUniqueRef] = useState('');
  const [isCustomUrn, setIsCustomUrn] = useState(false);
  const [product, setProduct] = useState({});
  const [manufacturer, setManufacturer] = useState({});
  const [versionErrorMessage, setVersionErrorMessage] = useState([]);;
  const [platform, setPlatform] = useState([]);;
  const [vets, setVets] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [site, setSite] = useState({});
  const [productDetail, setProductDetail] = useState({});
  const [attestationData, setAttestationData] = useState({});
  const [generatedSADocument, setGeneratedSADocument] = useState('');
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [certificateNumber, setCertificateNumber] = useState([]);
  const [certificateNumberList, setCertificateNumberList] = useState([]);
  const [user, setUser] = useState('');
  const [errors, setErrors] = useState(null);

  useEffect(() => {

    //Get User Data / Get New URN 
    getUserInfo().then(x => {
      setUser(x);
    });
    _handleGenerateUrn();

    //Get Product Data
    function fetchProductDetail() {

      ProductDataService.getWithDetailsById(productId)
        .then(response => {
          setProduct(response.data);
          setProductDetail(response.data.productDetails);
          setCertificateNumber(response.data.productDetails.certificateNumber);
          var manufacturerId = response.data.manufacturerId;
          setManufacturerId(manufacturerId);
          var siteId = response.data.productDetails.siteId;
          var platformId = response.data.productDetails.platformId;
          let certList = response.data.productDetails.certificateNumberList;
          setCertificateNumberList(certList);

          formInitialValues.isGEFS = response.data.isGEFS;
          //Get Certificate Data
          CertificateNumberService.getAll()
            .then((response) => {

              let selectedCertificateNumbers = response.data.filter(x => certList.includes(x.number.toString()));
              let selectedCertificateVersions = GetCertificateVersions();
              selectedCertificateVersions.filter(x => certList.includes(x.number.toString()))

              fetchCertFormData(selectedCertificateNumbers)
                .then(certFormData => {

                  //Set attestation data for each Form
                  setAttestationData(certFormData);

                  //Find selected form versions from certificate config
                  var outDatedList = [];
                  selectedCertificateVersions.forEach(x => {
                    var certVersionNumber = certFormData[x.number]?.version;
                    if (certVersionNumber != undefined) {
                      //Check if version is outdated
                      if (certVersionNumber < x.version) {
                        setVersionOutdated(true);
                        outDatedList.push(x.number)
                      }
                    }
                  });
                  setVersionErrorMessage(outDatedList)

                })
                .catch((e) => {
                  console.log(e);
                })

            })
            .catch((e) => { console.log(e); })

          //Get Associated Data
          ManufacturerDataService.getById(manufacturerId)
            .then((response) => {
              setManufacturer(response.data);
            })
            .catch((e) => { console.log(e); });

          SiteDataService.getWithEstablishmentNumberType(siteId)
            .then((response) => {
              setSite(response.data);
            })
            .catch((e) => { console.log(e); });

          PlatformDataService.getWithEstablishmentNumberType(platformId)
            .then((response) => {
              setPlatform(response.data);
            })
            .catch((e) => { console.log(e); });
        })
        .catch(e => { console.log(e); });

      //Get Associated Data
      VetsService.getAll()
        .then((response) => {
          setVets(response.data);
        })
        .catch((e) => { console.log(e); });

      OrganisationService.getFirst()
        .then((response) => {
          setOrganisation(response.data);
        })
        .catch((e) => { console.log(e); });

    }
    fetchProductDetail();
  }, []);

  const PrettyPrintJson = ({ data }) => (<div><pre>{
    JSON.stringify(data, null, 2)}</pre></div>
  );


  //Function - Generate a URN for this Attestation
  function _handleGenerateUrn() {
    AttestationService.getUniqueReference(productId, true)
      .then((response) => {
        setUniqueRef(response.data);
      })
      .catch((e) => { console.log(e); });
  }

  //Function - Get Gertificate form data from product id and the certificate numbers required on this product
  function fetchCertFormData(selectedCertificateNumbers) {
    return GetCertificateFormData(productId, selectedCertificateNumbers, false)
      .then(x => {
        return x
      });
  }

  //Function - Submit form 
  async function _submitForm(values, actions) {
    await _sleep(1000);

    let submitData = values;

    submitData.uniqueCode = uniqueRef;
    submitData.certificateNumberList = certificateNumberList;
    submitData.referenceNumber = values.customUniqueRef;
    submitData.productId = productId;
    submitData.productName = product.productName;
    submitData.manufacturerId = manufacturerId;
    submitData.filename = values.signedRequestUrl;
    submitData.traceability = values.traceability;
    submitData.documentSigningId = values.documentId;
    submitData.productJson = JSON.stringify({
      product: product,
      productDetail: productDetail,
      platform: platform,
      organisation: organisation,
      manufacturer: manufacturer,
      site: site,
      attestationData: attestationData,
    });
    submitData.vetId = values.vetId.id;
    submitData.batchFormat = values.batchFormat.value;
    submitData.batchNumberFrom = values.batchRangeFrom;
    submitData.batchNumberTo = values.batchRangeTo;
    submitData.batchNumber = values.batchNumber;
    submitData.finalConsumer = values.finalConsumer;
    submitData.decision = values.decision;
    submitData.productionDate = values.productionDate;
    submitData.productionDateMultipleStart = values.productionDateMultipleStart;
    submitData.productionDateMultipleEnd = values.productionDateMultipleEnd;
    submitData.productionDateSingle = values.productionDateSingle;

    console.info('submitData')
    console.info(submitData)
    AttestationService.create(submitData)
      .then(async(response) => {
        //Create Activity Log
        await ActivityService.log(
          'Requested an attestation', 
          'A GEFS attestation has been requested for ' + product.productName + ' with URN ' + uniqueRef,
          'Attestation', 
          'Request',
          values.signedRequestUrl,
          response.data.id
        );
      })
      .catch((e) => {
        setErrors(e.response.data);
        console.log(e);
      }
      );
    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  }

  //Function - Submit Functions
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function _handleSubmit(values, actions) {
    setSubmitAttempted(false);
    if (isLastStep) {
      _submitForm(values, actions);
    }
    else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }

    //Step 2 - Set Doc HTML for export to PDF
    if (activeStep == 0) {
      AttestationService.createSA(childRef.current.outerHTML)
        .then((response) => {
          setGeneratedSADocument(response.data);
        });
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }


  const title = "Attestation Request - Manufacturer Declaration";
  const breadcrumbData = {
    item1: { name: 'Products', link: Routes.Products.path },
    item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
    item3: { name: 'Attestation Request', link: '#' },
  };

  return (
    <div className="row">
      <BreadcrumbNav data={breadcrumbData} />
      <h2>{title}</h2>
      {/* //Stepper */}
      {activeStep !== steps.length && (
        <Row>
          <Col xl={10}>
            <Stepper steps={steps} activeStep={activeStep} />
          </Col>
        </Row>
      )}

      {/* //Step - Form Complete */}
      {activeStep === steps.length ? (
        <FormSubmission
          errors={errors}
          title={"Attestation Requested"}
          message={"The attestation has been sent to the vet.<br />The attestation progress and copy of this document is available back on the product page.</p>"}
          successButtonUrl={productUrl}
          successButtonName={"Back to product"}
        />
      )
        :
        (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {/* //Step - Main Content */}
            {({ isSubmitting, setFieldValue, setFieldTouched, touched, errors, values }) => (
              <Form id={formId}>

                <AttestationAlerts
                  isVersionOutdated={isVersionOutdated}
                  versionErrorMessage={versionErrorMessage}
                  size={11}
                  productId={productId}
                  isGEFS={true}
                  attestationId={null}
                />

                {/* //Main Stepped Form */}
                {_renderStepContent(
                  activeStep,
                  setFieldValue,
                  setFieldTouched,
                  touched,
                  errors,
                  productId,
                  user,
                  values,
                  childRef,
                  _handleSubmit,
                  isSubmitting,
                  uniqueRef,
                  isCustomUrn,
                  product,
                  manufacturer,
                  platform,
                  vets,
                  organisation,
                  site,
                  productDetail,
                  attestationData,
                  generatedSADocument,
                  certificateNumber
                )}

                {/* //Errors */}
                {(Object.keys(errors).length > 0 && submitAttempted) &&
                  <Alert
                    className="mt-2"
                    variant="danger"
                    size="sm"
                  >
                    <div className="d-flex justify-content-start">
                      <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                      Some errors were found, check the form above for any missing or incomplete information.
                    </div>
                  </Alert>
                }

                {/* //Back Button */}
                <div className={"d-inline"}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} variant="light" className="mx-3">
                      Back
                    </Button>
                  )}
                  {/* //Continue / Submit Button */}
                  {!isVersionOutdated ? (
                    <div className="d-inline">
                      <Button
                        onClick={() => { setSubmitAttempted(true) }}
                        disabled={isSubmitting}
                        type="submit"
                        variant="primary"
                        className={"btn-md"}
                      >
                        {activeStep == 0 ? 'Continue' : ''}
                        {activeStep == 1 ? 'Complete and send to vet' : ''}
                      </Button>
                    </div>
                  )
                    :
                    (
                      <p></p>
                    )
                  }
                </div>
              </Form>
            )}
          </Formik>
        )}
    </div>
  );
};

export default RequestAttestationPage;