import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useRef, useState } from "react";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { PlatformTable } from "../../../components/Tables/PlatformTable";
import { Routes } from "../../../routes";
import FilterBar from '../../filters/FilterBar';
import PlatformDataService from "../../services/PlatformService";
import Paging from "../common/Paging";

const PlatformListingPage = (props) => {
    const [totalItems, setTotalItems] = useState(0);
    const [platforms, setPlatforms] = useState([]);
    const [activePage, setActivePage] = React.useState(1);
    const [loaded, setLoaded] = useState(false);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [isSubmitting, setSubmitting] = useState(false);
    const [ready] = useState(true);
    const orderByOptions = ["Last updated", "A-Z", "Z-A"]
    const { totalPages = count, size = "md", withIcons = false, disablePrev = false } = props;

    const platformRef = useRef();
    const breadcrumbData = {
        item1 : {name:'Platforms', link: Routes.Platforms.path},
        item2 : {name:'View all', link: 'View all'},
    };
    const initialValues = {
        search: ''
    }
    platformRef.current = platforms;

    //Start - Paging Functions
    const onPrevItem = () => {
        const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
        setActivePage(prevActiveItem);
      };
      const onPageNumberClick = (number) => {
        setActivePage(number);
    };

    const onNextItem = (totalPages) => {
      const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
      setActivePage(nextActiveItem);
    };
    //End - Paging Functions

    
    const getPlatforms = (params) => {
        setLoaded(false);
        PlatformDataService.getAllPaged(params)
            .then((response) => {
                const { data, totalPages, totalRecords} = response.data;
                setTotalItems(totalRecords);
                setPlatforms(data);
                setCount(totalPages);
                setLoaded(true);
            })
            .catch((e) => {
                setLoaded(true);
                console.log(e);
            });
    };

    const handlePageSizeChange = (value) => {
        setPageSize(value.value);
    };
    

    //Submit
    const handleFilterSubmit= (params) => {
        setSubmitting(true);
        getPlatforms(params)
        setSubmitting(false);
    }

    const handleFilterChange = (values) => {
        setSubmitting(true);
        setSubmitting(false);
    }
    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            
            <Row className='row d-flex align-items-center'>
                <Col xs={12} sm={6} lg={6} className="text-start">
                    <h2 id="tabelLabel" >Platforms</h2>
                </Col>
                <Col xs={12} sm={6} lg={6} className="text-end">
                    <Button variant="secondary" size={"sm"} href={Routes.PlatformAdd.path} className="text-dark me-2">
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        <span>Add</span>
                    </Button>
                </Col>
            </Row>

            <div className="col-md-12 list">
                <Row className="mt-1">
                    <Col>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <FilterBar
                                            setLoaded={setLoaded} 
                                            loaded={loaded} 
                                            ready={ready} 
                                            hasSearch={true}
                                            activePage={activePage}
                                            pageSize={pageSize}
                                            orderByOptions={orderByOptions}
                                            setActivePage={setActivePage}
                                            handleChange={handleFilterChange}
                                            handleSubmit={handleFilterSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>

                                <PlatformTable loaded={loaded} platforms={platforms} />
                                <Paging
                                        activePage={activePage}
                                        size={size}
                                        pageSize={pageSize}
                                        totalPages={totalPages}
                                        totalItems={totalItems}
                                        handleChange={handlePageSizeChange}
                                        onPrevItem={onPrevItem}
                                        onNextItem={onNextItem}
                                        onPageNumberClick={onPageNumberClick}
                                        withIcons={withIcons}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default PlatformListingPage;