import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import FormController from "../common/FormController"
import AIService from "../../services/AIService";
import { Button, Card, Col, Row, Modal, Tooltip, OverlayTrigger, Spinner } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFileImage, faCamera } from '@fortawesome/free-solid-svg-icons';
import moment from "moment"
import { ConsignmentSealImageViewWidget } from "../../../components/Widgets";
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes } from '../../../auth/PermissionsMap';
import ConsignmentImageService from "../../services/ConsignmentImageService";

const ConsignmentSealImages = (props) => {

    const handleCloseModalAdd = () => setShowModalAdd(false);
    const handleCloseModalView = () => setShowModalView(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalView, setShowModalView] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [sealImages, setSealImages] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const [consignmentId] = useState(props.consignmentId);

    const initialValues = {
        consignmentId: consignmentId,
        filesToUpload: [],
        seal: true,
        container: false
    }
    const GPTError = "The seal number is not clearly visible."
    async function onSubmit(values, { resetForm }) {
        resetForm({});
        setSubmitting(true);

        try {
            const formData = new FormData();
            // Populate formData with the values
            for (const value in values) {
                formData.append(value, values[value]);
            }

            for (let i = 0; i < values.filesToUpload.length; i++) {
                formData.append('ImageFile', values.filesToUpload[i]);
            }
            const response = await AIService.getSealNumberResult(formData);
            console.log("Seal response", response.data);
            const sealNumber = response.data;
            console.log("Seal Number:", sealNumber);
            if (sealNumber == "Error") {
                props.setSealErrorMessage(GPTError);
            }
            else {
                const imageResponse = await ConsignmentImageService.create(formData);
                console.log("CREATE", imageResponse.data);
                props.setSealNumber(sealNumber);
                props.setSealErrorMessage(null);
                setUploadComplete(true);
                retrieveConsignmentImages();
                handleCloseModalAdd();
            }
        } catch (error) {
            console.error("An error occurred", error);
            props.setSealErrorMessage(GPTError);

        } finally {
            setSubmitting(false);
            handleCloseModalAdd();
        }
    }
    function retrieveConsignmentImages() {
        ConsignmentImageService.getAll(consignmentId)
            .then(response => {
                setSealImages(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        retrieveConsignmentImages();
    }, []);


    let filesImages = { icon: faFileImage, colour: 'text-purple', extensions: ["jpg", "png", "bmp", "tiff"] };

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
    }

    function formattedDate(date) {
        var newDate = moment(date).format("Do MMM YYYY");
        if (newDate === "Invalid date") {
            return "";
        }
        return newDate;
    }

    function getLinkText(file, fileExt) {
        var fileName = file.filename.substring(37);
        return (
            <div className='text'>
                <a href={file.fileUrl} >
                    <FontAwesomeIcon icon={fileExt.icon} className={`mx-auto ${fileExt.colour} `} />
                    <span className="d-block fs-7">{fileName}</span>
                    <small><i>{formatBytes(file.size, null)}. Added {formattedDate(file.CreatedDate)} by {file.userFullName}</i></small>
                </a>
            </div>
        );
    }



    const files = sealImages.map(file => (
        <li key={file.path} className="dropzone-upload" >
            {
                <>{getLinkText(file, filesImages)}</>
            }
        </li>
    ));
    const firstSealFile = sealImages.find(file => file.seal);
    return (
        <Card border="light" className="h-100 bg-white shadow-sm">
            <Card.Header className="align-items-center">
                {/* <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.consignments.create]}>
                    <> */}
                <OverlayTrigger placement="top" trigger={['hover', 'focus']} overlay={<Tooltip>Take a photo of the seal.</Tooltip>}>
                    <Button onClick={() => setShowModalAdd(true)} variant="light" size="sm" className="text-dark me-2 float-end text-end">
                        <FontAwesomeIcon icon={faCamera} className="me-2" size="sm" />Upload seal image
                    </Button>
                </OverlayTrigger>
                {firstSealFile && (
                <Button onClick={() => setShowModalView(true)} variant="light" size="sm" className="text-dark me-2 float-end text-end">
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    <span>View</span>
                </Button>
                )}
                {/* </>
                </PermissionsCheck> */}
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={12} className="">
                        {/* //Modal - Upload */}
                        <Modal size="md" as={Modal.Dialog} centered show={showModalAdd} onHide={handleCloseModalAdd}>
                            <Modal.Header>
                                <Modal.Title className="h6">Upload seal image</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleCloseModalAdd} />
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                >
                                    {(props) => (
                                        <Form>
                                            <Row className="mt-0">
                                                <Col md={12}>
                                                    <FormController
                                                        control="multipleFileUpload"
                                                        allowedFileTypes="image/*"
                                                        capture="environment"
                                                        label="Select images to upload"
                                                        name="filesToUpload"
                                                        className="mb-3"
                                                        imageUploader
                                                        multiple={true}
                                                    />

                                                    {isSubmitting && (
                                                        <>
                                                            <Spinner animation="border" variant="primary" size="sm" className="me-1" />
                                                            Please wait...
                                                        </>
                                                    )}

                                                    <div className="form-group mt-3">
                                                        <Button className="btn-block" type="submit" disabled={isSubmitting || props.values.filesToUpload.length <= 0}>{isSubmitting ? "Please wait..." : "Upload"}</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik >
                            </Modal.Body>
                        </Modal>

                        {/* //Modal - View */}
                        <Modal size="md" as={Modal.Dialog} centered show={showModalView} onHide={handleCloseModalView}>
                            <Modal.Header>
                                <Modal.Title className="h6">All seal images</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleCloseModalView} />
                            </Modal.Header>
                            <Modal.Body>
                                {/* //Image list */}
                                {files.length > 0 ? (
                                    <ConsignmentSealImageViewWidget
                                        title="Select seal photo(s)"
                                        sealImages={sealImages}
                                    />
                                )
                                    :
                                    (
                                        <div className="text-center pt-4">
                                            <h5 className="text-gray-600">No seal images<br /> have been uploaded yet</h5>
                                        </div>
                                    )}
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ConsignmentSealImages;