import React from 'react';

const Fishery8350 = (props) => {
    const { attestationData } = props;
    return (
        <>
            {attestationData?.containsFishery ? (
                <li>
                    <u><strong>II.3.C Fishery products</strong></u> that originate from the approved establishment
                    No <u><strong>{attestationData?.fisheryEstablishmentCodeEstablishments.map(establishment => establishment.approvalNumber).join(", ")}</strong></u>
                    {attestationData?.bivalveMolluscsEstablishmentCodeEstablishments.length > 0  && (
                        <u><strong>, {attestationData?.bivalveMolluscsEstablishmentCodeEstablishments.map(establishment => establishment.approvalNumber).join(", ")} </strong></u>
                    )}
                    &nbsp;situated in the country <u><strong>{attestationData.fisheryCountryOrigin.map(x => x.isoCode).join(", ")}</strong></u>
                    {attestationData?.bivalveMolluscsCountryOrigin?.length > 0 && (
                        <u><strong>, {attestationData.bivalveMolluscsCountryOrigin.map(x => x.isoCode).join(", ")}</strong></u>
                    )}
                    .
                </li>
            )
                :
                (
                    <li>II.3.C Does not contain processed fishery products.</li>
                )
            }
        </>
    )
};

export default Fishery8350;