import React from 'react';
import ReactDOM from 'react-dom';
import UserService from './pages/services/UserService';
import { msalConfig} from "./auth/authProvider";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider} from "@azure/msal-react";
import App from "./pages/App";

// styles
import "./scss/ivetted.scss";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
    UserService.process();
  }
//   if (event.eventType === EventType.LOGIN_FAILURE) {
//       return(
//           <NotAuthorised/>
//       )
//   }
})
  
ReactDOM.render(
	<MsalProvider instance={msalInstance}>
	  <App/> 
  </MsalProvider>
			,
	document.getElementById('root')
);