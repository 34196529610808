import *  as baseService  from "./BaseService";

const getAll = (params) => {
    return baseService.getApiData("/Country/getall");
};
const getById = (id) => {
    return baseService.getApiData("/Country/GetById?id=" + id);
};
const getAllByGroup = (name) => {
    return baseService.getApiData("/Country/getAllByGroup?name=" + name);
};

const getNonEUCountries = () => {
    return baseService.getApiData("/Country/getAllByGroup?name=Non_EU");
};
const getEUAndUKCountries = () => {
    return baseService.getApiData("/Country/getAllByGroup?name=EU_UK");
};
const getCountriesForSites = () => {
    return baseService.getApiData("/Country/getAllByGroup?name=Add_Sites");
};

const CountryService = {
    getAll,
    getById,
    getAllByGroup,
    getNonEUCountries,
    getEUAndUKCountries,
    getCountriesForSites
};

export default CountryService;