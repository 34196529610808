import React from 'react';
import FormController from "../../../../components/common/FormController"

const FieldRow = ({groupField}) =>{

    let displayName = '';

    if(groupField.displayName!==null){
        displayName = groupField.displayName;
    }
    else{
        displayName = groupField.fieldName;
    }


    return(
        <>
        {groupField.fieldType==="checkbox" ?
            <>
                <FormController
                    control="checkbox"
                    label={displayName}
                    name={`fields.${groupField.fieldName?.replaceAll(" ", "")}`}
                    value={groupField.value ==="true"? false : true }
                    col={8}
                    size="sm"
                    className="mt-3"
                />
            </>
        :
            <FormController
                control="input"
                label={displayName}
                name={`fields.${groupField.fieldName?.replaceAll(" ", "")}`}
                col={8}
                size="sm"
            />
        }
        </>
    )
}

export default FieldRow;
