
import moment from "moment";
import React, { useEffect, useState, useRef } from 'react';
import PermissionsCheck from '../../../../auth/PermissionCheck';
import { scopes} from '../../../../auth/PermissionsMap';
import AttestationService from '../../../services/AttestationService';
import { Card, Button, Badge, Col, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPen, faWindowClose,faPlus, faInfoCircle, faExclamationTriangle, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../../../routes";
import { useHistory } from "react-router-dom";
import SupplierDeclarationButton from "../../StorageDeclaration/SupplierDeclaration/SupplierDeclarationButton";

const AttestationPanel = (props) => {
    const {isGEFS, isStorageDeclaration, hasProductDetails, currentAttestation, isCertFormComplete, productId, visibility} = props;
    const history = useHistory();

    const getSADate = (date) => {
        var newDate = moment(date).format("Do MMM YYYY");
        if (newDate === "Invalid date") {
            return "";
        }
        return newDate;
    }

    function isValidSA(date){
        if (moment().isAfter(attestationDate)) {
          return "expired";
        }
        var attestationDate = moment(date);
        if (attestationDate === "Invalid date") {
            return "invalid";
        }
        var dateDiff = moment().diff(attestationDate, 'days');
        if(dateDiff < 0 && dateDiff >= -5){
          return "expiring";
        }
        if (moment().isBefore(attestationDate)) {
          return "valid";
        }
      return "invalid";
    }

    const newGEFSSupportAttestation = () => {
        history.push(Routes.RequestAttestationPage.path.replace(":id", productId));
    };

    const newNonGEFSSupportAttestation = () => {
        history.push(Routes.RequestAttestationNonGEFSPage.path.replace(":id", productId));
    };
    const newStorageDeclaration = () => {
        history.push(Routes.RequestStorageDeclarationPage.path.replace(":id", productId));
    };

    return(
        <>
            <Col className="text-center">
                <h4 className="text-title" id="tabelLabel" >
                    {isStorageDeclaration?
                        <>Storage declaration</>
                    :
                        <>Support attestation</>
                    }
                </h4>

            {visibility =="Active" &&
                <>
                {/* //Error Check */}
                <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.products.read]} scopesExcluded={[scopes.attestationrequests.create]}>
                    <p> 
                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2 text-danger" />
                        Your account type cannot request an attestation.
                    </p>
                </PermissionsCheck>

                {/* //Completed Attestation */}
                {currentAttestation !== ""?(
                    <div>
                        {!isStorageDeclaration &&
                            <p>
                                {isValidSA(currentAttestation.validTo)=="valid"&&(
                                    <Badge bg="primary" className="rounded px-3 py-2 bg-success"> Expires {moment(currentAttestation.validTo).fromNow()}</Badge>
                                )}
                                {isValidSA(currentAttestation.validTo)=="expiring"&&(
                                    <Badge bg="warning" className="rounded px-3 py-2 bg-success"> Expires {moment(currentAttestation.validTo).fromNow()}</Badge>
                                )}
                                <small>&nbsp;Valid until: {getSADate(currentAttestation.validTo)}</small>
                            </p>
                        }
                        <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.attestationrequests.create]}>
                        <>
                            <>

                            {isStorageDeclaration ?
                                <SupplierDeclarationButton
                                productId={productId}
                                    //onClick={newStorageDeclaration}
                                />
                            :
                            isGEFS ?
                            <Button 
                                onClick={newGEFSSupportAttestation}
                                variant="light" size="md" className="d-block mx-auto"> 
                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                    <span>Request a new Support Attestation</span>
                            </Button>
                            :
                            <Button 
                                onClick={newNonGEFSSupportAttestation}
                                variant="light" size="md" className="d-block mx-auto"> 
                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                    <span>Request a new Support Attestation</span>
                            </Button>
                            }
                            </>
                        </>
                        </PermissionsCheck>
                    </div>
                ):(
                    <>
                    {/* //New Attestation */}
                        {(hasProductDetails && isCertFormComplete) || (hasProductDetails && !isGEFS) ?(
                        <>
                            <p className="mb-2">No current valid attestation has been found for this product</p>
                            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.attestationrequests.create]}>
                                {isGEFS ?
                                <Button 
                                    onClick={newGEFSSupportAttestation}
                                    variant="light" size="md"> 
                                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                                        <span>Request a Support Attestation</span>
                                </Button>
                                :
                                isStorageDeclaration ?
                                    <Button 
                                        onClick={newStorageDeclaration}
                                        variant="light" size="md"> 
                                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                                            <span>Create a Storage Declaration</span>
                                    </Button>
                                :
                                    <Button 
                                        onClick={newNonGEFSSupportAttestation}
                                        variant="light" size="md"> 
                                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                                            <span>Request a Support Attestation</span>
                                    </Button>
                                }
                            </PermissionsCheck>
                        </>
                        )
                        :
                        (
                        <>
                            <p>
                                <FontAwesomeIcon icon={faExclamationTriangle} className="me-2 text-danger" />
                                Certificate specific information is required before&nbsp; 
                                {isStorageDeclaration? 
                                    <>a storage declaration can be completed.</>
                                    :
                                    <>an attestation can be completed.</>
                                }
                            </p>
                        </>
                        )
                        }
                    </>
                )}
                </>
            }
            </Col>
        </>
    )
}
 export default AttestationPanel;