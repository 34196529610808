import *  as baseService  from "./BaseService";

const getAll = (id) => {
    return baseService.getApiData("/supportingevidence/getByProductId?id=" + id);
};
const create = (data) => {
    return baseService.postApiData("/supportingevidence/create", data);
};
const remove = (id) => {
    return baseService.deleteApiData("/supportingevidence/delete?id=" + id);
};
const SupportingEvidenceService = {
    getAll,
    create,
    remove
};

export default SupportingEvidenceService;