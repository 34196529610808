import *  as baseService  from "./BaseService";

const getByProductId = (id) => {
    return baseService.getApiData("/productdairy/getByProductId?id=" + id);
};

const create = (data) => {
    return baseService.postApiData("/productdairy/Create", data);
};

const update = (id, data) => {
    return baseService.putApiData("/productdairy/update?id=" + id, data);
};

const ProductDairyService = {
    create,
    getByProductId,
    update
};

export default ProductDairyService;