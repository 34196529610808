import ConsignmentContentsCombinationsService from '../../../../services/ConsignmentContentsCombinationsService';
import CertificateAssignedService from '../../../../services/CertificateAssignedService';
import ConsignmentContentsService from '../../../../services/ConsignmentContentsService';
import CertificateAssignedCompoundService from '../../../../services/CertificateAssignedCompoundService';

export const SaveConsignmentContents = async (values, combineSelections, consignmentId) =>{
    
    // alert("SaveConsignmentContents");
    // alert(JSON.stringify(values))
    let submitData = [];
    
    //Loop through and create objects for processing
    values.contentsSelected.forEach((x, i) => {

        //Get combinations
        let combinations = combineSelections.filter(
            (y) => y.consignmentContentsId === x?.consignmentContentsId);
        combinations = combinations.filter((x) => x !== null && x !== undefined);
        
        let isGEFS = x?.attestation?.isGEFS;
        let isCompound = x.isCompound;
        let isStorageDeclaration = x.isStorageDeclaration;
        let documentId = null;
        if(x.documents !== undefined && x.documents !==null){
            documentId = x.documents[0]?.id;
        }
        //GEFS data submit object
        
        if (isStorageDeclaration) {

            var content = {
                id: x.consignmentContentsId,
                consignmentContentsId: x.consignmentContentsId,
                isGEFS: false,
                isStorageDeclaration: true,
                isCompound: isCompound,
                commodityCode: x.commodityCode,
                numberOfPackages: x.numberOfPackages,
                netWeightTotal: x.netWeightTotal,
                grossWeightTotal: x.grossWeightTotal,
                consignmentId: consignmentId,
                contentsStorage: [
                    {
                        storageExporterDeclarationId: x.id,
                        consignmentContentsId: x.consignmentContentsId,
                    },
                ],
              //  compound: compoundData,
                consignmentContentsCombinations: combinations,
            };
            console.info("content")
            console.info(content)
            submitData.push(content);
        }
        else{
            if (isGEFS) {
                var content = {
                    id: x.consignmentContentsId,
                    isGEFS: true,
                    isCompound: isCompound,
                    consignmentId: consignmentId,
                    commodityCode: x.commodityCode,
                    numberOfPackages: x.numberOfPackages,
                    netWeightTotal: x.netWeightTotal,
                    grossWeightTotal: x.grossWeightTotal,
                    contentsGEFS: [
                        {
                            dailyAttestationItemId: x.dailyAttestationItems.id,
                            consignmentContentsId: x.consignmentContentsId,
                        },
                    ],
                   // compound: compoundData,
                    consignmentContentsCombinations: combinations,
                };
                console.info("content")
                console.info(content)
                submitData.push(content);
            } 
            //Non GEFS data submit object
            else {
                var content = {
                    id: x.consignmentContentsId,
                    consignmentContentsId: x.consignmentContentsId,
                    isGEFS: false,
                    isStorageDeclaration: false,
                    isCompound: isCompound,
                    commodityCode: x.commodityCode,
                    numberOfPackages: x.numberOfPackages,
                    netWeightTotal: x.netWeightTotal,
                    grossWeightTotal: x.grossWeightTotal,
                    consignmentId: consignmentId,
                    contentsNonGEFS: [
                        {
                            attestationId: x.attestation.id,
                            consignmentContentsId: x.consignmentContentsId,
                        },
                    ],
                  //  compound: compoundData,
                    consignmentContentsCombinations: combinations,
                };
                console.info("content")
                console.info(content)
                submitData.push(content);
            }
        }
    });


    const existingContents = await ConsignmentContentsService.getAllByConsignmentId(consignmentId);
    const existingContent = existingContents.data;
            
    //Get consignment contents id lists of submitted data
    const submittedContentIds = submitData.map((x) => x.id);

    //Delete contents no longer selected
    await removeUnselectedContent(submittedContentIds, existingContent, combineSelections)
    var counter = 0;
    const sortedData = SortSubmitData(submitData);
    //ERROR WITH CODE - when a combined cert uncombined, all certs get deleted
    //Loop through each submit data item
    for (const submitItem of sortedData) {
        counter++
        let contentCreate = null;
        var contentCombo = submitItem.consignmentContentsCombinations[0];
        
        var isNewContent = existingContent.find((y) => y.id == submitItem.id)== (null||undefined) ? true : false;
        
        //New Data
        if (isNewContent) { 
            //Add new items which do not exist on database
            contentCreate = await ConsignmentContentsService.create(submitItem)
            var res = contentCreate.data;

            //Add new combos
            if (submitItem.consignmentContentsCombinations?.length > 0 && res != undefined) {
                 contentCombo = submitItem.consignmentContentsCombinations[0];
                await ConsignmentContentsCombinationsService.create(contentCombo);
            }
        }

        //Existing data
        else{
            //Check for certificates assigned already
            const assignedCertificates = await CertificateAssignedService.getByConsignmentContentsId(submitItem.id);
            const hasCertificates = assignedCertificates.data?.length>0 ? true : false;
        
            //Check combos data 
            const existingCombos = await ConsignmentContentsCombinationsService.getByContentsId(submitItem.id);
            const existingCombosData = existingCombos.data;

            //Get combos of submitted data
            const submittedComboIds = 
                submitData.flatMap((x) =>
                    x.consignmentContentsCombinations.length > 0
                    ? x.consignmentContentsCombinations.map((y) => y.consignmentContentsId)
                    : []
                )
                .filter((id) => id !== null);

            const existingCombosSorted =  SortCombosData(existingCombosData);

            //Remove combinations and certificates for unselected combination groups
            if(existingCombosSorted.length>0){
                for (const combo of existingCombosSorted){
                    //Remove combos when content has been unselected
                    if(!submittedComboIds.includes(combo.consignmentContentsId)){
                        await ConsignmentContentsCombinationsService.removeByConsignmentContentsId(combo.consignmentContentsId)
                    }
                    //Remove assigned certificates when content has been unselected
                    if(hasCertificates && !submittedComboIds.includes(combo.consignmentContentsId)){
                        await CertificateAssignedService.removeByConsignmentContentsId(combo.consignmentContentsId)
                    }
                    //Check for master Id changes and update chamges
                    const matchingMasterIds = existingCombosData.find(x => x.consignmentContentsMasterId == contentCombo.consignmentContentsMasterId);
                    if(matchingMasterIds == undefined || matchingMasterIds == null){
                        await ConsignmentContentsCombinationsService.update(contentCombo)
                    }

                    if(combo.consignmentContentsId == contentCombo.consignmentContentsId && combo.consignmentContentsMasterId !== contentCombo.consignmentContentsMasterId){
                        await CertificateAssignedService.removeByConsignmentContentsId(contentCombo.consignmentContentsId)
                    }
                }
            }
            
            const foundCombo = existingCombosSorted.filter(x=> submittedComboIds.includes(x.consignmentContentsId));
            
            //Add combinations if not found on existing data
            if(foundCombo?.length <=0){
                if (submitItem.consignmentContentsCombinations?.length > 0 ) {
                     contentCombo = submitItem.consignmentContentsCombinations[0];
                    //Create combo
                    if(contentCombo !== undefined){
                        await ConsignmentContentsCombinationsService.create(contentCombo);
                    }
                }
            }
        }
    }//End - For loop

}


async function removeUnselectedContent(selectionIds, existingContent, combinations) {

const existingSorted =  SortSubmitData(existingContent);
    //Loop through each consignment content
    for (const content of existingSorted){
        var existingContentId = content.id;

        //Check for contents no longer in the selection list
        if(existingContentId != null){
            //Combinations removed
            if(selectionIds?.includes(existingContentId)){
                if(content.consignmentContentsCombinations?.length > 0 && combinations.find(x=>x.consignmentContentsId == existingContentId) == undefined){
                    await CertificateAssignedService.removeByConsignmentContentsId(existingContentId);
                    await CertificateAssignedCompoundService.removeByConsignmentContentsId(existingContentId);
                    await ConsignmentContentsCombinationsService.removeByConsignmentContentsId(existingContentId);
                }
            }
            if (!selectionIds?.includes(existingContentId)) {
                await CertificateAssignedCompoundService.removeByConsignmentContentsId(existingContentId);
                await CertificateAssignedService.removeByConsignmentContentsId(existingContentId);
                await ConsignmentContentsCombinationsService.removeByConsignmentContentsId(existingContentId);
                await ConsignmentContentsService.remove(existingContentId);
            }
        }
    }
}

const SortSubmitData = (array) => {
    if(array == undefined || array?.length <= 0){
        return array;
    }
    // Sort the array based on the presence / true value of isPrimary 
    var result = array.sort((a, b) => {
        const aHasIsPrimary = a.consignmentContentsCombinations.some(child => child.isPrimary);
        const bHasIsPrimary = b.consignmentContentsCombinations.some(child => child.isPrimary);
        if (aHasIsPrimary && !bHasIsPrimary) {
        return -1; // a should be sorted before b
        } else if (!aHasIsPrimary && bHasIsPrimary) {
        return 1; // b should be sorted before a
        }
        return 0; // no sorting needed
    });
  return result;
}

const SortCombosData = (array) => {
    // Sort the array based on the presence / true value of isPrimary in child array 
    if(array == undefined || array?.length <= 0){
        return array;
    }
     var result = array.sort((a, b) => {
       const aHasIsPrimary = a.isPrimary;
       const bHasIsPrimary = b.isPrimary;
       if (aHasIsPrimary && !bHasIsPrimary) {
         return -1; // a should be sorted before b
       } else if (!aHasIsPrimary && bHasIsPrimary) {
         return 1; // b should be sorted before a
       }
       return 0; // no sorting needed
     });
     return result;
   }
