import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Formik, Form, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import CountryService from "../../../services/CountryService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ProductFisheryService from "../../../services/ProductFisheryService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import { Button, Card, Alert } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import ColdStoreSelect from '../../../../components/EstablishmentSelect/ColdStoreSelect';
import SiteService from '../../../services/SiteService';
import EstablishmentsService from '../../../services/EstablishmentsService';
import ActivityService from '../../../services/ActivityService';

const Fishery8361AddPage = (props) => {
    const certificateNumber = 8361;
    const [isSubmitting,  setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [formVersion] = useState(GetCertificateFormVersion(certificateNumber));
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [countrys, SetCountrys] = useState([]);
    const [specieslist, SetSpecies] = useState([]);
    const [apiValues, SetApiValues] = useState([]);
    const [siteEstablishment, setSiteEstablishment] = useState([]);
    const [coldStoreEstab, setColdStoreEstab] = useState([]);

    let title = (editing == true ? "Edit " : "New") + " Fishery Information (#8361-For Fishery products only)"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Fishery 8361', link: Routes.Fishery8361AddPage.path },
    };
    const initialValues = {
        productId: props.match.params.id,
        fisheryCountryOrigin: apiValues?.fisheryCountryOrigin ?? [],
        treatmentType: apiValues?.treatmentType ?? '',
        fisheryAquaculture: apiValues?.fisheryAquaculture ?? false,
        fisheryWildOrigin: apiValues?.fisheryWildOrigin ?? false,
        coldStore: apiValues?.coldStore ?? '',
        certifiedAsOrFor: apiValues?.certifiedAsOrFor ?? '',
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
        manufacturingPlant: apiValues?.manufacturingPlant ?? '',
        identificationMark: apiValues?.identificationMark ?? '',
        identificationMarkEstablishment: apiValues?.identificationMarkEstablishment ?? siteEstablishment[0],
        coldStoreEstablishments: apiValues?.coldStoreEstablishments ?? [],

    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";
    const treatments = [
        { value: "", key: "" },
        { value: "Live", key: "Live" },
        { value: "Chilled", key: "Chilled" },
        { value: "Frozen", key: "Frozen" },
        { value: "Processed", key: "Processed" },
    ]
    const certifiedAsOrFor = [
        { value: "", key: "" },
        { value: "Human Consumption", key: "Human Consumption" },
        { value: "Canning Industry", key: "Canning Industry" },
        { value: "Further processing", key: "Further processing" },
        { value: "Live aquatics animals for human consumption", key: "Live aquatics animals for human consumption" },
    ]
    //Validation
    const validationSchema = Yup.object().shape({
        fisheryCountryOrigin: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        treatmentType: Yup.string().required(validationMsgRequired),
        fisheryAquaculture: Yup.bool().test('fisheryAquaculture', validationMsgAtLeastOne, function (x) {
            return (this.parent.fisheryAquaculture || this.parent.fisheryWildOrigin)
        }),
        fisheryWildOrigin: Yup.bool().test('fisheryWildOrigin', validationMsgAtLeastOne, function (x) {
            return (this.parent.fisheryAquaculture || this.parent.fisheryWildOrigin)
        }),
        certifiedAsOrFor: Yup.string().required(validationMsgRequired),
        identificationMarkEstablishment: Yup.object().nullable().required(validationMsgRequired),
        // coldStoreEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
        //     .required(validationMsgRequired),
    });

    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;
            console.log(submitData);
            
            if (apiValues.length === 0) {
                ProductFisheryService.create(submitData)
                    .then(async response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Fishery 8361',
                            8361,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            else {
                ProductFisheryService.update(productId, values)
                    .then(async response => {
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Fishery 8361',
                            8361,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            console.log(JSON.stringify(values, null, 2));
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);



    useEffect(() => {
        //Get site estab
        ProductService.getProductDetailByProductId(productId)
            .then(response4 => {
                //Get site operatorId
                SiteService.getById(response4.data.siteId)
                    .then(response2 => {
                        //Get establishment data from operatorid
                        EstablishmentsService.getEstablishmentsDetails([response2.data.tracesId])
                            .then(response3 => {
                                //set parent site for this product
                                setSiteEstablishment(response3.data)
                            }, [])
                            .catch(e => {
                                console.log(e);
                            });
                    }, [])
                    .catch(e => {
                        console.log(e);
                    });
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductFisheryService.getByProductId(productId)
            .then(response => {
                var res = response.data;
                setColdStoreEstab(res.coldStoreEstablishments)
                if (response.data == "") {
                    return;
                }
                SetApiValues(response.data);
                setEditing(true);
                setReady(true);
                console.log("init values:" + apiValues);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductService.getById(productId)
            .then(response => {
                SetProduct(response.data.data);
                setIsGEFS(response.data.data.isGEFS);
            }, [])
            .catch(e => {
                console.log(e);
            });
        CountryService.getAll()
            .then((_countryResponse) => {
                const options = _countryResponse.data.map(d => ({
                    "id": d.id,
                    "countryName": d.countryName,
                    "isoCode": d.isoCode,
                })).sort((a, b) => a.countryName.localeCompare(b.countryName));
                const firstItem = "GB";
                options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                SetCountrys(options);
            })
            .finally(() => {
            });
        ProductScientificSpeciesDataService
            .getOnlyFishery()
            .then((_speciesResponse) => {
                SetSpecies(_speciesResponse.data);
            })
            .finally(() => {
            });
    }, []);

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);

    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();
        useEffect(() => {
            setColdStoreEstab(values?.coldStoreEstablishments)
        }, [values]);

        return null;
    };

    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <>
                    <SubmissionPage
                        productId={productId}
                        attestationId={attestationId}
                        isGEFS={isGEFS}
                        errors={errors}
                        title={(editing ? "Edited" : "Completed") + " Fishery 8361 Form"}
                        message={`Fishery information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                    />

                </>

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        {/* <PrettyPrintJson data={props.values} />   */}
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        <div className="">
                                            <h6>Fishery products that:</h6>

                                            <div className="pb-3">
                                                <MultiSelectField
                                                    id='fisheryCountryOrigin'
                                                    name='fisheryCountryOrigin'
                                                    label="Originates in"
                                                    placeholder="Select fields"
                                                    options={countrys}
                                                    defaultValues={props.values?.fisheryCountryOrigin}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.countryName}
                                                    value={props.values.fisheryCountryOrigin}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.fisheryCountryOrigin}
                                                    error={props.errors.fisheryCountryOrigin}
                                                    isClearable={true}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    col={4}
                                                />
                                            </div>
                                        </div>
                                        <FormController
                                            control="select"
                                            label="Treatment Type"
                                            name="treatmentType"
                                            options={treatments}
                                            isrequired="true"
                                            col={3}
                                        />


                                        <label className="mt-3 mb-3 required">Nature of Commodity</label>
                                        {props.errors?.fisheryAquaculture && (props.touched?.fisheryAquaculture || props.touched?.fisheryWildOrigin) &&
                                            (<span className="invalid-feedback d-block mb-3">{props.errors.fisheryAquaculture}</span>)
                                        }
                                        <FormController
                                            control="checkbox"
                                            label="Aquaculture"
                                            name="fisheryAquaculture"
                                            readOnly={readOnly}
                                        />

                                        <FormController
                                            control="checkbox"
                                            label="Wild Origin"
                                            name="fisheryWildOrigin"
                                            readOnly={readOnly}
                                        />

                                        <label className='mt-3 mb-2 required' > Certified As or For <span className='text-gray-600 text-italic'> (Refer to Box I.20 on certificate) </span> </label>
                                        <FormController
                                            control="select"
                                            name="certifiedAsOrFor"
                                            options={certifiedAsOrFor}
                                            col={4}
                                        />


                                        <MultiSelectField
                                            id='productScientificSpecies'
                                            name='productScientificSpecies'
                                            label="Species"
                                            placeholder="Select fields"
                                            options={specieslist}
                                            defaultValues={props.values?.productScientificSpecies}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                            value={props.values.productScientificSpecies}
                                            isMulti={true}
                                            onChange={props.setFieldValue}
                                            onBlur={props.setFieldTouched}
                                            touched={props.touched.productScientificSpecies}
                                            error={props.errors.productScientificSpecies}
                                            isClearable={true}
                                            isrequired="true"
                                            backspaceRemovesValue={true}
                                            col={4}
                                        />

                                        <ColdStoreSelect
                                            name="coldStoreEstablishments"
                                            selectedFieldName="coldStoreEstablishments"
                                            setEstablishmentSelected={props.setFieldValue}
                                            selections={props.values.coldStoreEstablishments}
                                            label="Cold Store(s)"
                                            isMulti={true}
                                            countries={props.values.fisheryCountryOrigin.map(x => x.isoCode).map((str) => str.replace(/-(0|1|2|3|4|5)/g, ''))}
                                            certificateNumber={certificateNumber}
                                            getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                            getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                            isRequired={false}
                                            readOnly={readOnly}
                                        />

                                        <MultiSelectField
                                            id='identificationMarkEstablishment'
                                            name='identificationMarkEstablishment'
                                            label="Identification mark"
                                            placeholder="Select fields"
                                            options={Array.from(new Map([...(siteEstablishment || []), ...(coldStoreEstab || [])].map(item => [item["approvalNumber"], item])).values())}
                                            getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                            getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                            isMulti={false}
                                            onChange={props.setFieldValue}
                                            onBlur={props.setFieldTouched}
                                            touched={props.touched.identificationMarkEstablishment}
                                            error={props.errors.identificationMarkEstablishment}
                                            isClearable={true}
                                            isrequired="true"
                                            backspaceRemovesValue={true}
                                            readOnly={readOnly}
                                            col={6}
                                        />
                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default Fishery8361AddPage;