import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStamp, faBullhorn, faDownload, faSave, faFilePdf} from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Button, Alert, Card, Modal } from '@themesberg/react-bootstrap';
import BlobService from "../../../services/BlobService"
import EhcGenerateService from '../../../services/EhcGenerateService';
import ConsignmentService from '../../../services/ConsignmentService';
import CertificateCompletedService from '../../../services/CertificateCompletedService';
import Progress from "../../../../components/Progress";
import moment from "moment-timezone";
import ConsignmentContentsService from '../../../services/ConsignmentContentsService';
import CertificateAssignedService from '../../../services/CertificateAssignedService';
import CertificateCompletedCompoundService from '../../../services/CertificateCompletedCompoundService';
import EhcCompletedService from '../../../services/EhcCompletedService';

const ConsignmentContentsCertificatePanel = (props) =>{
    const {
        fetchListData,
        isApproved,
        consignmentId,
        setGenerated,
        isComplete,
        setComplete,
        setFormCompleted,
        isGenerated,
        isGenerateReady,
        apiDailyAttestationValues,
        contentsSelected,
        languageCode
    } = props;

let interval = undefined;
const [running, setRunning] = useState(false);
    const [showModalGenerating, setShowModalGenerating] = useState(false);
    const [showModalCompleting, setShowModalCompleting] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [isGenerating, setGenerating] = useState(false);
    const [completing, setCompleting] = useState(false);
    const [currentGenerating, setCurrentGenerating] = useState(0);
    const [currentCompleting, setCurrentCompleting] = useState(0);
    const [generatingCount, setTotalGenerating] = useState(0);
    const [completingCount, setCompletingTotal] = useState(0);
    const [generatingPercentage, setGeneratingPercentage] = useState(null);
    const [completingPercentage, setCompletingPercentage] = useState(null);
    const [generateTime, setGenerateTime] = useState('');
    const [completeTime, setCompleteTime] = useState('');
    const [populateData, setPopulateData] = useState([]);
    const [progressMessage, setProgressMessage] = useState('');

    
    const setErrorList = (errorList) => {
        var res = [];
        //Unique errors
        {errorList != null &&
            errorList.map(img => {
            if (res.indexOf(img) === -1) {
                res.push(img)
            }
            });
            setErrorMessages(res);
        }
    }


    //Modal - Close
    const handleCloseModal = () => {
        setShowModalGenerating(false);
        setShowModalCompleting(false);
    }



    //Generate EHCs
    const _generateEHCs = async (id) => {

        setErrorMessages([]);
        setRunning(true);
        setGeneratingPercentage(0);
        setGenerating(true);
        setShowModalGenerating(true);
         try{ 
            //Find blank certificates to match consignment contents with
            await EhcGenerateService.match(id)
            .then(async matchResponse => {
                var certificateErrorList = [];
                var certificatesToGenerate =[];
                const { data, errors} = matchResponse.data;
                //Check for friendly errors
                if(errors !== null && errors !== undefined)
                {
                    setErrorList(errors);
                }
                
                //Filter out any secondary combinations to leave only single and primary consignment contents
                data.filter(x=>x !== null).forEach(x=>{
                    var findSecondaryCombination = x.consignmentContents?.consignmentContentsCombinations?.find(x=> x.isPrimary == false);
                    if(findSecondaryCombination == null || findSecondaryCombination == undefined){
                        certificatesToGenerate.push(x)
                    }
                });

                //Start - Populate each assigned certificate
                if(certificatesToGenerate !== null){

                    let counter = 0;
                    let foundCompoundIds = [];
                    let totalCertificates = certificatesToGenerate?.length;
                    setGeneratingPercentage(1);
                    setTotalGenerating(totalCertificates);
                    
                    setRunning(true);

                    for (const assignedCert of certificatesToGenerate) {
                        counter++

                        setCurrentGenerating(counter);

                        let totalCompound = certificatesToGenerate.filter(x=>x.consignmentContentsId == assignedCert.consignmentContentsId)?.length;
                    
                        //Compound check - if this assigned cert has more than one certificate
                        if(totalCompound > 1){
                            if(!foundCompoundIds.includes(assignedCert.ConsignmentContents)){
                                var certificateNumbers = assignedCert?.consignmentContents?.relatedAttestation[0]?.certificateNumberList;
                                
                                //Add to compound list for whole consignment
                                const obj = {
                                    certificateCount: certificateNumbers.length,
                                    certificateNumbers: certificateNumbers,
                                    consignmentContentsId: assignedCert.consignmentContentsId
                                };
                                foundCompoundIds.push(obj);
                            }
                        }
                        let certificateId = assignedCert.certificateId;

                        try{
                            // Sequential fetch requests to populate certificates
                            const response = await EhcGenerateService.populateAssignedCertificate(assignedCert, languageCode, certificateId);
                            const result = await response.data;
                            setPopulateData((prevData) => [...prevData, result]);
                        }
                        catch{ 
                            //Errors
                            certificateErrorList.push(`Certificate ${counter} didn't generate due to a technical issue.`);
                            setErrorList(certificateErrorList);
                            continue;
                        }
                        finally{
                            

                            //Once all certificates are processed close modal and reset values
                            if(totalCertificates == counter){ 
                                // Compound
                                if(foundCompoundIds.length>0){
                                    try{
                                        //Generate Compound document
                                        foundCompoundIds.map(async x=>{
                                            const response = await EhcGenerateService.generateCompoundCertificate(x.consignmentContentsId, languageCode, 0, x.certificateCount);
                                            const result = await response.data;

                                            //Complete
                                            GenerateComplete();
                                        })
                                    }
                                    catch(e){
                                        //Complete
                                        GenerateComplete();
                                    }
                                }
                                //Complete
                                else{
                                    GenerateComplete();
                                }
                            }
                        }
                        //lastConsignmentContentId = assignedCert.consignmentContentId;
                    }
                }
                else{
                    GenerateComplete();  //Catch if certificatesToGenerate == null
                }
                //End - Populate each assigned certificate
            })

            //Catch - Match certificate service
            .catch(e => {
                //Check for thrown errors
                if(e?.response !== undefined){
                    const {data, errors} = e.response;
                    setErrorMessages([data?.Detail]);
                    setErrorList(errorMessages);
                    GenerateComplete();
                }
                // else{
                //     //Check for friendly errors
                //     if(errorMessages!==null){
                //         errorMessages.push(`Certificates cannot generate due to a technical issue.`);
                //     }
                //     setErrorList(errorMessages);
                // }
                GenerateComplete();
            });
        }
        catch(e){
            GenerateComplete();
        }
            //End - Populate each assigned certificate
        
    };
    

    //Complete EHC Package
    const _complete = async (consignmentId) => {

        setErrorMessages([]);
        setCompletingPercentage(0);
        setCompleting(true);
        setShowModalCompleting(true);
        try {
            var certificateErrorList = [];
            const consignmentContentsResponse = await ConsignmentContentsService.getAllByConsignmentId(consignmentId);
            const consignmentContents = consignmentContentsResponse.data;
            let counter = 0;
            let total = consignmentContents.length;
            setCompletingTotal(total);

            for (const content of consignmentContents) {

                let assignedCounter = 0;
                setCurrentCompleting(counter + 1);

                let isCompound = content.isCompound;
                // Get the first assigned certificate
                const assignedCertificateResponse = await CertificateAssignedService.getByConsignmentContentsId(content.id);

                //Check for assigned certificates
                if(assignedCertificateResponse === undefined || assignedCertificateResponse === null ){
                    certificateErrorList.push("Can't find certificates");
                    setErrorList(certificateErrorList);
                    return null;
                }

                const assignedCertificates = assignedCertificateResponse?.data;
                
                for (const assignedCertificate of assignedCertificates) {
                    
                    //Delete any existing
                    const existing = await CertificateCompletedService.getByConsignmentContentsId(assignedCertificate.consignmentContentsId)
                    
                    var existingData = existing.data;
                    if(existingData?.length > 0){
                        existingData.map(async x => { await CertificateCompletedService.remove(x.id); });    
                    }
                    
                    console.log("assignedCertificate")
                    console.log(assignedCertificate)
                    // Create final completed Pdf certificate
                    const populatedResponse = await EhcCompletedService.populateCertficateCompleted(assignedCertificate.consignmentContentsId, assignedCertificate.certificateId, languageCode);
                    
                    console.log("populatedResponse")
                    console.log(populatedResponse)
                    const documentId = populatedResponse.data; // Filename returned from response
                
                    //Create certificate completed / contents id link object
                    const certificateCompleted = {
                        consignmentContentsId: assignedCertificate.consignmentContentsId,
                        documentId: documentId,
                        certificateId: assignedCertificate.certificateId,
                        isCompound: isCompound
                    };
                    console.log("certificateCompleted")
                    console.log(certificateCompleted)
                    var createComplete = await CertificateCompletedService.create(certificateCompleted);
                    
                    var response = await createComplete.data;
                    if(response !== null && response!== undefined){
                        
                    }
                    
                    //Generate completed compound certificate 
                    if(isCompound){

                        //Delete any existing
                        const existingCompound = await CertificateCompletedCompoundService.getByConsignmentContentsId(assignedCertificate.consignmentContentsId)
                        var existingCompoundData = existingCompound.data;
                        if(existingCompoundData?.length > 0){
                            existingCompoundData.map(async x => { await CertificateCompletedCompoundService.remove(x.id); });    
                        }
                    

                        //Create completed compound certificate Pdf  
                        var compoundDocument = await EhcCompletedService.generateCompletedCompoundCertificate(assignedCertificate.consignmentContentsId);
                        var compoundDocumentId = await compoundDocument.data;

                        const certificateCompletedCompound = {
                            consignmentContentsId: assignedCertificate.consignmentContentsId,
                            documentId: compoundDocumentId
                        };
                        var res = await CertificateCompletedCompoundService.create(certificateCompletedCompound);
                        
                        if(res.data!==null){
                            assignedCounter++;
                            continue;
                        }
                    }
                    
                    assignedCounter++;
                }
                counter++

                if(counter == total){
                    // Mark consignment as complete
                const createResponse = await ConsignmentService.complete(consignmentId);
                    console.log(createResponse);

                    setIsCompleted(true);
                }
            }

          } catch (e) {
                console.info(e)
             console.info(e.response)
                certificateErrorList.push(`Sorry the consignment hasn't been completed due to a technical issue.`);
                setErrorList(certificateErrorList);
                CompleteFinished();
          }
                        // && assignedCounter == assignedCertificates.length
    };

    //Download ZIP EHC Package
    const _downloadEHCs = (consignmentId) => {
        EhcCompletedService.downloadPack(consignmentId)
        .then(response => {
           var res = response.data;
            BlobService.downloadBlobZip(res);
        })
        .catch(e => {
            setErrorList(e.response.data);
        });
    };

    const handlePrint = async (consignmentId) => {
        try {
            const response = await EhcCompletedService.printPack(consignmentId);
            const pdfUrls = response.data;
            for (const url of pdfUrls) {
                BlobService.openBlobPdf(url);
            }
        } catch (e) {
            console.error("Error in handlePrint:", e);
        }
    };

    const GenerateComplete = () =>{
        setGeneratingPercentage(100);
        setTimeout(() => {
            setGenerated(true);
            setGenerating(false);
            setRunning(false);
            handleCloseModal();
            fetchListData();
            setGeneratingPercentage(0)
            setTotalGenerating(0);
            setCurrentGenerating(0);
            setProgressMessage('')
        }, 2000);
    }
      
    const CompleteFinished = () =>{
        setCompletingPercentage(100);
        setTimeout(() => {
            setComplete(true);
            setCompleting(false);
            handleCloseModal();
            fetchListData();
            setCompletingPercentage(0)
            setCompletingTotal(0);
            setCurrentCompleting(0);
            setProgressMessage('')
        }, 2000);
    }
      
    useEffect(() => {
        if(isCompleted){
           // alert("completed");
            CompleteFinished();
            setFormCompleted(true);
            setIsCompleted(false);
        }
    }, [isCompleted]);


    useEffect(() => {
        if(running){

            var seconds = (40 * generatingCount);
            var duration = "";
            
            if(seconds>59){
                var m = moment.utc(moment.duration(seconds, "seconds").asMilliseconds());
                var roundUp = m.second() || m.millisecond() ? m.add(1, 'minute').startOf('minute') : m.startOf('minute');
                duration = roundUp.format("m") + " minutes"
            }
            else{
                duration = moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("s")+ " seconds"
              }
            var increment = ( 0.007 / contentsSelected.length );

            setGenerateTime(duration);

            const timeout = setTimeout(() => {
                setGeneratingPercentage((prev) => prev + increment);
            }, 1);
            // if (generatingPercentage >1 && generatingPercentage <= 10) {
            //     setProgressMessage("Processing the certificate(s) data...");
            // }
            if (generatingPercentage >1 && generatingPercentage <= 99) {
                setProgressMessage(`Generating certificate ${currentGenerating} of ${generatingCount}`);
            }
            // if (generatingPercentage >80 && generatingPercentage <= 100) {
            //     setProgressMessage("Finishing up...");
            // }
            if (generatingPercentage >= 99) {
               // setGeneratingPercentage(0);
              clearTimeout(timeout);
             // setRunning(false);
            }
            return () => (clearTimeout(timeout));
        }
    }, [running, generatingPercentage]);



    useEffect(() => {
    if(completing){
        var seconds = (20 * completingCount);
        var duration = "";
        if(seconds>59){
            var m = moment.utc(moment.duration(seconds, "seconds").asMilliseconds());
            var roundUp = m.second() || m.millisecond() ? m.add(1, 'minute').startOf('minute') : m.startOf('minute');
            duration = roundUp.format("m") + " minutes"
        }
        else{
            duration = moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("s")+ " seconds"
            }
        var increment = ( 0.01 / contentsSelected.length );

        setCompleteTime(duration);

        const timeout = setTimeout(() => {
            setCompletingPercentage((prev) => prev + increment);
        }, 1);
        if (completingPercentage >1 && completingPercentage <= 99) {
            setProgressMessage(`Stamping certificate ${currentCompleting} of ${completingCount}`);
        }
        if (completingPercentage >= 99) {
            clearTimeout(timeout);
        }
        return () => (clearTimeout(timeout));
    }
    }, [completing, completingPercentage]);

    
    return(

        <Card border="light" className="bg-white shadow-sm mb-4 w-100">
            <Card.Header className="align-items-center">
                <h4 className="text-title mb-0" id="tabelLabel" >Export Health Certificates</h4>
            </Card.Header>
            <Card.Body className='d-flex flex-column'>
     

                {/* Zip Download */}
                {(isGenerated && isComplete) || isApproved ?
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                This consignment has been completed.
                            </p>
                            <p className="mb-1">
                                Download a zip file containing all of the completed certificates for upload to gov.uk.
                            </p>
                        </div>
                        <div className="col-md-8 mb-2">
                            <Button onClick={() => _downloadEHCs(consignmentId)}  variant="tertiary" size="md" className=" ">
                                <FontAwesomeIcon icon={faDownload} className=" me-2 " />
                                <span>Download Certificate Pack (ZIP)</span>
                            </Button>
                        </div>
                        <div className="col-md-12">
                            <p className="my-1">
                                Open all completed certificates in your browser.
                            </p>
                        </div>
                        <div className="col-md-10 ">
                            <Button onClick={() => handlePrint(consignmentId)} variant="tertiary" size="md" className="  ">
                                <FontAwesomeIcon icon={faFilePdf} className=" me-2 " />
                                <span>Display Completed Certificates (PDF)</span>
                            </Button>
                        </div>
                    </div>
                :
                <>
                    {/* Generate Certificates */}
                    <Row className={!isGenerateReady || isGenerating || apiDailyAttestationValues?.length <=0 ? "disabled opacity-50": ""}>
                        {isGenerated?
                            <Col lg={8}><strong>Generate (Unmatched)</strong> 
                                <p>Generate all ungenerated certificates in this consignment. This will not affect consignment contents already with certificates.
                                    <small className="text-italic text-danger mb-0">This may take a few minutes to process.</small>
                                </p>
                            </Col>
                        :
                            <Col lg={8}><strong>Generate</strong> 
                                <p>for this consignment once you have added all contents to this consignment and saved changes.
                                    <small className="text-italic text-danger mb-0">This may take a few minutes to process.</small>
                                </p>
                            </Col>
                        }
                        <Col lg={4}>
                            <Button
                                disabled={!isGenerateReady || isGenerating || apiDailyAttestationValues?.length <=0}
                                onClick={() => _generateEHCs(consignmentId)}
                                variant={isGenerated?"light" : "primary"}
                                size="md"
                                className={`my-1 me-3 w-100 ${isGenerated? "text-dark" : "text-light"}}`}>
                                <FontAwesomeIcon icon={faStamp} className="me-2" />
                                <span>{isGenerating ? "Generating..." : isGenerated? "Generate" : "Generate"}</span>
                            </Button>
                        </Col>
                    </Row>

                    {/* Complete */}
                    <Row className={!isComplete && isGenerated ? "": "disabled opacity-50"}>
                        <Col lg={8}>
                        <strong>Complete</strong> the consignment once you have generated all the certificates.<br />
                            <small className="text-italic text-danger mb-0">Further changes cannot be made once a consignment has been completed.</small>
                        </Col>
                        <Col lg={4}>
                            <Button
                                disabled={!isGenerateReady || isGenerating ||  apiDailyAttestationValues?.length <=0}
                                onClick={() => _complete(consignmentId)}
                                variant="primary"
                                size="md"
                                className="my-1 w-100">
                                <FontAwesomeIcon icon={faSave} className="me-2" />
                                <span>{isGenerating ? "Saving. Please wait..." : "Complete"}</span>
                            </Button>
                        </Col>
                    </Row>

                    {/* //Modal - Start*/}
                    <Modal size="lg" as={Modal.Dialog} centered show={showModalGenerating}>
                        <Modal.Body>
                            <div className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-center col-12">
                                        <div className='text-center p-4 p-lg-5 w-100 fmxw-500'>
                                        <h2 className="mb-5">Generating Certificate(s)</h2>
                                        <p>
                                            This will take an estimated {generateTime} to generate. Please wait on this screen until the process completes..</p>
                                        <div className="generateprogress"><Progress value={generatingPercentage} variant="success" label="" size="xl" />
                                            <label>{progressMessage}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    
                    {/* //Modal - Start*/}
                    <Modal size="lg" as={Modal.Dialog} centered show={showModalCompleting}>
                        <Modal.Body>
                            <div className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-center col-12">
                                        <div className='text-center p-4 p-lg-5 w-100 fmxw-500'>
                                        <h2 className="mb-5">Completing consignment</h2>
                                        <p>The final certificates are now being produced for the products in this consignment.

                                            <br/>
                                            This will take an estimated {completeTime} to generate. Please wait on this screen until the process completes..
                                        </p>
                                        <div className="completeprogress"><Progress value={completingPercentage} variant="success" label="" size="xl" />
                                            <label>{progressMessage}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
                }

                {errorMessages?.map((x,i) =>
                    <Alert
                        className="mt-2"
                        variant="warning"
                        size={"sm"}
                    >
                        <div className="d-flex justify-content-start">
                            <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                            {x}
                        </div>
                    </Alert>
                )}
            </Card.Body>
        </Card>
    )
}


export default ConsignmentContentsCertificatePanel;
