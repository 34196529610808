import { certificateRoutes } from "../components/CertificateForms/CertificateFormMap";
import *  as baseService  from "./BaseService";

const getLatest = () => {
    return baseService.getApiData("/Activity/GetLatestByLoggedInUser");
};

const log = (title, description, section, action, url, id) => {
    const data = {
        title: title,
        description: description,
        section: section,
        action: action,
        url: url,
        associatedId: id
    };
    return baseService.postApiData("/Activity/create", data);
};


const logCertForm = (section, number, action, isGEFS, productId, productName) => {
    var certRoute = certificateRoutes.find(x=>x.number == number);
    var baseUrl = isGEFS ? certRoute.GEFS : certRoute.NonGEFS;
    var url = baseUrl.replace(":id", productId);
    var newDescription = `A ${number} certificate form was added for ${productName}`
    var editDescription = `An ${number} certificate form was edited for ${productName}`
    
    const data = {
        title: (action=="Create"? "Added" : "Edited") + " certificate information form",
        description: (action=="Create"? newDescription : editDescription),
        section: section,
        action: action,
        url: url,
        associatedId: productId
    };
    return baseService.postApiData("/Activity/create", data);
};


const ActivityService = {
    getLatest,
    log,
    logCertForm
};

export default ActivityService;