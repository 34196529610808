
import React  from "react";
import NoAccess from "../pages/error/NoAccess";
import { permissions, roles } from "./PermissionsMap";
import { getUserRoles } from "./authorisation";

//Check user has permission requested in scopes
export const hasPermission = ({ permission, scopes }) => {
  const scopesMap = {};
  scopes.forEach((x) => {
    scopesMap[x] = true;
  });
  return permission.some((p) => scopesMap[p]);
};

//Check user has permission requested in roles requested
export const hasRole = ({ role, requiresRole }) => {
  const rolesMap = {};
  requiresRole.forEach((x) => {
    rolesMap[x] = true;
  });

  return role.some((role) => rolesMap[role]);
};

//Check user has permissions from scope list but not the excluded scopes list
export function isPermitted( scopes = []){
  return IsReadOnlyPermissions(scopes, []);
}

//Check user has permissions from scope list but not the excluded scopes list
export function IsReadOnlyPermissions( scopes = [], scopesExcluded = [],){
  const role = getUserRoles();
    let permissionGranted = false;
    let permissionFoundExclusions = false;
    role.forEach((x) => {
      const permission = permissions[x];
      if(permissionGranted == false){
       permissionGranted = hasPermission({permission: permission, scopes: scopes });
      }
      if(permissionFoundExclusions == false){
        permissionFoundExclusions = hasPermission({permission: permission, scopes: scopesExcluded });
      }
    });
    if(permissionGranted && !permissionFoundExclusions){return true}
  return false;
}

//Component to check permissions
export default function PermissionCheck({
    children, errorProps = null, RenderError = () => <NoAccess/>,  scopes = [], requiresRole = [], scopesExcluded = []
  }) {
    
  //Get all roles from user
  const role = getUserRoles();

  //Role based lookup
  if (requiresRole.length > 0 ){
    const roleGranted = hasRole({ role, requiresRole });
    if (roleGranted){
      return <>{children}</>;
    }
    else{
      return null;
    }
  } 

  //Permission scope based lookup
  if (scopes.length > 0 && requiresRole.length<=0){
    let permissionGranted = false;
    let permissionFoundExclusions = false;
    role.forEach((x) => {
      const permission = permissions[x];
      if(permissionGranted == false){
       permissionGranted = hasPermission({permission: permission, scopes: scopes });
      }
      //Check user has scopes but not excluded scopes
      if(scopesExcluded.length> 0 && permissionFoundExclusions == false){
        permissionFoundExclusions = hasPermission({permission: permission, scopes: scopesExcluded });
      }
    });
    //Return exlusions based lookup
    if(scopesExcluded.length> 0){
      if(permissionGranted && !permissionFoundExclusions) {return <>{children}</>; } else{ return null;}
    }
    //Return regular scope only permisison lookup
    if(!permissionGranted && errorProps?.disabled){return null}
    if (!permissionGranted && (!errorProps)) return <RenderError />;
  }

  return <>{children}</>;
}