import React from "react";
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import ScrollToTop from "../../../components/ScrollToTop";
import ReactHtmlParser from "react-html-parser";
import {
  faExclamationTriangle,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import { OpenBlobButton } from "./OpenBlobButton";

export default function FormSubmission(props) {
  const {
    errors,
    title,
    message,
    successButtonUrl,
    successButtonName,
    signedDocumentUrl = null,
  } = props;

  return (
    <>
      <ScrollToTop />
      <Row>
        <Col lg={8}>
          <Card border="light" className="bg-white shadow-sm mb-4 p-3">
            <Card.Body>
              <div className="text-center">
                {errors !== null ? (
                  <>
                    <h2>
                      <i className="vtdcomplete">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="mx-2 text-danger"
                        />
                      </i>
                      Sorry, there was a problem
                    </h2>
                    <p>The form has not been saved.</p>
                    {Array.isArray(errors) ? (
                      <p>{errors.Title} (Id: {errors.Id}, Code: {errors.Status})</p>
                    ) : typeof errors === 'object' && errors.Detail !== null ? (
                      <p>{errors?.Detail}</p>
                    ) : typeof errors === 'string' ? (
                      <p>{errors}</p>
                    ) : (
                      <p></p>
                    )
                    }
                  </>
                ) : (
                  <>
                    <h2>
                      <i className="vtdcomplete">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="mx-2 text-success"
                        />
                      </i>
                      {ReactHtmlParser(title)}
                    </h2>
                    <br />
                    <p>{ReactHtmlParser(message)}</p>

                    {signedDocumentUrl !== null && (
                      <>
                        <OpenBlobButton
                          signedDocumentUrl={signedDocumentUrl}
                          linkText={"Signed Declaration"}
                        />
                      </>
                    )}
                  </>
                )}

                <div className="btn-panel left">
                  <Button variant="outline-primary" href={successButtonUrl}>
                    {successButtonName}
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
