import * as Yup from 'yup';
import moment from 'moment';
import formModel from './NonGEFSModel';

const {
  formField: {
    isGEFS,
    vetId,
  }
} = formModel;


export default [
  Yup.object().shape({
    [vetId.name]: Yup.object().required(`${vetId.requiredErrorMsg}`),
  })
];
