import { faBullhorn, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Card, Col, Container, Row, } from "@themesberg/react-bootstrap";
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import SiteSelect from '../../../components/EstablishmentSelect/SiteSelect';
import { Routes } from "../../../routes";
import EstablishmentDataService from "../../services/EstablishmentTypeService";
import EstablishmentsService from "../../services/EstablishmentsService";
import ManufacturerDataService from "../../services/ManufacturerService";
import SiteDataService from "../../services/SiteService";
import FormController from "../common/FormController";
import FormSubmission from "../common/FormSubmission";
import { MultiSelectField } from "../common/MultiSelect";
import CountryService from '../../services/CountryService';
import ActivityService from '../../services/ActivityService';
import SiteImages from '../../../pages/components/Sites/SiteImages';
const SiteAddPage = (props) => {
    const defaultCountryCode = "GB";
    const [isSubmitting, setSubmitting] = useState(false);
    const [title, setTitle] = useState("Add Site");
    const [siteName, setSiteName] = useState('');
    const [defaultCountry, setDefaultCountry] = useState('');
    const [siteId] = useState(props.match.params.id);
    const [editing, setEditing] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [ready, SetReady] = useState(false);
    const [apiValues, setApiValues] = useState([]);
    const [countries, setCountries] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [errors, setErrors] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [establishmentSelected, setEstablishmentSelected] = useState(null);
    const [selectedEstablishment, setSelectedEstablishment] = useState(null);
    const [establishmentsDetails, setEstablishmentsDetails] = useState(null);

    const breadcrumbData = {
        item1: { name: 'Sites', link: Routes.Sites.path },
        item2: { name: editing ? siteName : ('Add Site'), link: editing ? Routes.Sites.path.replace(":id", siteId) : '#' },
        item3: { name: editing ? ('Edit Site') : null, link: '#' },
    };

    const [manufacturers, setManufacturers] = useState([]);
    const [establishmentNumberTypes, SetEstablishmentNumberTypes] = useState([]);

    const initialValues = {
        establishmentChoice: apiValues?.establishmentChoice ?? [],
        siteName: apiValues?.siteName ?? '',
        address1: apiValues?.address1 ?? '',
        address2: apiValues?.address2 ?? '',
        townCity: apiValues?.townCity ?? '',
        countryId: apiValues?.countryId ?? defaultCountry,
        postcode: apiValues?.postcode ?? '',
        isApproved: apiValues?.isApproved ?? '',
        establishmentNumber: apiValues?.establishmentNumber ?? '',
        registrationNumber: apiValues?.establishmentNumber ?? '',
        manufacturerId: apiValues?.manufacturer ?? '',
        selectedEstablishment: [],
    }

    let validationMaxLength = "Exceeded maximum length allowed for this field";

    const validationSchema = Yup.object().shape({
        siteName: Yup.string().required("Must enter site name").max(100, validationMaxLength),
        address1: Yup.string().required("Must enter an address line").max(150, validationMaxLength),
        address2: Yup.string().max(150, validationMaxLength),
        townCity: Yup.string().required("Must enter a town or city").max(50, validationMaxLength),
        postcode: Yup.string().required("Must enter a postcode").max(10, validationMaxLength),
        manufacturerId: Yup.object().nullable().required("Must choose a manufacturer"),
        //establishmentType: Yup.object().nullable().required("Must choose an approval or registration type"),
        isApproved: Yup.string().nullable().required("Must select approval type"),
        // establishmentId: Yup.array().nullable().required("Must choose an approval or establishment id"),
        establishmentChoice: Yup.array()
            //.nullable().required("Must choose an approved establishment").min(1)
            .when("isApproved", {
                is: "1",
                then: Yup.array().min(1, "Find and add a site before saving"),
            }),
        establishmentNumber: Yup.string()
            .when("isApproved", {
                is: "1",
                then: Yup.string().max(50, validationMaxLength).required(`Must choose an approved establishment`),
            }),
        registrationNumber: Yup.string()
            .when("isApproved", {
                is: "2",
                then: Yup.string().max(50, validationMaxLength).required(`Must choose a registration number`),
            }),

    });

    const handleselectedEstablishmentChange = (newselectedEstablishment, formik) => {
        console.log("newselectedEstablishment", newselectedEstablishment);
        // setEstablishmentSelected(newselectedEstablishment);
        // selectedEstablishment = selectedEstablishment;
        formik.setFieldValue("selectedEstablishment", newselectedEstablishment); // Update Formik field
        formik.setFieldValue("establishmentChoice", newselectedEstablishment); // Update Formik field

    };
    const handleEstablishmentsDetailsChange = (newEstablishmentsDetails, formik) => {
        console.log("newEstablishmentsDetails", newEstablishmentsDetails);
        console.log("newEstablishmentsDetails.addressLine1", newEstablishmentsDetails.addressLine1);

        // setEstablishmentsDetails(newEstablishmentsDetails);
        formik.setFieldValue("siteName", newEstablishmentsDetails.name); // Update Formik field
        formik.setFieldValue("address1", newEstablishmentsDetails.addressLine1); // Update Formik field
        formik.setFieldValue("address2", newEstablishmentsDetails.addressLine2); // Update Formik field
        formik.setFieldValue("townCity", newEstablishmentsDetails.city); // Update Formik field
        formik.setFieldValue("postcode", newEstablishmentsDetails.postalCode); // Update Formik field
        formik.setFieldValue("establishmentNumber", newEstablishmentsDetails.approvalNumber); // Update Formik field
        // selectedEstablishment = selectedEstablishment;

    };
    const handleErrorsChange = (newErrorsChange) => {
        console.log("newErrorsChange", newErrorsChange);

        setErrorMessage(newErrorsChange);

    };

    function onSubmit(values) {
        setSubmitting(true);
        setSubmitAttempted(true);
        setTimeout(() => {
            let submitData = values;
            submitData.manufacturerId = values.manufacturerId.value;
            submitData.countryId = values.countryId?.value;

            //Set submission data based on approval type
            if (submitData.isApproved == 1) {
                submitData.isApproved = true;
                submitData.establishmentNumber = values.establishmentNumber;
                submitData.establishmentNumberTypeId = establishmentNumberTypes.find(x => x.key == "Approval Number")?.value;
                submitData.tracesId = values.establishmentChoice[0].operatorId;
                submitData.tracesActive = true;
            };
            if (submitData.isApproved == 2) {
                submitData.isApproved = false;
                submitData.establishmentNumber = values.registrationNumber;
                submitData.establishmentNumberTypeId = establishmentNumberTypes.find(x => x.key == "Registration Number")?.value;
                submitData.tracesId = 0;
                submitData.tracesActive = false;
            };
            // if (establishmentsDetails) {
            //     submitData.isApproved = true;
            //     submitData.establishmentNumber = establishmentSelected.approvalNumber;
            //     submitData.establishmentNumberTypeId = establishmentNumberTypes.find(x => x.key == "Approval Number")?.value;
            //     submitData.tracesId = selectedEstablishment.operatorId;
            //     submitData.tracesActive = true;
            //     submitData.address1 = establishmentsDetails.addressLine1;
            //     submitData.address2 = establishmentsDetails.addressLine2;
            //     submitData.townCity = establishmentsDetails.city;
            //     submitData.siteName = establishmentSelected.name;
            //     submitData.postcode = establishmentsDetails.postalCode;
            // };
            // setEstablishmentsDetails(submitData);
            console.log("submitData")
            console.log(submitData)
            console.log("submitData")

            if (!editing) {
                SiteDataService.create(submitData)
                    .then(async response => {
                        //Create Activity Log
                        await ActivityService.log(
                            'Added a site',
                            'A site named ' + values.siteName + ' has been added',
                            'Site',
                            'Create',
                            Routes.SiteViewPage.path.replace(":id", response.data.id),
                            response.data.id
                        );
                        setFormSubmitted(true);
                    })
                    .catch(e => {
                        setFormSubmitted(true);
                        console.info("SiteDataService not editing");
                        console.info(e.toString());
                        setErrors(e.response.error);
                    });
            }
            else {
                SiteDataService.update(siteId, submitData)
                    .then(async response => {
                        //Create Activity Log
                        await ActivityService.log(
                            'Edited a site',
                            'A site named ' + values.siteName + ' has been edited',
                            'Site',
                            'Update',
                            Routes.SiteViewPage.path.replace(":id", response.data.id),
                            response.data.id
                        );

                        setFormSubmitted(true);
                        //   resetForm({})
                    })
                    .catch(e => {
                        setFormSubmitted(true);
                        console.info("SiteDataService editing");
                        console.info(e.toString());
                        setErrors(e.response.error);
                    });
            }
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    const _clearSelectedEstablishment = (form) => {
        setEstablishmentSelected(null);
        form.setFieldValue("establishmentChoice", []);
        form.setFieldValue("siteName", '');
        form.setFieldValue("addressLine1", '');
        form.setFieldValue("addressLine2", '');
        form.setFieldValue("townCity", '');
        form.setFieldValue("postcode", '');
        form.setFieldValue("establishmentNumber", '');
        form.setFieldValue("selectedEstablishment", '');
    }


    useEffect(() => {
        async function fetchListData() {
            try {

                const getCountryData = new Promise((resolve, reject) => {
                    resolve(CountryService.getCountriesForSites()
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "value": d.id,
                                "label": `${d.countryName} (${d.isoCode})`
                            })).sort((a, b) => a.label.localeCompare(b.label));

                            setCountries(response.data);
                            setCountryOptions(options);
                            // const findCountry = options.find((item) => item.label?.includes(defaultCountryCode));
                            // setDefaultCountry(findCountry);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getManufacturerData = new Promise((resolve, reject) => {
                    resolve(ManufacturerDataService.getAll()
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "value": d.id,
                                "label": d.manufacturerName
                            })).sort((a, b) => a.label.localeCompare(b.label));
                            setManufacturers(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getEstablishmentNumberTypeData = new Promise((resolve, reject) => {
                    resolve(EstablishmentDataService.getAll()
                        .then((response) => {
                            console.log("EstablishmentNumberType | GetAll | Data: " + JSON.stringify(response.data));
                            const options = response.data.map(d => ({
                                "value": d.id,
                                "key": d.typeName
                            }))
                            SetEstablishmentNumberTypes(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });


                Promise.all([getCountryData, getManufacturerData, getEstablishmentNumberTypeData]).then((responses) => {
                    SetReady(true);
                });
            } catch (e) {
                console.error(e);
            }
        };
        fetchListData();
    }, []);

    useEffect(() => {
        if (ready) {
            if (siteId !== null && siteId !== "" && siteId !== undefined) {
                setTitle("Edit Site");
                setEditing(true);
                SiteDataService.getById(siteId)
                    .then(response => {
                        var res = response.data;
                        setSiteName(res.siteName);
                        if (res.isApproved) { res.isApproved = 1 } else { res.isApproved = 2 };
                        if (res.establishmentNumberTypeId !== "" && establishmentNumberTypes !== "") {
                            const establishmentNumberType = establishmentNumberTypes.find(x => x.value === res.establishmentNumberTypeId);
                            res.establishmentNumberType = establishmentNumberType;
                        }
                        if (res.manufacturerId !== "" && manufacturers !== "") {
                            const manufacturer = manufacturers.find(x => x.value === res.manufacturerId);
                            res.manufacturer = manufacturer;
                        }
                        if (res.countryId !== "" && countries !== "") {
                            const country = countryOptions.find(x => x.value === res.countryId);
                            res.countryId = country;
                        }
                        setApiValues(res);
                        console.info(res)
                    });
            }

        }
    }, [ready]);

    const approvedOptions = [
        { value: 1, key: "Yes" },
        { value: 2, key: "No" },
    ];
    const handleIsApprovedChange = (setFieldValue, isApprovedValue) => {
        setFieldValue('isApproved', isApprovedValue);

        if (isApprovedValue === "2") {
            setFieldValue('establishmentChoice', []);
            setFieldValue('siteName', '');
            setFieldValue('address1', '');
            setFieldValue('address2', '');
            setFieldValue('townCity', '');
            setFieldValue('postcode', '');
            setFieldValue('establishmentNumber', '');
            setFieldValue('registrationNumber', '');
        }
    };

    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <FormSubmission
                    errors={errors}
                    title={editing ? "Edited Site" : "Added Site"}
                    message={`This site has been successfully ${editing ? "edited" : "added"}.`}
                    successButtonUrl={Routes.Sites.path}
                    successButtonName="Back to Sites"
                />
            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize="true"
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {(props, errors) => (
                            <Col md={8}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Body>
                                        <Form>
                                            <MultiSelectField
                                                isMulti={false}
                                                label="Country"
                                                name="countryId"
                                                placeholder="Select a country"
                                                isrequired="true"
                                                options={countryOptions}
                                                onChange={props.setFieldValue}
                                                onBlur={props.setFieldTouched}
                                                touched={props.touched.countryId}
                                                error={props.errors.countryId}
                                            />
                                            <MultiSelectField
                                                id="manufacturerId"
                                                name="manufacturerId"
                                                label="Manufacturer"
                                                placeholder="Select a manufacturer"
                                                selectOnSingleResult={true}
                                                //options={manufacturers?.filter((item, index) => index === 0)}// to test single manufacturer
                                                options={manufacturers}
                                                isMulti={false}
                                                isClearable={true}
                                                onChange={props.setFieldValue}
                                                onBlur={props.setFieldTouched}
                                                touched={props.touched.manufacturerId}
                                                error={props.errors.manufacturerId}
                                                backspaceRemovesValue={true}
                                                isrequired="true"
                                                col={8}
                                            />

                                            {props.values.countryId && props.values.manufacturerId &&
                                                <>

                                                    <div className="my-3">
                                                        <label className="isrequired my-2">
                                                            Is the manufacturing site listed on TRACES as an approved site?&nbsp;
                                                            (
                                                            <a target="_blank" href="https://webgate.ec.europa.eu/tracesnt/directory/listing/establishment/publication/index#!/search">
                                                                See approved sites
                                                            </a>
                                                            )
                                                        </label>
                                                        <FormController
                                                            control="radio"
                                                            name="isApproved"
                                                            isrequired="true"
                                                            col={6}
                                                            options={approvedOptions}
                                                            onChange={(e) => handleIsApprovedChange(props.setFieldValue, e.target.value)}
                                                        />
                                                    </div>

                                                    {props.values.isApproved == 1 && props.values.selectedEstablishment?.length == 0 &&
                                                        <Row>

                                                            <Col xl={12}>
                                                                <label>Find the establishment</label>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <SiteImages props={props} countryId={props.values.countryId} setErrorMessage={handleErrorsChange} setEstablishmentSelected={(newSelected) => handleselectedEstablishmentChange(newSelected, props)} setEstablishmentsDetails={(newSelected) => handleEstablishmentsDetailsChange(newSelected, props)} />

                                                            </Col>
                                                            <Col xl={1} className="text-center py-3">
                                                                or
                                                            </Col>
                                                            <Col xl={6}>
                                                                <div className="py-1">
                                                                    <SiteSelect
                                                                        isAddressRequired={true}
                                                                        name="establishmentChoice"
                                                                        selectedFieldName="selectedEstablishment"
                                                                        setEstablishmentSelected={props.setFieldValue}
                                                                        selections={props.values.establishmentChoice}
                                                                        isMulti={false}
                                                                        countries={countries.filter(x => x.id == props.values?.countryId?.value).map(x => x.isoCode)}
                                                                        certificateNumber={null}
                                                                        getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                                        getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                                        isRequired={true}
                                                                    />
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    }

                                                    {props.values.isApproved == 1 &&
                                                        <>
                                                            <Row>
                                                                <Col md={4} className="py-3 left-button" >
                                                                    {props.values.selectedEstablishment?.length > 0 &&
                                                                        <Button
                                                                            variant='light'
                                                                            size="sm"
                                                                            className="mb-2"
                                                                            onClick={() => _clearSelectedEstablishment(props)}
                                                                        >
                                                                            Change selection
                                                                        </Button>
                                                                    }
                                                                </Col>
                                                                <Col md={12} className="py-3">
                                                                    {errorMessage != null && props.values.selectedEstablishment?.length == 0 &&
                                                                        <p>
                                                                            <i className="vtdcomplete">
                                                                                <FontAwesomeIcon icon={faExclamationTriangle} className="mx-2 text-danger" />
                                                                            </i>
                                                                            {errorMessage}
                                                                        </p>

                                                                    }
                                                                    <hr />
                                                                    {props.values.selectedEstablishment?.length > 0 &&
                                                                        <Container className="px-3">
                                                                            <Row className="my-3"><Col md={3} className="fw-bold">Approval number:</Col><Col> {props.values.establishmentNumber}</Col></Row>
                                                                            <Row className="my-3"><Col md={3} className="fw-bold">Site name:</Col><Col> {props.values.siteName}</Col></Row>
                                                                            <Row className="my-3"><Col md={3} className="fw-bold">Address:</Col><Col> {props.values.address1}<br />{props.values.address2}</Col></Row>
                                                                            <Row className="my-3"><Col md={3} className="fw-bold">Town / city:</Col><Col> {props.values.townCity}</Col></Row>
                                                                            <Row className="my-3"><Col md={3} className="fw-bold">Postcode:</Col><Col> {props.values.postcode}</Col></Row>
                                                                        </Container>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                </>

                                            }





                                            {props.values.isApproved == 2 &&
                                                <>
                                                    <FormController
                                                        control="input"
                                                        type="text"
                                                        label="Site Name"
                                                        name="siteName"
                                                        isrequired="true"
                                                        col={6}
                                                    />
                                                    <FormController
                                                        control="input"
                                                        type="text"
                                                        label="Address line 1"
                                                        name="address1"
                                                        isrequired="true"
                                                        col={6}
                                                    />
                                                    <FormController
                                                        control="input"
                                                        type="text"
                                                        label="Address line 2"
                                                        name="address2"
                                                        col={6}
                                                    />
                                                    <FormController
                                                        control="input"
                                                        type="text"
                                                        label="Town / City"
                                                        name="townCity"
                                                        isrequired="true"
                                                        col={6}
                                                    />
                                                    <FormController
                                                        control="input"
                                                        type="text"
                                                        label="Postcode"
                                                        name="postcode"
                                                        isrequired="true"
                                                        col={4}
                                                    />
                                                    <FormController
                                                        control="input"
                                                        type="text"
                                                        label="Food local authority registration"
                                                        sublabel="(if available, otherwise N/A)"
                                                        name="registrationNumber"
                                                        isrequired="true"
                                                        col={4}
                                                    />
                                                    {/* <div className="d-none">
                                                        <PrettyPrintJson data={props.values} />
                                                    </div> */}
                                                </>
                                            }
                                            {Object.keys(props.errors).length > 0 &&

                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <div className="form-group mt-3">
                                                <Button type="submit"
                                                    disabled={isSubmitting}
                                                >{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default SiteAddPage;

