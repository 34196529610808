import * as Yup from 'yup';
import formModel from './FormModel';

const {
  formField: {
    formsComplete,
    batches,
    numberPackages,
    netWeight,
    grossWeight,
    invoiceNumber,	 
    decision,
    rejectReason,
    signedRequestUrl,
    documentId
  }
} = formModel;

const today = new Date();
const validationMsgDecimal = "Enter a decimal to a maximum of 3 decimal places (e.g 10.000)";

export default [
  //STAGE 1  
  Yup.object().shape({
    [batches.name]: Yup.string().required(batches.requiredErrorMsg),
    [numberPackages.name]: Yup.number().required(numberPackages.requiredErrorMsg),
    [invoiceNumber.name]: Yup.string().required(invoiceNumber.requiredErrorMsg),
    [netWeight.name]: Yup
      .number().typeError("Must enter a decimal")
      .required(netWeight.requiredErrorMsg)
      .min(0, "Must not be a negative number").test('is-decimal', validationMsgDecimal,
      (value) => /^\d{1,30}(\.\d{0,3})?$/.test(value)),
    [grossWeight.name]: Yup
      .number().typeError("Must enter a decimal")
      .required(grossWeight.requiredErrorMsg)
      .min(0, "Must not be a negative number")
      .min(Yup.ref("netWeight"), "Gross weight must not be less than net weight").test('is-decimal', validationMsgDecimal,
      (value) => /^\d{1,30}(\.\d{0,3})?$/.test(value)
),
  }),

  //STAGE 2  
  Yup.object().shape({
    [signedRequestUrl.name]: Yup.string().required(signedRequestUrl.requiredErrorMsg),
    [documentId.name]: Yup.string().required(documentId.requiredErrorMsg)
 })
];
