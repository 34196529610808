import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import ProductDataService from "../../../services/ProductService";
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import moment from "moment-timezone";
import { Formik, Form, FieldArray, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import ProductWildGameService from "../../../services/ProductWildGameService";
import ZoneService from "../../../services/ZoneService";
import TemperatureService from "../../../services/TemperatureService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import * as WildGame8367Labels from "./WildGame8367Labels"
import { Col, Row, Button, Card, Alert } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faLeaf, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import ActivityService from '../../../services/ActivityService';


const WildGame8367AddPage = (props) => {

    const [isSubmitting, setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [productDetail, setProductDetail] = useState([]);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [storageRequired, SetStorageRequired] = useState(false);
    const [zones, SetZones] = useState([]);
    const [dateRequired, SetDateRequired] = useState(false);
    const [productDetailCountry, SetCountry] = useState([]);
    const [apiValues, SetApiValues] = useState([]);


    let title = (editing == true ? "Edit " : "New") + " Wild Game Information (#8367-For Wild Game only)"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Wild Game 8367', link: Routes.WildGame8367AddPage.path },
    };
    const initialValues = {
        productId: props.match.params.id,
        storageRequired: false,
        storageDates: apiValues?.storageDates ?? [],
        health_1_4_statements: apiValues?.health_1_4_statements ?? 0,
        health_1_7: apiValues?.health_1_7 ?? false,
        species: apiValues?.species ?? '',
        animal_2_1_zone: apiValues?.animal_2_1_zone ?? [],
        animal_2_1_statements: apiValues?.animal_2_1_statements ?? 0,
        animal_2_1_date: apiValues?.animal_2_1_date ?? null,
        animal_2_2AA: apiValues?.animal_2_2AA ?? null,
        animal_2_2AB: apiValues?.animal_2_2AB ?? null,
        animal_2_2AC: apiValues?.animal_2_2AC ?? null,
        animal_2_4_statements: apiValues?.animal_2_4_statements ?? 0,
        animal_2_5: apiValues?.animal_2_5 ?? false,
        animal_2_5_A: apiValues?.animal_2_5_A ?? false,
        animal_2_5_B: apiValues?.animal_2_5_B ?? false,
        dateRequired: false,
        wildGameCarcaseWhole: apiValues?.wildGameCarcaseWhole ?? false,
        wildGameCarcaseSide: apiValues?.wildGameCarcaseSide ?? false,
        wildGameCarcaseQuarters: apiValues?.wildGameCarcaseQuarters ?? false,
        wildGameCuts: apiValues?.wildGameCuts ?? false,
        wildGameOffal: apiValues?.wildGameOffal ?? false,
        treatmentType: apiValues?.treatmentType ?? '',
        euApprovalNumber: apiValues?.euApprovalNumber ?? '',
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";

    const treatments = [
        { value: "", key: "" },
        { value: "Matured", key: "Matured" },
        { value: "Unskinned", key: "Unskinned" },
    ]

    //Validation
    const validationSchema = Yup.object().shape({
        storageDates: Yup.array().nullable()
            .when("storageRequired", {
                is: true,
                then:
                    Yup.array().of(Yup.object().shape({
                        storageDate: Yup.string().nullable().required(validationMsgRequired)
                    })
                    ).min(1, validationMsgAtLeastOne)
            }),
        animal_2_2AA: Yup.string().nullable().required(validationMsgRequired),
        // animal_2_2AB: Yup.string().nullable().required(validationMsgRequired),
        // animal_2_2AC: Yup.string().nullable().required(validationMsgRequired),
        animal_2_1_date: Yup.string().nullable()
            .when("dateRequired", {
                is: true,
                then: Yup.string().nullable().required(validationMsgRequired),
            }),
        wildGameCarcaseQuarters: Yup.bool().test('wildGameCarcaseQuarters', validationMsgAtLeastOne, function (x) {
            return (this.parent.wildGameCarcaseQuarters || this.parent.wildGameCarcaseSide || this.parent.wildGameCarcaseWhole || this.parent.wildGameCuts || this.parent.wildGameOffal)
        }),
        wildGameCarcaseSide: Yup.bool().test('wildGameCarcaseSide', validationMsgAtLeastOne, function (x) {
            return (this.parent.wildGameCarcaseQuarters || this.parent.wildGameCarcaseSide || this.parent.wildGameCarcaseWhole || this.parent.wildGameCuts || this.parent.wildGameOffal)
        }),
        wildGameCarcaseWhole: Yup.bool().test('wildGameCarcaseWhole', validationMsgAtLeastOne, function (x) {
            return (this.parent.wildGameCarcaseQuarters || this.parent.wildGameCarcaseSide || this.parent.wildGameCarcaseWhole || this.parent.wildGameCuts || this.parent.wildGameOffal)
        }),
        wildGameCuts: Yup.bool().test('wildGameCuts', validationMsgAtLeastOne, function (x) {
            return (this.parent.wildGameCarcaseQuarters || this.parent.wildGameCarcaseSide || this.parent.wildGameCarcaseWhole || this.parent.wildGameCuts || this.parent.wildGameOffal)
        }),
        wildGameOffal: Yup.bool().test('wildGameOffal', validationMsgAtLeastOne, function (x) {
            return (this.parent.wildGameCarcaseQuarters || this.parent.wildGameCarcaseSide || this.parent.wildGameCarcaseWhole || this.parent.wildGameCuts || this.parent.wildGameOffal)
        }),
    });

    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            console.log(submitData);
            
            if (apiValues.length === 0) {
                ProductWildGameService.create(submitData)
                    .then(async response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Wild game 8367',
                            8367,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            else {
                ProductWildGameService.update(productId, values)
                    .then(async response => {
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Wild game 8367',
                            8367,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            console.log(JSON.stringify(values, null, 2));
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();

        useEffect(() => {
            setFieldValue('storageRequired', storageRequired);
            setFieldValue('dateRequired', dateRequired);
            if (values?.animal_2_1_statements == 2) {
                SetDateRequired(true);
            }
            else {
                SetDateRequired(false);
            }
            if (values?.animal_2_1_statements == 2 && values.animal_2_1_date != null) {
                WildGame8367Labels.animal_2_1_statements[1].key = "in which foot and mouth disease has not been reported since " + moment(values?.animal_2_1_date).format("DD/MM/YYYY" + ";");
            }
            else {
                WildGame8367Labels.animal_2_1_statements[1].key = "in which foot and mouth disease has not been reported since __/__/__;";
                values.animal_2_1_date = null;
            }
            if (productDetailCountry.countryName == "Canada" || productDetailCountry.countryName == "United States of America") {
                setFieldValue('health_1_7', true);
            }
            else {
                setFieldValue('health_1_7', false);
            }
        }, [values]);

        return null;
    };
    useEffect(() => {
        async function fetchListData() {
            if (!ready) {
                try {
                    const populateProductDetailData = new Promise((resolve, reject) => {
                        resolve(
                            ProductDataService.getProductDetailByProductId(productId)
                                .then(response => {
                                    var res = response.data;
                                    console.log(res);
                                    setProductDetail(res);
                                    //Get Temperature Name
                                    if (res.temperatureId != null) {
                                        TemperatureService.getById(res.temperatureId)
                                            .then(response => {
                                                var res = response.data;
                                                // setTemperature(res.temperatureName);
                                                if (res.temperatureName.includes("Frozen")) {
                                                    SetStorageRequired(true);
                                                }
                                                else {
                                                    SetStorageRequired(false);
                                                }
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            });
                                    }
                                    if (res.countryOriginId != null) {
                                        ZoneService.getById(res.countryOriginId)
                                            .then((response) => {
                                                SetCountry(response.data);
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            })
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                })
                        );
                    });

                    Promise.all([populateProductDetailData]).then((responses) => {
                        setReady(true);
                    });
                } catch (e) {
                    console.error(e);
                }
            };

        }
        fetchListData();
    }, [ready]);

    useEffect(() => {
        ProductWildGameService.getByProductId(productId)
            .then(response => {
                if (response.data == "") {
                    return;
                }
                console.log(response.data);
                SetApiValues(response.data);
                setEditing(true);
                setReady(true);
                console.log("init values:" + apiValues);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductService.getById(productId)
            .then(response => {
                SetProduct(response.data.data);
                setIsGEFS(response.data.data.isGEFS);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ZoneService
            .getAllByGroup("8367_WILDGAME")
            .then((_countryResponse) => {
                const options = _countryResponse.data.map(d => ({
                    "id": d.id,
                    "displayName": d.displayName,
                    "countryName": d.countryName,
                    "regionName": d.regionName,
                    "isoCode": d.isoCode,
                })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                const firstItem = "GB-0";
                options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                SetZones(options);
            }).catch((e) => {
                console.log(e);
            })
    }, []);

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);


    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <>
                    <SubmissionPage
                        productId={productId}
                        attestationId={attestationId}
                        isGEFS={isGEFS}
                        errors={errors}
                        title={(editing ? "Edited" : "Completed") + " Wild Game 8367 Form"}
                        message={`Wild Game information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                    />

                </>

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        {/* <PrettyPrintJson data={props.values} /> */}
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        <FormController
                                            label="Game handling EU Approval Number"
                                            control="input"
                                            type="text"
                                            name="euApprovalNumber"
                                            col={4}
                                        />
                                        <h4 className="mt-5">Health Attestation</h4>

                                        I hereby certify that the Wild Game described in this product was produced in accordance with these
                                        requirements, in particular that it derives:

                                        <ul className="list-unstyled list-clauses" >

                                            <li>
                                                <FormController
                                                    control="radio"
                                                    label=""
                                                    name="health_1_4_statements"
                                                    options={WildGame8367Labels.health_1_4_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br />
                                            <label><span className='text-danger'>Please select if meat originates from Canada or USA</span></label>
                                            <li>
                                                <FormController
                                                    control="checkbox"
                                                    label={WildGame8367Labels.health_1_7.label}
                                                    name="health_1_7"
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <h4 className="mt-5">Animal Health Attestation</h4>
                                            <li>
                                                <span className='required'>Has been obtained in the <strong>zone(s) </strong>with code(s):</span>
                                                <MultiSelectField
                                                    id='meat'
                                                    name='animal_2_1_zone'
                                                    placeholder="Select fields"
                                                    options={zones}
                                                    defaultValues={props.values.animal_2_1_zone}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.displayName}
                                                    value={props.values.animal_2_1_zone}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.animal_2_1_zone}
                                                    error={props.errors.animal_2_1_zone}
                                                    isClearable={true}
                                                    col={6}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_statements"
                                                option={WildGame8367Labels.animal_2_1_statements[0]}
                                                readOnly={readOnly}
                                            />

                                            <Row>
                                                <FormController
                                                    control="radioSingle"
                                                    label=""
                                                    name="animal_2_1_statements"
                                                    option={WildGame8367Labels.animal_2_1_statements[1]}
                                                    readOnly={readOnly}
                                                    col={2}
                                                />
                                                {(props.values.animal_2_1_statements == 2) && (
                                                    <li>
                                                        <FormController
                                                            label="in which foot and mouth disease has not been reported since"
                                                            control="dateInput"
                                                            name="animal_2_1_date"
                                                            value={props.values?.animal_2_1_date ? moment(props.values?.animal_2_1_date).format("DD/MM/YYYY") : null}
                                                            col={2}
                                                        />
                                                    </li>
                                                )}

                                            </Row>
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_statements"
                                                option={WildGame8367Labels.animal_2_1_statements[2]}
                                            />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_statements"
                                                option={WildGame8367Labels.animal_2_1_statements[3]}
                                                readOnly={readOnly}
                                            />

                                            <br></br>
                                            <p>has been obtained <strong>from animals</strong> killed:</p>



                                            <Row>
                                                <Col sm={6} lg={4} xxl={2} >
                                                    <FormController
                                                        label="on"
                                                        control="dateInput"
                                                        name="animal_2_2AA"
                                                        value={props.values?.animal_2_2AA ? moment(props.values?.animal_2_2AA).format("DD/MM/YYYY") : ""}
                                                        col={10}
                                                    />
                                                </Col>

                                                <Col sm={6} lg={4} xxl={2} >
                                                    <FormController
                                                        label="between"
                                                        control="dateInput"
                                                        name="animal_2_2AB"
                                                        value={props.values?.animal_2_2AB ? moment(props.values?.animal_2_2AB).format("DD/MM/YYYY") : ""}
                                                        col={10}
                                                    />
                                                </Col>

                                                <Col sm={6} lg={4} xxl={2}>
                                                    <FormController
                                                        label="and"
                                                        control="dateInput"
                                                        name="animal_2_2AC"
                                                        value={props.values?.animal_2_2AC ? moment(props.values?.animal_2_2AC).format("DD/MM/YYYY") : ""}
                                                        col={10}
                                                    />
                                                </Col>
                                            </Row>


                                            <li>
                                                <FormController
                                                    control="radio"
                                                    label="has been strictly segregated from fresh meat not complying with the animal health requirements for the entry into the Union of fresh meat of wild
                                                    animals of the family Bovidae (other than bovine, ovine and caprine animals), wild camelid animals and wild cervid animals throughout the operations
                                                    of cutting and until:"
                                                    name="animal_2_4_statements"
                                                    options={WildGame8367Labels.animal_2_4_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br></br>
                                            <label><span className='text-danger'>Select this option if game meat does not originate from UK</span></label>
                                            <li>
                                                <FormController
                                                    control="checkbox"
                                                    label={WildGame8367Labels.animal_2_5.label}
                                                    name="animal_2_5"
                                                    readOnly={readOnly}
                                                />
                                            </li>


                                            {props.values.animal_2_5 && (
                                                <li>
                                                    <FormController
                                                        control="checkbox"
                                                        label={WildGame8367Labels.animal_2_5_A.label}
                                                        name="animal_2_5_A"
                                                        readOnly={readOnly}
                                                    />


                                                    <FormController
                                                        control="checkbox"
                                                        label={WildGame8367Labels.animal_2_5_B.label}
                                                        name="animal_2_5_B"
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                            )}
                                            <br></br>
                                            <label>Species<span className='text-danger'>  (Please include the scientific name)</span></label>
                                            <li>
                                                <FormController
                                                    control="input"
                                                    type="text"
                                                    name="species"
                                                    col={4}
                                                />
                                            </li>
                                            <label className="mt-3 mb-3 required">Nature of Commodity</label>
                                            {props.errors?.wildGameCarcasewhole && (props.touched?.wildGameCarcasewhole
                                                || props.touched?.wildGameCarcaseside
                                                || props.touched?.wildGameCarcasequarters
                                                || props.touched?.wildGameCuts
                                                || props.touched?.wildGameOffal) &&
                                                (<span className="invalid-feedback d-block mb-3">{props.errors.wildGameCarcasewhole}</span>)
                                            }


                                            <FormController
                                                control="checkbox"
                                                label="Carcase-whole"
                                                name="wildGameCarcaseWhole"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Carcase-side"
                                                name="wildGameCarcaseSide"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Carcase-quarters"
                                                name="wildGameCarcaseQuarters"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Cuts"
                                                name="wildGameCuts"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Offal"
                                                name="wildGameOffal"
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="select"
                                                label="Treatment Type"
                                                name="treatmentType"
                                                options={treatments}
                                                col={3}
                                            />

                                            <label className={storageRequired ? "required pt-3" : " pt-3"}>Date frozen <span className='text-gray-600 text-italic'>As per Notes Box reference 1.27 of EHC 8367</span></label>

                                            <FieldArray
                                                name="storageDates"
                                                render={({ insert, remove, push }) => (
                                                    <div>
                                                        {props.values?.storageDates?.length > 0 &&
                                                            props.values?.storageDates?.map((x, index) => (
                                                                <Row key={1 + index}>
                                                                    <Col md={3} lg={2}>
                                                                        <FormController
                                                                            label={`Freeze Date: ${1 + index}`}
                                                                            isrequired={storageRequired ? "true" : "false"}
                                                                            control="dateInput"
                                                                            name={`storageDates[${index}].storageDate`}
                                                                            value={props.values?.storageDates?.[index].storageDate ? moment(props.values?.storageDates?.[index].storageDate).format("DD/MM/YYYY") : ""}
                                                                        />
                                                                    </Col>
                                                                    <Col md={3} lg={2} className="mt-5">
                                                                        {(index > 0 &&
                                                                            <Button variant="outline-danger" disabled={readOnly} onClick={() => remove(index)}>
                                                                                <FontAwesomeIcon icon={faTimes} className="mx-2" />
                                                                            </Button>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        <Button variant="light" className="mt-3 mb-2" onClick={() => push({ storageDate: "" })}>
                                                            Add a Freeze Date
                                                        </Button>
                                                    </div>
                                                )}
                                            />
                                        </ul>
                                        {/* <PrettyPrintJson data={props.values} />   */}
                                        {/* <PrettyPrintJson data={props.errors} />   */}
                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default WildGame8367AddPage;