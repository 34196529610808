import React, { useState } from 'react';
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Routes } from "../../../routes";
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import PermissionsCheck from "../../../auth/PermissionCheck";
import { scopes } from "../../../auth/PermissionsMap";
import useReportGenerator from "./Hooks/useReportGenerator"; // Custom hook

const ReportsDashboard = () => {
    const [submittingState, setSubmittingState] = useState({ "GEFS": false, "Non-GEFS": false });
    const { generateReport } = useReportGenerator(setSubmittingState);

    const breadcrumbData = {
        item1: { name: 'Reports Dashboard', link: Routes.Dashboard.path }
    };

    return (
        <div>
            <BreadcrumbNav data={breadcrumbData}/>
            <h2>Reports Dashboard</h2>
            <Row>
                {/* Report Cards */}
                {renderReportCard("GEFS", submittingState["GEFS"])}
                {renderReportCard("Non-GEFS", submittingState["Non-GEFS"])}
            </Row>
        </div>
    );

    function renderReportCard(type, isSubmitting) {
        return (
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.reports[`retailer${type.replace(/-/g, '').toLowerCase()}`]]}>
                <Col xs={12} lg={4} className="mb-4">
                    <Card border="light" className="shadow-sm">
                        <Card.Header className="d-flex justify-content-center">
                            <div className={`icon-shape icon-sm text-white bg-${type === "GEFS" ? "primary" : "warning"} rounded me-3`}>
                                <FontAwesomeIcon icon={faClipboardList} />
                            </div>
                            <h5 className="my-2">Retailer {type} Report</h5>
                        </Card.Header>
                        <Card.Body className="text-center">
                            <Row>
                                <Col md={12} className="mx-auto mb-4 p-4">
                                    <p>Generate a report containing data of all active {type} products and related manufacturer data.</p>
                                    <p><small>Only contains data updated over the last 6 months.</small></p>
                                    <Button
                                        className="mt-3"
                                        variant="light"
                                        size="md"
                                        disabled={isSubmitting}
                                        onClick={() => generateReport(type)}
                                    >
                                        {isSubmitting ? "Please wait..." : "Generate report"}
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </PermissionsCheck>
        );
    }
};

export default ReportsDashboard;
