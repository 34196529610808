import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown } from '@themesberg/react-bootstrap';
import React, { useState } from 'react';

export default (props) => {
  const { handleChange, orderBySelected, options } = props;
  
  const selectOrder = (x) =>{
    handleChange(x);
  }
  const FilterSelectionButton = React.forwardRef(({ children, style, onClick }, ref) => (
    <Button variant="light" size="sm" className={`ps-3 orderByPill btn-block btn-block-md-down fw-normal rounded-pill bg-lightblue`} ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
      {children}
    </Button>
  ));

  const FilterSelectionsMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div ref={ref} style={{ ...style, maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }} className={className} aria-labelledby={labeledBy}>
        <ul className="list-unstyled"> 
          {React.Children.toArray(children).filter((child, index) => !value || options[index].toLowerCase().includes(value))} {/* Use data[index].label */}
        </ul>
      </div>
    );
  });

  return (
    <Dropdown className={`d-inline mx-2 `} autoClose="outside">
      <Dropdown.Toggle as={FilterSelectionButton} id="dropdown-autoclose-outside font-size-xs">
      <span className="fs-normal">{orderBySelected}</span>
        <span className="small"><FontAwesomeIcon icon={faChevronDown} className="ms-3" /></span>
      </Dropdown.Toggle>

      <Dropdown.Menu as={FilterSelectionsMenu}>
        {options?.map((x, i) => (
          <Dropdown.Item  
            className='py-1' 
            key={i} 
            eventKey={i} 
            onSelect={()=>selectOrder(x)}
          >
          <span className="small">{x}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
