import *  as baseService  from "./BaseService";

const generateRetailerGEFSReport = () => {
    return baseService.getApiData("/reports/generateRetailerGEFSReport");
};
const generateRetailerNonGEFSReport = () => {
    return baseService.getApiData("/reports/generateRetailerNonGEFSReport");
};
const ReportService = {
    generateRetailerGEFSReport,
    generateRetailerNonGEFSReport
};

export default ReportService;