import React, { useState } from 'react';
import { Button} from "@themesberg/react-bootstrap";
import { Routes } from "../../../routes";
import FormSubmission from "../common/FormSubmission";
import { is } from 'date-fns/locale';

export const BackButton = (props) => {
    const { isGEFS = false, attestationId = null, productId, isStorageDeclaration = false} = props;
    return(
        isGEFS || isStorageDeclaration?(
            <Button variant="light" className="mx-3" href={Routes.Product.path.replace(':id',productId)}>Return to Product</Button>
        ):
        (
            <Button variant="light" className="mx-3" href={Routes.ResponseAttestationNonGEFSPage.path.replace(':id',attestationId)}>Return to Support Attestation</Button>
            
        )
    )
};

export const SubmissionPage = (props) => {
    const { isGEFS = false, isStorageDeclaration = false, isAttestation = false, title, message, errors, productId, attestationId = null,isPoultry} = props
    let successButtonUrl = Routes.Product.path.replace(":id",productId);
    let successButtonName = "Back to product";
    let isPoultry1 = false;
    isPoultry1 = isPoultry;
   if(attestationId == null)
   {
    isPoultry1 = false;
   }
    if((!isGEFS && !isStorageDeclaration) || isAttestation == true || isPoultry1 == true){
        successButtonUrl = Routes.ResponseAttestationNonGEFSPage.path.replace(":id",attestationId);
        successButtonName = "Back to Support Attestation";
    }
    return(
        <>
            <FormSubmission
                errors={errors}
                title ={title}
                message = {message}
                successButtonUrl = {successButtonUrl}
                successButtonName = {successButtonName}
            />
        </>
    )
};
