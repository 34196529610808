import *  as baseService  from "./BaseService";

const getAll = (id) => {
    return baseService.getApiData("/productimage/getByProductId?id=" + id);
};
const create = (data) => {
    return baseService.postApiData("/productimage/create", data);
};
const remove = (id) => {
    return baseService.deleteApiData("/image/delete?id=" + id);
};
const ProductImageService = {
    getAll,
    create,
    remove
};

export default ProductImageService;