export const animal_2_3_A_statements = [
    {
        group: "",
        fieldname: "animal_2_3_A_statements",
        value: 1,
        key: "within a 10 km radius of which, including where appropriate, the territory of a neighbouring country there was no outbreak of highly pathogenic avian influenza for a period of at least 30 days prior to the date of collection of the eggs;",

    },

    {
        group: "",
        fieldname: "animal_2_3_A_statements",
        value: 2,
        key: "the egg products have undergone the following treatment:",

        subclauseName: "animal_2_3_A",
        subclauses: [
            {
                group: "",
                fieldname: "animal_2_3_A",
                value: 1,
                key: "liquid egg white was treated:",
                subclauseName: "animal_2_3_A_1",
                subclauses: [
                    {
                        group: "",
                        fieldname: "animal_2_3_A_1",
                        value: 1,
                        key: "with 55,6°C for 870 seconds;"
                    },
                    {
                        group: "",
                        fieldname: "animal_2_3_A_1",
                        value: 2,
                        key: "with 56,7°C for 232 seconds;"
                    },
                ]
            },
            {
                group: "",
                fieldname: "animal_2_3_A",
                value: 2,
                key: "10% salted yolk was treated with 62,2°C for 138 seconds",
            },
            {
                group: "",
                fieldname: "animal_2_3_A",
                value: 3,
                key: "dried egg white was treated:",
                subclauseName: "animal_2_3_A_3",
                subclauses: [
                    {
                        group: "",
                        fieldname: "animal_2_3_A_3",
                        value: 1,
                        key: "with 67°C for 20 hours;"
                    },
                    {
                        group: "",
                        fieldname: "animal_2_3_A_3",
                        value: 2,
                        key: "with 54,4°C for 50,4 hours;"
                    },
                ]
            },

            {
                group: "",
                fieldname: "animal_2_3_A",
                value: 4,
                key: "whole eggs were:",
                subclauseName: "animal_2_3_A_4",
                subclauses: [
                    {
                        group: "",
                        fieldname: "animal_2_3_A_4",
                        value: 1,
                        key: "treated with 60°C for 188 seconds;"
                    },
                    {
                        group: "",
                        fieldname: "animal_2_3_A_4",
                        value: 2,
                        key: "completely cooked;"
                    },
                ]
            },
            {
                group: "",
                fieldname: "animal_2_3_A",
                value: 5,
                key: "whole egg blends were:",
                subclauseName: "animal_2_3_A_5",
                subclauses: [
                    {
                        group: "",
                        fieldname: "animal_2_3_A_5",
                        value: 7,
                        key: "treated with 60°C for 188 seconds;"
                    },
                    {
                        group: "",
                        fieldname: "animal_2_3_A_5",
                        value: 8,
                        key: "treated with 61,1°C for 94 seconds;"
                    },
                    {
                        group: "",
                        fieldname: "animal_2_3_A_5",
                        value: 9,
                        key: "completely cooked;"
                    },
                ]
            },
        ],

    }
];
export const animal_2_3_B_statements = [
    {
        group: "",
        fieldname: "animal_2_3_B_statements",
        value: 1,
        key: "within a 10 km radius of which, including where appropriate, the territory of a neighbouring country there was no outbreak of infection with Newcastle disease virus within a period of at least 30 days prior to the date of collection of the eggs;",
    },
    {
        group: "",
        fieldname: "animal_2_3_B_statements",
        value: 2,
        key: "the egg products have undergone the following treatment:",

        subclauseName: "animal_2_3_B",
        subclauses: [
            {
                group: "",
                fieldname: "animal_2_3_B",
                value: 1,
                key: "liquid egg white was treated:",
                subclauseName: "animal_2_3_B_1",
                subclauses: [
                    {
                        group: "",
                        fieldname: "animal_2_3_B_1",
                        value: 1,
                        key: "with 55°C for 2 278 seconds;"
                    },
                    {
                        group: "",
                        fieldname: "animal_2_3_B_1",
                        value: 2,
                        key: "with 56,7°C for 232 seconds;"
                    },
                    {
                        group: "",
                        fieldname: "animal_2_3_B_1",
                        value: 3,
                        key: "with 59°C for 301 seconds;"
                    },
                ]
            },
            {
                group: "",
                fieldname: "animal_2_3_B",
                value: 2,
                key: "10% salted yolk was treated with 55°C for 176 seconds;",
            },
            {
                group: "",
                fieldname: "animal_2_3_B",
                value: 3,
                key: "dried egg white was treated with 57°C for 50,4 hours;",
            },
            {
                group: "",
                fieldname: "animal_2_3_B",
                value: 4,
                key: "whole eggs were:",
                subclauseName: "animal_2_3_B_4",
                subclauses: [
                    {
                        group: "",
                        fieldname: "animal_2_3_B_4",
                        value: 4,
                        key: "treated with 55°C for 2 521 seconds;"
                    },
                    {
                        group: "",
                        fieldname: "animal_2_3_B_4",
                        value: 5,
                        key: "treated with 57°C for 1 596 seconds;"
                    },
                    {
                        group: "",
                        fieldname: "animal_2_3_B_4",
                        value: 6,
                        key: "treated with 59°C for 674 seconds;"
                    },
                    {
                        group: "",
                        fieldname: "animal_2_3_B_4",
                        value: 7,
                        key: "completely cooked;"
                    },
                ]
            },
        ],
    },
]





