
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';

import ReactLogo from "../assets/img/loading.svg";

export default (props) => {

  const { show, component=false } = props;

  return (
    component?(
      <div className={`preloader component flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
        <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} />
      </div>
    )
    :
    (
      <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
        <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} />
      </div>
    )
  );
};
