import React, { useState, useEffect, useRef } from 'react';
import { Routes } from "../../../../../routes";
import BreadcrumbNav from "../../../../../components/BreadcrumbNav";
import {getUserInfo} from "../../../../../auth/authorisation";
import { Formik, Form } from 'formik';
import { Button, Alert, Col, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import AttestationService from "../../../../services/AttestationService"
import RequestAttestationNonGEFSForm from './Forms/RequestAttestationNonGEFSForm';
import validationSchema from './Forms/Models/NonGEFSValidation';
import formModel from './Forms/Models/NonGEFSModel';
import formInitialValues from './Forms/Models/NonGEFSValues';
import VetsService from "../../../../services/VetService"
import SiteDataService from "../../../../services/SiteService"
import ProductDataService from "../../../../services/ProductService"
import OrganisationService from '../../../../services/OrganisationService';
import ManufacturerDataService from "../../../../services/ManufacturerService";
import PlatformDataService from "../../../../services/PlatformService";
import FormSubmission from "../../../common/FormSubmission";
import CertificateNumberService from '../../../../services/CertificateNumberService';
import { GetCertificateNumbers, GetCertificateFormData } from '../../../CertificateForms/CertificateFormFunctions';
import ActivityService from '../../../../services/ActivityService';

const steps = ['Attestation Details'];
const { formId, formField } = formModel;

function _renderStepContent(step, setFieldValue, setFieldTouched, touched, errors, productId, user, values, childRef, submitHandler, isSubmitting,certificateNumber,attestationData, vets) {
  switch (step) {
    case 0:
      return <RequestAttestationNonGEFSForm 
      formField={formField}
      setFieldValue={setFieldValue} 
      setFieldTouched={setFieldTouched} 
      touched={touched} errors={errors} 
      productId={productId} 
      user={user}
      values={values}
      vets={vets}
      attestationData={attestationData}
      certificateNumber={certificateNumber}
      />;
    default:
      return <div>Not Found</div>;
  }
}


const RequestAttestationNonGEFSPage = (props) => {
    
  const [errors, setErrors] = useState(null);
  const childRef = useRef(null);
  const [productId] = useState(props.match.params.id);
  const productUrl = Routes.Product.path.replace(":id","") + productId;
  const [activeStep, setActiveStep] = useState(0); 
  const [successButtonInfo, setSuccessButtonInfo] = useState('');
  const currentValidationSchema = validationSchema[activeStep]; 
  const isLastStep = activeStep === steps.length - 1;
  const [product, setProduct] = useState({});
  const [attestationData, setAttestationData] = useState({});
  const [manufacturer, setManufacturer] = useState({});
  const [manufacturerId, setManufacturerId] = useState(null);
  const [platform, setPlatform] = useState([]);;
  const [vets, setVets] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [site, setSite] = useState({});
  const [productDetail, setProductDetail] = useState({});
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [certificateNumber, setCertificateNumber] = useState('');
  const [certificateNumberList, setCertificateNumberList] = useState([]);
  const [user, setUser] = useState('');
  // const [isVersionOutdated, setVersionOutdated] = useState(false); 
  // const [versionErrorMessage, setVersionErrorMessage] = useState([]);

  useEffect(()=>{ 
    getUserInfo()
    .then(x => {
      setUser(x);
    });
    
    setSuccessButtonInfo({url:(productUrl), name:"Back to product"});

    ProductDataService.getWithDetailsById(productId)
    .then(response => {
        setProduct(response.data);
        formInitialValues.isGEFS = false;

        setProductDetail(response.data.productDetails);

        var manufacturerId = response.data.manufacturerId;
        var siteId = response.data.productDetails.siteId;
        var platformId = response.data.productDetails.platformId;
        var certNumber = response.data.productDetails.certificateNumber;
        let certList = response.data.productDetails.certificateNumberList;
        setCertificateNumberList(certList);
        setManufacturerId(manufacturerId);
        setCertificateNumber(certNumber);

        CertificateNumberService.getAll()
        .then((response) => {

            let selectedCertificateNumbers = response.data.filter(x => certList.includes(x.number.toString()));
            // let selectedCertificateVersions = GetCertificateVersions();
            // selectedCertificateVersions.filter(x => certList.includes(x.number.toString()))

            fetchCertFormData(selectedCertificateNumbers)
              .then(certFormData=> {  
                //Set attestation data for each Form
                setAttestationData(certFormData);

                ////Find selected form versions from certificate config
               // var outDatedList =  [];
                // selectedCertificateVersions.forEach(x => { 
                //     var certVersionNumber = certFormData[x.number]?.version;
                //     if(certVersionNumber != undefined){
                //       //Check if version is outdated
                //       if(certVersionNumber < x.version){
                //         setVersionOutdated(true);
                //         outDatedList.push(x.number)
                //       }
                //     }
                // });
               // setVersionErrorMessage(outDatedList)

              })
              .catch((e) => {
                  console.log(e);
              })
            
        })
        .catch((e) => {console.log(e);})
          

        ManufacturerDataService.getById(manufacturerId)
        .then((response) => {
            setManufacturer(response.data);
        })
        .catch((e) => {console.log(e);});

        SiteDataService.getWithEstablishmentNumberType(siteId)
        .then((response) => {
            setSite(response.data);
        })
        .catch((e) => {console.log(e);});

        PlatformDataService.getWithEstablishmentNumberType(platformId)
            .then((response) => {
                setPlatform(response.data);
            })
            .catch((e) => {console.log(e);});
    })
    .catch(e => {console.log(e);});
    
      VetsService.getAll()
      .then((response) => {
          setVets(response.data);
      })
      .catch((e) => {console.log(e);});
      
      OrganisationService.getFirst()
      .then((response) => {
          setOrganisation(response.data);
      })
      .catch((e) => {console.log(e);});


  },[]);
  
  //Function - Get Gertificate form data from product id and the certificate numbers required on this product
  function fetchCertFormData(selectedCertificateNumbers) {
    return GetCertificateFormData(productId, selectedCertificateNumbers, false)
    .then(x=> {
      return x
    });
  }

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    
    let submitData = values;
    
    console.info(JSON.stringify(values));
    submitData.isGEFS = false;
    submitData.referenceNumber = values.customUniqueRef;
    submitData.productName = product.productName;
    submitData.productId = productId;
    submitData.manufacturerId = manufacturerId;
    submitData.certificateNumberList = certificateNumberList;
    submitData.vetId = values.vetId.id;

    AttestationService.create(submitData)
    .then(async (response) => {
      //Create Activity Log
      await ActivityService.log(
        'Requested an attestation', 
        'A Non-GEFS attestation has been requested for ' + product.productName ,
        'Attestation', 
        'Request',
        values.signedRequestUrl,
        response.data.id
      );

    })
    .catch((e) => {
      setErrors(e.response.data);
      console.log(e);}
    );
    
    actions.setSubmitting(false);

    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    setSubmitAttempted(false);
    if (isLastStep) 
    {
      _submitForm(values, actions);
    } 
    else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    } 
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const title = "Attestation Request - Non GEFS";
  const breadcrumbData = {
      item1 : {name:'Products', link: Routes.Products.path},
      item2 : {name: product.productName, link: Routes.Product.path.replace(":id",productId)},
      item3 : {name:'Attestation Request', link: '#'},
  };

  return (
      <div className="row">
          <BreadcrumbNav data={breadcrumbData}/>
          <h2>{title}</h2>
                  
          {activeStep === steps.length ? (
            <FormSubmission
                errors={errors}
                title ={"Attestation Requested"}
                message = {"The attestation has been sent to the vet.<br />The vets reply and declaration will be available on the product page once the vet has responded.</p>"}
                successButtonUrl = {productUrl}
                successButtonName = {"Back to product"}
            />
          ) 
          : 
          (
            <Formik
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}
              validateOnChange={true}
              validateOnBlur={false}
            >
              {({ isSubmitting, setFieldValue,  setFieldTouched, touched, errors, values}) => (
                <Form id={formId}>

                  {_renderStepContent(
                    activeStep, 
                    setFieldValue, 
                    setFieldTouched, 
                    touched, 
                    errors, 
                    productId,
                    user, 
                    values, 
                    childRef, 
                    _handleSubmit, 
                    isSubmitting,
                    certificateNumber,
                    attestationData,
                    vets
                  )}
{/*                   
                  <AttestationAlerts 
                    isVersionOutdated={isVersionOutdated} 
                    versionErrorMessage={versionErrorMessage} 
                    size={11}
                    productId={productId}
                    isGEFS={true}
                    attestationId={null}
                  /> */}

                  {(Object.keys(errors).length > 0 && submitAttempted) &&
                      <Alert
                        className="mt-2"
                        variant="danger"
                        size="sm"
                        >
                        <div className="d-flex justify-content-start">
                          <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                          Some errors were found, check the form above for any missing or incomplete information.
                      </div>
                      </Alert>
                  } 

                  <div className={"d-inline"}>
                    {activeStep !== 0 && (
                      <Button onClick={_handleBack} variant="light" className="mx-3">
                        Back
                      </Button>
                    )}
                    <div className="d-inline">
                      <Button
                        onClick={() => { setSubmitAttempted(true) }}
                        disabled={isSubmitting}
                        type="submit"
                        variant="primary"
                        className={"btn-md"}
                        >                      
                        {activeStep== 0 ? 'Send to vet' :'' }
                  
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
      </div>
    );
};

export default RequestAttestationNonGEFSPage;