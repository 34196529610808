import { Routes } from '../../../routes';
import { useHistory, useLocation } from "react-router-dom";
import { Card, Col, Row } from "@themesberg/react-bootstrap";
import React, { useState } from 'react';
import BreadcrumbNav from '../../../components/BreadcrumbNav';
import NoticeboardAdminTable from "../../../components/Tables/NoticeboardAdminTable";
import FilterBar from '../../filters/FilterBar';
import NoticeboardService from '../../services/NoticeboardService';
import Paging from "../common/Paging";

const NoticeboardAdmin  = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [notices, setNotices] = useState([]);
    const [count, setCount] = useState(0);
    const [activePage , setActivePage ] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(searchParams.get("size") || 25);
    const { totalPages = count, size = "md", withIcons = false } = props;
    const [ready, setReady] = useState(false);
    
    const title = "Noticeboard"
    const breadcrumbData = {
        item1 : {name:'Admin', link: Routes.NoticeboardAdmin.path},
        item2 : {name:'Noticeboard', link: '#'},
    }
    
    //Get all scrape all filtered links
    const getNotices = (params = {}) => {
        setLoaded(false);
        
        if(params=={}){
            setLoaded(true);
            return null;
        }

        NoticeboardService.getAllPaged(params)
            .then((response) => {
                const { data, totalPages, totalRecords } = response.data;
                setTotalItems(totalRecords);
                setNotices(data);
                setCount(data.length);
                setLoaded(true);
                setReady(true);
            })
            .catch((e) => {
                setLoaded(true);
                console.log(e);
            });
    };

    //Submit
    const handleFilterSubmit= (params) => {
        setSubmitting(true);
        getNotices(params)
        setSubmitting(false);
    }

    const handleFilterChange = (values) => {
        setSubmitting(true);
        setSubmitting(false);
    }


    //Start - Paging Functions
    const onPrevItem = () => {
        const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
        setActivePage(prevActiveItem);
    };

    const onPageNumberClick = (number) => {
        setActivePage(number);
    };

    const onNextItem = (totalPages) => {
        const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
        setActivePage(nextActiveItem);
    };

    const handlePageSizeChange = (value) => {
        setPageSize(value.value);
    };
    //End - Paging Functions


    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            <div className="">
                <Row className='row d-flex align-items-center'>
                    <Col xs={12} sm={6} lg={6} className="text-start">
                        <h2 id="tabelLabel" >{title}</h2>
                    </Col>
                    {/* <Col xs={12} sm={6} lg={6} className="text-end">
                        <Button disabled={!loaded} 
                                variant="warning" 
                                size="sm" 
                                onClick={() => handleScrapeAllLinks()}
                                >
                            Scrape All Links
                        </Button>
                    </Col> */}
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Header>
                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <FilterBar
                                            loaded={loaded}
                                            setLoaded={setLoaded}
                                            ready={ready} 
                                            hasSearch={false}
                                            defaultFilterValues={null}
                                            activePage={activePage}
                                            pageSize={pageSize}
                                            orderByOptions={[]}
                                            setActivePage={setActivePage}
                                            handleChange={handleFilterChange}
                                            handleSubmit={handleFilterSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <NoticeboardAdminTable loaded={loaded} noticeboard={notices}/>
                                <Paging
                                    activePage={activePage}
                                    size={size}
                                    pageSize={pageSize}
                                    totalPages={totalPages}
                                    totalItems={totalItems}
                                    onPrevItem={onPrevItem}
                                    onNextItem={onNextItem}
                                    handleChange={handlePageSizeChange}
                                    onPageNumberClick={onPageNumberClick}
                                    withIcons={withIcons}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default NoticeboardAdmin;