import React, { useState, useEffect } from 'react';
import ConsignmentDataService from "../../services/ConsignmentService";
import { Routes } from "../../../routes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFilter} from '@fortawesome/free-solid-svg-icons';
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Col,Row, Button, Card } from '@themesberg/react-bootstrap';
import { Formik, Form} from 'formik';
import FormController from "../common/FormController"
import { ConsignmentsTable } from "../../../components/Tables/ConsignmentTable";

import Paging from "../common/Paging"

const ConsignmentListingPage = (props) => {
    const [consignments, setConsignments] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [count, setCount] = useState(0);
    const [searchTitle, setSearchTitle] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const { totalPages = count, size = "md", withIcons = false, disablePrev = false } = props;

    const breadcrumbData = {
        item1 : {name:'Consignments', link: Routes.ConsignmentListingPage.path},
        item2 : {name:'View all', link: 'View all'},
    };
    const pageSizes = [
        { value: '10', label: 10 },
        { value: '25', label: 25 },
        { value: '100', label: 100 }
    ]
    const initialValues = {
        search: ''
    }

    //Start - Paging Functions
    const onPrevItem = () => {
        const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
        setActivePage(prevActiveItem);
    };

    const onPageNumberClick = (number) => {
        setActivePage(number);
    };
    const onNextItem = (totalPages) => {
        const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
        setActivePage(nextActiveItem);
    };
    //End - Paging Functions

    const getFilterParams = ( searchTitle,activePage, pageSize) => {
        let params = {};
        if (searchTitle) {
            params["title"] = searchTitle;
        }
        if (activePage) {
            params["page"] = activePage;
        }
        if (pageSize) {
            params["size"] = pageSize;
        }
        return params;
    };

    const retrieveConsignments = (values) => {
        let params =  '';
        if(values != null){
            params = getFilterParams(searchTitle, activePage, pageSize);
        }
        else{
            params = getFilterParams(searchTitle, activePage, pageSize);
        }
        getConsignments(params);
    };

    const getConsignments = (params) => {
        setLoaded(false);
        ConsignmentDataService.getAllPaged(params)
            .then((response) => {
                const { data, totalPages, totalRecords} = response.data;
                setTotalItems(totalRecords);
                setConsignments(data);
                setCount(totalPages);
                setLoaded(true);
            })
            .catch((e) => {
                setLoaded(true);
                console.log(e);
            });
    };

    const handlePageSizeChange = (value) => {
        setPageSize(value.value);
        setActivePage(1);
    };
    
    function onSubmit(values, { setSubmitting, resetForm } ) {
        setTimeout(() => {
            setActivePage(1);
            retrieveConsignments(values);
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    }
    
    useEffect(() => {
        retrieveConsignments();
    },[activePage, pageSize]);

    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            <h2 id="tabelLabel" >Consignments</h2>

            <div className="col-md-12 list">
                <Row className="mt-3">
                    <Col>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                                <Row>
                                    <Col xs={12} sm={6} lg={10}>
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={onSubmit}
                                            >
                                            {(props, isSubmitting) => (

                                                <Form>     
                                                    <Row className="mb-3">
                                                        <Col xs={12} sm={8} lg={8}>
                                                            <FormController
                                                                control="input"
                                                                type="text"
                                                                onChange={(e) => {
                                                                    props.setFieldValue('search',e.target.value);
                                                                    setSearchTitle(e.target.value);
                                                                }}
                                                                placeholder="Search by consignment name / transport identification number"
                                                                name="search"
                                                            />
                                                        </Col>
                                                       
                                                        <Col xs={12} sm={4} lg={4} >
                                                            <div className="form-group">
                                                                <Button variant="light" type="submit" disabled={isSubmitting}>
                                                                <FontAwesomeIcon icon={faFilter} />{isSubmitting ? "Please wait..." : "Filter"}</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik >
                                    </Col>
                                    <Col xs={12} sm={12} lg={2} className="text-end">
                                        <Button variant="secondary" href={Routes.ConsignmentAddPage.path} className="text-dark me-2">
                                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                                            <span>Add</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <ConsignmentsTable loaded={loaded} consignments={consignments} />
                                <Paging
                                    activePage={activePage}
                                    col={size}
                                    pageSize={pageSize}
                                    totalPages={totalPages}
                                    totalItems={totalItems}
                                    handleChange={handlePageSizeChange}
                                    onPrevItem={onPrevItem}
                                    onNextItem={onNextItem}
                                    onPageNumberClick={onPageNumberClick}
                                    withIcons={withIcons}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ConsignmentListingPage;

