import React from 'react';
export const AttestationContent = (props) => {
    const { attestationData } = props;
    return (
        <>
            <h5>II. Health Information</h5>
            <>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned, declare that I am aware of the relevant requirements of Regulation (EC) No 178/2002 of
                    the European Parliament and of the Council, Regulation (EC) No 852/2004 of the European Parliament and
                    of the Council, Regulation (EC) No 853/2004 of the European Parliament and of the Council, Regulation
                    (EU) 2017/625 of the European Parliament and of the Council, and Council Directive 2001/110/EC, and
                    hereby certify that {attestationData.productType} described in Part I were produced in accordance with
                    these requirements, and in particular that they:
                </p>
                <ol className="attestation-II-1">
                    <li>come from (an) establishment(s) that has(ve) been registered and implement(s) a program based on the hazard analysis and critical control points (HACCP)
                        principles in accordance with Article 5 of Regulation (EC) No 852/2004 and regularly audited by the competent authority;</li>
                    <li>have been handled and, where appropriate, prepared, packaged and stored in a hygienic manner in accordance with the requirements of Annex II to Regulation (EC)
                        No 852/2004;</li>
                    {attestationData.productType == "Apiculture products" ? (
                        <li>fulfil the guarantees covering live animals and products thereof provided by the control plan submitted in accordance with Article 6(2) of Commission Delegated Regulation (EU)
                            2022/2292 and the third country or region.</li>
                    ) :
                        (
                            <li>thereof of its/their origin is listed in Annex -I to Commission Implementing Regulation (EU) 2021/405 with an entry 'X' for honey;</li>
                        )}
                    {attestationData.productType == "Honey" ? (
                        <li>conform to the product description and composition criteria as denmed in Annexes I and II to Council Directive 2001/110/EC and, in particular, does not contain any added food ingredient,
                            including food additives or extraneous sugars.</li>
                    ) :
                        (
                            <li>N/A</li>
                        )}
                </ol>
            </>
        </>
    )
}

