import formModel from './DailyAttestationModel';
const {
  formField: {
    order,
    requireManufacturer,
    manufacturerName,
    manufacturer,
    manufacturerId,
    trackingRef,
    birdFluCheck,
    commercialNumber,
    uniqueCode,
    productsSelected,
    dailyAttestationData,
    signedRequestUrl,
    deliveryDate,
    documentId,
  }
} = formModel;

export default {
  [order.name]: 0,
  [requireManufacturer.name]: false,
  [manufacturerName.name]: null,
  [manufacturer.name]: null,
  [manufacturerId.name]: null,
  [trackingRef.name]: '',
  [birdFluCheck.name]: false,
  [uniqueCode.name]: '',
  [commercialNumber.name]: '',
  [productsSelected.name]: [],
  [dailyAttestationData.name]: [],
  [deliveryDate.name]: '',
  [signedRequestUrl.name]: '',
  [documentId.name]: '',
};

