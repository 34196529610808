export const health_1 = { 
    label: "are amino acids:"
}
export const health_1_statements = [
   { group: "HighlyRefined1Choices", fieldname: "health_1_statements", value: "1", key: "for production of which human hair was not used as a source;" },
   { group: "HighlyRefined1Choices", fieldname: "health_1_statements",  value: "2", key: "complying with Regulation (EC) No 1333/2008 of the European Parliament and of the Council;" }
]
export const health_2 = { 
    label: "are fat derivatives, submitted to:"
}
export const health_2_statements = [
   { group: "HighlyRefined2Choices", fieldname: "health_2_statements", value: "1", key: "transesterification or hydrolysis at a temperature of at least 200°C, under corresponding appropriate pressure, for at least 20 minutes;" },
   { group: "HighlyRefined2Choices", fieldname: "health_2_statements",  value: "2", key: "saponification with NaOH 12M, in a batch process at 95°C for three hours or in a continuous process at 140°C 2 bars (2 000 hPa) for 8 minutes;" },
   { group: "HighlyRefined2Choices", fieldname: "health_2_statements",  value: "3", key: "hydrogenation at 160°C at 12 bars (12 000 hPa) for 20 minutes;" }
]
export const health_3 = { 
    label: "are food flavourings authorised in accordance with Regulation (EC) No 1334/2008 of the European Parliament and of the Council."
}