import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const DocumentReader = ({ documentPath, updating, pageNumber, onLoadSuccess, scale }) => {
  const [documentKey, setDocumentKey] = useState(0);
  useEffect(() => {
    // Update the key whenever the documentPath changes
    setDocumentKey(prevKey => prevKey + 1);
  }, [documentPath]);

  

  return (
    <>
        <Document
            key={documentKey} // Add the key prop to the Document component
            id={"react-pdf" + documentKey}
            className={updating ? "d-none documentpreview" : "documentpreview"}
            renderMode="canvas"
            file={documentPath}
            onLoadSuccess={onLoadSuccess}
        >
          <Page scale={scale} pageNumber={pageNumber} />
        </Document>
    </>
  );
};

export default DocumentReader;