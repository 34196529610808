export const health_1_4_statements = [
    { group: "wildGameHealth_1_4", fieldname: "health_1_4_statements", value: "1", key: "the carcase or the parts of the carcase have been marked with a health mark in accordance with Article 48 and Annex II of Implementing Regulation (EU)2019/627;"},
    { group: "wildGameHealth_1_4", fieldname: "health_1_4_statements",  value: "2", key: "the packages of meat have been marked with an identification mark in accordance with Section 1 of Annex II to Regulation (EC) No 853/2004; "},
]
export const health_1_7 = { 
    label: "The product contains or is derived exclusively from meat, excluding offal and spinal cord, of wild cervid animals which have been exacmined for Chronic Wasting Disease by histopathology, immunohistochemisty or other diagnostic method recognised by the competent authorities with negative results and is not derived from animals coming from a region where Chronic Wasting Disease has been confirmed in the last three years or is officially suspected." 
}

export const animal_2_1_statements = [
    { group: "wildGameAnimal_2_1", fieldname: "animal_2_1_statements", value: "1", key: "in which foot and mouth disease has not been reported for a 12 month period before the date of killing of the animals from which the fresh meat was obtained and a vaccination against this disease has not been carried out;" },
    { group: "wildGameAnimal_2_1", fieldname: "animal_2_1_statements", value: "2", key: "in which foot and mouth disease has not been reported since __/__/__;" },
    { group: "wildGameAnimal_2_1", fieldname: "animal_2_1_statements",  value: "3", key: "in which foot and mouth disease has not been reported for a 12 month period before the date of killing of the animals from which the fresh meat was obtained and a vaccination programme against foot and mouth disease is being carried out in kept bovine animals under the supervision of the competent authority of the third country or territory." },
    { group: "wildGameAnimal_2_1", fieldname: "animal_2_1_statements",  value: "4", key: "in which foot and mouth disease has not been reported for a 12 month period before the date of killing of the animals from which the fresh meat was obtained and a vaccination programme against foot and mouth disease is being carried out in kept bovine animals under the supervision of the competent authority of the third country or territory; this supervision includes the control of the efficacy of the vaccination programme through regular serological surveillance that indicates adequate antibody levels in the animals and demonstrates the absence of foot and mouth disease virus circulation in the zone." },
]


export const animal_2_4_statements = [
    { group: "wildGameAnimal_2_4", fieldname: "animal_2_4_statements", value: "1", key: "it was packaged for further storage;" },
    { group: "wildGameAnimal_2_4", fieldname: "animal_2_4_statements",  value: "2", key: "its loading, as unpackaged fresh meat, to the means of transport for dispatch to the Union" },
]


export const animal_2_5 = { 
    label: "is <strong>de-boned fresh meat, other than offal</strong>, obtained from carcasses:" 
}

export const animal_2_5_A = { 
    label: "in which the main accessible lymph nodes have been removed; which have been submitted to maturation at a temperature above +2°C for at least 24 hours before the bones were removed; and in which the pH value of the meat was below 6.0 when tested electronically in the middle of the <i>longissimus-dorsi</i> muscle after maturation and before de-boning" 
}

export const animal_2_5_B = { 
    label: "in which the main accessible lymph nodes have been removed; and which have been submitted to maturation at a temperature above +2°C for at least 24 hours before the bones were removed."
}

