import formModel from './FormModel';
const {
  formField: {
    formsComplete,
    signedRequestUrl,
    documentId,
    finalConsumer,
    batches,
    numberPackages,
    netWeight,
    grossWeight,
    invoiceNumber,    
  }
} = formModel;

export default {
  [formsComplete.name]: false,
  [signedRequestUrl.name]: '',
  [documentId.name]: '',
  [finalConsumer.name]: false,
  [batches.name]: '',
  [netWeight.name]: 0.00,
  [grossWeight.name]: 0.00,
  [invoiceNumber.name]: '',
  [numberPackages.name]: 0
};

