import formModel from './GEFSModel';
const {
  formField: {
    traceability,
    vetId,
    test,
    finalConsumer,
    productionDate,
    productionDateMultipleStart,
    productionDateMultipleEnd,
    productionDateSingle,
    customUniqueRef,
    batchNumbers,
    batchNumberOptions,
    batchFormat,
    batchRangeFrom,
    batchRangeTo,
    signature,
    containsDairy,
    signedRequestUrl,
    documentId
  }
} = formModel;

export default {
  [customUniqueRef.name]: '',
  [traceability.name]: '',
  [vetId.name]: '',
  [test.name]: '',
  [finalConsumer.name]: true,
  [productionDate.name]: '',
  [productionDateMultipleStart.name]: '',
  [productionDateMultipleEnd.name]: '',
  [productionDateSingle.name]: '',
  [batchNumberOptions.name]: [],
  [batchNumbers.name]: null,
  [batchFormat.name]: null,
  [batchRangeFrom.name]: '',
  [batchRangeTo.name]: '',
  [signature.name]: '',
  [containsDairy.name]: false,
  [signedRequestUrl.name]: '',
  [documentId.name]: '',
};

