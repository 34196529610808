import React from "react"
import { Field } from "formik"
import { Col, Form } from "@themesberg/react-bootstrap";
import ReactHtmlParser from 'react-html-parser';

const CheckBox = (props) => {
    const { name, label, sublabel = null, checked, readOnly, setFalse = null, isrequired, col = 12, touched, errors, ...rest } = props
    return (
        <Field name={props.name}>
            {({ field, meta }) => {
                return (
                    <Form.Group controlId={`validation${name}`}>
                        <Col md={col}>
                            <Form.Check
                                id={name}
                                type="checkbox"
                                name={name}
                                checked={setFalse == true ? false : field.value}
                                disabled={readOnly}
                                label={ReactHtmlParser(label)}
                                className={(isrequired == "true" ? "required" : "")}
                                isInvalid={!!meta.error && meta.touched}
                                {...field}
                                {...rest}
                            />
                            {sublabel !== null && (
                                <Form.Text className="text-muted">&nbsp;&nbsp;
                                    {sublabel}
                                </Form.Text>
                            )}
                        </Col>
                        <Form.Control.Feedback type="invalid">
                            {meta.error}
                        </Form.Control.Feedback>
                    </Form.Group>
                )
            }}
        </Field>
    )
}
export default CheckBox


