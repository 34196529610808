import *  as baseService  from "./BaseService";

const getByProductId = (id) => {
    return baseService.getApiData("/productmeatpreparation/getByProductId?id=" + id);
};

const create = (data) => {
    return baseService.postApiData("/productmeatpreparation/Create", data);
};

const update = (id, data) => {
    return baseService.putApiData("/productmeatpreparation/update?id=" + id, data);
};

const ProductMeatPreparationService = {
    create,
    getByProductId,
    update
};

export default ProductMeatPreparationService;