import *  as baseService  from "./BaseService";

const create = (data) => {
    return baseService.postApiData("/AttestationRequest/create", data);
};
const createResponseNonGEFS = (data) => {
    return baseService.postApiData("/AttestationResponse/CreateNonGEFS", data);
};
const createResponse = (data) => {
    return baseService.postApiData("/AttestationResponse/CreateGEFS", data);
};
const createSA = (data) => {
    return baseService.postApiData("/Attestationdocument/CreateSupportAttestation", data);
};
const getAllByProduct = (id) => {
    return baseService.getApiData("/Attestation/GetAllByProductId?id=" + id);
};
const getCurrentByProductId = (id) => {
    return baseService.getApiData("/Attestation/GetCurrentByProductId?id=" + id);
};
const cancelAttestationRequest = (id) => {
    return baseService.putApiData("/Attestation/CancelSupportAttestationRequest?id=" + id);
};
const getAllByManufacturer = (id) => {
    return baseService.getApiData("/Attestation/getAllByManufacturer");
};
const getAllPagedByUserId = (params) => {
    return baseService.getApiDataWithParams("/Attestation/GetAllPagedByUserId", params);
};
const getAllPagedNonGEFS = (params) => {
    return baseService.getApiDataWithParams("/Attestation/GetAllPagedNonGEFS", params);
};
const getDailyAttestationProducts = (params) => {
    return baseService.getApiDataWithParams("/Attestation/GetAllDailyAttestationProducts", params);
};
const getAllByVet = (id) => {
    return baseService.getApiData("/Attestation/GetCurrentByVetUserId?id=" + id);
};
const getUniqueReference = (id, isGEFS) => {
    return baseService.getApiData("/Attestation/GetUniqueReference?productId=" + id + "&isGEFS=" + isGEFS);
};
const getById = (id) => {
    return baseService.getApiData("/Attestation/getById?id=" + id);
};
const processBatchNumbers = (type, batchFormat, batchRangeFrom, batchRangeTo) => {
    return baseService.getApiData("/Attestation/getBatchRange?type=" + type + "&format=" + batchFormat + "&batchFrom=" + batchRangeFrom + "&batchTo=" + batchRangeTo);
};
const AttestationService = {
    getAllByProduct,
    getById,
    create,
    createSA,
    createResponse,
    createResponseNonGEFS,
    getCurrentByProductId,
    getDailyAttestationProducts,
    getUniqueReference,
    getAllByVet,
    cancelAttestationRequest,
    getAllByManufacturer,
    getAllPagedByUserId,
    getAllPagedNonGEFS,
    processBatchNumbers
};

export default AttestationService;