import moment from "moment-timezone";

export function getTimeSpanText(date) {
    var now = moment();
    var newDate = moment(date);

    if (!newDate.isValid()) {
        return "";
    }

    var daysDiff = now.diff(newDate, "days");
    var weeksDiff = now.diff(newDate, "weeks");
    var monthsDiff = now.diff(newDate, "months");
    var yearsDiff = now.diff(newDate, "years");

    if (daysDiff === 0) {
        return "today";
    } else if (daysDiff === 1) {
        return "yesterday";
    } else if (daysDiff < 7) {
        return daysDiff + " days ago";
    } else if (weeksDiff === 1) {
        return "a week ago";
    } else if (daysDiff < 30) {
        return weeksDiff + " weeks ago";
    } else if (monthsDiff === 1) {
        return "a month ago";
    } else if (daysDiff < 365) {
        return monthsDiff + " months ago";
    } else if (yearsDiff === 1) {
        return "a year ago";
    } else {
        return yearsDiff + " years ago";
    }

}