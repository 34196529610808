import React, { useState } from 'react';
import { faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Form } from '@themesberg/react-bootstrap';

export default (props) => {
  const { title, data, name, numberSelected, hasSearch, selectedFilters = [], handleChange } = props;


  const handleSelectItem = (item) => {
    // { value: x.value, group: name, label: x.label, title: x.title, filter: x.isFilter }
    //item.name = name;
    const updatedItems = [...selectedFilters];
    const index = updatedItems.findIndex((selectedItem) => selectedItem.value === item.value);

    item.title = title;
    item.group = name;

    if (index === -1) {
      updatedItems.push(item);
    } else {
      updatedItems.splice(index, 1);
    }
    // Call the callback function to update the selectedFilters in the parent component
    handleChange(updatedItems);
  };


  const FilterButton = React.forwardRef(({ children, style, onClick }, ref) => (
    <Button variant="light" size="sm" className={`py-2 ps-3 btn-block btn-block-md-down  fw-normal border-0 rounded-pill ${selectedFilters?.find(x => x.group === name) ? 'bg-lightblue ' : 'bg-light'}`} ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
      {children}
    </Button>
  ));

  const FilterMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div ref={ref} style={{ ...style, maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }} className={className} aria-labelledby={labeledBy}>
        {hasSearch &&
          <Form.Control
            autoFocus
            size={"sm"}
            className={`mx-3 my-2 small w-auto py-1 rounded-pill ps-3`}
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
        }
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter((child, index) => !value || data[index].label.toLowerCase().replaceAll(" ", "").includes(value.toLowerCase().replaceAll(" ", "")))} {/* Use data[index].label */}
        </ul>
      </div>
    );
  });

  return (
    <Dropdown className={`d-inline btn-block-md-down mx-2`}
    // autoClose="outside"
    >
      <Dropdown.Toggle as={FilterButton} id="dropdown-autoclose-outside font-size-xs">
        {title}
        <span className="small"><FontAwesomeIcon icon={faChevronDown} className="ms-3" /></span>
      </Dropdown.Toggle>
      <Dropdown.Menu as={FilterMenu}>
        {data?.map((item, i) => (
          <Dropdown.Item className='py-1' key={i} eventKey={i} onSelect={() => handleSelectItem(item)}>
            <Form.Check
              type="checkbox"
              size={"sm"}
              className={`small py-1`}
              id={`checkbox-${i}`}
              label={item.label}
              checked={selectedFilters?.find(y => y.value === item.value.toString() && y.group === name)}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
