import React from "react";
import { Link, useHistory } from 'react-router-dom';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from '@themesberg/react-bootstrap';
import moment from "moment";
import BlobService from "../../services/BlobService";
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@themesberg/react-bootstrap';
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes } from '../../../auth/PermissionsMap';
import AttestationService from '../../../pages/services/AttestationService';

export const AttestationHistory = (props) => {
    const { attestations } = props;
    const history = useHistory();

    const getSADate = (date) => {
      var newDate = moment(date).format("Do MMM YYYY");
      if (newDate === "Invalid date") {
          return "";
      }
      return newDate;
  }
  const _openBlobPdf = (filename) => {
    BlobService.openBlobPdf(filename);
  }

  const cancelAttestationRequest = (id) => {
    AttestationService.cancelAttestationRequest(id)
    .then(response => {
        history.go(0);
    })
    .catch(e => {
        console.log(e);
    });
};

  const getDaysAgo = (date) => {
    var now = moment();
    var newDate = moment(date);
    if (newDate === "Invalid date") {
        return "";
    }
    var dateDiff = now.diff(newDate, "days");
    if (dateDiff === 0) {
        return "today";
    }
    else if (dateDiff === 1) {
        return "yesterday";
    }
    else{
      return + dateDiff + " days ago";
    }
  
  }
  
const TableRow = (props) => {
const {uniqueCode, id, certificateNumberList, validFrom, validTo, attestationRequest, attestationDetails, attestationResponse, batchNumbers } = props;
var productJson = {};

if(attestationDetails!=null){
    productJson = JSON.parse(attestationDetails?.productJson);
}

let certificateNumber ='';
if (Array.isArray(certificateNumberList)) {
    certificateNumber = certificateNumberList?.map(x => x).join(", ");
}
else  {
    certificateNumber = certificateNumberList;
}

let isGEFS = productJson?.product?.isGEFS;
return ( 
<tr>
    <td>
    <div className="list-inline list-group-flush list-group-timeline mb-0">
        <h6>{attestationDetails?.uniqueCode}</h6>
        <div className="list-group-item border-0 px-0">
        <div className="row ps-lg-1">
            <div className="col-auto pl-0">
                <div className="icon-shape icon-sm icon-shape-primary rounded">
                    <FontAwesomeIcon 
                        icon={faFileSignature} 
                        className={attestationResponse==null? "text-dark" : attestationResponse.isRejected? "text-danger" : "text-success"} />
                </div>
            </div>
                <div className="col ms-n2">
                    {attestationResponse!=null?(
                        //Attestation Completed
                        <>
                        <h3 className="fs-6 fw-bold mb-0">
                            {attestationResponse.filename!=''?
                                (
                                    <a onClick={()=> _openBlobPdf(attestationResponse.signedDocument?.filename)} target="_blank">
                                        Vet Declaration
                                    </a>
                                )
                                :(
                                    <>
                                        Vet Declaration
                                    </>
                                )
                            }
                        </h3>
                        <span className="small">
                            {attestationResponse.isRejected? "Rejected" : "Approved"} {getDaysAgo(attestationResponse.createdDate)} 
                        </span>
                        {attestationResponse.isRejected&&(
                            <span className="small d-block">Reason: {attestationResponse.rejectReason}</span>
                        )}
                        </>
                    ):
                    //Attestation Incomplete
                    (
                        <div className="" >
                            <h3 className="fs-6 mb-0">
                                Vet Declaration
                            </h3>
                            <div className="small">Not yet responded</div>
                        </div>
                    )
                }
                </div>
            </div>
        </div>
        {isGEFS &&
        <div className="list-group-item border-0 px-0">
            <div className="row ps-lg-1">
                <div className="col-auto">
                    <div className="icon-shape icon-sm icon-shape-primary rounded">
                    <FontAwesomeIcon icon={faFileSignature} className="text-success" />
                    </div>
                </div>
                <div className="col ms-n2 mb-1">
                    <h3 className="fs-6 mb-0 fw-bold">
                        <a onClick={()=> _openBlobPdf(attestationRequest.signedDocument?.filename)} target="_blank">
                            Supplier Declaration
                        </a>
                    </h3>
                    <span className="small">Requested {getDaysAgo(attestationRequest.createdDate)} 
                    </span>
                </div>
            </div>
        </div>
        }
        </div>
        </td>
    {/* <td>${returnValue}</td> */}
    <td className="text-center">{attestationResponse==null || attestationResponse.isRejected ? "N/A" : getSADate(validFrom)} </td>
    <td className="text-center">{attestationResponse==null || attestationResponse.isRejected ? "N/A" : getSADate(validTo)} </td>
    <td className="text-center">
        {batchNumbers !== null? batchNumbers : "N/A"}
    </td>
    <td className="text-center">
        {certificateNumber}
    </td>
    {attestationResponse==null&&(
    <td className="text-center" colSpan={2}>
        <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.attestationrequests.create]}>
            <Button size="sm" className=" block" 
                onClick={()=> cancelAttestationRequest(id)} 
                variant="danger" 
                > 
                <FontAwesomeIcon icon={faWindowClose} className="me-2" />
                <span>Cancel</span>
            </Button>
        </PermissionsCheck>
    </td>
    )}
</tr>
);
};
    
return (
    <>

    {attestations.length <= 0?(
        <div className="text-center pt-5">
        <h5 className="text-gray-600">No support attestation have been created or requested yet</h5>
        </div>
    )
    :
    (
    <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
        <tr>
            <th scope="col">Unique Code</th>
            <th scope="col" className="text-center">Production Date From</th>
            <th scope="col" className="text-center">Production Date To</th>
            <th scope="col" className="text-center">Batches</th>
            <th scope="col" className="text-center">Certificate</th>
            {attestations.find(x=>x.attestationResponse != null) &&(<th scope="col" className="text-center"  colSpan={2}></th>)}
        </tr>
        </thead>
        <tbody>
        {attestations.map(pv => <TableRow key={`attestation-history-${pv.id}`} {...pv} />)}
        </tbody>
    </Table>
)
    }


{/*       
    {JSON.stringify(attestations)}
        */}
    </>
)}

export default AttestationHistory;