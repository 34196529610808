import React from "react";
import { Field } from "formik";
import ReactHtmlParser from 'react-html-parser';
import { Col, Form } from "@themesberg/react-bootstrap";
import FormController from "./FormController";

function CheckBoxClauses(props) {
    const { label, name, isrequired, readOnly, disabled, options, inlinelist = false, ...rest } = props;

    return (
        <>
            {label !== "" && (
                <Form.Label className={`mt-0 ${isrequired === "true" ? "required" : ""}`}>{label}</Form.Label>
            )}
            <ol className={inlinelist ? " list-unstyled mb-2 pb-0" : "list-clauses mb-3 pb-0"}  >
                <Field name={name}>
                    {({ field, form, meta }) => {
                        const handleChange = (value) => {
                            const currentValue = Array.isArray(field.value) ? field.value : [];
                            const set = new Set(currentValue);
                            if (set.has(value)) {
                                set.delete(value);
                            } else {
                                set.add(value);
                            }
                            form.setFieldValue(name, Array.from(set));
                        };

                        return options.map(option => (
                            <li key={option.key}>
                                <Col className={inlinelist ? "" : "clause-container"}>
                                    {option.disabled != null ? (
                                        <Form.Group className="mb-1 clause-disabled">
                                            {ReactHtmlParser(option.key)}
                                        </Form.Group>
                                    )
                                        :
                                        (
                                            <Form.Group>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={`${name}-${option.value}`}
                                                    value={option.value}
                                                    checked={Array.isArray(field.value) ? field.value.includes(option.value) : false}
                                                    onChange={() => handleChange(option.value)}
                                                    disabled={option.disabled === "disabled"}
                                                    label={ReactHtmlParser(option.key)}
                                                    isInvalid={!!meta.error && meta.touched}
                                                    {...rest}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    {option.subclauses && Array.isArray(field.value) && field.value.includes(option.value) && (
                                        <div className="clause-container">
                                            <FormController
                                                control="checkboxClauses"
                                                label=""
                                                name={option.subclauseName}
                                                options={option.subclauses}
                                                readOnly={readOnly}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </li>
                        ));
                    }}
                </Field>
            </ol>
        </>
    );
}

export default CheckBoxClauses;
