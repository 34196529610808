import React from "react"
import { Field } from "formik"
import ReactHtmlParser from 'react-html-parser';
import {  Col, Form } from "@themesberg/react-bootstrap";
import FormController from "./FormController"

function getClassName(subform) {
    return subform != null ?  12 : 10;
}

function RadioButtonClauses(props) {
    const { readOnly, label, name, options, subform, clear, isrequired, col=12,  parent,parentSelected,disableBox,disableBox2, form, handleChange2,disableBox3,inlinelist, ...rest } = props

    return (
        <>
        {label!=""&&(
            <Form.Label className={` ${(isrequired=="true"?"required":"")}`}>{label}</Form.Label>
        )}
            <ol className={inlinelist?" list-unstyled mb-2 pb-0" : "list-clauses mb-3 pb-0"}  >
            <Field 
                isClearable={true} 
                name={name}>
                {formik => {
                    const { field, form, meta } = formik;
                    const { name, value: formikValue } = field;
                    const { setFieldValue } = form;

                    function clearCheckboxes(value, name)  {
                         if (subform) {
                             if(!disableBox2){
                                return false;
                            }
                             
                            if(field.value != null){
                                return field.value?.toString() == value?.toString()  && disableBox2 > 0
                            }
                            else{
                                return  field.value?.toString() == value?.toString();
                            }
                        }
                        else{
                            return  field.value?.toString() == value?.toString();
                        }    
                    }

                    return options.map(option => {
                        return (
                            <li key={option.key}>
                                <Col className={inlinelist ? "" : "clause-container"}   sm={getClassName(subform)}>
                                    {option.disabled != null ? (
                                        <Form.Group className="mb-1 clause-disabled">
                                            {ReactHtmlParser(option.key)}
                                        </Form.Group>
                                    )   
                                    :
                                    (
                                        <Form.Group  className="mb-1">
                                            <Form.Check
                                                id={option.fieldname + option.value}
                                                type="radio"
                                                {...field}
                                                {...rest}
                                                name={name}
                                                onChange={e => {
                                                       setFieldValue(name, parseInt(e.target.value));
                                                    }}
                                                value={ option.value}
                                                checked={clearCheckboxes(option.value,option.filename)}
                                                disabled = {disableBox || readOnly}
                                                label={ReactHtmlParser(option.key)}
                                                isInvalid={!!meta.error && meta.touched}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                            {meta.error}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        )}
                                        {option.subclauses != null && (
                                            <div className="clause-container">
                                                <FormController
                                                    control="radioClauses"
                                                    label=""
                                                    subform
                                                    parentSelected={field.value}
                                                    parent={option.value}
                                                    clear={true}
                                                    onChange={e => {
                                                    setFieldValue(name, e.target.value);
                                                    }}
                                                    disableBox= {(field.value !== option.value) ? "disabled" : ""}
                                                    disableBox2= {(field.value === option.value) ? option.value : false}
                                                    disableBox3= {(field.value === option.value) ? true : false}
                                                    readOnly={readOnly}
                                                    name={option.subclauseName}
                                                    options={option.subclauses}
                                                />
                                            </div>
                                        )}
                                </Col>
                            </li>
                        )
                    })
                }}
            </Field>
        </ol>
        </>
    )
}

export default RadioButtonClauses