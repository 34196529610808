import *  as baseService  from "./BaseService";

const getAllPaged = (params) => {
    return baseService.getApiDataWithParams("/vet/GetAllPaged", params);
};
const getAll = (params) => {
    return baseService.getApiDataWithParams("/vet/GetAll", params);
};
const getById = (id) => {
    return baseService.getApiData("/vet/GetById?id=" + id);
};
async function create (data) {
    return await baseService.postApiData("/vet/create", data);
};
const VetService = {
    getAllPaged,
    getAll,
    create,
    getById
};

export default VetService;