import React from 'react';
import { Col, Row } from "@themesberg/react-bootstrap";
import * as AttestationContent from "./AttestationContent";
import AttestationCertificateForms from "./AttestationCertificateForms"

export default function AttestationBody(props) {
    
    const {
        certificateNumber,
        uniqueRef, 
        customUniqueRef, 
        attestationData, 
        productId, 
        user, 
        organisation,
        manufacturer,
        platform, 
        site, 
        setFieldValue,
        productionDateChoice,
        productionDate, 
        productionDateMultipleEnd, 
        productionDateSingle, 
        productionDateMultipleStart,
        isGEFS, 
        product, 
        productDetail, 
        isReadOnly
    } = props;
    
    return (
        <>

        {isGEFS && 
        <>
            <AttestationContent.SectionANotice/>
            <AttestationContent.Urn 
                isGEFS={isGEFS}
                uniqueRef={uniqueRef}
                customUniqueRef={customUniqueRef}
                productId={productId}
                isReadOnly={isReadOnly}
            />
            <AttestationContent.SectionAStatement 
                isGEFS={isGEFS}
                uniqueRef={uniqueRef}
                organisation={organisation}
                user={user}
                manufacturer={manufacturer}
            />
        </>
        }

        {!isGEFS && 
            <AttestationContent.SectionAStatementNonGEFS 
                uniqueRef={uniqueRef}
                user={user}
                organisation={organisation}
                manufacturer={manufacturer}
                isReadOnly={isReadOnly}
            />
        }
        
        <AttestationContent.SectionAProductDetails
            site={site}
            platform={platform}
            manufacturer={manufacturer}
            product={product}
            detail={productDetail}
            isReadOnly={isReadOnly}
        />
    
        <AttestationContent.SectionAProductInfo
            product={product}
            isReadOnly={isReadOnly}
        />
        <Row className="mt-3">
            <Col md={12}>
                <h4>Section B</h4>
                {!isGEFS &&
                    <p>I, the undersigned veterinarian certify that the product described in Section A.2 comply with the following health requirements from Specimen EHC ({certificateNumber.map(x => x).join(", ")})</p>
                }
                <AttestationCertificateForms 
                    site={site}
                    platform={platform}
                    manufacturer={manufacturer}
                    certificateNumber={certificateNumber}
                    product={product}
                    productDetail={productDetail}
                    isReadOnly={isReadOnly}
                    attestationData={attestationData}
                    setFieldValue={setFieldValue}
                    productionDateChoice={productionDateChoice}
                    productionDate={productionDate}
                    productionDateMultipleEnd={productionDateMultipleEnd}
                    productionDateSingle={productionDateSingle}
                    productionDateMultipleStart={productionDateMultipleStart}
                />
            </Col>
        </Row>

        </>
    );
};