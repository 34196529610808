import { getByDisplayValue } from "@testing-library/dom";
import *  as baseService  from "./BaseService";

const getAll = (params) => {
    return baseService.getApiData("/temperature/getAll");
};
const getById = (id) => {
    return baseService.getApiData("/temperature/GetById?id=" + id);
};
const TemperatureService = {
    getAll,
    getById
};

export default TemperatureService;