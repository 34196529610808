import *  as baseService  from "./BaseService";

const getSignatureRequest = (data) => {
    return baseService.postApiData("/Signature/CreateSignatureRequest", data);
};
const uploadSignedDocument  = (id) => {
    return baseService.getApiData("/Signature/GetSignedDocument?signatureRequestId=" + id);
};
const getSignatureServiceUrl  = (id) => {
    return baseService.getApiData("/Signature/GetSignatureUrl?signatureId=" + id);
};
const SignatureService = {
    getSignatureServiceUrl,
    getSignatureRequest,
    uploadSignedDocument
};

export default SignatureService;

