import React, { useState, useEffect, useRef } from 'react';
import DocumentPanel from '../DocumentReader/DocumentPanel';
import { Form, Formik } from 'formik';
import NavigationBar from '../Components/NavigationBar';
import EhcGenerateService from '../../../../services/EhcGenerateService';
import { Accordion, Alert, Col, Row } from "@themesberg/react-bootstrap";
import FormController from '../../../common/FormController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';
// import { faCircleInfo } from "@fortawesome/free-regular-svg-icons";

const CompoundCertificateForm = (props) => {
  const {
      isCompound,
      consignmentContentsId,
      certificateId,
      setData, 
      onUpdated,
      initialSavedData,
      data,
      certificateGroups,
      updating,
      formData,
      updatedPdf,
    } = props;

    const [documentPath, setDocumentPath] = useState('');
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    
    const canvas = useRef();
    let ctx = null;

    
    const onSubmit = () =>
    {
    }

    let initialValues = {
      totalPages: numPages ?? [],
    }
    function retrieveFormInitialData() {

      const getCertificateCompoundByteArray= new Promise((resolve, reject) => {
        resolve(EhcGenerateService.getCompoundCertificateFilename(consignmentContentsId, certificateId)
            .then((response) => {
                var bytes = response.data;
                setDocumentPath(`data:application/pdf;base64,${bytes}`);
            })
            .catch((e) => {
                console.log(e);
            })
        );
      });
        Promise.all([getCertificateCompoundByteArray]).then((responses) => {
            //   });
      })
    }

    useEffect(() => {
      retrieveFormInitialData();
    },[]);

    useEffect(() => {
      if(updatedPdf!==undefined && updatedPdf!==null){
          //temp - works for composite
          retrieveFormInitialData();
          //setDocumentPath(updatedPdf);
      }
  },[updatedPdf]);


    const goToPrevPage = () =>
      setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  
    const goToNextPage = () =>
      setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
    
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setTotalPages(numPages);
    };
    return(
        <>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={onSubmit}
          >
          {(formik, setFieldValue) => (
            <Form key={certificateId}>
              <Row className="mt-2">
                <Col md={5}>
                  <Row className="mt-2">
                    <Col md={12}>
                      <Alert variant="info" className="mt-3 d-flex" col="sm">
                        <FontAwesomeIcon icon={faInfoCircle} className="me-3 mt-1 fs-5" /> 
                        <div>
                          <p>This area shows the <span className="fw-bold">latest compound certificate</span>. 
                              Update each certificates individually using the tabs above.</p>
                          <p>You can retun to this tab after an update has been made to see the latest compound certificate</p>
                        </div>
                      </Alert>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={12}>
                      <label className="mb-2">Adjust default total pages</label>
                    </Col>
                    <Col xs={3} md={6} lg={4} xl={3}>
                      <FormController 
                          control="input" 
                          type="number" 
                          name="totalPages"
                      />
                    </Col> 
                  </Row>
                </Col>
                <Col md={7}>
                  <NavigationBar
                    isCompound={isCompound}
                    numPages={numPages}
                    pageNumber={pageNumber}
                    goToNextPage={goToNextPage}
                    goToPrevPage={goToPrevPage}
                    data={data}
                    certificateGroups={certificateGroups}
                    setData={setData}
                    formData={formData}
                    onUpdated={onUpdated}
                    updating={updating}
                    values={formik.values}
                    //isSubmitting={isSubmitting}
                    initialSavedData={initialSavedData}
                    // config={config}
                  />

                  <DocumentPanel 
                    disableTools={true}
                    pageNumber={pageNumber}
                    numPages={numPages}
                    documentPath={documentPath}
                    updating={false}
                    formData={formData}
                    setData={setData}
                    updatedPdf={updatedPdf}
                    values={null}
                    canvas={canvas}
                    ctx={ctx}
                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                  />   
                </Col> 
              </Row>
          
            </Form>
          )}
          </Formik>
        </>
    )
}

export default CompoundCertificateForm;
