import { Routes } from "../../../routes";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFilter} from '@fortawesome/free-solid-svg-icons';
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Col,Row, Button, Card } from '@themesberg/react-bootstrap';
import { SupportAttestationHistoryTable } from "../../../components/Tables/SupportAttestationHistoryTable";
import { DailyAttestationListingTable } from "../../../components/Tables/DailyAttestationListingTable";
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes } from '../../../auth/PermissionsMap';


const AttestationDashboard = (props) => {

    const breadcrumbData = {
        item1 : {name:'Attestations', link: Routes.Platforms.path},
        item2 : {name:'View all', link: 'View all'},
    };
    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            
            <h2 id="tabelLabel" >Attestations</h2>
            
            <Row className="mt-3">
                {/* //Attestation History */}
                <Col lg={12} xxl={11}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Header>
                            <Row>
                                <Col md={12}>
                                    <h4 className="mb-3 text-title">Monthly Support Health Attestations</h4>
                                    {/* <p>The latest monthly attestation requests you have sent</p> */}
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <SupportAttestationHistoryTable />
                        </Card.Body>
                    </Card>
                </Col>

                {/* //Daily Attestations */}
                <Col lg={12} xxl={11}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Header>
                            <Row>
                                <Col md={12} sm={12} lg={9}>
                                    <h4 className="mb-3 text-title">Daily Attestations </h4>
                                    {/* <p>The latest daily attestations you have created</p>*/}
                                </Col> 
                                <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.dailyattestations.create]}>
                                    <Col xs={12} sm={12} lg={3} className="text-end">
                                        <Button variant="secondary" href={Routes.DailyAttestationAddPage.path.replace(":id?","")} className="text-dark me-2">
                                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                                            <span>New</span>
                                        </Button>
                                    </Col>
                                </PermissionsCheck>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <DailyAttestationListingTable />
                        </Card.Body>
                    </Card>
                </Col>
                
            </Row>
        </div>
    );
};
export default AttestationDashboard;