import *  as baseService  from "./BaseService";

const getAll = (id) => {
    return baseService.getApiData("/consignmentimage/getByconsignmentId?id=" + id);
};
const create = (data) => {
    return baseService.postApiData("/consignmentimage/create", data);
};
const remove = (id) => {
    return baseService.deleteApiData("/image/delete?id=" + id);
};
const ConsignmentImageService = {
    getAll,
    create,
    remove
};

export default ConsignmentImageService;