import React , {useState, useRef} from 'react';
import { Overlay, Tooltip } from '@themesberg/react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

const EllipsisTooltip = ({ text, ...props }) => {
    const ref = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
  
    const handleMouseEnter = () => {
      setShowTooltip(ref.current.offsetWidth < ref.current.scrollWidth);
    };
  
    const handleMouseLeave = () => {
      setShowTooltip(false);
    };
  
    return (
      <>
        <div
          ref={ref}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          {...props}
        >
          {text}
        </div>
        <Overlay target={ref.current} show={showTooltip} placement="top">
          {props => <Tooltip {...props}>{text}</Tooltip>}
        </Overlay>
      </>
    );
  };
  

 export default EllipsisTooltip;