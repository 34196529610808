import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Card } from "@themesberg/react-bootstrap";
import { Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import * as Yup from 'yup';
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import ColdStoreSelect from '../../../../components/EstablishmentSelect/ColdStoreSelect';
import CuttingPlantSelect from '../../../../components/EstablishmentSelect/CuttingPlantSelect';
import SlaughterhouseSelect from '../../../../components/EstablishmentSelect/SlaughterhouseSelect';
import { Routes } from "../../../../routes";
import EstablishmentsService from '../../../services/EstablishmentsService';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ProductService from "../../../services/ProductService";
import ProductStorageService from "../../../services/ProductStorageService";
import SiteService from '../../../services/SiteService';
import ZoneService from "../../../services/ZoneService";
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import FormController from "../../common/FormController";
import { MultiSelectField } from "../../common/MultiSelect";
import CountryService from '../../../services/CountryService';
import PlatformService from '../../../services/PlatformService';

const Storage8461AddPage = (props) => {

    const { id, attestationId } = useParams();
    const certificateNumber = "8461";
    const [readOnly, setReadOnly] = useState(false);
    const [formVersion] = useState(GetCertificateFormVersion(certificateNumber));
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [storageRequired, SetStorageRequired] = useState(false);
    const [zones, SetZones] = useState([]);
    const [memberStates, SetMemberStates] = useState([]);
    const [countryOrigin, setCountryOrigin] = useState(null);
    const [siteEstablishment, setSiteEstablishment] = useState([]);
    const [platformEstablishment, setPlatformEstablishment] = useState([]);
    const [countrys, SetCountrys] = useState([]);
    const [controlledRisk, SetControlledRisk] = useState([]);
    const [negligbleRisk, SetNegligbleRisk] = useState([]);
    const [specieslist, SetSpecies] = useState([]);
    const [apiValues, SetApiValues] = useState([]);


    let title = (editing == true ? "Edit " : "New") + " Storage Information (#8461)"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Storage 8461', link: Routes.Storage8461AddPage.path },
    };
    const initialValues = {
        productId: props.match.params.id,
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
        coldStoreEstablishments: apiValues?.coldStoreEstablishments ?? '',
        slaughterhouseEstablishments: apiValues?.slaughterhouseEstablishments ?? '',
        cuttingPlantEstablishments: apiValues?.cuttingPlantEstablishments ?? '',
        identificationMarkEstablishment: apiValues?.identificationMarkEstablishment ?? ''
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";


    //Validation
    const validationSchema = Yup.object().shape({
        // slaughterhouseEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
        //     .required(validationMsgRequired),
        identificationMarkEstablishment: Yup.object().required(validationMsgAtLeastOne),
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne),
       //coldStoreEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
           // .required(validationMsgRequired)
    });

    function onSubmit(values, { setSubmitting, resetForm }) {
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;
            console.log(submitData);

            if (apiValues.length === 0) {
                ProductStorageService.create(submitData)
                    .then(response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        resetForm({})
                    })
                    .catch(e => {
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            else {
                ProductStorageService.update(productId, values)
                    .then(response => {
                        setFormSubmitted(true);
                        resetForm({})
                    })
                    .catch(e => {
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();

        useEffect(() => {
            if (ready) {
            }
        }, [values]);

        return null;
    };
    useEffect(() => {
        async function fetchListData() {
            try {
                const getProductDetailData = new Promise((resolve, reject) => {
                    resolve(
                        //Get site estab
                        ProductService.getProductDetailByProductId(productId)
                            .then(response4 => {
                                setCountryOrigin(response4.data.countryOrigin);
                                //Get site operatorId
                                SiteService.getById(response4.data.siteId)
                                    .then(response2 => {
                                        setSiteEstablishment([{name: response2.data.siteName, approvalNumber: response2.data.establishmentNumber}])
                                    }, [])
                                    .catch(e => {
                                        console.log(e);
                                    });

                                    PlatformService.getById(response4.data.platformId)
                                    .then(response2 => {
                                        setPlatformEstablishment(response2.data)
                                    }, [])
                                    .catch(e => {
                                        console.log(e);
                                    });
                            }, [])
                    );
                });
                const getCountryData = new Promise((resolve, reject) => {
                    resolve(CountryService.getEUAndUKCountries()
                        .then((response) => {
                            const options = response.data.map(d => d.isoCode)
                            SetCountrys(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                
                const getProductScientificSpeciesData = new Promise((resolve, reject) => {
                    resolve(ProductScientificSpeciesDataService.getOnlyStorageDeclaration()
                        .then((_speciesResponse) => {
                            SetSpecies(_speciesResponse.data);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                Promise.all([getProductDetailData, getCountryData, getProductScientificSpeciesData]).then((responses) => {
                    setReady(true);
                });
            } catch (e) {
                console.error(e);
            }
        };
        fetchListData();
    }, []);

    useEffect(() => {
        if (ready) {
            ProductStorageService.getByProductId(productId)
                .then(response => {
                    if (response.data == "") {
                        return;
                    }
                    console.log(response.data);
                    SetApiValues(response.data);
                    setEditing(true);
                    setReady(true);
                    console.log("init values:" + apiValues);
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductService.getById(productId)
                .then(response => {
                    SetProduct(response.data.data);
                    setIsGEFS(response.data.data.isGEFS);
                }, [])
                .catch(e => {
                    console.log(e);
                });
                
            ZoneService
                .getAllByGroup("ALL_MEMBER_STATES")
                .then((_countryResponse) => {
                    const options = _countryResponse.data.map(d => ({
                        "id": d.id,
                        "displayName": d.displayName,
                        "countryName": d.countryName,
                        "regionName": d.regionName,
                        "isoCode": d.isoCode,
                    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                    const firstItem = "GB";
                    options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                    SetMemberStates(options);
                }).catch((e) => {
                    console.log(e);
                });
        }
    }, [ready]);

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);


    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <>
                    <SubmissionPage
                        productId={productId}
                        attestationId={attestationId}
                        isGEFS={isGEFS}
                        isStorageDeclaration={true}
                        isAttestation={false}
                        errors={errors}
                        title={(editing ? "Edited" : "Completed") + " Storage 8461 Form"}
                        message={`Storage information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                    />

                </>

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                    >
                        {(props, isSubmitting) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        {/* <FormController
                                            control="select"
                                            label="Meat Category"
                                            name="meat_MincedMeat"
                                            isrequired="true"
                                            options={meats}
                                            col={3}
                                        /> */}

                                        <strong>Products of animal origin or goods</strong> described here:
                                        <ul>
                                            <li>originate from and has been produced in the Union and was eligible for placing on the market in the Union, and</li>
                                            <li>was packed in the Union and, for products of animal origin, marked in the Union in accordance with Section I of Annex II to Regulation (EC) No 853/2004, and</li>
                                            <li>is destined to the Union, and</li>
                                            <li>has not been tampered and did not undergo and other handling than unloading, storage, re-loading, and transporting in <strong>GB-0</strong> and for 
                                                products of animal origin has been stored and transported in accordance with the relevant requirements of Annex III to Regulation (EC) No 853/2004 of the European Parliament and of the Council.
                                            </li>
                                        </ul>
                                        
                                        <p>
                                            <label>Country of origin:&nbsp;</label> 
                                            {countryOrigin?.countryName} 
                                        </p>

                                        <MultiSelectField
                                            id='productScientificSpecies'
                                            name='productScientificSpecies'
                                            label="Species"
                                            placeholder="Select fields"
                                            options={specieslist}
                                            defaultValues={props.values?.productScientificSpecies}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                            value={props.values.productScientificSpecies}
                                            isMulti={true}
                                            onChange={props.setFieldValue}
                                            onBlur={props.setFieldTouched}
                                            touched={props.touched.productScientificSpecies}
                                            error={props.errors.productScientificSpecies}
                                            isClearable={true}
                                            isrequired="true"
                                            backspaceRemovesValue={true}
                                            col={4}
                                            readOnly={readOnly}
                                        />

                                        <ColdStoreSelect
                                            name="coldStoreEstablishments"
                                            selectedFieldName="coldStoreEstablishments"
                                            setEstablishmentSelected={props.setFieldValue}
                                            selections={props.values.coldStoreEstablishments}
                                            excludedApprovalNumbers={[siteEstablishment[0]?.approvalNumber,platformEstablishment?.establishmentNumber]}
                                            label="Cold store(s)"
                                            isMulti={true}
                                            countries={countrys}
                                            readOnly={readOnly}
                                            certificateNumber={[certificateNumber]}
                                            getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                            getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                        />
                                
                                        <MultiSelectField
                                            id='identificationMarkEstablishment'
                                            name='identificationMarkEstablishment'
                                            label="Identification mark"
                                            placeholder="Select fields"
                                            options={(siteEstablishment || []).concat(props.values.coldStoreEstablishments || [])} 
                                            getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                            getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                            isMulti={false}
                                            onChange={props.setFieldValue}
                                            onBlur={props.setFieldTouched}
                                            touched={props.touched.identificationMarkEstablishment}
                                            error={props.errors.identificationMarkEstablishment}
                                            isClearable={true}
                                            isrequired="true"
                                            backspaceRemovesValue={true}
                                            readOnly={readOnly}
                                            col={6}
                                        />

                                            {/* <FormController
                                                control="input"
                                                type="text"
                                                label="Date of production"
                                                name="dateOfProduction"
                                                col={3}
                                            /> */}
                                        

                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        {/* {JSON.stringify(props.errors)} */}
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={readOnly || isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isStorageDeclaration={true}
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default Storage8461AddPage;