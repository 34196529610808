import { faBoxOpen, faFileCsv, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Dropdown, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTable } from "react-table";
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes } from '../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import FilterBar from "../../filters/FilterBar";
import { ProductListTable } from "../../../components/Tables/ProductListTable";
import { Routes } from "../../../routes";
import { ProductListFilterConfig } from "../../filters/configs/ProductListFilterConfig";
import ManufacturerDataService from "../../services/ManufacturerService";
import ProductDataService from "../../services/ProductService";
import SiteDataService from "../../services/SiteService";
import Paging from "../common/Paging";
import CertificateNumberService from '../../services/CertificateNumberService';
import VisibilityService from '../../services/VisibilityService';

const ProductListingPage = (props) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [ready, setReady] = useState(false);
    const [manufacturers, setManufacturers] = useState([]);
    const [certificateNumbers, setCertificateNumbers] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const [activePage, setActivePage] = useState(searchParams.get("page") || 1);
    const [sites, setSites] = useState([]);
    const [products, setProducts] = useState([]);
    const [visibilityOptions, setVisibilityOptions] = useState([]);
    const [count, setCount] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(searchParams.get("size") || 25);
    const [loaded, setLoaded] = useState(false);
    const { totalPages = count, size = "md", withIcons = false } = props;
    const productRef = useRef();
    const orderByOptions = ["Last updated", "A-Z", "Z-A", "Valid attestations"];

    productRef.current = products;
    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: 'View All', link: 'View All' },
    };
    //Get paged results based on provided filter parameters
    const getProducts = (params) => {
        ProductDataService.getAllPaged(params)
            .then((response) => {
                const { data, totalPages, totalRecords } = response.data;
                setTotalItems(totalRecords);
                setProducts(data);
                setCount(totalPages);
                setLoaded(true);
            })
            .catch((e) => {
                setLoaded(true);
                console.log(e);
            });
    };


    //Submit
    const handleFilterSubmit = (params) => {
        setSubmitting(true);
        getProducts(params)
        setSubmitting(false);
    }

    const handleFilterChange = (values) => {
        setSubmitting(true);
        //setLoaded(true);
        setSubmitting(false);
    }

    //Start - Paging Functions
    const onPrevItem = () => {
        const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
        setActivePage(prevActiveItem);
    };

    const onPageNumberClick = (number) => {
        setActivePage(number);
    };

    const onNextItem = (totalPages) => {
        const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
        setActivePage(nextActiveItem);
    };

    const handlePageSizeChange = (value) => {
        setPageSize(value.value);
    };
    //End - Paging Functions


    //Link to product
    const openProduct = (rowIndex) => {
        const id = productRef.current[rowIndex].id;
        props.history.push("/product/" + id);
    };

    //Table config
    const columns = useMemo(() => [
        {
            Header: "Product Name",
            accessor: "productName",
            Cell: (props) => {
                const rowIdx = props.row.id;
                const row = props.row;
                const productUrl = `/product/${row.original.id}`;
                return (
                    <a href={productUrl} className="text-black" onClick={(e) => {
                        e.preventDefault();
                        openProduct(rowIdx);
                    }}>
                        {props.value}
                    </a>
                );
            },
        },
        {
            Header: "Product Code",
            accessor: "productCode",
        },
        {
            Header: "EAN",
            accessor: "ean",
        },

        {
            Header: "Attestation Valid To",
            accessor: "attestationValidTo",
        },
        {
            Header: "Batch",
            accessor: "batchNumbers",
        }
    ], []
    );
    const { headerGroups, rows } = useTable({
        columns,
        data: products,
    });

    //On load - Get list data
    useEffect(() => {
        const getSiteData = new Promise((resolve, reject) => {
            resolve(SiteDataService.getAll()
                .then(response => {
                    const options = response.data.map(d => ({
                        "value": d.id,
                        "label": d.siteName
                    })).sort((a, b) => a.label.localeCompare(b.label));
                    setSites(options);
                })
                .catch(e => {
                    console.log(e);
                })
            );
        });
        const getManufacturerData = new Promise((resolve, reject) => {
            resolve(ManufacturerDataService.getAll()
                .then(response => {
                    const options = response.data.map(d => ({
                        "value": d.id,
                        "label": d.manufacturerName
                    })).sort((a, b) => a.label.localeCompare(b.label));
                    setManufacturers(options);
                })
                .catch(e => {
                    console.log(e);
                })
            );
        });

        const getCertificateNumbers = new Promise((resolve, reject) => {
            resolve(CertificateNumberService.getAll()
                .then(response => {
                    const options = response.data.map(d => ({
                        "value": d.number,
                        "label": d.name
                    })).sort((a, b) => a.label.localeCompare(b.label));
                    setCertificateNumbers(options);
                })
                .catch(e => {
                    console.log(e);
                })
            );
        });

        const getVisibilityOptions = new Promise((resolve, reject) => {
            resolve(VisibilityService.getAll()
                .then(response => {
                    const options = response.data.map(d => ({
                        "value": d.name,
                        "label": d.name
                    })).sort((a, b) => a.label.localeCompare(b.label));
                    setVisibilityOptions(options);
                })
                .catch(e => {
                    console.log(e);
                })
            );
        });

        Promise.all([getSiteData, getManufacturerData, getCertificateNumbers, getVisibilityOptions])
            .then((responses) => {
                setReady(true);

            });
    }, []);

    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData} />
            <Row className='row d-flex align-items-center'>
                <Col xs={12} sm={6} lg={6} className="text-start">
                    <h2 id="tabelLabel" >Products</h2>
                </Col>
                <Col xs={12} sm={6} lg={6} className="text-end">
                    <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.products.create]}>
                        <Dropdown>
                            <Dropdown.Toggle as={Button} variant="secondary" size={"sm"} className="text-dark">
                                <FontAwesomeIcon icon={faPlus} className=" me-2" />
                                <span>Add</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
                                
                                <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.products.create]}>
                                    <Dropdown.Item href={Routes.AddProduct.path}>
                                        <FontAwesomeIcon icon={faBoxOpen} className="me-2" /> Single Product
                                    </Dropdown.Item>
                                </PermissionsCheck>
                                
                                <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.products.createbulk]}>
                                    <Dropdown.Item href={Routes.FileUpload.path}>
                                        <FontAwesomeIcon icon={faFileCsv} className="me-2" /> CSV Upload
                                    </Dropdown.Item>
                                </PermissionsCheck>
                            </Dropdown.Menu>
                        </Dropdown>
                    </PermissionsCheck>
                    
                </Col>
            </Row>
            <div className="col-md-12 list">
                <Row className="mt-1">
                    <Col>
                        <Card border="light" className="bg-white shadow-sm mb-4 ">
                            <Card.Header>
                                <Row>
                                    <Col md={12}>
                                        <FilterBar
                                            loaded={loaded}
                                            setLoaded={setLoaded}
                                            ready={ready}
                                            hasSearch={true}
                                            defaultFilterValues={ProductListFilterConfig(manufacturers, sites, certificateNumbers, visibilityOptions)}
                                            activePage={activePage}
                                            pageSize={pageSize}
                                            orderByOptions={orderByOptions}
                                            setActivePage={setActivePage}
                                            handleChange={handleFilterChange}
                                            handleSubmit={handleFilterSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <ProductListTable
                                    headerGroups={headerGroups}
                                    products={rows}
                                    columns={columns}
                                    loaded={loaded}
                                />
                                <Paging
                                    activePage={activePage}
                                    size={size}
                                    pageSize={pageSize}
                                    totalPages={totalPages}
                                    totalItems={totalItems}
                                    onPrevItem={onPrevItem}
                                    onNextItem={onNextItem}
                                    handleChange={handlePageSizeChange}
                                    onPageNumberClick={onPageNumberClick}
                                    withIcons={withIcons}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ProductListingPage;