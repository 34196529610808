import formModel from './NonGEFSFormModel';
const {
  formField: {
    traceability,
    formsComplete,
    dateInspection,
    decision,
    rejectReason,
    signedRequestUrl,
    documentId,
    isGEFS,
    containsDairy,
    approvedExportCountries,
    approvedExportCountriesOfOrigin,
    hatcheries,
    hatcheryName,
    hatcheryPostcode,
    finalConsumer,
    isDateCollectionProductionMulti,
    dateCollectionProductionStart,
    dateCollectionProductionEnd,
    productionDate,
    productionDateSingle,
    productionDateMultipleStart,
    productionDateMultipleEnd,
    dateCollectionProductionSingle,
    dateCollectionProduction,
    batchNumber,
    numberProductsCases
  }
} = formModel;

export default {
  [containsDairy.name]: false,
  [traceability.name]: '',
  [formsComplete.name]: false,
  [isGEFS.name]: true,
  [dateInspection.name]: '',
  [approvedExportCountries.name]: '',
  [approvedExportCountriesOfOrigin.name]: '',
  [hatcheries.name]: '',
  [hatcheryName.name]: '',
  [hatcheryPostcode.name]: '',
  [decision.name]: '',
  [rejectReason.name]: '',
  [signedRequestUrl.name]: '',
  [documentId.name]: '',
  [finalConsumer.name]: false,
  [isDateCollectionProductionMulti]: null, 
  [dateCollectionProductionStart]: '', 
  [dateCollectionProductionEnd]: '', 
  [dateCollectionProductionSingle]: '', 
  [dateCollectionProduction]: '', 
  [productionDate.name]: '',
  [productionDateSingle.name]: '',
  [productionDateMultipleStart.name]: '',
  [productionDateMultipleEnd.name]: '',
  [batchNumber.name]: '',
  [numberProductsCases.name]: 0,
  [containsDairy.name]: false,
};

