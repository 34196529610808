import React, { useEffect } from "react";
import { Col, Row, Alert } from "@themesberg/react-bootstrap";
import moment from "moment";
import FormController from "../pages/components/common/FormController"
import { FieldArray, getIn, useFormikContext } from 'formik';
import { use } from "i18next";


export const DateCollectionProduction = (props) => {
    const { 
        isDateCollectionProductionMultiName, 
        dateCollectionProductionStartName, 
        dateCollectionProductionEndName, 
        dateCollectionProductionSingleName, 
        isDateCollectionProductionMultiLabel, 
        dateCollectionProductionStartLabel, 
        dateCollectionProductionEndLabel, 
        dateCollectionProductionSingleLabel, 
        dateCollectionProductionName, 
        isReadOnly
     } = props;
    
    const { values: formValues, setFieldValue } = useFormikContext();

    useEffect(() => {  
        var date = String(getIn(formValues,isDateCollectionProductionMultiName)) == "true"?
    moment(getIn(formValues,dateCollectionProductionStartName))?.format("DD MMMM YY")
    + " to " +
    moment(getIn(formValues,dateCollectionProductionEndName))?.format("DD MMMM YY")
    :
        moment(getIn(formValues,dateCollectionProductionSingleName))?.format("DD MMMM YY");
        setFieldValue(dateCollectionProductionName, date)
        console.log("dateCollectionProductionName",formValues)
    }
    ,[formValues])

    return (
    isReadOnly ? (
        String(formValues.isDateCollectionProductionMulti) ?
        <>
        <strong className="mt-3">{dateCollectionProductionStartLabel}</strong><br />
        {moment(formValues.dateCollectionProductionStart)?.format("D MMMM, YYYY")}
        &nssp;to&nssp;
        <strong className="mt-3">{dateCollectionProductionEndLabel}</strong><br />
        {moment(formValues.dateCollectionProductionEnd)?.format("D MMMM, YYYY")}
        </>
        :
        <>
            <strong className="mt-3">{dateCollectionProductionSingleLabel}</strong><br />
            {moment(formValues.dateCollectionProductionSingle)?.format("D MMMM, YYYY")}
        </>
    )
    :
    (
        <Row className="">
            <Col md={12}>
                <label className="mt-3 mb-2 required">{isDateCollectionProductionMultiLabel}</label>
                <br/>
                <div className="text-italic text-gray mb-2"> Note: You can select the product's oldest date of production for the specific batches declared.</div>
            </Col>
            <Col md={3}>
                <FormController
                    className="mt-2"
                    control="radio"
                    options={[{value: false, key: "Single Date" },{value: true, key: "Date Range" }]}
                    placeholder={isDateCollectionProductionMultiLabel}
                    isrequired="true"
                    name={isDateCollectionProductionMultiName}
                    value={formValues.isDateCollectionProductionMulti}
                    col={12}
                />
            </Col>
            <Col md={7}>
            {String(getIn(formValues, isDateCollectionProductionMultiName)) === "true" &&
                <Row>
                    <Col md={6}>
                        <label className="mt-0 required">Date from:</label>
                        <FormController
                            control="dateInput"
                            placeholder={dateCollectionProductionStartLabel}
                            isrequired={"true"}
                            name={dateCollectionProductionStartName}
                            col={12}
                        />
                    </Col>
                    <Col md={6}>
                        <label className="mt-0 required">Date to:</label>
                        <FormController
                            control="dateInput"
                            placeholder={dateCollectionProductionEndLabel}
                            isrequired={"true"}
                            name={dateCollectionProductionEndName}
                            col={12}
                        />
                    </Col>
                </Row>
            }
            {String(getIn(formValues, isDateCollectionProductionMultiName)) === "false" &&
            <>
                <label className="mt-0 required">Date:</label>
                <FormController
                    control="dateInput"
                    placeholder={dateCollectionProductionSingleLabel}
                    isrequired={"true"}
                    name={dateCollectionProductionSingleName}
                    col={5}
                />
            </>
            }
            </Col>
        </Row>
    )  
    )
}