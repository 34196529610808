import { Card, Col, Row, Alert } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faCheckCircle, faCalendar} from '@fortawesome/free-solid-svg-icons';
import UpcomingService from '../pages/services/UpcomingService';
import {getTimeSpanText} from '../pages/components/common/DateFormatting';
import EllipsisTooltip from './EllipsisTooltip';
import moment from 'moment-timezone';
import { Routes } from '../routes'
import { NoResults } from './Tables/NoResults';

  export default (props) => {
    const [activity, setUpcoming] = useState([]);
    const Upcoming = (props) => {
      const { title, expiryDate, type, productId, productName, url, color ="red", icon="", last=""} = props;
      const { item} = props;
      
      return (

        <div class="list-group-item border-0 px-0">
        <div class="row ps-lg-1">
          <div class="col-auto px-0">
            <div class="icon-shape icon-xs icon-shape-purple rounded">
              <FontAwesomeIcon icon={faCalendar} className="" />
            </div>
          </div>
          <div class="col ms-n2 mb-1 text-truncate">
            <div class="d-flex align-items-center mt-2 mb-1">
              <span className="fs-7 fw-bold">
              {moment(expiryDate)?.format("D MMMM, YYYY")}
              </span>
            </div>
            <h3 class="fs-7 mb-1 fw-normal">
              {type}
            </h3>
            <small>
              <a href={Routes.Product.path.replace(":id", productId)} className="text-dark">
                <EllipsisTooltip text={title}/>
                <span>{productName}</span>
              </a>
            </small>
          </div>
        </div>
      </div>
      );
    };
  
	useEffect(() => {
    UpcomingService.getUpcoming()
      .then(x => {
        setUpcoming(x.data)
      })
      .catch(error => {
      });
    }, []);

    return (
      <Card border="light" className="shadow-sm notification-card border-0 shadow">
        <Card.Body>
          <h5 className="mb-2 text-title fw-bold">Expiring Soon</h5>

              <div class="list-group list-group-flush list-group-timeline">
                {activity?.length == 0 ?
                (
                  <NoResults name="expiring content"/>
                )
                :
                (activity?.map((item, index) => (  
                  <Upcoming 
                    {...item}
                  />
                )))
                } 
          </div>
      </Card.Body>
      </Card>
    );
  };