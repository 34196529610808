import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { Alert, Col, Row, Button } from "@themesberg/react-bootstrap";
import { certificateRoutes } from '../../CertificateForms/CertificateFormMap';
import { useHistory } from "react-router-dom";

export const AttestationAlerts = (props) => {
    const { isVersionOutdated = false, versionErrorMessage, size, productId, isGEFS = true, attestationId = null } = props

    const history = useHistory();

    const openForm = (certNumber) => {
        var certRoute = certificateRoutes.find(x => x.number == certNumber);
        {
            isGEFS ?
                history.push(certRoute.GEFS.replace(":id", productId))
                :
                history.push(certRoute.NonGEFS.replace(":id", productId).replace(":attestationId", attestationId))
        }
    }

    const getFormNumberText = () => {
        var text = "";
        {
            versionErrorMessage.length > 1 ?
                text = " " + versionErrorMessage.toString().replaceAll(",", " and ") + " certificate forms have "
                :
                text = " " + versionErrorMessage.toString() + " certificate form has "
        }
        return text;
    }

    return (
        <>
            {isVersionOutdated && (
                <Row>
                    <Col md={size}>
                        <Alert className="mt-2 mb-0 d-flex justify-content-between align-items-center" variant="warning">
                            {isGEFS ? (
                                <>
                                    <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                    <div className="">
                                        The {getFormNumberText()} been updated since the last save, before proceeding
                                        with an attestation request please go back and check all information is up to date on the certificate form(s).
                                        <br />
                                        {
                                            versionErrorMessage.map(x =>
                                                <Button
                                                    variant="warning"
                                                    size={"sm"}
                                                    className="d-inline mt-2 me-3"
                                                    onClick={() => openForm(x)}
                                                >
                                                    Open {x} certificate
                                                </Button>
                                            )
                                        }
                                    </div>
                                </>
                            )
                                :
                                (
                                    <>
                                        <div className="">
                                            <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                            The {getFormNumberText()} been updated since the last save. Before proceeding, please ensure all information is up-to-date.
                                        </div>
                                    </>
                                )
                            }
                        </Alert>
                    </Col>
                </Row >
            )
            }
        </>
    );
};

export default AttestationAlerts;