import  * as msal  from "@azure/msal-browser";
import { msalInstance } from '../index'

const tenant = process.env.REACT_APP_AZURE_TENANT;
const signInPolicy = process.env.REACT_APP_AZURE_POLICY_SIGNIN;
const applicationID = process.env.REACT_APP_AZURE_CLIENT_ID;
const instance =  process.env.REACT_APP_INSTANCE;

var tenantName = document.location.hostname.split('.')[0];

if (tenantName === null || typeof(tenantName) === "undefined" || tenantName === "localhost"|| tenantName === "ivetted-webapp-dev") {
   tenantName = "dev";
} 
export function getTenantName() {
  return tenantName;
}

export async function bearerToken() {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    alert('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
    }).catch( function (error) {
      if (error.errorCode === "consent_required" || error.errorCode === "interaction_required" || error.errorCode === "login_required") {
          msalInstance.acquireTokenRedirect(loginRequest).then(
              function (response) {
                  // call API
              }).catch(function (error) {
                  console.log(error);
              });;
      }
  });
  return response.accessToken
}

export const b2cPolicies = {
  names: {
      signUpSignIn: signInPolicy,
      // forgotPassword: "b2c_1_reset",
  },
  authorities: {
      signUpSignIn: {
          authority: instance + tenant + "/" + signInPolicy,
      },
      // forgotPassword: {
      //    //authority: "https://your-tenant-name.b2clogin.com/your-tenant-name.onmicrosoft.com/b2c_1_reset",
      //     authority: instance + tenant + "/" + signInPolicy,
      // },
  },
  authorityDomain: instance
}

// Config object to be passed to Msal on creation
export const msalConfig = {
  
    auth: {
        clientId: applicationID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: window.location.origin,
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: true,
        validateAuthority: false,
        extraQueryParameters: 
        { 
             tenant: tenantName
        }
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
        // cacheLocation: "localStorage",
        // storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {	
                    return;	
                }
                switch (level) {	
                    case msal.LogLevel.Error:	
                      //  console.error(message);	
                        return;	
                    case msal.LogLevel.Info:	
                       // console.info(message);	
                        return;	
                    case msal.LogLevel.Verbose:	
                       // console.info(message);	
                        return;	
                    case msal.LogLevel.Warning:	
                      //  console.warn(message);	
                        return;	
                    default:
                        return;
                }
            }
        }
    }
};

export const scopes = [
        "https://ivettedB2c.onmicrosoft.com/ivetted-api/tasks.read",
        "openid",
        "profile"
];


// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: scopes,
      extraQueryParameters: 
      { 
            tenant: tenantName
      }
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: " https://graph.microsoft.com/.default/me"
};

export function getUser() {
  const instance = new msal.PublicClientApplication(msalConfig);
  const account = instance.getAllAccounts()[0];
  return account?.idTokenClaims;
}


export const authProvider = msal;