import * as Ovine8369Labels from "../Ovine8369Labels"
import { findArrayElementByFieldNameAndValue, findArrayElementByValue } from "../../../Attestations/Common/AttestationFunctions";
import moment from "moment-timezone";
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
export const AttestationContent = (props) => {
    const { attestationData } = props;
    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    var animals = '';
    const species = attestationData.productScientificSpecies;
    const speciesNames = species.map(x => x.speciesName).join(", ");

    if (speciesNames.includes("Ovis aries") && speciesNames.includes("Capra hircus")) {
        animals = 'Ovine and Caprine';
    }
    else if (speciesNames.includes("Capra hircus")) {
        animals = 'Caprine';
    }
    else if (speciesNames.includes("Ovis aries")) {
        animals = 'Ovine';
    }

    { Ovine8369Labels.health_1_5_statements[1].key = "the packages of " + attestationData.meat_MincedMeat + " have been marked with an identification mark in accordance with Section I of Annex II to Regulation (EC) No 853/2004;" };

    { Ovine8369Labels.animal_2_1_B_statements[1].key = "in which foot and mouth disease has not been reported since " + moment(attestationData?.animal_2_1_B_date).format("DD/MM/YYYY") + "." };

    { Ovine8369Labels.animal_2_2_statements[1].key = "have been introduced on " + moment(attestationData?.animal_2_2_B_date).format("DD/MM/YYYY") + " into the zone referred to under point II.2.1., from the zone with code <strong><u>" + attestationData?.animal_2_2_zone?.map(x => x.isoCode).join(", ") + "</u></strong> that at that date was authorised for the entry of fresh meat of" + { animals } + "animals into the Union and where they have remained since birth, or for at least 3 months before slaughter." };

    { Ovine8369Labels.animal_2_2_statements[2].key = "have been introduced on " + moment(attestationData?.animal_2_2_C_date).format("DD/MM/YYYY") + " into the zone referred to under point II.2.1., from the Member State with ISO code <strong><u>" + attestationData?.animal_2_2_memberState?.map(x => x.isoCode).join(", ") + "</u></strong> and vaccination against this disease has not been carried out during a period of 12 months before the date of slaughter of the animals from which the fresh meat was obtained" };


    return (
        <>
            <h5>II. Health Attestation</h5>
            <>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned, declare that I am aware of the relevant requirements of Regulation (EC) No 999/2001 of the European Parliament
                    and of the Council, Regulation (EC) No 178/2002 of the European Parliament and of the Council, Regulation (EC) No 852/2004 of the European
                    Parliament and of the Council, Regulation (EC) No 853/2004 of the European Parliament and of the Council, Regulation (EU) 2017/625 of the
                    European Parliament and of the Council, Commission Delegated Regulation (EU) 2019/624 and Commission Implementing Regulation (EU) 2019/627
                    and hereby certify that the fresh meat of domestic Ovine and Caprine animals (Ovis aries and Capra hircus) described in Part I was produced in
                    accordance with these requirements, in particular that:
                </p>
                <ol className="attestation-II-1">
                    <li>
                        the {attestationData.meat_MincedMeat} comes from (an) establishment(s) applying general hygiene requirements and implementing a programme based on
                        the hazard analysis and critical control points (HACCP) principles in accordance with Article 5 of Regulation (EC) No 852/2004, regularly audited
                        by the competent authorities, and being listed as an EU approved establishment;
                    </li>
                    {attestationData.health_1_2 ? (
                        <li>{ReactHtmlParser(Ovine8369Labels.health_1_2.label)}</li>
                    ) :
                        (
                            <li>N/A</li>
                        )}
                    {attestationData.health_1_3 ? (
                        <li>{ReactHtmlParser(Ovine8369Labels.health_1_3.label)}</li>
                    ) :
                        (
                            <li>N/A</li>
                        )}
                    <li>
                        the meat has been found fit for human consumption following ante-mortem and post-mortem inspections carried out in accordance with Articles 8
                        to 17, 23, 24, 30, 31, 33 to 35, 37, 38 of Implementing Regulation (EU) 2019/627 and Articles 3, 4, 5, 7 and 8 of Delegated Regulation (EU)
                        2019/624;
                    </li>
                    <li>
                        {attestationData.health_1_5_statements > 0 &&
                            <li>
                                {findArrayElementByFieldNameAndValue(Ovine8369Labels.health_1_5_statements, 'health_1_5_statements', attestationData.health_1_5_statements)}
                            </li>
                        }
                    </li>
                    <li>
                        the {attestationData.meat_MincedMeat} satisfies the relevant criteria laid down in Commission Regulation (EC) No 2073/2005;
                    </li>
                    <li>
                        the guarantees covering live animals and products thereof provided by the residue plans submitted in accordance with Article 29 of Council
                        Directive 96/23/EC, are fulfilled and the concerned animals and products are listed in Commission Decision 2011/163/EU for the concerned
                        country of origin;
                    </li>
                    <li>
                        the {attestationData.meat_MincedMeat} has been produced under conditions guaranteeing compliance with the maximum residue levels for pesticides laid
                        down in Regulation (EC) No 396/2005 of the European Parliament and of the Council, and the maximum levels for contaminants laid down in
                        Commission Regulation (EC) No 1881/2006.
                    </li>
                    <li>
                        the {attestationData.meat_MincedMeat} has been stored and transported in accordance with the relevant requirements of Sections I and V respectively of
                        Annex III to Regulation (EC) No 853/2004.
                    </li>
                    <li>
                        {attestationData.bseRiskCategoryNegligible || attestationData.bseRiskCategoryControlled || attestationData.bseRiskCategoryUndetermined ? (
                            <ul>
                                {attestationData.bseRiskCategoryNegligible && (
                                    <ul>
                                        <li>the country or region of origin is classified in accordance with Commission Decision
                                            2007/453/EC as a <strong><u>country or region posing a negligible BSE risk</u></strong>, and

                                            <ul>
                                                {attestationData.bseRiskCategoryNegligibleStatements.map(statement =>
                                                    <li>
                                                        {findArrayElementByValue(Ovine8369Labels.BseRiskCategoryNegligibleStatementsChoice, statement)}
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    </ul>
                                )}
                                {/* BSE Controlled */}
                                {attestationData.bseRiskCategoryControlled &&
                                    <ul>
                                        <li>the country or region of origin is classified in accordance with Decision 2007/453/EC
                                            as a <strong><u>country or region posing a controlled BSE risk</u></strong>, and

                                            <ol type="a">
                                                <li>
                                                    {findArrayElementByValue(
                                                        Ovine8369Labels.BseRiskCategoryControlledStatementsChoice, 1)
                                                    }
                                                </li>
                                                <li>
                                                    {findArrayElementByValue(
                                                        Ovine8369Labels.BseRiskCategoryControlledStatementsChoice, 2)
                                                    }
                                                </li>
                                                {attestationData.bseRiskCategoryControlledStatements > 0 &&
                                                    <li>
                                                        {findArrayElementByValue(
                                                            Ovine8369Labels.BseRiskCategoryControlledStatementsChoice,
                                                            attestationData.bseRiskCategoryControlledStatements)
                                                        }


                                                    </li>
                                                }
                                                {attestationData.bseControlledC > 0 &&
                                                    <li>
                                                        {findArrayElementByValue(
                                                            Ovine8369Labels.BseRiskCategoryControlledChoice_C,
                                                            attestationData.bseControlledC)
                                                        }
                                                    </li>
                                                }
                                            </ol>
                                        </li>
                                    </ul>
                                }
                                {/* BSE Undetermined */}
                                {attestationData.bseRiskCategoryUndetermined &&
                                    <ul>
                                        <li>the country or region of origin has not been classified in accordance with Decision 2007/453/EC or is classified as a country or region with an <u>undetermined BSE risk</u>, and
                                            <ol type="a">
                                                <li>
                                                    {findArrayElementByValue(Ovine8369Labels.BseRiskCategoryUndeterminedStatementsChoice, 1)}
                                                </li>
                                                <li>
                                                    {findArrayElementByValue(Ovine8369Labels.BseRiskCategoryUndeterminedStatementsChoice, 2)}
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                }
                            </ul>
                        ) :
                            (
                                <li>N/A</li>
                            )}
                    </li>
                </ol>
                <h6>II.2 Animal health attestation</h6>
                <p>I, the undersigned, hereby certify that the <strong>fresh meat</strong> described in Section A.2:</p>
                <ol type="1" className="attestation-II-2">
                    <li>
                        <strong><u></u></strong>
                        has been obtained in the <strong>zone(s)</strong> with code(s): <strong><u>{attestationData.animal_2_1_zone?.map(x => x.isoCode).join(", ")}</u></strong> which, at the date of issue of this support attestation is/are authorised for the entry into
                        the Union of <strong>fresh meat of {animals} animals</strong> and listed in Section A.2 of Annex XIII to Commission Implementing Regulation (EU) 2021/404, and:
                        <ul>
                            <li>
                                in which infection with rinderpest virus and African swine fever has not been reported for a period of 12 months before the date of
                                slaughter of the animals from which the fresh meat was obtained, and during the same period vaccination against these diseases has not
                                been carried out; and
                            </li>
                            {attestationData.animal_2_1_B_statements > 0 &&
                                <li>
                                    {findArrayElementByFieldNameAndValue(Ovine8369Labels.animal_2_1_B_statements, 'animal_2_1_B_statements', attestationData.animal_2_1_B_statements)}
                                </li>
                            }
                        </ul>
                    </li>
                    <li>
                        has been obtained <strong>from animals</strong> that:
                        {attestationData.animal_2_2_statements > 0 &&
                            <ul>
                                <li>
                                    {findArrayElementByFieldNameAndValue(Ovine8369Labels.animal_2_2_statements, 'animal_2_2_statements', attestationData.animal_2_2_statements)}
                                </li>
                            </ul>
                        }
                    </li>
                    <li>
                        has been obtained from animals coming from <strong>establishments</strong>:
                        <ul>
                            <li>
                                registered by and under the control of the competent authority of the third country or territory and have a system in place to maintain and
                                to keep records in accordance with Article 8 of Commission Delegated Regulation (EU) 2020/692;
                            </li>
                            <li>
                                which receive regular animal health visits from a veterinarian for the purpose of the detection of, and information on, signs indicative of
                                the occurrence of diseases, including the relevant listed diseases referred to in Annex I to Delegated Regulation (EU) 2020/692, and
                                emerging diseases;
                            </li>
                            <li>
                                which were not subject to national restriction measures for animal health reasons, including the relevant listed diseases referred to in
                                Annex I to Delegated Regulation (EU) 2020/692, and emerging diseases, at the time of dispatch to the slaughterhouse;
                            </li>
                            <li>
                                in which none of the animals kept therein have been vaccinated against foot and mouth disease, infection with rinderpest virus;
                            </li>
                            {attestationData.animal_2_3_E_statements > 0 &&
                                <ul>
                                    <li>
                                        {findArrayElementByFieldNameAndValue(Ovine8369Labels.animal_2_3_E_statements, 'animal_2_3_E_statements', attestationData.animal_2_3_E_statements)}
                                    </li>
                                </ul>
                            }
                            {attestationData.animal_2_3_F_A ? (
                                <li>{ReactHtmlParser(Ovine8369Labels.animal_2_3_F_A.label)}</li>
                            ) :
                                (
                                    <li>N/A</li>
                                )}
                            {attestationData.animal_2_3_F_B ? (
                                <li>{ReactHtmlParser(Ovine8369Labels.animal_2_3_F_B.label)}</li>
                            ) :
                                (
                                    <li>N/A</li>
                                )}
                        </ul>
                    </li>
                    <li>
                        has been obtained <strong>from animals</strong> which:
                        <ul>
                            <li>
                                have been dispatched from their establishment of origin to an approved slaughterhouse by means of transport: (i) constructed in such a way
                                that the animals cannot escape or fall out; (ii) in which visual inspection of the space where animals are kept is possible; (iii) from which
                                the escape of animal excrements, litter or feed is prevented or minimised, and (iv) which was cleaned and disinfected with a disinfectant
                                authorised by the competent authority of the third country or territory immediately before the transportation of the animals without contact
                                with other animals which did not comply with the conditions referred to in point II.2.1., II.2.2. and II.2.3.;
                            </li>
                            <li>
                                during the transport to the slaughterhouse the animals did not pass through a third country or territory or zone thereof which is not listed
                                for the entry into the Union of fresh meat of {animals} animals and they have not come into contact with animals of a lower health status;
                            </li>
                            {attestationData.animal_2_4CA ? (
                                <li>
                                    have been slaughtered on {moment(attestationData?.animal_2_4CA).format("DD/MM/YYYY")};
                                </li>
                            ) :
                                (
                                    <li>
                                        have been slaughtered between {moment(attestationData.animal_2_4CB).format("DD/MM/YYYY")} and {moment(attestationData.animal_2_4CC).format("DD/MM/YYYY")};
                                    </li>
                                )}
                            <li>
                                had no contact with animals of a lower health status during their slaughter.
                            </li>
                        </ul>
                    </li>
                    <li>
                        has been obtained in a <strong>slaughterhouse</strong> in and around which, within a radius of 10 km, including where appropriate the territory of a neighboring
                        country, none of the diseases referred to in point II.2.1 has been reported during a period of 30 days before the date of slaughtering of the animals.
                    </li>
                    <li>
                        has been strictly segregated from fresh meat not complying with the animal health requirements for the entry into the Union of fresh meat of
                        {animals} animals throughout the operations of slaughter, cutting and until:
                        {attestationData.animal_2_6_statements > 0 &&
                            <ul>
                                <li>
                                    {findArrayElementByFieldNameAndValue(Ovine8369Labels.animal_2_6_statements, 'animal_2_6_statements', attestationData.animal_2_6_statements)}
                                </li>
                            </ul>
                        }
                    </li>
                    {attestationData.animal_2_7 ? (
                        <li>
                            {ReactHtmlParser(Ovine8369Labels.animal_2_7.label)}
                            <ul>
                                {attestationData.animal_2_7_A ? (
                                    <li>{ReactHtmlParser(Ovine8369Labels.animal_2_7_A.label)}</li>
                                ) :
                                    (
                                        <li>N/A</li>
                                    )}


                                {attestationData.animal_2_7_B ? (
                                    <li>{ReactHtmlParser(Ovine8369Labels.animal_2_7_B.label)}</li>
                                ) :
                                    (
                                        <li>N/A</li>
                                    )}
                            </ul>
                        </li>
                    ) :
                        (
                            <li>N/A</li>
                        )}
                </ol>
                <h6>II.3 Animal welfare attestation</h6>
                <p>I, the undersigned, hereby certify, that the Meat described in Section A.2 derive from animals which have been treated in the slaughterhouse
                    in accordance with the requirements of the Union legislation on the protection of animals at the time of killing or at least equivalent requirements.</p>

                {/* <PrettyPrintJson data={attestationData} >  </PrettyPrintJson> */}
                {/* <PrettyPrintJson data={compositeInfo} >  </PrettyPrintJson>
            <PrettyPrintJson data={speciesList} >  </PrettyPrintJson>  */}
            </>
        </>
    )
}

