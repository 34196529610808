import React, { Component } from "react";
import { Col, Form } from "@themesberg/react-bootstrap";
import { Field } from "formik";
import Select from "react-select";

class MultiSelectField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: props.defaultValues,
      disableSingleResult: false,
      enabledBlankOption: false,
    };
  }

  handleChange = (value) => {
    const { onChange, name } = this.props;
    onChange(name, value);
  };

  handleBlur = () => {
    const { onBlur, name } = this.props;
    onBlur(name, true);
  };
  
  componentDidMount() {
    const { selectOnSingleResult, options } = this.props;
    if (selectOnSingleResult && options.length === 1) {
      const firstKey = options[0];
      this.setState({ defaultValue: firstKey, disableSingleResult: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectOnSingleResult, options } = this.props;
    const { disableSingleResult } = this.state;
    
    if (
      selectOnSingleResult &&
      options.length === 1 &&
      !disableSingleResult &&
      options !== prevProps.options
    ) {
      const firstKey = options[0];
      this.setState({ defaultValue: firstKey, disableSingleResult: true }, () => {
        const { onChange, name } = this.props;
        onChange(name, firstKey);
      });
    }
  }

  render() {
    const {
      id,
      className,
      name,
      label = null,
      sublabel = null,
      placeholder,
      options,
      value,
      col = 12,
      size,
      isMulti,
      isDisabled,
      readOnly,
      touched,
      defaultValues,
      error,
      isrequired,
      isClearable,
      getOptionValue,
      getOptionLabel,
      hasBlankOption = false,
      backspaceRemovesValue,
      selectOnSingleResult = false,
      ...rest
    } = this.props;

    const { defaultValue, disableSingleResult } = this.state;



    return (
      <Field name={name}>
        {({ field, meta }) => {
          return (
            <>
              {label != null && (
                <Form.Label
                  className={isrequired === "true" ? "required" : ""}
                  error={error}
                  htmlFor={name}
                >
                  {label}
                </Form.Label>
              )}
              {sublabel != null && (
                <Form.Text className="text-muted">
                  &nbsp;&nbsp;{sublabel}
                </Form.Text>
              )}
              <Col md={col}>
                <Select
                  {...field}
                  {...rest}
                  value={field.value !== "" ? field.value : defaultValue}
                  id={id}
                  placeholder={placeholder}
                  options={options}
                 // defaultValue={defaultValue}
                  onChange={this.handleChange}
                  getOptionValue={getOptionValue}
                  getOptionLabel={getOptionLabel}
                  onBlur={this.handleBlur}
                  name={name}
                  isMulti={isMulti}
                  isDisabled={isDisabled || readOnly || disableSingleResult}
                  isClearable={isClearable}
                  isInvalid={!!meta.error && meta.touched}
                  backspaceRemovesValue={backspaceRemovesValue}
                  components={{ ClearIndicator: null }}
                  className={
                    meta.touched && meta.error
                      ? 
                      `${className} is-invalid form-control-select-${size} form-control-select-container`
                      : 
                      `${className} form-control-select-${size} form-control-select-container ${hasBlankOption && "form-control-select-item-first-blank"}`
                      
                  }
                  classNamePrefix="form-control-select"
                />

                {meta.touched && meta.error !== null ? (
                  <div className="invalid-feedback">{meta.error}</div>
                ) : null}
              </Col>
            </>
          );
        }}
      </Field>
    );
  }
}

export { MultiSelectField };
