import { faExclamationCircle, faExclamationTriangle, faHammer, faInfoCircle, faStar, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Card, Col, Modal, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import NoticeboardService from '../pages/services/NoticeboardService';
import Preloader from './Preloader';
import ReactHtmlParser from 'react-html-parser';
import { NoResults } from './Tables/NoResults';

export default () => {
  const [notices, setNotices] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const Notices = (props) => {
    const { title, description, categoryName, message, url, color ="red", icon="", last=""} = props;
    const handleCloseModalInvite = () => setShowModalInvite(false);
    const [showModalInvite, setShowModalInvite] = useState(false);

    const styles  ={ 
      performance:{
        icon: faExclamationCircle,
        variant: "lightblue",
       // color: "text-gray"
      },
      outage:{
        icon: faTimesCircle,
        variant: "danger",
        //color: "text-gray"
      },
      feature:{
        icon: faStar,
        variant: "lightblue",
       // color: "text-black"
      },
      patch:{
        icon: faExclamationTriangle,
        variant: "soft-green",
       // color: "text-gray"
      },
      maintenance:{
        icon: faHammer,
        variant: "danger",
       // color: "text-gray"
      },
      interruption:{
        icon: faExclamationTriangle,
        variant: "warning",
       // color: ""
      },
      default:{
        icon: faInfoCircle,
        variant: "lightblue",
      //  color: "text-gray"
      }
    } ;
    
    var selectedStyle = 
      categoryName=="Service Interruption" ?  selectedStyle = styles.interruption 
      : categoryName=="New feature" ?  selectedStyle = styles.feature
      : categoryName=="Scheduled Maintenance" ?  selectedStyle = styles.maintenance
      : categoryName=="System Outage" ?  selectedStyle = styles.outage
      : categoryName=="Performance Issue" ?  selectedStyle = styles.performance
      : categoryName=="Patch" ?  selectedStyle = styles.patch
      : styles.default;
      
    return (
      <>
      
      <Row hidden={!loaded} className={`align-items-center`}>
        <Col>
          <Alert variant={selectedStyle.variant} className="alert-sm">
            <div className={`fs-6 d-flex align-items-start`}>
              <FontAwesomeIcon icon={selectedStyle.icon} className="me-2 fs-5 pt-1" />
              <a onClick={()=> setShowModalInvite(true)}>
                  <div className='fs-7 fw-bold'>{title}</div>
                  <div className='fs-7'>{description}</div>
              </a>
            </div>
          </Alert>
          <Modal size="lg" as={Modal.Dialog} centered show={showModalInvite} onHide={handleCloseModalInvite}>
            <Modal.Header>
                <Modal.Title className="h5 mt-2">
                  <span>{title}</span>
                  <br/>
                  <small className="fw-normal pt-2">
                    {description}
                  </small>
                </Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleCloseModalInvite} />
            </Modal.Header>
            <Modal.Body>
              <Row className="my-3">
                  <Col md={12}>
                    {ReactHtmlParser(message)}
                  </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
      </>
    );
  };
    
  useEffect(() => {
    NoticeboardService.getAllNotices()
      .then(x => {
        setNotices(x.data)
        setLoaded(true)
      })
      .catch(error => {
        console.info(JSON.stringify(error))
      });
  }, []);

  return (
    <>
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <h5 className="mb-3 text-title fw-bold">Noticeboard</h5>
        <Preloader component={true} show={!loaded} />
          {loaded && notices?.length == 0 ?
          (
            <NoResults name="notices"/>
          )
          :
          loaded && notices?.map((item, index) => (  
            <Notices 
              {...item}
            />
        ))}
      </Card.Body>
    </Card>
    </>
  );
};