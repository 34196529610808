export const ProductListFilterConfig = (manufacturers, sites, certificateNumbers, visibilityOptions) => {
    const filterConfig = [
      {
        hasMenu: true,
        title: "Manufacturers",
        name: "manufacturers", 
        data: manufacturers,
        hasSearch: true,
        isFilter: true,
      },
      {
        hasMenu: true,
        title: "GEFS",
        name: "gefs", 
        data: [{ value: "1", label: "GEFS Only", group: "gefs" }, { value: "2", label: "Non-GEFS only", group: "gefs" }],
        hasSearch: false,
        isFilter: true
      },
      {
        hasMenu: true,
        title: "Sites",
        name: "sites", 
        data: sites,
        hasSearch: true,
        isFilter: true
      },
      {
        hasMenu: true,
        title: "Status",
        name: "visibility", 
        data: visibilityOptions,
        hasSearch: false,
        isFilter: true,
        roles: ["admin", "retailer"]
      },
      {
        hasMenu: true,
        title: "Certificate types",
        name: "certificateNumbers",
        data: certificateNumbers,
        hasSearch: false,
        isFilter: true,
        roles: ["admin"]
      }
    ]
    
    return filterConfig;
  };
  