import React, { useState, useEffect } from 'react';
import CertificateDataService from "../../services/CertificateBankService";
import { Routes } from "../../../routes";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Col, Row, Button, Card, Modal, Toast } from '@themesberg/react-bootstrap';
import FilterBar from "../../filters/FilterBar";
import { CertificateListFilterConfig } from "../../filters/configs/CertificateListFilterConfig";
import { CertificatesTable } from "../../../components/Tables/CertificateTable";
import PlatformDataService from "../../services/PlatformService";
import CertificateNumberService from '../../services/CertificateNumberService';
import Paging from "../common/Paging";
import CountryService from '../../services/CountryService';

const CertificateListingPage = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [certificates, setCertificates] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [count, setCount] = useState(0);
    const { totalPages = count, size = "md", withIcons = false, disablePrev = false } = props;
    const [platforms, SetPlatforms] = useState([]);
    const [destinationCountries, SetDestinationCountries] = useState([]);
    const [certificateNumbers, SetCertificateNumbers] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [selectedCertificateId, setSelectedCertificateId] = useState(null);
    const [selectedCertificateName, setSelectedCertificateName] = useState(null);
    const orderByOptions = ["Last updated", "Date uploaded"];
    const [activePage, setActivePage] = useState(searchParams.get("page") || 1);
    const [pageSize, setPageSize] = useState(searchParams.get("size") || 25);
    const [ready, setReady] = useState(false);

    const breadcrumbData = {
        item1: { name: 'Certificate Bank', link: Routes.CertificateListingPage.path },
        item2: { name: 'View all', link: 'View all' },
    };

    const getCertificates = (params) => {
        setLoaded(false);
        CertificateDataService.getAllPaged(params)
            .then((response) => {
                const { data, totalPages, totalRecords } = response.data;
                setTotalItems(totalRecords);
                setCertificates(data);
                setCount(totalPages);
                setLoaded(true);
            })
            .catch((e) => {
                setLoaded(true);
                console.log(e);
            });
    };

    const handleFilterSubmit = (params) => {
        setSubmitting(true);
        getCertificates(params);
        setSubmitting(false);
    };

    const handleFilterChange = (values) => {
        setSubmitting(true);
        setSubmitting(false);
    };

    const handleRemove = (id, certificateName) => {
        setSelectedCertificateId(id);
        setSelectedCertificateName(certificateName);
        setShowModal(true);
    };

    const confirmRemove = () => {
        CertificateDataService.remove(selectedCertificateId)
            .then(response => {
                setShowToast(true);
                setShowModal(false);
                setCertificates(certificates.filter(cert => cert.id !== selectedCertificateId));
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleCloseModal = () => setShowModal(false);

    const onPrevItem = () => {
        const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
        setActivePage(prevActiveItem);
    };

    const onPageNumberClick = (number) => {
        setActivePage(number);
    };

    const onNextItem = (totalPages) => {
        const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
        setActivePage(nextActiveItem);
    };

    const handlePageSizeChange = (value) => {
        setPageSize(value.value);
    };

    useEffect(() => {
        const getPlatformData = new Promise((resolve, reject) => {
            resolve(PlatformDataService.getAll()
                .then(response => {
                    const options = response.data.map(d => ({
                        "value": d.id,
                        "label": d.platformName
                    })).sort((a, b) => a.label.localeCompare(b.label));
                    SetPlatforms(options);
                })
                .catch(e => {
                    console.log(e);
                })
            );
        });

        const getCertificateNumbers = new Promise((resolve, reject) => {
            resolve(CertificateNumberService.getAll()
                .then(response => {
                    const options = response.data.map(d => ({
                        "value": d.id,
                        "label": d.name
                    })).sort((a, b) => a.label.localeCompare(b.label));
                    SetCertificateNumbers(options);
                })
                .catch(e => {
                    console.log(e);
                })
            );
        });

        const getDestinationCountries = new Promise((resolve, reject) => {
            resolve(CountryService.getAll()
                .then(response => {
                    const options = response.data.map(d => ({
                        "value": d.id,
                        "label": d.countryName
                    })).sort((a, b) => a.label.localeCompare(b.label));
                    SetDestinationCountries(options);
                })
                .catch(e => {
                    console.log(e);
                })
            );
        });

        Promise.all([getPlatformData, getCertificateNumbers, getDestinationCountries])
            .then((responses) => {
                setReady(true);
            });
    }, []);

    const filterConfig = CertificateListFilterConfig(platforms, certificateNumbers, destinationCountries);

    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData} />
            <Row className='row d-flex align-items-center'>
                <Col xs={12} sm={6} lg={6} className="text-start">
                    <h2 id="tabelLabel" >Certificate Bank</h2>
                </Col>
                <Col xs={12} sm={6} lg={6} className="text-end">
                    <Button variant="secondary" size="sm" href={Routes.CertificateUpload.path} className="text-dark me-2">
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        <span>Upload</span>
                    </Button>
                </Col>
            </Row>
            <div className="col-md-12 list">
                <Row className="mt-1">
                    <Col>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <FilterBar
                                            initialise={true}
                                            loaded={loaded}
                                            setLoaded={setLoaded}
                                            ready={ready}
                                            hasSearch={true}
                                            defaultFilterValues={filterConfig}
                                            activePage={activePage}
                                            pageSize={pageSize}
                                            orderByOptions={orderByOptions}
                                            setActivePage={setActivePage}
                                            handleChange={handleFilterChange}
                                            handleSubmit={handleFilterSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <CertificatesTable certificates={certificates} loaded={loaded} onRemove={handleRemove} />
                                <Paging
                                    activePage={activePage}
                                    col={size}
                                    pageSize={pageSize}
                                    totalPages={totalPages}
                                    totalItems={totalItems}
                                    handleChange={handlePageSizeChange}
                                    onPrevItem={onPrevItem}
                                    onNextItem={onNextItem}
                                    onPageNumberClick={onPageNumberClick}
                                    withIcons={withIcons}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* Confirmation Modal */}
            <Modal size="md" as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title className="h6">Are you sure you want to remove?</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-end">
                        <Button variant="secondary" onClick={handleCloseModal} className="me-2">Cancel</Button>
                        <Button variant="danger" onClick={confirmRemove}>Remove</Button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Success Toast */}
            <div className="toast-container">
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide className={`toast-${document.documentElement.classList.contains('theme-dark') ? 'dark' : 'light'}`}>
                    <Toast.Header className={`toast-header-${document.documentElement.classList.contains('theme-dark') ? 'dark' : 'light'}`}>
                        <strong className="me-auto">Success</strong>
                    </Toast.Header>
                    <Toast.Body className={`toast-body-${document.documentElement.classList.contains('theme-dark') ? 'dark' : 'light'}`}>You have removed: {selectedCertificateName}</Toast.Body>
                </Toast>
            </div>
        </div>
    );
};

export default CertificateListingPage;
