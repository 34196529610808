import React from "react"
import { Field } from "formik"
import ReactHtmlParser from 'react-html-parser';

import { Form} from "@themesberg/react-bootstrap";

function RadioButtonSingle(props) {
    const { label, name, readOnly, option, isrequired, className, ...rest } = props
    return (
        <div>
            {label!=null&&(
                <Form.Label className={(isrequired=="true"?"required":"")}>{label}</Form.Label>
            )}
            <Field name={name}>
                {({ field, meta }) => {
                        return (
                            <div key={option.key} className={className}>
                                <Form.Group controlId={`validation${name}`}>
                                    <Form.Check
                                        id={name + option.value}
                                        type="radio"
                                        {...field}
                                        {...rest}
                                        disabled={readOnly}
                                        isInvalid={!!meta.error && meta.touched}
                                        value={option.value}
                                        checked={field.value.toString() == option.value.toString()}
                                        label={ReactHtmlParser(option.key)}
                                    />
                                </Form.Group>
                                <Form.Control.Feedback type="invalid">
                                        <div className="invalid-feedback">
                                            {meta.error}
                                        </div>
                                </Form.Control.Feedback>
                            </div>
                        )
                    }
                }
            </Field>
        </div>
    )
}

export default RadioButtonSingle