import React from "react";
import { Link, useHistory } from 'react-router-dom';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Tab, Tabs, Nav, Row, Col } from '@themesberg/react-bootstrap';
import moment from "moment";
import BlobService from "../../services/BlobService";
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@themesberg/react-bootstrap';
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes } from '../../../auth/PermissionsMap';
import AttestationService from '../../services/AttestationService';

export const StorageDeclarationHistory = (props) => {
    const { supplierDeclarations, exporterDeclarations } = props;

    const getSADate = (date) => {
      var newDate = moment(date).format("Do MMM YYYY");
      if (newDate === "Invalid date") {
          return "";
      }
      return newDate;
    }
    const _openBlobPdf = (filename) => {
        BlobService.openBlobPdf(filename);
    }
  
  const getDaysAgo = (date) => {
    var now = moment();
    var newDate = moment(date);
    if (newDate === "Invalid date") {
        return "";
    }
    var dateDiff = now.diff(newDate, "days");
    if (dateDiff === 0) {
        return "today";
    }
    else if (dateDiff === 1) {
        return "yesterday";
    }
    else{
      return + dateDiff + " days ago";
    }
  
  }
  

  
const TableRowSupplier = (props) => {
    const {uniqueCode, id, validFrom, validTo, productJson, signedDocument, batches, createdDate
        //batchNumbers 
    } = props;
    let productJsonArray = JSON.parse(productJson);
    let certificateNumber ='';
    if (Array.isArray(productJsonArray?.productDetail?.certificateNumber)) {
        certificateNumber = productJsonArray?.productDetail?.certificateNumber?.map(x => x).join(", ");
    }
    else  {
        certificateNumber = productJsonArray?.productDetail?.certificateNumber;
    }
    
    return ( 
    <tr>
        <td>
        <div className="list-inline list-group-flush list-group-timeline mb-0">
            
            <h6>{uniqueCode}</h6>
            <div className="list-group-item border-0 px-0">
            <div className="row ps-lg-1">
                <div className="col-auto pl-0">
                    <div className="icon-shape icon-sm icon-shape-primary rounded">
                        <FontAwesomeIcon 
                            icon={faFileSignature} 
                            className={productJsonArray==null? "text-dark" : "text-success"} />
                    </div>
                </div>
                    <div className="col ms-n2">
                        
                        {signedDocument!=null ?(
                            //Storage Declaration Response Completed
                            <>
                            <h3 className="fs-6 fw-bold mb-0">
                                {signedDocument!=null?
                                    (
                                        <a onClick={()=> _openBlobPdf(signedDocument?.filename)} target="_blank">
                                            Supplier Declaration
                                        </a>
                                    )
                                    :(
                                        <>
                                            Supplier Declaration
                                        </>
                                    )
                                }
                            </h3>
                            
                            {createdDate!=null&&
                                <span className="small">
                                     Signed {getDaysAgo(createdDate)} 
                                </span>
                            }
                        </>
                        ):
                        //Storage Declaration Response Incomplete
                        (
                            <div className="" >
                                <h3 className="fs-6 mb-0">
                                Supplier Declaration
                                </h3>
                                <div className="small">Not yet responded</div>
                            </div>
                        )
                    }
                    </div>
                </div>
            </div>
            </div>
            </td>
        <td className="text-center">{productJsonArray==null ? "N/A" : getSADate(validFrom)} </td>
        <td className="text-center">{productJsonArray==null ? "N/A" : getSADate(validTo)} </td>
        <td className="text-center">
            {certificateNumber}
        </td>
    </tr>
    );
};
        

const TableRowExporter = (props) => {
    const {uniqueCode, id, validFrom, validTo, signedDocument, batches, createdDate, storageSupplierDeclaration
        //batchNumbers 
    } = props;
    let productJsonArray = JSON.parse(storageSupplierDeclaration?.productJson);
    let certificateNumber ='';
    if (Array.isArray(productJsonArray?.productDetail?.certificateNumber)) {
        certificateNumber = productJsonArray?.productDetail?.certificateNumber?.map(x => x).join(", ");
    }
    else  {
        certificateNumber = productJsonArray?.productDetail?.certificateNumber;
    }
    
    return ( 
    <tr>
        <td>
        <div className="list-inline list-group-flush list-group-timeline mb-0">
            
            <h6>{uniqueCode}</h6>
            <div className="list-group-item border-0 px-0">
                <div className="row ps-lg-1">
                    <div className="col-auto pl-0">
                        <div className="icon-shape icon-sm icon-shape-primary rounded">
                            <FontAwesomeIcon 
                                icon={faFileSignature} 
                                className={productJsonArray==null? "text-dark" : "text-success"} />
                        </div>
                    </div>
                    <div className="col ms-n2">
                        {signedDocument!=null &&(
                            //Storage Declaration Response Completed
                            <>
                            <h3 className="fs-6 fw-bold mb-0">
                                {signedDocument!=null?
                                    (
                                        <a onClick={()=> _openBlobPdf(signedDocument?.filename)} target="_blank">
                                            Exporter Declaration
                                        </a>
                                    )
                                    :(
                                        <>Exporter Declaration</>
                                    )
                                }
                            </h3>
                            
                            {signedDocument!=null&&
                                <span className="small">
                                    Created {getDaysAgo(createdDate)} 
                                </span>
                            }
                        </>
                        )
                    }
                    </div>
                </div>
            </div>
            </div>
            </td>
        <td className="text-center">
            {batches !== null? batches : "N/A"}
        </td>
        <td className="text-center">
            {certificateNumber}
        </td>
    </tr>
    );
};


return (
    <>

    {supplierDeclarations.length <= 0 && exporterDeclarations.length <= 0 ?(
        <div className="text-center pt-5">
            <h5 className="text-gray-600">No storage declarations have been created or requested yet</h5>
        </div>
    )
    :
    (

        // Tabbed tables for Supplier / Exporter declarations
        <Tab.Container defaultActiveKey="first" className="mt-0">
        {/* Tabbed Menu */}
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="px-2">
              <Nav.Item>
                <Nav.Link eventKey="first">Supplier Declarations</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Exporter Declarations</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
            {/* Supplier Declaration Table */}
              <Tab.Pane eventKey="first">
                <Table responsive className="align-items-center table-flush">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col">Unique Code</th>
                        <th scope="col" className="text-center">Valid from</th>
                        <th scope="col" className="text-center">Valid to</th>
                        <th scope="col" className="text-center">Certificate</th>
                    </tr>
                    </thead>
                    <tbody>
                        {supplierDeclarations.map(pv => 
                            <TableRowSupplier 
                                key={`supplier-declarations-history-${pv.id}`} 
                                {...pv} 
                            />)
                        }
                    </tbody>
                </Table>

              </Tab.Pane>
              {/* Exporter Declaration Table */}
              <Tab.Pane eventKey="second">
                <Table responsive className="align-items-center table-flush">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col">Unique Code</th>
                        <th scope="col" className="text-center">Batches</th>
                        <th scope="col" className="text-center">Certificate</th>
                    </tr>
                    </thead>
                    <tbody>
                        {exporterDeclarations.map(pv => 
                        <TableRowExporter 
                            key={`exporter-declarations-history-${pv.id}`} 
                            {...pv} 
                        />)
                    }
                    </tbody>
                </Table>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>




    
)
    }

    </>
)}


            
export default StorageDeclarationHistory;