import *  as baseService  from "./BaseService";

const downloadPack = (consignmentId) => {
    return baseService.getApiData("/EhcCompleted/GetCertificatePackFilename?consignmentId=" + consignmentId);
};
const printPack = (consignmentId) => {
    return baseService.getApiData("/EhcCompleted/GetCertificatePackForPrint?consignmentId=" + consignmentId);
};
const populateCertficateCompleted = (consignmentContentsId, certificateId, languageCode) => {
    return baseService.postApiData("/EhcCompleted/PopulateCertficateCompleted?consignmentContentsId=" + consignmentContentsId+ "&certificateId=" + certificateId + "&languageCode=" + languageCode, null);
};
const generateCompletedCompoundCertificate = (consignmentContentsId, languageCode) => {
    return baseService.postApiData("/EhcCompleted/GenerateCompletedCompoundCertificate?consignmentContentsId=" + consignmentContentsId + "&languageCode=" + languageCode, null);
};

 const EhcCompletedService = {
    populateCertficateCompleted,
    downloadPack,
    printPack,
    generateCompletedCompoundCertificate,
};

export default EhcCompletedService;