import React, { useState, useEffect, useMemo, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
import { Col,Row, Pagination} from '@themesberg/react-bootstrap';
import Select from "react-select";

const Paging = (props) => {  
    const { activePage, loaded = false, size, pageSize, totalPages, onNextItem, onPrevItem, disablePrev = false, withIcons, handleChange, onPageNumberClick, totalItems, ...rest } = props

    const [pages, setPages] = useState([]);

    const getPaging = () => {
        let items = [];
        console.info("paging" + props.totalPages);
        var total = totalPages;
        var maxPageButtons = 10
        for (let number = 1; number <= total; number++) 
        {
            var isToDisplay = true;
            const isItemActive = activePage == number;
            if(total > maxPageButtons){
                if((activePage + (maxPageButtons / 2) < number) || ((activePage - (maxPageButtons / 2) > number))){
                    isToDisplay = false;
                }
            }
            if(isToDisplay){
                items.push(
                    <Pagination.Item active={isItemActive} key={number}  onClick={() => {onPageNumberClick(number)}}>
                        {number}
                    </Pagination.Item>
                );
            }
        };
        setPages(items);
    }

    const pageSizes = [
        { value: '5', label: 5 },
        { value: '10', label: 10 },
        { value: '25', label: 25 },
        { value: '100', label: 100 }
    ]

    useEffect(() => {
        getPaging();
    },[totalPages, activePage]);
    
    return (

        <Row className="mt-3" hidden={totalItems==0} >
            <Col lg={5}>
                <Pagination size={size}>
                    <Pagination.Prev disabled={activePage==1} onClick={onPrevItem}>
                        {withIcons ? <FontAwesomeIcon icon={faAngleDoubleLeft} /> : "Previous"}
                    </Pagination.Prev>
                    {pages}
                    
                    <Pagination.Next disabled={totalPages===activePage}  onClick={() => onNextItem(totalPages)}>
                        {withIcons ? <FontAwesomeIcon icon={faAngleDoubleRight} /> : "Next"}
                    </Pagination.Next>
                </Pagination>
            </Col>
            <Col lg={3} className="d-flex align-items-center justify-content-end">
            <small className="fw-bold-inline justify-content-end mx-3">
                    Found <b>{totalItems}</b> entries
                </small>
            </Col>
            <Col lg={4} className="d-flex align-items-center justify-content-end">
            <small className="fw-bold-inline justify-content-end mx-3">
                    Items per page:
                </small>
                
                <Select className="page-drop-down" size="sm" options={pageSizes} placeholder={pageSize} value={pageSize} onChange={handleChange}/>
            </Col>
        </Row>
    )
}

export default Paging