import *  as baseService  from "./BaseService";

const getByProductId = (id) => {
    return baseService.getApiData("/productcomposite/getByProductId?id=" + id);
};

const add = (data) => {
    return baseService.postApiData("/productcomposite/Create", data);
};

const update = (id, data) => {
    return baseService.putApiData("/productcomposite/update?id=" + id, data);
};

const CompositeService = {
    add,
    getByProductId,
    update
};

export default CompositeService;