import React, { useState, useEffect } from 'react';
import { Routes } from "../../../routes";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import FormController from "../common/FormController"
import { Formik, Form } from 'formik';
import ManufacturerDataService from "../../services/ManufacturerService";
import { Button, Card, Col } from "@themesberg/react-bootstrap";
import FormSubmission from "../common/FormSubmission";
import * as Yup from 'yup';
import ActivityService from '../../services/ActivityService';

const ManufacturerAddPage = (props) => {
    
    const [isSubmitting,  setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [ready, setReady] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [manufacturerName, setManufacturerName] = useState('');
    const [manufacturerId, setManufacturerId] = useState(props.match.params.id);
    const [apiValues, setApiValues] = useState([]);
    const [title, setTitle] = useState("Add Manufacturer");
   
    const breadcrumbData = {
        item1 : {name:'Manufacturers', link: Routes.ManufacturersListingPage.path},
        item2 : {name: editing? apiValues.manufacturerName : ('Add Manufacturer'), link:  editing? Routes.ManufacturersListingPage.path.replace(":id",manufacturerId) : '#'},
        item3 : {name: editing? ('Edit Manufacturer') : null, link: '#'},
    };

    const initialValues = {
        manufacturerName: apiValues?.manufacturerName?? '',
        supplierCode: apiValues?.supplierCode?? '',
        address1: apiValues?.address1?? '',
        address2: apiValues?.address2?? '',
        townCity: apiValues?.townCity?? '',
        postcode: apiValues?.postcode?? '',
    }

    let validationMaxLength = "Exceeded maximum length allowed for this field";

    const validationSchema = Yup.object().shape({
        supplierCode: Yup.string().required("Must enter supplier code").max(50, validationMaxLength),
        manufacturerName: Yup.string().required("Must enter an manufacturer name").max(100, validationMaxLength),
        address1: Yup.string().required("Must enter an address line 1").max(150, validationMaxLength),
        address2: Yup.string().max(150, validationMaxLength),
        townCity: Yup.string().required("Must enter a town or city").max(50, validationMaxLength),
        postcode: Yup.string().required("Must enter a postcode").max(10, validationMaxLength),
    });
    
    useEffect(() => {
        if(ready){
            ManufacturerDataService.getById(manufacturerId)
                .then(response => {
            var res = response.data;
            setManufacturerName(res.manufacturerName);
            setApiValues(res);
        })
        .catch(e => {
            console.log(e);
        });
        if(manufacturerId!=null){
            setTitle("Edit Product");
            setEditing(true);
        }
        }
    },[ ready]);

    function onSubmit(values, { resetForm } ) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            if(!editing){
                ManufacturerDataService.create(submitData)
                    .then(async response => {
                        //Create Activity Log
                        await ActivityService.log(
                            'Added a manufacturer',
                            'A manufacturer named ' + values.manufacturerName + ' has been added',
                            'Manufacturer', 
                            'Create',
                            Routes.ManufacturerViewPage.path.replace(":id",response.data.id),
                            response.data.id
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            else{
                ManufacturerDataService.update(manufacturerId, submitData)
                    .then(async response => {
                        await ActivityService.log(
                            'Edited a manufacturer',
                            'A manufacturer named ' + values.manufacturerName + ' has been edited',
                            'Manufacturer', 
                            'Update',
                            Routes.ManufacturerViewPage.path.replace(":id",response.data.id),
                            response.data.id
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            setFormSubmitted(true);
        }, 1000);
    }
    
    const PrettyPrintJson = ({data}) => (<div><pre>{
        JSON.stringify(data, null, 2) }</pre></div>);
        
    useEffect(() => {
        if(manufacturerId!=null){
            setTitle("Edit Manufacturer");
            setEditing(true);
        }
    },[]);

    return (
        <div>   
            <BreadcrumbNav data={breadcrumbData}/>
            <h2>{title}</h2>

            {formSubmitted ? (
                <FormSubmission
                    errors={errors}
                    title ="Added Manufacturer"
                    message = "This manufacturer has been successfully added to the site." 
                    successButtonUrl = {Routes.ManufacturersListingPage.path}
                    successButtonName = "Back to Manufacturers"/>
            ) 
            : 
            (
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize="true"
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                {(props) => (

                    <Col md={8}>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Body>
                                <Form>
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Manufacturer Name"
                                        name="manufacturerName"
                                        isrequired="true"
                                        col={8}
                                    />
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Supplier code"
                                        name="supplierCode"
                                        isrequired="true"
                                        col={6}
                                    />
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Address line 1"
                                        name="address1"
                                        isrequired="true"
                                        col={6}
                                    />
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Address line 2"
                                        name="address2"
                                        col={6}
                                    />
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Town / City"
                                        name="townCity"
                                        isrequired="true"
                                        col={6}
                                    />
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Postcode"
                                        name="postcode"
                                        isrequired="true"
                                        col={6}
                                    />
                                
                                    <div  className="d-none">
                                        <PrettyPrintJson data={ props.values } />
                                    </div>

                                        {/*////// SUBMIT /////////*/}
                                    <div className="form-group mt-3">
                                        <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                    </div>

                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Formik >
            )}
        </div>
    );
};

export default ManufacturerAddPage;