import React from "react"
import { useField, useFormikContext } from "formik"
import { Col, Row, Form } from "@themesberg/react-bootstrap";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.min.css'
import moment from "moment"

const DateInput = (props) => {
     const { name, label, type,readOnly, dateFormat="dd MMMM yyyy", isrequired, col = 12, size, dateValues, selected, onChange, ...rest } = props
    
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const timezone = "Europe/London";
  field.timezone=timezone;

  function handleDateChange(date) {
    if (date && moment(date).isValid()) {
      setFieldValue(field.name, moment(date).format("YYYY-MM-DD"));
    } else {
      // Handle invalid or empty date
      setFieldValue(field.name, '');
    }
  }

function formatDate(momentDate) {        
  return moment(momentDate).format("YYYY-MM-DD");
}
    return (
      <Form.Group controlId={`validation${name}`}>
        {(label!=="" && label!== null && label!== undefined) &&
          <Form.Label className={(isrequired=="true"?"required":"")}>
            {label}
          </Form.Label>
        }
        <Row>
          <Col md={col}>
              <DatePicker 
                  {...field}
                  {...rest}
                 // showTimeSelect={showTime}
                  disabled={readOnly}
                  selected={(field.value && new Date(field.value)) || null}
                  isInvalid={!!meta.error && meta.touched}
                  onChange={handleDateChange}
                  value={(dateFromValue) => {setFieldValue(field.name, formatDate(dateFromValue))}}
                  dateFormat={dateFormat}
                  className={(meta.touched && meta.error) ? `form-control-${size} form-control is-invalid` : `form-control-${size} form-control`}
                  name={name}
                  autoComplete='off'
              />
          </Col>
        </Row>
          {/* /TODO not working well with datepicker */}
        {meta.touched && meta.error ? (
          <div className="d-block invalid-feedback">{meta.error}</div>
        ) : null}
      </Form.Group>
    )
}
export default DateInput