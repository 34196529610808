import *  as baseService  from "./BaseService";

const create = (data) => {
    return baseService.postApiData("/DailyAttestation/create", data);
};
const getAllByProduct = (id) => {
    return baseService.getApiData("/DailyAttestation/GetAllByProductId?id=" + id);
}
const getById = (id) => {
    return baseService.getApiData("/DailyAttestation/getById?id=" + id);
};
const getAllPagedByUserId = (params) => {
    return baseService.getApiDataWithParams("/DailyAttestation/GetAllPagedByUserId", params);
};
const generateUniqueReferenceNumber = (manufacturerId) => {
    return baseService.getApiData("/DailyAttestation/generateUniqueReferenceNumber?manufacturerId=" + manufacturerId);
};
const DailyAttestationService = {
    getAllByProduct,
    getById,
    create,
    getAllPagedByUserId,
    generateUniqueReferenceNumber
};

export default DailyAttestationService;