import React, { useEffect, useState } from 'react';
import { Card, Container, Breadcrumb, Button, ButtonGroup, Badge, Col, Row, Image } from "@themesberg/react-bootstrap";
import { userPermissions, userrPermissionsToAccess, getUserTenantMemberships, getUserRoles, getUserInfo} from "../../../auth/authorisation";
import { bearerToken, getTenantName} from  "../../../auth/authProvider";

const UserAdminPage  = (props) => {
    
	const [tenants, setTenants] = useState([]);
	const [roles, setRoles] = useState([]);
	const [token, setToken] = useState('');
  
    const [user, setUser] = useState({});

    async function setTokenData() {
        setToken(await bearerToken());
    }

    useEffect(() => {
        getUserTenantMemberships()
            .then(x => {
                setTenants(x);
            });
            
            setTokenData(); 
        
       getUserInfo()
       .then(x => {
        setUser(x);
       });

        let getroles = getUserRoles();
        setRoles(getroles);
        
      
        },[]);

    
    return (
        <div>
            
                <Row className="">
                    <Col md={12}>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                                <Row className="align-items-center">
                                    <Col>
                                        <h4 className="mb-0">User Details</h4>
                                    </Col>
                                    <Col className="text-end" md={2}>
                                        
                                    </Col>
                                </Row>
                            </Card.Header>

                            <Card.Body>
                                <ul className="list-unstyled">
                                    <li>
                                        <strong>Id:</strong>
                                        <p>{user.id!==null?(user.id):("N/A")}</p>
                                    </li>
                                    <li>
                                        <strong>Name:</strong>
                                        <p>{user.fullName!==null?(user.fullName):("N/A")}</p>
                                    </li>
                                    <li>
                                        <strong>Email:</strong>
                                        <p>{user.email!==null?(user.email):("N/A")}</p>
                                    </li>
                                    <li>
                                        <strong>Tenant Name:</strong>
                                        <p>{user.tenantName!==null?(user.tenantName):("N/A")}</p>
                                    </li>
                                    <li>
                                        <strong>Job Title:</strong>
                                        <p>{user.jobTitle!==null?(user.jobTitle):("N/A")}</p>
                                    </li>
                                    <li>
                                        <strong>RCVS:</strong>
                                        <p>{user.rcvs!==null?(user.rcvs):("N/A")}</p>
                                    </li>
                                    <li>
                                        <strong>Practice Name:</strong>
                                        <p>{user.practiceName!==null?(user.practiceName):("N/A")}</p>
                                    </li>
                                    <li>
                                        <strong>Address:</strong>
                                        <p>{user.tenantName!==null?(user.addressline1):("N/A")}</p>
                                        <p>{user.tenantName!==null?(user.city):("N/A")}</p>
                                        <p>{user.tenantName!==null?(user.postcode):("N/A")}</p>
                                    </li>
                                    
                                    <li>
                                        <strong>Manufacturer Id:</strong>
                                        <p>{user.manufacturerId!==null?(JSON.stringify(user.manufacturerId)):("N/A")}</p>

                                    </li>
                                    <li>
                                        <strong>Roles:</strong>
                                        <p>{roles!==null?(JSON.stringify(roles)):("N/A")}</p>
                                    </li>
                                    <li>
                                        <strong>Tenant Memberships:</strong>
                                        <p>{tenants!==null?(JSON.stringify(tenants)):("N/A")}</p>
                                    </li>
                                    <li>
                                        <strong>User Permissions:</strong>
                                    </li>
                                    <li>
                                        <strong>Exp:</strong>
                                        <p>{user.exp!==null?(user.exp):("N/A")}</p>
                                    </li>
                                    <li>
                                        <hr />
                                    </li>
                                    <li>
                                        <strong>User raw:</strong>
                                        <br />
                                        <i>{JSON.stringify(user)}</i>
                                    </li>
                                    <li>
                                        <strong>Access token:</strong>
                                        <br />
                                        <i>{`Bearer ${token}`}</i>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                
        </div>
    
    )

}
export default UserAdminPage;