import *  as baseService  from "./BaseService";

const getAll = () => {
    return baseService.getApiData("/CertificateLanguage/getall");
};

const CertificateLanguageService = {
    getAll,
};

export default CertificateLanguageService;