import *  as baseService  from "./BaseService";

const getAll = () => {
    return baseService.getApiData("/BorderControlPost/GetAll");
};

const getById = (id) => {
    return baseService.getApiData("/BorderControlPost/GetById?id=" + id);
};

const getByCode = (code) => {
    return baseService.getApiData("/BorderControlPost/GetByCode?code=" + code);
};

const BorderControlPostService = {
    getAll,
    getById,
    getByCode
};

export default BorderControlPostService;