import React, { useState, useEffect } from 'react';
import { Routes } from "../../../routes";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Formik, Form } from 'formik';
import FormController from "../common/FormController"
import PlatformDataService from "../../services/PlatformService";
import { MultiSelectField } from "../common/MultiSelect";
import EstablishmentDataService from "../../services/EstablishmentTypeService";
import FormSubmission from "../common/FormSubmission";
import { Button,Card, Col } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import ActivityService from '../../services/ActivityService';

const PlatformAddPage = (props) => {
    
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isSubmitting,  setSubmitting] = useState(false);
    const [ready, SetReady] = useState(false);
    const [apiValues, setApiValues] = useState([]);
    const [platformName, setPlatformName] = useState('');
    const [platformId, setPlatformId] = useState(props.match.params.id);
    const [title, setTitle] = useState("Add Platform");

    const breadcrumbData = {
        item1 : {name:'Platforms', link: Routes.ManufacturersListingPage.path},
        item2 : {name: editing? platformName : ('Add Platform'), link:  editing? Routes.Platforms.path.replace(":id",platformId) : '#'},
        item3 : {name: editing? ('Edit Platform') : null, link: '#'},
    };
    
    const [establishmentNumberTypes, SetEstablishmentNumberTypes] = useState([]);

    const initialValues = {
        platformName: apiValues?.platformName?? '',
        address1: apiValues?.address1?? '',
        address2: apiValues?.address2?? '',
        townCity: apiValues?.townCity?? '',
        postcode: apiValues?.postcode?? '',
        establishmentType: apiValues?.establishmentNumberType?? [],
        establishmentNumber: apiValues?.establishmentNumber?? '',
    }

    let validationMaxLength = "Exceeded maximum length allowed for this field";

    const validationSchema = Yup.object().shape({
        platformName: Yup.string().required("Must enter platform name").max(50, validationMaxLength),
        address1: Yup.string().required("Must enter an address line").max(150, validationMaxLength),
        address2: Yup.string().max(150, validationMaxLength),
        townCity: Yup.string().required("Must enter a town or city").max(50, validationMaxLength),
        postcode: Yup.string().required("Must enter a postcode").max(10, validationMaxLength),
        establishmentType: Yup.object().nullable().required("Must choose an approval or registration type"),
        establishmentNumber: Yup.string().required("Must choose a approval or registration number").max(50, validationMaxLength),
    });
    
    function onSubmit(values, { resetForm } ) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            submitData.establishmentNumberTypeId =  values.establishmentType.value

            if(!editing){
                PlatformDataService.create(submitData)
                    .then(async response => {
                        //Create Activity Log
                        await ActivityService.log(
                            'Added a platform',
                            'A platform named ' + values.platformName + ' has been added',
                            'Platform', 
                            'Create',
                            Routes.PlatformViewPage.path.replace(":id",response.data.id),
                            response.data.id
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            else{
                PlatformDataService.update(platformId, submitData)
                    .then(async response => {
                        //Create Activity Log
                        await ActivityService.log(
                            'Edited a platform',
                            'A platform named ' + values.platformName + ' has been edited',
                            'Platform', 
                            'Update',
                            Routes.PlatformViewPage.path.replace(":id",response.data.id),
                            response.data.id
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            setFormSubmitted(true);
        }, 1000);
    }
    
    const PrettyPrintJson = ({data}) => (<div><pre>{
        JSON.stringify(data, null, 2) }</pre></div>);
        
    useEffect(() => {
        getEstablishmentNumberTypeData();
    },[]); 


    useEffect(() => {
        if(ready){
            if(platformId!=null){
                setTitle("Edit Platform");
                setEditing(true);
                PlatformDataService.getById(platformId)
                    .then(response => {
                        var res = response.data;
                        setPlatformName(res.platformName);
                        if(res.establishmentNumberTypeId!=="" && establishmentNumberTypes!==""){
                            const establishmentNumberType = establishmentNumberTypes.find(x => x.value === res.establishmentNumberTypeId);
                            res.establishmentNumberType = establishmentNumberType;
                        }
                        setApiValues(res);
                });
    
            }
        }
    },[ready]);

    async function getEstablishmentNumberTypeData(){
        EstablishmentDataService.getAll()
        .then((response) => {
            console.log("EstablishmentNumberType | GetAll | Data: "+ JSON.stringify(response.data));
            const options = response.data.map(d => ({
                "value": d.id,
                "label": d.typeName
            }))
            SetEstablishmentNumberTypes(options);
            SetReady(true);
        })
        .catch((e) => {
            console.log(e);
        });
    }

    return (
    <div>
        <BreadcrumbNav data={breadcrumbData}/>
        <h2>{title}</h2>
        {formSubmitted ? (
            <FormSubmission
                errors={errors}
                title ="Added Platform"
                message = "This platform has been successfully added to the site." 
                successButtonUrl = {Routes.Platforms.path}
                successButtonName = "Back to Platforms"
            />
        ) 
        : 
        (
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize="true"
                validateOnChange={false}
                validateOnBlur={false}
            >
                {(props) => (
                    <Col md={8}>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Body>
                                <Form>
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Platform Name"
                                        name="platformName"
                                        isrequired="true"
                                        col={8}
                                    />
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Address line 1"
                                        name="address1"
                                        isrequired="true"
                                        col={6}
                                    />
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Address line 2"
                                        name="address2"
                                        col={6}
                                    />
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Town / City"
                                        name="townCity"
                                        isrequired="true"
                                        col={6}
                                    />
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Postcode"
                                        name="postcode"
                                        isrequired="true"
                                        col={4}
                                    />
                                    <MultiSelectField
                                        id="establishmentType"
                                        name="establishmentType"
                                        label="Type of Approval / Registration Number"
                                        placeholder="Select type of approval number"
                                        options={establishmentNumberTypes}
                                        isMulti={false}
                                        isClearable={true}
                                        onChange={props.setFieldValue}
                                        onBlur={props.setFieldTouched}
                                        touched={props.touched.establishmentType}
                                        error={props.errors.establishmentType}
                                        backspaceRemovesValue={true}
                                        isrequired="true"
                                        col={6}
                                    />
                                    {props.values.establishmentType!= '' &&(
                                        <FormController
                                            control="input"
                                            type="text"
                                            label={props.values.establishmentType.label}
                                            name="establishmentNumber"
                                            isrequired="true"
                                            col={6}
                                        />
                                    )}
                                
                                
                                    <div  className="d-none">
                                        <PrettyPrintJson data={ props.values } />
                                    </div>

                                        {/*////// SUBMIT /////////*/}
                                    <div className="form-group mt-3">
                                        <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                    </div>

                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Formik >
            )}
        </div>
    );
};

export default PlatformAddPage;