
import * as Meat8384Labels from "../Meat8384Labels"
import { findArrayElementByFieldNameAndValue, findArrayElementByValue } from "../../../Attestations/Common/AttestationFunctions";
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export const AttestationContent = (props) => {
    const { attestationData, setFieldValue, isReadOnly } = props;

    return (
        attestationData !== undefined &&
        <>
            <h5>II. Health Attestation</h5>

            <h6>II.1 Public Health Attestation</h6>
            <p>
                I, the undersigned declare that I am aware of the relevant requirements of Regulation (EU) 2019/6 of the European Parliament and of
                the Council and Commission Delegated Regulation (EU) 2023/905 and hereby certify that the meat products, including rendered animal
                fats and greaves, meat extracts and treated stomachs, bladders and intestines others than casings described in Part I were produced
                in accordance with these requirements, and in particular, that the animals from which the meat is derived have not been administered
                antimicrobial medicinal products for growth promotion or yield increase or antimicrobial medicinal products containing an antimicrobial
                that is included in the list of antimicrobials reserved for the treatment of certain infections in humans laid down in Commission Implementing
                Regulation (EU) 2022/1255 as set out in Article 3 of Delegated Regulation (EU) 2023/905 and originate from a third country or region thereof
                listed in accordance with Article 5(2) of Delegated Regulation (EU) 2023/905.
            </p>
            <ol className="attestation-II-1">
                <li>
                    they come from (an) establishment(s) applying general hygiene requirements and implementing a programme based on the hazard analysis and critical
                    control points (HACCP) principles in accordance with Article 5 of Regulation (EC) No 852/2004, regularly audited by the competent authorities, and
                    being listed as an EU approved establishment;
                </li>
                <li>
                    {attestationData.health_1_2_1 == true && (<span>{findArrayElementByValue(Meat8384Labels.health_1_2, 1)}</span>)}
                    <br />
                    {attestationData.health_1_2_2 == true && (<span>{findArrayElementByValue(Meat8384Labels.health_1_2, 2)}</span>)}
                </li>
                <li>
                    they have been produced from raw material which met the requirements of Sections I to VI of Annex Ill to Regulation (EC) No 853/2004;
                </li>
                <li>
                    they have been marked with an identification mark in accordance with Section I of Annex II to Regulation (EC) No 853/2004;
                </li>
                <li>
                    the label(s) affixed on the packaging of meat products described in Part I, bear(s) an identification mark to the effect that the meat products come wholly from fresh meat from
                    establishments (slaughterhouses, game handling establishment and cutting plants) approved for the entry into the Union;
                </li>
                <li>
                    they satisfy the relevant criteria laid down in Commission Regulation (EC) No 2073/2005;
                </li>
                <li>
                    the guarantees covering live animals and products thereof provided by the control plan submitted
                    in accordance with Article 6(2) of Commission Delegated Regulation (EU) 2022/2292 are
                    fulfilled and the concerned animals and products are listed in Annex -I to Commission
                    Implementing Regulation (EU) 2021/405 for the concerned third country or territory;
                </li>
                <li>
                    the means of transport and the loading conditions of the meat products of this consignment meet
                    the hygiene requirements laid down as regards the entry into the Union;
                </li>
            <li>
            <ol className="attestation-II-1-9">
                {attestationData.health_1_4_1 == true ? (
                    <li>
                        if obtained from meat of <strong><u>domestic porcine animals</u></strong>, this meat fulfills the requirements of Commission Implementing Regulation (EU) 2015/1375D, and
                        in particular:
                        <ul>
                            {attestationData.health_1_4_1_1 == true &&
                                <li>
                                    <span>{findArrayElementByValue(Meat8384Labels.health_1_4_1, 1)}</span>
                                </li>
                            }
                            {attestationData.health_1_4_1_2 == true &&
                                <li>
                                    <span>{findArrayElementByValue(Meat8384Labels.health_1_4_1, 2)}</span>
                                </li>
                            }
                            {attestationData.health_1_4_1_3 == true &&
                                <li>
                                    <span>{findArrayElementByValue(Meat8384Labels.health_1_4_1, 3)}</span>
                                </li>
                            }
                        </ul>
                    </li>
                ) :
                    (
                        <li>N/A</li>
                    )}

                {attestationData.health_1_4_2 == true ? (
                    <li>
                        if obtained from meat of <strong><u>solipeds or wild boar</u></strong>, this meat fulfils the requirements of Implementing Regulation (EU) 2015/1375, and in particular, has
                        been subject to an examination by a digestion method for Trichinella with negative results;
                    </li>
                )
                    :
                    (
                        <li>N/A</li>
                    )}


                {attestationData.health_1_4_3 == true ? (
                    <li>the treated stomachs, bladders and intestines and meat extracts have been produced in accordance with Section XIII of Annex III to Regulation (EC) No 853/2004.</li>
                )
                    :
                    (
                        <li>N/A</li>
                    )}



                {attestationData.health_1_4_4 == true ? (
                    <li>the rendered animal fats and greaves have been produced in accordance with Section XII of Annex III to Regulation (EC) No 853/2004.</li>
                )
                    :
                    (
                        <li>N/A</li>
                    )}
            </ol >
            </li>

                {attestationData.bseRiskCategoryNegligible || attestationData.bseRiskCategoryControlled || attestationData.bseRiskCategoryUndetermined ? (
                    <>
                        <li>if containing material from bovine, ovine or caprine animals, with regard to bovine spongiform encephalopathy (BSE):
                            {attestationData.bseRiskCategoryNegligible && (
                                <ul>
                                    <li>the country or region of origin is classified in accordance with Commission Decision
                                        2007/453/EC as a country or region posing a <u>negligible BSE risk</u>, and

                                        <ul>
                                            {attestationData.bseRiskCategoryNegligibleStatements.map(statement =>
                                                <li>
                                                    {findArrayElementByValue(Meat8384Labels.BseRiskCategoryNegligibleStatementsChoice, statement)}
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                </ul>
                            )}
                            {/* BSE Controlled */}
                            {attestationData.bseRiskCategoryControlled &&
                                <ul>
                                    <li>the country or region of origin is classified in accordance with Decision 2007/453/EC
                                        as a country or region posing a <u>controlled BSE risk</u> , and

                                        <ul>
                                            <li>
                                                {findArrayElementByValue(
                                                    Meat8384Labels.BseRiskCategoryControlledStatementsChoice, 1)
                                                }
                                            </li>
                                            <li>
                                                {findArrayElementByValue(
                                                    Meat8384Labels.BseRiskCategoryControlledStatementsChoice, 2)
                                                }
                                            </li>
                                            {attestationData.bseRiskCategoryControlledStatements > 0 &&
                                                <li>
                                                    {findArrayElementByValue(
                                                        Meat8384Labels.BseRiskCategoryControlledStatementsChoice,
                                                        attestationData.bseRiskCategoryControlledStatements)
                                                    }


                                                </li>
                                            }
                                        </ul>
                                    </li>
                                </ul>
                            }
                            {/* BSE Undetermined */}
                            {attestationData.bseRiskCategoryUndetermined &&
                                <ul>
                                    <li>the country or region of origin has not been classified in accordance with Decision 2007/453/EC or is classified as a country or region with an <u>undetermined BSE risk</u>, and
                                        <ul>
                                            <li>
                                                {findArrayElementByValue(Meat8384Labels.BseRiskCategoryUndeterminedStatementsChoice, 1)}
                                            </li>
                                            <li>
                                                {findArrayElementByValue(Meat8384Labels.BseRiskCategoryUndeterminedStatementsChoice, 2)}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            }
                        </li>
                    </>
                ) :
                    (
                        <li>N/A</li>
                    )}

                {attestationData.health_1_12 ? (
                    <li>
                        if containing material from <strong><u>domestic solipeds</u></strong>, the fresh meat used in the preparation of the meat products:
                        <ul>
                            {attestationData.health_1_12_1 == true &&
                                <li>
                                    <span>{findArrayElementByValue(Meat8384Labels.health_1_12_A, 1)}</span>
                                </li>
                            }
                            {attestationData.health_1_12_2 == true &&
                                <li>
                                    <span>{findArrayElementByValue(Meat8384Labels.health_1_12_A, 2)}</span>
                                </li>
                            }
                        </ul>
                    </li>
                ) :
                    (
                        <li>N/A</li>
                    )}
                {attestationData.health_1_13 ? (
                    <li>{ReactHtmlParser(Meat8384Labels.health_1_13.label)}</li>
                ) :
                    (
                        <li>N/A</li>
                    )}
                {attestationData.health_1_14 ? (
                    <li>{ReactHtmlParser(Meat8384Labels.health_1_14.label)}</li>
                ) :
                    (
                        <li>N/A</li>
                    )}
            </ol>

            <h6>II.2 Animal health attestation</h6>
            <p>The <strong>meat product</strong>, including rendered animal fats and greaves, meat extracts and treated stomachs, bladders and intestines others than casings, described in Section A.2</p>
            <ol type="1" className="attestation-II-2">
                <li>
                    has been processed in and dispatched from the <strong>zone</strong> with code: <strong><u>{attestationData.animal_2_1_zone?.map(x => x.isoCode).join(", ")}</u></strong>, which, at the date of issue of this support attestation, is:
                    <ol type="a">
                        <li>
                            authorised for entry into the Union of fresh meat of the species of animals from which the meat product described in Section A.2 has been processed and listed in.<br />
                            {attestationData.animal_2_1_a_1 &&
                                <ul>
                                    <li>
                                        {findArrayElementByFieldNameAndValue(Meat8384Labels.animal_2_1_a, 'animal_2_1_a', 1)}
                                    </li>
                                </ul>
                            }
                            {attestationData.animal_2_1_a_2 &&
                                <ul>
                                    <li>
                                        {findArrayElementByFieldNameAndValue(Meat8384Labels.animal_2_1_a, 'animal_2_1_a', 2)}
                                    </li>
                                </ul>
                            }
                        </li>
                        <li>and listed in Part 1 of Annex XV to Implementing Regulation (EU) 2021/404 for entry into the Union of the meat products described in Section A.2 under the non-specific treatment "A";</li>
                    </ol>
                </li>
                <li>
                    has been processed from fresh meat from the species of animals with code/s: <strong><u>{attestationData?.speciesList?.map(x => x.speciesGroupName).join(", ")}</u></strong>
                </li>
                <li>
                    has been processed from fresh meat that has undergone a non-specific treatment;
                </li>
                <li>
                    has been processed from fresh meat that complied with all the relevant requirements for entry into the Union of fresh meat laid down in Commission
                    Delegated Regulation (EU) 2020/692 and, therefore, was eligible for entry into the Union as such and was obtained from animals that complied with the
                    residency period in an establishment located in
                    <ul>
                        {attestationData.animal_2_4_1 == true &&
                            <li>
                                the zone referred to in point II.2.1
                            </li>
                        }
                        {attestationData.animal_2_4_2 == true &&
                            <li>
                                <>the zone/s with code/s <strong><u>{attestationData.animal_2_4_2_zone.map(x => x.isoCode).join(", ")}</u></strong> which, at the date of issue of this support attestation is/are authorised for the entry into the
                                    Union of fresh meat of the species from which the meat product has been processed and listed in

                                    {attestationData.animal_2_4_2_1 == true && (
                                        <ul>
                                            <li>
                                                {findArrayElementByFieldNameAndValue(Meat8384Labels.animal_2_4_2, 'animal_2_4_2', 1)}
                                            </li>
                                        </ul>
                                    )}
                                    {attestationData.animal_2_4_2_2 == true && (
                                        <ul>
                                            <li>
                                                {findArrayElementByFieldNameAndValue(Meat8384Labels.animal_2_4_2, 'animal_2_4_2', 2)}
                                            </li>
                                        </ul>
                                    )}

                                </>
                            </li>
                        }
                        {attestationData.animal_2_4_3 == true &&
                            <li>a Member State</li>
                        }
                    </ul>
                </li>

                {(attestationData.animal_2_5_1 || attestationData.animal_2_5_2) ? (
                    <li>
                        has been processed from fresh meat obtained from:
                        <ul>
                            <li>
                                {attestationData.animal_2_5_1 == true &&
                                    findArrayElementByValue(Meat8384Labels.animal_2_5, "1")
                                }
                                {attestationData.animal_2_5_2 == true &&
                                    findArrayElementByValue(Meat8384Labels.animal_2_5, "2")
                                }
                            </li>
                        </ul>
                    </li>
                ) : (
                    <li>N/A</li>
                )}
                <li>
                    after processing has been handled until packaging in a way to prevent cross contamination that could introduce an animal health risk;
                </li>
                {attestationData.animal_2_7 == true ? (
                    <li>is intended for a Member State which has been granted the status free from infection with Newcastle disease virus without vaccination in accordance with Commission Delegated Regulation (EU) 2020/689, and has been obtained from poultry which have not been vaccinated against infection with Newcastle disease virus with a live vaccine during the period of 30 days prior to the date of slaughter</li>
                ) :
                    (
                        <li>N/A</li>
                    )}
            </ol>

            <h6>II.3 Animal welfare attestation</h6>

            <p>I, the undersigned, hereby certify, that the meat products described in Section A.2 derive from animals which have been treated in the slaughterhouse
                in accordance with the requirements of the Union legislation on the protection of animals at the time of killing or at least equivalent requirements.</p>
        </>
    )

}

