import { faCheck, faEllipsisH, faEye, faFileContract, faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Col, Dropdown, Row, Table } from '@themesberg/react-bootstrap';
import { Form, Formik } from 'formik';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import PermissionsCheck from '../../../auth/PermissionCheck';
import FormController from "../../../pages/components/common/FormController";
import Paging from "../../../pages/components/common/Paging";
import BlobService from "../../../pages/services/BlobService";
import DailyAttestationService from "../../../pages/services/DailyAttestationService";
import ManufacturerService from "../../../pages/services/ManufacturerService";
import { Routes } from "../../../routes";
import Preloader from "../../Preloader";
import { NoResults } from "../NoResults";
import EllipsisTooltip from './../../EllipsisTooltip';
  
export default function Td({ children, to, id, colSpan, className}, ...rest) {
  // Conditionally wrapping content into a link
  const ContentTag = to ? Link : 'div';
  const history = useHistory();
  const _viewDailyAttestation = (id) => {
      history.push(Routes.DailyAttestationViewPage.path.replace(":id", id));
  };
  return (
    <td colSpan={colSpan} style={{ cursor: "pointer", color:"" }} className={className}  >
      <ContentTag to={to}>{children}</ContentTag>
    </td>
  );
}

export const DailyAttestationSelectTable = (props) => {
  const [count, setCount] = useState(0);const [totalItems, setTotalItems] = useState(0);
  const {
    showExtraOptions = false,
    totalPages = count,
    size = "md", 
    withIcons = false, 
    isSelectForm = true, 
    onChange, 
    contentsSelected,
    setFormChanged = false
  } = props;

  const [attestations, setAttestations] = useState([]);
  const [activePage, setActivePage] = React.useState(1);
  const [loaded, setLoaded] = useState(false);
  const [manufacturers, setManufacturers] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const history = useHistory();

  const initialValues = {
    searchTerms: '',
    batchNumber: '',
    manufacturerId:'',
    deliveryDate:'',
    showNonGEFS:false,
    enableCompound:false
  }

  function onSubmit(values, { setSubmitting, resetForm } ) {
      setTimeout(() => {
          setActivePage(1);
          retrieveAttestations(values);
          console.log(JSON.stringify(values, null, 2));
          setSubmitting(false);
      }, 1000);
  }

  useEffect(() => {
    if(isSelectForm){
      //alert("contentsSelected: " + formValues.contentsSelected);
    }
    getManufacturerData();
  },[isSelectForm]);


  
  async function getManufacturerData(){
    ManufacturerService.getAll()
      .then((response) => {
          const options = response.data.map(d => ({
              "value": d.id,
              "label": d.manufacturerName
          })).sort((a, b) => a.label.localeCompare(b.label));
          setManufacturers(options);
      })
      .catch((e) => {
          console.log(e);
      });
  }


  const retrieveAttestations = (values) => {
    let params =  '';
    if(values != null){
      params = getFilterParams(values.searchTerms,values.batchNumber,null, values.manufacturerId, values.deliveryDate,  false, values.enableCompound, activePage, pageSize);
    }
    else{
        params = getFilterParams(null,null,null,null,null,null,null, activePage, pageSize);
    }
    getAttestationsData(params);
  };

  useEffect(() => {
      retrieveAttestations();
  },[activePage, pageSize]);


  useEffect(() => {
    if(contentsSelected?.length>0){
      setFormChanged(true);
    }
    else{
      setFormChanged(false);
    }
  },[contentsSelected]);

 
  const getFilterParams = ( searchTerms,batchNumber,searchPO, manufacturerId, deliveryDate, showNonGEFS, enableCompound, activePage, pageSize) => {
    let params = {};
    if (searchTerms) {
        params["searchTerms"] = searchTerms;
    }
    if (batchNumber) {
        params["batchNumber"] = batchNumber;
    }
    if (searchPO) {
      params["searchPO"] = searchPO;
    }
    if (manufacturerId) {
        params["manufacturerId"] = manufacturerId;
    }
    if (deliveryDate) {
        params["deliveryDate"] = deliveryDate;
    }
    if (activePage) {
        params["page"] = activePage;
    }
    if (pageSize) {
        params["size"] = pageSize;
    }
    if (showNonGEFS) {
        params["enableNonGEFS"] = showNonGEFS;
    }
    if (enableCompound) {
        params["enableCompound"] = enableCompound;
    }
    return params;
};

  
  const handlePageSizeChange = (value) => {
    setPageSize(value.value);
    setActivePage(1);
  };

  const openBlobPdf = (filename) => {
    BlobService.openBlobPdf(filename);
  }

  const getAttestationsData = (params) => {
    setLoaded(false);
    DailyAttestationService.getAllPagedByUserId(params)
        .then((response) => {
            const { data, totalPages, totalRecords} = response.data;
            setTotalItems(totalRecords);
            setAttestations(data);
            setCount(totalPages);
            setLoaded(true);
        })
        .catch((e) => {
            setLoaded(true);
            console.log(e);
        });
  };
  

  //Table Row

  const TableRow = (props) => {
    const {
      id, 
      uniqueCode, 
      manufacturerName, 
      commercialNumber,
      dailyAttestationItems, 
      signedDocument, 
      deliveryDate,
      selections} 
      = props;
     const [selected, setSelected] = useState(false);

    useEffect(() => {
      if(isSelected()){
        setSelected(true)
      }
      else{
        setSelected(false)
      }
    }, []);

    const isSelected = () => {
      if(selections!==undefined){
        const found = selections.find(x => x.id == id );
        if(found!== undefined && found !== null){
          return true;
        }
      }
      return false;
    }

    const getSADate = (date) => {
      var newDate = moment(date).format("DD/MM/YY");
      if (newDate === "Invalid date") {
          return "";
      }
      return newDate;
    }

    const guid = uuid();
    
    const selectItem = (id, uniqueCode, commercialNumber, manufacturerName, dailyAttestationItems, signedDocument) => { 
      var res = contentsSelected;
      dailyAttestationItems.forEach((item, i)=>{
        var isCompound = false;
        var certificateNumbers = item.attestation.certificateNumberList;
        if(certificateNumbers?.length>1){
          isCompound = true;
        }
        var obj = {
          id: id, 
          isCompound: isCompound, 
          productName: null,
          commodityCode: JSON.parse(item?.attestation?.attestationDetails.productJson)?.product?.productDetails?.commodityHscode,
          netWeightTotal: (item?.numberOfPackages * JSON.parse(item?.attestation?.attestationDetails.productJson)?.product?.productDetails?.netWeight).toFixed(3),
          grossWeightTotal: (item?.numberOfPackages * JSON.parse(item?.attestation?.attestationDetails.productJson)?.product?.productDetails?.grossWeight).toFixed(3),
          certificateNumbers: certificateNumbers,
          numberOfPackages: item?.numberOfPackages,
          dailyAttestationId: item?.id, 
          uniqueCode: uniqueCode, 
          commercialNumber: commercialNumber,
          manufacturerName: manufacturerName, 
          batchNumbers: item?.batchNumbersDisplay,
          dailyAttestationItems: item, 
          productJson: item?.attestation?.attestationDetails.productJson,
          isGEFS: true,
          attestation: item?.attestation,
          signedDocument:signedDocument, 
          combinations: [],
          consignmentContentsId: uuid()
        };
        if(!selected){
            //Check for existing product in consignment content list
            var hasMatch = contentsSelected.find(x=>x.dailyAttestationItems?.id == obj.dailyAttestationItems?.id);
            if(hasMatch == undefined ){
              setSelected(true);
              setFormChanged(true);
              res.push(obj);
            }
          }
          else{
            res.pop(obj);
            setSelected(false);
          }
        

      });
    }

    return (
      <tr className={selected? "bg-lightblue" : null}>
      <Td id={id} colSpan={3} className="text-truncate">
        <EllipsisTooltip text= {uniqueCode} />
      </Td>
      <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
        <Td id={id} colSpan={3} className="text-truncate">
        <EllipsisTooltip text= {manufacturerName} />
        </Td>
      </PermissionsCheck>
      <Td id={id} colSpan={2} className="text-truncate">
        <EllipsisTooltip text= {commercialNumber} />
      </Td>
      <Td id={id} colSpan={2} className="text-truncate">
        <EllipsisTooltip text=
          {
            dailyAttestationItems.length == 1 ?
              dailyAttestationItems.map(item => item.batchNumbersDisplay).join(', ')
            :
              dailyAttestationItems.map((item, i) => (
                <span className="d-block">Item {i + 1}: {item.batchNumbersDisplay}</span>
              )
              )
          }
        />
      </Td>
      <Td id={id} colSpan={1} className="text-truncate">
        <EllipsisTooltip text= {getSADate(deliveryDate)} />
      </Td>
      <Td colSpan={1} className="text-right">
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle as={Button} split variant="link" className="d-inline text-dark m-0 p-0">
            <span className="icon icon-sm">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="position-absolute">
            <Dropdown.Item onClick={()=> _viewDailyAttestation(id)}>
              <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" /> 
              View Details
            </Dropdown.Item>
            <Dropdown.Item onClick={()=>{openBlobPdf(signedDocument?.filename)}}>
              <FontAwesomeIcon icon={faFileContract} className="dropdown-item-icon" />
              Signed Declaration
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {isSelectForm &&
          <div className="d-inline px-4">
              {selected?
              (
                <Button variant="primary" size="sm" className="text-center" 
                  onClick={() => selectItem(id, uniqueCode,commercialNumber, manufacturerName, dailyAttestationItems, signedDocument)}>
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              )
              :
              (
                <Button variant="light" size="sm" className="text-center"  
                  onClick={() => selectItem(id, uniqueCode,commercialNumber, manufacturerName, dailyAttestationItems, signedDocument)}>
                  <FontAwesomeIcon icon={faPlus}/>
                </Button>
              )
            }
          </div>
        }
      </Td>
    </tr>
    );
  };

  //Start - Paging Functions
  const onPrevItem = () => {
    const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
    setActivePage(prevActiveItem);
  };

  const onPageNumberClick = (number) => {
    setActivePage(number);
  };
  const onNextItem = (totalPages) => {
    const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
    setActivePage(nextActiveItem);
  };
  //End - Paging Functions

  //Open View Attestation
  const _viewDailyAttestation = (id) => {
    if(isSelectForm){
      window.open(Routes.DailyAttestationViewPage.path.replace(":id", id));
    }
    else{
      history.push(Routes.DailyAttestationViewPage.path.replace(":id", id));
    }
  };


  return (
    <>
      <Col xs={12} sm={6} lg={12}>
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            >
            {(props, isSubmitting) => (
              <Form>     
                  <Row className="mb-3">
                      <Col xs={12} sm={4} lg={3}>
                          <FormController
                              control="input"
                              type="text"
                              placeholder="Search by PO or tracking document number"
                              name="searchTerms"
                             // size="sm"
                          />
                      </Col>
                      <Col xs={12} sm={2} lg={2}>
                          <FormController
                              control="input"
                              type="text"
                              placeholder="Batch number"
                              name="batchNumber"
                             // size="sm"
                          />
                      </Col>
                      
                      <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
                          <Col xs={12} sm={2} lg={3}>
                            <FormController
                                id="manufacturer"
                                name="manufacturer"
                                placeholder="Select a manufacturer"
                                control="multiselect"
                                options={manufacturers}
                                isMulti={false}
                                isClearable={true}
                                onBlur={props.setFieldTouched}
                                onChange={(name, value) => {
                                  props.setFieldValue('manufacturer',value);
                                  props.setFieldValue('manufacturerId',value?.value);
                                    
                                }}
                                touched={props.touched.manufacturer}
                                errors={props.errors.manufacturer}
                                isrequired="true"
                                col={12}
                              //  size="sm"
                            />
                          </Col>
                      </PermissionsCheck>

            
                      <Col xs={12} sm={2} lg={2}>
                          <FormController
                              control="dateInput"
                              type="text"
                              placeholderText="Select a delivery date"
                              name="deliveryDate"
                              // size="sm"
                          />
                      </Col>

                      {showExtraOptions &&
                      <>
                        {/* <Col xs={12} sm={1} lg={2}>
                            <FormController
                                control="checkbox"
                                label="Compound"
                                disabled={true}
                                name="enableCompound"
                                className="my-2"
                            //   size="sm"
                            />
                        </Col> */}
                        <Col xs={12} sm={1} lg={2} >
                            <div className="form-group">
                                <Button 
                                  //size="sm" 
                                  variant="light" 
                                  type="submit" 
                                  disabled={isSubmitting}>
                                <FontAwesomeIcon icon={faFilter} />{isSubmitting ? "Please wait..." : "Filter"}</Button>
                            </div>
                        </Col>
                      </>
                      }
                  </Row>
              </Form>
            )}
        </Formik >
      </Col>
      <Preloader component={true} show={!loaded} />

      <Table hidden={!loaded || attestations==0} hover className="user-table align-items-center table-flush table-ellipsis">
        <thead>
          <tr>
            <th className="border-bottom" colSpan={3}>Tracking Document Number</th>
            <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
              <th className="border-bottom" colSpan={3}>Manufacturer</th>
            </PermissionsCheck>
            <th className="border-bottom" colSpan={2}>Ref Number</th>
            <th className="border-bottom" colSpan={2}>Batch numbers</th>
            <th className="border-bottom" colSpan={1}>Delivery date</th>
            <th className="border-bottom" colSpan={1}></th>
          </tr>
        </thead>
        <tbody>
            {attestations?.map(t => 
              <TableRow 
                  setFormChanged={setFormChanged}
                  key={`dailyattestation-${t.id}`}
                  selections={contentsSelected}
                  {...t} 
              />)}
        </tbody>
      </Table>

      <Paging
        activePage={activePage}
        size={size}
        pageSize={pageSize}
        totalPages={totalPages}
        totalItems={totalItems}
        handleChange={handlePageSizeChange}
        onPrevItem={onPrevItem}
        onNextItem={onNextItem}
        onPageNumberClick={onPageNumberClick}
        withIcons={withIcons}
      />
      {loaded && attestations?.length == 0 &&(
        <NoResults name="daily attestations"/>
      )}
    </>
  );
};
