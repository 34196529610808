
  export default {
    formId: 'attestationResponseNonGEFSForm',
    formField: {
      traceability: {
        name: 'traceability',
        label: 'Procedure for Traceability',
        sublabel: 'The batch information on the support attestation should match the information available when inspecting the physical product (e.g. on the product label)',
        requiredErrorMsg: 'Traceability is required'
      },
      formsComplete: {
        name: 'formsComplete',
        label: 'Forms Completed',
        requiredErrorMsg: 'forms are required'
      },
      approvedExportCountries: {
        name: 'approvedExportCountries',
        label: 'I certify the establishment listed in Section B is/are approved for export to the following:',
        requiredErrorMsg: 'Approved Export Countries Of Origin is required'
      },
      approvedExportCountriesOfOrigin: {
        name: 'approvedExportCountriesofOrigin',
        label: 'Country name',
        requiredErrorMsg: 'Approved Export Countries Of Origin Country is required'
      },
      dateInspection: {
        name: 'dateInspection',
        label: 'Date of Inspection',
        requiredErrorMsg: 'dateInspection is required'
      },
      decision: {
        name: 'decision',
        label: 'Following the review of the attestation request, how would you like to proceed?',
        requiredErrorMsg: 'decision is required'
      },
      rejectReason: {
        name: 'rejectReason',
        label: 'Reason for rejection',
        requiredErrorMsg: 'reject reason required'
      },
      signedRequestUrl: {
        name: 'signedRequestUrl',
        label: 'Signed Request Url',
        requiredErrorMsg: ''
      },
      documentId: {
        name: 'documentId',
        label: 'Document ID',
        requiredErrorMsg: ''
      },
      isGEFS: {
        name: 'isGEFS',
        label: 'isGEFS',
        requiredErrorMsg: 'isGEFS required'
      },
      containsDairy: {
        name: 'containsDairy',
        label: 'containsDairy',
        requiredErrorMsg: 'containsDairy required'
      },
      finalConsumer: {
        name: 'finalConsumer',
        label: 'Final consumer',
        requiredErrorMsg: 'Final consumer is required'
      },
      isDateCollectionProductionMulti: {
        name: 'isDateCollectionProductionMulti',
        label: 'Date of production:',
        requiredErrorMsg: 'Date of production is required'
      },
      dateCollectionProductionStart: {
        name: 'dateCollectionProductionStart',
        label: null,
        requiredErrorMsg: 'Date of production is required'
      },
      dateCollectionProductionEnd: {
        name: 'dateCollectionProductionEnd',
        label: null,
        requiredErrorMsg: 'Date of production is required'
      },      
      dateCollectionProductionSingle: {
        name: 'dateCollectionProductionSingle',
        label: null,
        requiredErrorMsg: 'Date of production is required'
      },  
      dateCollectionProduction: {
        name: 'dateCollectionProduction',
        label: null,
        requiredErrorMsg: 'Date of production is required'
      },
      productionDate: {
        name: 'productionDate',
        label: 'were produced',
        requiredErrorMsg: 'Select a date for production'
      },
      productionDateSingle: {
        name: 'productionDateSingle',
        label: 'Production date',
        requiredErrorMsg: 'Select a date for production date'
      },
      productionDateMultipleStart: {
        name: 'productionDateMultipleStart',
        label: 'Production date (start)',
        requiredErrorMsg: 'Select a date for production start'
      },
      productionDateMultipleEnd: {
        name: 'productionDateMultipleEnd',
        label: 'Production date (end)',
        requiredErrorMsg: 'Select a date for production end'
      },
      batchNumber: {
        name: 'batchNumber',
        label: 'Batch:',
        requiredErrorMsg: 'Batch number is required'
      },
      numberProductsCases: {
        name: 'numberProductsCases',
        label: 'Number of Products/Cases: ',
        requiredErrorMsg: 'Number of Products/Cases is required'
      },  
      hatcheries: {
        name: 'hatcheries',
        label: 'Hatcheries:',
        requiredErrorMsg: 'A Hatchery is required'
      },
      hatcheryName: {
        name: 'hatcheryName',
        label: 'Hatchery Name: ',
        requiredErrorMsg: 'Hatchery Name is required'
      },
      hatcheryPostcode: {
        name: 'hatcheryPostcode',
        label: 'Hatchery Postcode: ',
        requiredErrorMsg: 'Hatchery Postcode is required'
      },
      isDateRequired: {
        name: 'isDateRequired',
        label: 'isDateRequired: ',
        requiredErrorMsg: 'isDateRequired'
      },
    }
  };
  
