import *  as baseService  from "./BaseService";

const create = (data) => {
    return baseService.postApiData("/StorageExporterDeclaration/create", data);
};
const createResponse = (data) => {
    return baseService.postApiData("/StorageExporterDeclaration/create", data);
};
const createDocument = (data) => {
    return baseService.postApiData("/StorageExporterDeclaration/GenerateDocument", data);
};
const getAllByProduct = (id) => {
    return baseService.getApiData("/StorageExporterDeclaration/GetAllByProductId?id=" + id);
};
const getCurrentByProductId = (id) => {
    return baseService.getApiData("/StorageExporterDeclaration/GetCurrentByProductId?id=" + id);
};
const cancelStorageDeclarationRequest = (id) => {
    return baseService.putApiData("/StorageExporterDeclaration/CancelSupportStorageDeclarationRequest?id=" + id);
};
const getAllByManufacturer = (id) => {
    return baseService.getApiData("/StorageExporterDeclaration/getAllByManufacturer");
};
const getAllPagedByUserId = (params) => {
    return baseService.getApiDataWithParams("/StorageExporterDeclaration/GetAllPagedByUser", params);
};
const getAllPaged = (params) => {
    return baseService.getApiDataWithParams("/StorageExporterDeclaration/getAllPaged", params);
};
const getAllByPlatformCertifier = (id) => {
    return baseService.getApiData("/StorageExporterDeclaration/GetCurrentByVetUserId?id=" + id);
};
const getUniqueReference = (productId) => {
    return baseService.getApiData("/StorageExporterDeclaration/GetUniqueReference?productId=" + productId);
};
const getById = (id) => {
    return baseService.getApiData("/StorageExporterDeclaration/getById?id=" + id);
};
const getAllIncompleteRequests = () => {
    return baseService.getApiData("/StorageExporterDeclaration/getAllIncompleteRequests");
};
const StorageExporterDeclarationService = {
    getAllByProduct,
    getById,
    create,
    createResponse,
    createDocument,
    getCurrentByProductId,
    getUniqueReference,
    getAllByPlatformCertifier,
    cancelStorageDeclarationRequest,
    getAllByManufacturer,
    getAllPagedByUserId,
    getAllPaged,
    getAllIncompleteRequests
};

export default StorageExporterDeclarationService;