import React from 'react';
import { Col, Row } from "@themesberg/react-bootstrap";
import * as Composite8350Labels from "../Composite8350Labels"
import FormController from "../../../common/FormController"
import {findArrayElementByFieldName,findArrayElementByFieldNameAndValue} from "../../../Attestations/Common/AttestationFunctions";
import { useFormikContext } from 'formik';
import moment from "moment"

const Dairy8350 = (props) => {
    const {isReadOnly, attestationData, productionDateChoice, productionDate, productionDateSingle, productionDateMultipleStart, productionDateMultipleEnd, setFieldValue} = props;
    const { values: formValues, errors: formErrors } = useFormikContext();
    formValues.containsDairy = attestationData?.containsDairy;
    let compositeLabels = Composite8350Labels;
    {if(attestationData?.dairyData?.dairyBZoneOriginates != null) 
        {
            var zones = attestationData?.dairyData.dairyBZoneOriginates.map(x => x.isoCode.slice(0,-2)).join(", ");
            compositeLabels.dairyBOriginatesInChoice[2].key = "a country with code " + zones +  " authorised for entry into the Union of milk, colostrum, dairy products and colostrum based products in Annex XVII, Part 1, to Implementing Regulation (EU) 2021/404, where the country where the composite product was produced is also authorised, under the same conditions, for entry into the Union of milk, colostrum, dairy products and colostrum-based products and listed in Part 1 of that Annex;" 
        } 
    }

  return (
    <>
        {formValues.containsDairy?(
            <li>
                <strong><u>II.3.B Dairy products or colostrum-based products</u> in any quantity that meet the animal health requirements laid down in Commission Delegated Regulation (EU) 2020/692 and therefore are eligible for entry into the Union as such, and</strong>
                <ol type="a">
                    {attestationData.containsDairy?(
                        <>
                        <li>
                            <p>have been produced
                                {attestationData.dairyData.dairyA_1 &&
                                    <> in the country with code <strong><u>{attestationData.dairyData.dairyACountryOrigin.map(x => x.isoCode.slice(0,-2)).join(", ")}</u></strong> as listed in Part 1 of Annex XVII to Implementing Regulation (EU) 2021/404;&nbsp;
                                    {/* which has
                                        been free from foot and mouth disease and infection with rinderpest virus for a period of at least 12 months prior to the date of
                                        milking and, during that period, no vaccination against those diseases has been carried out. */}
                                    </>
                                }
                                {attestationData.dairyData.dairyA_2 &&
                                    <>
                                        in the country with code <strong><u>{attestationData.dairyData.dairyA2CountryOrigin.map(x => x.isoCode.slice(0,-2)).join(", ")}</u></strong> as listed in a list of third countries and territories adopted by the Commission in accordance
                                        with Article 230(1) of Regulation (EU) 2016/429 and the treatment applied is conform to the minimum treatment provided for in
                                        Article 157 and Annex XXVII to Delegated Regulation (EU) 2020/692;&nbsp;
                                    </>
                                }
                                {attestationData.dairyData.dairyA_3 &&
                                    <>
                                        in a Member State;
                                    </>
                                }
                            and in the establishment <strong><u>{attestationData.dairyData?.dairyAapprovalNumberEstablishments.map(establishment => establishment.approvalNumber).join(", ")}</u></strong> (approval number of the establishment(s) of origin of the dairy products or the
                            colostrum-based products contained in the composite product authorised at the time of production for export of dairy products or
                            colostrum-based products to the EU)
                            </p>
                        </li>

                    <li>
                        Originate from:
                        <ul>
                            {attestationData.dairyData.dairyBOriginatesIn.includes("1") &&(<li key={compositeLabels.dairyBOriginatesInChoice[0].value}>{compositeLabels.dairyBOriginatesInChoice[0].key}</li>)}
                            {attestationData.dairyData.dairyBOriginatesIn.includes("2") &&(<li key={compositeLabels.dairyBOriginatesInChoice[1].value}>{compositeLabels.dairyBOriginatesInChoice[1].key}</li>)}
                            {attestationData.dairyData.dairyBOriginatesIn.includes("3") &&(<li key={compositeLabels.dairyBOriginatesInChoice[2].value}>{compositeLabels.dairyBOriginatesInChoice[2].key}</li>)}
                        </ul>
                        
                    </li>
                    <li>

                        {attestationData.dairyData.dairyCMilkObtainedFrom ? (
                        <>
                            <span>dairy products are made from raw milk obtained from: </span>


                            {attestationData.dairyData.dairyCStatements == 1 && (
                                <ul>
                                    <li>
                                        {attestationData.dairyData.dairyCMilkType1 && findArrayElementByFieldName(compositeLabels.dairyCMilkChoices, 'dairyData.dairyCMilkType1') + ", "}
                                        {attestationData.dairyData.dairyCMilkType2 && findArrayElementByFieldName(compositeLabels.dairyCMilkChoices, 'dairyData.dairyCMilkType2') + ", "}
                                        {attestationData.dairyData.dairyCMilkType3 && findArrayElementByFieldName(compositeLabels.dairyCMilkChoices, 'dairyData.dairyCMilkType3') + ", "}
                                        {attestationData.dairyData.dairyCMilkType4 && findArrayElementByFieldName(compositeLabels.dairyCMilkChoices, 'dairyData.dairyCMilkType4') + ", "}
                                        {attestationData.dairyData.dairyCMilkType5 && findArrayElementByFieldName(compositeLabels.dairyCMilkChoices, 'dairyData.dairyCMilkType5') + ", "}
                                        {compositeLabels.dairyCStatementsChoice[0].key.replace("Bos Taurus, Ovis aries, Capra hircus, Bubalus bubalis, Camelus dromedarius","")}
                                    
                                    
                                        <ul>
                               
                                                <li key="dairyC">
                                                    {findArrayElementByFieldNameAndValue(compositeLabels.dairyCStatementsChoice, 'dairyData.dairyC_1', attestationData.dairyData.dairyC_1)}
                                                    {attestationData.dairyData.dairyC_1 === 6  &&
                                                    <ul>
                                                        <li>
                                                            {findArrayElementByFieldNameAndValue(compositeLabels.dairyCStatementsChoice, 'dairyData.dairyC_1_6', attestationData.dairyData.dairyC_1_6)}
                                                        </li>
                                                    </ul>
                                                    }
                                                </li>
                                        </ul>
                                    </li>
                                </ul>
                                )}
                                
                            {attestationData.dairyData.dairyCStatements === 2 && (
                                <ul>
                                    <li>
                                    {findArrayElementByFieldNameAndValue(compositeLabels.dairyCStatementsChoice, 'dairyData.dairyCStatements', 2)}
                                    {attestationData.dairyData.dairyC_2 !== null &&(
                                        <ul>
                                            <li>{findArrayElementByFieldNameAndValue(compositeLabels.dairyCStatementsChoice, 'dairyData.dairyC_2', attestationData.dairyData.dairyC_2)}</li>
                                        </ul>
                                    )}
                                    </li>
                                </ul>
                            )}
                                
                        </>
                        )
                        :(
                            <span>are <strong>not</strong> dairy products made from raw milk obtained from</span>
                        )
                        }


                    </li>
                    <li>
                        {attestationData.dairyData.dairyD ? (
                            compositeLabels.dairyD.key
                        )
                        :
                        (
                            <>are <b>not</b> are colostrum-based products</>
                        )
                        }
                    </li>
                    {/* <li>
                        
                    {isReadOnly?(
                        <>
                            {formValues.productionDate == 1 &&
                                <span> were produced on {moment(productionDateSingle).format("DD/MM/YYYY")}</span>
                            }
                            {formValues.productionDate == 2 &&
                                <span> were produced between {moment(productionDateMultipleStart).format("DD/MM/YYYY")} and {moment(productionDateMultipleEnd).format("DD/MM/YYYY")}</span>
                            }
                        </>
                        
                    )
                    :
                    (
                        <>
                        <div className="d-block mb-3"><span className="is-required">were produced: </span>
                        <FormController
                            control="radio"
                            label={null}
                            name={productionDate.name}
                            options={productionDateChoice}
                        />
                        {(formValues.productionDate == 1) && (
                                <FormController
                                    control="dateInput"
                                    isrequired="true"
                                    col={3}
                                    label={productionDateSingle.label}
                                    name={productionDateSingle.name}
                        
                                /> 
                        )}
                        {(formValues.productionDate == 2) && (
                            <Row className="mt-0">
                            <Col md={3}>
                                <FormController
                                    control="dateInput"
                                    isrequired="true"
                                    label={productionDateMultipleStart.label}
                                    name={productionDateMultipleStart.name}
                                    selected={formValues.productionDateMultipleStart}
                                    onChange={date => setFieldValue('productionDateMultipleStart', date)}
                                /> 
                            </Col>
                            <Col md={3}>
                                <FormController
                                    control="dateInput"
                                    isrequired="true"
                                    selected={formValues.productionDateMultipleEnd}
                                    label={productionDateMultipleEnd.label}
                                    name={productionDateMultipleEnd.name}
                                    onChange={date => setFieldValue('productionDateMultipleEnd', date)}
                                /> 
                            </Col>
                        </Row>
                        )}
                        </div>
                        </>
                    )}
                </li> */}
                </>
        ):
        (<div></div>)
        }
                </ol> 
            </li>
        )
        :
        (
            <li>II.3.B Does not contain processed dairy products.</li>
        )
        }
    </>
    )
};
export default Dairy8350;
