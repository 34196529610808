import *  as baseService  from "./BaseService";

const getByProductId = (id) => {
    return baseService.getApiData("/productdogchews/getByProductId?id=" + id);
};

const create = (data) => {
    return baseService.postApiData("/productdogchews/Create", data);
};

const update = (id, data) => {
    return baseService.putApiData("/productdogchews/update?id=" + id, data);
};

const ProductDogChewsService = {
    create,
    getByProductId,
    update
};

export default ProductDogChewsService;