import *  as baseService  from "./BaseService";

const getByProductId = (id) => {
    return baseService.getApiData("/productvitamind/getByProductId?id=" + id);
};

const create = (data) => {
    return baseService.postApiData("/productvitamind/Create", data);
};

const update = (id, data) => {
    return baseService.putApiData("/productvitamind/update?id=" + id, data);
};

const ProductVitaminDService = {
    create,
    getByProductId,
    update
};

export default ProductVitaminDService;