import React, { useState, useEffect, useRef} from "react";
import { Col, Row, Card} from '@themesberg/react-bootstrap';
import BreadcrumbNav from "../../components/BreadcrumbNav";
import { getUserRoles, isAdmin, isVet, isRetailer, isManufacturer, isManufacturerAdmin} from "../../auth/authorisation";
import PermissionsCheck from '../../auth/PermissionCheck';
import { roles} from '../../auth/PermissionsMap';

function UserGuides(props){
    
    const manufacturerGuides = [roles.admin, roles.retailer, roles.manufacturer, roles.manufactureradmin];
    const vetGuides = [roles.admin, roles.retailer, roles.vet, roles.certifyingofficer];

	return (
        <>
            <BreadcrumbNav 
                data={{
                    item1 : {name:'User Guides', path:'/'},
                    item2 : {name:'User Guides', path:'/'},
                }}
            />
            <Row>
                <Col >
                    <h2 className="mb-3" id="tabelLabel" >User Guides</h2>
                </Col>
            </Row>
            <h4 className="my-3 text-title">Navigation and Registration</h4>
            <Row>
                <Col xl={12}>
                    <Row>
                        <PermissionsCheck errorProps={{ disabled: true }} requiresRole={manufacturerGuides}
                            >
                            <Col lg={6} xl={4}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <label className="mb-0">Navigation around Dashboard - Manufacturer</label>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body className='embed-responsive embed-responsive-16by9'>	
                                        <iframe width="100%" height="200px" src="https://www.youtube.com//embed/bAUoXgLfR4I?showinfo=0" frameborder="0" allow="accelerometer; autoplay; showinfo=0; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col lg={6} xl={4}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <label className="mb-0">Registration - Manufacturer</label>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>	
                                        <iframe width="100%" height="200px" src="https://www.youtube.com//embed/u3OGTm5KS0U" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </PermissionsCheck>
                        
                        <PermissionsCheck errorProps={{ disabled: true }} requiresRole={vetGuides}>
                            <Col lg={6} xl={4}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <label className="mb-0">Registration - Registered Vet</label>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>	
                                        <iframe width="100%" height="200px" src="https://www.youtube.com//embed/Y3jouzEOoZI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </PermissionsCheck>
                    </Row>

                    <h4 className="my-3 text-title">Using the website</h4>
                    <Row>
                        <PermissionsCheck errorProps={{ disabled: true }} requiresRole={manufacturerGuides}>
                        <>
                              <Col lg={6} xl={4}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <label className="mb-0">Step 1 - Site Registration</label>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>	
                                        <iframe width="100%" height="200px" src="https://www.youtube.com//embed/zeTDLRknSvY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6} xl={4}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <label className="mb-0">Step 2 - Product Information</label>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>	
                                        <iframe width="100%" height="200px" src="https://www.youtube.com//embed/bFqNfwtZGlk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6} xl={4}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <label className="mb-0">Step 3 - Product Detail Information</label>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>	
                                        <iframe width="100%" height="200px" src="https://www.youtube.com//embed/hcJ7YlDpFLU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6} xl={4}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <label className="mb-0">Step 4 - Composite Information</label>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>	
                                        <iframe width="100%" height="200px" src="https://www.youtube.com//embed/OvyqKmGCv34" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6} xl={4}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <label className="mb-0">Step 5 (Part 1) - Request as Support Attestation</label>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>	
                                        <iframe width="100%" height="200px" src="https://www.youtube.com//embed/7R2dhd7QJX0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6} xl={4}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <label className="mb-0">Step 5 (Part 2) - Request as Support Attestation</label>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>	
                                        <iframe width="100%" height="200px" src="https://www.youtube.com//embed/PgHHPqZcemY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                    </Card.Body>
                                </Card>
                            </Col>
                            </>
                        </PermissionsCheck>

                        <PermissionsCheck errorProps={{ disabled: true }} requiresRole={vetGuides}>
                            <>
                                <Col lg={6} xl={4}>
                                    <Card border="light" className="bg-white shadow-sm mb-4">
                                        <Card.Header>
                                            <Row className="align-items-center">
                                                <Col>
                                                    <label className="mb-0">Step 6 -Registered vet signing the support attestation</label>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body>	
                                            <iframe width="100%" height="200px" src="https://www.youtube.com//embed/kpd9UycdRnY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6} xl={4}>
                                    <Card border="light" className="bg-white shadow-sm mb-4">
                                        <Card.Header>
                                            <Row className="align-items-center">
                                                <Col>
                                                    <label className="mb-0">Step 7 - Review the signed support attestation</label>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body>	
                                            <iframe width="100%" height="200px" src="https://www.youtube.com//embed/YSdRg6LcHIU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>
                        </PermissionsCheck>

                        
                        <PermissionsCheck errorProps={{ disabled: true }} requiresRole={manufacturerGuides}>
                            <Col lg={6} xl={4}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <label className="mb-0">Step 8 - Creating Daily Supplier Declarations</label>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>	
                                        <iframe width="100%" height="200px" src="https://www.youtube.com/embed/WFCDziAa2_w" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </PermissionsCheck>

                    </Row>
                </Col>
            </Row>
        </>
	);
}

export default UserGuides;