import *  as baseService  from "./BaseService";

const getByDocumentId = (documentId) => {
    return baseService.getApiData("/CertificateCompleted/GetByDocumentId?id=" + documentId);
};
const getByConsignmentContentsId = (consignmentContentsId) => {
    return baseService.getApiData("/CertificateCompleted/GetById?ConsignmentContentsId=" + consignmentContentsId);
};
const getAllByConsignmentId = (consignmentId) => {
    return baseService.getApiData("/CertificateCompleted/GetAllByConsignmentId?ConsignmentContentsId=" + consignmentId);
};
const create = (data) => {
    return baseService.postApiData("/CertificateCompleted/Create", data);
};
const remove = (id) => {
    return baseService.deleteApiData("/CertificateCompleted/Delete", id);
};
const CertificateCompletedService = {
    create,
    getAllByConsignmentId,
    getByConsignmentContentsId,
    getByDocumentId
};

export default CertificateCompletedService;
