import { Col, Row } from '@themesberg/react-bootstrap';
import React from "react";
import WelcomeImage from '../../../assets/img/ivetted_welcome.jpg';
import ActivityWidget from '../../../components/ActivityWidget';
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import NoticeboardWidget from '../../../components/NoticeboardWidget';
import UpcomingWidget from '../../../components/UpcomingWidget';
import UserProfileWidget from '../../../components/UserProfileWidget';
import PermissionsCheck, {isPermitted} from '../../../auth/PermissionCheck';

export default (props) => {
  
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/r1yczguo';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

  const jumbotronStyle = {
    backgroundImage: `url(${WelcomeImage})`,
    backgroundSize: 'cover',
    color: '#222'
  };
  const darkText = {
    color: '#222 !important'
  };
  const openUserGuides = () => {
    props.history.push("/userguides/");
};
  
  return (
    <>
      <BreadcrumbNav  data={{item1 : {name:'Dashboard', path:'/'}}} />
      <Row>
          <Col >
              <h2 className="mb-3" id="tabelLabel" ></h2>
          </Col>
      </Row>

      <Row>
        {/* Left side */}
        <Col xs={12} xl={8} className="mb-4">
          <Row>
            {/* Hero */}
            <Col xs={12} lg={12}>
              <div class="jumbotron p-2 mb-4 rounded-3" style={jumbotronStyle}>
                <div class="container-fluid py-4">
                  <div class="display-4 fw-bold" style={darkText}>Welcome to iVetted</div>
                  <div className="col-md-5 fs-5" style={darkText}>
                    <p>Your all in one service for food traceability and export health certificates.</p>
                  </div>
                  <button class="btn btn-outline-dark btn-md" type="button" onClick={() => openUserGuides()}>See our user guides</button>
                </div>
              </div>
            </Col>

            {/* Recent Activity */}
            <Col xs={6} lg={6}>
              <ActivityWidget />
            </Col>

            {/* Expiring soon */}
            <Col xs={6} lg={6}>
              <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "manufacturer",'manufactureradmin','certifyingofficer','platformcertifier']}>
                <UpcomingWidget />
              </PermissionsCheck>
            </Col>
          </Row>
        </Col>
        {/* Right side */}
        <Col xs={12} xl={4} className="mb-4">
          <Row>
            {/* User */}
            <Col xs={12} lg={12} className="mb-4">
              <UserProfileWidget />
            </Col>
            {/* Noticeboard */}
            <Col xs={12} lg={12}>
              <NoticeboardWidget />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
