
import { bearerToken, getTenantName} from "../../auth/authProvider";
import axios from "axios";

export async function getAuthHeader() {
    const token = await bearerToken();
	return {
		headers: new Headers({
            'tenant': await getTenantName(),
            'Authorization': `Bearer ${token}`,
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		})
	};
}

export async function getApiDataWithParams(url, params) {
    const token = await bearerToken();
    var request = axios.create({
    baseURL: process.env.REACT_APP_API_URL_UAT,
    headers: {
        'tenant': await getTenantName(),
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
    });
    return request.get(url, {params});
};

export async function getApiData(url) {
    const token = await bearerToken();
    var request = axios.create({
        baseURL: process.env.REACT_APP_API_URL_UAT,
        headers: {
            'tenant': await getTenantName(),
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    return request.get(url);
};

export async function postApiData(url, data) {
    const token = await bearerToken();
    var request = axios.create({
        baseURL: process.env.REACT_APP_API_URL_UAT,
        headers: {
            'tenant': await getTenantName(),
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
    }
});

    return request.post(url, data);
};

export async function putApiData(url, data) {
    const token = await bearerToken();
        var request = axios.create({
            baseURL: process.env.REACT_APP_API_URL_UAT,
            headers: {
                'tenant': await getTenantName(),
                'Authorization':  `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
        }
    });
    return request.put(url, data);
};
    
export async function deleteApiData(id) {
    const token = await bearerToken();
        var request = axios.create({
            baseURL: process.env.REACT_APP_API_URL_UAT,
            headers: {
                'tenant': await getTenantName(),
                'Authorization':  `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
        }
    });
    return request.delete(id);
};
export async function getApiBlob(filename) {

    const token = await bearerToken();
    var request = axios.create({
        baseURL: process.env.REACT_APP_API_URL_UAT,
        headers: {
            'tenant': await getTenantName(),
            'Authorization':  `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Content-Disposition': 'attachment; filename=file.pdf'
    }
});
return request.get(filename, { responseType: 'arraybuffer' });

};

    