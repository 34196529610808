import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form} from 'formik';
import FormController from "../common/FormController"
import SupportingEvidenceService from "../../services/SupportingEvidenceService";
import { Button, Card, Col, Row, Modal, CloseButton } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileImage, faTimes, faFile, faPlus, faFileExcel, faFileWord } from '@fortawesome/free-solid-svg-icons';
import moment from "moment"
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes} from '../../../auth/PermissionsMap';



const SupportingEvidence = (props) => {

    const handleCloseModalAdd = () => setShowModalAdd(false);
    const handleCloseModalView = () => setShowModalView(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalView, setShowModalView] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [supportingEvidence, setSupportingEvidence] = useState([]);
    const [productId] = useState(props.productId);

    const initialValues = {
        productId: productId,
        filesToUpload: []
    }
    function onSubmit(values, { setSubmitting, resetForm } ) {
        resetForm({})
        setTimeout(() => {
            const formData = new FormData();
            for (let value in values) {
              formData.append(value, values[value]);
            }
             for (let i = 0; i <= values.filesToUpload.length; i++) {
               formData.append(`files`, values.filesToUpload[i]);
             }

            SupportingEvidenceService.create(formData)
                .then(response => {
                    console.log(response.data);
                    setUploadComplete(true);
                    retrieveSupportingEvidence();
                    handleCloseModalAdd();
                })
                .catch(e => {
                    console.log(e);
                });
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    }

    function retrieveSupportingEvidence() {
        
        SupportingEvidenceService.getAll(productId)
        .then(response => {
            console.log(response.data);
            setSupportingEvidence(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    };
    useEffect(() => {
        retrieveSupportingEvidence();
    },[]);

    let filesImages = {icon: faFileImage, colour: 'text-purple', extensions:["jpg" , "png" , "bmp" , "tiff"]};
    let filesWord = {icon: faFileWord, colour: 'text-blue', extensions:["docx" , "doc"]};
    let filesPdf = {icon: faFilePdf, colour: 'text-danger', extensions:["pdf"]};
    let filesExcel = {icon: faFileExcel,colour: 'text-success', extensions:["xlsx", "xls", "csv"]};
    let filesUnknown = {icon: faFileExcel,colour: 'text-grey', extensions:[]};

    
    const getExtension = (file) =>{
            return file.filename.split('.').pop().toLowerCase();
    }

    
    {/* Function REMOVE  */}
    const handleRemove = (id) => {
        SupportingEvidenceService.remove(id)
        .then(response => {
            retrieveSupportingEvidence();
        })
        .catch(e => {
            console.log(e);
        });
    };

    const FileRow = ({file, fileExt}) => {

        function  getLinkText(file, fileExt){
            var fileName = file.filename.substring(37);
            return (
            <div className='text'>
                <a href={file.fileUrl} >
                <FontAwesomeIcon icon={fileExt.icon} className={`mx-auto ${fileExt.colour} `} />
                <span className="d-block fs-7">{fileName}</span>
                <small><i>{formatBytes(file.size, null)}. Added {formattedDate(file.CreatedDate)} by {file.userFullName}</i></small>
                </a>
            </div>
            );
        }

        function formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
        }

        function formattedDate(date){
            var newDate = moment(date).format("D/MM/YY");
            if (newDate === "Invalid date") {
                return "";
            }
        return newDate;
        }

        return (
            

        <Row>
            <Col sm={10}>
                {getLinkText(file, fileExt)}
            </Col>
            <Col sm={2}>
                <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.supportingevidence.create]}>
                    <CloseButton onClick={()=>{handleRemove(file.id)}}/>
                </PermissionsCheck>
            </Col>
        </Row>
        )
    };

    const files = supportingEvidence.map(file => (
        <li key={file.path} className="dropzone-upload" >
        {
            filesPdf.extensions.includes(getExtension(file))?(
                <FileRow file={file} fileExt={filesPdf}/>
            )
            :
            filesWord.extensions.includes(getExtension(file))?(
                <FileRow file={file} fileExt={filesWord}/>
            )
            :
            filesImages.extensions.includes(getExtension(file))?(
                <FileRow file={file} fileExt={filesImages}/>
            )
            :
            filesExcel.extensions.includes(getExtension(file))?(
                <FileRow file={file} fileExt={filesExcel}/>
            )
            :
            (
                <FileRow file={file} fileExt={filesUnknown}/>
            )
        }
        </li>
    ));
    
    return (
        <Card border="light" className="h-100 bg-white shadow-sm">
        <Card.Header className="align-items-center">
                <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.supportingevidence.create]}>
                    <Button onClick={() => setShowModalAdd(true)}  variant="light" size="sm" className="text-dark me-2 float-end text-end">
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        <span>Add</span>
                    </Button>
                </PermissionsCheck>
            <h4 className="text-title mb-0" id="tabelLabel" >Supporting Evidence</h4>
        </Card.Header>
        <Card.Body>
            <Row>
                <Col xs={12} className="">
                    <Modal size="md" as={Modal.Dialog} centered show={showModalAdd} onHide={handleCloseModalAdd}>
                        <Modal.Header>
                            <Modal.Title className="h6">Upload Files as Supporting Evidence</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={handleCloseModalAdd} />
                        </Modal.Header>
                        <Modal.Body>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                            >
                                {(props, isSubmitting) => (
                                    <Form>
                                        <Row className="mt-0">
                                            <Col md={12}>
                                                <FormController
                                                    control="multipleFileUpload"
                                                    allowedFileTypes = "image/*,.pdf,.doc,.docx,.xls,.csv,.xlsx"
                                                    label="Select images to upload"
                                                    name="filesToUpload"
                                                    className="mb-3"
                                                    multiple={true}
                                                />
                                                <div className="form-group mt-3">
                                                    <Button className="btn-block" type="submit" disabled={isSubmitting || props.values.filesToUpload.length<=0 }>{isSubmitting ? "Please wait..." : "Upload"}</Button>
                                                </div>  
                                            </Col>
                                        </Row>
                                    </Form>
                                    )}
                                </Formik >
                        </Modal.Body>
                    </Modal>

                    {files.length>0?(
                    <ul className="dropzone-uploaded list-unstyled mb-2">
                        {files.slice(0, 3)}
                    </ul>
                    )
                    :
                    (
                        <div className="text-center pt-4">
                            <h5 className="text-gray-600">
                                No supporting evidence<br/>files have been uploaded yet
                            </h5>
                        </div>
                    )}
                    
                    {files.length>3&&(
                        <Button onClick={() => setShowModalView(true)} variant="outline-gray" size="sm" >Show all {files.length} files</Button>
                    )}

                    <Modal size="md" as={Modal.Dialog} centered show={showModalView} onHide={handleCloseModalView}>
                        <Modal.Header>
                            <Modal.Title className="h6">All Supporting Evidence</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={handleCloseModalView} />
                        </Modal.Header>
                        <Modal.Body>
                            {files.length>0&&(
                                <ul className="dropzone-uploaded list-unstyled">
                                    {files}
                                </ul>
                            )}
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>
        </Card.Body>
    </Card>
    );
};

export default SupportingEvidence;