import { Card, Col, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from 'react';
import ScrollToTop from "../../../../../components/ScrollToTop";
import SigningComponent from '../../../../../components/SigningComponent';
import FormController from "../../../common/FormController";
import ExporterDeclarationTemplate from "./ExporterDeclarationTemplate";


export default function ExporterDeclarationReview(props) {
    const {
        formField: {
        },
        user,
        forwardedRef,
        uniqueRef,
        product, 
        manufacturer,
        platform, 
        organisation,
        site, 
        productDetail,
        attestationData,
        generatedDocument,
        certificateNumber,
        temperature
    } = props;

    const [isPdfGenerating, setPdfGenerating] = useState(false);

    const decisions = [
        { group: "decision",   fieldname: "decision1",  value: 1, key: "I <span className='text-danger'>REJECT</span> this attestation request" },
        { group: "decision",   fieldname: "decision2",  value: 2, key: "I <span className='text-success'>ACCEPT</span> this attestation request"}
    ]

    //Check is PDF is being generated in backround, ready for signing
    useEffect(()=>{ 
        generatedDocument==""? setPdfGenerating(true) : setPdfGenerating(false) 
    },[generatedDocument]);

    return (
        <Row>
            <Col xl={11}>
                <ScrollToTop/>
                <Card border="light" className="bg-white shadow-sm mb-4 p-3">
                    <Card.Body>
                        <ExporterDeclarationTemplate 
                            showPreview={false}
                            user={user}
                            values={user}
                            forwardedRef={forwardedRef}
                            uniqueRef={uniqueRef}
                            product={product}
                            manufacturer={manufacturer}
                            platform={platform}
                            organisation={organisation}
                            site={site}
                            productDetail={productDetail}
                            attestationData={attestationData}
                            certificateNumber={certificateNumber}
                            temperature={temperature}
                        />

                        <>
                            <h4>Sign Exporter Declaration Document</h4>
                            <p>
                                Now you have completed the Exporter Declaration form, click the button below to review and sign the document.<br />
                                Please Note: Clicking the button below will redirect you to our secure document signing provider.
                            </p>
                            <SigningComponent
                                document={generatedDocument}
                                ready={isPdfGenerating}
                                buttonText = "Sign Exporter Declaration"
                            /> 
                        </>

                    </Card.Body>
                 </Card> 
            </Col>
        </Row>
  );
  
}
