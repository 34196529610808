import { Button, Col, Container, Row } from "@themesberg/react-bootstrap";
import React, { useState } from 'react';
import PermissionCheck from '../../../../../auth/PermissionCheck';
import Preloader from "../../../../../components/Preloader";
import DocumentAdminOverlay from './DocumentAdminOverlay';
import DocumentReader from './DocumentReader';
import NavigationBar from "../Components/NavigationBar";

const DocumentPanel = ({disableTools, documentPath, updating, pageNumber, onDocumentLoadSuccess, ctx, numPages, formData, setData, canvas }) => {
    
  const [scale, setScale] = useState(1.1);
  return(
    <Row className='page'>
        <Col md={12}>
                {/* <Button onClick={()=>{setScale(scale + 0.2)}}>+</Button>
                <Button onClick={()=>{setScale(scale - 0.2)}}>-</Button> */}
            <hr className="mb-0" /> 

                <div className={!updating? "d-none" : "container"}>
                    <div className="d-flex align-items-center justify-content-center col-12">
                        <div className='text-center p-4 p-lg-5 w-100 fmxw-500'>
                            <h2 className="mb-5">Updating Certificate(s)</h2>
                            <p>This will take a little while to generate. Please wait...</p>
                            <Preloader component={true} show={updating} />
                        </div>
                        
                    </div>
                </div>
                {!disableTools &&
                    <PermissionCheck errorProps={{ disabled: true }} requiresRole={["admin"]}>
                        <DocumentAdminOverlay 
                        scale={scale}
                            totalPages={numPages}
                            documentPath={documentPath}
                            data={formData}
                            setData={setData}
                            pageNumber={pageNumber}
                            ctx={ctx}
                            canvas={canvas}
                        />
                    </PermissionCheck>
                }

                
                {/* //PDF Document embedded */}
                <DocumentReader 
                    id="react-pdf"
                    documentPath={documentPath}
                    pageNumber={pageNumber}
                    updating={updating}
                    onLoadSuccess={onDocumentLoadSuccess}
                    scale={scale}
                />
        </Col>
    </Row>
  )
}

export default DocumentPanel
