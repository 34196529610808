import { Alert, Card, Col, Row } from "@themesberg/react-bootstrap";
import { FieldArray, getIn, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import FormController from '../../../common/FormController';
import { CheckForCertificateNumber, CheckForEgg} from './FormComponents/DailyAttestationFunctions';
import DailyAttestationPoultryFields from "./FormComponents/DailyAttestationPoultryFields";
import { DairyEstablishmentValues, DairyHelperText, MeatEstablishmentValues, MeatHelperText, DateCollectionProductionHelperText, AbattoirEstablishmentValues, AbattoirHelperText } from "./FormComponents/DairyAttestationHelperText";
import moment from "moment"
import { MultiSelectField } from "../../../common/MultiSelect";
import { DateCollectionProduction } from "../../../../../components/DateCollectionProduction";

export default function DailyAttestationForm(props) {
  const {
    dailyAttestationValues,
    formField: {
      order,
      birdFluCheck,
      trackingRef,
      dailyAttestationData ,
      batchNumbers,
      batchNumberRange,
      numberOfPackages,
      numberOfUnits,
      numberOfCases,
      farmPost,
      farmPostEgg,
      slaughterDate,
      slaughterDates,
      abattoirs,
      abattoirApprovalNumbers,
      poultryMeatArrivalDate,
      dairyEstablishments,
      meatEstablishments,
      grossWeight,
      netWeight,
      netWeightTotal,
      grossWeightTotal,
      originAnimal,
      poultryCuttingPlantApprovalNumbers,
      notes,
      establishmentsSlaughterhouses,
      establishmentsDairy,
      isDateCollectionProductionMulti,
      dateCollectionProductionStart,
      dateCollectionProductionEnd,
      dateCollectionProductionSingle,
      dateCollectionProduction,
      establishmentsMeat
    },
    uniqueRef,
    errors,
    touched,
  } = props;
  
  const { values: formValues, setFieldValue} = useFormikContext();
  
    //Map Unique tracking Ref to form values
    useEffect(() => {
      formValues.uniqueCode = uniqueRef;
  }, [uniqueRef]);
   
  useEffect(() => {

    dailyAttestationValues.forEach((x, index) => {
      x.order = (index + 1)
    });
    setFieldValue('dailyAttestationData', dailyAttestationValues);

  }, [dailyAttestationValues]);


  const PrettyPrintJson = ({data}) => (<div><pre>{
    JSON.stringify(data, null, 2) }</pre></div>
);
  return (
    <>          
    <Alert variant="info" className="mt-3" col="sm">
      Complete all of the required information below for each of the product attestations selected before proceeding to the next section.
    </Alert>
      <Card border="light" className="bg-white shadow-sm mb-4 p-3">
          <Card.Body>
            <div className="attestation-urn bg-white card-border-black shadow-none p-3 text-center">
              <label>{trackingRef.label}:</label>
              <h4>{uniqueRef}</h4>
              <small className="d-block">Number of product(s): {formValues.productsSelected?.length}</small>
            </div>
            </Card.Body>
      </Card>
      {/* //Daily Attestation Data Array - Form for Each Product */}
      <FieldArray
          name={dailyAttestationData.name}>
          {arrayHelpers => {
              return (
                <Row>
                  {formValues.dailyAttestationData?.map((x,index) => (
                  <Col xl={12}>
                      <Card border="light" className="bg-white shadow-sm mb-4 p-3">
                      <Card.Header className="border-light border-bottom pb-3">
                        <h4 className="text-title">Product {index +1} - {x.productName} (EAN: - {x.ean})</h4>
                        <div className="d-block"><strong>Support Health Attestation:</strong> {x.uniqueCode}, <strong>Valid Dates:</strong> {x.validDates} {x.validTo !== undefined && <> to {x.validTo}</>}</div>
                      </Card.Header>
                      <Card.Body className="pt-0">
                        <Row>
                           <FormController
                           className="d-none"
                              control="input"
                              type="text"
                              name={`dailyAttestationData.${index}.${order.name}`}
                              isrequired="true"
                              col={6}
                            />
                          <Col md={6}>
                            {!x.hasBatches ?
                            <>
                            <FormController
                              control="input"
                              type="text"
                              label={batchNumbers.label}
                              name={`dailyAttestationData.${index}.${batchNumbers.name}`}
                              isrequired="true"
                              col={12}
                            />
                            <small className="text-info mt-2">
                              Batch numbers must be within the range of <strong>{x.attestationBatchNumbers}</strong> from as stated on the selected attestation.
                            </small>
                            </>
                            :
                            <>
                              <MultiSelectField
                              label={batchNumberRange.label}
                                placeholder="Select batch numbers(s)"
                                options={x.batches}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.number}
                                touched={`touched.dailyAttestationData.${index}.${batchNumberRange.name}`}
                                error={`errors.dailyAttestationData.${index}.${batchNumberRange.name}`}
                                isMulti={true}
                                onChange={props.setFieldValue}
                                onBlur={props.setFieldTouched}
                                isClearable={true}
                                col={12}
                                id={`dailyAttestationData.${index}.${batchNumberRange.name}`}
                                name={`dailyAttestationData.${index}.${batchNumberRange.name}`}
                                isrequired="true"
                                backspaceRemovesValue={true}
                                readOnly={false}
                                />
                            </>
                            }
                          </Col>
                          <Col md={2} lg={2} xxl={2}>
                            <FormController
                                control="input"
                                type="number"
                                label={props.values.dailyAttestationData[index]?.weightType == 1 ? numberOfUnits.label : numberOfCases.label}
                                min="0"
                                name={`dailyAttestationData.${index}.${numberOfPackages.name}`}
                                isrequired="true"
                                col={12}
                            />
                          </Col>
                          <Col md={2}>
                            <label className="mt-3">{netWeightTotal.label} ({x.weightType ==1 ? "Unit": "Case" }):</label>
                            <div className="fs-5 text-muted">
                              {(props.values.dailyAttestationData[index].netWeight * props.values.dailyAttestationData[index].numberOfPackages).toFixed(3)}kg
                            </div>
                          </Col>
                          <Col md={2}>
                            <label className="mt-3">{grossWeightTotal.label} ({props.values.dailyAttestationData[index]?.weightType ==1 ? "Unit": "Case" }):</label>
                            <div className="fs-5 text-muted">
                                {(props.values.dailyAttestationData[index].grossWeight * props.values.dailyAttestationData[index].numberOfPackages).toFixed(3)}kg
                            </div>
                          </Col>
                         
                        </Row>
                        <Row>

                          {CheckForEgg(x.attestationData) &&
                            <Col md={4}>
                              <FormController
                                  control="input"
                                  type="text"
                                  label={farmPostEgg.label}
                                  name={`dailyAttestationData.${index}.${farmPostEgg.name}`}
                                  col={12}
                              />
                            </Col>
                          }

                          {/* {JSON.stringify(formValues)} */}
                          {CheckForCertificateNumber(x,[8383,8384])
                            && AbattoirEstablishmentValues(x.attestationData) !== "" &&
                          <Col md={6}>
                              
                            {Array.isArray(AbattoirEstablishmentValues(x.attestationData)) ?
                              <MultiSelectField
                                label={abattoirs.label}
                                placeholder="Select slaughterhouse(s)"
                                options={AbattoirEstablishmentValues(x.attestationData)}
                                getOptionValue={option => option.name}
                                getOptionLabel={option => option.approvalNumber}
                                touched={`touched.dailyAttestationData.${index}.${establishmentsSlaughterhouses.name}`}
                                error={`errors.dailyAttestationData.${index}.${establishmentsSlaughterhouses.name}`}
                                isMulti={true}
                                onChange={props.setFieldValue}
                                onBlur={props.setFieldTouched}
                                isClearable={true}
                                col={12}
                                id={`dailyAttestationData.${index}.${establishmentsSlaughterhouses.name}`}
                                name={`dailyAttestationData.${index}.${establishmentsSlaughterhouses.name}`}
                                isrequired="true"
                                backspaceRemovesValue={true}
                                readOnly={false}
                                />
                            :
                            <>
                              <FormController
                                label={abattoirs.label}
                                control="input"
                                type="text"
                                touched={`touched.dailyAttestationData.${index}.${abattoirs.name}`}
                                error={`errors.dailyAttestationData.${index}.${abattoirs.name}`}
                                col={12}
                                id={`dailyAttestationData.${index}.${abattoirs.name}`}
                                name={`dailyAttestationData.${index}.${abattoirs.name}`}
                              />
                              <AbattoirHelperText attestationData={x.attestationData} />
                            </>
                            }
                          </Col>
                         }
                         
                          {CheckForCertificateNumber(x,[8350])
                            && DairyEstablishmentValues(x.attestationData) !== "" &&
                          <Col md={6}>
                            {Array.isArray(DairyEstablishmentValues(x.attestationData)) ?
                                <>
                                  <MultiSelectField
                                    label={establishmentsDairy.label}
                                    placeholder="Select dairy establishment(s)"
                                    options={DairyEstablishmentValues(x.attestationData)}
                                    getOptionValue={option => option.name}
                                    getOptionLabel={option => option.approvalNumber}
                                    touched={`touched.dailyAttestationData.${index}.${establishmentsDairy.name}`}
                                    error={`errors.dailyAttestationData.${index}.${establishmentsDairy.name}`}
                                    isMulti={true}
                                    onChange={props.setFieldValue}
                                    onBlur={props.setFieldTouched}
                                    isClearable={true}
                                    col={12}
                                    id={`dailyAttestationData.${index}.${establishmentsDairy.name}`}
                                    name={`dailyAttestationData.${index}.${establishmentsDairy.name}`}
                                    isrequired="true"
                                    backspaceRemovesValue={true}
                                    readOnly={false}
                                />
                              </>
                            :
                              <>
                                  <FormController
                                    label={dairyEstablishments.label}
                                    control="input"
                                    type="text"
                                    touched={`touched.dailyAttestationData.${index}.${dairyEstablishments.name}`}
                                    error={`errors.dailyAttestationData.${index}.${dairyEstablishments.name}`}
                                    col={12}
                                    id={`dailyAttestationData.${index}.${dairyEstablishments.name}`}
                                    name={`dailyAttestationData.${index}.${dairyEstablishments.name}`}
                                  />
                                  <DairyHelperText attestationData={x.attestationData} />
                              </>
                            }

                          </Col>
                          }


                          {CheckForCertificateNumber(x,[8390,8383,8350,8384,8396])&&
                            <Col md={6}>
                              <FormController
                                  control="input"
                                  type="text"
                                  label={originAnimal.label}
                                  name={`dailyAttestationData.${index}.${originAnimal.name}`}
                                  sublabel="(if applicable)"
                                  col={12}
                              />
                            </Col>
                          }

                          <Col md={12}>
                            <FormController
                                control="input"
                                type="text"
                                label={notes.label}
                                name={`dailyAttestationData.${index}.${notes.name}`}
                                sublabel="(if applicable)"
                                col={12}
                            />
                          </Col>
                        </Row>
                        <Row>
                        <Col md={10}>
                            <DateCollectionProduction 
                              isDateCollectionProductionMultiName={`dailyAttestationData.${index}.${isDateCollectionProductionMulti.name}`} 
                              isDateCollectionProductionMultiLabel={isDateCollectionProductionMulti.label}  
                              dateCollectionProductionStartName={`dailyAttestationData.${index}.${dateCollectionProductionStart.name}`}    
                              dateCollectionProductionStartLabel={dateCollectionProductionStart.label}   
                              dateCollectionProductionEndName={`dailyAttestationData.${index}.${dateCollectionProductionEnd.name}`}  
                              dateCollectionProductionEndLabel={dateCollectionProductionEnd.label}  
                              dateCollectionProductionSingleName={`dailyAttestationData.${index}.${dateCollectionProductionSingle.name}`}  
                              dateCollectionProductionSingleLabel={dateCollectionProductionSingle.label}  
                              dateCollectionProductionName={`dailyAttestationData.${index}.${dateCollectionProduction.name}`}
                              isReadOnly={false}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <DailyAttestationPoultryFields 
                            key={`dailyattestation-pou-${x.id}`}  
                            {...x}
                            index={index}
                            birdFluCheck={birdFluCheck}
                            farmPost={farmPost}
                            slaughterDate={slaughterDate}
                            slaughterDates={slaughterDates}
                            poultryCuttingPlantApprovalNumbers={poultryCuttingPlantApprovalNumbers}
                            abattoirApprovalNumbers={abattoirApprovalNumbers}
                            poultryMeatArrivalDate={poultryMeatArrivalDate}
                            attestationData={x.attestationData}
                            groupNames={"POU"} 
                            productDetails={x.productDetails}
                          />

                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  ))}
                </Row>
          );
        }}
      </FieldArray>
    </>
  );
}
