import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Form, Row } from '@themesberg/react-bootstrap';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import FilterDropDown from "./components/FilterDropDown";
import FilterOrderBy from "./components/FilterOrderBy";
import FilterSelection from "./components/FilterSelection";
import { clearParams, deleteParamByName, deleteParamsByNameList, getParamByName, updateParam } from "./functions/FilterParamsFunctions";
import { addMandatoryParams, convertSelectionsToUrlParams, convertURLParamsToServiceParams, convertURLParamsToSelections, convertFiltersToURLParams } from "./functions/FilterReaderFunctions";
import { isInUserRole } from "../../auth/authorisation";
export default (props) => {
  const {
    initialise,
    loaded,
    setLoaded,
    orderByOptions,
    handleSubmit, 
    handleChange, 
    defaultFilterValues = null, 
    isSubmitting, 
    ready, 
    hasSearch, 
    activePage, 
    pageSize, 
    setActivePage,
    enableUrlParams = true,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [orderBy, setOrderBy] = useState("A-Z");
  const [selections, setSelections] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [valuesReady, setValuesReady] = useState(false);

  //Update history with latest parameters
  const updateHistory = (params) => {
    history.push({
        pathname: location.pathname, 
        search: params?.toString()
    });
  }

  
  //////////////////////////////////////////////////////////////
  // URL parameter functions
 //// /////////////////////////////////////////////////////////

  //Update URL parameters / history with latest selections
  const handleSetUrlParams = (params) => {
    //var params = conv(params, activePage, pageSize, orderBy, searchText);
   // params = addMandatoryParams(params, activePage, pageSize, orderBy, searchText);
    updateHistory(params);
    return params;
  }

  //Clear URL parameters
  const handleParamsClear = async () => {
    await clearParams(location, history);
  }


  
  //////////////////////////////////////////////////////////////
  // Filter bar selections - Update selections and parameters
  //////////////////////////////////////////////////////////////
  
  //Change event of any filter selection
  const handleSelectionsChange = (selections) => {
    setLoaded(false);
    setActivePage(1);
    var search = searchText;

    //Check search param - set text box null if empty
    //var searchParam = getParamByName(location, "search");
    if(selections.find(x=>x.group == "search") == undefined){
      search = "";
      //setSearchText("");
    }

    var params = convertSelectionsToUrlParams(selections, 1, pageSize, orderBy, search);
    params = addMandatoryParams(params, 1, pageSize, orderBy, search);
    handleSetUrlParams(params);
    handleChange(selections);
  }

  //Change event of any paging change
  const handlePagingChange = () => {
    setLoaded(false);
    var params = convertSelectionsToUrlParams(selections, activePage, pageSize, orderBy, searchText);
    params = addMandatoryParams(params, activePage, pageSize, orderBy, searchText);
    handleSetUrlParams(params);
    handleChange(selections);
  }

  //Change event of order by change
  const handleOrderByChange = (value) => {
    setLoaded(false);
    setOrderBy(value)
    updateParam(location, "orderBy", value);
    var params = convertSelectionsToUrlParams(selections, activePage, pageSize, value, searchText);
  //  params = addMandatoryParams(params, activePage, pageSize, orderBy, searchText);
    handleSetUrlParams(params);
    handleChange(selections);
  }

  //Clear all selections and parameters
  const handleSelectionsClear = () => {
    setLoaded(false);
    setSelections([]);
    handleParamsClear();
    handleChange([]);
  }


  //////////////////////////////////////////////////////////////
  // Event listeners
  //////////////////////////////////////////////////////////////

  //Initial load
  useEffect(() => {
    setLoaded(false);
    if(!ready){
      return;
    }
    var serviceParams = convertURLParamsToServiceParams(location, filterValues, activePage, pageSize, orderBy);
    handleSubmit(serviceParams);
    setValuesReady(true);
  }, [ready]);

  
  //Initial load
  useEffect(() => {
    if(valuesReady && ready){
      (() => {
        var res = convertURLParamsToSelections(location, filterValues)
        setSelections(res);
      })();
    }
  }, [valuesReady, ready]);


  //URL parameters change event listener - Submit parameters
  useEffect(() => {
    if (valuesReady && ready)
    {
      setLoaded(false);
      var res = convertURLParamsToSelections(location, filterValues)
      setSelections(res);
      var serviceParams = convertURLParamsToServiceParams(location, filterValues, activePage, pageSize, orderBy);
      handleSubmit(serviceParams);
     // setLoaded(true);
      //Clear text box when search param is removed
    }
  }, [location.search]);

  // // Selections event listener - Change selections and parameters
  // useEffect(() => {
  //   if(loaded && ready ){

  //     handleSelectionsChange()
      
  //   }

  // }, [selections]);


  // Paging event listener - Change selections and parameters
  useEffect(() => {
   if(valuesReady && ready)  {
      handlePagingChange();
   }
  }, [activePage, pageSize]);


  // // Parameters object event listener - Submits parameters
  // useEffect(() => {
  //   if(loaded && selections && ready && parameters !== undefined && parameters !== null){
  //     handleSetUrlParams(parameters);
  //   }
  // }, [parameters]);

  //Search text change event - Submits parameters
  useEffect(() => {
    if(valuesReady && ready){
      setLoaded(false);
      SetSearchFieldParmsValues();
      // Cleanup the debounced function on component unmount
      return () => SetSearchFieldParmsValues.cancel();
    }
  }, [searchText]);

  //Debounce search text change event / Update search params with search text value
  const SetSearchFieldParmsValues = debounce(async () => {
    const updatedSelections = selections
    const index = updatedSelections.findIndex((selectedItem) => selectedItem.group === "search");
    if(searchText!=""){
      if (index > -1) {
        updatedSelections.splice(index, 1)
      } 
      const searchItem = { value: searchText, group: "search", label: searchText, title: "Search" }
      updatedSelections.push(searchItem);
    }
    else{
      updatedSelections.splice(index, 1)
    }
    // else{
    //   var params = await deleteParamByName(location, "search")
    //   updateHistory(params) 
    // }
      //  // Make a copy of the original array
      //  const updatedSelections = selections.map((item) => {
      //   // If the title matches, update the value property
      //   if (item.group === "search") {
      //     return { ...item, value: searchText, label: searchText  };
      //   }
      //   // Otherwise, return the original object
      //   return item;
      // });
  
      // Update the state with the modified array
      setSelections(updatedSelections);
      handleSelectionsChange(updatedSelections);
     // setLoaded(true);
  }, 1000);
  
  //Create Filter values list based on user permissions
  useEffect(() => {
    if(defaultFilterValues !== null){
      const checkPermissions = async () => {
          let newlist = await Promise.all(defaultFilterValues?.map(async (x) => {
              let hasPermission = true; // default to true
              if (x.roles) {
                  // Check if any role is in the user's role
                  const rolePermissions = await Promise.all(x.roles.map(async (roleName) => {
                      const isInRole = await isInUserRole(roleName);
                      if (isInRole) {
                          console.info(roleName + " " + x.title);
                      }
                      return isInRole;
                  }));
                  // Set hasPermission to true if any of the role checks passed
                  hasPermission = rolePermissions.some(isPermitted => isPermitted);
              }
              return hasPermission ? x : null;
          }));

          // Filter out null values (where permission was not granted)
          newlist = newlist.filter(item => item !== null);
          setFilterValues(newlist);
      };
      checkPermissions();
    }
}, [defaultFilterValues]); // Ensure dependencies are correctly listed

  return (
    <div className="px-2">
      <div className="d-flex flex-column flex-md-row mb-3">
        <div className="flex-fill px-0">
          {/* Filters selected menu */}
          <FilterSelection 
            clearParams={handleSelectionsClear}
            numberSelected={selections?.length}
            handleChange={handleSelectionsChange}
            selectedFilters={selections} // Pass the selectedFilters prop to the child component
          />
          <div className="d-inline v-line"></div>

          {/* Filter dropdowns */}
          {filterValues?.filter(x=>x.hasMenu)?.map((x, i) => {
            const uniqueData = x.data?.map((item) => ({ ...item }));// Create a new array with unique references for each dataList item
            return ( //Return each drop down in filter list
              <FilterDropDown
                title={x.title}
                key={`filterDropDown${i}`}
                data={uniqueData} // Use the uniqueData array
                hasSearch={x.hasSearch}
                name={x.name}
                numberSelected={x.data?.length}
                selectedFilters={selections} // Pass the selectedFilters prop to the child component
                handleChange={handleSelectionsChange}
              />
            );
          })}
        </div>

        <div className="d-inline p-0 text-end">
          <div className="d-inline-block px-0 filterSearch rounded-pill ">
            {hasSearch && 
              <Form.Group className="input-group m-0"> 
                <Form.Control
                  required
                  type="text"
                  className="filterSearchInput rounded-pill"
                  size="sm"
                  value={searchText}
                  placeholder="Search"
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <div className="search-icon rounded-pill">
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              </Form.Group>
            }
          </div>

          <FilterOrderBy
            key={`filterOrderBy`}
            orderBySelected={orderBy}
            isSubmitting={isSubmitting}
            ready={ready}
            options={orderByOptions}
            handleChange={handleOrderByChange}
          />
        </div>
      </div>
    </div>
  );
};


