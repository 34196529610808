export const natureCommodity8350Choices = [
    { value: "Meat product", label: "Meat product" },
    { value: "Dairy product", label: "Dairy product" },
    { value: "Colostrum-based product", label: "Colostrum-based product" },
    { value: "Aquaculture product", label: "Fishery - Aquaculture product" },
    { value: "Wild origin product", label: "Fishery - Wild origin product" },
    { value: "Egg products", label: "Egg products" },
];
export const natureCommodity8461Choices = [
    { value: 'Collagen product', label: 'Collagen product' },
    { value: 'Composite product', label: 'Composite product' },
    { value: 'Dairy product', label: 'Dairy product' },
    { value: 'Egg products', label: 'Egg products' },
    { value: 'Fishery product', label: 'Fishery product' },
    { value: 'Fresh meat', label: 'Fresh meat' },
    { value: 'Gelatine product', label: 'Gelatine product' },
    { value: 'Honey product', label: 'Honey product' },
    { value: 'Meat preparation product', label: 'Meat preparation product' },
    { value: 'Meat product', label: 'Meat product' },
    { value: 'Minced meat product', label: 'Minced meat product' }];

export const natureCommodity8461ChoicesAnnualAnimalHealthVisit = [
    { value: 'Dairy product', label: 'Dairy product' },
    { value: 'Egg products', label: 'Egg products' },
    { value: 'Fresh meat', label: 'Fresh meat' },
    { value: 'Meat preparation product', label: 'Meat preparation product' },
    { value: 'Minced meat product', label: 'Minced meat product' }];
    