import { Badge, Button, Spinner, Table } from '@themesberg/react-bootstrap';
import moment from "moment";
import React, { useState } from "react";
import Preloader from "../../components/Preloader";
import EstablishmentsService from "../../pages/services/EstablishmentsService";
import { NoResults } from "./NoResults";

export const EstablishmentScraperTable = (props) => {
  const { scrapeLinks, loaded=false} = props;
  const totalTransactions = scrapeLinks?.length;

  const getDate = (date) => {
    var newDate = moment(date).format("DD/MM/YY HH:mm");
    if (newDate === "Invalid date" || newDate === "01/01/01 00:00") {
        return "N/A";
    }
    return newDate;
  }
  
  const TableRow = (props) => {
    const { countryCode, classificationCode, classificationName, countryName, url, lastLogDate, lastLogStatus } = props;
    const [status, setStatus] = useState(lastLogStatus ?? '');
    const [isProcessing, setProcessing] = useState(false);

    //Run scrape single link function
    async function handleScrapeSingle (classificationCode, countryCode){
      setStatus("Processing");
      setProcessing(true);

      var scrape =  await EstablishmentsService.scrapeSpecificData(classificationCode, countryCode);
      var response = await scrape;
      if(response.status == 200){
        setStatus("Success");
        setProcessing(false);
      }
      else{
        setStatus("Error");
        setProcessing(false);
      }
    }
    return (
      <tr className={`bg-${isProcessing ? "gray-900" : "white"}`}>
        <td className="text-center"> 
           <Badge pill 
                  bg={status == "Success" ? "success" : status == "Error"? "danger" :  status == "Processing" ? "primary" : "light"} 
                  text={status == "Success" ? "white" : status == "Error"? "white" :  status == "Processing"? "white" : "dark"} 
                  className="badge-lg notification-count ms-2"
            >
              {status}
            </Badge>
        </td>
        <td colSpan={2}>
            {countryName} ({countryCode})
        </td>
        <td colSpan={2}> 
          <span className="fw-normal">
            {classificationCode} ({classificationName})
          </span>
        </td>
        <td className="text-center"> 
          <span className="fw-normal">
            {getDate(lastLogDate)} 
          </span>
        </td>
        <td>
          <Spinner className="p-2" hidden={!isProcessing} animation="border" size="sm" role="status" aria-hidden="true" />
        </td>
        <td> 
          <span className="fw-normal">
            <Button 
              disabled={isProcessing} 
              variant={isProcessing? "primary" : "light" }
              size="sm" 
              onClick={() => handleScrapeSingle(classificationCode, countryCode)}
            >
               Scrape
            </Button>
          </span>
        </td>
        {/* <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td> */}
      </tr>
    );
  };

  return (
      <>

        <Preloader component={true} show={!loaded} />
        <Table hidden={!loaded || scrapeLinks==0} hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom text-center">Status</th>
              <th colSpan={2} className="border-bottom">Country</th>
              <th colSpan={2} className="border-bottom">Classification</th>
              <th className="text-center border-bottom">Last scraped</th>
              <th className="border-bottom"></th>
              <th className="border-bottom"></th>
            </tr>
          </thead>
          <tbody>
            {scrapeLinks?.map(t => <TableRow key={`scrapeLinks-${t.id}`} {...t} />)}
          </tbody>
        </Table>
        {loaded && scrapeLinks?.length == 0 &&(
          <NoResults name="scrapeLinks"/>
        )}
      </>
  );
};


export default EstablishmentScraperTable;


