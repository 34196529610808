import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import * as Composite8350Labels from "../Composite8350Labels"
import { findArrayElementByValue, findArrayElementByFieldNameAndValue } from "../../../Attestations/Common/AttestationFunctions";
const Meat8350 = (props) => {
    const { attestationData } = props;
    let compositeLabels = Composite8350Labels;
    {
        if (attestationData?.meatData?.meatZoneOriginates != null) {
            var zones = attestationData?.meatData.meatZoneOriginates.map(x => x.isoCode).join(", ");
            { compositeLabels.meatOriginatesFromChoice[2].key = "a zone with code " + zones + " authorised for entry into the Union of meat products not required to undergo a specific risk-mitigating treatment as set out in Annex XV to Commission Implementing Regulation (EU) 2021/404 with assigned treatment A, and the zone where the composite product was produced is also authorised for entry into the Union of meat products with assigned treatment A." };
        }
    }
    return (
        <>
            {attestationData?.containsMeat ? (
                <li>
                    <strong>
                        <u>II.3A Meat products*</u> in any quantity except gelatine, collagen and highly refined products referred to in Section XVI of Annex III to Regulation (EC) No 853/2004, which:
                    </strong>
                    <ol>
                        <li>
                            meet the animal health requirements in Commission Delegated Regulation (EU) 2020/692 and contain the following meat constituents which are eligible for entry
                            into the Union as such and meet the criteria indicated below:
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Species*</th>
                                        <th>Treatment*</th>
                                        <th>Origin*</th>
                                        <th>Approved Establishment(s)*</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attestationData.meatData.speciesList.map((species, i) => {
                                        return (
                                            species.speciesData.map((speciesData, i) => {
                                                return (
                                                    <tr>
                                                        <td>{species.speciesGroupName}</td>
                                                        <td>{speciesData.treatment}</td>
                                                        <td>{speciesData.countryOfOrigin.map(x => x.isoCode).join(", ")}</td>
                                                        <td>{speciesData.establishmentCodeEstablishments.map(establishment => establishment.approvalNumber).join(", ")}</td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    })}
                                </tbody>
                            </table>
                        </li>
                        <li>
                            Originate from:
                            {/* {JSON.stringify(attestationData)} */}
                            <ul>
                                {attestationData.meatData.originatesFrom1 && (<li key={compositeLabels.meatOriginatesFromChoice[0].value}>{compositeLabels.meatOriginatesFromChoice[0].key}</li>)}
                                {attestationData.meatData.originatesFrom2 && (<li key={compositeLabels.meatOriginatesFromChoice[1].value}>{compositeLabels.meatOriginatesFromChoice[1].key}</li>)}
                                {attestationData.meatData.originatesFrom3 && (<li key={compositeLabels.meatOriginatesFromChoice[2].value}>{compositeLabels.meatOriginatesFromChoice[2].key}</li>)}
                            </ul>
                        </li>
                        <li>if containing material from bovine, ovine or caprine animals, with regard to bovine spongiform encephalopathy (BSE):

                            {/* No BSE Statements*/}
                            {!attestationData.meatData.bseRiskCategoryNegligible &&
                                !attestationData.meatData.bseRiskCategoryControlled &&
                                !attestationData.meatData.bseRiskCategoryUndetermined &&
                                <p>n/a</p>}

                            {/* BSE Negligible*/}
                            {attestationData.meatData.bseRiskCategoryNegligible &&
                                <ul>
                                    <li>the country or region of origin is classified in accordance with Commission Decision
                                        2007/453/EC as a country or region posing a <u>negligible BSE risk</u>, and
                                        <ul>
                                            {attestationData.meatData.bseRiskCategoryNegligibleStatements.map(statement =>
                                                <li>
                                                    {findArrayElementByValue(compositeLabels.BseRiskCategoryNegligibleStatementsChoice, statement)}
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                </ul>
                            }
                            {/* BSE Controlled */}
                            {attestationData.meatData.bseRiskCategoryControlled &&
                                <ul>
                                    <li>the country or region of origin is classified in accordance with Decision 2007/453/EC
                                        as a country or region posing a <u>controlled BSE risk</u> , and
                                        <ul>
                                            {attestationData.meatData.bseRiskCategoryControlledStatements.length > 0 &&
                                                <>
                                                    <li>
                                                        {findArrayElementByFieldNameAndValue(compositeLabels.BseRiskCategoryControlledStatementsChoice,
                                                            'meatData.BseRiskCategoryControlledStatements', 1)}
                                                    </li>
                                                    <li>
                                                        {compositeLabels.BseRiskCategoryControlledStatementsChoice.map((item) => (
                                                            attestationData.meatData.bseRiskCategoryControlledStatements.includes(item.value) && (
                                                                <li key={item.value}>
                                                                    {ReactHtmlParser(item.key)}

                                                                    {item.subclauses && (
                                                                        <ul>
                                                                            {item.subclauses.map((subItem) => (
                                                                                attestationData.meatData.bseRiskCategoryControlled4Statements.includes(subItem.value) && (
                                                                                    <li key={subItem.value}>
                                                                                        {ReactHtmlParser(subItem.key)}
                                                                                    </li>
                                                                                )
                                                                            ))}
                                                                        </ul>
                                                                    )}
                                                                </li>
                                                            )
                                                        ))}
                                                    </li>
                                                    <br></br>
                                                    <p>and</p>
                                                    {attestationData.meatData.bseRiskCategoryControlledCStatements.length > 0 &&
                                                        <>
                                                            {attestationData.meatData.bseRiskCategoryControlledCStatements.includes("1") && (<li key={compositeLabels.BseRiskCategoryControlledChoice_C[0].value}>{ReactHtmlParser(compositeLabels.BseRiskCategoryControlledChoice_C[0].key)}</li>)}
                                                            {attestationData.meatData.bseRiskCategoryControlledCStatements.includes("2") && (<li key={compositeLabels.BseRiskCategoryControlledChoice_C[1].value}>{ReactHtmlParser(compositeLabels.BseRiskCategoryControlledChoice_C[1].key)}</li>)}
                                                        </>
                                                    }
                                                </>
                                            }
                                        </ul>
                                    </li>
                                </ul>
                            }
                            {/* BSE Undetermined */}
                            {attestationData.meatData.bseRiskCategoryUndetermined &&
                                <ul>
                                    <li>the country or region of origin has not been classified in accordance with Decision 2007/453/EC or is classified as a country or region with an <u>undetermined BSE risk</u>, and
                                        <ul>
                                            {attestationData.meatData.bseRiskCategoryUndeterminedStatements.length > 0 &&
                                                <li>
                                                    {compositeLabels.BseRiskCategoryUndeterminedStatementsChoice.map((item) => (
                                                        attestationData.meatData.bseRiskCategoryUndeterminedStatements.includes(item.value) && (
                                                            <li key={item.value}>
                                                                {ReactHtmlParser(item.key)}

                                                                {item.subclauses && (
                                                                    <ul>
                                                                        {item.subclauses.map((subItem) => (
                                                                            attestationData.meatData.bseRiskCategoryUndetermined4Statements.includes(subItem.value) && (
                                                                                <li key={subItem.value}>
                                                                                    {ReactHtmlParser(subItem.key)}
                                                                                </li>
                                                                            )
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                            </li>
                                                        )
                                                    ))}
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                </ul>
                            }
                        </li>
                    </ol>
                </li>
            )
                :
                (
                    <li>II.3.A Does not contain meat products, treated stomachs, bladders and Intestines (2) in any quantity which meet the animal health requirements in Commission Decision 2007/777/EC</li>
                )
            }
        </>
    );
};

export default Meat8350 