import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useMemo, useRef, useState } from "react";
import { useTable } from "react-table";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { VetListTable } from "../../../components/Tables/VetListTable";
import { Routes } from "../../../routes";
import FilterBar from '../../filters/FilterBar';
import VetService from "../../services/VetService";
import Paging from "../common/Paging";

const VetListingPage = (props) => {
    const [vets, setVets] = useState([]);
    const [count, setCount] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const breadcrumbData = {
            item1 : {name:'Vets', link: Routes.Products.path},
            item2 : {name:'View All', link: 'View All'},
        };
    const productRef = useRef();
    const [pageSize, setPageSize] = React.useState(10);
    const [activePage, setActivePage] = React.useState(1);
    const [ready] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [defaultFilterValues, setDefaultFilterValues] = useState([]);
    const { totalPages = count, size = "md", withIcons = false, disablePrev = false } = props;
    const orderByOptions = ["Last updated", "A-Z", "Z-A"]

    const onPrevItem = () => {
      const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
      setActivePage(prevActiveItem);
    };
    
    const onPageNumberClick = (number) => {
        setActivePage(number);
    };
  
    const onNextItem = (totalPages) => {
      const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
      setActivePage(nextActiveItem);
    };

    productRef.current = vets;

    
    //End - Paging Functions

    const getVets = (params) => {
        setLoaded(false);
        VetService.getAllPaged(params)
            .then((response) => {
                const { data, totalPages, totalRecords} = response.data;
                setTotalItems(totalRecords);
                setVets(data);
                setCount(totalPages);
                setLoaded(true);
            })
            .catch((e) => {
                setLoaded(true);
                console.log(e);
            });
    };

    //Submit
    const handleFilterSubmit= (params) => {
        setSubmitting(true);
        getVets(params)
        setSubmitting(false);
    }

    const handleFilterChange = (values) => {
        setSubmitting(true);
        setSubmitting(false);
    }

    const openVet = (rowIndex) => {
        const id = productRef.current[rowIndex].id;
        props.history.push("/product/" + id);
    };
    const handlePageSizeChange = (value) => {
        setPageSize(value.value);
    };
    
    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "vetName",
        },
        {
            Header: "RCVS Number",
            accessor: "rcvsNumber",
        },
        {
            Header: "Practice name",
            accessor: "practiceName",
        },
        {
            Header: "Town/City",
            accessor: "townCity",
        },
        {
            Header: "Actions",
            accessor: "actions",
            Cell: (props) => {
                const rowIdx = props.row.id;
                return (
                    <div>
                        <span onClick={() => openVet(rowIdx)}>
                            <i className="far fa-edit action mr-2"></i>View
                        </span>
                    </div>
                );
            },
        },
    ],
        []
    );

    const {
        headerGroups,
        rows,
    } = useTable({
        columns,
        data: vets,
    });

    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            <h2 id="tabelLabel" >Vets</h2>
            <div className="col-md-12 list">
                <Row className="mt-2">
                    <Col>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <FilterBar
                                            setLoaded={setLoaded} 
                                            loaded={loaded} 
                                            ready={ready} 
                                            defaultFilterValues={defaultFilterValues}
                                            hasSearch={true}
                                            activePage={activePage}
                                            pageSize={pageSize}
                                            orderByOptions={orderByOptions}
                                            setActivePage={setActivePage}
                                            handleChange={handleFilterChange}
                                            handleSubmit={handleFilterSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <VetListTable loaded={loaded} vets={vets}/>
                                <Paging
                                        activePage={activePage}
                                        col={size}
                                        pageSize={pageSize}
                                        totalPages={totalPages}
                                        totalItems={totalItems}
                                        handleChange={handlePageSizeChange}
                                        onPrevItem={onPrevItem}
                                        onNextItem={onNextItem}
                                        onPageNumberClick={onPageNumberClick}
                                        withIcons={withIcons}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default VetListingPage;