import *  as baseService  from "./BaseService";

const invite = (data) => {
    return baseService.postApiData("/invitation/Invite", data);
};

const inviteVet = (data) => {
    return baseService.postApiData("/invitation/invite", data);
};
const inviteRetailer = (data) => {
    return baseService.postApiData("/invitation/inviteToRetailColleague", data);
};

const InvitationService = {
    invite,
    inviteVet,
    inviteRetailer
};

export default InvitationService;