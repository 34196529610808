import React from 'react';
import { Card, Accordion } from '@themesberg/react-bootstrap';
import { Col, Pagination, Button, Dropdown, Breadcrumb } from '@themesberg/react-bootstrap';

const BreadcrumbNav = (props) => {
  const { data, className = "" } = props;
 {/*console.info(data);*/}
    return (
      <div>
        

        {data.item3 != null ?(
          <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
            <Breadcrumb.Item href={data.item1.link}>{data.item1.name}</Breadcrumb.Item>
        <Breadcrumb.Item href={data.item2.link}>{data.item2.name}</Breadcrumb.Item>
        <Breadcrumb.Item active>{data.item3.name}</Breadcrumb.Item>
        </Breadcrumb>
        )
        :
        data.item2 != null ?(
          <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
            <Breadcrumb.Item href={data.item1.link}>{data.item1.name}</Breadcrumb.Item>
        <Breadcrumb.Item active>{data.item2.name}</Breadcrumb.Item>
        </Breadcrumb>
        )
        :
        (
          <Breadcrumb listProps={{ className: "breadcrumb-primary breadcrumb-transparent" }}>
          <Breadcrumb.Item active >{data.item1.name}</Breadcrumb.Item>
        </Breadcrumb>
        )
        }

        {/* <Breadcrumb.Item href={data.item2.link}>{data.item2.name}</Breadcrumb.Item> */}
        

      </div>
    );
  }

export default BreadcrumbNav;