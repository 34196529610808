import { faBullhorn, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { IsReadOnlyPermissions } from "../../../../auth/PermissionCheck";
import { scopes } from "../../../../auth/PermissionsMap";
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Routes } from "../../../../routes";
import ActivityService from "../../../services/ActivityService";
import CertificateNumberService from "../../../services/CertificateNumberService";
import CountryService from "../../../services/CountryService";
import PlatformDataService from "../../../services/PlatformService";
import ProductService from "../../../services/ProductService";
import SiteCertificateNumbersService from "../../../services/SiteCertificateNumbersService";
import SiteDataService from "../../../services/SiteService";
import TemperatureDataService from "../../../services/TemperatureService";
import ZoneService from "../../../services/ZoneService";
import FormController from "../../common/FormController";
import FormSubmission from "../../common/FormSubmission";
import UnunpermittedCertificateNumbersAlert from "../Components/UnpermittedCertificateNumbersAlert";
import {
  HsCodes8325Choices,
  HsCodes8350Choices,
  HsCodes8360Choices,
  HsCodes8367Choices,
  HsCodes8368Choices,
  HsCodes8369Choices,
  HsCodes8370Choices,
  HsCodes8371Choices,
  HsCodes8383Choices,
  HsCodes8390Choices,
  HsCodes8391Choices,
  HsCodes8396Choices,
  HsCodes8399Choices,
  HsCodes8468Choices,
} from "./Data/HsCodes";
import {
  natureCommodity8350Choices,
  natureCommodity8461Choices,
} from "./Data/NatureOfCommodity";
import { handleCommodityCodeOptions } from "./Model/Functions";
import { validationSchema } from "./Model/Validation";

const ProductDetailsAddPage = (props) => {
  const [readOnly, setReadOnly] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isCheckStarted, setCheckStarted] = useState(false);
  const [isCheckCompleted, setCheckCompleted] = useState(false);
  const [temperatures, setTemperatures] = useState([]);
  const [siteCertificateNumbers, setSiteCertificateNumbers] = useState([]);
  const [certificateNumbers, setCertificateNumbers] = useState([]);
  const [editing, setEditing] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [product, setProduct] = useState([]);
  const [ready, setReady] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [productId] = useState(props.match.params.id);
  const [productCertificateNumbers, SetProductCertificateNumbers] = useState(
    []
  );
  const [countrys, setCountrys] = useState([]);
  const [regions, setRegions] = useState([]);
  const [certificateNumberOptions, setCertificateNumberOptions] = useState([]);
  const [siteId, SetSiteId] = useState("");
  const [countryOrigin, setCountryOrigin] = useState([]);
  const [countryOriginId, setCountryOriginId] = useState("");
  const [isStorageDeclaration, setIsStorageDeclaration] = useState(false);
  const [siteOptions, setSiteOptions] = useState([]);
  const [sites, SetSites] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [temperatureOptions, setTemperatureOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const [regionOptions, setRegionOptions] = useState([]);
  const [apiValues, SetApiValues] = useState([]);
  const [unpermittedCertificateNumbers, setUnpermittedCertificateNumbers] =
    useState([]);
  const [permittedCertificateNumbers, setPermittedCertificateNumbers] =
    useState([]);
  const [title, setTitle] = useState("Add Product Detail");

  //Breadcrumb data
  const breadcrumbData = {
    item1: { name: "Products", link: Routes.Products.path },
    item2: {
      name: product?.productName,
      link: Routes.Product.path.replace(":id", productId),
    },
    item3: { name: "Product Detail", link: "#" },
  };

  //Initial values
  const initialValues = {
    productId: productId,
    fisheryType: apiValues?.fisheryType ?? "",
    eggPercentage: apiValues?.eggPercentage ?? "",
    site: apiValues?.siteId ?? null,
    siteId: apiValues?.siteId ?? "",
    platform: apiValues?.platformId ?? "",
    platformId: apiValues?.platformId ?? [],
    certificateNumber: apiValues?.certificateNumberList ?? [],
    countryOriginId: apiValues?.countryOriginId ?? "",
    regionOfOrigin: apiValues?.regionOfOriginId ?? [],
    regionOfOriginId: apiValues?.regionOfOriginId ?? "",
    description: apiValues?.description ?? "",
    natureOfCommodity: apiValues?.commodityNature ?? "",
    commodityHscode: apiValues?.commodityHscodeMultiSelect ?? null,
    commodityHscodeFreeText: apiValues?.commodityHscode ?? "",
    certifiedHumanConsumption: apiValues?.certifiedHumanConsumption ?? true,
    temperatureId: apiValues?.temperatureId ?? "",
    temperature: apiValues?.temperatureId ?? [],
    packagingTypeRetail: apiValues?.packagingTypeRetail ?? "",
    packagingTypeShelf: apiValues?.packagingTypeShelf ?? "",
    packagingTypeOuter: apiValues?.packagingTypeOuter ?? "",
    weightType: apiValues?.weightType ?? "",
    netWeight: apiValues?.netWeight ?? "",
    grossWeight: apiValues?.grossWeight ?? "",
    natureOfCommodityMultiSelect: apiValues?.natureOfCommodityMultiSelect ?? [],
    zonesVaildationRequired: false,
  };

  //Function - Submit form
  function onSubmit(values, { resetForm }) {
    setSubmitting(true);
    setTimeout(() => {
      let submitData = values;

      if (
        submitData.certificateNumber == "8461" ||
        (submitData.certificateNumber == "8350" &&
          submitData.certificateNumber.length == 1)
      ) {
        submitData.commodityNature = values.natureOfCommodityMultiSelect
          .map((x) => x.value)
          .join(", ");
      } else {
        submitData.commodityNature = values.natureOfCommodity;
      }
      if (
        submitData.certificateNumber == "8461" ||
        submitData.certificateNumber == "8384" ||
        submitData.certificateNumber == "8392" ||
        submitData.certificateNumber == "8393" ||
        submitData.certificateNumber.length > 1
      ) {
        submitData.commodityHscode = values.commodityHscodeFreeText;
      } else {
        submitData.commodityHscode = values.commodityHscode.value;
      }
      submitData.siteId = values.site.value;
      submitData.platformId = values.platform.value;
      submitData.countryOriginId = countryOriginId;

      if (values.regionOfOrigin !== null) {
        submitData.regionOfOriginId = values.regionOfOrigin.value;
      } else {
        submitData.regionOfOriginId = null;
      }

      if (values.temperature !== null) {
        submitData.temperatureId = values.temperature.value;
      }

      submitData.site = null;
      submitData.countryOrigin = null;
      submitData.regionOfOrigin = null;

      if (!editing) {
        ProductService.createDetail(submitData)
          .then(async (response) => {
            //Create Activity Log
            await ActivityService.log(
              "Added product detail",
              "Detail added for " + product.productName + "",
              "Product detail",
              "Create",
              Routes.ProductDetailAddPage.path.replace(":id", productId),
              productId
            );
            resetForm({});
          })
          .catch((e) => {
            setSubmitting(false);
            setErrors(e.response.data);
          });
      } else {
        console.info(submitData);
        ProductService.updateDetail(productId, submitData)
          .then(async (response) => {
            await ActivityService.log(
              "Edited product detail",
              "Detail edited for " + product.productName + "",
              "Product detail",
              "Update",
              Routes.ProductDetailAddPage.path.replace(":id", productId),
              productId
            );
            resetForm({});
          })
          .catch((e) => {
            setSubmitting(false);
            setErrors(e.response.data);
          });
      }
      setFormSubmitted(true);
    }, 1000);
  }

  //Function - Get initial data for lists
  useEffect(() => {
    const fetchData = async () => {
      try {
        const productResponse = await ProductService.getById(productId);
        setProduct(productResponse.data.data);
        var isStorage =
          productResponse.data.data?.isStorageDeclaration ?? false;
        setIsStorageDeclaration(isStorage);

        const siteResponse = await SiteDataService.getAll();

        // var siteOptionsData = [];

        // if (isStorage) {
        //   var filteredSiteData = siteResponse.data.filter(
        //     (x) =>
        //       x.isApproved == true && !x.establishmentNumber.startsWith("GB")
        //   );
        //   siteOptionsData = filteredSiteData;
        // } else {
        //   siteOptionsData = siteResponse.data;
        // }

        SetSites(siteResponse.data);
        // const siteOptions = siteOptionsData
        //   .map((d) => ({
        //     value: d.id,
        //     label:
        //       d.siteName +
        //       (d.establishmentNumber ? ` (${d.establishmentNumber})` : ""),
        //   }))
        //   .sort((a, b) => a.label.localeCompare(b.label));
        // setSiteOptions(siteOptions);

        const platformResponse = await PlatformDataService.getAll();
        setPlatforms(platformResponse.data);
        const platformOptions = platformResponse.data.map((d) => ({
          value: d.id,
          label: d.platformName,
        }));
        setPlatformOptions(platformOptions);

        const temperatureResponse = await TemperatureDataService.getAll();
        setTemperatures(temperatureResponse.data);
        const temperatureOptions = temperatureResponse.data.map((d) => ({
          value: d.id,
          label: d.temperatureName,
        }));
        setTemperatureOptions(temperatureOptions);

        const countryResponse = await CountryService.getAll();
        setCountrys(countryResponse.data);
        const countryOptions = countryResponse.data
          .map((d) => ({
            value: d.id,
            label: d.countryName,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCountryOptions(countryOptions);

        const zoneResponse = await ZoneService.getAllRegions();
        setRegions(zoneResponse.data);
        const zoneOptions = zoneResponse.data
          .map((d) => ({
            value: d.regionId,
            label: d.regionName,
            filter: d.countryName,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setRegionOptions(zoneOptions);
        setReady(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //Function -  Site selection change event
  const handleSiteChange = (e, val, setFieldValue) => {
    if (val != undefined && sites != undefined) {
      var selectedSiteId = val?.value;
      setFieldValue("site", val);
      var foundSite = sites.find((x) => x.id == selectedSiteId);
      var foundCountry = countrys.find((x) => x.id == foundSite?.countryId);

      setCertificateNumbers("certificateNumber", []);
      setCountryOrigin(foundCountry);
      setCountryOriginId(foundCountry?.id);
      GetPermittedCertificateNumbers(selectedSiteId);

      // setFieldValue('regionOfOriginId', '');
      // setFieldValue('regionOfOrigin', null);

      // let filteredRegions = regionOptions?.filter(x=>x.filter == countryOrigin?.countryName)
      // let filteredRegionCount = filteredRegions?.length;
      // filteredRegionCount > 0 ?
      //     setFieldValue("zonesVaildationRequired",true)
      // :
      setFieldValue("zonesVaildationRequired", false);
    }
  };

  //Function -  Site selection change event
  const changeDataBySite = (val) => {
    if (val != undefined && sites != undefined) {
      var selectedSiteId = val?.value;
      var foundSite = sites.find((x) => x.id == selectedSiteId);
      var foundCountry = countrys.find((x) => x.id == foundSite?.countryId);

      setCertificateNumbers("certificateNumber", []);
      setCountryOrigin(foundCountry);
      setCountryOriginId(foundCountry?.id);
      GetPermittedCertificateNumbers(selectedSiteId);
    }
    console.log("changeDataBySite:", selectedSiteId + foundSite);
  };

  // const handleCertificateSelection = (e, setFieldValue, certList) => {

  //     const found = certificateNumbers.find(x => x.value === e.target.value);
  //     if (found == undefined && found == null) {
  //         return;
  //     }

  //     var array = certList.slice();
  //     if (e.target.checked) {
  //         array.push(e.target.value);
  //         setFieldValue("certificateNumber", array);
  //     }
  //     else {
  //         array.splice(array.indexOf(e.target.value), 1);
  //         setFieldValue("certificateNumber", array);
  //     }
  // }

  //Function - Get existing data if editing
  useEffect(() => {
    const fetchData = async () => {
      if (ready) {
        try {
          const productResponse = await ProductService.getById(productId);
          const productData = productResponse.data.data;
          setProduct(productData);

          const isStorage = productData?.isStorageDeclaration ?? false;
          setIsStorageDeclaration(isStorage);

          const productDetailsResponse =
            await ProductService.getProductDetailByProductId(productId);

          if (productDetailsResponse && productDetailsResponse.data) {
            const data = productDetailsResponse.data;
            SetSiteId(data.siteId);
            setCountryOriginId(data.countryOriginId);
            var foundCountry = countrys.find(
              (x) => x.id == data.countryOriginId
            );
            setCountryOrigin(foundCountry);

            if (isStorage && !data.certificateNumberList.includes("8461")) {
              data.certificateNumberList = ["8461"];
            }
            data.natureOfCommodityMultiSelect = [];
            const updateNatureOfCommodity = (choices, certificate) => {
              const arrayNature = data.commodityNature.split(",");
              arrayNature.forEach((el) => {
                const found = choices?.find(
                  (x) => x.value.trim() === el.trim()
                );
                if (found) {
                  data.natureOfCommodityMultiSelect.push(found);
                }
              });
            };

            if (data.certificateNumberList.includes("8350")) {
              updateNatureOfCommodity(natureCommodity8350Choices, "8350");
            } else if (data.certificateNumberList.includes("8461")) {
              updateNatureOfCommodity(natureCommodity8461Choices, "8461");
            }
            data.regionOfOrigin = null;
            data.certificateNumberList = data.certificateNumberList.filter(
              (item) => item !== "8354"
            );

            const mapper = {
              siteId: siteOptions,
              platformId: platformOptions,
              countryOriginId: countryOptions,
              regionOfOriginId: regionOptions,
              temperatureId: temperatureOptions,
            };

            Object.keys(mapper).forEach((key) => {
              if (data[key] && mapper[key].length) {
                data[key] = mapper[key].find((x) => x.value === data[key]);
              }
            });

            changeDataBySite(data.siteId);

            const HsCodeChoices = {
              8350: HsCodes8350Choices,
              8399: HsCodes8399Choices,
              8383: HsCodes8383Choices,
              8468: HsCodes8468Choices,
              8390: HsCodes8390Choices,
              8396: HsCodes8396Choices,
              8391: HsCodes8391Choices,
              8367: HsCodes8367Choices,
              8325: HsCodes8325Choices,
              8370: HsCodes8370Choices,
              8369: HsCodes8369Choices,
              8368: HsCodes8368Choices,
              8360: HsCodes8360Choices,
              8371: HsCodes8371Choices,
            };

            if (data.commodityHscode) {
              const selectedHsCodeChoice =
                HsCodeChoices[data.certificateNumber];
              if (selectedHsCodeChoice) {
                data.commodityHscodeMultiSelect = selectedHsCodeChoice.find(
                  (x) => x.value === data.commodityHscode
                );
              }
            }

            SetApiValues(data);
            setTitle("Edit Product Detail");
            setEditing(true);
          }
        } catch (e) {
          console.log(e);
        }
      }
    };

    fetchData();
  }, [ready]);

  //Function - Check selected site against their permitted establishment certificate numbers
  const GetPermittedCertificateNumbers = (selectedSiteId) => {
    const getProductCertificateNumber = new Promise((resolve, reject) => {
      resolve(
        CertificateNumberService.getByProductId(productId)
          .then((response) => {
            SetProductCertificateNumbers(response.data);
          })
          .catch((e) => {
            console.log(e);
          })
      );
    });

    const getCertificateNumbers = new Promise((resolve, reject) => {
      resolve(
        SiteCertificateNumbersService.getBySiteId(selectedSiteId)
          .then((response) => {
            console.log(response.data);
            const options = response.data.map((d) => ({
              group: "certificateNumber",
              fieldname: "certificateNumber",
              value: d.certificateNumber.number.toString(),
              key: d.certificateNumber.name,
              active: d.certificateNumber.active,
            }));
            const options2 = response.data.map((d) => ({
              value: d.certificateNumber.name.slice(0, -8),
              key: d.certificateNumber.name.slice(0, -8),
              label: d.certificateNumber.name.slice(0, -8),
            }));

            setSiteCertificateNumbers(options2);

            if (!isStorageDeclaration) {
              setCertificateNumbers(response.data.filter((x) => x.active));
              setCertificateNumberOptions(options.filter((x) => x.active));
            }

            isStorageDeclaration &&
              CertificateNumberService.getByCertificateNumber(8461)
                .then((response) => {
                  const res = response.data;
                  var options = [];
                  options.push({
                    group: "certificateNumber",
                    fieldname: "certificateNumber",
                    value: res.number.toString(),
                    key: res.name,
                    active: res.active,
                  });
                  setCertificateNumbers([res]);
                  setCertificateNumberOptions(options.filter((x) => x.active));
                  SetProductCertificateNumbers(options.filter((x) => x.active));
                })
                .catch((e) => {
                  console.log(e);
                });
          })
          .catch((e) => {
            console.log(e);
          })
      );
      Promise.all([getCertificateNumbers, getProductCertificateNumber]).then(
        (responses) => {
          setReady(true);
        }
      );
    });
  };

  //Function - Set read only permissions
  useEffect(() => {
    setReadOnly(
      IsReadOnlyPermissions(
        [scopes.productdetail.read],
        [
          scopes.productdetail.update,
          scopes.productdetail.create,
          scopes.productnongefs.create,
          scopes.productnongefs.update,
        ]
      )
    );
  }, []);

  const FormChangeObserver = () => {
    const { values, setFieldValue } = useFormikContext();
    //Observer - RegionOptions - Check if regions require validation
    useEffect(() => {
      if (ready) {
        // var filteredRegions = regionOptions?.filter(x=>x.filter == countryOrigin?.countryName)
        // var filteredRegionCount = filteredRegions?.length;

        // console.log("regionOfOrigin:", values?.regionOfOrigin)
        // if(filteredRegionCount > 0 ){
        //     setFieldValue("zonesVaildationRequired",true);
        // }
        // else{
        setFieldValue("zonesVaildationRequired", false);
        // }
      }
    }, [values]);

    //Observer - Filter Certificate list to permitted certificate numbers
    useEffect(() => {
      setCheckStarted(true);
      if (isCheckCompleted && !isCheckStarted && !isStorageDeclaration) {
        setCheckStarted(true);
        var permittedCertificateNumbers = productCertificateNumbers.filter(
          (x) =>
            !unpermittedCertificateNumbers
              .map((item) => item.value)
              .includes(x.value)
        );

        if (permittedCertificateNumbers?.length > 0) {
          setPermittedCertificateNumbers(permittedCertificateNumbers);
        }
        setFieldValue(
          "certificateNumber",
          permittedCertificateNumbers.map((x) => x.number.toString())
        );
      }

      if (isStorageDeclaration && isCheckCompleted && !isCheckStarted) {
        var storageCertificateNumber = productCertificateNumbers.filter(
          (x) => x.value == "8461"
        );
        var permittedCertificateNumbers = productCertificateNumbers.filter(
          (x) =>
            !unpermittedCertificateNumbers
              .map((item) => item.value)
              .includes(x.value)
        );

        if (storageCertificateNumber?.length > 0) {
          setPermittedCertificateNumbers(permittedCertificateNumbers);
        }
        setCheckStarted(true);
        setFieldValue("certificateNumber", ["8461"]);
      }
    }, [isCheckCompleted, isStorageDeclaration]);
    return null;
  };

  useEffect(() => {
    if(sites==null || sites?.length==0){
        return;
    }

    var siteOptionsData = [];

    if (isStorageDeclaration) {
      var filteredSiteData = sites.filter(
        (x) => x.isApproved == true && !x.establishmentNumber.startsWith("GB")
      );
      siteOptionsData = filteredSiteData;
    } else {
      siteOptionsData = sites;
    }

    const siteOptions = siteOptionsData
      .map((d) => ({
        value: d.id,
        label:
          d.siteName +
          (d.establishmentNumber ? ` (${d.establishmentNumber})` : ""),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setSiteOptions(siteOptions);
  }, [sites, isStorageDeclaration]);

  return (
    <div>
      <BreadcrumbNav data={breadcrumbData} />
      <h2>{title}</h2>
      {formSubmitted ? (
        <FormSubmission
          errors={errors}
          title={(editing ? "Edited" : "Added") + " Product Detail"}
          message="Product detail for this product has been successfully added to the site."
          successButtonUrl={Routes.Product.path.replace(":id", productId)}
          successButtonName="Back to product"
        />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(props) => (
            <Col xl={10} xxl={8}>
              <Card border="light" className="bg-white shadow-sm mb-4">
                <Card.Body>
                  <Form>
                    {/* Site selector */}
                    <FormController
                      id="site"
                      name="site"
                      label="Manufacturing Site"
                      placeholder="Select a manufacturing site"
                      control="multiselect"
                      options={siteOptions}
                      isMulti={false}
                      isClearable={true}
                      onBlur={props.setFieldTouched}
                      onChange={(e, val) => {
                        handleSiteChange(e, val, props.setFieldValue);
                      }}
                      touched={props.touched.site}
                      errors={props.errors.site}
                      isrequired="true"
                      col={8}
                      readOnly={readOnly}
                      className="mb-4"
                    />

                    {/* Certifciate number selection */}
                    {props.values.site !== null && (
                      <FormController
                        className="border-chk clause-container mb-0"
                        control="checkboxClauses"
                        label={`Falls under`}
                        name="certificateNumber"
                        //onChange={(e, val) => {handleCertificateSelection(e, props.setFieldValue, props.values.certificateNumber, certificateNumbers); props.setFieldValue("certificateNumber", val);}}
                        options={certificateNumberOptions}
                        inlinelist={true}
                        isrequired="true"
                        col={12}
                        readOnly={readOnly}
                      />
                    )}

                    {/* Unpermitted certificate numbers alert */}
                    {isStorageDeclaration && (
                      <UnunpermittedCertificateNumbersAlert
                        isEnabled={!isStorageDeclaration}
                        setCompleted={setCheckCompleted}
                        unpermittedCertificateNumbers={
                          unpermittedCertificateNumbers
                        }
                        setUnpermittedCertificateNumbers={
                          setUnpermittedCertificateNumbers
                        }
                        siteName={sites.find((x) => x.value == siteId)?.label}
                        productCertificateNumbers={productCertificateNumbers.map(
                          (item) => item.number
                        )}
                        siteId={siteId}
                      />
                    )}

                    {/* Main form */}
                    {props.values.certificateNumber.length > 0 && (
                      <>
                        <FormController
                          className="mb-3"
                          control="multiselect"
                          id="platform"
                          name="platform"
                          label="Platform"
                          placeholder="Select a platform"
                          options={platformOptions}
                          isMulti={false}
                          isClearable={true}
                          onChange={props.setFieldValue}
                          onBlur={props.setFieldTouched}
                          touched={props.touched.platform}
                          error={props.errors.platform}
                          backspaceRemovesValue={true}
                          isrequired="true"
                          col={8}
                          readOnly={readOnly}
                        />
                        {regionOptions.filter(
                          (x) => x.filter == countryOrigin?.countryName
                        ).length > 0 ||
                          (props.values.certificateNumber?.includes("8468") && (
                            <>
                              <FormController
                                id="regionOfOrigin"
                                name="regionOfOrigin"
                                label="Region of origin"
                                placeholder="Select a region of origin"
                                control="multiselect"
                                options={regionOptions.filter(
                                  (x) => x.filter == countryOrigin?.countryName
                                )}
                                isMulti={false}
                                isClearable={true}
                                onBlur={props.setFieldTouched}
                                onChange={(e, val) => {
                                  if (val != undefined && val != null) {
                                    props.setFieldValue("regionOfOrigin", val);
                                  } else {
                                    props.setFieldValue("regionOfOrigin", null);
                                  }
                                }}
                                touched={props.touched.regionOfOrigin}
                                errors={props.errors.regionOfOrigin}
                                isrequired={props.values.zonesVaildationRequired.toString()}
                                hasBlankOption={true}
                                col={8}
                                readOnly={readOnly}
                              />
                            </>
                          ))}

                        <FormController
                          control="input"
                          type="text"
                          label="Description of commodity"
                          name="description"
                          isrequired="true"
                          col={12}
                          readOnly={readOnly}
                        />

                        <FormController
                          control="checkbox"
                          className="mt-3"
                          label="Certified for human consumption"
                          name="certifiedHumanConsumption"
                          isrequired="true"
                          readOnly={readOnly}
                        />
                        <FormController
                          control="multiselect"
                          id="temperature"
                          name="temperature"
                          label="Temperature"
                          placeholder="Select a temperature"
                          options={temperatureOptions}
                          isMulti={false}
                          isClearable={true}
                          onChange={props.setFieldValue}
                          onBlur={props.setFieldTouched}
                          touched={props.touched.temperature}
                          errors={props.errors.temperature}
                          backspaceRemovesValue={true}
                          isrequired="true"
                          col={4}
                          readOnly={readOnly}
                        />

                        {/* Nature of commodity */}
                        {(props.values.certificateNumber?.includes("8384") ||
                          props.values.certificateNumber?.includes("8383") ||
                          props.values.certificateNumber?.includes("8354") ||
                          props.values.certificateNumber?.includes("8396") ||
                          (props.values.certificateNumber?.includes("8350") &&
                            props.values.certificateNumber?.length > 1) ||
                          props.values.certificateNumber?.includes("8468")) && (
                          <div>
                            <FormController
                              control="input"
                              type="text"
                              label="Nature of commodity"
                              name="natureOfCommodity"
                              isrequired="true"
                              col={6}
                              readOnly={readOnly}
                            />
                          </div>
                        )}

                        {props.values.certificateNumber == "8350" &&
                          props.values.certificateNumber.length == 1 && (
                            <FormController
                              control="multiselect"
                              id="natureOfCommodityMultiSelect"
                              name="natureOfCommodityMultiSelect"
                              label="Nature of commodity"
                              placeholder="Nature of commodity"
                              options={natureCommodity8350Choices}
                              isMulti={true}
                              isClearable={true}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              touched={
                                props.touched.natureOfCommodityMultiSelect
                              }
                              errors={props.errors.natureOfCommodityMultiSelect}
                              backspaceRemovesValue={true}
                              isrequired="true"
                              col={4}
                              readOnly={readOnly}
                            />
                          )}

                        {props.values.certificateNumber == "8461" && (
                          <FormController
                            control="multiselect"
                            id="natureOfCommodityMultiSelect"
                            name="natureOfCommodityMultiSelect"
                            label="Nature of commodity"
                            placeholder="Nature of commodity"
                            options={natureCommodity8461Choices}
                            isMulti={true}
                            isClearable={true}
                            onChange={props.setFieldValue}
                            onBlur={props.setFieldTouched}
                            touched={props.touched.natureOfCommodityMultiSelect}
                            errors={props.errors.natureOfCommodityMultiSelect}
                            backspaceRemovesValue={true}
                            isrequired="true"
                            col={4}
                            readOnly={readOnly}
                          />
                        )}

                        {/* HS Codes */}

                        {props.values.certificateNumber == "8461" ||
                        props.values.certificateNumber == "8384" ||
                        props.values.certificateNumber == "8392" ||
                        props.values.certificateNumber == "8393" ||
                        props.values.certificateNumber?.length > 1 ? (
                          <FormController
                            control="input"
                            type="text"
                            label={`Commodity code (HS code)`}
                            name="commodityHscodeFreeText"
                            isrequired="true"
                            col={4}
                            readOnly={readOnly}
                          />
                        ) : (
                          <FormController
                            control="multiselect"
                            id="commodityHscode"
                            name="commodityHscode"
                            label="Commodity code (HS code)"
                            placeholder="Commodity code (HS code)"
                            options={handleCommodityCodeOptions(
                              props.values.certificateNumber
                            )}
                            isMulti={false}
                            isClearable={true}
                            onChange={props.setFieldValue}
                            onBlur={props.setFieldTouched}
                            touched={props.touched.commodityHscode}
                            errors={props.errors.commodityHscode}
                            backspaceRemovesValue={true}
                            isrequired="true"
                            col={4}
                            readOnly={readOnly}
                          />
                        )}

                        {/* Packaging  */}
                        <FormController
                          control="input"
                          type="text"
                          label="Type of packaging retail"
                          name="packagingTypeRetail"
                          isrequired="true"
                          col={6}
                          readOnly={readOnly}
                        />
                        <FormController
                          control="input"
                          type="text"
                          label="Type of packaging on shelf"
                          name="packagingTypeShelf"
                          isrequired="true"
                          col={6}
                          readOnly={readOnly}
                        />
                        <FormController
                          control="input"
                          type="text"
                          label="Type of packaging outer"
                          name="packagingTypeOuter"
                          isrequired="true"
                          col={6}
                          readOnly={readOnly}
                        />
                        {/* Weight  */}
                        <Row>
                          <Col lg={4} xl={3} xxl={3}>
                            <FormController
                              className="mt-2"
                              control="radio"
                              // sublabel={"<span>For a <strong>mixed case</strong> of products use unit weight. Otherwise if not a single unit of product use case weight.</span>"}
                              options={[
                                { value: 1, key: "Unit weight" },
                                { value: 2, key: "Case weight" },
                              ]}
                              label="Type of weight"
                              isrequired="true"
                              name={"weightType"}
                              col={12}
                            />
                          </Col>
                          <Col lg={6} xl={6} xxl={6}>
                            <Alert variant="info" className="my-3">
                              <div className="d-flex justify-content-start">
                                <FontAwesomeIcon
                                  icon={faInfoCircle}
                                  className="me-3 mt-1"
                                />
                                <span>
                                  For a <strong>mixed case</strong> of products
                                  use unit weight If it is not a mixed case or a
                                  single unit of product use case weight.
                                </span>
                              </div>
                            </Alert>
                          </Col>
                        </Row>

                        <FormController
                          control="input"
                          type="number"
                          label="Net Weight(kg)"
                          name="netWeight"
                          isrequired="true"
                          col={3}
                          readOnly={readOnly}
                        />
                        <FormController
                          control="input"
                          type="number"
                          label="Gross Weight(kg)"
                          name="grossWeight"
                          isrequired="true"
                          col={3}
                          readOnly={readOnly}
                        />

                        {/* Error messages  */}
                        {Object.keys(props.errors).length > 0 && (
                          <Alert className="mt-2" variant="danger" size="sm">
                            <div className="d-flex justify-content-start">
                              <FontAwesomeIcon
                                icon={faBullhorn}
                                className="me-3"
                              />
                              {/* {JSON.stringify(props.errors, null, 2)} */}
                              Some errors were found, check the form above for
                              any missing or incomplete information.
                            </div>
                          </Alert>
                        )}

                        {/* Buttons  */}
                        <div className="btn-panel left">
                          <div className="form-group mt-3">
                            <Button
                              type="submit"
                              disabled={readOnly || isSubmitting}
                            >
                              {isSubmitting ? "Please wait..." : "Save"}
                            </Button>
                            <Button
                              variant="light"
                              href={Routes.Product.path.replace(
                                ":id",
                                productId
                              )}
                            >
                              Return to Product
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    {/* Observer component for changes in form  */}
                    <FormChangeObserver />
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Formik>
      )}
    </div>
  );
};
export default ProductDetailsAddPage;
