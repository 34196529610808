export const health_1_StatementsChoice = [
    { 
        group: "health_1_statements", 
        fieldname: "health_1_statements", 
        value: "1",
        key: "carcases and parts of animals slaughtered or, in the case of game, bodies or parts of animals killed, and which are fit for human consumption in accordance with Union legislation, but are not intended for human consumption for commercial reasons;"       
    },
    {
        group: "health_1_statements", 
        fieldname: "health_1_statements", 
        value: "2",
        key: "carcases and the following parts originating either from animals that have been slaughtered in a slaughterhouse and were considered fit for slaughter for human consumption following an ante-mortem inspection or bodies and the following parts of animals from game killed for human consumption in accordance with Union legislation:"
    },
    {
        group: "health_1_statements", 
        fieldname: "health_1_statements", 
        value: "3",
        key: "blood of animals which did not show any signs of disease communicable through blood to humans or animals, obtained from animals that have been slaughtered in a slaughterhouse after having been considered fit for slaughter for human consumption following an ante-mortem inspection in accordance with Union legislation;"
    },
    {
        group: "health_1_statements", 
        fieldname: "health_1_statements", 
        value: "4",
        key: "animal by-products arising from the production of products intended for human consumption, including degreased bone, greaves and centrifuge or separator sludge from milk processing;"
    },
    {
        group: "health_1_statements", 
        fieldname: "health_1_statements", 
        value: "5",
        key: "aquatic animals, and parts of such animals, expect sea mammals, which did not show any signs of disease communicable to humans or animals:"
    },
    {
        group: "health_1_statements", 
        fieldname: "health_1_statements", 
        value: "6",
        key: "animal by-products from aquatic animals originating from plants or establishments manufacturing products for human consumption;"
    },
    {
        group: "health_1_statements", 
        fieldname: "health_1_statements", 
        value: "7",
        key: "material from animals which have been treated with certain substances which are prohibited by Council Directive 96/22/EC (2a), the import of the material being permitted in accordance with Article of Regulation (EC) No 1069/2009;"
    },
]


export const health_1_2_StatementsChoice = [
    { 
        group: "health_1_2_statements", 
        fieldname: "health_1_2_statements", 
        value: "1",
        key: "carcases or bodies and parts of animals which are rejected as unfit for human consumption in accordance with Union legislation, but which did not show any signs of disease communicable to humans or animals; ",
    },
    {
        group: "health_1_2_statements", 
        fieldname: "health_1_2_statements", 
        value: "2",
        key: "heads of poultry;"
    },
    {
        group: "health_1_2_statements", 
        fieldname: "health_1_2_statements", 
        value: "3",
        key: "hides and skins, including trimmings and splitting thereof, horns and feet, including the phalanges and the carpus and metacarpus bones, tarsus and metatarsus bones;"
    },
    {
        group: "health_1_2_statements", 
        fieldname: "health_1_2_statements", 
        value: "4",
        key: "pig bristles;"
    },
    {
        group: "health_1_2_statements", 
        fieldname: "health_1_2_statements", 
        value: "5",
        key: "feathers;" 
        
    }
]

export const health_2_StatementsChoice = [
    { 
        group: "health_2_statements", 
        fieldname: "health_2_statements", 
        value: "1",
        key: "in the case of dogchews made from hides and skins of ungulates or from fish, to a treatment sufficient to destroy pathogenic organisms (including salmonella); and the dogchews are dry;",
    },
    {
        group: "health_2_statements", 
        fieldname: "health_2_statements", 
        value: "2",
        key: "in the case of dogchews made from animal by-products other than hides and skins of ungulates or from fish, to a heat treatment of at least 900C throughout their substance;"
    },
]

export const health_6_1_statementsChoice = [
    { 
        group: "health_6_1_statements", 
        fieldname: "health_6_1_statements", 
        value: "1",
        key: "is derived from other ruminants than bovine, ovine or caprine animals.",
    },
    {
        group: "health_6_1_statements", 
        fieldname: "health_6_1_statements", 
        value: "2",
        key: "is derived from bovine, ovine or caprine animals and does not contain and is not derived from:"
    },
]
export const health_6_2_statementsChoice = [
    { 
        group: "health_6_2_statements", 
        fieldname: "health_6_2_statements", 
        value: "1",
        key: "bovine, ovine and caprine materials other than those derived from animals born, continuously reared and slaughtered in a country or region classified as posing a negligible BSE risk in accordance with Decision 2007/453/EC.",
    },
    {
        group: "health_6_2_statements", 
        fieldname: "health_6_2_statements", 
        value: "2",
        key: "specified risk material as defined in point 1 of Annex V to Regulation (EC) No 999/2001 of the European Parliament and of the Council;<br><br>   mechanically separated meat obtained from bones of bovine, ovine or caprine animals, except from those animals that were born, continuously reared and slaughtered in a country or region classified as posing a negligible BSE risk in accordance with Commission Decision 2007/453/EC), in which there has been no indigenous BSE case,<br><br>  animal by-product or derived product obtained from bovine, ovine or caprine animals which have been killed, after stunning, by laceration of the central nervous tissue by means of an elongated rod-shaped instrument introduced into the cranial cavity, or by means of gas injected into the cranial cavity, except for those animals that were born, continuously reared and slaughtered in a country or region classified as posing a negligible BSE risk in accordance with Decision 2007/453/EC."
    },    
]

