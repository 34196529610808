export default {
  formId: 'attestationRequestNonGEFSForm',
  formField: {
    vetId: {
      name: 'vetId',
      label: 'Select a Vet to review the Attestation Request',
      requiredErrorMsg: 'vet is required'
    },
    isGEFS: {
      name: 'isGEFS',
      label: 'isGEFS',
      requiredErrorMsg: 'isGEFS required'
    },
  }
};
  
