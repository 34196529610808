import React , {useState, useEffect} from 'react';
import { Alert, Table } from '@themesberg/react-bootstrap';
import { GetCertificateFormData, GetCertificateGenerationExclusions } from '../../CertificateForms/CertificateFormFunctions';
import CertificateNumberService from '../../../services/CertificateNumberService';
import ConsignmentContentsTableRow from './ConsignmentContentsTableRow';
import ProductService from '../../../services/ProductService';

const ConsignmentContentsTable = (props) => {
    const {
        setCombineSelectionValues,
        setCombineUniqueSelections,
        combineUniqueSelections,
        selections, 
        isCombineMode, 
        setCombineMode, 
        setCombineMasterId,
        combineMasterId,
        setCombinePreviousSelections,
        combineSelections,
        combineCertificateNumber,
        setCombineCertificateNumber,
        setCombineSelections,
        setSelections, 
        isApproved,
        setFormChanged,
        combinationsMax = 5,
        noMatchesFound,
        setNoMatchesFound,
        setFormSubmitted
    } = props;

    const [comboCheckComplete, setComboCheckComplete] = useState(false);
    const [combineSelections2, setCombineSelections2] = useState([]);
    const [possibleComboDataList, setPossibleComboDataList] = useState([]);
   
    //Combine Certificate Select
    const _handleCombineCertificateMode = (selection) => {
        setCombinePreviousSelections(combineSelections);
        var masterId = selection.consignmentContentsId ;
        if(selection.isStorageDeclaration){
            var certNumberList = selection.certificateNumbers;
            setCombineCertificateNumber(certNumberList);
        }
        else{
            var certNumberList = selection.attestation.certificateNumberList;
            setCombineCertificateNumber(certNumberList[0]);
        }
        handleCombineSelectItem(selection);
        setCombineMasterId(masterId);
        setCombineMode(true);
        setCombineUniqueSelections([]);
    }

    //Remove from daily attestation / product list
    const _handleRemoveProduct = (id) => {
        const res = selections.filter((x) => x.consignmentContentsId !== id);
        setSelections(res);
        setFormChanged(true);
        return res;
    }

    //Remove combination group 
    const _handleRemoveGroup = (masterId) => {

        var filteredCombineGroup = combineSelections
                .filter(y=>y.consignmentContentsMasterId !== masterId 
                    && masterId !== null);

        var masterSelections = combineSelections.filter(y=>y.consignmentContentsMasterId === masterId)
        .map(x=> {return x.consignmentContentsId});
        
        const res = selections.filter((x) => !masterSelections.includes(x.consignmentContentsId));
        setCombineSelections(filteredCombineGroup);
        setCombineSelections2(filteredCombineGroup);
        setCombineSelectionValues("combineSelections",filteredCombineGroup);
        setSelections(res);
        setFormChanged(true);
    }

    //Unselect single combined item
    const removeCombineSelectItem= (id) => {
        setCombineSelections(current =>
            current.filter(selection => {
            return selection.consignmentContentsId !== id && selection !== null;
            }),
        );
        setFormChanged(true);
    }

    //Function - Get Gertificate form data from product id and the certificate numbers required on this product
    async function fetchCertFormData(selectedCertificateNumbers, productId) {
        const x = await GetCertificateFormData(productId, selectedCertificateNumbers, false);
        return x;
    }

    //Uncombine group
    const removeCombineGroupSelectItem= (masterId) => {
        var filteredCombineGroup = combineSelections
                .filter(y=>y.consignmentContentsMasterId !== masterId 
                    && masterId !== null);
        setCombineSelections(filteredCombineGroup);
        setCombineSelections2(filteredCombineGroup);
        setCombineSelectionValues("combineSelections",filteredCombineGroup);
        var newSelections = [];
        selections.forEach(selection => {
            selection.combinations.forEach(y=>{
                    if(y.consignmentContentsMasterId == masterId){
                        selection.combinations = [];
                        selection.certificates = [];
                        selection.documents = [];
                    }
                })
                newSelections.push(selection)
            }
        );
        setSelections(newSelections);
        setFormChanged(true)
        
    }

    //Combine Select /Deselect
    async function handleCombineSelectItem(item){
        var itemId = item.consignmentContentsId;
        let currentMasterId = combineMasterId!== null && combineMasterId !==undefined? combineMasterId : itemId;
        let id = itemId;
        let certificateNumberList = 

        item.isStorageDeclaration? 
            item.certificateNumbers
            :
            item.attestation?.certificateNumberList?  
                item.attestation?.certificateNumberList
                :
                    item.attestation?.certificateNumberList ?
                        item.attestation?.certificateNumberList 
                     :
                        item.attestation?.certificateNumber;
                

        if(combineMasterId == null || combineMasterId == undefined){
            setCombineMasterId(id);
        }

        if(item.attestation != undefined || item.isStorageDeclaration){

            //Get certificate numbers from selected item
            CertificateNumberService.getAll()
            .then(async (response) => {
                let selectedCertificateNumbers = await response.data.filter(x => certificateNumberList.includes(x.number.toString()));
                //Create a certificate data object and check for possible matches
                var productId = item.isStorageDeclaration?  item.dailyAttestationItems.productId : item.attestation.productId;
                await fetchCertFormData(selectedCertificateNumbers, productId)
                .then(async certFormData=> {  
                    await CheckForCombineMatches(certificateNumberList);
                });
            });
        }

        const res = selections.filter((x) => x.consignmentContentsId == id || x.consignmentContentsId == item.id);
        //Set combinations data
        res.forEach(e => {
            var isPrimary=  false;
            if(currentMasterId == e.consignmentContentsId){
                isPrimary= true;
            }
            var obj = 
            {
                isPrimary: isPrimary,
                consignmentContentsMasterId: currentMasterId,
                consignmentContentsId: e.consignmentContentsId
            }
            
            setCombineSelections(current => [...current, obj]);
            setCombineSelections2(current => [...current, obj]);
            setCombineSelectionValues(current => [...current, obj]);
        });
        setFormChanged(true);

    return combineSelections;
    }

    //Exclude properties from the data provided for matching later
    const setPropertyExclusion = (certificateObject, exclusions) =>{
        // console.info("certificateObject")
        // console.info(certificateObject)
        Object.entries(certificateObject).forEach(([key, value])=>{
            if(Array.isArray(value)){
                value.forEach((x)=>{
                    setPropertyExclusion(x, exclusions);
                });
            }
            if(typeof value === 'object' && !Array.isArray(value)){
                if(value !== null)
                {                   
                    setPropertyExclusion(value, exclusions)                
                }
            }
            //Exclude any property id's as these will be unique
            if(key.includes('Id') || key.startsWith('id') || key == 'version'){
                certificateObject[key] = null;
            }
            var itemExclusion = exclusions?.propertyNames.find(x=>x == key)
            if(itemExclusion!==undefined){
                certificateObject[key] = null;
            }
        })
        return certificateObject;
    }

    //Check for matches on the page for the provided certificate number list
    const CheckForCombineMatches = async (number) =>{
        setPossibleComboDataList([]);
        setComboCheckComplete(false);

        //Get certificate numbers and filter the provided cartificate numbers on item
        const response = await CertificateNumberService.getAll()
        const result = await response.data;
        var certNumber = number[0];
        var cerNumberMatches = selections.filter(x=> 
            x.isStorageDeclaration? x.certificateNumbers?.includes(certNumber) 
            : 
            x.certificateNumbers !== undefined ? x.certificateNumbers.includes(certNumber) 
            :
                x.attestation?.certificateNumberList.includes(certNumber) 
            )
        
        var counter = 0
        //Loop through each possible combinations 
        for (const item of cerNumberMatches) {
            counter++
            var id = item.consignmentContentsId;
            let certList =  item.isStorageDeclaration? item.certificateNumbers : item.attestation.certificateNumberList

            let selectedCertificateNumbers ="";
            if(Array.isArray(certList) || certList?.length>0)
            {
                selectedCertificateNumbers = result.filter(x => certList.includes(x.number.toString()));
            }
            else{
                selectedCertificateNumbers = result.filter(x => certList == x.number.toString());
            }

            //Get product / certificate info data from productJSON
            var productJson = JSON.parse(item.productJson);

            //Specific added properties to check
            var productDetail = productJson.productDetail;
            var countryOfOrigin = productDetail?.countryOriginId;
            var weightType = productDetail?.weightType;

            //Data for the attestation
            var certData = productJson.attestationData;
            
            //Create a certificate data object and check for possible matches
            var certificateObject = certData[certNumber];

            //Set country of origin
            certificateObject["countryOfOrigin"] = countryOfOrigin;
            certificateObject["weightType"] = weightType.toString();

            //Custom check - 8350 / GEFS - Dairy Establishments
            if(certNumber.toString()=="8350" && item.isGEFS){
                var dairyEstablishments = item?.dailyAttestationItems?.establishmentsDairy?.map(x=> x.approvalNumber).sort().join(',')
                certificateObject["dairyEstablishments"] = dairyEstablishments;
            }
            
            //Exclude certian properties from the list for matching (non unique objects such as names with id's)
            var exclusions = GetCertificateGenerationExclusions()?.find(x=>x.number==certNumber);
            var certificateObjectWithExclusions = setPropertyExclusion(certificateObject, exclusions);
            
            //Log certificate object being compared
            console.info("Object being compared:")
            console.info(certificateObjectWithExclusions)

            //Set possible combinations, based on certificate number matches in the content list
            if(certNumber!==undefined){
                setPossibleComboDataList(currentDict => ({
                    ...currentDict,
                    [id]: certificateObjectWithExclusions
                }));
            }
            // Sort the data array using the custom sorting function
            if(counter == cerNumberMatches.length){
                setComboCheckComplete(true);
            }
        }
    }

    //Get distinct possible combos, ones which have excactly the same Part 2 data
    useEffect(() => {
        if(comboCheckComplete){
            var uniqueIds = filterUniqueCombinations(possibleComboDataList);
           // uniqueIds.filter(x=>x !== combineMasterId);
            setCombineUniqueSelections(uniqueIds);
            setComboCheckComplete(false);
            if(uniqueIds.length <= 0){
                setNoMatchesFound(true);
            }
       }
    }, [comboCheckComplete]);


    //Filter a dictionary of {id,comboItem} to check for unique matches
    function filterUniqueCombinations(possibleComboDataList) {
        const idArray = [];
        if(possibleComboDataList?.length > 0){
            return null;
        }
        var masterData = possibleComboDataList[combineMasterId];
        Object.keys(possibleComboDataList).map((id) => {
            var item = possibleComboDataList[id];
                if(isObjectEqual(masterData, item)
                    && id !== combineMasterId){
                    idArray.push(id);
                }
        });
        return idArray;
    }

    function isObjectEqual(obj1, obj2) {
        // Compare if the two objects are equal
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }
    const PrettyPrintJson = ({data}) => (<div><pre>{
        JSON.stringify(data, null, 2) }</pre></div>
    );


    return(
        <>
        {noMatchesFound && isCombineMode && 
            <Alert variant='info' className='' >
                No possible combinations found for this product. Exit combine mode and try another product.      
            </Alert>
        }
        {selections?.length > 0 &&
            <Table hover className="align-items-center table-flush table-ellipsis">
            <thead>
                <tr>
                    <th className="border-bottom" colSpan={1}>Packages</th>
                    <th className="border-bottom" colSpan={5}>Product / Attestation Details</th>
                    <th className="border-bottom" colSpan={2}>Certificate</th>
                    <th className="border-bottom" colSpan={1}></th>
                </tr>
            </thead>
            <tbody>  

                {selections
                .filter(x=> x.combinations[0]?.isPrimary!=false)
                 //.sort((a, b) => (a.combinations.length - b.combinations.length))
                   // .filter(f=> !f.dailyAttestationItems.uniqueCode.includes("COMPOUND")) //Temprorary filter until compound is enabled
                    .map((x, i)=>
                        <ConsignmentContentsTableRow 
                            combineUniqueSelections={combineUniqueSelections}
                            selection={x}
                            isApproved={isApproved}
                            selections={selections}
                            isGEFS={x.isGEFS}
                            isStorageDeclaration={x.isStorageDeclaration}
                            commodityCode={x.commodityCode}
                            netWeightTotal={x.netWeightTotal}
                            grossWeightTotal={x.grossWeightTotal}
                            numberOfCasesUnits={x.numberOfPackages}
                            isCompound={x.isCompound}
                            compound={x.compound}
                            key={`attestation-${x.id + i}`}
                            handleRemoveGroup={_handleRemoveGroup}
                            handleRemoveProduct={_handleRemoveProduct}
                            handleCombineCertificateMode={_handleCombineCertificateMode}
                            removeCombineSelectItem={removeCombineSelectItem}
                            removeCombineGroupSelectItem={removeCombineGroupSelectItem}
                            isCombineMode={isCombineMode}
                            combineCertificateNumber={combineCertificateNumber}
                            combineMasterId={combineMasterId}
                            combinationsMax={combinationsMax}
                            combineSelections={combineSelections}
                            handleCombineSelectItem={handleCombineSelectItem}
                            setFormSubmitted={setFormSubmitted}
                            //{...selection}
                        />
                    )
                }
            </tbody>
            </Table>
        }
        </>
    )

    
};


export default ConsignmentContentsTable