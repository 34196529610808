import { Badge, Button, Spinner, Table } from '@themesberg/react-bootstrap';
import moment from "moment";
import React, { useState } from "react";
import Preloader from "../Preloader";
import NoticeboardService from "../../pages/services/NoticeboardService";
import { NoResults } from "./NoResults";

export const NoticeboardAdminTable = (props) => {
  const { noticeboard, loaded=false} = props;
  const totalTransactions = noticeboard?.length;

  const getDate = (date) => {
    var newDate = moment(date).format("DD/MM/YY HH:mm");
    if (newDate === "Invalid date" || newDate === "01/01/01 00:00") {
        return "N/A";
    }
    return newDate;
  }
  
  const TableRow = (props) => {
    const { category, title, dateStart, dateEnd, resolved, createdDate } = props;
    const [status, setStatus] = useState(createdDate ?? '');
    const [isProcessing, setProcessing] = useState(false);

    //Run scrape single link function
    async function handleScrapeSingle (classificationCode, countryCode){
      setStatus("Processing");
      setProcessing(true);

      var scrape =  await NoticeboardService.scrapeSpecificData(classificationCode, countryCode);
      var response = await scrape;
      if(response.status == 200){
        setStatus("Success");
        setProcessing(false);
      }
      else{
        setStatus("Error");
        setProcessing(false);
      }
    }
    return (
      <tr className={`bg-${isProcessing ? "gray-900" : "white"}`}>
        <td className="text-center"> 
           <Badge pill 
                  bg={status == "Success" ? "success" : status == "Error"? "danger" :  status == "Processing" ? "primary" : "light"} 
                  text={status == "Success" ? "white" : status == "Error"? "white" :  status == "Processing"? "white" : "dark"} 
                  className="badge-lg notification-count ms-2"
            >
              {status}
            </Badge>
        </td>
        <td colSpan={2}>
            {category}
        </td>
        <td colSpan={2}>
            {title}
        </td>
        <td colSpan={2}> 
          <span className="fw-normal">
            {getDate(dateStart)} 
          </span>
        </td>
        <td className="text-center"> 
          <span className="fw-normal">
            {getDate(dateEnd)} 
          </span>
        </td>
        <td>
          <Spinner className="p-2" hidden={!isProcessing} animation="border" size="sm" role="status" aria-hidden="true" />
        </td>
        <td> 
          <span className="fw-normal">
            {/* <Button 
              disabled={isProcessing} 
              variant={isProcessing? "primary" : "light" }
              size="sm" 
              // onClick={() => handleScrapeSingle(classificationCode, countryCode)}
            >
               Scrape
            </Button> */}
          </span>
        </td>
        {/* <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td> */}
      </tr>
    );
  };

  return (
      <>

        <Preloader component={true} show={!loaded} />
        <Table hidden={!loaded || noticeboard==0} hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Category</th>
              <th className="border-bottom">Title</th>
              <th className="border-bottom">Start</th>
              <th className="border-bottom">End</th>
              <th className="border-bottom">Resolved</th>
              <th className="border-bottom"></th>
            </tr>
          </thead>
          <tbody>
            {noticeboard?.map(t => <TableRow key={`noticeboard-${t.id}`} {...t} />)}
          </tbody>
        </Table>
        {loaded && noticeboard?.length == 0 &&(
          <NoResults name="noticeboard"/>
        )}
      </>
  );
};


export default NoticeboardAdminTable;


