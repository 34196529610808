import React from 'react';

export const CheckForCertificateNumber =(itemData, targetCertificateNumbers) =>{
    var res = itemData?.productDetails?.certificateNumbers
            .filter(item => targetCertificateNumbers.includes(item.number))
    if(res?.length > 0){
        return true;
    }
    return false;
}

export const CheckForEgg = (attestationData) => {
    if (attestationData !== undefined && attestationData !== null) {
        if (attestationData[8350]?.containsEgg === true) {
            return true;
        }
        if (attestationData[8360] !== undefined && attestationData[8360] !== null && Object.keys(attestationData[8360]).length > 0) {
            return true;
        }
    }
    return false;
};
export const DoesContainCertificateNumber =(itemData, targetCertificateNumbers) =>{
    var certs = itemData?.attestation?.certificateNumberList;
    const found = certs.some(r=> targetCertificateNumbers.includes(r))
    if(found != undefined && found != null){
        return true;
    }
    return false;
}
