import React, { useState, useEffect } from 'react';
import { Routes } from "../../../routes";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Formik, Form, useFormikContext } from 'formik';
import FormController from "../common/FormController"
import PlatformService from "../../services/PlatformService";
import BorderControlPostService from "../../services/BorderControlPostService";
import CountryService from "../../services/CountryService";
import ConsignmentService from "../../services/ConsignmentService";
import { Button, Card, Col, Alert, Row } from "@themesberg/react-bootstrap";
import FormSubmission from "../common/FormSubmission";
import * as Yup from 'yup';
import moment from 'moment-timezone';
import CertificateLanguageService from '../../services/CertificateLanguageService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrganisationService from '../../services/OrganisationService';
import { faBullhorn, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ConsignmentContainerImages from './ConsignmentContainerImages';
import ConsignmentSealImages from './ConsignmentSealImages';


const ConsignmentAddPage = (props) => {
    const [title, setTitle] = useState("Add Consignment");
    const [breadcrumbData, setBreadcrumbData] = useState({
        item1: { name: 'Consignments', link: Routes.ConsignmentListingPage.path }
    });
    const [isSubmitting, setSubmitting] = useState(false);
    const [consignmentId] = useState(props.match?.params?.id);
    const [cloneId] = useState(props.cloneId);
    const [defaultPlaceOfLoading, setDefaultPlaceOfLoading] = useState('');
    const [editing, setEditing] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showCertificateLanguageChange, setShowCertificateLanguageChange] = useState(false);
    const [ready, setReady] = useState(false);
    const [apiValues, setApiValues] = useState([]);
    const [platformDetails, setPlatformDetails] = useState(null);
    const [organisationName, setOrganisationName] = useState('');
    const [organisationAddress, setOrganisationAddress] = useState('');
    const [errors, setErrors] = useState(null);
    const [platforms, setPlatforms] = useState([]);
    const [countrys, SetCountrys] = useState([]);
    const [nonEUCountries, SetNonEUCountries] = useState([]);
    const [borderControlPosts, SetBorderControlPosts] = useState([]);
    const [productId] = useState(props.productId);
    const [certificateLanguages, SetCertificateLanguages] = useState([]);
    const [SealErrorMessage, setSealErrorMessage] = useState(null);
    const [ContainerErrorMessage, setContainerErrorMessage] = useState(null);
    const [sealNumber, setSealNumber] = useState('');
    const [containerNumber, setContainerNumber] = useState('');


    const initialValues = {
        exporterName: apiValues?.exporterName ?? organisationName,
        exporterAddress: apiValues?.exporterAddress ?? organisationAddress,
        platformId: apiValues?.platformId ?? null,
        consignmentName: apiValues?.consignmentName ?? '',
        borderControlPostId: apiValues?.borderControlPostId ?? null,
        departureDate: apiValues?.departureDate ?? null,
        placeOfLoading: apiValues?.placeOfLoading ?? '',
        destinationCountryId: apiValues?.destinationCountryId ?? null,
        destinationName: apiValues?.destinationName ?? '',
        destinationAddress1: apiValues?.destinationAddress1 ?? '',
        destinationAddress2: apiValues?.destinationAddress2 ?? '',
        destinationApprovalNumber: apiValues?.destinationApprovalNumber ?? '',
        operatorCountryId: apiValues?.operatorCountryId ?? null,
        operatorName: apiValues?.operatorName ?? '',
        operatorAddress1: apiValues?.operatorAddress1 ?? '',
        operatorAddress2: apiValues?.operatorAddress2 ?? '',
        consigneeCountryId: apiValues?.consigneeCountryId ?? null,
        consigneeName: apiValues?.consigneeName ?? '',
        consigneeAddress1: apiValues?.consigneeAddress1 ?? '',
        consigneeAddress2: apiValues?.consigneeAddress2 ?? '',
        transportPlane: apiValues?.transportPlane ?? false,
        transportShip: apiValues?.transportShip ?? false,
        transportRail: apiValues?.transportRail ?? false,
        transportRoad: apiValues?.transportRoad ?? false,
        transportOther: apiValues?.transportOther ?? false,
        transportOtherName: apiValues?.transportOtherName ?? '',
        transportVesselName: apiValues?.transportVesselName ?? '',
        documentType: apiValues?.documentType ?? '',
        documentCode: apiValues?.documentCode ?? '',
        documentCommercialRef: apiValues?.documentCommercialRef ?? '',
        documentCountry: apiValues?.documentCountry ?? null,
        sealNumber: apiValues?.sealNumber ?? '',
        containerNumber: apiValues?.containerNumber ?? '',
        importForEU: apiValues?.importForEU ?? true,
        forTransit: apiValues?.forTransit ?? false,
        certificateLanguageId: apiValues?.certificateLanguageId ?? null,
        thirdCountryId: apiValues?.thirdCountryId ?? null
    }

    let validationMaxLength = "Exceeded maximum length allowed for this field";
    let validationMsgRequired = "This field is required";

    const validationSchema = Yup.object().shape({
        consignmentName: Yup.string().nullable().required(validationMsgRequired),
        exporterName: Yup.string().nullable().required(validationMsgRequired),
        exporterAddress: Yup.string().nullable().required(validationMsgRequired),
        platformId: Yup.object().nullable().required(validationMsgRequired),
        destinationCountryId: Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required()
        }).typeError(validationMsgRequired),
        operatorCountryId: Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required()
        }).typeError(validationMsgRequired),
        certificateLanguageId: Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required()
        }).typeError(validationMsgRequired),
        borderControlPostId: Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required()
        }).typeError(validationMsgRequired),
        placeOfLoading: Yup.string().nullable().required(validationMsgRequired).max(65, validationMaxLength),
        thirdCountryId: Yup.object().nullable().when('forTransit', {
            is: true,
            then: Yup.object().nullable().required(validationMsgRequired)
        }),
        transportVesselName: Yup.string().nullable().when('transportShip', {
            is: true,
            then: Yup.string().nullable().required(validationMsgRequired)
        }),
        departureDate: Yup.date().nullable().required("Must enter departure date"),
        destinationName: Yup.string().required("Must enter destination name").max(100, validationMaxLength),
        destinationAddress1: Yup.string().required("Must enter destination address").max(100, validationMaxLength),
        sealNumber: Yup.string().required("Must enter a seal number").max(100, validationMaxLength),
        containerNumber: Yup.string().required("Must enter container number").max(100, validationMaxLength)
    });
    const handleContainerErrorChange = (newErrorsChange) => {
        setContainerErrorMessage(newErrorsChange);
    };
    const handleSealErrorChange = (newErrorsChange) => {
        setSealErrorMessage(newErrorsChange);
    };
    const handleSealNumberChange = (newSealNumber, formik) => {
        console.log("sealNumber", newSealNumber);
        formik.setFieldValue("sealNumber", newSealNumber);
    };
    const handleContainerNumberChange = (newContainerNumber, formik) => {
        console.log("containerNumber", newContainerNumber);
        formik.setFieldValue("containerNumber", newContainerNumber);
    };
    //Submit form
    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            if (cloneId != null) {
                submitData.id = null;
            }
            if (submitData.consignmentName === null) {
                submitData.consignmentName = "Consignment to " + values.destinationCountryId.label;
            }
            if (values.platformId !== null) {
                submitData.platformId = values.platformId.value;
            }
            if (values.destinationCountryId !== null) {
                submitData.destinationCountryId = values.destinationCountryId.value;
            }
            if (values.consigneeCountryId !== null) {
                submitData.consigneeCountryId = values.consigneeCountryId.value;
            }
            if (values.operatorCountryId !== null) {
                submitData.operatorCountryId = values.operatorCountryId.value;
            }
            if (values.thirdCountryId !== null) {
                submitData.thirdCountryId = values.thirdCountryId.value;
            }
            if (values.borderControlPostId !== null) {
                submitData.borderControlPostId = values.borderControlPostId.value;
            }
            if (values.certificateLanguageId !== null) {
                submitData.certificateLanguageId = values.certificateLanguageId.value;
            }

            console.info(submitData);
            if (!editing || cloneId != null) {
                ConsignmentService.create(submitData)
                    .then(response => {
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            else {
                ConsignmentService.update(consignmentId, submitData)
                    .then(response => {
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            setFormSubmitted(true);
        }, 1000);
    }

    useEffect(() => {
        async function fetchListData() {
            try {
                const getBorderControlPostData = new Promise((resolve, reject) => {
                    resolve(BorderControlPostService.getAll()
                        .then((response) => {
                            //alert(JSON.stringify(response.data));
                            const options = response.data.data.map(d => ({
                                "value": d.id,
                                "label": d.country.countryName + " - " + d.city + " - " + d.name + " (" + d.code + ")",
                                "certificateLanguageId": d.certificateLanguage.id
                            })).sort((a, b) => a.label.localeCompare(b.label));
                            SetBorderControlPosts(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getPlatformData = new Promise((resolve, reject) => {
                    resolve(PlatformService.getAll()
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "value": d.id,
                                "label": d.platformName
                            })).sort((a, b) => a.label.localeCompare(b.label));
                            setPlatforms(options);
                            setPlatformDetails(response.data);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getCountryData = new Promise((resolve, reject) => {
                    resolve(CountryService.getAll()
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "value": d.id,
                                "label": d.countryName
                            }))
                            SetCountrys(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getNonEUCountryData = new Promise((resolve, reject) => {
                    resolve(CountryService.getNonEUCountries()
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "value": d.id,
                                "label": d.countryName
                            }))
                            SetNonEUCountries(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getCertificateLanguages = new Promise((resolve, reject) => {
                    resolve(CertificateLanguageService.getAll(productId)
                        .then(response => {
                            const options = response.data.map(d => ({
                                "value": d.id.toString(),
                                "label": d.name,
                                "active": d.active,
                            }));
                            SetCertificateLanguages(options);
                        })
                        .catch(e => {
                            console.log(e);
                        })
                    );
                });

                const getOrganisation = new Promise((resolve, reject) => {
                    resolve(OrganisationService.getFirst()
                        .then(response => {
                            var organisation = response.data;
                            setOrganisationName(organisation?.name);
                            setOrganisationAddress(`${organisation?.address1}, ${organisation?.address2 == '' ? '' : organisation?.address2 + ","} ${organisation?.townCity}, ${organisation?.postcode}`,);
                        })
                        .catch(e => {
                            console.log(e);
                        })
                    );
                });

                Promise.all([getBorderControlPostData, getPlatformData, getCountryData, getNonEUCountryData, getCertificateLanguages, getOrganisation]).then((responses) => {
                    setReady(true);
                });
            } catch (e) {
                console.error(e);
            }
        };
        fetchListData();
    }, []);

    useEffect(() => {
        if (ready) {
            var id = "";
            if (consignmentId != null || cloneId != null) {
                setTitle("Edit Consignment");
                setEditing(true);
                setBreadcrumbData({
                    item1: { name: 'Consignments', link: Routes.ConsignmentListingPage.path },
                    item2: { name: 'Edit Consignment', link: Routes.Collagen8396AddPage.path.replace(":id", consignmentId) }
                });
                if (cloneId != null) {
                    setTitle("Clone Consignment");
                    setBreadcrumbData({
                        item1: { name: 'Consignments', link: Routes.ConsignmentListingPage.path },
                        item2: { name: 'Clone Consignment', link: '#' }
                    })
                    id = cloneId;
                }
                else {

                    id = consignmentId;
                }
                ConsignmentService.getById(id)
                    .then(response => {
                        var res = response.data;
                        if (response.data.certificateLanguageId !== "" && certificateLanguages !== "") {
                            const selectedCertificateLanguage = certificateLanguages.find(x => x.value === response.data.certificateLanguageId);
                            res.certificateLanguageId = selectedCertificateLanguage;
                        }
                        if (response.data.platformId !== "" && platforms !== "") {
                            const selectedPlatform = platforms.find(x => x.value === response.data.platformId);
                            res.platformId = selectedPlatform;
                        }
                        if (response.data.destinationCountryId !== "" && countrys !== "") {
                            const selectedCountry = countrys.find(x => x.value === response.data.destinationCountryId);
                            res.destinationCountryId = selectedCountry;
                        }
                        if (response.data.operatorCountryId !== "" && countrys !== "") {
                            const selectedCountry = countrys.find(x => x.value === response.data.operatorCountryId);
                            res.operatorCountryId = selectedCountry;
                        }
                        if (response.data.consigneeCountryId !== "" && countrys !== "") {
                            const selectedCountry = countrys.find(x => x.value === response.data.consigneeCountryId);
                            res.consigneeCountryId = selectedCountry;
                        }
                        if (response.data.borderControlPostId !== "" && borderControlPosts !== "") {
                            const selectedPost = borderControlPosts.find(x => x.value === response.data.borderControlPostId);
                            res.borderControlPostId = selectedPost;
                        }
                        if (response.data.thirdCountryId !== "" && nonEUCountries !== "") {
                            const selectedCountry = nonEUCountries.find(x => x.value === response.data.thirdCountryId);
                            res.thirdCountryId = selectedCountry;
                        }
                        if (cloneId != null) {
                            res.consignmentName = "";
                            res.departureDate = null;
                        }
                        setApiValues(res);
                    });
            }

        }
    }, [ready]);

    const handleBorderControlPostChange = (name, value, setFieldValue) => {
        if (borderControlPosts !== null) {
            let selectedId = value.certificateLanguageId;
            const selectedLanguage = certificateLanguages.find(x => x.value == selectedId);
            if (selectedLanguage != undefined && selectedLanguage != null) {
                setFieldValue("certificateLanguageId", selectedLanguage)
            }
        }
    }
    const ChangeFormValues = () => {
        const { values } = useFormikContext();
        useEffect(() => {
            if (values?.transportShip == false) {
                values.transportVesselName = '';
            }
            if (values?.forTransit == false) {
                values.thirdCountryId = null;
            }


        }, [
            values
        ]
        );
        return null;
    };

    const FormatPlatformName = (props, value) => {
        if (value !== null) {
            var selectedPlatform = platformDetails.find(x => x.id == value.value)
            var placeOfLoading = `${selectedPlatform.platformName}, ${selectedPlatform.address1}, ${selectedPlatform.address2 == '' ? '' : selectedPlatform.address2 + ","} ${selectedPlatform.townCity}, ${selectedPlatform.postcode}`
            setDefaultPlaceOfLoading(placeOfLoading);
            //if(props.values.placeOfLoading?.length<=0){
            props.setFieldValue('placeOfLoading', placeOfLoading);
            //}
        }
    }

    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <FormSubmission
                    errors={errors}
                    title="Added Consignment"
                    message="This consignment has been successfully added to the consignment."
                    successButtonUrl={Routes.ConsignmentListingPage.path}
                    successButtonName="Back to Consignments"
                />
            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize="true"
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {(props) => (
                            <Col md={8}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Body>
                                        <Form>
                                            <FormController
                                                sublabel="Provide a name for this consignment for identification"
                                                control="input"
                                                label="Consignment Name"
                                                name="consignmentName"
                                                isrequired="true"
                                                col={8}
                                            />

                                            <h5 className="text-title mt-5 mb-0">Consignor / Exporter </h5>
                                            <p className="text-italic">As referred to in 1.1 on the Export Health Certificate</p>

                                            <FormController
                                                control="input"
                                                label="Consignor / Exporter Name"
                                                name="exporterName"
                                                col={6}
                                                isrequired="true"
                                            />

                                            <FormController
                                                control="input"
                                                label="Consignor / Exporter Address Line 1 "
                                                name="exporterAddress"
                                                col={8}
                                                isrequired="true"
                                            />


                                            <br></br>
                                            <h5 className="text-title mb-0">Platform</h5>
                                            <p className="text-italic">As referred to in 1.11 on the Export Health Certificate</p>
                                            <FormController
                                                control="multiselect"
                                                name="platformId"
                                                label="Platform"
                                                placeholder="Select a platform"
                                                options={platforms}
                                                isMulti={false}
                                                isClearable={true}
                                                getOptionValue={option => option.value}
                                                getOptionLabel={option => option.label}
                                                onChange={(name, value) => {
                                                    props.setFieldValue('platformId', value);
                                                    FormatPlatformName(props, value)
                                                }}
                                                onBlur={props.setFieldTouched}
                                                touched={props.touched.platform}
                                                error={props.errors.platform}
                                                backspaceRemovesValue={true}
                                                isrequired="true"
                                                col={8}
                                            />

                                            <h5 className="text-title mt-5 mb-0">Destination</h5>
                                            <p className="text-italic">As referred to in 1.12 on the Export Health Certificate</p>
                                            <FormController
                                                name="destinationCountryId"
                                                label="Destination country"
                                                placeholder="Select a country"
                                                control="multiselect"
                                                options={countrys}
                                                isMulti={false}
                                                isClearable={true}
                                                onBlur={props.setFieldTouched}
                                                onChange={props.setFieldValue}
                                                touched={props.touched.destinationCountry}
                                                errors={props.errors.destinationCountry}
                                                isrequired="true"
                                                col={8}
                                            />

                                            <FormController
                                                control="input"
                                                label="Destination Name"
                                                name="destinationName"
                                                col={5}
                                                isrequired="true"
                                            />

                                            <FormController
                                                control="input"
                                                label="Destination Address Line 1 "
                                                name="destinationAddress1"
                                                col={5}
                                                isrequired="true"
                                            />

                                            <FormController
                                                control="input"
                                                label="Destination Address Line 2 "
                                                name="destinationAddress2"
                                                col={5}
                                            />

                                            <FormController
                                                control="input"
                                                label="Destination Registration /  Approval Number"
                                                name="destinationApprovalNumber"
                                                col={5}
                                            />
                                            <h5 className="text-title mt-5">Departure Details</h5>
                                            <p className="text-italic">As referred to in 1.14 on the Export Health Certificate</p>
                                            <FormController
                                                control="dateInput"
                                                type="text"
                                                label="Date of departure"
                                                name="departureDate"
                                                isrequired={"true"}
                                                col={5}
                                            />


                                            <label className="required mt-3">Place of Loading name and address</label>
                                            <FormController
                                                control="input"
                                                label={null}
                                                name="placeOfLoading"
                                                sublabel={defaultPlaceOfLoading != '' ? `The selected platform full address is: ${defaultPlaceOfLoading}` : null}
                                                col={8}
                                                counterMax={65}
                                                showCounter={true}
                                                isrequired={"true"}
                                            />
                                            {/* <h5 className="text-title mt-5 mb-0">Accompanying Documents</h5>
                                                <p className="text-italic">As referred to in 1.17 on the Export Health Certificate</p>
                                                <FormController
                                                    control="text"
                                                    label={"Type"}
                                                    name={"documentType"}
                                                    col={5}
                                                />
                                                <FormController
                                                    control="text"
                                                    label={"Type"}
                                                    name={"documentCode"}
                                                    col={5}
                                                />
                                                <FormController
                                                    control="text"
                                                    label={"Type"}
                                                    name={"documentCommercialRef"}
                                                    col={5}
                                                /> 
                                            */}

                                            <h5 className="text-title mt-5 mb-0">Consignee/Importer</h5>
                                            <p className="text-italic">As referred to in 1.5 on the Export Health Certificate</p>
                                            <FormController
                                                name="consigneeCountryId"
                                                label="Consignee country"
                                                placeholder="Select a country"
                                                control="multiselect"
                                                options={countrys}
                                                isMulti={false}
                                                isClearable={true}
                                                onBlur={props.setFieldTouched}
                                                onChange={props.setFieldValue}
                                                touched={props.touched.consigneeCountry}
                                                errors={props.errors.consigneeCountry}
                                                isrequired="true"
                                                col={8}
                                            />

                                            <FormController
                                                control="input"
                                                label="Consignee Name"
                                                name="consigneeName"
                                                col={5}
                                                isrequired="true"
                                            />

                                            <FormController
                                                control="input"
                                                label="Consignee Address Line 1 "
                                                name="consigneeAddress1"
                                                col={5}
                                                isrequired="true"
                                            />

                                            <FormController

                                                control="input"
                                                label="Consignee Address Line 2 "
                                                name="consigneeAddress2"
                                                col={5}
                                            />

                                            <h5 className="text-title mt-5 mb-0">Operator responsible for the consignment</h5>
                                            <p className="text-italic">As referred to in 1.6 on the Export Health Certificate</p>
                                            <FormController
                                                name="operatorCountryId"
                                                label="Operator country"
                                                placeholder="Select a country"
                                                control="multiselect"
                                                options={countrys}
                                                isMulti={false}
                                                isClearable={true}
                                                onBlur={props.setFieldTouched}
                                                onChange={props.setFieldValue}
                                                touched={props.touched.operatorCountry}
                                                errors={props.errors.operatorCountry}
                                                isrequired="true"
                                                col={8}
                                            />

                                            <FormController
                                                control="input"
                                                label="Operator Name"
                                                name="operatorName"
                                                col={5}
                                                isrequired="true"
                                            />

                                            <FormController
                                                control="input"
                                                label="Operator Address Line 1 "
                                                name="operatorAddress1"
                                                col={5}
                                                isrequired="true"
                                            />

                                            <FormController
                                                control="input"
                                                label="Operator Address Line 2 "
                                                name="operatorAddress2"
                                                col={5}
                                            />

                                            <h5 className="text-title mt-5 mb-0">Means of Transport</h5>
                                            <p className="text-italic">As referred to in 1.15 on the Export Health Certificate</p>
                                            <FormController
                                                className="mt-4"
                                                control="checkbox"
                                                label="Transport by aeroplane"
                                                name="transportPlane"
                                                col={5}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Transport by ship"
                                                name="transportShip"
                                                col={5}
                                            />

                                            {props.values.transportShip == true &&
                                                <FormController
                                                    control="input"
                                                    label="Vessel Name:"
                                                    name="transportVesselName"
                                                    isrequired="true"
                                                    className="mb-5"
                                                    col={5}
                                                />
                                            }

                                            <FormController
                                                control="checkbox"
                                                label="Transport by railway wagon"
                                                name="transportRail"
                                                col={5}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Transport by road vehicle"
                                                name="transportRoad"
                                                col={5}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Transport by other"
                                                name="transportOther"
                                                col={5}
                                            />

                                            {props.values.transportOther == true &&
                                                <FormController
                                                    control="input"
                                                    label="Transport Other Name:"
                                                    name="transportOtherName"
                                                    isrequired={"true"}
                                                    className="mb-5"
                                                    col={5}
                                                />
                                            }

                                            <h5 className="text-title mt-5 mb-0">Entry Border Control Post</h5>
                                            <p className="text-italic">As referred to in 1.16 on the Export Health Certificate</p>
                                            <FormController
                                                name="borderControlPostId"
                                                label="Border Control Post"
                                                placeholder="Border Control Post"
                                                control="multiselect"
                                                options={borderControlPosts}
                                                isMulti={false}
                                                isClearable={true}
                                                onBlur={props.setFieldTouched}
                                                touched={props.touched.borderControlPost}
                                                errors={props.errors.borderControlPost}
                                                isrequired="true"
                                                onChange={(name, value) => {
                                                    props.setFieldValue(name, value);
                                                    handleBorderControlPostChange(name, value, props.setFieldValue);
                                                }}
                                                col={8}
                                            />
                                            <div className='my-2'>
                                                <div className='text-gray text-italic '>
                                                    Certificate(s) language:&nbsp;
                                                    {certificateLanguages.find(x => x.value == props.values.certificateLanguageId?.value)?.label ? certificateLanguages.find(x => x.value == props.values.certificateLanguageId.value)?.label : "N/A"}
                                                    {props.values.certificateLanguageId !== null &&
                                                        <Button variant='link' className="text-italic" onClick={() => setShowCertificateLanguageChange(true)}>
                                                            Change
                                                        </Button>
                                                    }
                                                    {showCertificateLanguageChange &&
                                                        <FormController
                                                            control="multiselect"
                                                            name="certificateLanguageId"
                                                            placeholder="Select a language"
                                                            options={certificateLanguages}
                                                            isrequired="true"
                                                            col={4}
                                                            isMulti={false}
                                                            onChange={(name, value) => {
                                                                props.setFieldValue(name, value);
                                                            }}
                                                            onBlur={props.setFieldTouched}
                                                            touched={props.touched.certificateLanguageId}
                                                            error={props.errors.certificateLanguageId}
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            <h5 className="text-title mt-5 mb-0">Container number / Seal number</h5>
                                            <p className="text-italic">As referred to in 1.19 on the Export Health Certificate</p>
                                            <Row>
                                                <Col md={5}>
                                                    <FormController
                                                        control="input"
                                                        label="Seal number: "
                                                        name="sealNumber"
                                                        isrequired="true"

                                                    />
                                                </Col>
                                                <Col md={7} className="seal-container-position">
                                                    <ConsignmentSealImages props={props} consignmentId={consignmentId} setSealNumber={(newSelected) => handleSealNumberChange(newSelected, props)} setSealErrorMessage={handleSealErrorChange} />
                                                </Col>
                                            </Row>
                                            {SealErrorMessage != null &&
                                                <p>
                                                    <i className="vtdcomplete">
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="mx-2 text-danger" />
                                                    </i>
                                                    {SealErrorMessage}
                                                </p>

                                            }
                                            <Row>
                                                <Col md={5}>
                                                    <FormController
                                                        control="input"
                                                        label="Container number: "
                                                        name="containerNumber"
                                                        isrequired="true"
                                                    />
                                                </Col>
                                                <Col md={7} className="seal-container-position">
                                                    <ConsignmentContainerImages props={props} consignmentId={consignmentId} setContainerNumber={(newSelected) => handleContainerNumberChange(newSelected, props)} setContainerErrorMessage={handleContainerErrorChange} />
                                                </Col>
                                            </Row>
                                            {ContainerErrorMessage != null &&
                                                <p>
                                                    <i className="vtdcomplete">
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="mx-2 text-danger" />
                                                    </i>
                                                    {ContainerErrorMessage}
                                                </p>

                                            }

                                            <FormController
                                                control="checkbox"
                                                className="my-3"
                                                label="For import or admission to EU"
                                                name="importForEU"
                                                isrequired="true"
                                                col={5}
                                            />

                                            <FormController
                                                control="checkbox"
                                                className="my-3"
                                                label="For transit"
                                                name="forTransit"
                                                col={5}
                                            />

                                            {props.values.forTransit == true &&
                                                <FormController
                                                    name="thirdCountryId"
                                                    label="Third country"
                                                    placeholder="Select a country"
                                                    control="multiselect"
                                                    options={nonEUCountries}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    onBlur={props.setFieldTouched}
                                                    onChange={props.setFieldValue}
                                                    touched={props.touched.thirdCountryId}
                                                    errors={props.errors.thirdCountryId}
                                                    isrequired="true"
                                                    col={8}
                                                />
                                            }

                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            <div className="d-inline-block btn-panel mt-3">
                                                {/*////// SUBMIT /////////*/}
                                                <Button variant="primary" type="submit" className="d-inline-block btn-panel" disabled={isSubmitting}>
                                                    {isSubmitting ? "Please wait..." : "Save"}
                                                </Button>
                                                <Button variant="light" className="d-inline-block btn-panel ms-3" href="/consignments">
                                                    Back to Consignments
                                                </Button>
                                            </div>

                                            <ChangeFormValues />
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default ConsignmentAddPage;