import React from 'react';
import { MultiSelectField } from "../../../../common/MultiSelect";
import { Card, Col, Row } from "@themesberg/react-bootstrap";

export default function RequestAttestationNonGEFSForm(props) {
  const {
    formField: {
        vetId
    },
    setFieldValue,
    errors,
    touched,
    setFieldTouched,
    vets, 
  } = props;
  
  
  return (

    <Row  className="justify-content-left mt-4">
        <Col xl={11}>
            <Card border="light" className="bg-white shadow-sm mb-4 p-3">
                <Card.Body>
                    <div>
                        <h5 className="mb-3">Vet Inspection</h5>
                        <MultiSelectField
                            id={vetId.name}
                            name={vetId.name}
                            label={vetId.label}
                            col={4}
                            placeholder="Select a vet by RCVS Number"
                            isrequired={"true"}
                            options={vets}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.rcvsnumber} 
                            isMulti={false}
                            isClearable={true}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched.vetId}
                            error={errors.vetId}
                        />
                    </div>
                </Card.Body>
            </Card>
        </Col>
    </Row>
  );
}
