export const health_1_3_A = { 
    label: "has been subjected to an examination by a digestion method for Trichinella with negative results;" 
}
export const health_1_3_B = { 
    label: "has been subjected to a freezing treatment in accordance with Annex II to Implementing Regulation (EU) 2015/1375."
}
export const health_1_3_C = { 
    label: "is derived from domestic porcine animals either coming from a holding officially recognised as applying controlled housing conditions in accordance with Article 8 of Implementing Regulation (EU) 2015/1375 or not weaned and less than 5 weeks of age."
}
export const health_1_4 = { 
    label: "the minced meat has been produced in accordance with Section V of Annex III to Regulation (EC) No 853/2004 and frozen to an internal temperature of not more than -18 °C;" 
}

export const health_1_6_statements = [
    { group: "porcineHealth_1_6", fieldname: "health_1_6_statements", value: "1", key: "the carcase or parts of the carcase have been marked with a health mark in accordance with Article 48 of and Annex II to Implementing Regulation (EU) 2019/627;" },
    { group: "porcineHealth_1_6", fieldname: "health_1_6_statements", value: "2", key: "the packages of [meat] [minced meat] have been marked with an identification mark in accordance with Section I of Annex II to Regulation (EC) No 853/2004;" },
]

export const health_1_11 = { 
    label: "it fulfils the requirements of Commission Regulation (EC) No 1688/2005;" 
}

export const animal_2_1_B_statements = [
    { group: "porcineAnimal_2_1_B", fieldname: "animal_2_1_B_statements", value: "1", key: "in which <strong><u>foot and mouth disease</u></strong> has not been reported for a period of 12 months before the date of slaughter of the animals from which the fresh meat was obtained, and vaccination against this disease has not been carried out during the same period" },
    { group: "porcineAnimal_2_1_B", fieldname: "animal_2_1_B_statements",  value: "2", key: "in which <strong><u>foot and mouth disease</u></strong> has not been reported since ___/___/____ ." },
]


export const animal_2_1_C_statements = [
    { group: "porcineAnimal_2_1_C", fieldname: "animal_2_1_C_statements", value: "1", key: "in which <strong><u>classical swine fever</u></strong> has not been reported for a period of 12 months before the date of slaughter of the animals from which the fresh meat was obtained, and during the same period vaccination against this disease has not been carried out." },
    { group: "porcineAnimal_2_1_C", fieldname: "animal_2_1_C_statements",  value: "2", key: "in which <strong><u>classical swine fever</u></strong> has not been reported since ___/___/____  and vaccination against this disease has not been carried out during a period of 12 months before the date of slaughter of the animals from which the fresh meat was obtained" },
]
export const animal_2_2_statements = [
    { group: "porcineAnimal_2_2", fieldname: "animal_2_2_statements", value: "1", key: "have remained in the country of slaughter since birth, or for at least 3 months before slaughter." },
    { group: "porcineAnimal_2_2", fieldname: "animal_2_2_statements",  value: "2", key: "have been introduced on ___/___/____ into the country of slaughter, from the zone with code ___ - __ that at that date was authorised for the entry of fresh meat of porcine animals into the Union and where they have remained since birth, or for at least 3 months before slaughter." },
    { group: "porcineAnimal_2_2", fieldname: "animal_2_2_statements",  value: "3", key: "have been introduced on ___/___/____ into the country of slaughter, from the Member State with ISO code _____." },

]

export const animal_2_4_D = { 
    label: "have been slaughtered"
}

export const animal_2_6_statements = [
    { group: "porcineAnimal_2_6", fieldname: "animal_2_6_statements", value: "1", key: "it was packaged for further storage." },
    { group: "porcineAnimal_2_6", fieldname: "animal_2_6_statements",  value: "2", key: "its loading, as unpackaged fresh meat, to the means of transport for dispatch to the Union" },
]
