import React from 'react';
import { Button, Card, Col, Row, Badge } from "@themesberg/react-bootstrap";

export const ProductDetailsSummary = (props) =>{
   const {productDetail, platform, siteName, temperature, productCountry, productZone} = props;

return(
    <Row>
        <Col md={6}>
            <small>Description:</small> <h6>{productDetail.description}</h6>
        </Col>
        <Col md={3}><small>Net weight ({productDetail.weightType ==1 ? "Unit": "Case" }):</small> <h6>{productDetail.netWeight}kg</h6></Col>
        <Col md={3}><small>Gross weight ({productDetail.weightType ==1 ? "Unit": "Case" }):</small> <h6>{productDetail.grossWeight}kg</h6></Col>
        <Col md={3}><small>Certificate number:</small> <h6>{productDetail.certificateNumber.map(x => x).join(", ")}</h6></Col>
        <Col md={3}><small>Nature of commodity:</small> <h6>{productDetail.commodityNature == ''? "N/A" : productDetail.commodityNature }</h6></Col>
        <Col md={3}><small>Commodity HS code:</small> <h6>{productDetail.commodityHscode}</h6></Col>
        <Col sm={3}><small>Platform:</small> <h6>{(platform === null || platform==="") ? "N/A" : platform}</h6></Col>
        <Col md={3}><small>Site:</small> <h6>{siteName}</h6></Col>
        <Col md={3}><small>Temperature:</small> <h6>{temperature}</h6></Col>
        <Col md={3}><small>Shelf packaging:</small> <h6>{productDetail.packagingTypeShelf}</h6></Col>
        <Col md={3}><small>Outer packaging:</small> <h6>{productDetail.packagingTypeOuter}</h6></Col>
        <Col md={3}><small>Retail packaging:</small> <h6>{productDetail.packagingTypeRetail}</h6></Col>   
        <Col md={3}><small>Country of origin:</small> <h6>{productDetail.countryOrigin?.countryName}</h6></Col>
        <Col md={3}><small>Region of Origin:</small> <h6>{productDetail.regionOfOrigin?.regionName}</h6></Col>
        <Col md={3}><small>Certified for Human Consumption</small> <h6>{productDetail.certifiedHumanConsumption? "Yes": "No"}</h6></Col>
    </Row>
)

}

export default ProductDetailsSummary;