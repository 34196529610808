import *  as baseService  from "./BaseService";

const getAllPaged = (params) => {
    return baseService.getApiDataWithParams("/manufacturer/GetAllPaged", params);
};

const getAll = (params) => {
    var response = baseService.getApiData("/manufacturer/GetAll");
    return response;
};
const getById = (id) => {
    return baseService.getApiData("/manufacturer/GetById?id=" + id);
};
const create = (data) => {
    return baseService.postApiData("/manufacturer/Create", data);
};
const update = (id, data) => {
    return baseService.putApiData("/manufacturer/update?id=" + id, data);
};
const ManufacturerService = {
    getAll,
    getAllPaged,
    getById,
    create,
    update
};

export default ManufacturerService;