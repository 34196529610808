import formModel from './NonGEFSModel';
const {
  formField: {
    vetId,
    isGEFS,
  }
} = formModel;

export default {
  [vetId.name]: '',
  [isGEFS.name]: false,
};

