import React, { useState, useEffect, useRef } from 'react';
import ConsignmentAddPage from './ConsignmentAddPage';

const ConsignmentClonePage = (props) => {

    const [cloneId] = useState(props.match.params.id);

    return (
        <ConsignmentAddPage cloneId={cloneId} />
    );
};

export default ConsignmentClonePage;