import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from 'react';
import BreadcrumbNav from '../../../components/BreadcrumbNav';
import EstablishmentScraperTable from '../../../components/Tables/EstablishmentScraperTable';
import { Routes } from '../../../routes';
import FilterBar from '../../filters/FilterBar';
import { ScraperFilterConfig } from '../../filters/configs/ScraperFilterConfig';
import EstablishmentsService from '../../services/EstablishmentsService';

const EstablishmentScraperPage  = (props) => {
    const [scrapeLinks, setScrapeLinks] = useState([]);
    const [count, setCount] = useState(0);
    const [activePage , setActivePage ] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [allScraped, setAllScraped] = useState(false);
    const [ready] = useState(true);
    
    const title = "Establishments scraper"
    const breadcrumbData = {
        item1 : {name:'Admin', link: Routes.UserAdminPage.path},
        item2 : {name:'Establishments scraper', link: '#'},
    }
    
    //Get all scrape all filtered links
    const getScrapeLinks = (params = {}) => {
        setLoaded(false);
        
        if(params=={}){
            setLoaded(true);
            return null;
        }
        EstablishmentsService.getScrapeLinks(params)
            .then((response) => {
                const { data } = response;
                //setTotalItems(data.length);
                setScrapeLinks(data);
                setCount(data.length);
                setLoaded(true);
            })
            .catch((e) => {
                setLoaded(true);
                console.log(e);
            });
    };

    //Submit
    const handleFilterSubmit= (params) => {
        setSubmitting(true);
        getScrapeLinks(params)
        setSubmitting(false);
    }

    const handleFilterChange = (values) => {
        setSubmitting(true);
        setSubmitting(false);
    }


    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            <div className="">
                <Row className='row d-flex align-items-center'>
                    <Col xs={12} sm={6} lg={6} className="text-start">
                        <h2 id="tabelLabel" >{title}</h2>
                    </Col>
                    {/* <Col xs={12} sm={6} lg={6} className="text-end">
                        <Button disabled={!loaded} 
                                variant="warning" 
                                size="sm" 
                                onClick={() => handleScrapeAllLinks()}
                                >
                            Scrape All Links
                        </Button>
                    </Col> */}
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <FilterBar
                                            loaded={loaded}
                                            setLoaded={setLoaded}
                                            ready={ready} 
                                            hasSearch={false}
                                            defaultFilterValues={ScraperFilterConfig()}
                                            activePage={activePage}
                                            pageSize={20}
                                            orderByOptions={[]}
                                            setActivePage={setActivePage}
                                            handleChange={handleFilterChange}
                                            handleSubmit={handleFilterSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <EstablishmentScraperTable loaded={loaded} scrapeLinks={scrapeLinks}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default EstablishmentScraperPage;