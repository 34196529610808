import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useMemo, useRef, useState } from "react";
import { useTable } from "react-table";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Routes } from "../../../routes";
import FilterBar from '../../filters/FilterBar';
import Paging from "../common/Paging";
import PlatformCertifiersService from '../../services/PlatformCertifiersService';
import { PlatformCertifierListTable } from '../../../components/Tables/PlatformCertifierListTable';
import PermissionsCheck from '../../../auth/PermissionCheck';
import PlatformCertifierInvite from './PlatformCertifierInvite';
import { scopes} from '../../../auth/PermissionsMap';

const PlatformCertifierListingPage = (props) => {
    const [platformCertifiers, setPlatformCertifiers] = useState([]);
    const [count, setCount] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const breadcrumbData = {
            item1 : {name:'Platform Certifiers', link: Routes.PlatformCertifiersListingPage.path},
            item2 : {name:'View All', link: 'View All'},
        };
    const productRef = useRef();
    const [pageSize, setPageSize] = React.useState(10);
    const [activePage, setActivePage] = React.useState(1);
    const [ready] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [defaultFilterValues, setDefaultFilterValues] = useState([]);
    const { totalPages = count, size = "md", withIcons = false, disablePrev = false } = props;
    const orderByOptions = ["Last updated", "A-Z", "Z-A"]

    const openPlatformCertifier = (rowIndex) => {
        const id = productRef.current[rowIndex].id;
        props.history.push("/platformCertifier/" + id);
    };

    const onPrevItem = () => {
      const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
      setActivePage(prevActiveItem);
    };
    
    const onPageNumberClick = (number) => {
        setActivePage(number);
    };
  
    const onNextItem = (totalPages) => {
      const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
      setActivePage(nextActiveItem);
    };

    productRef.current = platformCertifiers;

    
    //End - Paging Functions

    const getPlatformCertifiers = (params) => {
        setLoaded(false);
        PlatformCertifiersService.getAllPaged(params)
            .then((response) => {
                const { data, totalPages, totalRecords} = response.data;
                setTotalItems(totalRecords);
                setPlatformCertifiers(data);
                setCount(totalPages);
                setLoaded(true);
            })
            .catch((e) => {
                setLoaded(true);
                console.log(e);
            });
    };

    //Submit
    const handleFilterSubmit= (params) => {
        setSubmitting(true);
        getPlatformCertifiers(params)
        setSubmitting(false);
    }

    const handleFilterChange = (values) => {
        setSubmitting(true);
        setSubmitting(false);
    }

    const handlePageSizeChange = (value) => {
        setPageSize(value.value);
    };
    
    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "platformCertifierName",
        },
        {
            Header: "Actions",
            accessor: "actions",
            Cell: (props) => {
                const rowIdx = props.row.id;
                return (
                    <div>
                        <span onClick={() => openPlatformCertifier(rowIdx)}>
                            <i className="far fa-edit action mr-2"></i>View
                        </span>
                    </div>
                );
            },
        },
    ],
        []
    );

    const {
        headerGroups,
        rows,
    } = useTable({
        columns,
        data: platformCertifiers,
    });

    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            <h2 id="tabelLabel" >Platform Certifiers</h2>
            <div className="col-md-12 list">
                <Row className="mt-2">
                    <Col xs={12} sm={12} lg={9} xl={9}>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                                <Row>
                                    <Col xs={12} sm={12} xl={12}>
                                        <FilterBar
                                            setLoaded={setLoaded} 
                                            loaded={loaded} 
                                            ready={ready} 
                                            defaultFilterValues={defaultFilterValues}
                                            hasSearch={true}
                                            activePage={activePage}
                                            pageSize={pageSize}
                                            orderByOptions={orderByOptions}
                                            setActivePage={setActivePage}
                                            handleChange={handleFilterChange}
                                            handleSubmit={handleFilterSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <PlatformCertifierListTable 
                                    loaded={loaded} 
                                    platformCertifiers={platformCertifiers}
                                />
                                <Paging
                                        activePage={activePage}
                                        col={size}
                                        pageSize={pageSize}
                                        totalPages={totalPages}
                                        totalItems={totalItems}
                                        handleChange={handlePageSizeChange}
                                        onPrevItem={onPrevItem}
                                        onNextItem={onNextItem}
                                        onPageNumberClick={onPageNumberClick}
                                        withIcons={withIcons}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={12} lg={3} xl={3}>
                        <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.invite.platformcertifiers]}>
                            <Col xs={12} md={12} xl={12} className="mb-4">
                                <PlatformCertifierInvite />
                            </Col>
                        </PermissionsCheck>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default PlatformCertifierListingPage;