import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import ProductDataService from "../../../services/ProductService";
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import moment from "moment-timezone";
import { Formik, Form, FieldArray, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import ProductPoultryService from "../../../services/ProductPoultryService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ZoneService from "../../../services/ZoneService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import * as Poultry8371Labels from "./Poultry8371Labels"
import { Col, Row, Button, Card, Alert } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faLeaf, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import ColdStoreSelect from '../../../../components/EstablishmentSelect/ColdStoreSelect';
import SiteService from '../../../services/SiteService';
import EstablishmentsService from '../../../services/EstablishmentsService';
import SlaughterhouseSelect from '../../../../components/EstablishmentSelect/SlaughterhouseSelect';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ActivityService from '../../../services/ActivityService';


const Poultry8371AddPage = (props) => {
    const certificateNumber = 8371;
    const { id, attestationId } = useParams();
    const [formVersion] = useState(GetCertificateFormVersion(certificateNumber));
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [zones, SetZones] = useState([]);
    const [specieslist, SetSpecies] = useState([]);
    const [apiValues, SetApiValues] = useState([]);
    const [siteEstablishment, setSiteEstablishment] = useState([]);
    const [coldStoreEstab, setColdStoreEstab] = useState([]);
    const [memberStates, SetMemberStates] = useState([]);
    const [onDate, setOnDate] = useState(null);
    const [IsGB1Selected, setIsGB1Selected] = useState(null);
    let title = (editing == true ? "Edit " : "New") + " Poultry Information (#8371-For Poultry only)"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Poultry 8371', link: Routes.Poultry8371AddPage.path },
    };
    const initialValues = {
        productId: props.match.params.id,
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
        health_1_H: apiValues?.health_1_H ?? false,
        health_1_6: apiValues?.health_1_6 ?? false,
        animal_2_1_Zone_Hatchery: apiValues?.animal_2_1_Zone_Hatchery ?? [],
        animal_2_1_Zone_FarmOfOrigin: apiValues?.animal_2_1_Zone_FarmOfOrigin ?? [],
        animal_2_1_Zone_Slaughterhouse: apiValues?.animal_2_1_Zone_Slaughterhouse ?? [],
        animal_2_2_A_statements: apiValues?.animal_2_2_A_statements ?? 0,
        animal_2_2_B_statements: apiValues?.animal_2_2_B_statements ?? 0,
        animal_2_4_A_statements: apiValues?.animal_2_4_A_statements ?? 0,
        animal_2_4_A_a_statements: apiValues?.animal_2_4_A_a_statements ?? 0,
        animal_2_4_B_statements: apiValues?.animal_2_4_B_statements ?? 0,
        animal_2_4_C_statements: apiValues?.animal_2_4_C_statements ?? 0,
        animal_2_4_F_statements: apiValues?.animal_2_4_F_statements ?? 0,
        animal_2_5_A: apiValues?.animal_2_5_A ?? null,
        animal_2_5_B: apiValues?.animal_2_5_B ?? null,
        animal_2_5_C: apiValues?.animal_2_5_C ?? null,
        animal_2_8_statements: apiValues?.animal_2_8_statements ?? 0,
        animal_2_10: apiValues?.animal_2_10 ?? false,
        abattoirEstablishments: apiValues?.abattoirEstablishments ?? [],
        coldStoreEstablishments: apiValues?.coldStoreEstablishments ?? [],
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";
    //Validation
    const validationSchema = Yup.object().shape({
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne).required(validationMsgRequired),
        health_1_H: Yup.boolean(),
        animal_2_1_Zone_Hatchery: Yup.array()
            .min(1, 'At least one item is required')
            .required('This field is required')
            .test('no-GB-2', 'GB-2 is not allowed (see warning)', (array) => {
                return !array?.some(item => item.isoCode === 'GB-2');
            }),
        animal_2_1_Zone_FarmOfOrigin: Yup.array()
            .min(1, 'At least one item is required')
            .required('This field is required')
            .test('no-GB-2', 'GB-2 is not allowed (see warning)', (array) => {
                return !array?.some(item => item.isoCode === 'GB-2');
            }),
        animal_2_1_Zone_Slaughterhouse: Yup.array()
            .min(1, 'At least one item is required')
            .required('This field is required')
            .test('no-GB-2', 'GB-2 is not allowed (see warning)', (array) => {
                return !array?.some(item => item.isoCode === 'GB-2');
            }),
        animal_2_2_A_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_2_B_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_4_A_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_4_B_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_4_C_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_4_F_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_5_A: Yup.date()
            .required(validationMsgRequired),
        animal_2_5_B: Yup.date().nullable(),
        animal_2_5_C: Yup.date().nullable().when(['animal_2_5_A', 'animal_2_5_B'], {
            is: (animal_2_5_A, animal_2_5_B) => !!animal_2_5_A && !!animal_2_5_B,
            then: Yup.date().nullable().required('Please complete the Date range'),
            otherwise: Yup.date().nullable(),
        }),

        animal_2_8_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_10: Yup.boolean(),
        abattoirEstablishments: Yup.array().min(1, validationMsgAtLeastOne).required(validationMsgRequired),
    });

    function onSubmit(values, { setSubmitting, resetForm }) {
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;


            if (apiValues.length === 0) {
                ProductPoultryService.create(submitData)
                    .then(async response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Poultry 8371',
                            8371,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            else {
                ProductPoultryService.update(productId, values)
                    .then(async response => {
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Poultry 8371',
                            8371,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();

        useEffect(() => {
            if (ready) {
                const hasSpecifiedIsoCode = values.animal_2_1_Zone_FarmOfOrigin.some(x => x.isoCode === 'FI' || x.isoCode === 'SE') ||
                    values.animal_2_1_Zone_Hatchery.some(x => x.isoCode === 'FI' || x.isoCode === 'SE') ||
                    values.animal_2_1_Zone_Slaughterhouse.some(x => x.isoCode === 'FI' || x.isoCode === 'SE');

                setFieldValue('health_1_H', hasSpecifiedIsoCode);
                setFieldValue('animal_2_10', hasSpecifiedIsoCode);

                const codes = values.animal_2_1_Zone_FarmOfOrigin.map(x => x.isoCode).concat(values.animal_2_1_Zone_Hatchery.map(x => x.isoCode)).concat(values.animal_2_1_Zone_Slaughterhouse.map(x => x.isoCode))
                const search = (text) => memberStates.filter(({ isoCode }) => isoCode.includes(text));
                const result = search(codes.isoCode);
                // if (values.animal_2_4_A_statements == 1) {
                //     setFieldValue('animal_2_4_A_a_statements', 0);
                // }
                // else {
                //     if (result.find(f => f.isoCode === codes.isoCode)) {
                //         setFieldValue('animal_2_4_A_a_statements', 2);
                //     }
                //     else {
                //         setFieldValue('animal_2_4_A_a_statements', 1);
                //     }
                // }
                const isGB1Selected = [
                    'animal_2_1_Zone_FarmOfOrigin',
                    'animal_2_1_Zone_Hatchery',
                    'animal_2_1_Zone_Slaughterhouse',
                ].some((field) => values?.[field]?.some((x) => x.isoCode === 'GB-1'));

                setIsGB1Selected(isGB1Selected);

                if (!values.animal_2_5_A) {
                    setFieldValue('animal_2_5_B', '');
                    setFieldValue('animal_2_5_C', '');
                }
                setColdStoreEstab(values?.coldStoreEstablishments)
                setOnDate(values?.animal_2_5_A);
            }
        }, [values]);

        return null;
    };
    useEffect(() => {
        async function fetchListData() {

            try {
                const populateProductDetailData = new Promise((resolve, reject) => {
                    resolve(
                        ProductDataService.getProductDetailByProductId(productId)
                            .then(response4 => {
                                //Get site operatorId
                                SiteService.getById(response4.data.siteId)
                                    .then(response2 => {

                                        //Get establishment data from operatorid
                                        EstablishmentsService.getEstablishmentsDetails([response2.data.tracesId])
                                            .then(response3 => {
                                                //set parent site for this product
                                                setSiteEstablishment(response3.data)
                                            }, [])
                                            .catch(e => {
                                                console.log(e);
                                            });
                                    }, [])
                                    .catch(e => {
                                        console.log(e);
                                    });
                            }, [])
                    );
                });
                const populateProductData = new Promise((resolve, reject) => {
                    resolve(
                        ProductService.getById(productId)
                            .then(response => {
                                SetProduct(response.data.data);
                            }, [])
                    );
                });
                const populateProductPoultryData = new Promise((resolve, reject) => {
                    resolve(
                        ProductPoultryService.getByProductId(productId)
                            .then(response => {
                                var res = response.data;
                                setColdStoreEstab(res.coldStoreEstablishments)
                                if (response.data == "") {
                                    return;
                                }
                                SetApiValues(res);
                                setEditing(true);
                                setReady(true);
                                console.log("init values:" + apiValues);
                            }, [])
                    );
                });
                const populateProductScientificSpeciesData = new Promise((resolve, reject) => {
                    resolve(
                        ProductScientificSpeciesDataService
                            .getOnlyPoultry()
                            .then((_speciesResponse) => {
                                SetSpecies(_speciesResponse.data);
                            })
                    );
                });
                const populateMemberstateData = new Promise((resolve, reject) => {
                    resolve(
                        ZoneService
                            .getAllByGroup("ALL_MEMBER_STATES")
                            .then((_countryResponse) => {
                                const options = _countryResponse.data.map(d => ({
                                    "id": d.id,
                                    "displayName": d.displayName,
                                    "countryName": d.countryName,
                                    "regionName": d.regionName,
                                    "isoCode": d.isoCode,
                                })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                                const firstItem = "GB";
                                options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                                SetMemberStates(options);
                            }).catch((e) => {
                                console.log(e);
                            })
                    );
                });
                const populatePoultryData = new Promise((resolve, reject) => {
                    resolve(
                        ZoneService.getAllByGroup("ALL_FRESH_MEAT_POULTRY_GAME_POU")
                            .then((_zonesResponse) => {
                                const options = _zonesResponse.data.map(d => ({
                                    "id": d.id,
                                    "displayName": d.displayName,
                                    "countryName": d.countryName,
                                    "regionName": d.regionName,
                                    "isoCode": d.isoCode,
                                })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                                const firstItem = "GB-1";
                                options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                                SetZones(options);
                            }).catch((e) => {
                                console.log(e);
                            })
                    );
                });
                Promise.all([populateProductDetailData, populateProductData, populateProductPoultryData, populateProductScientificSpeciesData, populateMemberstateData, populatePoultryData]).then((responses) => {
                    setReady(true);
                });
            } catch (e) {
                console.error(e);
            }
        };
        fetchListData();
    }, []);

    const extractIsoCodes = (values, memberStates) => {
        const isoCodes = [];

        if (values?.animal_2_1_Zone_FarmOfOrigin) isoCodes.push(...values.animal_2_1_Zone_FarmOfOrigin.map(x => x.isoCode));
        if (values?.animal_2_1_Zone_Hatchery) isoCodes.push(...values.animal_2_1_Zone_Hatchery.map(x => x.isoCode));
        if (values?.animal_2_1_Zone_Slaughterhouse) isoCodes.push(...values.animal_2_1_Zone_Slaughterhouse.map(x => x.isoCode));
        return isoCodes.map(str => str.replace(/-(0|1|2|3|4|5)/g, ''));
    };

    const formatDate = (date) => {
        return (date && moment(date, "YYYY-MM-DD").isValid()) ? moment(date).format("DD/MM/YYYY") : "";
    }

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);


    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <>
                    <SubmissionPage
                        isPoultry={true}
                        productId={productId}
                        attestationId={attestationId}
                        isGEFS={isGEFS}
                        errors={errors}
                        title={(editing ? "Edited" : "Completed") + " Poultry 8371 Form"}
                        message={`Poultry information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                    />

                </>

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        enableReinitialize="true"
                    >
                        {(props, isSubmitting) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        <ul className="list-unstyled list-clauses" >
                                            <h6>Poultry products that:</h6>
                                            <br></br>
                                            <li>
                                                <FormController
                                                    control="checkbox"
                                                    label={Poultry8371Labels.health_1_H.label}
                                                    name="health_1_H"
                                                    readOnly={readOnly}
                                                />
                                            </li>

                                            <label><span className='text-gray-600 text-italic'>Please select only if final destination is Sweden or Finland</span></label>
                                            <br></br>
                                            <br></br>
                                            <span className='required'>II.2.1 Hatchery that come from the <strong>zone(s)</strong> with code(s):</span>
                                            <li>
                                                <MultiSelectField
                                                    id='hatchery'
                                                    name='animal_2_1_Zone_Hatchery'
                                                    placeholder="Select fields"
                                                    options={zones}
                                                    defaultValues={props.values.animal_2_1_Zone_Hatchery}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.displayName}
                                                    value={props.values.animal_2_1_Zone_Hatchery}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.animal_2_1_Zone_Hatchery}
                                                    error={props.errors.animal_2_1_Zone_Hatchery}
                                                    isClearable={true}
                                                    col={6}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <span className='required'>II.2.1 Farm Of Origin that come  from the <strong>zone(s)</strong> with code(s):</span>
                                            <li>
                                                <MultiSelectField
                                                    id='farmOfOrigin'
                                                    name='animal_2_1_Zone_FarmOfOrigin'
                                                    placeholder="Select fields"
                                                    options={zones}
                                                    defaultValues={props.values.animal_2_1_Zone_FarmOfOrigin}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.displayName}
                                                    value={props.values.animal_2_1_Zone_FarmOfOrigin}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.animal_2_1_Zone_FarmOfOrigin}
                                                    error={props.errors.animal_2_1_Zone_FarmOfOrigin}
                                                    isClearable={true}
                                                    col={6}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <span className='required'>II.2.1 Slaughterhouse that come  from the <strong>zone(s)</strong> with code(s):</span>
                                            <li>
                                                <MultiSelectField
                                                    id='slaughterhouse'
                                                    name='animal_2_1_Zone_Slaughterhouse'
                                                    placeholder="Select fields"
                                                    options={zones}
                                                    defaultValues={props.values.animal_2_1_Zone_Slaughterhouse}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.displayName}
                                                    value={props.values.animal_2_1_Zone_Slaughterhouse}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.animal_2_1_Zone_Slaughterhouse}
                                                    error={props.errors.animal_2_1_Zone_Slaughterhouse}
                                                    isClearable={true}
                                                    col={6}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            {(props.values?.animal_2_1_Zone_FarmOfOrigin?.map(x => x.isoCode)?.includes('GB-2') ||
                                                props.values?.animal_2_1_Zone_Hatchery?.map(x => x.isoCode)?.includes('GB-2') ||
                                                props.values?.animal_2_1_Zone_Slaughterhouse?.map(x => x.isoCode)?.includes('GB-2')) && (

                                                    <Alert variant="info" className="mt-3 d-flex" col="sm">
                                                        <FontAwesomeIcon icon={faInfoCircle} className="me-3 mt-1 fs-5" />
                                                        <div>
                                                            <span className='text-danger'>Meat from Highly Pathogenic Avian Influenza Restricted zone (GB-2) <br />
                                                                The poultry meat to which this attestation refers is not eligible for export to the European Union without a specific risk mitigating treatment being applied, as defined in EU Regulation 2020/692 Annex XXVI (at least the specific treatment "O"). Although the material originates from a zone currently closed for entry of poultry meat to the Union as fresh meat, there has been no outbreak of highly pathogenic avian influenza, in and around the establishment in an area of 10km, in the 30 days prior to slaughter."
                                                            </span>
                                                        </div>
                                                    </Alert>
                                                )}
                                            <li>
                                                <MultiSelectField
                                                    id='productScientificSpecies'
                                                    name='productScientificSpecies'
                                                    label="Species"
                                                    placeholder="Select fields"
                                                    options={specieslist}
                                                    defaultValues={props.values?.productScientificSpecies}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                                    value={props.values.productScientificSpecies}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.productScientificSpecies}
                                                    error={props.errors.productScientificSpecies}
                                                    isClearable={true}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    col={4}
                                                />
                                            </li>
                                            <h4 className="mt-5">Animal Health Attestation</h4>
                                            <li>
                                                <label>
                                                    <span>
                                                        Fresh meat has been obtained from II.2.2.1 above:
                                                    </span>
                                                </label>
                                                <FormController
                                                    control="radio"
                                                    name="animal_2_2_A_statements"
                                                    options={Poultry8371Labels.animal_2_2_A_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br></br>
                                            <li>
                                                <FormController
                                                    control="radio"
                                                    name="animal_2_2_B_statements"
                                                    options={Poultry8371Labels.animal_2_2_B_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br></br>
                                            <li>
                                                <label>
                                                    <span>
                                                        has been obtained from <strong> <u>animals</u> </strong>that:
                                                    </span>
                                                </label>
                                                {IsGB1Selected && (
                                                    <FormController
                                                        control="radioSingle"
                                                        name="animal_2_4_A_statements"
                                                        option={Poultry8371Labels.animal_2_4_A_statements[0]}
                                                        readOnly={readOnly}
                                                    />)}

                                                {!IsGB1Selected && (
                                                    <FormController
                                                        control="radioSingle"
                                                        name="animal_2_4_A_statements"
                                                        option={Poultry8371Labels.animal_2_4_A_statements[1]}
                                                        readOnly={readOnly}
                                                        col={2}
                                                    />)}

                                            </li>
                                            {/* <br></br>
                                            <li>
                                                <FormController
                                                    control="radio"
                                                    name="animal_2_4_A_a_statements"
                                                    options={Poultry8371Labels.animal_2_4_A_a_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li> */}
                                            <br></br>
                                            <li>
                                                <label>
                                                    <span>
                                                        has been obtained from <strong> <u>animals</u> </strong>that:
                                                    </span>
                                                </label>
                                                <FormController
                                                    control="radio"
                                                    name="animal_2_4_B_statements"
                                                    options={Poultry8371Labels.animal_2_4_B_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <li>
                                                <FormController
                                                    control="radio"
                                                    label=""
                                                    name="animal_2_4_C_statements"
                                                    options={Poultry8371Labels.animal_2_4_C_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br></br>
                                            <li>
                                                <label>
                                                    <span>
                                                        During transport to the slaugherhouse
                                                    </span>
                                                </label>
                                                <FormController
                                                    control="radio"
                                                    name="animal_2_4_F_statements"
                                                    options={Poultry8371Labels.animal_2_4_F_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br></br>
                                            <Row className="align-items-center">
                                                <Col xs="auto">
                                                    <span className="mx-2"> has been obtained from animals which have been slaughtered on</span>
                                                </Col>

                                                <Col xs="auto" className="d-flex align-items-center">
                                                    <label className="required"></label>
                                                    <FormController
                                                        control="dateInput"
                                                        name="animal_2_5_A"
                                                        isrequired="true"
                                                        value={formatDate(props.values?.animal_2_5_A)}
                                                        onChange={(e) => {
                                                            const formattedDate = formatDate(e.target.value);
                                                            props.setFieldValue('animal_2_5_A', formattedDate);
                                                            setOnDate(e.target.value);
                                                        }}
                                                        col={true}
                                                    />
                                                </Col>

                                                <Col xs="auto">
                                                    <span className="mx-2">between</span>
                                                </Col>

                                                <Col xs="auto">
                                                    <FormController
                                                        control="dateInput"
                                                        name="animal_2_5_B"
                                                        value={formatDate(props.values?.animal_2_5_B)}
                                                        readOnly={!onDate}
                                                        col={true}
                                                    />
                                                </Col>

                                                <Col xs="auto">
                                                    <span className="mx-2">and</span>
                                                </Col>

                                                <Col xs="auto">
                                                    <FormController
                                                        control="dateInput"
                                                        name="animal_2_5_C"
                                                        value={formatDate(props.values?.animal_2_5_C)}
                                                        readOnly={!onDate}
                                                        col={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <li>
                                                <FormController
                                                    control="radio"
                                                    label={Poultry8371Labels.animal_2_8_statements[0].label}
                                                    name="animal_2_8_statements"
                                                    options={Poultry8371Labels.animal_2_8_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br></br>
                                            <FormController
                                                control="checkbox"
                                                label={Poultry8371Labels.animal_2_10.label}
                                                name="animal_2_10"
                                                readOnly={readOnly}
                                            />
                                            <label><span className='text-danger'>Please select only if final destination is Sweden or Finland</span></label>
                                            <br></br>

                                            <SlaughterhouseSelect
                                                name="abattoirEstablishments"
                                                selectedFieldName="abattoirEstablishments"
                                                setEstablishmentSelected={props.setFieldValue}
                                                selections={props.values.abattoirEstablishments}
                                                label="Abattoirs"
                                                isMulti={true}
                                                countries={extractIsoCodes(props.values, memberStates)}
                                                certificateNumber={[certificateNumber]}
                                                getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                isRequired={true}
                                                readOnly={readOnly}
                                            />

                                            <ColdStoreSelect
                                                name="coldStoreEstablishments"
                                                selectedFieldName="coldStoreEstablishments"
                                                setEstablishmentSelected={props.setFieldValue}
                                                selections={props.values.coldStoreEstablishments}
                                                label="Cold Store(s)"
                                                isMulti={true}
                                                countries={"GB"}
                                                certificateNumber={certificateNumber}
                                                getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                isRequired={false}
                                                readOnly={readOnly}
                                            />


                                        </ul>
                                        {/* <PrettyPrintJson data={props.values} />   */}
                                        {/* <PrettyPrintJson data={props.errors} />   */}
                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default Poultry8371AddPage;