import React  from 'react';
import FormSubmission from "../../../common/FormSubmission";


export default function DailyAttestationSuccess(props) {

  const { signedRequestUrl, errors, title,  message, successButtonUrl, successButtonName} = props;
  return (
      <>
        <FormSubmission
            errors={errors}
            title ={title}
            message = {message}
            successButtonUrl = {successButtonUrl}
            successButtonName = {successButtonName}
            signedDocumentUrl={signedRequestUrl}
        />
    </>
  );
}
