import React, { useState, useEffect } from 'react';
import { faEdit, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Col, Modal, Row } from '@themesberg/react-bootstrap';
import { FieldArray, Form, Formik } from 'formik';
import { Alert, Spinner } from 'react-bootstrap';
import ConsignmentContentsService from '../../../../services/ConsignmentContentsService';
import EhcGenerateService from '../../../../services/EhcGenerateService';
import FormController from '../../../common/FormController';
import * as Yup from 'yup';

export default (props) => {
    const {selections, setFormSubmitted, setShowModal, selection, combinations, isGEFS, isStorageDeclaration, showModal} = props;
    const handleCloseModal = () => setShowModal(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [hasCertificates, setHasCertificates] = useState(false);

    //Show message if certificates exist for any item in modal
    useEffect(() => {
        if(selection.certificates?.length > 0){
            setHasCertificates(true);
        }
        else{
            setHasCertificates(false);
        }
    }, [showModal]);  

    //Initial values
    const initialValues = {
        productArray: 
        combinations.length == 0 ? //One product
            [{
                "weightType": JSON.parse(selection.productJson)?.productDetail?.weightType,
                "consignmentContentsId": selection.consignmentContentsId,
                "productName": selection.productName,
                "commodityCode": selection.commodityCode ?? JSON.parse(selection.productJson)?.product?.productDetails?.commodityHscode ?? '',
                "numberOfPackages": selection.numberOfPackages ?? selection.dailyAttestationItems.numberPackages ?? selection.attestation?.attestationResponse?.attestationResponseNonGEFS?.numberProductsCases ?? 0.00
            }]
        : combinations?.map((x) => ( //Combined product
            {
                "weightType": JSON.parse(selections.find(item=>item.consignmentContentsId == x.consignmentContentsId)?.productJson)?.productDetail?.weightType,
                "consignmentContentsId": selections.find(item=>item.consignmentContentsId == x.consignmentContentsId)?.consignmentContentsId,
                "productName": selections.find(item=>item.consignmentContentsId == x.consignmentContentsId)?.productName,
                "commodityCode": selections.find(item=>item.consignmentContentsId == x.consignmentContentsId)?.commodityCode ?? JSON.parse(selections.find(item=>item.consignmentContentsId == x.consignmentContentsId)?.productJson)?.product?.productDetails?.commodityHscode ?? '',
                "numberOfPackages": selections.find(item=>item.consignmentContentsId == x.consignmentContentsId)?.numberOfPackages ??selection.dailyAttestationItems.numberPackages ?? selection.attestation?.attestationResponse?.attestationResponseNonGEFS?.numberProductsCases ?? 0.00
            }
        ))
    }
    async function onSubmit(values, {resetForm}) {
        setSubmitting(true)
        values.productArray.map(async (item) => {
            item.netWeightTotal = calcNetWeight(item.consignmentContentsId, item.numberOfPackages);
            item.grossWeightTotal = calcGrossWeight(item.consignmentContentsId, item.numberOfPackages);
            const response = await ConsignmentContentsService.updateDetails(item);
            if(response.status == 200){
                const response = await EhcGenerateService.deleteGeneratedCertificates(item.consignmentContentsId);
                const result = await response.data;
                console.log("Rollback successful:", result);
            }
            else{
                alert("Error updating product details")
            }
        });
        setSubmitting(false)
        setFormSubmitted(true);
        handleCloseModal();
       
    }
    const calcNetWeight = (consignmentContentsId, numberOfPackages) => {
        var total = (JSON.parse(selections.find(item=>item.consignmentContentsId == consignmentContentsId)?.productJson)?.product?.productDetails?.netWeight * numberOfPackages).toFixed(3);
        return total;
    }
    const calcGrossWeight = (consignmentContentsId, numberOfPackages) => {
        var total = (JSON.parse(selections.find(item=>item.consignmentContentsId == consignmentContentsId)?.productJson)?.product?.productDetails?.grossWeight * numberOfPackages).toFixed(3);
        return total;
    }
    
    const calcTotalNetWeight = (combinations) => {
        var total = 0.00;
        combinations.map((x) => {
            var weight = (JSON.parse(selections.find(item=>item.consignmentContentsId == x.consignmentContentsId)?.productJson)?.product?.productDetails?.netWeight * x.numberOfPackages).toFixed(3);
            total += parseFloat(weight);
        });
        return total.toFixed(3);
    }
    
    const calcTotalGrossWeight = (combinations) => {
        var total = 0.00;
        combinations.map((x) => {
            var weight = (JSON.parse(selections.find(item=>item.consignmentContentsId == x.consignmentContentsId)?.productJson)?.product?.productDetails?.grossWeight * x.numberOfPackages).toFixed(3);
            total += parseFloat(weight);
        });
        return total.toFixed(3);
    }

    const validationMsgRequired = "This field is required";

    //Validation
    const validationSchema =
        Yup.object().shape({
            productArray: Yup.array()
            .of(Yup.object().shape({
                consignmentContentsId: Yup.string()
                    .required(validationMsgRequired),
                commodityCode: Yup.string()
                    .max(50,"Must be no more than 50 characters")
                    .required(validationMsgRequired),
                numberOfPackages: Yup
                    .number().typeError("Must enter a number or decimal")
                    .required(validationMsgRequired)
                    .min(0, "Must not be a negative number")
            }))
        });

    return(

        <Modal size="md" as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal}>
            <Modal.Header>
                <Modal.Title className="text-title">
                    <Row className="ms-3 my-1">
                        <Col md={12}><h4 className="m-0">Update Product Details</h4></Col>
                    </Row>
                </Modal.Title>
                <Button className=" mt-n4" variant="close" aria-label="Close" onClick={handleCloseModal} />
            </Modal.Header>
            <Modal.Body>
                <Row className="my-2">
                    <Col md={12}>
                        <Formik 
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            validateOnChange={true}
                            validateOnBlur={true}
                        >
                        {(formik) => (
                            <Form>
                                <Row>
                                    <Col md={12}>
                                        <FieldArray
                                            name={"productArray"}>
                                            {arrayHelpers => {
                                                return (
                                                    formik.values.productArray?.map((x,index) => (
                                                        <Row className="mx-3">
                                                            <Col md={12}>
                                                                {/* Product title */}
                                                                <div className="fs-5 fw-bold text-gray d-inline">{formik.values.productArray[index].productName}</div>
                                                                {isStorageDeclaration?
                                                                        <Badge className="rounded px-1 py-1 bg-primary d-inline fs-7 ms-3">Storage</Badge>
                                                                    :
                                                                    (
                                                                        isGEFS?
                                                                            <Badge className="rounded px-1 py-1 bg-tertiary d-inline fs-7 ms-3">GEFS</Badge>
                                                                        :
                                                                            <Badge className="rounded px-1 py-1 bg-warning d-inline fs-7 ms-3">Non-GEFS</Badge>
                                                                    )
                                                                }

                                                                <Row className="my-3">
                                                                    <Col md={6}>
                                                                    {/* Commodity code field */}
                                                                        <FormController
                                                                            control="input"
                                                                            label={"Commodity code"}
                                                                            name={`productArray.${index}.commodityCode`}
                                                                            isrequired={"true"}
                                                                            col={8}
                                                                        />
                                                                    </Col>
                                                                    <Col md={6}>
                                                                    {/* Number of packages field */}
                                                                        <FormController
                                                                            control="input"
                                                                            type="number"
                                                                            label={formik.values.productArray[index].weightType ==1 ? "Number of units": "Number of cases" }
                                                                            name={`productArray.${index}.numberOfPackages`}
                                                                            col={6}
                                                                            isrequired={"true"}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <label className="mt-3">Total net weight:</label>
                                                                <div className="fs-6 text-muted">
                                                                    {calcNetWeight(formik.values.productArray[index]?.consignmentContentsId, formik.values.productArray[index]?.numberOfPackages)}kg
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <label className="mt-3">Total gross weight:</label>
                                                                <div className="fs-6 text-muted">
                                                                    {calcGrossWeight(formik.values.productArray[index]?.consignmentContentsId, formik.values.productArray[index]?.numberOfPackages)}kg
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <hr className="my-4 text-gray-600" />
                                                            </Col>
                                                        </Row>
                                                    ))
                                                )
                                            }}
                                        </FieldArray>
                                    </Col>
                                    <Col md={12}>
                                        
                                    {formik.values.productArray.length > 1 &&
                                        <>
                                            <Row className="mx-3 text-gray-700">
                                                <Col md={12}>
                                                    <label>Combined weight totals</label>
                                                </Col>
                                                <Col md={6}>
                                                    <label className="mt-3">Net weight :</label>
                                                    <div className="fs-6 text-muted">
                                                        {calcTotalNetWeight(formik.values.productArray)}kg
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <label className="mt-3">Gross weight:</label>
                                                    <div className="fs-6 text-muted">
                                                        {calcTotalGrossWeight(formik.values.productArray)}kg
                                                    </div>
                                                </Col>
                                            </Row>
                                            <hr className="my-4 text-gray-600" />
                                        </>
                                    }
                                    </Col>
                                    <Col md={12}>
                                        {hasCertificates &&
                                            <Alert variant="warning" className="text-center fs-7">
                                                <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                                                Update will delete the generated certificate(s) for this item.
                                            </Alert>
                                        }
                                        <div className='text-center'>
                                            <Button onClick={handleCloseModal} variant="light" className="d-inline me-2">Cancel</Button>
                                            <Button type="submit" className="d-inline">
                                            {isSubmitting ? 
                                                <Spinner animation="border" size="sm" className="me-2" />
                                                :
                                                    <FontAwesomeIcon icon={faEdit} className="me-2" />
                                                }
                                                Update
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                        </Formik>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}