import *  as baseService  from "./BaseService";

const getByProductId = (id) => {
    return baseService.getApiData("/productwildgame/getByProductId?id=" + id);
};

const create = (data) => {
    return baseService.postApiData("/productwildgame/Create", data);
};

const update = (id, data) => {
    return baseService.putApiData("/productwildgame/update?id=" + id, data);
};

const ProductWildGameService = {
    create,
    getByProductId,
    update
};

export default ProductWildGameService;