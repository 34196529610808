import React, { useState, useEffect, useRef } from 'react';
import { Routes } from "../../../../../routes";
import BreadcrumbNav from "../../../../../components/BreadcrumbNav";
import { getUserInfo } from "../../../../../auth/authorisation";
import { Formik, Form } from 'formik';
import Stepper from "../../../../../components/Stepper";
import { Button, Alert, Col, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import moment from "moment"
import ResponseAttestationNonGEFSProductInfo from "./Forms/ResponseAttestationNonGEFSProductInfo";
import ResponseAttestationNonGEFSForm from "./Forms/ResponseAttestationNonGEFSForm";
import ResponseAttestationNonGEFSReview from "./Forms/ResponseAttestationNonGEFSReview";
import validationSchema from './Models/NonGEFSValidation';
import formModel from './Models/NonGEFSFormModel';
import formInitialValues from './Models/NonGEFSFormValues';
import AttestationService from "../../../../services/AttestationService"
import CertificateNumberService from "../../../../services/CertificateNumberService"
import { GetCertificateNumbers, GetCertificateFormData } from '../../../CertificateForms/CertificateFormFunctions';
import FormSubmission from "../../../common/FormSubmission";
import ProductService from '../../../../services/ProductService';
import ManufacturerService from '../../../../services/ManufacturerService';
import SiteService from '../../../../services/SiteService';
import PlatformService from '../../../../services/PlatformService';
import VetService from '../../../../services/VetService';
import OrganisationService from '../../../../services/OrganisationService';
import ActivityService from '../../../../services/ActivityService';
import { GetCertificateVersions } from '../../../CertificateForms/CertificateFormFunctions';
import AttestationAlerts from "../../../Attestations/Common/AttestationAlerts";

const steps = ['Product Information', 'Attestation Details', 'Review & Sign'];
const { formId, formField } = formModel;

function _renderStepContent(attestationId, step, setFieldValue, setFieldTouched, initialInspectionDate, touched, errors, productId, user, values, childRef, attestationHtml, submitHandler, isSubmitting, uniqueRef, isCustomUrn, product, manufacturer, platform, vets, organisation, site, productDetail, attestationData, attestationRequestData, generatedSADocument, certificateNumber, isVersionOutdated, versionErrorMessage) {
  switch (step) {
    case 0:
      return <ResponseAttestationNonGEFSProductInfo
        attestationId={attestationId}
        formField={formField}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        initialInspectionDate={initialInspectionDate}
        touched={touched} errors={errors}
        productId={productId}
        user={user}
        values={values}
        uniqueRef={uniqueRef}
        isCustomUrn={isCustomUrn}
        product={product}
        manufacturer={manufacturer}
        platform={platform}
        vets={vets}
        organisation={organisation}
        site={site}
        productDetail={productDetail}
        attestationData={attestationData}
        attestationRequestData={attestationRequestData}
      />;
    case 1:
      return <ResponseAttestationNonGEFSForm
        formField={formField}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        initialInspectionDate={initialInspectionDate}
        touched={touched} errors={errors}
        productId={productId}
        user={user}
        values={values}
        uniqueRef={uniqueRef}
        isCustomUrn={isCustomUrn}
        product={product}
        manufacturer={manufacturer}
        platform={platform}
        vets={vets}
        organisation={organisation}
        site={site}
        productDetail={productDetail}
        attestationData={attestationData}
        attestationRequestData={attestationRequestData}
        isGEFS={false}
        certificateNumber={certificateNumber}
      />;
    case 2:
      return <ResponseAttestationNonGEFSReview
        attestationHtml={attestationHtml}
        formField={formField}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        touched={touched} errors={errors}
        productId={productId}
        user={user}
        values={values}
        submitHandler={submitHandler}
        uniqueRef={uniqueRef}
        isSubmitting={isSubmitting}
        forwardedRef={childRef}
        product={product}
        manufacturer={manufacturer}
        platform={platform}
        vets={vets}
        organisation={organisation}
        site={site}
        productDetail={productDetail}
        attestationData={attestationData}
        generatedSADocument={generatedSADocument}
        isGEFS={false}
        certificateNumber={certificateNumber}
      />
    default:
      return <div>Not Found</div>;
  }
}

const ResponseAttestationNonGEFSPage = (props) => {

  const [ready, setReady] = useState(false);
  const [errors, setErrors] = useState(null);
  const childRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [productJson, setProductJson] = useState(null);
  const [successButtonInfo, setSuccessButtonInfo] = useState('');
  const [attestationHtml, setAttestationHtml] = useState('');
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [attestationId] = useState(props.match.params.id);
  const [attestationData, setAttestationData] = useState({});
  const [user, setUser] = useState('');
  const [productId, setProductId] = useState('');
  const [attestationRequestData, setAttestationRequestData] = useState('');
  const [productUrl, setProductUrl] = useState('');
  const [uniqueRef, setUniqueRef] = useState('');
  const [isCustomUrn, setIsCustomUrn] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [product, setProduct] = useState({});
  const [manufacturer, setManufacturer] = useState({});
  const [platform, setPlatform] = useState([]);;
  const [vets, setVets] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [site, setSite] = useState({});
  const [productDetail, setProductDetail] = useState({});
  const [initialInspectionDate, setInitialInspectionDate] = useState('');
  const [uploadedSADocument, setUploadedSADocument] = useState('');
  const [generatedSADocument, setGeneratedSADocument] = useState('');
  const [certificateNumber, setCertificateNumber] = useState([]);
  const [selectedCertificateNumbers, setSelectedCertificateNumbers] = useState([]);
  const [versionErrorMessage, setVersionErrorMessage] = useState([]);;
  const [isVersionOutdated, setVersionOutdated] = useState(false);

  useEffect(() => {

    async function fetchData() {
      try {
        getUserInfo()
          .then(x => {
            setUser(x);
          });

        const vetsResponse = await VetService.getAll();
        let vets = await vetsResponse.data;
        setVets(vets);

        const response = await AttestationService.getById(attestationId);
        const res = await response.data;
        const productId = await res.productId;
        setProductId(productId)
        const attestationRequest = await res.attestationRequest;

        setProductUrl(Routes.Product.path.replace(":id", "") + productId);
        setSuccessButtonInfo({ url: Routes.SupportAttestations.path, name: "Back to Support Attestations" });

        const productResponse = await ProductService.getWithDetailsById(productId);
        const product = await productResponse.data;
        setProduct(product);
        const productDetail = await product.productDetails;
        setProductDetail(productDetail);

        formInitialValues.isGEFS = await product.isGEFS;

        const certNumber = await productDetail.certificateNumber;
        const certList = await productDetail.certificateNumberList;
        setCertificateNumber(certNumber);

        const response3 = await CertificateNumberService.getAll();
        let selectedCertificateNumbers = "";
        if (Array.isArray(certList)) {
          selectedCertificateNumbers = response3.data.filter(x => certList.includes(x.number.toString()));
        } else {
          selectedCertificateNumbers = response3.data.filter(x => certList == x.number.toString());
        }
        setSelectedCertificateNumbers(selectedCertificateNumbers);

        const attestationData = await fetchCertFormData(selectedCertificateNumbers, productId);
        setAttestationData(attestationData);

        const manufacturerId = productResponse.data.manufacturerId;
        const siteId = productResponse.data.productDetails.siteId;
        const platformId = productResponse.data.productDetails.platformId;

        setAttestationRequestData(attestationRequest);
        setInitialInspectionDate(moment(attestationRequest.createdDate).format("MMMM D, YYYY"));

        const siteResponse = await SiteService.getWithEstablishmentNumberType(siteId);
        const site = await siteResponse.data
        setSite(site);

        const manufacturerResponse = await ManufacturerService.getById(manufacturerId);
        const manufacturer = await manufacturerResponse.data;
        setManufacturer(manufacturer);

        const platformResponse = await PlatformService.getWithEstablishmentNumberType(platformId);
        const platform = await platformResponse.data;
        setPlatform(platform);

        const organisationResponse = await OrganisationService.getFirst();
        const organisation = await organisationResponse.data;
        setOrganisation(organisation);

        CertificateNumberService.getAll()
          .then((response) => {

            let selectedCertificateNumbers = response.data.filter(x => certList.includes(x.number.toString()));
            let selectedCertificateVersions = GetCertificateVersions();
            selectedCertificateVersions.filter(x => certList.includes(x.number.toString()))

            fetchCertFormData(selectedCertificateNumbers, productId)
              .then(certFormData => {

                //Set attestation data for each Form
                setAttestationData(certFormData);

                //Find selected form versions from certificate config
                var outDatedList = [];
                selectedCertificateVersions.forEach(x => {
                  var certVersionNumber = certFormData[x.number]?.version;

                  if (certVersionNumber != undefined) {
                    //Check if version is outdated


                    if (certVersionNumber < x.version) {
                      setVersionOutdated(true);
                      outDatedList.push(x.number)
                    }
                  }
                });
                setVersionErrorMessage(outDatedList)

              })
              .catch((e) => {
                console.log(e);
              })

          })
          .catch((e) => { console.log(e); })

        const json = {
          product: product,
          productDetail: productDetail,
          platform: platform,
          organisation: organisation,
          manufacturer: manufacturer,
          site: site,
          attestationData: attestationData
        };

        setProductJson(json);

      } catch (e) {
        console.log(e);
      } finally {
        setReady(true);
      }


    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  //Function - Get Gertificate form data from product id and the certificate numbers required on this product
  function fetchCertFormData(selectedCertificateNumbers, id) {
    return GetCertificateFormData(id, selectedCertificateNumbers, false)
      .then(x => {
        return x
      });
  }

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    let submitData = values;
    const attestationData = await fetchCertFormData(selectedCertificateNumbers, productId);
    productJson.attestationData = attestationData;
    submitData.productJson = JSON.stringify(productJson).toString();
    submitData.uniqueCode = uniqueRef;
    submitData.attestationId = attestationId;
    submitData.filename = values.signedRequestUrl;
    submitData.documentSigningId = values.documentId;
    submitData.decision = values.decision;
    submitData.inspectionDate = values.dateInspection;


    AttestationService.createResponseNonGEFS(submitData)
      .then(async (response) => {
        //Create Activity Log

        await ActivityService.log(
          'Responded to an attestation',
          'A Non-GEFS attestation has been responded to for ' + product.productName + ' with URN ' + uniqueRef,
          'Attestation',
          'Response',
          values.signedRequestUrl,
          response.data.id
        );
      })
      .catch((e) => {
        setErrors(e.response.data);
        console.log(e);
      }
      );

    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    setSubmitAttempted(false);
    if (isLastStep) {
      _submitForm(values, actions);
    } else {

      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }

    if (activeStep == 0) {
      _handleGenerateUrn();
    }

    //Set Doc HTML for export to PDF
    if (activeStep == 1) {
      AttestationService.createSA(childRef.current.outerHTML)
        .then((response) => {
          setGeneratedSADocument(response.data);
        });
    }
  }
  function _handleGenerateUrn() {
    AttestationService.getUniqueReference(productId, false)
      .then((response) => {
        setUniqueRef(response.data);
        // alert(response.data);
      })
      .catch((e) => { console.log(e); });
    setSuccessButtonInfo({ url: (productUrl), name: "Back to product" });
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const title = "Attestation - Non GEFS";
  const breadcrumbData = {
    item1: { name: 'Support Attestations', link: Routes.SupportAttestations.path },
    item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
    item3: { name: 'Attestation Response', link: '#' },
  };

  return (
    <div className="row">
      <BreadcrumbNav data={breadcrumbData} />
      <h2>{title}</h2>

      {activeStep !== steps.length && (
        <Row>
          <Col xl={10}>
            <Stepper steps={steps} activeStep={activeStep} />
          </Col>
        </Row>
      )}

      {activeStep === steps.length ? (
        <FormSubmission
          errors={errors}
          title={"Attestation Response Sent"}
          message={"The attestation response has been sent and the attestation for this product is now valid."}
          successButtonUrl={Routes.SupportAttestations.path}
          successButtonName={"Back to support attestations"}
        />
      )
        :
        (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({ isSubmitting, setFieldValue, setFieldTouched, touched, errors, values }) => (
              <Form id={formId}>
                <AttestationAlerts
                  isVersionOutdated={isVersionOutdated}
                  versionErrorMessage={versionErrorMessage}
                  size={11}
                  productId={productId}
                  isGEFS={false}
                  attestationId={null}
                />
                {ready &&
                  _renderStepContent(attestationId, activeStep, setFieldValue, setFieldTouched, initialInspectionDate, touched, errors, productId, user, values, childRef, attestationHtml, _handleSubmit, isSubmitting, uniqueRef, isCustomUrn,
                    product,
                    manufacturer,
                    platform,
                    vets,
                    organisation,
                    site,
                    productDetail,
                    attestationData,
                    attestationRequestData,
                    generatedSADocument,
                    certificateNumber)
                }

                {(Object.keys(errors).length > 0 && submitAttempted) &&
                  <Alert
                    className="mt-2"
                    variant="danger"
                    col="sm"
                  >
                    <div className="d-flex justify-content-start">
                      <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                      Some errors were found, check the form above for any missing or incomplete information.
                    </div>
                  </Alert>
                }
                {/* 
                {JSON.stringify(errors)}  */}

                <div className={"d-inline"}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} variant="light" className="mx-3">
                      Back
                    </Button>
                  )}
                  {!isVersionOutdated ? (
                    <div className="d-inline">
                      <Button
                        onClick={() => { setSubmitAttempted(true) }}
                        disabled={isSubmitting}
                        type="submit"
                        variant="primary"
                        className={"btn-md"}
                      >
                        {activeStep == 0 ? 'Continue' : ''}
                        {activeStep == 1 ? 'Continue' : ''}
                        {activeStep == 2 ? 'Complete Attestation' : ''}

                      </Button>
                    </div>
                  )
                    :
                    (
                      <p></p>
                    )
                  }
                </div>
              </Form>
            )}
          </Formik>
        )}
    </div>
  );
};

export default ResponseAttestationNonGEFSPage;