import { Card, Table } from '@themesberg/react-bootstrap';
import React from "react";
import { Link } from 'react-router-dom';
import Preloader from "../Preloader";
import { NoResults } from "./NoResults";

export const PlatformCertifierListTable = (props) => {
  const { platformCertifiers, loaded=false} = props;
  const totalTransactions = platformCertifiers.length;

  const TableRow = (props) => {
    const {id, name, dueDate, status } = props;
    const statusVariant = status === "Paid" ? "success"
      : status === "Due" ? "warning"
        : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          {/* <Card.Link as={Link} to={Routes.PlatformViewPage.path.replace(":id",id)} className="fw-normal text-black"> */}
          <Card.Link as={Link} to="#" className="fw-normal text-black">
            {name}
          </Card.Link>
        </td>
        {/* <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td> */}
      </tr>
    );
  };

  return (
      <>

        <Preloader component={true} show={!loaded} />
        <Table hidden={!loaded || platformCertifiers==0} hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Certifiers name</th>
              {/* <th className="border-bottom">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {platformCertifiers.map(t => <TableRow key={`transaction-${t.id}`} {...t} />)}
          </tbody>
        </Table>
        {loaded && platformCertifiers.length == 0 &&(
          <NoResults name="platform certifiers"/>
        )}
      </>
  );
};
