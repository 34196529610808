import { Card, Col, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from 'react';
import ScrollToTop from "../../../../../../components/ScrollToTop";
import SigningComponent from '../../../../../../components/SigningComponent';
import FormController from "../../../../common/FormController";
import ResponseAttestationNonGEFSTemplate from "./ResponseAttestationNonGEFSTemplate";

export default function ResponseAttestationNonGEFSReview(props) {
    const {
        formField: {
            decision,
            rejectReason,
        },
        user,
        forwardedRef,
        uniqueRef,
        product, 
        manufacturer,
        hatcheries,
        platform, 
        organisation,
        site, 
        productDetail,
        speciesList, 
        attestationData,
        generatedSADocument,
        certificateNumber
    } = props;

    const [isPdfGenerating, setPdfGenerating] = useState(false);

    const decisions = [
        { group: "decision",   fieldname: "decision1",  value: 1, key: "I <span className='text-danger'>REJECT</span> this attestation request" },
        { group: "decision",   fieldname: "decision2",  value: 2, key: "I <span className='text-success'>ACCEPT</span> this attestation request"}
    ]

    //Check is PDF is being generated in backround, ready for signing
    useEffect(()=>{ 
        generatedSADocument==""? setPdfGenerating(true) : setPdfGenerating(false) 
    },[generatedSADocument]);

    return (
        <Row>
            <Col xl={11}>
                <ScrollToTop/>
                <Card border="light" className="bg-white shadow-sm mb-4 p-3">
                    <Card.Body>
                        <ResponseAttestationNonGEFSTemplate 
                            user={user}
                            values={user}
                            forwardedRef={forwardedRef}
                            uniqueRef={uniqueRef}
                            product={product}
                            manufacturer={manufacturer}
                            hatcheries={hatcheries}
                            platform={platform}
                            organisation={organisation}
                            site={site}
                            productDetail={productDetail}
                            speciesList={speciesList}
                            attestationData={attestationData}
                            certificateNumber={certificateNumber}
                        />

                        <h4>{decision.label}</h4>

                        <div className="mb-3">
                            <div className="clause-container mb-0">
                                <FormController 
                                    control="radio"
                                    name={decision.name}
                                    col={12}
                                    options={decisions}
                                />
                            </div>
                            {props.values.decision==1 &&(
                                <Row className="mt-0">
                                    <Col md={12}>
                                        <FormController 
                                            control="textarea"
                                            isrequired="true"
                                            fieldheight="100px"
                                            label={rejectReason.label}
                                            name={rejectReason.name}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </div>
                        
                        {props.values.decision==2 &&(
                            <>
                                <h4>Review Veterinary Declaration Document</h4>
                                <p>
                                    Now you have completed the Veterinary Declaration, click the button below to review and sign the document.<br />
                                    Please Note: Clicking the button below will redirect you to our secure document signing provider.
                                </p>
                                <SigningComponent
                                    document={generatedSADocument}
                                    ready={isPdfGenerating}
                                    buttonText = "Review & Sign Vet Declaration"
                                /> 
                            </>
                        )}

                    </Card.Body>
                 </Card> 
            </Col>
        </Row>
  );
  
}
