import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import Logo from "../assets/img/log_lg.png";
import LogoDark from "../assets/img/logo_dark.png";
import FoodBgDark from "../assets/img/illustrations/food_bg_dark.jpg";
import FoodBg from "../assets/img/illustrations/food_bg.jpg";
const ToggleSwitch = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('darkMode') === 'true'
  );

  useEffect(() => {
    handleDarkMode();
  }, []);

  useEffect(() => {
    handleDarkMode();
  }, [isDarkMode]);

  const dispatch = useDispatch();

  const handleDarkMode = () => {
    if (isDarkMode) {
      document.documentElement.classList.add('theme-dark');
      localStorage.setItem('darkMode', true);
      dispatch({ type: 'SET_LOGO', payload: LogoDark });
      dispatch({ type: 'SET_FOOD_BG', payload: FoodBgDark });
    } else {
      document.documentElement.classList.remove('theme-dark');
      localStorage.setItem('darkMode', false);
      dispatch({ type: 'SET_LOGO', payload: Logo });
      dispatch({ type: 'SET_FOOD_BG', payload: FoodBg });
    }
  };

  const handleToggle = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div>
      <div className="toggle-switch">
        <label className="switch">
          <input
            type="checkbox"
            checked={isDarkMode}
            onChange={handleToggle}
          />
          <span className="slider round">
            <FontAwesomeIcon
              icon={faSun}
              className="toggle-off"
            />
            <FontAwesomeIcon
              icon={faMoon}
              className="toggle-on"
            />
          </span>
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
