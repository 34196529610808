import *  as baseService  from "./BaseService";

const getCertificateGroups = (certificateNumber) => {
    return baseService.getApiData("/EhcData/GetCertificateGroups?certificateNumber=" + certificateNumber);
};
const getSavedData = (consignmentContentsId, certificateId) => {
    return baseService.getApiData("/EhcData/GetAllDataForCertificate?consignmentContentsId=" + consignmentContentsId + "&certificateId=" + certificateId);
};
const getDataForCertificate = (consignmentContentsId, certificateId) => {
    return baseService.getApiData("/EhcData/GetDataForCertificate?consignmentContentsId=" + consignmentContentsId + "&certificateId=" + certificateId);
};
const getLatestCertificateData = (certificateId, certificateNumber) => {
    return baseService.getApiData("/EhcData/GetLatestCertificateData?certificateId=" + certificateId + "&certificateNumber=" + certificateNumber);
};

 const EhcDataService = {
    getLatestCertificateData,
    getSavedData,
    getCertificateGroups,
    getDataForCertificate
};

export default EhcDataService;