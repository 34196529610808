import { Button, Card, Col } from "@themesberg/react-bootstrap";
import { Form, Formik, } from 'formik';
import React, { useEffect, useState } from 'react';
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Routes } from "../../../routes";
import VetService from "../../services/VetService";
import FormController from "../common/FormController";
import FormSubmission from "../common/FormSubmission";

import * as Yup from 'yup';

const VetAddPage = (props) => {
    
    const title = "Add Vet"
    const [isSubmitting, setSubmitting] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const breadcrumbData = {
        item1 : {name:'Vets', link: Routes.Products.path},
        item2 : {name:'Add Vet', link: 'Test'},
    };
    const initialValues = {
      name: '',
      rcvsNumber: '',
      practiceName: '',
      address1: '',
      address2: '',
      townCity: '',
      supportingEvidence: [],
      files: [],
      postcode: '',
      emailAddress: ''
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Must enter a name"),
        rcvsNumber: Yup.string().required("Must enter a RCVS Number"),
        practiceName: Yup.string().required("Must enter a Practice Name"),
        address1: Yup.string().required("Must enter an address line 1"),
        townCity: Yup.string().required("Must enter a town or city"),
        postcode: Yup.string().required("Must enter a postcode"),
        emailAddress: Yup.string().email("E-mail address is invalid").required("Must enter an E-mail address")
    });
    
    function onSubmit(values, {resetForm } ) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            VetService.create(submitData)
                .then(response => {
                    console.log(response.data);
                    setFormSubmitted(true);
                    resetForm({});
                })
                .catch(e => {
                    setSubmitting(false);
                    console.log(e);
                });
            console.info(JSON.stringify(values, null, 2));
        }, 1000);
    }
    useEffect(() => {
        
    },[]);

    return (
    <div>
        <BreadcrumbNav data={breadcrumbData}/>
        <h2>{title}</h2>
        
        {formSubmitted ? (
            <FormSubmission
                title ="Added Vet"
                message = "This vet has been successfully added to the site." 
                successButtonUrl = {Routes.Vets.path}
                successButtonName = "Back to Vets"
            />
        ) 
        : 
        (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize="true"
            validateOnChange={false}
            validateOnBlur={false}
        >
            {(props) => (
                <Col md={8}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Body>
                            <Form>
                                <FormController
                                    control="input"
                                    type="text"
                                    label="Vet Name"
                                    name="name"
                                    isrequired="true"
                                    col={6}
                                />
                                <FormController
                                    control="input"
                                    type="text"
                                    label="RCVS Number"
                                    name="rcvsNumber"
                                    isrequired="true"
                                    col={3}
                                />
                                <FormController
                                    control="input"
                                    type="text"
                                    label="Practice Name"
                                    name="practiceName"
                                    isrequired="true"
                                    col={6}
                                />
                                <FormController
                                    control="input"
                                    type="text"
                                    label="Address line 1"
                                    name="address1"
                                    isrequired="true"
                                    col={6}
                                />
                                <FormController
                                    control="input"
                                    type="text"
                                    label="Address line 2"
                                    name="address2"
                                    col={6}
                                />
                                <FormController
                                    control="input"
                                    type="text"
                                    label="Town / City"
                                    name="townCity"
                                    isrequired="true"
                                    col={6}
                                />
                                <FormController
                                    control="input"
                                    type="text"
                                    label="Postcode"
                                    name="postcode"
                                    isrequired="true"
                                    col={4}
                                />
                                <FormController
                                    control="input"
                                    type="text"
                                    label="Email address"
                                    name="emailAddress"
                                    isrequired="true"
                                    col={6}
                                />
                                {/*////// SUBMIT /////////*/}
                                <div className="form-group mt-3">
                                    <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            )}
        </Formik >
        )}
    </div>
    );
};

export default VetAddPage;