import { Routes } from "../../../routes";
import React, { useState, useMemo, useRef, useEffect } from "react";
import AttestationService from "../../services/AttestationService";
import { useTable } from "react-table";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Col,Row, Card } from '@themesberg/react-bootstrap';
import { SupportAttestationTable } from "../../../components/Tables/SupportAttestationTable";
import {getUserInfo} from "../../../auth/authorisation";

const SupportAttestationListingPage = (props) => {
    const [attestationRequests, setAttestationRequests] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const breadcrumbData = {
            item1 : {name:'Support Attestation Requests', link: Routes.SupportAttestations.path},
            item2 : {name:'View All', link: 'View All'},
        };
    const productRef = useRef();
    const [user, setUser] = useState('');
    productRef.current = attestationRequests;
    
    const retrieveProducts = () => {
         setLoaded(false);
        getUserInfo()
            .then(x => {
                setUser(x);
                AttestationService.getAllByVet(x.id)
                    .then((response) => {
                        console.info("the resp...." + JSON.stringify(response));
                        setAttestationRequests(response.data);
                        console.log(response.data);
                        setLoaded(true);
                    })
                    .catch((e) => {
                        setLoaded(true);
                        console.log(e);
             });
        });
    };

    useEffect(retrieveProducts, []);
    

    return (
        <>
            <BreadcrumbNav data={breadcrumbData}/>
            <h2 id="tabelLabel" >Support Attestation Requests</h2>
            
            <div className="col-md-12 list">
                <Row className="mt-3">
                    <Col>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                            </Card.Header>
                            <Card.Body>
                                <SupportAttestationTable loaded={loaded} attestationRequests={attestationRequests}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </div>
        </>
    );
};
export default SupportAttestationListingPage;