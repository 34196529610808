import React, { useState } from 'react';
import FormController from "../../../common/FormController"
import { Button, Col, Row, Modal, CloseButton } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress} from '@fortawesome/free-solid-svg-icons';
const PositionModal =(props) =>{

    const {show, namePrefix, className} = props;
    const handleCloseModalAdd = () => setShowModalAdd(false);
    const [showModalAdd, setShowModalAdd] = useState(false);

    function onSubmit(values, { setSubmitting, resetForm } ) {
        setTimeout(() => {
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    }

    return(
        <div className={className + " " + (show? "d-block" : "d-none")}>
                                                                
        <Button onClick={() => setShowModalAdd(true)}  variant="light" size="sm" className="my-2 text-dark">
             <FontAwesomeIcon icon={faCompress} className=" text-center" /> 
        </Button>
    
        {/* //Modal - Start*/}
        <Modal size="md" as={Modal.Dialog} centered show={showModalAdd} onHide={handleCloseModalAdd}>
            <Modal.Header>
                <Modal.Title className="h5">Stamp Position</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleCloseModalAdd} />
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3 justify-content-md-center">
                    <Col md={6}>
                        <h6 className="my-2">Position</h6>
                        <FormController
                            control="input"
                            type="number"
                            label="Distance from top"
                            placeholder=""
                            name={`${namePrefix}Top`}
                            col={4}
                            // onChange={(e) => {
                            //     props.setFieldValue('search',e.target.value);
                            //     setSearchTitle(e.target.value);
                            // }}
                        />
                        <FormController
                            control="input"
                            type="number"
                            label="Distance from left"
                            placeholder="Left"
                            name={`${namePrefix}Left`}
                            col={4}
                        />
                        <FormController
                            control="input"
                            type="number"
                            label="Added top padding"
                            placeholder=""
                            name={`${namePrefix}MarginTop`}
                            col={4}
                        />
                    </Col>
                    <Col md={6}>
                        <h5 className="my-2">Size</h5>
                        <FormController
                            control="input"
                            type="text"
                            label="Width"
                            placeholder=""
                            name={`${namePrefix}Width`}
                            col={4}
                        />
                        <FormController
                            control="input"
                            type="text"
                            label="Height"
                            placeholder="Height"
                            name={`${namePrefix}Height`}
                            col={4}
                        />
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button  
                    onClick={() => handleCloseModalAdd()}
                    variant="primary" 
                    size="md" 
                    className="mx-auto"
                    >
                        Save
                </Button>
            </Modal.Footer>
        </Modal>
        {/* //Modal - End */}
    </div>                            
    );
}

export default PositionModal;