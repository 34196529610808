import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import ProductDataService from "../../../services/ProductService";
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import moment from "moment-timezone";
import { Formik, Form, FieldArray, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import ProductBovineService from "../../../services/ProductBovineService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ZoneService from "../../../services/ZoneService";
import TemperatureService from "../../../services/TemperatureService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import * as Bovine8368Labels from "./Bovine8368Labels"
import { Col, Row, Button, Card, Alert } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faLeaf, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import MeatBseRisks from '../Meat/Components/MeatBseRisks';

const Bovine8368AddPage = (props) => {

    const [isSubmitting,  setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [storageRequired, SetStorageRequired] = useState(false);
    const [zones, SetZones] = useState([]);
    const [memberStates, SetMemberStates] = useState([]);
    const [countrys, SetCountrys] = useState([]);
    const [controlledRisk, SetControlledRisk] = useState([]);
    const [negligbleRisk, SetNegligbleRisk] = useState([]);
    const [specieslist, SetSpecies] = useState([]);
    const [apiValues, SetApiValues] = useState([]);


    let title = (editing == true ? "Edit " : "New") + " Bovine Information (#8368-For Bovine only)"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Bovine 8368', link: Routes.Bovine8368AddPage.path },
    };
    const initialValues = {
        productId: props.match.params.id,
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
        treatmentType: apiValues?.treatmentType ?? '',
        meat_MincedMeat: apiValues?.meat_MincedMeat ?? '',
        storageRequired: false,
        storageDates: apiValues?.storageDates ?? [],
        health_1_3: apiValues?.health_1_3 ?? false,
        health_1_5_statements: apiValues?.health_1_5_statements ?? false,
        countryOrigin: apiValues?.countryOrigin ?? [],
        BseRiskCategoryNegligible: apiValues?.bseRiskCategoryNegligible ?? false,
        BseRiskCategoryControlled: apiValues?.bseRiskCategoryControlled ?? false,
        BseRiskCategoryUndetermined: apiValues?.bseRiskCategoryUndetermined ?? false,
        BseRiskCategoryNegligibleStatements: apiValues?.bseRiskCategoryNegligibleStatements ?? 0,
        BseRiskCategoryControlledStatements: apiValues?.bseRiskCategoryControlledStatements ?? 0,
        BseRiskCategoryUndeterminedStatements: apiValues?.bseRiskCategoryUndeterminedStatements ?? 0,
        BseControlledC: apiValues?.bseControlledC ?? 0,
        BseNegligibleB: apiValues?.bseNegligibleB ?? 0,
        BseNegligibleC: apiValues?.bseNegligibleC ?? 0,
        health_1_11: apiValues?.health_1_11 ?? false,
        animal_2_1_B_statements: apiValues?.animal_2_1_B_statements ?? 0,
        animal_2_1_B_date: apiValues?.animal_2_1_B_date ?? null,
        animal_2_2_statements: apiValues?.animal_2_2_statements ?? 0,
        animal_2_2_B_date: apiValues?.animal_2_2_B_date ?? null,
        animal_2_2_C_date: apiValues?.animal_2_2_C_date ?? null,
        animal_2_3_E_statements: apiValues?.animal_2_3_E_statements ?? 0,
        animal_2_3_F_A: apiValues?.animal_2_3_F_A ?? false,
        animal_2_3_F_B: apiValues?.animal_2_3_F_B ?? false,
        animal_2_3_G: apiValues?.animal_2_3_G ?? false,
        animal_2_4_C: apiValues?.animal_2_4_C ?? false,
        animal_2_4CA: apiValues?.animal_2_4CA ?? null,
        animal_2_4CB: apiValues?.animal_2_4CB ?? null,
        animal_2_4CC: apiValues?.animal_2_4CC ?? null,
        animal_2_4_E: apiValues?.animal_2_4_E ?? false,
        animal_2_6_statements: apiValues?.animal_2_6_statements ?? 0,
        animal_2_7: apiValues?.animal_2_7 ?? false,
        animal_2_7_A: apiValues?.animal_2_7_A ?? false,
        animal_2_7_B: apiValues?.animal_2_7_B ?? false,
        animal_2_1_zone: apiValues?.animal_2_1_zone ?? [],
        animal_2_2_zone: apiValues?.animal_2_2_zone ?? [],
        animal_2_2_memberState: apiValues?.animal_2_2_memberState ?? [],
        slaugherhouses: apiValues?.slaugherhouses ?? '',
        cuttingPlant: apiValues?.cuttingPlant ?? '',
        idenitificationMarks: apiValues?.idenitificationMarks ?? '',
        dateOfProduction: apiValues?.dateOfProduction ?? '',
        coldstore: apiValues?.coldstore ?? '',
        bovineCarcaseWhole: apiValues?.bovineCarcaseWhole ?? false,
        bovineCarcaseSide: apiValues?.bovineCarcaseSide ?? false,
        bovineCarcaseQuarters: apiValues?.bovineCarcaseQuarters ?? false,
        bovineCuts: apiValues?.bovineCuts ?? false,
        bovineOffal: apiValues?.bovineOffal ?? false,
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";

    const treatments = [
        { value: "", key: "" },
        { value: "De-boned", key: "De-boned" },
        { value: "Bone in", key: "Bone in" },
        { value: "Matured", key: "Matured" },
        { value: "Bone in & Matured", key: "Bone in & Matured" },
    ]
    const meats = [
        { value: "", key: "" },
        { value: "meat", key: "meat" },
        { value: "minced meat", key: "minced meat" },
    ]


    //Validation
    const validationSchema = Yup.object().shape({
        meat_MincedMeat: Yup.string().required(validationMsgRequired),
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        animal_2_1_zone: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        storageDates: Yup.array().nullable()
            .when("storageRequired", {
                is: true,
                then:
                    Yup.array().of(Yup.object().shape({
                        storageDate: Yup.string().nullable().required(validationMsgRequired)
                    })
                    ).min(1, validationMsgAtLeastOne)
            }),
        countryOrigin: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        BseRiskCategoryNegligible: Yup.bool()
            .test('BseRiskCategoryNegligible', validationMsgAtLeastOne, function (x) {
                return (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined);
            }),
        BseRiskCategoryControlled: Yup.bool()
            .test('BseRiskCategoryControlled', validationMsgAtLeastOne, function (x) {
                return (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined);
            }),
        BseRiskCategoryUndetermined: Yup.bool()
            .test('BseRiskCategoryUndetermined', validationMsgAtLeastOne, function (x) {
                return (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined);
            }),
        BseRiskCategoryNegligibleStatements: Yup.number()
            .when("BseRiskCategoryNegligible", {
                is: true,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        BseNegligibleB: Yup.number()
            .when("BseRiskCategoryNegligibleStatements", {
                is: 2,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        BseNegligibleC: Yup.number()
            .when("BseRiskCategoryNegligibleStatements", {
                is: 3,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        BseRiskCategoryControlledStatements: Yup.number()
            .when("BseRiskCategoryControlled", {
                is: true,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        BseControlledC: Yup.number()
            .when("BseRiskCategoryControlled", {
                is: true,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        BseRiskCategoryUndeterminedStatements: Yup.number()
            .when("BseRiskCategoryUndetermined", {
                is: true,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),

        health_1_5_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_1_B_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_2_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_3_E_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_6_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_1_B_date: Yup.string().nullable()
            .test('animal_2_1_B_date', validationMsgRequired, function (x) {
                return (this.parent.animal_2_1_B_statements == 2 && x === null)
                    ? false : true;
            }),
        animal_2_2_B_date: Yup.string().nullable()
            .test('animal_2_2_B_date', validationMsgRequired, function (x) {
                return (this.parent.animal_2_2_statements == 2 && x === null)
                    ? false : true;
            }),
        animal_2_2_C_date: Yup.string().nullable()
            .test('animal_2_2_C_date', validationMsgRequired, function (x) {
                return (this.parent.animal_2_2_statements == 3 && x === null)
                    ? false : true;
            }),
        animal_2_2_zone: Yup.array()
            .test('animal_2_2_zone', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 2 && x.length === 0)
                    ? false : true;
            }),
        animal_2_2_memberState: Yup.array()
            .test('animal_2_2_memberState', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 3 && x.length === 0)
                    ? false : true;
            }),
        animal_2_4CA: Yup.string().nullable()
            .test('animal_2_4CA', validationMsgRequired, function (x) {
                return (this.parent.animal_2_4CB == null && this.parent.animal_2_4CC == null && x === null)
                    ? false : true;
            }),
        animal_2_4CB: Yup.string().nullable()
            .test('animal_2_4CB', validationMsgRequired, function (x) {
                return (this.parent.animal_2_4CC != null && x === null)
                    ? false : true;
            }),
        animal_2_4CC: Yup.string().nullable()
            .test('animal_2_4CC', validationMsgRequired, function (x) {
                return (this.parent.animal_2_4CB != null && x === null)
                    ? false : true;
            }),
        // animal_2_7_A: Yup.bool()
        //     .test('animal_2_7_A', validationMsgRequired, function (x) {
        //         return ((!this.parent.animal_2_7_A && !this.parent.health_2_7_B))
        //             ? false : true;
        //     }),
        slaugherhouses: Yup.string().required(validationMsgRequired),
        idenitificationMarks: Yup.string().required(validationMsgRequired),
        bovineCarcaseQuarters: Yup.bool().test('bovineCarcaseQuarters', validationMsgAtLeastOne, function (x) {
            return (this.parent.bovineCarcaseQuarters || this.parent.bovineCarcaseSide || this.parent.bovineCarcaseWhole || this.parent.bovineCuts || this.parent.bovineOffal)
        }),
        bovineCarcaseSide: Yup.bool().test('bovineCarcaseSide', validationMsgAtLeastOne, function (x) {
            return (this.parent.bovineCarcaseQuarters || this.parent.bovineCarcaseSide || this.parent.bovineCarcaseWhole || this.parent.bovineCuts || this.parent.bovineOffal)
        }),
        bovineCarcaseWhole: Yup.bool().test('bovineCarcaseWhole', validationMsgAtLeastOne, function (x) {
            return (this.parent.bovineCarcaseQuarters || this.parent.bovineCarcaseSide || this.parent.bovineCarcaseWhole || this.parent.bovineCuts || this.parent.bovineOffal)
        }),
        bovineCuts: Yup.bool().test('bovineCuts', validationMsgAtLeastOne, function (x) {
            return (this.parent.bovineCarcaseQuarters || this.parent.bovineCarcaseSide || this.parent.bovineCarcaseWhole || this.parent.bovineCuts || this.parent.bovineOffal)
        }),
        bovineOffal: Yup.bool().test('bovineOffal', validationMsgAtLeastOne, function (x) {
            return (this.parent.bovineCarcaseQuarters || this.parent.bovineCarcaseSide || this.parent.bovineCarcaseWhole || this.parent.bovineCuts || this.parent.bovineOffal)
        }),

    });

    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            console.log(submitData);

            if (!values.BseRiskCategoryNegligibleStatements !== null) {
                submitData.BseRiskCategoryNegligibleStatements = values.BseRiskCategoryNegligibleStatements;
            }

            if (apiValues.length === 0) {
                ProductBovineService.create(submitData)
                    .then(response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            else {
                ProductBovineService.update(productId, values)
                    .then(response => {
                        setFormSubmitted(true);
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            console.log(JSON.stringify(values, null, 2));
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();

        useEffect(() => {
            if (ready) {
                var selectedNegligbleRisk = [];
                var selectedControlledRisk = [];
                var selectedUndetermined = [];
                values.countryOrigin.forEach((country) => {

                    if (country.countryName != undefined && country.countryName != null) {
                        if (negligbleRisk.find(f => f.countryName === country.countryName)) {
                            selectedNegligbleRisk.push(country);

                        }
                        if (controlledRisk.find(f => f.countryName === country.countryName)) {
                            selectedControlledRisk.push(country);


                        }
                        if (!negligbleRisk.find(f => f.countryName === country.countryName) && !controlledRisk.find(f => f.countryName === country.countryName)) {
                            selectedUndetermined.push(country);
                        }
                    }
                    else {
                        setFieldValue('BseRiskCategoryNegligible', false);
                        setFieldValue('BseRiskCategoryControlled', false);
                        setFieldValue('BseRiskCategoryUndetermined', false);
                    }
                });

                if (selectedNegligbleRisk.length > 0) {
                    setFieldValue('BseRiskCategoryNegligible', true);
                }
                else {
                    setFieldValue('BseRiskCategoryNegligible', false);
                }
                if (selectedControlledRisk.length > 0) {
                    setFieldValue('BseRiskCategoryControlled', true);
                }
                else {
                    setFieldValue('BseRiskCategoryControlled', false);
                }
                if (selectedUndetermined.length > 0) {
                    setFieldValue('BseRiskCategoryUndetermined', true);
                }
                else {
                    setFieldValue('BseRiskCategoryUndetermined', false);
                }

                if (!values.BseRiskCategoryNegligible) {
                    setFieldValue('BseRiskCategoryNegligibleStatements', 0);
                    setFieldValue('BseNegligibleB', 0);
                    setFieldValue('BseNegligibleC', 0);
                }
                if (values.BseRiskCategoryNegligible && values.BseRiskCategoryNegligibleStatements != 2) {
                    setFieldValue('BseNegligibleB', 0);
                }
                if (values.BseRiskCategoryNegligible && values.BseRiskCategoryNegligibleStatements != 6) {
                    setFieldValue('BseNegligibleC', 0);
                }
                if (!values.BseRiskCategoryControlled) {
                    setFieldValue('BseRiskCategoryControlledStatements', 0);
                    setFieldValue('BseControlledC', 0);
                }
                if (!values.BseRiskCategoryUndetermined) {
                    setFieldValue('BseRiskCategoryUndeterminedStatements', 0);
                }

                if (values.meat_MincedMeat == "meat") {
                    setFieldValue('health_1_2', true);
                }
                else {
                    setFieldValue('health_1_2', false);
                }
                if (values.meat_MincedMeat == "minced meat") {
                    setFieldValue('health_1_3', true);
                }
                else {
                    setFieldValue('health_1_3', false);
                }
                setFieldValue('storageRequired', storageRequired);

                if (values.meat_MincedMeat != null) {
                    Bovine8368Labels.health_1_5_statements[1].key = "the packages of <strong>" + values.meat_MincedMeat + "</strong> have been marked with an identification mark in accordance with Section I of Annex II to Regulation (EC) No 853/2004;";
                }

                if (values?.animal_2_1_B_statements == 2 && values.animal_2_1_B_date != null) {
                    Bovine8368Labels.animal_2_1_B_statements[1].key = "in which foot and mouth disease has not been reported since " + moment(values?.animal_2_1_B_date).format("DD/MM/YYYY") + "."
                }
                else {
                    Bovine8368Labels.animal_2_1_B_statements[1].key = "in which foot and mouth disease has not been reported since ___/___/____ .";
                }
                if (!values?.animal_2_2_zone.length == 0 && values.animal_2_2_B_date != null) {
                    Bovine8368Labels.animal_2_2_statements[1].key = "have been introduced on " + moment(values?.animal_2_2_B_date).format("DD/MM/YYYY") + " into the zone referred to under point II.2.1., from the zone with code <strong><u>" + values?.animal_2_2_zone?.map(x => x.isoCode).join(", ") + "</u></strong> that at that date was authorised for the entry of fresh meat of Bovine <strong>animals</strong> into the Union and where they have remained since birth, or for at least 3 months before slaughter.";
                }
                else if (values?.animal_2_2_zone.length == 0 && values.animal_2_2_B_date != null) {
                    Bovine8368Labels.animal_2_2_statements[1].key = "have been introduced on " + moment(values?.animal_2_2_B_date).format("DD/MM/YYYY") + " into the zone referred to under point II.2.1., from the zone with code ___ - __ that at that date was authorised for the entry of fresh meat of Bovine <strong>animals</strong> into the Union and where they have remained since birth, or for at least 3 months before slaughter.";
                }
                else if (!values?.animal_2_2_zone.length == 0 && values.animal_2_2_B_date == null) {
                    Bovine8368Labels.animal_2_2_statements[1].key = "have been introduced on ___/___/____ into the zone referred to under point II.2.1., from the zone with code <strong><u>" + values?.animal_2_2_zone?.map(x => x.isoCode).join(", ") + "</u></strong> that at that date was authorised for the entry of fresh meat of Bovine <strong>animals</strong> into the Union and where they have remained since birth, or for at least 3 months before slaughter.";
                }
                else {
                    Bovine8368Labels.animal_2_2_statements[1].key = "have been introduced on ___/___/____ into the zone referred to under point II.2.1., from the zone with code ___ - __ that at that date was authorised for the entry of fresh meat of Bovine <strong>animals</strong> into the Union and where they have remained since birth, or for at least 3 months before slaughter.";
                }
                if (!values?.animal_2_2_memberState.length == 0 && values.animal_2_2_C_date != null) {
                    Bovine8368Labels.animal_2_2_statements[2].key = "have been introduced on " + moment(values?.animal_2_2_C_date).format("DD/MM/YYYY") + " into the zone referred to under point II.2.1., from the Member State with ISO code <strong><u>" + values?.animal_2_2_memberState?.map(x => x.isoCode).join(", ") + "</u></strong> and vaccination against this disease has not been carried out during a period of 12 months before the date of slaughter of the <strong>animals</strong> from which the fresh meat was obtained";
                }
                else if (values?.animal_2_2_memberState.length == 0 && values.animal_2_2_C_date != null) {
                    Bovine8368Labels.animal_2_2_statements[2].key = "have been introduced on " + moment(values?.animal_2_2_C_date).format("DD/MM/YYYY") + " into the zone referred to under point II.2.1., from the Member State with ISO code _____.";
                }
                else if (!values?.animal_2_2_memberState.length == 0 && values.animal_2_2_C_date == null) {
                    Bovine8368Labels.animal_2_2_statements[2].key = "have been introduced on ___/___/____ into the zone referred to under point II.2.1., from the Member State with ISO code <strong><u>" + values?.animal_2_2_memberState?.map(x => x.isoCode).join(", ") + "</u></strong>.";
                }
                else {
                    Bovine8368Labels.animal_2_2_statements[2].key = "have been introduced on ___/___/____ into the zone referred to under point II.2.1., from the Member State with ISO code _____.";
                }
            }
            if (values.animal_2_4CA != null) {
                setFieldValue('animal_2_4CB', null);
                setFieldValue('animal_2_4CC', null);
            }
            if (values.animal_2_4CB != null && values.animal_2_4CC != null) {
                setFieldValue('animal_2_4CA', null);
            }
            if(values.animal_2_3_F_A == true)
            {
                setFieldValue('animal_2_3_F_B', false);
            }
            if(values.animal_2_3_F_B == true)
            {
                setFieldValue('animal_2_3_F_A', false);
            }
        }, [values]);

        return null;
    };
    useEffect(() => {
        async function fetchListData() {

            try {
                const populateProductDetailData = new Promise((resolve, reject) => {
                    resolve(
                        ProductDataService.getProductDetailByProductId(productId)
                            .then(response => {
                                var res = response.data;
                                console.log(res);
                                //Get Temperature Name
                                if (res.temperatureId != null) {
                                    TemperatureService.getById(res.temperatureId)
                                        .then(response => {
                                            var res = response.data;
                                            // setTemperature(res.temperatureName);
                                            if (res.temperatureName.includes("Frozen")) {
                                                SetStorageRequired(true);
                                            }
                                            else {
                                                SetStorageRequired(false);
                                            }
                                        })
                                        .catch(e => {
                                            console.log(e);
                                        });
                                }
                            })
                            .catch(e => {
                                console.log(e);
                            })
                    );
                });
                const getCountryData = new Promise((resolve, reject) => {
                    resolve(ZoneService.getAllCountrys()
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "id": d.id,
                                "countryName": d.countryName
                            }))
                            SetCountrys(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getBSEControlledRiskData = new Promise((resolve, reject) => {
                    resolve(ZoneService.getAllByGroup("ALL_BSE_CONTROLLED_RISK")
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "id": d.id,
                                "displayName": d.displayName,
                                "countryName": d.countryName,
                                "regionName": d.regionName,
                                "isoCode": d.isoCode,
                            })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                            SetControlledRisk(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getBSENegligbleRiskData = new Promise((resolve, reject) => {
                    resolve(ZoneService.getAllByGroup("ALL_BSE_NEGLIGBLE_RISK")
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "id": d.id,
                                "displayName": d.displayName,
                                "countryName": d.countryName,
                                "regionName": d.regionName,
                                "isoCode": d.isoCode,
                            })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                            SetNegligbleRisk(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                Promise.all([populateProductDetailData, getCountryData, getBSEControlledRiskData, getBSENegligbleRiskData]).then((responses) => {
                    setReady(true);
                });
            } catch (e) {
                console.error(e);
            }
        };
        fetchListData();
    }, []);

    useEffect(() => {
        if (ready) {
            ProductBovineService.getByProductId(productId)
                .then(response => {
                    if (response.data == "") {
                        return;
                    }
                    console.log(response.data);
                    SetApiValues(response.data);
                    setEditing(true);
                    setReady(true);
                    console.log("init values:" + apiValues);
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductService.getById(productId)
                .then(response => {
                    SetProduct(response.data.data);
                    setIsGEFS(response.data.data.isGEFS);
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductScientificSpeciesDataService
                .getOnlyBovine()
                .then((_speciesResponse) => {
                    SetSpecies(_speciesResponse.data);
                })
                .finally(() => {
                });
            ZoneService.getAllByGroup("ALL_FRESH_MEAT_UNGULATES_BOV")
                .then((_zonesResponse) => {
                    const options = _zonesResponse.data.map(d => ({
                        "id": d.id,
                        "displayName": d.displayName,
                        "countryName": d.countryName,
                        "regionName": d.regionName,
                        "isoCode": d.isoCode,
                    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                    const firstItem = "GB-0";
                    options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                    SetZones(options);
                }).catch((e) => {
                    console.log(e);
                });
            ZoneService
                .getAllByGroup("ALL_MEMBER_STATES")
                .then((_countryResponse) => {
                    const options = _countryResponse.data.map(d => ({
                        "id": d.id,
                        "displayName": d.displayName,
                        "countryName": d.countryName,
                        "regionName": d.regionName,
                        "isoCode": d.isoCode,
                    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                    const firstItem = "GB";
                    options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                    SetMemberStates(options);
                }).catch((e) => {
                    console.log(e);
                });
        }
    }, [ready]);

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);


    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <>
                    <SubmissionPage
                        productId={productId}
                        attestationId={attestationId}
                        isGEFS={isGEFS}
                        errors={errors}
                        title={(editing ? "Edited" : "Completed") + " Bovine 8368 Form"}
                        message={`Bovine information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                    />

                </>

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        {/* <PrettyPrintJson data={props.values} /> */}
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        <FormController
                                            control="select"
                                            label="Meat Category"
                                            name="meat_MincedMeat"
                                            isrequired="true"
                                            options={meats}
                                            col={3}
                                        />
                                        <h4 className="mt-5">Health Attestation</h4>

                                        I hereby certify that the Bovine described in this product was produced in accordance with these
                                        requirements, in particular that it derives:

                                        <ul className="list-unstyled list-clauses" >

                                            <li className="mt-4">
                                                <FormController
                                                    control="checkbox"
                                                    label={Bovine8368Labels.health_1_3.label}
                                                    name="health_1_3"
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <li>
                                                <FormController
                                                    control="radio"
                                                    label=""
                                                    name="health_1_5_statements"
                                                    options={Bovine8368Labels.health_1_5_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <li>
                                                <FormController
                                                    id="countryOrigin"
                                                    name="countryOrigin"
                                                    label="The country of slaughter/cutting"
                                                    placeholder="Select a country of origin"
                                                    control="multiselect"
                                                    options={countrys}
                                                    defaultValues={props.values?.countryOrigin}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.countryName}
                                                    value={props.values.countryOrigin}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    onBlur={props.setFieldTouched}
                                                    onChange={props.setFieldValue}
                                                    touched={props.touched.countryOrigin}
                                                    errors={props.errors.countryOrigin}
                                                    backspaceRemovesValue={true}
                                                    isrequired="true"
                                                    col={4}
                                                />

                                                <MeatBseRisks
                                                    title="bovine products"
                                                    formNumber="8368"
                                                    values={props.values}
                                                    labels={Bovine8368Labels}
                                                    readOnly={readOnly}
                                                />
                                            </li>

                                            <li className="mt-4">
                                                <label><span className='text-danger'>Please only select if final destination is Sweden or Finland</span></label>
                                                <FormController
                                                    control="checkbox"
                                                    label={Bovine8368Labels.health_1_11.label}
                                                    name="health_1_11"
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <h4 className="mt-5">Animal Health Attestation</h4>
                                            <li>
                                                <span className='required'>The <strong>animals</strong> have been slaughtered in <strong>zone(s)</strong> with code(s):</span>
                                                <MultiSelectField
                                                    id='meat'
                                                    name='animal_2_1_zone'
                                                    placeholder="Select fields"
                                                    options={zones}
                                                    defaultValues={props.values.animal_2_1_zone}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.displayName}
                                                    value={props.values.animal_2_1_zone}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.animal_2_1_zone}
                                                    error={props.errors.animal_2_1_zone}
                                                    isClearable={true}
                                                    col={6}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_B_statements"
                                                option={Bovine8368Labels.animal_2_1_B_statements[0]}
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_B_statements"
                                                option={Bovine8368Labels.animal_2_1_B_statements[1]}
                                                readOnly={readOnly}
                                            />
                                            {(props.values.animal_2_1_B_statements == 2) && (
                                                <FormController
                                                    label="on"
                                                    control="dateInput"
                                                    name="animal_2_1_B_date"
                                                    value={props.values?.animal_2_1_B_date ? moment(props.values?.animal_2_1_B_date).format("DD/MM/YYYY") : ""}
                                                    col={3}
                                                />
                                            )}
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_B_statements"
                                                option={Bovine8368Labels.animal_2_1_B_statements[2]}
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_B_statements"
                                                option={Bovine8368Labels.animal_2_1_B_statements[3]}
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_1_B_statements"
                                                option={Bovine8368Labels.animal_2_1_B_statements[4]}
                                                readOnly={readOnly}
                                            />
                                            <hr />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_2_statements"
                                                option={Bovine8368Labels.animal_2_2_statements[0]}
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_2_statements"
                                                option={Bovine8368Labels.animal_2_2_statements[1]}
                                                readOnly={readOnly}
                                            />
                                            {(props.values.animal_2_2_statements == 2) && (
                                                <li>
                                                    <FormController
                                                        label="on"
                                                        control="dateInput"
                                                        name="animal_2_2_B_date"
                                                        value={props.values?.animal_2_2_B_date ? moment(props.values?.animal_2_2_B_date).format("DD/MM/YYYY") : ""}
                                                        col={3}
                                                    />
                                                    <br></br>

                                                    <span className='required'>from the <strong>zone </strong>with code:</span>

                                                    <MultiSelectField
                                                        id='meat'
                                                        name='animal_2_2_zone'
                                                        placeholder="Select fields"
                                                        options={zones}
                                                        defaultValues={props.values.animal_2_2_zone}
                                                        getOptionValue={option => option.id}
                                                        getOptionLabel={option => option.displayName}
                                                        value={props.values.animal_2_2_zone}
                                                        isMulti={true}
                                                        onChange={props.setFieldValue}
                                                        onBlur={props.setFieldTouched}
                                                        touched={props.touched.animal_2_2_zone}
                                                        error={props.errors.animal_2_2_zone}
                                                        isClearable={true}
                                                        col={3}
                                                        isrequired="true"
                                                        backspaceRemovesValue={true}
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                            )}
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_2_statements"
                                                option={Bovine8368Labels.animal_2_2_statements[2]}
                                                readOnly={readOnly}
                                            />

                                            {(props.values.animal_2_2_statements == 3) && (
                                                <li>
                                                    <FormController
                                                        label="on"
                                                        control="dateInput"
                                                        name="animal_2_2_C_date"
                                                        value={props.values?.animal_2_2_C_date ? moment(props.values?.animal_2_2_C_date).format("DD/MM/YYYY") : ""}
                                                        col={3}
                                                    />
                                                    <br></br>

                                                    <span className='required'>from the <strong>Member State </strong>with ISO code:</span>

                                                    <MultiSelectField
                                                        id='meat'
                                                        name='animal_2_2_memberState'
                                                        placeholder="Select fields"
                                                        options={memberStates}
                                                        defaultValues={props.values.animal_2_2_memberState}
                                                        getOptionValue={option => option.id}
                                                        getOptionLabel={option => option.displayName}
                                                        value={props.values.animal_2_2_memberState}
                                                        isMulti={true}
                                                        onChange={props.setFieldValue}
                                                        onBlur={props.setFieldTouched}
                                                        touched={props.touched.animal_2_2_memberState}
                                                        error={props.errors.animal_2_2_memberState}
                                                        isClearable={true}
                                                        col={3}
                                                        isrequired="true"
                                                        backspaceRemovesValue={true}
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                            )}
                                            <hr />
                                            <br></br>
                                            <span>has been obtained from <strong>animals</strong> coming from <strong><u>establishments</u></strong>:</span>
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_3_E_statements"
                                                option={Bovine8368Labels.animal_2_3_E_statements[0]}
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_3_E_statements"
                                                option={Bovine8368Labels.animal_2_3_E_statements[1]}
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="radioSingle"
                                                label=""
                                                name="animal_2_3_E_statements"
                                                option={Bovine8368Labels.animal_2_3_E_statements[2]}
                                                readOnly={readOnly}
                                            />
                                            <hr />
                                            <FormController
                                                control="checkbox"
                                                label={Bovine8368Labels.animal_2_3_F_A.label}
                                                name="animal_2_3_F_A"
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="checkbox"
                                                label={Bovine8368Labels.animal_2_3_F_B.label}
                                                name="animal_2_3_F_B"
                                                readOnly={readOnly}
                                            />
                                            <hr />
                                            <FormController
                                                control="checkbox"
                                                label={Bovine8368Labels.animal_2_3_G.label}
                                                name="animal_2_3_G"
                                                readOnly={readOnly}
                                            />

                                            <br></br>
                                            <p>has been obtained <strong>from animals</strong> which:</p>
                                            <label className="mt-3 mb-3 required">have been slaughtered:</label>
                                            {!props.values.animal_2_4CB && !props.values.animal_2_4CC && (
                                                <FormController
                                                    label="on"
                                                    control="dateInput"
                                                    name="animal_2_4CA"
                                                    value={props.values?.animal_2_4CA ? moment(props.values?.animal_2_4CA).format("DD/MM/YYYY") : ""}
                                                    col={2}
                                                />

                                            )}
                                            <br></br>
                                            {!props.values.animal_2_4CA && !props.values.animal_2_4CB && !props.values.animal_2_4CC && (
                                                <p>or:</p>
                                            )}
                                            {!props.values.animal_2_4CA && (

                                                <Row>
                                                    <Col sm={6} lg={5} xxl={2} >
                                                        <FormController
                                                            label="between"
                                                            control="dateInput"
                                                            name="animal_2_4CB"
                                                            value={props.values?.animal_2_4CB ? moment(props.values?.animal_2_4CB).format("DD/MM/YYYY") : ""}
                                                            col={11}
                                                        />
                                                    </Col>

                                                    <Col sm={6} lg={5} xxl={2}>
                                                        <FormController
                                                            label="and"
                                                            control="dateInput"
                                                            name="animal_2_4CC"
                                                            value={props.values?.animal_2_4CC ? moment(props.values?.animal_2_4CC).format("DD/MM/YYYY") : ""}
                                                            col={10}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                            <br></br>

                                            <li>
                                                <FormController
                                                    control="checkbox"
                                                    label={Bovine8368Labels.animal_2_4_E.label}
                                                    name="animal_2_4_E"
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br></br>
                                            <li>
                                                <span>has been strictly segregated from fresh meat not complying with the animal health requirements for the entry into the Union of fresh meat of
                                                    bovine <strong>animals</strong> throughout the operations of slaughter, cutting and until:</span>
                                                <FormController
                                                    control="radio"
                                                    label=""
                                                    name="animal_2_6_statements"
                                                    options={Bovine8368Labels.animal_2_6_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br></br>
                                            <li>
                                                <FormController
                                                    control="checkbox"
                                                    label={Bovine8368Labels.animal_2_7.label}
                                                    name="animal_2_7"
                                                    readOnly={readOnly}
                                                />
                                            </li>

                                            {props.values.animal_2_7 && (
                                                <li>
                                                    <FormController
                                                        control="checkbox"
                                                        label={Bovine8368Labels.animal_2_7_A.label}
                                                        name="animal_2_7_A"
                                                        readOnly={readOnly}
                                                    />
                                                    <FormController
                                                        control="checkbox"
                                                        label={Bovine8368Labels.animal_2_7_B.label}
                                                        name="animal_2_7_B"
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                            )}
                                            <li>
                                                <MultiSelectField
                                                    id='productScientificSpecies'
                                                    name='productScientificSpecies'
                                                    label="Species"
                                                    placeholder="Select fields"
                                                    options={specieslist}
                                                    defaultValues={props.values?.productScientificSpecies}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                                    value={props.values.productScientificSpecies}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.productScientificSpecies}
                                                    error={props.errors.productScientificSpecies}
                                                    isClearable={true}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    col={4}
                                                />
                                            </li>
                                            <label className="mt-3 mb-3 required">Nature of Commodity</label>
                                            {props.errors?.bovineCarcasewhole && (props.touched?.bovineCarcasewhole
                                                || props.touched?.bovineCarcaseside
                                                || props.touched?.bovineCarcasequarters
                                                || props.touched?.bovineCuts
                                                || props.touched?.bovineOffal) &&
                                                (<span className="invalid-feedback d-block mb-3">{props.errors.bovineCarcasewhole}</span>)
                                            }


                                            <FormController
                                                control="checkbox"
                                                label="Carcase-whole"
                                                name="bovineCarcaseWhole"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Carcase-side"
                                                name="bovineCarcaseSide"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Carcase-quarters"
                                                name="bovineCarcaseQuarters"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Cuts"
                                                name="bovineCuts"
                                                readOnly={readOnly}
                                            />

                                            <FormController
                                                control="checkbox"
                                                label="Offal"
                                                name="bovineOffal"
                                                readOnly={readOnly}
                                            />
                                            <FormController
                                                control="select"
                                                label="Treatment Type"
                                                name="treatmentType"
                                                options={treatments}
                                                col={3}
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="EU approval numbers of Slaugherhouses"
                                                name="slaugherhouses"
                                                isrequired="true"
                                                options={treatments}
                                                col={3}
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="EU approval number of Cutting plants"
                                                name="cuttingPlant"
                                                options={treatments}
                                                col={3}
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Idenitification marks"
                                                name="idenitificationMarks"
                                                isrequired="true"
                                                options={treatments}
                                                col={3}
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Date of production"
                                                name="dateOfProduction"
                                                options={treatments}
                                                col={3}
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Coldstore"
                                                name="coldstore"
                                                options={treatments}
                                                col={3}
                                            />
                                            {(storageRequired) && (
                                                <li>
                                                    <label className={storageRequired ? "required pt-3" : " pt-3"}>Date frozen <span className='text-gray-600 text-italic'>As per Notes Box reference 1.27 of EHC 8368</span></label>

                                                    <FieldArray
                                                        name="storageDates"
                                                        render={({ insert, remove, push }) => (
                                                            <div>
                                                                {props.values?.storageDates?.length > 0 &&
                                                                    props.values?.storageDates?.map((x, index) => (
                                                                        <Row key={1 + index}>
                                                                            <Col md={3} lg={2}>
                                                                                <FormController
                                                                                    label={`Freeze Date: ${1 + index}`}
                                                                                    isrequired={storageRequired ? "true" : "false"}
                                                                                    control="dateInput"
                                                                                    name={`storageDates[${index}].storageDate`}
                                                                                    value={props.values?.storageDates?.[index].storageDate ? moment(props.values?.storageDates?.[index].storageDate).format("DD/MM/YYYY") : ""}
                                                                                />
                                                                            </Col>
                                                                            <Col md={3} lg={2} className="mt-5">
                                                                                {(index > 0 &&
                                                                                    <Button variant="outline-danger" disabled={readOnly} onClick={() => remove(index)}>
                                                                                        <FontAwesomeIcon icon={faTimes} className="mx-2" />
                                                                                    </Button>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    ))}
                                                                <Button variant="light" className="mt-3 mb-2" onClick={() => push({ storageDate: "" })}>
                                                                    Add a Freeze Date
                                                                </Button>
                                                            </div>
                                                        )}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                        {/* <PrettyPrintJson data={props.values} />   */}
                                        {/* <PrettyPrintJson data={props.errors} />   */}
                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default Bovine8368AddPage;