import *  as baseService  from "./BaseService";

const getAllPaged = (params) => {
    return baseService.getApiDataWithParams("/Noticeboard/GetAllPaged", params);
};

const getAllNotices = () => {
    return baseService.getApiData("/Noticeboard/getAllNotices");
};
const NoticeboardService = {
    getAllNotices,
    getAllPaged
};

export default NoticeboardService;