import React from 'react';
import {  Button,Card, Col, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFileContract } from '@fortawesome/free-solid-svg-icons';
import BlobService from "../../services/BlobService";

export function OpenBlobButton(props) {
	const {signedDocumentUrl, linkText} = props;

    const openBlobPdf = (filename) => {
      BlobService.openBlobPdf(filename);
    }
    
    return(
		<p>
			<Button 
				variant="light" size="md" className="d-print-none"
				onClick={()=>{openBlobPdf(signedDocumentUrl)}}>
				<FontAwesomeIcon icon={faFileContract} className="me-2" />
				<span>{linkText}</span>
			</Button>

		</p>

    )
}