import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Formik, Form, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import ProductVitaminDService from "../../../services/ProductVitaminDService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ProductService from "../../../services/ProductService";
import { Button, Card, Alert } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import { MultiSelectField } from "../../common/MultiSelect";

const VitaminD8392AddPage = (props) => {

    const [isSubmitting, setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [formVersion] = useState(GetCertificateFormVersion(8392));
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [specieslist, SetSpecies] = useState([]);
    const [apiValues, SetApiValues] = useState([]);


    let title = (editing == true ? "Edit " : "New") + " Products not covered by article 8 to 26 Information (#8392-For products not covered by article 8 to 26 only)"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'For products not covered by article 8 to 26 8392', link: Routes.VitaminD8392AddPage.path },
    };
    const initialValues = {
        productId: props.match.params.id,
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";

    //Validation
    const validationSchema = Yup.object().shape({
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
    });

    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;
            console.log(submitData);

            if (apiValues.length === 0) {
                ProductVitaminDService.create(submitData)
                    .then(response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            else {
                ProductVitaminDService.update(productId, values)
                    .then(response => {
                        setFormSubmitted(true);
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            console.log(JSON.stringify(values, null, 2));
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);



    useEffect(() => {
        ProductVitaminDService.getByProductId(productId)
            .then(response => {
                if (response.data == "") {
                    return;
                }
                console.log(response.data);
                SetApiValues(response.data);
                setEditing(true);
                setReady(true);
                console.log("init values:" + apiValues);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductService.getById(productId)
            .then(response => {
                SetProduct(response.data.data);
                setIsGEFS(response.data.data.isGEFS);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductScientificSpeciesDataService
            .getOnlyHighlyRefined()
            .then((_speciesResponse) => {
                SetSpecies(_speciesResponse.data);
            })
            .finally(() => {
            });
    }, []);

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);


    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <>
                    <SubmissionPage
                        productId={productId}
                        attestationId={attestationId}
                        isGEFS={isGEFS}
                        errors={errors}
                        title={(editing ? "Edited" : "Completed") + " Products not covered by article 8 to 26 8392 Form"}
                        message={`Products not covered by article 8 to 26 Information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                    />

                </>

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        {/* <PrettyPrintJson data={props.values} />   */}
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        <MultiSelectField
                                            id='productScientificSpecies'
                                            name='productScientificSpecies'
                                            label="Species"
                                            placeholder="Select fields"
                                            options={specieslist}
                                            defaultValues={props.values?.productScientificSpecies}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                            value={props.values.productScientificSpecies}
                                            isMulti={true}
                                            onChange={props.setFieldValue}
                                            onBlur={props.setFieldTouched}
                                            touched={props.touched.productScientificSpecies}
                                            error={props.errors.productScientificSpecies}
                                            isClearable={true}
                                            isrequired="true"
                                            backspaceRemovesValue={true}
                                            col={4}
                                        />
                                        <h4 className="mt-5">Health Attestation</h4>
                                        <ol className="attestation-II-1">
                                            <li>come from (an) establishment(s) implementing a programme based on the hazard analysis and critical control points (HACCP) principles in accordance with
                                                Article 5 of Regulation (EC) No 852/2004 and regularly audited by the competent authority;</li>

                                            <li>have been handled and, where appropriate, prepared, packaged and stored in a hygienic manner in accordance with the requirements of Annex II to Regulation (EC)
                                                No 852/2004;</li>

                                            <li>fulfil the guarantees covering live animals and products thereof provided by the residue plans submitted in accordance with Article 29 of Council Directive
                                                96/23/EC, and the concerned animals and products listed in Commission Decision 2011/163/EU for the concerned country of origin;</li>

                                            <li>have been produced under conditions guaranteeing compliance with the maximum residue levels for pesticides laid down in Regulation (EC) No 396/2005 of the
                                                European Parliament and of the Council, and the maximum levels for contaminants laid down in Commission Regulation (EC) No 1881/2006.</li>

                                        </ol>
                                        <div className="form-group">
                                            <br></br>
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>

                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default VitaminD8392AddPage;