import React from 'react';
import { Card, Col, Row} from "@themesberg/react-bootstrap";
 import * as AttestationContent from "../../../Common/AttestationContent";
import { useFormikContext } from 'formik';
import AttestationBody from "../../../Common/AttestationBody";

export default function RequestAttestationTemplate(props) {
    
  const { values: formValues } = useFormikContext();
  const {
    certificateNumber,
    user,
    forwardedRef,
    uniqueRef,
    product, 
    manufacturer,
    platform, 
    organisation,
    site, 
    productDetail,
    attestationData,
    productId,
    isGEFS=true
  } = props;

  return (
      
        <Card ref={forwardedRef} border="light" className="d-none d-print-block bg-white shadow-sm mb-4 p-3">
            <Card.Body>
                <div className="attestation-title text-center">
                    <h2>Section I Supplier Declaration</h2>
                </div>
                <AttestationBody
                    finalConsumer={formValues.finalConsumer} 
                    traceability={formValues.traceability} 
                    uniqueRef={uniqueRef}
                    customUniqueRef={formValues.customUniqueRef}
                    attestationData={attestationData}
                    user={user} 
                    productId={productId}
                    organisation={organisation}
                    manufacturer={manufacturer}
                    platform={platform}
                    site={site}
                    productionDateChoice={formValues.productionDateChoice}
                    productionDate={formValues.productionDate}
                    productionDateMultipleEnd={formValues.productionDateMultipleEnd}
                    productionDateSingle={formValues.productionDateSingle}
                    productionDateMultipleStart={formValues.productionDateMultipleStart}
                    isGEFS={isGEFS}
                    product={product} 
                    productDetail={productDetail} 
                    isReadOnly={true}
                    certificateNumber={certificateNumber}
                />
                <Row className="mt-3">
                    <Col md={12}>
                        <AttestationContent.Traceability 
                            traceability={formValues.traceability} 
                            isReadOnly={true}  
                            manufacturer={manufacturer} 
                            productDetail={productDetail}
                            attestationData={attestationData}
                            isrequired={"true"}
                        />
                        <AttestationContent.TraceabilityTable 
                            finalConsumer={formValues.finalConsumer} 
                            isReadOnly={true}  
                            attestationData={attestationData}
                            certificateNumber={certificateNumber}
                            isrequired={"true"}
                        />
                        <AttestationContent.Batches
                            batchRangeFrom={formValues.batchRangeFrom}
                            batchRangeTo={formValues.batchRangeTo}
                            isReadOnly={true}   
                            isGEFS={isGEFS}
                        />
                        <AttestationContent.CertificateFormDetails 
                            certificateNumber={certificateNumber}
                            isReadOnly={true}  
                            manufacturer={manufacturer} 
                            productDetail={productDetail}
                            attestationData={attestationData}
                            isGEFS={isGEFS}
                            site={site}
                        />
                    </Col>
                </Row>
                
                <AttestationContent.AuthorisedBy user={user}  manufacturer={manufacturer}  hasSignature={true}/>

            </Card.Body>
        </Card>
  );
}
