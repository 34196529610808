import *  as baseService  from "./BaseService";

const getAll = (params) => {
    return baseService.getApiData("/productscientificspecies/getall");
};
const getById = (id) => {
    return baseService.getApiData("/productscientificspecies/GetById?id=" + id);
};
const getOnlyMeat = () => {
    return baseService.getApiData("/productscientificspecies/getMeatOnly");
};
const getOnlyFishery = () => {
    return baseService.getApiData("/productscientificspecies/getFisheryOnly");
};
const getOnlyDairy = () => {
    return baseService.getApiData("/productscientificspecies/getDairyOnly");
};
const getOnlyCollagen = () => {
    return baseService.getApiData("/productscientificspecies/getCollagenOnly");
}
const getOnlyGelatine = () => {
    return baseService.getApiData("/productscientificspecies/getGelatineOnly");
}
const getOnlyHighlyRefined = () => {
    return baseService.getApiData("/productscientificspecies/getHighlyRefinedOnly");
};
const getMeatAndFishery = () => {
    return baseService.getApiData("/productscientificspecies/getMeatAndFishery");
};
const getOnlyWildGame = () => {
    return baseService.getApiData("/productscientificspecies/getWildGameOnly");
};
const getOnlyDogChews = () => {
    return baseService.getApiData("/productscientificspecies/getDogChewsOnly");
};
const getOnlyCasing = () => {
    return baseService.getApiData("/productscientificspecies/getCasingOnly");
};
const getOnlyPorcine = () => {
    return baseService.getApiData("/productscientificspecies/getPorcineOnly");
};
const getOnlyOvine = () => {
    return baseService.getApiData("/productscientificspecies/getOvineOnly");
};
const getOnlyBovine = () => {
    return baseService.getApiData("/productscientificspecies/getBovineOnly");
};
const getOnlyPoultry = () => {
    return baseService.getApiData("/productscientificspecies/GetPoultryOnly");
};
const getOnlyEgg = () => {
    return baseService.getApiData("/productscientificspecies/getEggOnly");
};
const getOnlyStorageDeclaration = () => {
    return baseService.getApiData("/productscientificspecies/getStorageDeclarationOnly");
};
const ProductScientificSpeciesService = {
    getAll,
    getOnlyMeat,
    getOnlyFishery,
    getOnlyDairy,
    getOnlyCollagen,
    getOnlyGelatine,
    getOnlyHighlyRefined,
    getMeatAndFishery,
    getOnlyWildGame,
    getOnlyDogChews,
    getOnlyCasing,
    getOnlyPorcine,
    getOnlyOvine,
    getOnlyBovine,
    getOnlyPoultry,
    getOnlyEgg,
    getOnlyStorageDeclaration,
    getById
};

export default ProductScientificSpeciesService;