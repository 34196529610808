import formModel from './GEFSFormModel';
const {
  formField: {
    firstInspection,
    changeStatement,
    changesDescription,
    dateInspection,
    decision,
    rejectReason,
    signedRequestUrl,
    documentId,
    isGEFS,
    inspectionType
  }
} = formModel;

export default {
  [firstInspection.name]: false,
  [changeStatement.name]: 0,
  [inspectionType.name]: 0,
  [changesDescription.name]: '',
  [isGEFS.name]: true,
  [dateInspection.name]: '',
  [decision.name]: '',
  [rejectReason.name]: '',
  [signedRequestUrl.name]: '',
  [documentId.name]: '',
};



