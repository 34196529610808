import React, { useState, useEffect} from 'react';
import { Formik, Form, useFormikContext} from 'formik';
import FormController from "../../../common/FormController"
import { Button, Col, Row, Modal, CloseButton } from "@themesberg/react-bootstrap";

const AddGroupModal =(props) =>{
    const {values, totalPages, rect, show, handleDataObjectUpdate,certificateGroups, showModalAdd, handleCloseModalAdd, scale, className} = props;
    const [data, setData] = useState(props.data);
    const [pages, setPages] = useState([]);

    useEffect(() => {

        setData(data); //Pass value from parent if it has one
        
      }, []);

    useEffect(() => {

        var pageList = [];
        for(var i=0; i < totalPages; i++){
            var pageNumber = i + 1;
            pageList.push(parseInt(pageNumber));
        }
        setPages(pageList);
      }, [totalPages]);

    var initialValues = ({
        groupId: '',
        pages: [],
        groupName: '',
        parentGroupId: ''
    })


    function onSubmit(values, { setSubmitting, resetForm } ) {
        setTimeout(() => {
           // var certificateGroups = formValues?.certificateGroups;
           // if(certificateGroups !== undefined && certificateGroups !== null){
                var obj = 
                {
                    pages: values.pages,
                    groupId: values.groupId,
                    groupName: values.groupName,
                    parentGroupId: values.parentGroupId
                }
                var newgroups = certificateGroups.push(obj);
                data.certificateGroups = newgroups;
                handleDataObjectUpdate(data);
                handleCloseModalAdd();
          //  }
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    }
    return(
        <div className={className + " " + (show? "d-block" : "d-none")}>
       
        {/* //Modal - Start*/}
        <Modal size="sm" as={Modal.Dialog} centered show={showModalAdd} onHide={handleCloseModalAdd}>
            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
            >
                {(formik, isSubmitting, setFieldValue) => (
                    <Form>
                        <Modal.Header>
                            <Modal.Title className="h5">Add New Group</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={handleCloseModalAdd} />
                        </Modal.Header>
                        <Modal.Body>

                            <Row className="mb-3 justify-content-md-center">
                                
                                <Col md={12}>
                                    <Row>
                                        <Col md={6}>
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Group name"
                                                placeholder=""
                                                isrequired={"true"}
                                                name={`groupName`}
                                                col={12}
                                                size="sm"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Group Id"
                                                placeholder=""
                                                isrequired={"true"}
                                                name={`groupId`}
                                                col={12}
                                                size="sm"
                                            />
                                        </Col>
                                        <Col md={6}>
                                        <FormController
                                            id="pages"
                                            name="pages"
                                            placeholder="Select pages"
                                            control="multiselect"
                                            options={pages}
                                            isMulti={true}
                                            isClearable={true}
                                            onBlur={formik.setFieldTouched}
                                            onChange={(name, value) => {
                                            formik.setFieldValue('pages',value);
                                            }}
                                            getOptionValue={option => option}
                                            getOptionLabel={option => option}
                                            // touched={props.touched.pages}
                                            // errors={props.errors.pages}
                                            isrequired="true"
                                            col={12}
                                        />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Group Id"
                                                placeholder=""
                                                isrequired={"true"}
                                                name={`groupId`}
                                                col={12}
                                                size="sm"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Parent Group Id"
                                                placeholder=""
                                                isrequired={"true"}
                                                name={`parentGroupId`}
                                                col={12}
                                                size="sm"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                {/* <FontAwesomeIcon icon={faFilter} /> */}
                                {isSubmitting ? "Please wait..." : "Save Group"}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik > 
        </Modal>
        {/* //Modal - End */}
    </div>                            
    );
}

export default AddGroupModal;