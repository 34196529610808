import *  as baseService  from "./BaseService";

const getBySiteId = (siteId) => {
    return baseService.getApiData("/SiteCertificateNumbers/GetBySiteId?siteId=" + siteId);
};
const getBySiteIdAndIsGEFS = (siteId,IsGEFS,IsNonGEFS) => {
    return baseService.getApiData("/SiteCertificateNumbers/GetBySiteIdAndIsGEFS?siteId=" + siteId + "&IsGEFS=" + IsGEFS + "&IsNonGEFS=" + IsNonGEFS);
};
const checkSiteAllowsCertificateNumbers = (params) => {
    return baseService.postApiData("/SiteCertificateNumbers/checkSiteAllowsCertificateNumbers", params);
};
const SiteCertificateNumbersService = {
    getBySiteId,
    getBySiteIdAndIsGEFS,
    checkSiteAllowsCertificateNumbers
};


export default SiteCertificateNumbersService;