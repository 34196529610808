import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Row, Col, Form, Modal, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RadioButtonCard = ({ name, options, disabled }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(name);

    return (
        <Row>
            {options.map((option) => (
                <Col xs={12} md={6} lg={6} key={option.key}>

                <Card 
                    disabled={disabled}
                    className={`mt-3 border-3 ${field.value === option.value ? ' border-primary bg-lightblue' : 'bg-light'}`} 
                    onClick={() => setFieldValue(name, option.value)}
                    style={{ cursor: 'pointer' }}
                >
                    <Card.Body className='p-3'>
                        <div className='d-flex justify-content-start'>
                            <div  className='d-flex justify-content-between'>
                            <FontAwesomeIcon icon={option.icon} className="me-1 mt-1" />
                            <div className="fs-6 fw-bold mb-1">{option.title}</div>
                            </div>
                        </div>
                        <div>
                            <div className="fs-7">{option.description}</div>
                        </div>
                    </Card.Body>
                </Card>
                </Col>
            ))}
        </Row>
    );
};

export default RadioButtonCard;