import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Formik, Form, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import ProductGelatineService from "../../../services/ProductGelatineService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import * as Gelatine8390Labels from "./Gelatine8390Labels"
import { Button, Card, Alert } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import MeatBseRisks from '../Meat/Components/MeatBseRisks';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import ZoneService from '../../../services/ZoneService';
import SiteService from '../../../services/SiteService';
import EstablishmentsService from '../../../services/EstablishmentsService';
import SiteSelect from '../../../../components/EstablishmentSelect/SiteSelect';
import ActivityService from '../../../services/ActivityService';


const Gelatine8390AddPage = (props) => {
    const certificateNumber = 8390;
    const [isSubmitting,  setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [formVersion] = useState(GetCertificateFormVersion(certificateNumber));
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [specieslist, SetSpecies] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [apiValues, SetApiValues] = useState([]);
    const [controlledRisk, SetControlledRisk] = useState([]);
    const [negligbleRisk, SetNegligbleRisk] = useState([]);
    const [countrys, SetCountrys] = useState([]);
    const [siteEstablishment, setSiteEstablishment] = useState([]);
    const [manufacturingPlantEstab, setManufacturingPlantEstab] = useState([]);
    let title = (editing == true ? "Edit " : "New") + " Gelatine Information (#8390-For Gelatine products only)"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Gelatine 8390', link: Routes.Gelatine8390AddPage.path },
    };
    const initialValues = {
        productId: props.match.params.id,
        health_1_5_statements: apiValues?.health_1_5_statements ?? 0,
        health_1_6: apiValues?.health_1_6 ?? false,
        manufacturingPlant: apiValues?.manufacturingPlant ?? '',
        identificationMark: apiValues?.identificationMark ?? '',
        countryOrigin: apiValues?.countryOrigin ?? [],
        BseControlled: apiValues?.bseControlled ?? 0,
        BseRiskCategoryNegligible: apiValues?.bseRiskCategoryNegligible ?? false,
        BseRiskCategoryControlled: apiValues?.bseRiskCategoryControlled ?? false,
        BseRiskCategoryUndetermined: apiValues?.bseRiskCategoryUndetermined ?? false,
        BseRiskCategoryNegligibleStatements: apiValues?.bseRiskCategoryNegligibleStatements ?? null,
        BseRiskCategoryControlledStatements: apiValues?.bseRiskCategoryControlledStatements ?? null,
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
        identificationMarkEstablishment: apiValues?.identificationMarkEstablishment ?? siteEstablishment[0],
        manufacturingPlantEstablishments: apiValues?.manufacturingPlantEstablishments ?? [],
    }
    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";

    //Validation
    const validationSchema = Yup.object().shape({
        identificationMarkEstablishment: Yup.object().nullable().required(validationMsgRequired),
        manufacturingPlantEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        countryOrigin: Yup.array()
            .when("health_1_6", {
                is: true,
                then: Yup.array().min(1, validationMsgAtLeastOne)
            }),
        BseRiskCategoryNegligible: Yup.bool()
            .test('BseRiskCategoryNegligible', validationMsgAtLeastOne, function (x) {
                return (this.parent.health_1_6) ? (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined) : true;
            }),
        BseRiskCategoryControlled: Yup.bool()
            .test('BseRiskCategoryControlled', validationMsgAtLeastOne, function (x) {
                return (this.parent.health_1_6) ? (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined) : true;
            }),
        BseRiskCategoryUndetermined: Yup.bool()
            .test('BseRiskCategoryUndetermined', validationMsgAtLeastOne, function (x) {
                return (this.parent.health_1_6) ? (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined) : true;
            }),
        BseRiskCategoryNegligibleStatements: Yup.array().nullable()
            .when("BseRiskCategoryNegligible", {
                is: true,
                then: Yup.array().min(1, validationMsgAtLeastOne)
            }),
        BseRiskCategoryControlledStatements: Yup.array().nullable()
            .when("BseRiskCategoryControlled", {
                is: true,
                then: Yup.array().min(1, validationMsgAtLeastOne)
            }),
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
    });


    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;
            console.log(submitData);
            if (!values.BseRiskCategoryControlled !== null) {
                submitData.BseRiskCategoryControlled = values.BseRiskCategoryControlled;
            }
            if (!values.bseRiskCategoryNegligible !== null) {
                submitData.bseRiskCategoryNegligible = values.bseRiskCategoryNegligible;
            }
            if (!values.bseRiskCategoryUndetermined !== null) {
                submitData.bseRiskCategoryUndetermined = values.bseRiskCategoryUndetermined;
            }
            if (!values.BseRiskCategoryControlledStatements !== null) {
                submitData.BseRiskCategoryControlledStatements = values.BseRiskCategoryControlledStatements;
            }
            if (!values.BseRiskCategoryNegligibleStatements !== null) {
                submitData.BseRiskCategoryNegligibleStatements = values.BseRiskCategoryNegligibleStatements;
            }


            if (apiValues.length === 0) {
                ProductGelatineService.create(submitData)
                    .then(async response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Gelatine 8390',
                            8390,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            else {
                ProductGelatineService.update(productId, values)
                    .then(async response => {
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Gelatine 8390',
                            8390,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    ////perform this action on page load 
    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();
        useEffect(() => {

            if (ready) {
                values.countryOrigin.forEach((country) => {

                    if (country.countryName != undefined && country.countryName != null) {
                        if (negligbleRisk.find(f => f.countryName === country.countryName)) {
                            setFieldValue('BseRiskCategoryNegligible', true);

                        }
                        if (controlledRisk.find(f => f.countryName === country.countryName)) {
                            setFieldValue('BseRiskCategoryControlled', true);

                        }
                        if (!negligbleRisk.find(f => f.countryName === country.countryName) && !controlledRisk.find(f => f.countryName === country.countryName)) {
                            setFieldValue('BseRiskCategoryUndetermined', true);
                        }
                    }
                    else {
                        setFieldValue('BseRiskCategoryNegligible', false);
                        setFieldValue('BseRiskCategoryControlled', false);
                        setFieldValue('BseRiskCategoryUndetermined', false);
                    }
                });
            }
            setManufacturingPlantEstab(values?.manufacturingPlantEstablishments)

            if (!values.BseRiskCategoryNegligible) {
                setFieldValue('BseRiskCategoryNegligibleStatements', null);
            }
            if (!values.BseRiskCategoryControlled) {
                setFieldValue('BseRiskCategoryControlledStatements', null);
            }

            let healthStatements = 0;

            if (values.productScientificSpecies.some(obj => obj.isMeat === true)) {
                healthStatements = 1;
            } else if (values.productScientificSpecies.some(obj => obj.isWildGame === true)) {
                healthStatements = 2;
            } else if (values.productScientificSpecies.some(obj => obj.isFishery === true)) {
                healthStatements = 3;
            }

            setFieldValue('health_1_5_statements', healthStatements);

            const hasAnyBOVOVCAP = values.productScientificSpecies.some(
                obj => obj.isBovine || obj.isOvine || obj.isCaprine
            );

            setFieldValue('health_1_6', hasAnyBOVOVCAP);
        },
            [values]);
        return null;
    };
    useEffect(() => {
        async function fetchListData() {
            try {
                const getCountryData = new Promise((resolve, reject) => {
                    resolve(ZoneService.getAllByGroup("8390_TRACES_COUNTRYS")
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "id": d.id,
                                "countryName": d.countryName
                            })).sort((a, b) => a.countryName.localeCompare(b.countryName));
                            SetCountrys(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getBSEControlledRiskData = new Promise((resolve, reject) => {
                    resolve(ZoneService.getAllByGroup("ALL_BSE_CONTROLLED_RISK")
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "id": d.id,
                                "displayName": d.displayName,
                                "countryName": d.countryName,
                                "regionName": d.regionName,
                                "isoCode": d.isoCode,
                            })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                            SetControlledRisk(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getBSENegligbleRiskData = new Promise((resolve, reject) => {
                    resolve(ZoneService.getAllByGroup("ALL_BSE_NEGLIGBLE_RISK")
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "id": d.id,
                                "displayName": d.displayName,
                                "countryName": d.countryName,
                                "regionName": d.regionName,
                                "isoCode": d.isoCode,
                            })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                            SetNegligbleRisk(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                Promise.all([getCountryData, getBSEControlledRiskData, getBSENegligbleRiskData]).then((responses) => {
                    setReady(true);
                });
            } catch (e) {
                console.error(e);
            }
        };
        fetchListData();
    }, []);


    useEffect(() => {
        if (ready) {
            //Get site estab
            ProductService.getProductDetailByProductId(productId)
                .then(response4 => {
                    //Get site operatorId
                    SiteService.getById(response4.data.siteId)
                        .then(response2 => {

                            //Get establishment data from operatorid
                            EstablishmentsService.getEstablishmentsDetails([response2.data.tracesId])
                                .then(response3 => {
                                    //set parent site for this product
                                    setSiteEstablishment(response3.data)
                                }, [])
                                .catch(e => {
                                    console.log(e);
                                });
                        }, [])
                        .catch(e => {
                            console.log(e);
                        });
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductGelatineService.getByProductId(productId)
                .then(response => {
                    var res = response.data;
                    setManufacturingPlantEstab(res.manufacturingPlantEstablishments)
                    if (response.data == "") {
                        return;
                    }
                    SetApiValues(res);
                    if (res !== null) {
                        setEditing(true);
                    }
                    setReady(true);
                    console.log("init values:" + apiValues);
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductService.getById(productId)
                .then(response => {
                    SetProduct(response.data.data);
                    setIsGEFS(response.data.data.isGEFS);
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductScientificSpeciesDataService
                .getOnlyGelatine()
                .then((_speciesResponse) => {
                    const speciesOptions = _speciesResponse.data.map(d => ({
                        "id": d.id,
                        "speciesName": d.speciesName,
                        "alternativeName": d.alternativeName,
                        "isMeat": d.isMeat,
                        "isWildGame": d.isWildGame,
                        "isFishery": d.isFishery,
                        "isBovine": d.isBovine,
                        "isOvine": d.isOvine,
                    })).sort((a, b) => a.speciesName.localeCompare(b.speciesName));
                    SetSpecies(speciesOptions);
                })
                .finally(() => {
                });

        }
    }, [ready]);

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);


    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <>
                    <SubmissionPage
                        productId={productId}
                        attestationId={attestationId}
                        isGEFS={isGEFS}
                        errors={errors}
                        title={(editing ? "Edited" : "Completed") + " Gelatine 8390 Form"}
                        message={`Gelatine information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                    />

                </>

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>

                                        {/* <PrettyPrintJson data={props.values} />   */}
                                        <p>Product: {product.productName} (Product Code: {product.productCode}, EAN: {product.ean})</p>
                                        <hr />
                                        <h4 className="mt-5">Health Information</h4>

                                        I hereby certify that the gelatine described in this product was produced in accordance with these
                                        requirements, in particular that it derives:

                                        <ul className="list-unstyled list-clauses" >
                                            <li>
                                                <MultiSelectField
                                                    id='productScientificSpecies'
                                                    name='productScientificSpecies'
                                                    label="Species"
                                                    placeholder="Select fields"
                                                    options={specieslist}
                                                    defaultValues={props.values?.productScientificSpecies}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                                    value={props.values.productScientificSpecies}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.productScientificSpecies}
                                                    error={props.errors.productScientificSpecies}
                                                    isClearable={true}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    col={4}
                                                />
                                            </li>
                                            <li>
                                                <FormController
                                                    control="radio"
                                                    label=""
                                                    name="health_1_5_statements"
                                                    options={Gelatine8390Labels.health_1_5_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br />
                                            {props.values.health_1_5_statements == 1 && (
                                                <li>
                                                    <FormController
                                                        control="checkbox"
                                                        label={Gelatine8390Labels.health_1_6.label}
                                                        name="health_1_6"
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                            )}
                                            {props.values.health_1_6 && props.values.health_1_5_statements == 1 && (
                                                <li>
                                                    <FormController
                                                        id="countryOrigin"
                                                        name="countryOrigin"
                                                        label="Country of animal origin"
                                                        placeholder="Select a country of origin"
                                                        control="multiselect"
                                                        options={countrys}
                                                        defaultValues={props.values?.countryOrigin}
                                                        getOptionValue={option => option.id}
                                                        getOptionLabel={option => option.countryName}
                                                        value={props.values.countryOrigin}
                                                        isMulti={true}
                                                        isClearable={true}
                                                        onBlur={props.setFieldTouched}
                                                        onChange={props.setFieldValue}
                                                        touched={props.touched.countryOrigin}
                                                        errors={props.errors.countryOrigin}
                                                        isrequired="true"
                                                        backspaceRemovesValue={true}
                                                        col={4}
                                                    />
                                                    <MeatBseRisks
                                                        title="gelatine products"
                                                        formNumber="8390"
                                                        values={props.values}
                                                        labels={Gelatine8390Labels}
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                            )}

                                            <li>
                                                <SiteSelect
                                                    name="manufacturingPlantEstablishments"
                                                    selectedFieldName="manufacturingPlantEstablishments"
                                                    setEstablishmentSelected={props.setFieldValue}
                                                    selections={props.values.manufacturingPlantEstablishments}
                                                    label="Manufacturing Plant"
                                                    isMulti={true}
                                                    countries={props.values.countryOrigin.map(x => x.isoCode)}
                                                    certificateNumber={certificateNumber}
                                                    getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                    getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                    isRequired={true}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <li>
                                                <MultiSelectField
                                                    id='identificationMarkEstablishment'
                                                    name='identificationMarkEstablishment'
                                                    label="Identification mark"
                                                    placeholder="Select fields"
                                                  getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                    getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                    isMulti={false}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.identificationMarkEstablishment}
                                                    error={props.errors.identificationMarkEstablishment}
                                                    isClearable={true}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                    col={6}
                                                />
                                            </li>
                                        </ul>
                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default Gelatine8390AddPage;