import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import FormController from "../common/FormController"
import InvitationService from "../../services/InvitationService"
import ManufacturerDataService from "../../services/ManufacturerService"
import { Card, Button} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';

const ManufacturerInvite = (props) => {
    const {manufacturerName, manufacturerId} = props;
	const [selected, setSelected] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [sentEmail, setSentEmail] = useState(false);
	const [manufacturers, setManufacturers] = useState([]);

    useEffect(() => {
        if(manufacturerId == undefined || manufacturerId == null || manufacturerId == ""){
            setSelected(true);
            getManufacturerData();
        }
    },[]);

    async function getManufacturerData(){
        ManufacturerDataService.getAll()
            .then((response) => {
                const options = response.data.map(d => ({
                    "value": d.id,
                    "label": d.manufacturerName
                })).sort((a, b) => a.label.localeCompare(b.label));
                setManufacturers(options);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    return (
        <Formik
            initialValues={{  
                selected: selected, 
                inviteEmail: '', 
                selectedManufacturerId: [], 
                role: 'Manufacturer', 
                itemId: manufacturerId
            }}
            enableReinitialize="true"
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values, { setSubmitting }) => {
                if(selected){
                    values.itemId = values.selectedManufacturerId.value;
                }

                if(typeof values.itemId === 'string') {
                    values.itemId = values.itemId;
                }
                else{
                    values.itemId = values.itemId[0];
                }
                setTimeout(() => {
                    InvitationService.invite(values)
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(e => {
                        console.log(e);
                    });
                console.log(JSON.stringify(values, null, 2));
                setSubmitting(false);
                setSubmitted(true);
                setSentEmail(values.inviteEmail);

                }, 1000);
            }}
            validationSchema={yup.object({
                selectedManufacturerId: yup.object().nullable()
                    .when("selected",{
                    is: true,
                    then: yup.object().nullable().required("Must choose a manufacturer")
                }),
                inviteEmail: yup.string().email("Invalid email address").required("Must enter an email address "),
            })}
        >
        {(formik, isSubmitting) => (
            <Card border="light" className="bg-lightblue shadow-sm mb-4">
                <Card.Header>
                    <h4 className="mb-0" >Invite an Authorised Signatory</h4>
                </Card.Header>
                <Card.Body>
                {!submitted?(
                    <Form className="text-align" noValidate >
                        {selected === true ?(
                            <>
                            <p>Invite a user by email to administer products and sign documents on behalf of a manufacturer</p>
                                <FormController
                                    id="selectedManufacturerId"
                                    name="selectedManufacturerId"
                                    placeholder="Select a manufacturer"
                                    control="multiselect"
                                    options={manufacturers}
                                    isMulti={false}
                                    isClearable={true}
                                    onBlur={formik.setFieldTouched}
                                    onChange={formik.setFieldValue}
                                    touched={formik.touched.selectedManufacturerId}
                                    errors={formik.errors.selectedManufacturerId}
                                    isrequired="true"
                                    col={12}
                                />
                            </>
                        ):
                        (
                            <p>Invite a user by email to administer products and sign documents on behalf of {manufacturerName}</p>
                        )}
                        <FormController
                            control="input"
                            type="email"
                            label={null}
                            name="inviteEmail"
                            placeholder="Email Address"
                            errors={formik.errors.inviteEmail}
                            touched={formik.touched.inviteEmail}
                        />
                        <div className="d-grid gap-2">
                        <Button type="submit" variant="outline-dark width-100" size="md" className="mt-2"> 
                            <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                            <span>Invite</span>
                        </Button>
                        </div>
                    </Form>
                    ):
                    (
                        <p>
                            <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> Invitation successfully sent to {sentEmail}
                        </p>
                    )
                    }
                </Card.Body>
            </Card>
        )}
        </Formik >
    );
};

export default ManufacturerInvite;