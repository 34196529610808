import React, { useState, useEffect, useRef } from 'react';
import AttestationService from "../../../services/AttestationService"
import DailyAttestationService from "../../../services/DailyAttestationService"
import moment from "moment"
import DailyAttestationAddPage from './DailyAttestationAddPage';
import formInitialValues from './Forms/Models/DailyAttestationValues';
import {ReadDataToProductItem} from './Forms/FormComponents/DailyAttestationProductListFunctions'

const DailyAttestationClonePage = (props) => {
    
  const [cloneId] = useState(props.match.params.id);
  const [cloneData, setCloneData] = useState(null);
  
  
  useEffect(()=>{ 
    if(formInitialValues!==undefined){
      var data = formInitialValues;
      
      DailyAttestationService.getById(cloneId)
      .then((response) => {
        var res = response.data;
        data.commercialNumber = res.commercialNumber;
        data.uniqueCodeClone = res.uniqueCode;
        data.dailyAttestationData = res.dailyAttestationItems;


        data.dailyAttestationData.map((x, i) => {

            
          if(x.isDateCollectionProductionMulti){
            x.dateCollectionProductionSingle = null ;
            x.dateCollectionProductionStart = moment(x.dateCollectionProductionStart);
            x.dateCollectionProductionEnd = moment(x.dateCollectionProductionEnd);
          }
          else{
            x.dateCollectionProductionSingle = moment(x.dateCollectionProductionStart);
            x.dateCollectionProductionStart = null;
            x.dateCollectionProductionEnd = null;
          }
          }
        );
        data.productsSelected = [];
        data.documentId = null;
        data.signedRequestUrl = null;

            
          res.dailyAttestationItems.forEach((x, i) => {
            AttestationService.getById(x.attestationId)
            .then((response2) => 
            {
              var attestation = response2.data;
              data.productsSelected.push(ReadDataToProductItem(attestation.id, attestation.certificateNumberList, x.id, attestation.attestationRequest, attestation.batchNumberRange, attestation.batchNumbers, attestation.attestationDetails, attestation.validFrom, attestation.validTo))
            })
            .catch((e) => {
              console.log(e);
            })
          });

        })
        .catch((e) => {console.log(e);});
        
        setCloneData(data);
    }
  
  },[]);

  return (
        <DailyAttestationAddPage cloneData={cloneData} />
    );
};

export default DailyAttestationClonePage;