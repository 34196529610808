import *  as baseService  from "./BaseService";

const getAll = (params) => {
    return baseService.getApiData("/Zone/getall");
};
const getById = (id) => {
    return baseService.getApiData("/Zone/GetById?id=" + id);
};
const getByRegionId = (regionId) => {
    return baseService.getApiData("/Zone/getByRegionId?id=" + regionId);
};
const getAllByGroup = (name) => {
    return baseService.getApiData("/ZoneGroupSelectionZones/getAllByGroup?name=" + name);
};
const getAllCountrys = (params) => {
    return baseService.getApiData("/Zone/getAllCountrys");
};
const getAllRegions = (params) => {
    return baseService.getApiData("/Zone/getAllRegions");
};
const ZoneService = {
    getAll,
    getById,
    getByRegionId,
    getAllByGroup,
    getAllCountrys,
    getAllRegions
};

export const zoneSpeciesGroups = [
    { zoneGroupName: '8350_MEAT_PRODUCTS_BOV_A', speciesGroupName: 'BOV', treatmentType: 'A', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_BOV_B', speciesGroupName: 'BOV', treatmentType: 'B', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_BOV_C', speciesGroupName: 'BOV', treatmentType: 'C', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_OVI_A', speciesGroupName: 'OVI', treatmentType: 'A', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_OVI_B', speciesGroupName: 'OVI', treatmentType: 'B', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_OVI_C', speciesGroupName: 'OVI', treatmentType: 'C', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_OVI_D', speciesGroupName: 'OVI', treatmentType: 'D', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_POR_A', speciesGroupName: 'POR', treatmentType: 'A', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_POR_B', speciesGroupName: 'POR', treatmentType: 'B', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_POR_C', speciesGroupName: 'POR', treatmentType: 'C', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_POR_D', speciesGroupName: 'POR', treatmentType: 'D', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_GBM_A', speciesGroupName: 'GBM', treatmentType: 'A', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_GBM_B', speciesGroupName: 'GBM', treatmentType: 'B', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_GBM_D', speciesGroupName: 'GBM', treatmentType: 'D', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_POU_A', speciesGroupName: 'POU', treatmentType: 'A', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_POU_B', speciesGroupName: 'POU', treatmentType: 'B', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_POU_D', speciesGroupName: 'POU', treatmentType: 'D', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_RAT_A', speciesGroupName: 'RAT', treatmentType: 'A', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_RAT_B', speciesGroupName: 'RAT', treatmentType: 'B', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_RAT_D', speciesGroupName: 'RAT', treatmentType: 'D', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_RUF_A', speciesGroupName: 'RUF', treatmentType: 'A', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_RUF_B', speciesGroupName: 'RUF', treatmentType: 'B', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_RUF_C', speciesGroupName: 'RUF', treatmentType: 'C', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_RUW_A', speciesGroupName: 'RUW', treatmentType: 'A', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_RUW_B', speciesGroupName: 'RUW', treatmentType: 'B', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_RUW_C', speciesGroupName: 'RUW', treatmentType: 'C', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_SUF_A', speciesGroupName: 'SUF', treatmentType: 'A', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_SUF_B', speciesGroupName: 'SUF', treatmentType: 'B', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_SUF_C', speciesGroupName: 'SUF', treatmentType: 'C', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_SUW_A', speciesGroupName: 'SUW', treatmentType: 'A', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_SUW_B', speciesGroupName: 'SUW', treatmentType: 'B', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_SUW_C', speciesGroupName: 'SUW', treatmentType: 'C', zonesArr: [] },
    { zoneGroupName: '8350_MEAT_PRODUCTS_SUW_D', speciesGroupName: 'SUW', treatmentType: 'D', zonesArr: [] },
];

export default ZoneService;