import * as Yup from 'yup';
import formModel from './NonGEFSFormModel';
import moment from 'moment-timezone';

const {
  formField: {
    formsComplete,
    dateInspection,
    traceability,
    numberProductsCases,
    hatcheries,
    hatcheryName,
    hatcheryPostcode,
    approvedExportCountries,
    approvedExportCountriesOfOrigin,
    isDateCollectionProductionMulti = false,
    dateCollectionProductionStart,
    dateCollectionProductionEnd,
    dateCollectionProductionSingle,
    productionDate,
    productionDateMultipleStart,
    productionDateMultipleEnd,
    productionDateSingle,
    batchNumber,
    decision,
    rejectReason,
    signedRequestUrl,
    documentId,
    containsDairy,
    isDateRequired
  }
} = formModel;


export default [
  //STAGE 1  
  Yup.object().shape({
    // [formsComplete.name]: Yup.bool().required(`${formsComplete.requiredErrorMsg}`)
    [formsComplete.name]: Yup.bool().oneOf([true], formsComplete.requiredErrorMsg)
  }),

  //STAGE 2  
  Yup.object().shape({
    [traceability.name]: Yup.string().required(`${traceability.requiredErrorMsg}`),
    //  [dateInspection.name]: Yup.date().max(today, 'Date of inspection cannot be in the future').required(`${dateInspection.requiredErrorMsg}`),
    [numberProductsCases.name]: Yup.number().required(`${numberProductsCases.requiredErrorMsg}`),
    // [hatcheries.name]: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       [hatcheryName.name]: Yup.string().required(hatcheryName.requiredErrorMsg),
    //       [hatcheryPostcode.name]: Yup.string()
    //         .required(hatcheryPostcode.requiredErrorMsg)
    //         .matches(
    //           /^(GIR ?0AA|((([A-Z]{1,2}[0-9]{1,2})|(([A-Z]{1,2}[A-HJ-Y][0-9]{1,2})|(([A-Z]{1,2}[0-9][A-Z])|([A-Z]{1,2}[A-HJ-Y][0-9]?[A-Z])))) ?[0-9][A-Z]{2}))$/,
    //           "Please enter a valid UK postcode"
    //         ),
    //     })
    //   )
    //   .nullable()
    //   .min(1, hatcheries.requiredErrorMsg),
    [approvedExportCountries.name]: Yup.number().required(`${approvedExportCountries.requiredErrorMsg}`),
    [approvedExportCountriesOfOrigin.name]: Yup.string().when([approvedExportCountries.name], {
      is: 2,
      then: Yup.string().required(approvedExportCountriesOfOrigin.requiredErrorMsg)
    }),
    [batchNumber.name]: Yup.string().required(batchNumber.requiredErrorMsg),

    [isDateCollectionProductionMulti.name]: Yup.bool().required(isDateCollectionProductionMulti.requiredErrorMsg),
    // Modify the validation schema for date fields to check isDateRequired
    [dateCollectionProductionSingle.name]: Yup.date()
      .when('isDateRequired', {
        is: true,
        then: Yup.date()
          .when('isDateCollectionProductionMulti', {
            is: false,
            then: Yup.date()
              .test(
                'single-date-validation',
                function (value) {
                  const valueDate = moment(value);
                  if (value && valueDate.isAfter(moment())) {
                    return this.createError({
                      message: `Date of production must not be later than today.`
                    });
                  }
                  return true;
                }
              )
              .required('Date of production is required.'),
          }),
      }),

    [dateCollectionProductionStart.name]: Yup.date()
      .when('isDateRequired', {
        is: true,
        then: Yup.date()
          .when('isDateCollectionProductionMulti', {
            is: true,
            then: Yup.date()
              .test(
                'start-date-validation',
                function (value) {
                  const { dateCollectionProductionEnd } = this.parent;
                  const endDate = dateCollectionProductionEnd
                    ? moment(dateCollectionProductionEnd, 'DD/MM/YYYY').startOf('day')
                    : null;
                  const valueDate = moment(value);

                  if (value && endDate && valueDate.isAfter(endDate)) {
                    return this.createError({
                      message: `Start date must not be later than the end date.`
                    });
                  }
                  if (value && valueDate.isAfter(moment())) {
                    return this.createError({
                      message: `Date of production must not be later than today.`
                    });
                  }
                  return true;
                }
              )
              .required('Start date of production is required.'),
          }),
      }),

    [dateCollectionProductionEnd.name]: Yup.date()
      .when('isDateRequired', {
        is: true,
        then: Yup.date()
          .when('isDateCollectionProductionMulti', {
            is: true,
            then: Yup.date()
              .test(
                'end-date-validation',
                function (value) {
                  const { dateCollectionProductionStart } = this.parent;
                  const startDate = dateCollectionProductionStart
                    ? moment(dateCollectionProductionStart, 'DD/MM/YYYY').startOf('day')
                    : null;
                  const valueDate = moment(value);

                  if (value && startDate && valueDate.isBefore(startDate)) {
                    return this.createError({
                      message: `End date must not be earlier than the start date.`
                    });
                  }
                  if (value && valueDate.isAfter(moment())) {
                    return this.createError({
                      message: `Date of production must not be later than today.`
                    });
                  }
                  return true;
                }
              )
              .required('End date of production is required.'),
          }),
      }),

  }),

  //STAGE 2  
  Yup.object().shape({
    [decision.name]: Yup.number().required(`${decision.requiredErrorMsg}`),
    [rejectReason.name]: Yup.string().when([decision.name], {
      is: 1,
      then: Yup.string().required(rejectReason.requiredErrorMsg),
    }),
    [signedRequestUrl.name]: Yup.string().when([decision.name], {
      is: 2,
      then: Yup.string().required(signedRequestUrl.requiredErrorMsg),
    }),
    [documentId.name]: Yup.string().when([decision.name], {
      is: 2,
      then: Yup.string().required(documentId.requiredErrorMsg),
    }),
  })
];
