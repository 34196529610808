import * as Casing8393Labels from "../Casing8393Labels"
import { findArrayElementByFieldNameAndValue, findArrayElementByFieldName, findArrayElementByValue } from "../../../Attestations/Common/AttestationFunctions";
import moment from "moment-timezone";
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
export const AttestationContent = (props) => {
    const { attestationData } = props;
    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);
    return (
        <>
            <h5>II. Health Attestation</h5>
            <>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned, declare that I am aware of the relevant requirements of Regulation (EC) No 999/2001 of the European Parliament and of the Council,
                    Regulation (EC) No 178/2002 of the European Parliament and of the Council, Regulation (EC) No 852/2004 of the European Parliament and of the Council
                    and Regulation (EC) No 853/2004 of the European Parliament and of the Council and hereby certify that the casings described in Part I were produced in
                    accordance with these requirements, in particular that:
                </p>
                <ol className="attestation-II-1">
                    <li>
                        they come from (an) establishment(s) applying general hygiene requirements and implementing a programme based on the hazard analysis and
                        critical control points (HACCP) principles in accordance with Article 5 of Regulation (EC) No 852/2004, regularly audited by the competent
                        authorities, and being listed as an EU approved establishment;
                    </li>
                    <li>
                        the animals from which the casings were derived have passed ante-mortem and post-mortem inspections;
                    </li>
                    <li>
                        the casings have been produced in accordance with Section XIII of Annex III to Regulation (EC) No 853/2004;
                    </li>
                    <li>
                        they have been marked with an identification mark in accordance with Section I of Annex II to Regulation (EC) No 853/2004;
                    </li>
                    <li>
                        the guarantees covering casings provided by the residue plans submitted in accordance with Article 29 of Council Directive 96/23/ECD, are fulfilled and the
                        casings are listed in Commission Decision 2011/163/EU for the country from which casings are exported;
                    </li>
                    {/* <li>
                        the means of transport and the loading conditions of casings of this consignment meet the hygiene requirements laid down in respect of export
                        to the European Union;
                    </li> */}
                    <li>N/A</li>

                    {attestationData.bseRiskCategoryNegligible || attestationData.bseRiskCategoryControlled || attestationData.bseRiskCategoryUndetermined ? (
                        <>
                            <li>If derived from bovine, ovine or caprine animals, with regard to bovine spongiform encephalopathy (BSE):
                                {attestationData.bseRiskCategoryNegligible && (
                                    <ul>
                                        <li>the country or region of origin is classified in accordance with Commission Decision 2007/453/EC as a country or region posing
                                            a negligible BSE risk, and

                                            <ul>
                                                {attestationData.bseRiskCategoryNegligibleStatements.map(statement =>
                                                    <li>
                                                        {findArrayElementByValue(Casing8393Labels.BseRiskCategoryNegligibleStatementsChoice, statement)}
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    </ul>
                                )}
                                {/* BSE Controlled */}
                                {attestationData.bseRiskCategoryControlled &&
                                    <ul>
                                        <li>the country or region of origin is classified in accordance with Decision 2007/453/EC as a country or region posing a controlled
                                            BSE risk, and (

                                            <ul>
                                                <li>
                                                    {findArrayElementByValue(
                                                        Casing8393Labels.BseRiskCategoryControlledStatementsChoice, 1)
                                                    }
                                                </li>
                                                <li>
                                                    {findArrayElementByValue(
                                                        Casing8393Labels.BseRiskCategoryControlledStatementsChoice, 2)
                                                    }
                                                </li>
                                                {attestationData.bseRiskCategoryControlledStatements > 0 &&
                                                    <li>
                                                        {findArrayElementByValue(
                                                            Casing8393Labels.BseRiskCategoryControlledStatementsChoice,
                                                            attestationData.bseRiskCategoryControlledStatements)
                                                        }


                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    </ul>
                                }
                                {/* BSE Undetermined */}
                                {attestationData.bseRiskCategoryUndetermined &&
                                    <ul>
                                        <li>the country or region of origin is classified in accordance with Decision 2007/453/EC as a country or region with an undetermined
                                            BSE risk, and
                                            <ul>
                                                <li>
                                                    {findArrayElementByValue(Casing8393Labels.BseRiskCategoryUndeterminedStatementsChoice, 1)}
                                                </li>
                                                <li>
                                                    {findArrayElementByValue(Casing8393Labels.BseRiskCategoryUndeterminedStatementsChoice, 2)}
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                }
                            </li>
                        </>
                    ) :
                        (
                            <li>N/A</li>
                        )}
                </ol>

                <h6>II.2 Animal health attestation</h6>
                <p>I, the undersigned, hereby certify, that the <strong>casings</strong> described in Section A.2:</p>
                <ol type="1" className="attestation-II-2">
                    <li>
                        <strong><u></u></strong>
                        have been processed in and dispatched from the <strong>zone(s)</strong> with code(s): <strong><u>{attestationData.animal_2_1_zone?.map(x => x.isoCode).join(", ")}</u></strong>, which at the date of issue of this support attestation,
                        is/are authorised for entry into the Union of casings of the species of animals from which the casings described in Part I have been
                        obtained and listed in Part 1 of Annex XVI to Commission Implementing Regulation (EU) 2021/404;
                    </li>
                    <li>

                        {attestationData.animal_2_2_statements == 1 &&
                            <li>
                                <ul>
                                    <li>
                                        have been processed from bladders and/or intestines obtained from&nbsp;
                                        {attestationData.casing_2_1_SpeciesType1 && findArrayElementByFieldName(Casing8393Labels.casingSpeciesChoices, 'casing_2_1_SpeciesType1') + ", "}
                                        {attestationData.casing_2_1_SpeciesType2 && findArrayElementByFieldName(Casing8393Labels.casingSpeciesChoices, 'casing_2_1_SpeciesType2') + ", "}
                                        {attestationData.casing_2_1_SpeciesType3 && findArrayElementByFieldName(Casing8393Labels.casingSpeciesChoices, 'casing_2_1_SpeciesType3') + ", "}
                                        {attestationData.casing_2_1_SpeciesType4 && findArrayElementByFieldName(Casing8393Labels.casingSpeciesChoices, 'casing_2_1_SpeciesType4') + " "}

                                        , and
                                    </li>
                                    <li>
                                        processed in and dispatched from the <strong>zone(s)</strong> with code(s): <strong><u>{attestationData.animal_2_2_zone?.map(x => x.isoCode).join(", ")}</u></strong>,
                                        which at the date of issuance of this animal health/official certificate, is/are authorised for entry into the Union of fresh meat of such species of animals and listed in Annex XIII,
                                        Part 1, to Implementing Regulation (EU) 2021/404, without any specific condition indicated in column 5 of the table in Part 1 of that Annex;
                                    </li>
                                </ul>

                            </li>
                        }
                        {attestationData.animal_2_2_statements == 2 &&

                            <li>
                                have been processed from bladders and/or intestines obtained from&nbsp;
                                {attestationData.casing_2_2_SpeciesType1 && findArrayElementByFieldName(Casing8393Labels.casingSpeciesChoices2, 'casing_2_2_SpeciesType1') + ", "}
                                {attestationData.casing_2_2_SpeciesType2 && findArrayElementByFieldName(Casing8393Labels.casingSpeciesChoices2, 'casing_2_2_SpeciesType2') + ", "}
                                {attestationData.casing_2_2_SpeciesType3 && findArrayElementByFieldName(Casing8393Labels.casingSpeciesChoices2, 'casing_2_2_SpeciesType3') + ", "}
                                {attestationData.casing_2_2_SpeciesType4 && findArrayElementByFieldName(Casing8393Labels.casingSpeciesChoices2, 'casing_2_2_SpeciesType4') + " "}

                                and during their processing have been:
                                <br></br>
                                {findArrayElementByFieldNameAndValue(Casing8393Labels.animal_2_2_A_statements, 'animal_2_2_A_statements', attestationData.animal_2_2_A_statements)}
                            </li>
                        }
                        {attestationData.animal_2_2_statements == 3 &&
                            <li>
                                have been processed from bladders and/or intestines obtained from animals <strong>other than</strong> bovine, ovine, caprine and/or porcine animals and during their processing have been:
                                <br></br>
                                {findArrayElementByFieldNameAndValue(Casing8393Labels.animal_2_2_B_statements, 'animal_2_2_B_statements', attestationData.animal_2_2_B_statements)}
                            </li>
                        }

                    </li>


                    <li>
                        during processing and until packaging have been handled in a way to prevent cross contamination that could introduce animal health risk.
                    </li>
                </ol>
                {/* <PrettyPrintJson data={attestationData} >  </PrettyPrintJson> */}
                {/* <PrettyPrintJson data={compositeInfo} >  </PrettyPrintJson>
            <PrettyPrintJson data={speciesList} >  </PrettyPrintJson>  */}
            </>
        </>
    )
}

