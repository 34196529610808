import *  as baseService  from "./BaseService";

const getById = (id) => {
    return baseService.getApiData("/consignment/getById?id=" + id);
};

const create = (data) => {
    return baseService.postApiData("/consignment/create", data);
};

const getAllPaged = (params) => {
    return baseService.getApiDataWithParams("/consignment/GetAllPaged", params);
};

const update = (id, data) => {
    return baseService.putApiData("/consignment/update?id=" + id, data);
};
const complete = (id) => {
    return baseService.putApiData("/consignment/complete?id=" + id);
};
const ConsignmentService = {
    create,
    getAllPaged,
    getById,
    update,
    complete
};

export default ConsignmentService;