import {clearFieldValue, setFieldValues} from './FieldFunctions'
import {clearLineValue } from './LineStampFunctions'
import {clearTextStampValue } from './TextStampFunctions'
import { getIn } from 'formik';

//Update config data with form field data and logic
export const updateData = (values, data, certificateGroups, languageCode) => {
    data.config = null;

    // Utility function to extract values
    const extractValues = (fieldName, valuePrefixes, values) => {
        return valuePrefixes.reduce((acc, prefix) => {
            acc[prefix] = getIn(values, `${fieldName}${prefix}`) ?? null;
            return acc;
        }, {});
    };

    // Update fields
    data.fields.forEach((x, i) => setFieldValues(x, i, values, data));

    // Function to update stamps
    const updateStamps = (stamps, valuesKey, languageCode) => stamps.flatMap(stamp => {
        const fieldName = stamp.fieldName?.replaceAll(" ", "");
        const found = getIn(values[valuesKey], fieldName);
        if (found === null || found === undefined) return [];
        
        const { Top: top, Width: width, Height: height, Left: left, MarginTop: marginTop } = extractValues(fieldName, ["Top", "Width", "Height", "Left", "MarginTop"], values[valuesKey]);

        const updatedStamp = { ...stamp, value: found, defaultValue: found, top, width, height, left, marginTop };
        const additionalStamps = (stamp.additionalPositions ?? []).filter(x=> x.languageCode == "EN" ||  x.languageCode == languageCode).map(element => ({
            ...updatedStamp,
            ...element,
            fieldName: `${stamp.fieldName} ${element.languageCode}`,
            linkedFieldName: stamp.fieldName,
            additionalPositions: []
        }));

        return [updatedStamp, ...additionalStamps];
    });

    // Update textStamps and lines
    data.textStamps = updateStamps(data.textStamps, 'textStamps', ['Top', 'Width', 'Height', 'Left', 'MarginTop'], languageCode);
    data.lines = updateStamps(data.lines, 'lineStamps', ['Top', 'Width', 'Height', 'Left', 'MarginTop', 'DisableChildGroupsIfFalse'], languageCode);

    data.certificateGroups = certificateGroups;
    return data;
};

//Export data on current form as a config file in JSON
export const exportData = (values, data, certificateGroups, languageCode) => {
    var res = updateData(values, data, certificateGroups, languageCode);
    var json = clearDataValues(res);
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(json)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = data.number + ".json";

    link.click();
};

//Empty line data
 export const clearDataValues = (data) => {
    data.fields.forEach((x,i)=>{
        clearFieldValue(x, i, data);
    });
    data.textStamps.forEach((x,i, data)=>{
        clearTextStampValue(x, i, data);
    });
    data.lines.forEach((x,i, data)=>{
        clearLineValue(x, i, data);
    });
    return data;
}
