//Roles - all roles available for user
export const roles = {
  retailer: "retailer",
  manufacturer: "manufacturer",
  manufactureradmin: "manufactureradmin",
  admin: "admin",
  certifyingofficer: "certifyingofficer",
  vet: "vet",
  platformcertifier: "platformcertifier"
};

//Scopes - permission levels available in each section
export const scopes = {
  admin: {
    create: "admin.create",
    read: "admin.read",
  },
  certificates: {
    create: "certificates.create",
    read: "certificates.read",
    certify: "certificates.update",
  },
  consignments: {
    create: "consignments.create",
    read: "consignments.read",
    update: "consignments.update",
    certify: "consignments.certify",
  },
  products: {
    create: "products.create",
    read: "products.read",
    update: "products.update",
    createbulk: "products.createbulk",
  },
  productdetail: {
    create: "productdetail.create",
    read: "productdetail.read",
    update: "productdetail.update",
  },
  productnongefs: {
    create: "productnongefs.create",
    update: "productnongefs.update",
  },
  supportingevidence: {
    read: "supportingevidence.read",
    create: "supportingevidence.create",
  },
  platforms: {
    create: "platforms.create",
    read: "platforms.read",
    update: "platforms.update",
  },
  manufacturers: {
    create: "manufacturers.create",
    read: "manufacturers.read",
    update: "manufacturers.update",
  },
  sites: {
    create: "sites.create",
    read: "sites.read",
    update: "sites.update",
  },
  vets: {
    read: "vets.read",
  },
  invite: {
    retailers: "invite.retailers",
    manufacturers: "invite.manufacturers",
    manufactureradmins: "invite.manufactureradmins",
    vets: "invite.vets",
    admin: "invite.admin",
    certifyingofficer: "invite.certifyingofficer",
    platformcertifiers: "invite.platformcertifiers",
  },
  attestationrequests: {
    read: "attestationrequests.read",
    create: "attestationrequests.create",
  },
  attestationresponses: {
    read: "attestationresponses.read",
    create: "attestationresponses.create",
  },
  storagerequests: {
    read: "storagerequests.read",
    create: "storagerequests.create",
  },
  storageresponses: {
    read: "storageresponses.read",
    create: "storageresponses.create",
  },
  platformcertifiers: {
    read: "platformcertifiers.read",
  },
  storagedeclarations: {
    read: "storagedeclarations.read",
  },
  dailyattestations: {
    read: "dailyattestations.read",
    create: "dailyattestations.create",
  },
  reports: {
    dashboard: "reports.dashboard",
    retailergefs: "reports.retailergefs",
    retailernongefs: "reports.retailernongefs"
  },
  noticeboard: {
    read: "noticeboard.read",
    create: "noticeboard.create"
  },
  establishments: {
    read: "establishments.read"
  },
};

//Permissions for each role / section
export const permissions = {

  [roles.admin]: [
    scopes.admin.read, scopes.admin.create,
    scopes.products.read, scopes.products.create, scopes.products.update, scopes.products.createbulk, 
    scopes.productdetail.read, scopes.productdetail.create, scopes.productdetail.update, 
    scopes.supportingevidence.read, scopes.supportingevidence.create,
    scopes.sites.read, scopes.sites.create, scopes.sites.update, 
    scopes.manufacturers.read, scopes.manufacturers.create, scopes.manufacturers.update, 
    scopes.platforms.read, scopes.platforms.create, scopes.platforms.update, 
    scopes.vets.read, 
    scopes.invite.retailers, scopes.invite.manufacturers, scopes.invite.manufactureradmins, scopes.invite.vets, scopes.invite.certifyingofficer, scopes.invite.platformcertifiers,
    scopes.attestationrequests.create,
    scopes.certificates.create, scopes.certificates.read,  scopes.certificates.update, 
    scopes.certificates.create, scopes.certificates.read,  scopes.certificates.update, 
    scopes.consignments.create, scopes.consignments.read,  scopes.consignments.update, 
    scopes.dailyattestations.read, scopes.dailyattestations.create,
    scopes.storagedeclarations.read,
    scopes.storagerequests.read, scopes.storagerequests.create,
    scopes.platformcertifiers.read,
    scopes.reports.dashboard, scopes.reports.retailergefs,scopes.reports.retailernongefs,
    scopes.noticeboard.read, scopes.noticeboard.create,
    scopes.establishments.read
  ],

  [roles.retailer]: [
    scopes.products.read, scopes.products.create, scopes.products.update, scopes.products.createbulk, 
    scopes.productdetail.read, 
    scopes.sites.read, scopes.sites.create, scopes.sites.update, 
    scopes.manufacturers.read, scopes.manufacturers.create, scopes.manufacturers.update, 
    scopes.platforms.read, scopes.platforms.create, scopes.platforms.update, 
    scopes.vets.read, 
    scopes.invite.retailers, scopes.invite.manufacturers, scopes.invite.platformcertifiers,
    scopes.attestationresponses.read, 
    scopes.attestationrequests.read, 
    scopes.dailyattestations.read, 
    scopes.storagedeclarations.read,
    scopes.storagerequests.read,
    scopes.platformcertifiers.read,
    scopes.reports.dashboard, scopes.reports.retailergefs,scopes.reports.retailernongefs
  ],

  [roles.manufacturer]: [
    scopes.products.read,
    scopes.productdetail.read, scopes.productdetail.create, scopes.productdetail.update, 
    scopes.supportingevidence.read, scopes.supportingevidence.create,
    scopes.sites.read, scopes.sites.create, scopes.sites.update, 
    scopes.invite.manufacturers, scopes.invite.manufactureradmins, scopes.invite.vets,
    scopes.attestationrequests.create,
    scopes.dailyattestations.read, scopes.dailyattestations.create, 
    scopes.storagedeclarations.read,
    scopes.storagerequests.read, scopes.storagerequests.create
  ],
  [roles.manufactureradmin]: [
    scopes.products.read,
    scopes.productdetail.read, scopes.productdetail.create, scopes.productdetail.update, 
    scopes.supportingevidence.read, scopes.supportingevidence.create,
    scopes.sites.read, scopes.sites.create, scopes.sites.update, 
    scopes.invite.vets,
    scopes.dailyattestations.read, scopes.dailyattestations.create,
    scopes.storagedeclarations.read,
    scopes.storagerequests.read, scopes.storagerequests.create
  ],

  [roles.certifyingofficer]: [
    scopes.products.read, 
    scopes.productdetail.read, 
    scopes.supportingevidence.read, scopes.supportingevidence.create,
    scopes.certificates.create, scopes.certificates.read,  scopes.certificates.update, 
    scopes.consignments.create, scopes.consignments.read,  scopes.consignments.update, 
    scopes.attestationresponses.read, scopes.attestationresponses.create, 
    scopes.dailyattestations.read,
    scopes.platformcertifiers.read,
    scopes.storagedeclarations.read,
    scopes.storagerequests.read,
    scopes.storageresponses.read,
    scopes.establishments.read
  ],
  
  [roles.vet]: [
    scopes.productdetail.read, 
    scopes.productnongefs.update, scopes.productnongefs.create, 
    scopes.attestationrequests.read,
    scopes.attestationresponses.read, scopes.attestationresponses.create
  ],
  [roles.platformcertifier]: [
    scopes.storagerequests.read,
    scopes.storageresponses.read, scopes.storageresponses.create
  ],
};