import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import FormController from "../common/FormController"
import ProductImageService from "../../services/ProductImageService";
import { Button, Card, Col, Row, Modal } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus, faFileImage} from '@fortawesome/free-solid-svg-icons';
import moment from "moment"
import {ProductImageCarouselWidget, ProductImageViewWidget} from "../../../components/Widgets";
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes} from '../../../auth/PermissionsMap';

const ProductImages = (props) => {

    const handleCloseModalAdd = () => setShowModalAdd(false);
    const handleCloseModalView = () => setShowModalView(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalView, setShowModalView] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [productImages, setProductImages] = useState([]);
    const [productId] = useState(props.productId);

    const initialValues = {
        productId: productId,
        filesToUpload: []
    }

    function onSubmit(values, { setSubmitting, resetForm } ) {
        resetForm({})
        setTimeout(() => {
            const formData = new FormData();
            for (let value in values) {
              formData.append(value, values[value]);
            }
             for (let i = 0; i <= values.filesToUpload.length; i++) {
               formData.append(`files`, values.filesToUpload[i]);
             }
             ProductImageService.create(formData)
                .then(response => {
                    console.log(response.data);
                    setUploadComplete(true);
                    retrieveSupportingEvidence();
                    handleCloseModalAdd();
                })
                .catch(e => {
                    console.log(e);
                });
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    }
    
    function retrieveSupportingEvidence() {
        ProductImageService.getAll(productId)
        .then(response => {
            console.log(response.data);
            setProductImages(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    };

    useEffect(() => {
        retrieveSupportingEvidence();
    },[]);

    let filesImages = {icon: faFileImage, colour: 'text-purple', extensions:["jpg" , "jpg", "png" , "bmp" , "tiff"]};
    
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
    }
    
    function formattedDate(date){
        var newDate = moment(date).format("Do MMM YYYY");
        if (newDate === "Invalid date") {
            return "";
        }
      return newDate;
    }
      
    function  getLinkText(file, fileExt){
        var fileName = file.filename.substring(37);
        return (
        <div className='text'>
            <a href={file.fileUrl} >
            <FontAwesomeIcon icon={fileExt.icon} className={`mx-auto ${fileExt.colour} `} />
            <span className="d-block fs-7">{fileName}</span>
            <small><i>{formatBytes(file.size, null)}. Added {formattedDate(file.CreatedDate)} by {file.userFullName}</i></small>
            </a>
        </div>
        );
    }

    const files = productImages.map(file => (
        <li key={file.path} className="dropzone-upload" >
        {
            <>{getLinkText(file, filesImages)}</>
        }
      </li>
    ));
    return (
        <Card border="light" className="h-100 bg-white shadow-sm">
            <Card.Header className="align-items-center">
                <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.productdetail.create]}>
                    <>
                        <Button onClick={() => setShowModalAdd(true)}  variant="light" size="sm" className="text-dark me-2 float-end text-end">
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                                <span>Add</span>
                        </Button>
                        {productImages.length>=1&&(
                            <Button onClick={() => setShowModalView(true)}  variant="light" size="sm" className="text-dark me-2 float-end text-end">
                                <FontAwesomeIcon icon={faPlus} className="me-2" />
                                    <span>Edit</span>
                            </Button>
                        )}
                    </>
                </PermissionsCheck>
                <h4 className="text-title" id="tabelLabel" >Images</h4>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={12} className="">
                        {/* //Modal - Upload */}
                        <Modal size="md" as={Modal.Dialog} centered show={showModalAdd} onHide={handleCloseModalAdd}>
                            <Modal.Header>
                                <Modal.Title className="h6">Upload product images</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleCloseModalAdd} />
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                >
                                    {(props, isSubmitting) => (
                                        <Form>
                                            <Row className="mt-0">
                                                <Col md={12}>
                                                    <FormController
                                                        control="multipleFileUpload"
                                                        allowedFileTypes = "image/*"
                                                        label="Select images to upload"
                                                        name="filesToUpload"
                                                        className="mb-3"
                                                        imageUploader
                                                        multiple={true}
                                                    />
                                                                    
                                                    <div className="form-group mt-3">
                                                        <Button className="btn-block" type="submit" disabled={isSubmitting || props.values.filesToUpload.length<=0 }>{isSubmitting ? "Please wait..." : "Upload"}</Button>
                                                    </div>
                                                </Col>
                                            </Row>   
                                        </Form>
                                        )}
                                    </Formik >
                            </Modal.Body>
                        </Modal>
                        
                        {/* //Modal - View */}
                        <Modal size="md" as={Modal.Dialog} centered show={showModalView} onHide={handleCloseModalView}>
                            <Modal.Header>
                                <Modal.Title className="h6">All images</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleCloseModalView} />
                            </Modal.Header>
                            <Modal.Body>
                                {/* //Image list */}
                                {files.length>0?(
                                    <ProductImageViewWidget
                                            title="Select product photo(s)"
                                            productImages={productImages}
                                    />
                                )
                                :
                                (
                                    <div className="text-center pt-4">
                                        <h5 className="text-gray-600">No product images<br/> have been uploaded yet</h5>
                                    </div>
                                )}
                            </Modal.Body>
                        </Modal>

                        {/* //Image list */}
                        {files.length>0?(
                            <ProductImageCarouselWidget
                                title="Select product photo(s)"
                                productImages={productImages}
                            />
                        )
                        :
                        (
                            <div className="text-center pt-4"><h5 className="text-gray-600">
                                No product images<br/> have been uploaded yet</h5>
                            </div>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ProductImages;