import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Dropdown } from '@themesberg/react-bootstrap';
import React, { useState } from 'react';

export default (props) => {
  const {numberSelected, selectedFilters, handleChange, clearParams} = props;
  
  const handleRemoveItem = (item) => {
    const updatedItems = [...selectedFilters];
    const index = updatedItems.findIndex((selectedItem) => selectedItem.value === item.value);
 
    if (index === -1) {
      updatedItems.push(item);
    } else {
      updatedItems.splice(index, 1);
    }
    // Call the callback function to update the selectedFilters in the parent component
    handleChange(updatedItems);
  };

  const FilterSelectionButton = React.forwardRef(({ children, style, onClick }, ref) => (
    <Button 
      variant="light" 
      size="sm" 
      className={`mt-1 py-2 ps-3 fw-normal rounded-pill bg-lightblue border-0`} 
      ref={ref} 
      onClick={(e) => { e.preventDefault(); onClick(e); }}
    >
      {children}
    </Button>
  ));

  const FilterSelectionsMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');
      return (
        <div ref={ref} style={{ ...style, maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }} className={className} aria-labelledby={labeledBy}>
          <ul className="list-unstyled  my-0"> 
            {React.Children.toArray(children)
              .filter((child, index) => 
                !value 
                || selectedFilters[index]?.label
                      .toLowerCase().includes(value)
              )
            } {/* Use data[index].label */}
          </ul>
        </div>
    );
  });

  ///////////////////////////////////////////////////////////////////////////////////
  // Filter selectons button
  ///////////////////////////////////////////////////////////////////////////////////

  return (<>
  
    {

    // No filter selections 
    selectedFilters.length <= 0 ? 
        <Button variant="light" disabled size="sm" className={'py-2 ps-3 pe-3 fw-normal rounded-pill bg-light'}>
        No filters applied
      </Button>

    :

      // 1 filter selection
      selectedFilters?.length == 1 ?
      <Badge size={"sm"} className={`text-dark p-2 px-3 ms-2 me-0 rounded-pill ${selectedFilters.length >= 1 ? 'bg-lightblue fw-bold' : 'bg-light fw-normal'}`} pill>
        {selectedFilters?.filter(x=>x.value !=="" && x.group !=="")[0].title}
        :&nbsp;
        {selectedFilters?.filter(x=>x.value !=="" && x.group !=="")[0].label}
        <Button className="p-0 text-dark bg-transparent" onClick={()=> clearParams()}>
          <FontAwesomeIcon icon={faTimes} className="ms-3" />
        </Button>
      </Badge>

      :

      // 2+ filter selections
      <Dropdown className={`d-inline mx-2`} autoClose="outside">
          <Dropdown.Toggle as={FilterSelectionButton} id="dropdown-autoclose-outside font-size-xs">
            {numberSelected} filters applied
            <span className="small"><FontAwesomeIcon icon={faChevronDown} className="ms-3" /></span>
          </Dropdown.Toggle>
          <Dropdown.Menu as={FilterSelectionsMenu}>
            {selectedFilters?.filter(x=>x.value !=="" && x.group !=="").map((item, i) => (
              <Dropdown.Item  
                className='py-1' 
                key={i} 
                eventKey={i} 
              >
                <Button variant="light" size="sm" className={'btn-filter-item py-2 ps-3 pe-3 fw-normal rounded-pill bg-lightblue pe-5'}>
                  <span className="small">
                    {item.title}: {item.label} </span>
                  <FontAwesomeIcon 
                    icon={faTimes} 
                    onClick={()=> handleRemoveItem(item)} 
                    className="ms-3 btn-filter-item-remove " 
                  />
                </Button>
              </Dropdown.Item>
            ))}
            <hr className='m-1' />
            <Dropdown.Item  
              className='py-1 filter-item ' 
              key={999} 
              eventKey={999} >
                <span className="small" onClick={()=> clearParams()}>Clear all </span>
              </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
    }
  </>
  );
};
