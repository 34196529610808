import React , {useState} from 'react';
import SelectionRowContent from "./SelectionRowContent"
import { Badge, Card} from '@themesberg/react-bootstrap';
const SelectionRowCombined = (props) => {
    const {
        isGEFS,
        isStorageDeclaration,
        isCompound,
        compound,
        combinedSelection,
        commodityCode,
        numberOfPackages,
        dateCollectionProduction,
        isCombinedMaster,
        isCombinedSecondary,
        certificateNumbers,
        uniqueCode,
        productName,
        batchNumbers,
    } = props;
    
    return(
        <>
            <tr className="">
                <td>
                    <SelectionRowContent 
                        isCombined={true}
                        isGEFS={isGEFS}
                        isStorageDeclaration={isStorageDeclaration}
                        isCompound={isCompound}
                        compound={compound}
                        commodityCode={commodityCode}
                        certificateNumbers={certificateNumbers}
                        numberOfPackages={numberOfPackages}
                        productName={productName}
                        uniqueCode={uniqueCode}
                        trackingRef={uniqueCode}
                        batchNumbers={batchNumbers} 
                        dateCollectionProduction={dateCollectionProduction}
                        isCombinedMaster={isCombinedMaster}
                        isCombinedSecondary={isCombinedSecondary}
                    />
                </td>
            </tr>
        </>
    )
};

export default SelectionRowCombined