import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Formik, Form, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import ProductDogChewsService from "../../../services/ProductDogChewsService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import * as DogChews8325Labels from "./DogChews8325Labels"
import FormSubmission from "../../common/FormSubmission";
import { Button, Card, Alert, Col, Row } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import ActivityService from '../../../services/ActivityService';


const DogChews8325AddPage = (props) => {

    const { id, attestationId } = useParams();
    const [isSubmitting,  setSubmitting] = useState(false);
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [specieslist, SetSpecies] = useState([]);
    const [formVersion] = useState(GetCertificateFormVersion(8325));

    let title = (editing == true ? "Edit " : "New") + " Dog Chews (#8325) Information"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Dog Chews 8325', link: Routes.DogChews8325AddPage.path },
    };
    const [_1Aves, set_1Aves] = useState(false);
    const [_2Ruminantia, set_2Ruminantia] = useState(false);
    const [_3Suidae, set_3Suidae] = useState(false);
    const [_4MammaliaOtherThanRuminantiaorSuidae, set_4MammaliaOtherThanRuminantiaorSuidae] = useState(false);
    const [_5Pesca, set_5Pesca] = useState(false);
    const [_6Mollusca, set_6Mollusca] = useState(false);
    const [_7Crustacea, set_7Crustacea] = useState(false);
    const [_8InvertebratesOtherThanMolluscaandCrustacea, set_8InvertebratesOtherThanMolluscaandCrustacea] = useState(false);
    const [apiValues, SetApiValues] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const initialValues = {
        productId: props.match.params.id,
        commoditiesCertifiedFor:apiValues?.commoditiesCertifiedFor ?? '',
        manufacturingPlant: apiValues?.manufacturingPlant ?? '',
        health_1_1: apiValues?.health_1_1 ?? false,
        health_1_2: apiValues?.health_1_2 ?? false,
        health_1_2_statements: apiValues?.health_1_2_statements ?? [],
        health_1_2_A: apiValues?.health_1_2_A ?? false,
        health_1_2_B: apiValues?.health_1_2_B ?? false,
        health_1_2_C: apiValues?.health_1_2_C ?? false,
        health_1_2_D: apiValues?.health_1_2_D ?? false,
        health_1_2_E: apiValues?.health_1_2_E ?? false,
        health_1_3: apiValues?.health_1_3 ?? false,
        health_1_4: apiValues?.health_1_4 ?? false,
        health_1_5: apiValues?.health_1_5 ?? false,
        health_1_6: apiValues?.health_1_6 ?? false,
        health_1_7: apiValues?.health_1_7 ?? false,
        health_2_1: apiValues?.health_2_1 ?? false,
        health_2_2: apiValues?.health_2_2 ?? false,
        health_6_1_statements: apiValues?.health_6_1_statements ?? [],
        health_6_2_statements: apiValues?.health_6_2_statements ?? [],
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
    }
    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";
    const commoditiesCertifiedFor = [
        { value: "", key: "" },
        { value: "Petfood", key: "Petfood" },
        { value: "Technical use", key: "Technical use" },
    ]

    //Validation
    const validationSchema = Yup.object().shape({
        manufacturingPlant: Yup.string().trim()
            .required(validationMsgRequired),
            commoditiesCertifiedFor: Yup.string().required(validationMsgRequired),
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        health_1_1: Yup.bool()
            .test('health_1_1', validationMsgAtLeastOne, function (x) {
                return (((_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) && (!this.parent.health_1_1 && !this.parent.health_1_2 && !this.parent.health_1_3 && !this.parent.health_1_4 && !this.parent.health_1_5 && !this.parent.health_1_6 && !this.parent.health_1_7)))
                    ? false : true;
            }),
        health_1_2: Yup.bool()
            .test('health_1_2', validationMsgAtLeastOne, function (x) {
                return ((_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) && (!this.parent.health_1_1 && !this.parent.health_1_2 && !this.parent.health_1_3 && !this.parent.health_1_4 && !this.parent.health_1_5 && !this.parent.health_1_6 && !this.parent.health_1_7))
                    ? false : true;
            }),
        health_1_2_A: Yup.bool()
            .test('health_1_2_A', validationMsgAtLeastOne, function (x) {
                return ((this.parent.health_1_2 && (!this.parent.health_1_2_A && !this.parent.health_1_2_B && !this.parent.health_1_2_C && !this.parent.health_1_2_D && !this.parent.health_1_2_E)))
                    ? false : true;
            }),
        health_1_2_B: Yup.bool()
            .test('health_1_2_B', validationMsgAtLeastOne, function (x) {
                return ((this.parent.health_1_2 && (!this.parent.health_1_2_A && !this.parent.health_1_2_B && !this.parent.health_1_2_C && !this.parent.health_1_2_D && !this.parent.health_1_2_E)))
                    ? false : true;
            }),
        health_1_2_C: Yup.bool()
            .test('health_1_2_C', validationMsgAtLeastOne, function (x) {
                return ((this.parent.health_1_2 && (!this.parent.health_1_2_A && !this.parent.health_1_2_B && !this.parent.health_1_2_C && !this.parent.health_1_2_D && !this.parent.health_1_2_E)))
                    ? false : true;
            }),
        health_1_2_D: Yup.bool()
            .test('health_1_2_D', validationMsgAtLeastOne, function (x) {
                return ((this.parent.health_1_2 && (!this.parent.health_1_2_A && !this.parent.health_1_2_B && !this.parent.health_1_2_C && !this.parent.health_1_2_D && !this.parent.health_1_2_E)))
                    ? false : true;
            }),
        health_1_2_E: Yup.bool()
            .test('health_1_2_E', validationMsgAtLeastOne, function (x) {
                return ((this.parent.health_1_2 && (!this.parent.health_1_2_A && !this.parent.health_1_2_B && !this.parent.health_1_2_C && !this.parent.health_1_2_D && !this.parent.health_1_2_E)))
                    ? false : true;
            }),
        health_1_3: Yup.bool()
            .test('health_1_3', validationMsgAtLeastOne, function (x) {
                return ((_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) && (!this.parent.health_1_1 && !this.parent.health_1_2 && !this.parent.health_1_3 && !this.parent.health_1_4 && !this.parent.health_1_5 && !this.parent.health_1_6 && !this.parent.health_1_7))
                    ? false : true;
            }),
        health_1_4: Yup.bool()
            .test('health_1_4', validationMsgAtLeastOne, function (x) {
                return ((_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) && (!this.parent.health_1_1 && !this.parent.health_1_2 && !this.parent.health_1_3 && !this.parent.health_1_4 && !this.parent.health_1_5 && !this.parent.health_1_6 && !this.parent.health_1_7)) ? false : true;
            }),
        health_1_5: Yup.bool()
            .test('health_1_5', validationMsgAtLeastOne, function (x) {
                return ((_8InvertebratesOtherThanMolluscaandCrustacea || _6Mollusca || _5Pesca) && (!this.parent.health_1_1 && !this.parent.health_1_2 && !this.parent.health_1_3 && !this.parent.health_1_4 && !this.parent.health_1_5 && !this.parent.health_1_6 && !this.parent.health_1_7)) ? false : true;
            }),
        health_1_6: Yup.bool()
            .test('health_1_6', validationMsgAtLeastOne, function (x) {
                return ((_8InvertebratesOtherThanMolluscaandCrustacea || _6Mollusca || _5Pesca) && (!this.parent.health_1_1 && !this.parent.health_1_2 && !this.parent.health_1_3 && !this.parent.health_1_4 && !this.parent.health_1_5 && !this.parent.health_1_6 && !this.parent.health_1_7)) ? false : true;
            }),
        health_1_7: Yup.bool()
            .test('health_1_7', validationMsgAtLeastOne, function (x) {
                return ((_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae || _5Pesca || _6Mollusca || _7Crustacea || _8InvertebratesOtherThanMolluscaandCrustacea) && (!this.parent.health_1_1 && !this.parent.health_1_2 && !this.parent.health_1_3 && !this.parent.health_1_4 && !this.parent.health_1_5 && !this.parent.health_1_6 && !this.parent.health_1_7)) ? false : true;
            }),
        health_2_1: Yup.bool()
            .test('health_2_1', validationMsgRequired, function (x) {
                return (((_2Ruminantia || _3Suidae || _5Pesca) && (!this.parent.health_2_1 && !this.parent.health_2_2)))
                    ? false : true;
            }),
        health_2_2: Yup.bool()
            .test('health_2_2', validationMsgRequired, function (x) {
                return (((_1Aves || _4MammaliaOtherThanRuminantiaorSuidae || _6Mollusca || _7Crustacea || _8InvertebratesOtherThanMolluscaandCrustacea) && (!this.parent.health_2_1 && !this.parent.health_2_2)))
                    ? false : true;
            }),
        health_6_1_statements: Yup.array()
            .test('health_6_1_statements', validationMsgAtLeastOne, function (x) {
                return (_2Ruminantia && x.length === 0)
                    ? false : true;
            }),
        health_6_2_statements: Yup.array()
            .test('health_6_2_statements', validationMsgAtLeastOne, function (x) {
                return (!this.parent.health_6_1_statements.length === 0 && _2Ruminantia && x.length === 0)
                    ? false : true;
            }),
    });


    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;
            console.log(submitData);

            if (apiValues.length === 0) {
                ProductDogChewsService.create(submitData)
                    .then(async response => {
                        await ActivityService.logCertForm(
                            'Dog chews 8325',
                            8325,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            else {
                ProductDogChewsService.update(productId, values)
                    .then(async response => {
                        await ActivityService.logCertForm(
                            'Dog chews 8325',
                            8325,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            setFormSubmitted(true);
        }, 1000);
    }

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);

    const handleSpeciesChange = (name, value, props) => {
        var values = props.values;
        console.log(props.values);
        if (values = !undefined) {
            const unique = [...new Set(value.map(item => item.speciesName))]; // [ 'A', 'B']

            if (unique.includes("Aves")) {
                set_1Aves(true);
            }
            else {
                set_1Aves(false);
            }

            if (unique.includes("Ruminantia")) {
                set_2Ruminantia(true);
            }
            else {
                set_2Ruminantia(false);

            }
            if (unique.includes("Suidae")) {
                set_3Suidae(true);
            }
            else {
                set_3Suidae(false);
            }
            if (unique.includes("Mammalia Other Than Ruminantia or Suidae")) {
                set_4MammaliaOtherThanRuminantiaorSuidae(true);
            }
            else {
                set_4MammaliaOtherThanRuminantiaorSuidae(false);
            }
            if (unique.includes("Pesca")) {
                set_5Pesca(true);
            }
            else {
                set_5Pesca(false);
            }
            if (unique.includes("Mollusca")) {
                set_6Mollusca(true);
            }
            else {
                set_6Mollusca(false);
            }
            if (unique.includes("Crustacea")) {
                set_7Crustacea(true);
            }
            else {
                set_7Crustacea(false);
            }
            if (unique.includes("Invertebrates Other Than Mollusca")) {
                set_8InvertebratesOtherThanMolluscaandCrustacea(true);
            }
            else {
                set_8InvertebratesOtherThanMolluscaandCrustacea(false);
            }
        }

    }
    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    const ChangeFormValues = () => {
        const { values } = useFormikContext();
        useEffect(() => {

            if (ready) {
                handleSpeciesChange("", values.productScientificSpecies, props)
                if (values.health_1_2 == false) {
                    values.health_1_2_A = false;
                    values.health_1_2_B = false;
                    values.health_1_2_C = false;
                    values.health_1_2_D = false;
                    values.health_1_2_E = false;
                }
                if (!values.health_6_1_statements.includes("2")) {
                    values.health_6_2_statements = [];
                }

                if ((_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) == false) {
                    values.health_1_1 = false;
                    values.health_1_2 = false;
                    values.health_1_2_A = false;
                    values.health_1_3 = false;
                    values.health_1_4 = false;
                }
                if ((_2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) == false) {
                    values.health_1_2_C = false;
                }

                if (_1Aves == false) {
                    values.health_1_2_B = false;
                    values.health_1_2_E = false;
                }
                if (_3Suidae == false) {
                    values.health_1_2_D = false;
                }



                if ((_5Pesca || _6Mollusca || _8InvertebratesOtherThanMolluscaandCrustacea) == false) {
                    values.health_1_5 = false;
                    values.health_1_6 = false;
                }

                if ((_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae ||
                    _5Pesca || _6Mollusca || _7Crustacea || _8InvertebratesOtherThanMolluscaandCrustacea) == false) {
                    values.health_1_7 = false;
                }

                if ((_2Ruminantia || _3Suidae || _5Pesca) == false) {
                    values.health_2_1 = false;
                }
                if ((_1Aves || _4MammaliaOtherThanRuminantiaorSuidae || _6Mollusca || _7Crustacea || _8InvertebratesOtherThanMolluscaandCrustacea) == false) {
                    values.health_2_2 = false;
                }
                if (_2Ruminantia == false) {
                    values.health_6_1_statements = [];
                    values.health_6_2_statements = [];
                }
            }

        }, [values]
        );
        return null;
    };

    useEffect(() => {
        ProductDogChewsService.getByProductId(productId)
            .then(response => {
                if (response.data == "") {
                    return;
                }
                console.log(response.data);
                SetApiValues(response.data);
                setEditing(true);
                setReady(true);
                console.log("init values:" + apiValues);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductService.getById(productId)
            .then(response => {
                SetProduct(response.data.data);
                setIsGEFS(response.data.data.isGEFS);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductScientificSpeciesDataService
            .getOnlyDogChews()
            .then((_speciesResponse) => {
                SetSpecies(_speciesResponse.data);
            })
            .finally(() => {
            });
    }, []);

    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <SubmissionPage
                    productId={productId}
                    attestationId={attestationId}
                    isGEFS={isGEFS}
                    errors={errors}
                    title={(editing ? "Edited" : "Completed") + " Dog Chews 8325 Form"}
                    message={`Dairy information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                />
            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        {/* <PrettyPrintJson data={props.values} /> */}
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        <div className="">
                                            <ul className="list-unstyled">
                                                <li>
                                                    <FormController
                                                        control="input"
                                                        type="text"
                                                        label="Manufacturer approval number"
                                                        name="manufacturingPlant"
                                                        isrequired="true"
                                                        col={4}
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                                <li>
                                                    <FormController
                                                        control="select"
                                                        label="Commodities certified for"
                                                        name="commoditiesCertifiedFor"
                                                        options={commoditiesCertifiedFor}
                                                        isrequired="true"
                                                        col={4}
                                                    />
                                                </li>
                                                <li>
                                                    <MultiSelectField
                                                        id='productScientificSpecies'
                                                        name='productScientificSpecies'
                                                        label="Species"
                                                        placeholder="Select fields"
                                                        options={specieslist}
                                                        defaultValues={props.values?.productScientificSpecies}
                                                        getOptionValue={option => option.id}
                                                        getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                                        value={props.values.productScientificSpecies}
                                                        isMulti={true}
                                                        onChange={(name, value) => {
                                                            props.setFieldValue(name, value);
                                                            handleSpeciesChange(name, value, props);
                                                        }}
                                                        onBlur={props.setFieldTouched}
                                                        touched={props.touched.productScientificSpecies}
                                                        error={props.errors.productScientificSpecies}
                                                        isClearable={true}
                                                        isrequired="true"
                                                        backspaceRemovesValue={true}
                                                        col={4}
                                                    />
                                                </li>
                                                <h4 className="mt-5">Animal Health Attestation</h4>

                                                <label> have been prepared exclusivley with the following animal by-products:<span className="required" />  </label>
                                                <br></br>
                                                {(_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) && (
                                                    <li>
                                                        <FormController
                                                            control="checkbox"
                                                            label={DogChews8325Labels.health_1_StatementsChoice[0].key}
                                                            name="health_1_1"
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}
                                                {(_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) && (
                                                    <li>
                                                        <FormController
                                                            control="checkbox"
                                                            label={DogChews8325Labels.health_1_StatementsChoice[1].key}
                                                            name="health_1_2"
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}
                                                {(_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) && props.values.health_1_2 && (
                                                    <div className="border-left pl-3 subform">
                                                        <>
                                                            <div className="clause-container pl-0 ms-0 mt-3">
                                                                {(_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) && (
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={DogChews8325Labels.health_1_2_StatementsChoice[0].key}
                                                                        name="health_1_2_A"
                                                                        readOnly={readOnly}
                                                                    />
                                                                )}
                                                                {(_1Aves) && (
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={DogChews8325Labels.health_1_2_StatementsChoice[1].key}
                                                                        name="health_1_2_B"
                                                                        readOnly={readOnly}
                                                                    />
                                                                )}
                                                                {(_2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) && (
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={DogChews8325Labels.health_1_2_StatementsChoice[2].key}
                                                                        name="health_1_2_C"
                                                                        readOnly={readOnly}
                                                                    />
                                                                )}
                                                                {(_3Suidae) && (
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={DogChews8325Labels.health_1_2_StatementsChoice[3].key}
                                                                        name="health_1_2_D"
                                                                        readOnly={readOnly}
                                                                    />
                                                                )}
                                                                {(_1Aves) && (
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={DogChews8325Labels.health_1_2_StatementsChoice[4].key}
                                                                        name="health_1_2_E"
                                                                        readOnly={readOnly}
                                                                    />
                                                                )}
                                                            </div>
                                                        </>

                                                        <hr />
                                                    </div>
                                                )}
                                                {(_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) && (
                                                    <li>
                                                        <FormController
                                                            control="checkbox"
                                                            name="health_1_3"
                                                            label={DogChews8325Labels.health_1_StatementsChoice[2].key}
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}
                                                {(_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae) && (
                                                    <li>
                                                        <FormController
                                                            control="checkbox"
                                                            name="health_1_4"
                                                            label={DogChews8325Labels.health_1_StatementsChoice[3].key}
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}
                                                {(_5Pesca || _6Mollusca || _8InvertebratesOtherThanMolluscaandCrustacea) && (
                                                    <li>
                                                        <FormController
                                                            control="checkbox"
                                                            name="health_1_5"
                                                            label={DogChews8325Labels.health_1_StatementsChoice[4].key}
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}
                                                {(_5Pesca || _6Mollusca || _8InvertebratesOtherThanMolluscaandCrustacea) && (
                                                    <li>
                                                        <FormController
                                                            control="checkbox"
                                                            name="health_1_6"
                                                            label={DogChews8325Labels.health_1_StatementsChoice[5].key}
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}
                                                {(_1Aves || _2Ruminantia || _3Suidae || _4MammaliaOtherThanRuminantiaorSuidae ||
                                                    _5Pesca || _6Mollusca || _7Crustacea || _8InvertebratesOtherThanMolluscaandCrustacea) && (
                                                        <li>
                                                            <FormController
                                                                control="checkbox"
                                                                name="health_1_7"
                                                                label={DogChews8325Labels.health_1_StatementsChoice[6].key}
                                                                readOnly={readOnly}
                                                            />
                                                        </li>
                                                    )}

                                                <>{(_2Ruminantia || _3Suidae || _5Pesca || _1Aves || _4MammaliaOtherThanRuminantiaorSuidae ||
                                                    _6Mollusca || _7Crustacea || _8InvertebratesOtherThanMolluscaandCrustacea) && (

                                                        <li>
                                                            <hr />
                                                            <div className="clause-container pl-0 ms-0 mt-3">
                                                                <label>  have been subjected</label>
                                                                {(_2Ruminantia || _3Suidae || _5Pesca) && (
                                                                    <FormController
                                                                        control="checkbox"
                                                                        name="health_2_1"
                                                                        label={DogChews8325Labels.health_2_StatementsChoice[0].key}
                                                                        readOnly={readOnly}
                                                                    />
                                                                )}
                                                                {(_1Aves || _4MammaliaOtherThanRuminantiaorSuidae ||
                                                                    _6Mollusca || _7Crustacea || _8InvertebratesOtherThanMolluscaandCrustacea) && (
                                                                        <FormController
                                                                            control="checkbox"
                                                                            name="health_2_2"
                                                                            label={DogChews8325Labels.health_2_StatementsChoice[1].key}
                                                                            readOnly={readOnly}
                                                                        />
                                                                    )}
                                                            </div>
                                                        </li>
                                                    )}
                                                </>


                                                <>   {(_2Ruminantia) && (
                                                    <li>
                                                        <hr />
                                                        <div className="clause-container pl-0 ms-0 mt-3">
                                                            <FormController
                                                                control="checkboxClauses"
                                                                label="the dogchews described above"
                                                                name="health_6_1_statements"
                                                                options={DogChews8325Labels.health_6_1_statementsChoice}
                                                                readOnly={readOnly}
                                                            />
                                                        </div>
                                                    </li>
                                                )}

                                                </>
                                                {(_2Ruminantia && props.values.health_6_1_statements.includes("2")) && (
                                                    <div className='subform ps-5 mt-3'>
                                                        <FormController
                                                            control="checkboxClauses"
                                                            name="health_6_2_statements"
                                                            disabled={!props.values.health_6_1_statements.includes("2")}
                                                            options={DogChews8325Labels.health_6_2_statementsChoice}
                                                            readOnly={readOnly}
                                                        />
                                                    </div>
                                                )}
                                            </ul>
                                        </div>
                                        {/* <PrettyPrintJson data={props.errors} /> */}
                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting || readOnly}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />
                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default DogChews8325AddPage;