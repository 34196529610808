import moment from "moment-timezone";
import React from "react";
import i18n from "./Components/LanguageConfig"; // Import the i18n instance
import { MultiLangText } from "./Components/TemplateItem";
import { natureCommodity8461ChoicesAnnualAnimalHealthVisit } from "../../../components/Products/ProductDetail/Data/NatureOfCommodity";
export default function SupplierDeclarationTemplate(props) {
  const {
    languageCode,
    certificateFormData,
    user,
    uniqueRef,
    product,
    manufacturer,
    platform,
    organisation,
    site,
    productDetail,
    temperature,
    childRef,
    validTo,
  } = props;

  const { t } = i18n;

  const coldStoreFormattedList =
    certificateFormData?.coldStoreEstablishments?.length > 0
      ? ", " +
        certificateFormData?.coldStoreEstablishments
          .map((x) => x.approvalNumber)
          .join(", ")
      : "";

  return (
    <div
      ref={childRef}
      border="light"
      className="d-none d-block d-print-block "
    >
      <div className="storage-declaration-template">
        <div className="px-3 attestation-title text-center">
          <MultiLangText
            className="h2"
            languageCode={languageCode}
            engValue={t("supplierDeclarationTitle")}
            translatedValue={t("supplierDeclarationTitle", {
              lng: languageCode,
            })}
            delimitterType="newline"
          />
          <br />
          <br />

          <MultiLangText
            className="h4"
            languageCode={languageCode}
            engValue={t("uniqueRefLabel")}
            translatedValue={t("uniqueRefLabel", { lng: languageCode })}
            delimitterType="newline"
          />
          <br />
          <span className="h3">{uniqueRef}</span>
        </div>
        <br />
        <br />
        {/* //Intro */}
        <div className="px-3">
          <h4 className="mb-3">
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s1Title")}
              translatedValue={t("s1Title", { lng: languageCode })}
              delimitterType="inline"
            />
          </h4>
          <p>
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s1p1", {
                userName: user?.fullName,
                manufacturerName: manufacturer?.manufacturerName,
              })}
              translatedValue={t("s1p1", {
                lng: languageCode,
                userName: user?.fullName,
                manufacturerName: manufacturer?.manufacturerName,
              })}
              delimitterType="inline"
            />
          </p>
          <p>
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s1p2")}
              translatedValue={t("s1p2", { lng: languageCode })}
              delimitterType="inline"
            />
          </p>
          <p>
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s1p3")}
              translatedValue={t("s1p3", { lng: languageCode })}
              delimitterType="inline"
            />
          </p>
          {productDetail?.commodityNature &&
            natureCommodity8461ChoicesAnnualAnimalHealthVisit.some(
              (choice) => choice.value === productDetail.commodityNature
            ) && (
              <p>
                <strong>
                  <MultiLangText
                    languageCode={languageCode}
                    engValue={t("s1pNatureOfC")}
                    translatedValue={t("s1pNatureOfC", { lng: languageCode })}
                    delimitterType="inline"
                  />
                </strong>
              </p>
            )}
          <p>
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s1p4", {
                organisationName: organisation.name,
                organisationAddress: organisation?.fullAddress,
              })}
              translatedValue={t("s1p4", {
                lng: languageCode,
                organisationName: organisation.name,
                organisationAddress: organisation?.fullAddress,
              })}
              delimitterType="newline"
            />
          </p>
        </div>
        {/* //Section A */}
        <div className="px-3">
          <h4>
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s2aTitle")}
              translatedValue={t("s2aTitle", { lng: languageCode })}
              delimitterType="inline"
            />
          </h4>
          <h6>
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s2a1Title")}
              translatedValue={t("s2a1Title", { lng: languageCode })}
              delimitterType="inline"
            />
          </h6>
          <p>
            {/* <MultiLangText 
                        languageCode={languageCode}
                        engValue={t("s2a1p1", {
                            platformEstablishmentNumber: platform?.establishmentNumber,
                        })} 
                        translatedValue={t("s2a1p1", {
                            lng: languageCode, 
                            platformEstablishmentNumber: platform?.establishmentNumber,
                        })}
                        delimitterType="newline"
                    />

                        */}
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s2a1p1", {
                siteEstablishmentType: site?.establishmentNumber,
              })}
              translatedValue={t("s2a1p1", {
                lng: languageCode,
                siteEstablishmentType: site?.establishmentNumber,
              })}
              delimitterType="newline"
            />
            <br />
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s2a1p1address", {
                siteName: site?.siteName,
                siteAddress: site?.fullAddress,
                siteEstablishmentType: site?.establishmentNumberTypeName,
                siteEstablishmentNumber: site?.establishmentNumber,
              })}
              delimitterType="newline"
            />
          </p>
          <p>
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s2a1p2", {
                platformEstablishmentType:
                  platform?.establishmentNumberTypeName,
              })}
              translatedValue={t("s2a1p2", {
                lng: languageCode,
                platformEstablishmentType:
                  platform?.establishmentNumberTypeName,
              })}
              delimitterType="newline"
            />
            <br />
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s2a1p2address", {
                platformName: platform?.platformName,
                platformAddress: platform?.fullAddress,
                platformEstablishmentType:
                  platform?.establishmentNumberTypeName,
                platformEstablishmentNumber: platform?.establishmentNumber,
              })}
              delimitterType="newline"
            />
          </p>

          <h6 className="mb-3">
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s2a2Title")}
              translatedValue={t("s2a2Title", { lng: languageCode })}
              delimitterType="inline"
            />
          </h6>
          <p>
            <MultiLangText
              languageCode={languageCode}
              engValue={t("s2a2p1")}
              translatedValue={t("s2a2p1", { lng: languageCode })}
              delimitterType="inline"
            />
          </p>
        </div>

        <table border="0" cellspacing="6" cellpadding="6">
          <tbody>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("productName")}
                  translatedValue={t("productName", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>{product.productName}</strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("ean")}
                  translatedValue={t("ean", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>{product.ean}</strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("supplierName")}
                  translatedValue={t("supplierName", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>{manufacturer?.manufacturerName}</strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("supplierCode")}
                  translatedValue={t("supplierCode", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>{manufacturer?.supplierCode}</strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("countryOrigin")}
                  translatedValue={t("countryOrigin", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>{productDetail?.countryOrigin?.isoCode}</strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("coldStore")}
                  translatedValue={t("coldStore", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>
                  {certificateFormData?.coldStoreEstablishments
                    ?.map((x) => x.approvalNumber)
                    .join(", ")}
                </strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("temperature")}
                  translatedValue={t("temperature", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>{temperature.temperatureName}</strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("commodityCode")}
                  translatedValue={t("commodityCode", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>{productDetail.commodityHscode}</strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("commodityNature")}
                  translatedValue={t("commodityNature", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>{productDetail?.commodityNature}</strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("idMark")}
                  translatedValue={t("idMark", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>
                  {
                    certificateFormData.identificationMarkEstablishment
                      ?.approvalNumber
                  }
                </strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("species")}
                  translatedValue={t("species", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>
                  {certificateFormData?.productScientificSpecies
                    ?.map((x) => x.speciesName)
                    .join(", ")}
                </strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("packagingType")}
                  translatedValue={t("packagingType", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>
                  <MultiLangText
                    languageCode={languageCode}
                    engValue={t("packagingShelf")}
                    translatedValue={t("packagingShelf", { lng: languageCode })}
                    delimitterType="inline"
                  />
                  <br />
                  {productDetail.packagingTypeShelf}{" "}
                </strong>
                <strong>
                  <MultiLangText
                    languageCode={languageCode}
                    engValue={t("packagingOuter")}
                    translatedValue={t("packagingOuter", { lng: languageCode })}
                    delimitterType="inline"
                  />
                  <br />
                  {productDetail.packagingTypeOuter}{" "}
                </strong>
                <strong>
                  <MultiLangText
                    languageCode={languageCode}
                    engValue={t("packagingRetail")}
                    translatedValue={t("packagingRetail", {
                      lng: languageCode,
                    })}
                    delimitterType="inline"
                  />
                  <br />
                  {productDetail.packagingTypeRetail}
                </strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("finalConsumer")}
                  translatedValue={t("finalConsumer", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>Yes</strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("manufacturingPlant")}
                  translatedValue={t("manufacturingPlant", {
                    lng: languageCode,
                  })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>
                  {site.siteName}, {site?.establishmentNumber}
                </strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("netWeight")}
                  translatedValue={t("netWeight", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>{productDetail?.netWeight}kg</strong>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("grossWeight")}
                  translatedValue={t("grossWeight", { lng: languageCode })}
                  delimitterType="inline"
                />
              </td>
              <td>
                <strong>{productDetail?.grossWeight}kg</strong>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        {/* //Section B */}
        <div className="px-3">
          <>
            <h4>
              <MultiLangText
                languageCode={languageCode}
                engValue={t("s2Title")}
                translatedValue={t("s2Title", { lng: languageCode })}
                delimitterType="inline"
              />
            </h4>
            <p>
              <MultiLangText
                languageCode={languageCode}
                engValue={t("s2p1")}
                translatedValue={t("s2p1", { lng: languageCode })}
                delimitterType="newline"
              />
            </p>

            <p>
              <MultiLangText
                languageCode={languageCode}
                engValue={t("s2p2Title")}
                translatedValue={t("s2p2Title", { lng: languageCode })}
                delimitterType="inline"
              />
            </p>
            <ul>
              <li>
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("s2p2li1")}
                  translatedValue={t("s2p2li1", { lng: languageCode })}
                  delimitterType="inline"
                />
              </li>
              <li>
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("s2p2li2")}
                  translatedValue={t("s2p2li2", { lng: languageCode })}
                  delimitterType="inline"
                />
              </li>
              <li>
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("s2p2li3")}
                  translatedValue={t("s2p2li3", { lng: languageCode })}
                  delimitterType="inline"
                />
              </li>
              <li>
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("s2p2li4")}
                  translatedValue={t("s2p2li4", { lng: languageCode })}
                  delimitterType="inline"
                />
              </li>
            </ul>
            <p>
              {site?.isApproved ? (
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("s2p3approved", {
                    siteEstablishmentNumber: site?.establishmentNumber,
                    establishmentTypeName: site?.establishmentType?.name,
                    coldStoreEstablishments: coldStoreFormattedList,
                  })}
                  translatedValue={t("s2p3approved", {
                    lng: languageCode,
                    siteEstablishmentNumber: site?.establishmentNumber,
                    coldStoreEstablishments: coldStoreFormattedList,
                  })}
                  delimitterType="newline"
                />
              ) : (
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("s2p3registered", {
                    siteEstablishmentNumber: site?.establishmentNumber,
                    establishmentTypeName: site?.establishmentType?.name,
                    coldStoreEstablishments: coldStoreFormattedList,
                  })}
                  translatedValue={t("s2p3registered", {
                    lng: languageCode,
                    siteEstablishmentNumber: site?.establishmentNumber,
                    coldStoreEstablishments: coldStoreFormattedList,
                  })}
                  delimitterType="newline"
                />
              )}
            </p>
          </>
        </div>

        <div className="px-3">
          <h5 className="authorised-by">
            <MultiLangText
              languageCode={languageCode}
              engValue={t("authorisedBy")}
              translatedValue={t("authorisedBy", { lng: languageCode })}
              delimitterType="inline"
            />
          </h5>
          <h1 className="d-none d-print-block signature-placeholder">
            [sig|req|signer1]
          </h1>
        </div>
        <table border="0" cellspacing="6" cellpadding="1" width="50%">
          <tbody>
            <tr valign="top">
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("name")}
                  translatedValue={t("name", { lng: languageCode })}
                  delimitterType="newline"
                />
              </td>
              <td>{user.fullName}</td>
            </tr>
            <tr valign="top">
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("emailAddress")}
                  translatedValue={t("emailAddress", { lng: languageCode })}
                  delimitterType="newline"
                />
              </td>
              <td>{user.email}</td>
            </tr>
            <tr valign="top">
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("jobTitle")}
                  translatedValue={t("jobTitle", { lng: languageCode })}
                  delimitterType="newline"
                />
              </td>
              <td>{user.jobTitle}</td>
            </tr>
            <tr valign="top">
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("date")}
                  translatedValue={t("date", { lng: languageCode })}
                  delimitterType="newline"
                />
              </td>
              <td>{moment().format("Do MMMM YYYY")}</td>
            </tr>
            <tr valign="top">
              <td width="50%">
                <MultiLangText
                  languageCode={languageCode}
                  engValue={t("validTo")}
                  translatedValue={t("validTo", { lng: languageCode })}
                  delimitterType="newline"
                />
              </td>
              <td>
                {validTo}
                <br />
                <small>
                  <MultiLangText
                    languageCode={languageCode}
                    engValue={t("validToLabel")}
                    translatedValue={t("validToLabel", { lng: languageCode })}
                    delimitterType="newline"
                  />
                </small>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
