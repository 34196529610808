import React from "react";
export const NoResults = (props) => {
  const { name } = props;
  return (

    <>
      <div className="text-center py-9"><h5 className="text-gray-600">
        No {name} have been found</h5>
        </div>
    </>
  );
};
