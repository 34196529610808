import React from "react"
import { Field, ErrorMessage } from "formik"
import {  Col, Row, Form } from "@themesberg/react-bootstrap";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const Select = (props) => {  
    const { name, label, options, isrequired, col = 12, touched, errors, ...rest } = props
    return (
        <Field  name={props.name}>
        {({ field, meta }) => {
            return (
            <Form.Group controlId={`validation${name}`}>
                <Col md={col}>
                    {label!=null&&(
                        <Form.Label className={(isrequired=="true"?"required":"")}>{label}</Form.Label>
                    )}
                    <Form.Select 
                        id={name} 
                        name={name} 
                            {...field}
                            {...rest}
                            isInvalid={!!meta.error && meta.touched}
                            className={(meta.touched && meta.error) ? 'form-control select is-invalid' : 'select form-control'}
                        >
                        {options.map(option => {
                            return (
                                <option key={option.value} value={option.value}>
                                    {option.key}
                                </option>
                            )
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                    {meta.error}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
        )}} 
        </Field>
    )
}
export default Select


