import *  as baseService  from "./BaseService";

const create = (data) => {
    return baseService.postApiData("/StorageSupplierDeclaration/create", data);
};
const createResponse = (data) => {
    return baseService.postApiData("/StorageExporterDeclaration/create", data);
};
const createDocument = (data) => {
    return baseService.postApiData("/StorageSupplierDeclaration/GenerateDocument", data);
};
const getAllByProduct = (id) => {
    return baseService.getApiData("/StorageSupplierDeclaration/GetAllByProductId?id=" + id);
};
const getCurrentByProductId = (id) => {
    return baseService.getApiData("/StorageSupplierDeclaration/GetCurrentByProductId?id=" + id);
};
const cancelStorageDeclarationRequest = (id) => {
    return baseService.putApiData("/StorageSupplierDeclaration/CancelSupportStorageDeclarationRequest?id=" + id);
};
const getAllByManufacturer = (id) => {
    return baseService.getApiData("/StorageSupplierDeclaration/getAllByManufacturer");
};
const getAllPagedByUserId = (params) => {
    return baseService.getApiDataWithParams("/StorageSupplierDeclaration/GetAllPagedByUser", params);
};
const getAllPaged = (params) => {
    return baseService.getApiDataWithParams("/StorageSupplierDeclaration/getAllPaged", params);
};
const getAllByPlatformCertifier = (id) => {
    return baseService.getApiData("/StorageSupplierDeclaration/GetCurrentByVetUserId?id=" + id);
};
const getUniqueReference = (id, isGEFS) => {
    return baseService.getApiData("/StorageSupplierDeclaration/GetUniqueReference?productId=" + id);
};
const getUniqueReference2 = (id, isGEFS) => {
    return baseService.getApiData("/StorageExporterDeclaration/GetUniqueReference?productId=" + id);
};
const getById = (id) => {
    return baseService.getApiData("/StorageSupplierDeclaration/getById?id=" + id);
};
const getAllIncompleteRequests = () => {
    return baseService.getApiData("/StorageSupplierDeclaration/getAllIncompleteRequests");
};
const StorageDeclarationService = {
    getAllByProduct,
    getById,
    create,
    createResponse,
    createDocument,
    getCurrentByProductId,
    getUniqueReference,
    getUniqueReference2,
    getAllByPlatformCertifier,
    cancelStorageDeclarationRequest,
    getAllByManufacturer,
    getAllPagedByUserId,
    getAllPaged,
    getAllIncompleteRequests
};

export default StorageDeclarationService;