import React, { useEffect } from 'react';
import {Col, Row, Button, Card} from "@themesberg/react-bootstrap";
import FormController from "../../common/FormController"
import { FieldArray, useFormikContext } from "formik";
import { MultiSelectField} from "../../common/MultiSelect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import MeatEstablishmentSelect from '../../../../components/EstablishmentSelect/MeatEstablishmentSelect';

const generateTreatments = (treatments) => [
  { value: null, key: null },
  ...treatments.map((treatment) => ({ value: treatment, key: treatment })),
];

var treatments = generateTreatments(["A", "B", "C", "D"]);
const treatmentsBOV = generateTreatments(["A", "B", "C"]);
const treatmentsOVI = generateTreatments(["A", "B", "C", "D"]);
const treatmentsPOR = generateTreatments(["A", "B", "C", "D"]);
const treatmentsPOU = generateTreatments(["A", "B", "D"]);
const treatmentsRAT = generateTreatments(["A", "B", "D"]);
const treatmentsRUF = generateTreatments(["A", "B", "C"]);
const treatmentsRUW = generateTreatments(["A", "B", "C"]);
const treatmentsSUF = generateTreatments(["A", "B", "C"]);
const treatmentsSUW = generateTreatments(["A", "B", "C", "D"]);
const treatmentsGBM = generateTreatments(["A", "B", "D"]);


const SpeciesData = ({setZonesArray, certificateNumber, readOnly, prefix, speciesGroupIndex, speciesDataArrayHelpers, countrys, zonesarray, requireTreatments, requireCountryOfOrigin, customCountryOfOriginLabel = "Country of origin", requireEstablishmentCodes,customEstablishmentCodesSubLabel = "",speciesGroupName,disableCountryAndEstablishmentCodes}) => {
    const { values, setFieldTouched, setFieldValue } = useFormikContext();
  
var newSpeciesData = [];

    values[prefix].speciesList[speciesGroupIndex].speciesData.forEach((speciesData, index) =>  
    {
      var zones = [];
      var found = null;

      if(zonesarray != undefined && zonesarray != null)
      {
        if(requireTreatments === true)
        {
          if(speciesGroupName == "BOV")
          {
            treatments =  treatmentsBOV;
          }
          if(speciesGroupName == "OVI")
          {
    
            treatments =  treatmentsOVI;
          }
          if(speciesGroupName == "POR")
          {
    
            treatments =  treatmentsPOR;
          }
          if(speciesGroupName == "POU")
          {
    
            treatments =  treatmentsPOU;
          }
          if(speciesGroupName == "RAT")
          {
    
            treatments =  treatmentsRAT;
          }
          if(speciesGroupName == "RUF")
          {
    
            treatments =  treatmentsRUF;
          }
          if(speciesGroupName == "RUW")
          {
    
            treatments =  treatmentsRUW;
          }
          if(speciesGroupName == "SUF")
          {
    
            treatments =  treatmentsSUF;
          }
          if(speciesGroupName == "SUW")
          {
    
            treatments =  treatmentsSUW;
          }
          if(speciesGroupName == "GBM")
          {
    
            treatments =  treatmentsGBM;
          }


        if(speciesData.treatment != "")
        {
          found =  zonesarray.find(x => x.speciesGroupName === speciesGroupName &&  x.treatmentType === speciesData.treatment);
        }
        }
        else
        {
          values[prefix].speciesList[speciesGroupIndex].speciesData[index].treatment = "A";
          found =  zonesarray.find(x => x.speciesGroupName === speciesGroupName);
        }
        if(found != undefined && found != null)
        {
          if(countrys != null)
          {
          zones = found.zonesArr.concat(countrys);
          zones.sort((a, b) => a.countryName.localeCompare(b.countryName));
          }
          else
          {
            zones = found.zonesArr;
          }
        }
      }
       values[prefix].speciesList[speciesGroupIndex].speciesData[index].zones = zones;

      newSpeciesData.push(speciesData);
    })



    {/* Function ADD Treatment Details to Species Array */}
    const handleAddTreatmentDetail = () => {
      const speciesData = {};
      speciesData.disable = true;
      // speciesData.zones = null;

      if(requireTreatments){
        speciesData.treatment = '';
      }
      if(requireEstablishmentCodes){
        speciesData.establishmentCodes = '';
        speciesData.establishmentCodeEstablishments = [];
      }
      if(requireCountryOfOrigin){
        speciesData.countryOfOrigin = [];
      }
      speciesDataArrayHelpers.push(speciesData);
    };




    {/* Function REMOVE Treatment Details to Species Array */}
    const handleRemoveTreatmentDetails = (index) => {
      speciesDataArrayHelpers.remove(index);
    };
    
    return (
      <>

      {/* Species Treatment Details Rows*/}
        {newSpeciesData?.map((speciesData, index) => (
          <div key={speciesData.treatment + index}>
            <Row className="mb-3">
                {/* Treatment Type */}
                {requireTreatments &&
                  <Col sm={2}>
                      <FormController
                          control="select"
                          label="Treatment type"
                          error={`errors.${prefix}.speciesList.${speciesGroupIndex}.speciesData.${index}.treatment`}
                          name={`${prefix}.speciesList.${speciesGroupIndex}.speciesData.${index}.treatment`}
                          options={treatments.filter(treatment => 
                            !values[prefix].speciesList[speciesGroupIndex]?.speciesData?.map(x=>x.treatment).includes(treatment.value) ||
                            values[prefix].speciesList[speciesGroupIndex].speciesData[index].treatment===treatment.value)
                          }
                          onChange={(e) => {
                            setZonesArray(speciesGroupName, e.target.value); 
                            setFieldValue(`${prefix}.speciesList.${speciesGroupIndex}.speciesData.${index}.treatment`, e.target.value)}
                          }
                          disabled={readOnly}
                          isrequired="true"
                      />
                  </Col>
                }

                {/* Country Of Origin */}
                {requireCountryOfOrigin && !speciesData.treatment == "" &&( 
                  <Col sm={4}>
                    <MultiSelectField
                      id={`${prefix}.speciesList.${speciesGroupIndex}.speciesData.${index}.countryOfOrigin`}
                      name={`${prefix}.speciesList.${speciesGroupIndex}.speciesData.${index}.countryOfOrigin`}
                      label={customCountryOfOriginLabel}
                      placeholder="Select fields"
                      options={zonesarray.filter(x=>x.speciesGroupName === speciesGroupName && x.treatmentType === speciesData.treatment)[0]?.zonesArr}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.displayName} 
                      isMulti={true}
                      isrequired="true"
                      isClearable={true}
                      backspaceRemovesValue={true}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      defaultValues={values[prefix].speciesList[speciesGroupIndex].speciesData[index].countryOfOrigin}
                      touched={`touched.${prefix}.speciesList.${speciesGroupIndex}.speciesData.${index}.countryOfOrigin`}
                      value={values[prefix]?.speciesList[speciesGroupIndex]?.speciesData[index]?.countryOfOrigin}
                      error={`errors.${prefix}.speciesList.${speciesGroupIndex}.speciesData.${index}.countryOfOrigin`}
                      index = {index}
                      readOnly={readOnly}
                    />
                  </Col>
                )}

                       
                {/* Establishment Codes */}
                {requireEstablishmentCodes && !speciesData.treatment == ""  &&(
                  <Col sm={5}>
                     <MeatEstablishmentSelect 
                       name={`${prefix}.speciesList.${speciesGroupIndex}.speciesData.${index}.establishmentCodeEstablishments`}
                       selectedFieldName={`${prefix}.speciesList.${speciesGroupIndex}.speciesData.${index}.establishmentCodeEstablishments`}
                       setEstablishmentSelected={setFieldValue}
                       selections={values[prefix].speciesList[speciesGroupIndex].speciesData[index].establishmentCodeEstablishments}
                       label="Meat product establishment approval number(s)"
                       isMulti={true}
                       countries={
                          values[prefix].speciesList[speciesGroupIndex].speciesData[index].countryOfOrigin
                            .map(x => x.isoCode)
                            .map((str) => str.replace(/-(0|1|2|3|4|5)/g, ''))
                        }
                       certificateNumber={certificateNumber}
                       species={[values[prefix].speciesList[speciesGroupIndex].speciesGroupName]}
                       getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                       getOptionLabel={option => option.name ? option.name + " ("  + option.approvalNumber + ")" : ""}
                       sublabel={customEstablishmentCodesSubLabel}
                       isRequired={true}
                       readOnly={readOnly}
                   />      
                  </Col>
                )}

                {/* Remove Treatment Details Row */}
                {requireTreatments &&
                  <Col sm={1} className="mt-5">
                  <Button variant="outline-danger" disabled={readOnly} onClick={()=>{handleRemoveTreatmentDetails(index)}}>
                    <FontAwesomeIcon icon={faTimes} className="mx-2" />
                  </Button>
                  </Col>
                }
            </Row>
        </div>
        ))}
        
                    
        {/* Add Treatment Row  */}
        {requireTreatments &&
        values[prefix].speciesList[speciesGroupIndex].speciesData.length < treatments.length - 1 &&
        <Row>
          <Col sm={12}>
            <Button variant="outline-info" size="sm"  onClick={handleAddTreatmentDetail}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add Additional Treatment
            </Button>
          </Col>
        </Row>
        }
                        
      </>
    );
};
  
const SpeciesTable = ({setZonesArray, certificateNumber, productId, readOnly, prefix, productType, speciesArrayHelpers, countrys,zonesarray, meatGroups, requireTreatments = true, requireCountryOfOrigin = true, customCountryOfOriginLabel, requireEstablishmentCodes = true, requireSpeciesDetail = true,customEstablishmentCodesSubLabel,disableCountryAndEstablishmentCodes = false}) => {
    const { values,  errors, touched, setFieldValue } = useFormikContext();
  
 
    useEffect(() => {
      if(values[prefix]!= null){
        values[prefix].speciesList.forEach(x=>{
          x.productId = productId;
        })
      }
    }, [values[prefix]]);


    {/* Function - Species Group Checked/Unchecked */}
    const handleSpeciesGroup = (e, meatgroup) => {
      const selectedSpecies = {};
      selectedSpecies.productId = productId;
      selectedSpecies.speciesGroupName = meatgroup.name;

      if(requireSpeciesDetail){
        
        selectedSpecies.speciesData = [
          {
            treatment:'',
            establishmentCodes:'',
            establishmentCodeEstablishments:[],
            countryOfOrigin: [],
            zones:[],
          }
        ];
      }

      if(prefix !== null){
        setFieldValue(`${prefix}.contains${meatgroup.name}`,e.target.checked);
      }
      else{
        setFieldValue(`contains${meatgroup.name}`,e.target.checked);
      }

      {/* Find and ADD from array if Species Group checked */}
      if(e.target.checked ){
          var existingItemIndex;
          
          if(prefix !== null){
            values[prefix].speciesList.findIndex(obj => 
              Object.keys(obj).some(key => obj[key] === meatgroup.name)
                  );
          }
          else{
            values.speciesList.findIndex(obj => 
              Object.keys(obj).some(key => obj[key] === meatgroup.name)
                  );
          }
          if(existingItemIndex >= 0){
            speciesArrayHelpers.remove(selectedSpecies);
          }
          if(meatgroup != null){
            speciesArrayHelpers.push(selectedSpecies);
          }
      }
      else{
        {/* Find and REMOVE from array if Species Group unchecked */}
        
        if(prefix !== null){
          var index =  values[prefix].speciesList?.findIndex(obj => 
              Object.keys(obj).some(key => obj[key] === meatgroup.name)
          );
        }
        else{
          var index =  values.speciesList?.findIndex(obj => 
              Object.keys(obj).some(key => obj[key] === meatgroup.name)
          );
        }
        speciesArrayHelpers.remove(index);
      }
    };
  
    return (
      <>
      <h4 className="mt-4">Species</h4>
      <p>Select all species groups which apply to this {productType} product:<span className="required"/></p>               

        {/* Species Checkboxes (BOV/OVI etc)*/}
        <Row>
          {meatGroups?.map((meatgroup, index) => (
              <Col sm={6} lg={4} xxl={2} className="button" key={index}>
                  <FormController
                      className="border-chk"
                      control="checkbox"
                      label={`Contains ${meatgroup.name}`}
                      name={prefix == null ? (`contains${meatgroup.name}`) : (`${prefix}.contains${meatgroup.name}`)}
                      onChange={(e) => handleSpeciesGroup(e, meatgroup)}
                      disabled={readOnly}
                  />
              </Col>
          ))}
        </Row>

        {/* Species Data Array (Treatment Rows for each Species) */}
        
        {requireSpeciesDetail &&
          <>
            {values[prefix].speciesList?.map((species, index) => (
             
              <div key={species.speciesGroupName + index} className="my-4">
                <Card border="light" className="bg-gray-100 shadow-sm">
        
                  <Card.Header className="border-light">
                    {requireTreatments?
                      <h5 className="text-title">{species.speciesGroupName.toUpperCase()} - Treatment Details</h5>
                    :
                      <h5 className="text-title">{species.speciesGroupName.toUpperCase()} - Details</h5>
                    }
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <FieldArray name={`${prefix}.speciesList[${index}].speciesData`}>
                      {arrayHelpers => (
                        <>
                          <SpeciesData
                            setZonesArray={setZonesArray}
                            errors={errors}
                            prefix={prefix}
                            touched={touched}
                            countrys={countrys}
                            zonesarray={zonesarray}
                            requireTreatments={requireTreatments}
                            customCountryOfOriginLabel={customCountryOfOriginLabel}
                            requireCountryOfOrigin={requireCountryOfOrigin}
                            requireEstablishmentCodes={requireEstablishmentCodes}
                            customEstablishmentCodesSubLabel={customEstablishmentCodesSubLabel}
                            speciesGroupName={species.speciesGroupName.toUpperCase()}
                            speciesGroupIndex={index}
                            disableCountryAndEstablishmentCodes={disableCountryAndEstablishmentCodes}
                            speciesDataArrayHelpers={arrayHelpers}
                            readOnly={readOnly}
                            certificateNumber={certificateNumber}
                          />
                        </>
                      )}
                    </FieldArray>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </>
        }
      </>
    );
};

export default SpeciesTable;