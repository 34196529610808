export const CertificateListFilterConfig = (platforms, certificateNumbers, destinationCountries) => {
    const filterConfig = [
      {
        hasMenu: true,
        title: "Platforms",
        name: "platforms", 
        data: platforms,
        hasSearch: true,
        isFilter: true
      },
      {
        hasMenu: true,
        title: "Availability",
        name: "active", 
        data: [{ value: "false", label: "All Available", group: "availabilityOptions" }, { value: "true", label: "All Assigned", group: "availabilityOptions" }],
        hasSearch: false,
        isFilter: true,
        defaultValue: false 
      },
      {
        hasMenu: true,
        title: "Destinations",
        name: "destinationCountries", 
        data: destinationCountries,
        hasSearch: true,
        isFilter: true
      },
      {
        hasMenu: true,
        title: "Certificate types",
        name: "certificateNumbers",
        data: certificateNumbers,
        hasSearch: false,
        isFilter: true
      }
    ]
    return filterConfig;
  };