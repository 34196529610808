import *  as baseService  from "./BaseService";

const getByProductId = (id) => {
    return baseService.getApiData("/producthighlyrefined/getByProductId?id=" + id);
};

const create = (data) => {
    return baseService.postApiData("/producthighlyrefined/Create", data);
};

const update = (id, data) => {
    return baseService.putApiData("/producthighlyrefined/update?id=" + id, data);
};

const ProductHighlyRefinedService = {
    create,
    getByProductId,
    update
};

export default ProductHighlyRefinedService;