import React, {useState} from 'react';
import FieldRow from './FieldRow';
import LineStampRow from './LineStampRow';
import TextStampRow from './TextStampRow';

const FormGroup = (props) => {
    const {
        data, 
        pageNumber, 
        group,
        parentDisabled, 
        parentGroup, 
        canvas, 
        ctx, 
        values,
        setFieldValue
    } = props;
    return(
        <>
        {/* //Parent Data - Fields */}
        {data?.fields?.filter(x=> x.pages?.includes(pageNumber)
            && x.groupId === group.groupId
            && x.isHidden !== true)
            .map((groupField, i) => 
                <div key={`group-${i}`}>
                    <FieldRow groupField={groupField}/>
                </div>
            )
        }

        {/* Parent Data - Stamps in Groups */}
            {data?.textStamps?.filter(x => x.page === pageNumber 
                && x.groupId === group.groupId
                && x.stampStyle !=="overflow"
                && x.isHidden !== true
                )
                .sort((a, b) => a.top - b.top)
                .map((x, i) =>
                    <div key={`parentgroup-${i}`}>
                        <TextStampRow 
                        parentDisabled={parentDisabled}
                            canvas={canvas} ctx={ctx} stamp={x} />
                    </div>
            )}

            {data?.lines?.filter(x => x.page === pageNumber 
                && x.groupId === group.groupId 
                && x.isHidden !== true)
                .sort((a, b) => a.top - b.top)
                .map((x, i) =>
                    <div key={`grandparentgroup-${i}`}>
                        <LineStampRow 
                            parentDisabled={parentDisabled}
                            values={values} 
                            canvas={canvas} 
                            ctx={ctx} 
                            stamp={x} 
                            parentGroup={parentGroup} 
                            setFieldValue={setFieldValue}
                        />
                    </div>
                )
            }
        </>
    )
}

export default FormGroup;