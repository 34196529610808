import {getUser, getAccessToken } from './authProvider';

export async function getUserInfo() {
	const user = await getUser();
	if (user === null) {
		return null;
	}
	var claims = {
		id: user.sub,
		fullName: user.given_name + ' ' + user.family_name,
		firstName: user.given_name,
		lastName: user.family_name,
		jobTitle: user.job_title,
		companyName: user.companyName,
		addressline1: user.streetAddress,
		address: user.streetAddress + ", " + user.city + ", " + user.postalCode,
		city: user.city,
		postcode: user.postalCode,
		rcvs: user.rcvsNumber,
		practiceName: user.practiceName,
		email: user.signInName,
		appTenants: user.appTenants,
		tenantName: user.tenantName,
		manufacturerId: user.relatedManufacturerIds,
		exp: user.exp
	};
	return claims;
}

export async function getUserTenantMemberships() {
	const user = await getUser();
	return user.appTenants;
}

export function getUserRoles() {
	const user = getUser();
	return user.roles;
}

export async function getAndCheckForUserRole(roleName) {
	const roles = await getUserRoles();
	var res =  isUserTypeInRoles(roles, roleName);
	return res;
}

export async function isInUserRole(roleName) {
	const roles = await getUserRoles();
	const found = roles.find(x => x == roleName );
	if(found!= undefined && found != null){
		return true;
	}
	return false;
}


Object.flatten = function(data) {
    var result = {};
    function recurse (cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
             for(var i=0, l=cur.length; i<l; i++)
                 recurse(cur[i], prop + "[" + i + "]");
            if (l == 0)
                result[prop] = [];
        } else {
            var isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop+"."+p : p);
            }
            if (isEmpty && prop)
                result[prop] = {};
        }
    }
    recurse(data, "");
    return result;
}

function isUserTypeInRoles(roles, roleName) {
	if (typeof (roles) === "undefined" || roles === null || roles.length === 0) {
		return false;
	}
	const matchFound = roles.find(item => { 
		return item === roleName 
	});
	if(matchFound != undefined){
		console.info("Found role '" + roleName );
	}
	return matchFound;
}

export async function isAdmin() {
	const roles = await getUserRoles();
	return isUserTypeInRoles(roles, "admin");
}
export async function isRetailer() {
	const roles = await getUserRoles();
	return isUserTypeInRoles(roles, "retailer");
}
export async function isManufacturer() {
	const roles = await getUserRoles();
	return isUserTypeInRoles(roles, "manufacturer");
}
export async function isManufacturerAdmin() {
	const roles = await getUserRoles();
	return isUserTypeInRoles(roles, "manufactureradmin");
}
export async function isVet() {
	const roles = await getUserRoles();
	return isUserTypeInRoles(roles, "vet");
}
export async function isCertifyingOfficer() {
	const roles = await getUserRoles();
	return isUserTypeInRoles(roles, "certifyingofficer");
}
export async function isPlatformCertifier() {
	const roles = await getUserRoles();
	return isUserTypeInRoles(roles, "platformcertifier");
}




