import React, { useState, useEffect } from 'react';
import { Routes } from "../../../routes";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Formik, Form } from 'formik';
import FormController from "../common/FormController"
import ProductDataService from "../../services/ProductService";
import ManufacturerDataService from "../../services/ManufacturerService";
import FormSubmission from "../common/FormSubmission";
import { Button, Card, Col, Form as BSForm } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import VisibilityService from '../../services/VisibilityService';
import ActivityService from '../../services/ActivityService';
import moment from "moment-timezone";

function ProductAddPage(props) {

    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState(null);
    const [apiValues, setApiValues] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [ready, setReady] = useState(false);
    const [manufacturers, setManufacturers] = useState([]);
    const [visibilityOptions, setVisibilityOptions] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');
    const [title, setTitle] = useState("Add Product");


    const isGEFSOptions = [
        { group: "", fieldname: "isGEFS", value: true, key: "Yes - GEFS" },
        { group: "", fieldname: "isGEFS", value: false, key: "No - Non GEFS" },
    ];

    const storageDeclarationOptions = [
        { group: "", fieldname: "storageDeclaration", value: true, key: "Yes" },
        { group: "", fieldname: "storageDeclaration", value: false, key: "No" },
    ];

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: editing ? apiValues.productName : ('Add Product'), link: editing ? Routes.Product.path.replace(":id", productId) : '#' },
        item3: { name: editing ? ('Edit Product') : null, link: '#' },
    };

    const initialValues = {
        active: true,
        visibility: apiValues?.visibilityName ?? '',
        productName: apiValues?.productName ?? '',
        productCode: apiValues?.productCode ?? '',
        ean: apiValues?.ean ?? '',
        manufacturerId: apiValues?.manufacturerId ?? '',
        imageFile: apiValues?.imageFile ?? '',
        timeLengthDelivered: apiValues?.timeLengthDelivered ?? null,
        isGEFS: apiValues?.isGEFS ?? false,
        isStorageDeclaration: apiValues?.isStorageDeclaration ?? false,
    }

    let validationMaxLength = "Exceeded maximum length allowed for this field";

    const validationSchema = Yup.object().shape({
        productName: Yup.string().required("Must enter product name").max(150, validationMaxLength),
        productCode: Yup.string().required("Must enter product code").max(50, validationMaxLength),
        // ean: Yup.string().required("Must enter an EAN"),
        ean: Yup.string().max(300, validationMaxLength).required("Must enter an EAN").test('checkEanUnique', 'This EAN is already existing.', function (value) {
            return new Promise((resolve, reject) => {
                if (editing && value == apiValues.ean) {
                    resolve(true);
                }
                else {
                    ProductDataService.hasMatchingEan(value).then(res => {
                        resolve(res.data ? false : true)
                    });
                }
            })
        }
        ),
        manufacturerId: Yup.object().required("Must choose a manufacturer"),
        isStorageDeclaration: Yup.bool()
            .when("isGEFS", {
                is: 0,
                then: Yup.bool().required(1, "Must select if this product is a storage declaration")
            }),
        isGEFS: Yup.bool().required("Must select if this product is for GEFS"),
        visibility: Yup.string().required(),
        timeLengthDelivered: Yup.string().nullable().required("Must specify a date"),

    });

    async function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(async () => {
            const formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            !editing && formData.append('id', productId);

            if (values.isGEFS) {
                formData.set('isStorageDeclaration', false);
            }

            formData.append('visibilityName', values.visibility);
            formData.append('imageFile', selectedFile);
            formData.set('manufacturerId', values.manufacturerId.value);

            if (!editing) {
                await ProductDataService.create(formData)
                    .then(async response => {
                        //Create Activity Log
                        await ActivityService.log(
                            'Added a product',
                            'A product named ' + values.productName + ' has been added',
                            'Product',
                            'Create',
                            Routes.Product.path.replace(":id", response.data.id),
                            response.data.id
                        );
                        setProductId(response.data.id);
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
            else {
                await ProductDataService.update(productId, formData)
                    .then(async response => {
                        //Create Activity Log
                        await ActivityService.log(
                            'Edited a product',
                            'A product named ' + values.productName + ' has been edited',
                            'Product',
                            'Update',
                            Routes.Product.path.replace(":id", response.data.id),
                            response.data.id
                        );
                        resetForm({});
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            setFormSubmitted(true);
        }, 1000);
    }

    useEffect(() => {
        async function fetchListData() {
            try {

                if (props.match.params.id === null || props.match.params.id === undefined) {
                    setTitle("Add Product");
                    setEditing(false);
                    setProductId(uuid());
                }
                else {
                    setTitle("Edit Product");
                    setEditing(true);
                    setProductId(props.match.params.id);
                }

                const getVisibilityData = new Promise((resolve, reject) => {
                    resolve(VisibilityService.getAll()
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "value": d.name,
                                "key": d.name + " - " + d.notes,
                                "label": d.name + " - " + d.notes,
                                "group": "visibility",
                                "fieldname": `visibility`,
                            })).sort((a, b) => a.label.localeCompare(b.label));
                            setVisibilityOptions(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });

                const getManufacturerData = new Promise((resolve, reject) => {
                    resolve(ManufacturerDataService.getAll()
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "value": d.id,
                                "label": d.manufacturerName
                            })).sort((a, b) => a.label.localeCompare(b.label));
                            setManufacturers(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                Promise.all([getVisibilityData, getManufacturerData]).then((responses) => {
                    setReady(true);
                });
            } catch (e) {
                console.error(e);
            }
        };
        fetchListData();
    }, []);

    useEffect(() => {
        if (ready) {
            ProductDataService.getById(productId)
                .then(response => {
                    var res = response.data.data;
                    if (res != null) {
                        if (res?.manufacturerId !== null && manufacturers != "") {
                            const selectedManufacturer = manufacturers.find(x => x.value === response.data.data.manufacturerId);
                            res.manufacturerId = selectedManufacturer;
                        }
                        setProductName(res?.productName);
                        setApiValues(res);
                    }

                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, [ready]);

    const formatDate = (date) => {
        return (date && moment(date, "YYYY-MM-DD").isValid()) ? moment(date).format("DD/MM/YYYY") : "";
    }

    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>
            {formSubmitted ? (
                <FormSubmission
                    errors={errors}
                    title={(editing ? "Edited" : "Added") + " Product"}
                    message={`This product has been successfully ${(editing ? "updated" : "added")}.`}
                    successButtonUrl={Routes.Product.path.replace(":id", productId)}
                    successButtonName="Back to product" />
            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Col md={8}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Body>
                                        <Form>
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Product Name"
                                                name="productName"
                                                isrequired="true"
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Product Code"
                                                name="productCode"
                                                isrequired="true"
                                                col={6}
                                            />
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="EAN"
                                                name="ean"
                                                isrequired="true"
                                                col={6}
                                            />
                                            <FormController
                                                control="multiselect"
                                                name="manufacturerId"
                                                label="Manufacturer"
                                                placeholder="Select a manufacturer"
                                                options={manufacturers}
                                                isMulti={false}
                                                isClearable={true}
                                                onChange={props.setFieldValue}
                                                onBlur={props.setFieldTouched}
                                                touched={props.touched.manufacturerId}
                                                error={props.errors.manufacturerId}
                                                backspaceRemovesValue={true}
                                                col={8}
                                                isrequired="true"
                                            />
                                            <FormController
                                                label="Length of time being delivered"
                                                control="dateInput"
                                                name="timeLengthDelivered"
                                                isrequired="true"
                                                value={props.values?.timeLengthDelivered ? moment(props.values?.timeLengthDelivered).format("DD/MM/YYYY") : ""}
                                                col={6}
                                            />
                                            <FormController
                                                control="radio"
                                                label="Is this a product for GEFS (Group Export Faciliatation Scheme)?"
                                                name="isGEFS"
                                                isrequired="true"
                                                options={isGEFSOptions}
                                                col={6}
                                            />

                                            {props.values.isGEFS.toString() === "false" && (
                                                <FormController
                                                    control="radio"
                                                    label="Is this a storage declaration only?"
                                                    name="isStorageDeclaration"
                                                    isrequired="true"
                                                    defaultValue={false}
                                                    options={storageDeclarationOptions}
                                                    col={6}
                                                />
                                            )}
                                            <FormController
                                                control="radio"
                                                label="Visibility"
                                                name="visibility"
                                                isrequired="true"
                                                options={visibilityOptions}
                                                col={6}
                                            />

                                            {/*////// SUBMIT /////////*/}
                                            <div className="form-group mt-3">
                                                <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default ProductAddPage;