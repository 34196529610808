import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faChartArea, faChartBar, faChartLine, faFlagUsa, faFolderOpen, faGlobeEurope, faPaperclip, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faAngular, faBootstrap, faReact, faVuejs } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar, Carousel, CloseButton } from '@themesberg/react-bootstrap';
// import { CircleChart, BarChart, SalesValueChart, SalesValueChartphone } from "./Charts";
import ProductImageService from "../pages/services/ProductImageService";
import ConsignmentImageService from "../pages/services/ConsignmentImageService";
import { Routes } from "../routes";
import Profile1 from "../assets/img/team/profile-picture-1.jpg";
import ProfileCover from "../assets/img/profile-cover.jpg";
import NoticeboardService from '../pages/services/NoticeboardService';

export const ProfileCardWidget = () => {
  return (
    <Card border="light" className="text-center p-0 mb-4">
      <div style={{ backgroundImage: `url(${ProfileCover})` }} className="profile-cover rounded-top" />
      <Card.Body className="pb-5">
        <Card.Img src={Profile1} alt="Neil Portrait" className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" />
        <Card.Title>Neil Sims</Card.Title>
        <Card.Subtitle className="fw-normal">Senior Software Engineer</Card.Subtitle>
        <Card.Text className="text-gray mb-4">New York, USA</Card.Text>

        <Button variant="primary" size="sm" className="me-2">
          <FontAwesomeIcon icon={faUserPlus} className="me-1" /> Connect
        </Button>
        <Button variant="secondary" size="sm">Send Message</Button>
      </Card.Body>
    </Card>
  );
};

export const ShowPhotoWidget = (props) => {
  const { title, photo } = props;

  return (
   
            <Image width="100%" fluid rounded src=
            {
              (photo!==null?
                photo:"data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_17b596cd2f8%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_17b596cd2f8%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2260.8046875%22%20y%3D%2295.4046875%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
              )
            } />

  );
};


export const ProductImageCarouselWidget = (props) => {
  const { title, photo, productImages } = props;

  return (
    
<>
{/* 
{JSON.stringify(props)} */}
        {productImages< 1?(
          <>
            <div className="text-center pt-5"><h5 className="text-gray-600">No images<br/>have been uploaded yet</h5></div>
          </>
        ):
        (
      <Carousel variant="dark"  className="img-responsive"
     // fade
      controls={productImages.length>0 ? true : false} indicators={productImages.length>0? true : false}
      >
        {productImages.map(file => (
          <Carousel.Item className="img-fluid">
            <img
              className="carousel-image"
              src={file.fileUrl}
              alt="product image"
            />
            </Carousel.Item>
        ))}
      </Carousel>
        )
}
      </>

  );
};




export const ProductImageViewWidget = (props) => {
  const { title, photo, productImages } = props;
  
  function remove (imageId, index){
    ProductImageService.remove(imageId)
    .then(response => {
        console.log(response.data);
        productImages.splice(index, 1);
        window.location.reload();
    })
    .catch(e => {
        console.log(e);
    });
  };
  return (
    <Row>
  
{/* 
{JSON.stringify(props)} */}
        {productImages< 1?(
          <>
            <div className="text-center pt-5"><h5 className="text-gray-600">No images<br/>have been uploaded yet</h5></div>
          </>
        ):
        (
          productImages.map((file,i) => (
          <Col md={4} className="position-relative">
          <Image responsive className="img-upload-preview img-thumbnail img-fluid" alt="image" src={file.fileUrl} />
          <a href="#"  className="position-absolute end-0 mx-3" onClick={() => remove(file.imageId, i)}>
          <CloseButton  />
          </a>
          
          {/* <div className="text text-truncate "><span>{(file).fileUrl}</span>< br/><small>{formatBytes(file.size, null)},</small></div> */}
        </Col>

        ))
        )
}
</Row>
  );
};

export const ConsignmentSealImageViewWidget = (props) => {
  const { title, photo, sealImages } = props;
  
  function remove (imageId, index){
    ConsignmentImageService.remove(imageId)
    .then(response => {
        console.log(response.data);
        sealImages.splice(index, 1);
        window.location.reload();
    })
    .catch(e => {
        console.log(e);
    });
  };
  return (
    <Row>
  

        {sealImages< 1?(
          <>
            <div className="text-center pt-5"><h5 className="text-gray-600">No images<br/>have been uploaded yet</h5></div>
          </>
        ):
        (
          sealImages.filter(file => file.seal).map((file, i) => (
            <Col md={4} className="position-relative">
              <Image responsive className="img-upload-preview img-thumbnail img-fluid" alt="image" src={file.fileUrl} />
              <a href="#" className="position-absolute end-0 mx-3" onClick={() => remove(file.imageId, i)}>
                <CloseButton />
              </a>
            </Col>

        ))
        )
}
</Row>
  );
};

export const ConsignmentContainerImageViewWidget = (props) => {
  const { title, photo, containerImages } = props;
  
  function remove (imageId, index){
    ConsignmentImageService.remove(imageId)
    .then(response => {
        console.log(response.data);
        containerImages.splice(index, 1);
        window.location.reload();
    })
    .catch(e => {
        console.log(e);
    });
  };
  return (
    <Row>
  

        {containerImages< 1?(
          <>
            <div className="text-center pt-5"><h5 className="text-gray-600">No images<br/>have been uploaded yet</h5></div>
          </>
        ):
        (
          containerImages.filter(file => file.container).map((file, i) => (
            <Col md={4} className="position-relative">
              <Image responsive className="img-upload-preview img-thumbnail img-fluid" alt="image" src={file.fileUrl} />
              <a href="#" className="position-absolute end-0 mx-3" onClick={() => remove(file.imageId, i)}>
                <CloseButton />
              </a>
            </Col>

        ))
        )
}
</Row>
  );
};

export const CounterWidget = (props) => {
  const { icon, iconColor, category, title, period, percentage } = props;
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={4} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <div className={`icon icon-shape icon-md icon-${iconColor} rounded me-4 me-sm-0`}>
              <FontAwesomeIcon icon={icon} />
            </div>
            <div className="d-sm-none">
              <h5>{category}</h5>
              <h3 className="mb-1">{title}</h3>
            </div>
          </Col>
          <Col xs={12} xl={8} className="px-xl-0">
            <div className="d-none d-sm-block">
              <h5>{category}</h5>
              <h3 className="mb-1">{title}</h3>
            </div>
            {/* <small>{period}, <FontAwesomeIcon icon={faGlobeEurope} size="xs" /> WorldWide</small> */}
            {/* <div className="small mt-2">
              <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
              <span className={`${percentageColor} fw-bold`}>
                {percentage}%
              </span> Since last month
            </div> */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

// export const CircleChartWidget = (props) => {
//   const { title, data = [] } = props;
//   const series = data.map(d => d.value);

//   return (
//     <Card border="light" className="shadow-sm">
//       <Card.Body>
//         <Row className="d-block d-xl-flex align-items-center">
//           <Col xs={12} xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
//             <CircleChart series={series} />
//           </Col>
//           <Col xs={12} xl={7} className="px-xl-0">
//             <h5 className="mb-3">{title}</h5>

//             {data.map(d => (
//               <h6 key={`circle-element-${d.id}`} className="fw-normal text-gray">
//                 <FontAwesomeIcon icon={d.icon} className={`icon icon-xs text-${d.color} w-20 me-1`} />
//                 {` ${d.label} `}{`${d.value}%`}
//               </h6>
//             ))}
//           </Col>
//         </Row>
//       </Card.Body>
//     </Card>
//   );
// };

// export const BarChartWidget = (props) => {
//   const { title, value, percentage, data = [] } = props;
//   const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
//   const series = data.map(d => d.value);
//   const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
//   const percentageColor = percentage < 0 ? "text-danger" : "text-success";

//   return (
//     <Card border="light" className="shadow-sm">
//       <Card.Body className="d-flex flex-row align-items-center flex-0 border-bottom">
//         <div className="d-block">
//           <h6 className="fw-normal text-gray mb-2">{title}</h6>
//           <h3>{value}</h3>
//           <small className="mt-2">
//             <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
//             <span className={`${percentageColor} fw-bold`}>
//               {percentage}%
//             </span>
//           </small>
//         </div>
//         <div className="d-block ms-auto">
//           {data.map(d => (
//             <div key={`bar-element-${d.id}`} className="d-flex align-items-center text-end mb-2">
//               <span className={`shape-xs rounded-circle bg-${d.color} me-2`} />
//               <small className="fw-normal">{d.label}</small>
//             </div>
//           ))}
//         </div>
//       </Card.Body>
//       <Card.Body className="p-2">
//         <BarChart labels={labels} series={series} />
//       </Card.Body>
//     </Card>
//   );
// };

export const ConsignmentsWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">Consignments</h5>
      <Button variant="light" size="sm" href={Routes.ConsignmentListingPage.path}>See all</Button>
    </Card.Header>
      <Card.Body>
        <span>Create consignments and export health certificates.</span>
        <div className="d-none">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};


export const CertificatesWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">Certificate Bank</h5>
      <Button variant="light" size="sm" href={Routes.CertificateListingPage.path}>See all</Button>
    </Card.Header>
      <Card.Body>
        <span>Upload and view certificates in the bank.</span>
        <div className="d-none">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const AttestationsWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">Attestations</h5>
      <Button variant="light" size="sm" href={Routes.AttestationDashboard.path}>See all</Button>
    </Card.Header>
      <Card.Body>
        <span>View and create daily and monthly attestations.</span>
        <div className="d-none">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const VetsWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">Vets</h5>
      <Button variant="light" size="sm" href={Routes.Vets.path}>See all</Button>
    </Card.Header>
      <Card.Body>
        <span>View, add and edit vets.</span>
        <div className="d-none">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="d-block">
              <label className="mb-0">Currently listed</label>
              <h2 className="mb-0">52</h2>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

  export const SystemUpdatesWidget = (props) => {
    const [notices, setNotices] = useState([]);
    const Notices = (props) => {
      //const { title, description, url, color ="red", icon="", last=""} = props;
      const { item} = props;
      // const extraClassName = last ? "" : "mb-2";
  
      return (
        <Row className={`align-items-center`}>
          <Col xs="auto">
            {/* <span className={`icon icon-md text-${color}`}>
              <FontAwesomeIcon icon={icon} className="me-1" />
            </span> */}
          </Col>
          <Col>
          {item.title}
{/* 
{JSON.stringify(props)} */}
            {/* <div className="progress-wrapper">
              <div className="progress-info">
                <h6 className="mb-0">{title}</h6>
                <small className="fw-bold text-dark">
                  <span>{percentage} %</span>
                </small>
              </div>
              <ProgressBar variant={color} now={percentage} min={0} max={100} />
            </div> */}
          </Col>
        </Row>
      );
    };
  
	useEffect(() => {
    NoticeboardService.getAll()
      .then(x => {
        setNotices(x.data)
      })
      .catch(error => {
        alert(JSON.stringify(error))
      });
    }, []);

    return (
      <Card border="light" className="shadow-sm">
        
        <Card.Header className="border-bottom border-light d-flex justify-content-between">
          <h5 className="mb-0">System notices</h5>
        </Card.Header>

        {notices?.map((item, index) => (  
          <Notices item={item}
            {...item}
          />
          ))}
      
        {/* <Card.Body>
          <Progress title="Awaiting attestations" color="purple" icon={faBootstrap} percentage={34} />
          <Progress title="With Attetstaions" color="danger" icon={faAngular} percentage={60} />
          <Progress title="With EHC's" color="tertiary" icon={faVuejs} percentage={45} />
          <Progress title="Expiring Attestationstellar - Dashboard" color="info" icon={faReact} percentage={35} />
          <Progress last title="Incomplete" color="purple" icon={faBootstrap} percentage={34} /> 
        </Card.Body> */}
      </Card>
    );
  };
export const ProgressTrackWidget = () => {
  const Progress = (props) => {
    const { title, percentage, icon, color, last = false } = props;
    const extraClassName = last ? "" : "mb-2";

    return (
      <Row className={`align-items-center ${extraClassName}`}>
        <Col xs="auto">
          <span className={`icon icon-md text-${color}`}>
            <FontAwesomeIcon icon={icon} className="me-1" />
          </span>
        </Col>
        <Col>
          <div className="progress-wrapper">
            <div className="progress-info">
              <h6 className="mb-0">{title}</h6>
              <small className="fw-bold text-dark">
                <span>{percentage} %</span>
              </small>
            </div>
            <ProgressBar variant={color} now={percentage} min={0} max={100} />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">Products</h5>
        <Button variant="secondary" size="sm">See all</Button>
      </Card.Header>

      <Card.Body>

        {/* <Progress title="Awaiting attestations" color="purple" icon={faBootstrap} percentage={34} />
        <Progress title="With Attetstaions" color="danger" icon={faAngular} percentage={60} />
        <Progress title="With EHC's" color="tertiary" icon={faVuejs} percentage={45} />
        <Progress title="Expiring Attestationstellar - Dashboard" color="info" icon={faReact} percentage={35} />
        <Progress last title="Incomplete" color="purple" icon={faBootstrap} percentage={34} /> */}
      </Card.Body>
    </Card>
  );
};

export const RankingWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
          <div>
            <h6><FontAwesomeIcon icon={faGlobeEurope} className="icon icon-xs me-3" />Attestations</h6>
          </div>
          <div>
            <Card.Link href="#" className="text-primary fw-bold">
              #755 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between border-bottom border-light py-3">
          <div>
            <h6 className="mb-0"><FontAwesomeIcon icon={faFlagUsa} className="icon icon-xs me-3" />Country Rank</h6>
            <div className="small card-stats">
              United States <FontAwesomeIcon icon={faAngleUp} className="icon icon-xs text-success ms-2" />
            </div>
          </div>
          <div>
            <Card.Link href="#top" className="text-primary fw-bold">
              #32 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between pt-3">
          <div>
            <h6 className="mb-0"><FontAwesomeIcon icon={faFolderOpen} className="icon icon-xs me-3" />Category Rank</h6>
            <Card.Link href="#top" className="small card-stats">
              Travel &gt; Accomodation
            </Card.Link>
          </div>
          <div>
            <Card.Link href="#top" className="text-primary fw-bold">
              #16 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

// export const SalesValueWidget = (props) => {
//   const { title, value, percentage } = props;
//   const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
//   const percentageColor = percentage < 0 ? "text-danger" : "text-success";

//   return (
//     <Card className="bg-secondary-alt shadow-sm">
//       <Card.Header className="d-flex flex-row align-items-center flex-0">
//         <div className="d-block">
//           <h5 className="fw-normal mb-2">
//             {title}
//           </h5>
//           <h3>${value}</h3>
//           <small className="fw-bold mt-2">
//             <span className="me-2">Yesterday</span>
//             <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
//             <span className={percentageColor}>
//               {percentage}%
//             </span>
//           </small>
//         </div>
//         <div className="d-flex ms-auto">
//           <Button variant="secondary" size="sm" className="me-2">Month</Button>
//           <Button variant="primary" size="sm" className="me-3">Week</Button>
//         </div>
//       </Card.Header>
//       <Card.Body className="p-2">
//         <SalesValueChart />
//       </Card.Body>
//     </Card>
//   );
// };

// export const SalesValueWidgetPhone = (props) => {
//   const { title, value, percentage } = props;
//   const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
//   const percentageColor = percentage < 0 ? "text-danger" : "text-success";

//   return (
//     <Card className="bg-secondary-alt shadow-sm">
//       <Card.Header className="d-md-flex flex-row align-items-center flex-0">
//         <div className="d-block mb-3 mb-md-0">
//           <h5 className="fw-normal mb-2">
//             {title}
//           </h5>
//           <h3>${value}</h3>
//           <small className="fw-bold mt-2">
//             <span className="me-2">Yesterday</span>
//             <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
//             <span className={percentageColor}>
//               {percentage}%
//             </span>
//           </small>
//         </div>
//         <div className="d-flex ms-auto">
//           <Button variant="secondary" size="sm" className="me-2">Month</Button>
//           <Button variant="primary" size="sm" className="me-3">Week</Button>
//         </div>
//       </Card.Header>
//       <Card.Body className="p-2">
//         <SalesValueChartphone />
//       </Card.Body>
//     </Card>
//   );
// };

export const ManufacturersWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">Manufactuers</h5>
      <Button variant="light" size="sm" href={Routes.ManufacturersListingPage.path}>See all</Button>
    </Card.Header>
      <Card.Body>
        <span>View, add and edit manufacturers.</span>
        <div className="d-none">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="d-block">
              <label className="mb-0">Currently listed</label>
              <h2 className="mb-0">52</h2>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const ProductsWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">Products</h5>
      <Button variant="light" size="sm" href={Routes.Products.path} >See all</Button>
    </Card.Header>
      <Card.Body>
        <span>View, add and edit products.</span>
        <div className="d-none">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="d-block">
              <label className="mb-0">Currently listed</label>
              <h2 className="mb-0">3</h2>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};


export const PlatformsWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">Platforms</h5>
      <Button variant="light" size="sm" href={Routes.Platforms.path}>See all</Button>
    </Card.Header>
      <Card.Body>
        <span>View, add and edit platforms.</span>
        <div className="d-none">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="d-block">
              <label className="mb-0">Currently listed</label>
              <h2 className="mb-0">3</h2>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};


export const StorageSupplierDeclarationsWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">Storage Declarations - Supplier</h5>
      <Button variant="light" size="sm" href={Routes.SupportAttestations.path}>See all</Button>
    </Card.Header>
      <Card.Body>
        <span>View supplier storage declarations and create exporter declarations.</span><div className="d-block">
          {/* <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="d-block">
              <label className="mb-0">Awaiting response</label>
              <h2 className="mb-0">3</h2>
            </div>
          </div> */}
        </div>
      </Card.Body>
    </Card>
  );
};

export const StorageExporterDeclarationsWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">Storage Declarations - Exporter</h5>
      <Button variant="light" size="sm" href={Routes.SupportAttestations.path}>See all</Button>
    </Card.Header>
      <Card.Body>
        <span>View exporter storage declarations.</span><div className="d-block">
          {/* <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="d-block">
              <label className="mb-0">Awaiting response</label>
              <h2 className="mb-0">3</h2>
            </div>
          </div> */}
        </div>
      </Card.Body>
    </Card>
  );
};


export const SupportAttestationsWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">Support Attestation Requests</h5>
      <Button variant="light" size="sm" href={Routes.SupportAttestations.path}>See all</Button>
    </Card.Header>
      <Card.Body>
        <span>View and respond to Support Attestation Requests.</span><div className="d-block">
          {/* <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="d-block">
              <label className="mb-0">Awaiting response</label>
              <h2 className="mb-0">3</h2>
            </div>
          </div> */}
        </div>
      </Card.Body>
    </Card>
  );
};

export const UserGuidesWidget = () => {
  return (
    <Card border="light" className="d-none shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">User Guides</h5>
      <Button variant="light" size="sm" href={Routes.UserGuides.path}>See all</Button>
    </Card.Header>
      <Card.Body>
        <span>View, add and edit user guides.</span>
        <div className="d-none">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
            </div>
            <div className="d-block">
              
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
export const SitesWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
    <Card.Header className=" d-flex justify-content-between">
      <h5 className="mb-0">Sites</h5>
      <Button variant="light" size="sm" href={Routes.Sites.path}>See all</Button>
    </Card.Header>
      <Card.Body>
        <span>View, add and edit sites.</span>
        <div className="d-none">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="d-block">
              <label className="mb-0">Currently listed</label>
              <h2 className="mb-0">218s</h2>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};