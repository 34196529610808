export const health_1_H = { 
    label: "it fulfils the requirements of Commission Regulation (EC) No 1688/2005." 
}
export const animal_2_2_A_statements = [

    { group: "animal_2_2_A_statements", fieldname: "animal_2_2_A_statements", value: "1", key: "<strong><u>vaccination against highly pathogenic avian influenza is not carried out;</strong></u>"},
    { group: "animal_2_2_A_statements", fieldname: "animal_2_2_A_statements", value: "2", key: "vaccination against highly pathogenic avian influenza is carried out in accordance with a vaccination programme that complies with the requirements set out in Annex XIII to Delegated Regulation (EU) 2020/692;"},
]
export const animal_2_2_B_statements = [
    { group: "animal_2_2_B_statements", fieldname: "animal_2_2_B_statements", value: "1", key: "<strong><u>vaccination against infection with Newcastle disease virus</strong></u> with vaccines which <strong><u>do not comply</strong></u> with both the general and specific criteria of Annex XV to Delegated Regulation (EU) 2020/692 is prohibited;"},
    { group: "animal_2_2_B_statements", fieldname: "animal_2_2_B_statements", value: "2", key: "vaccination against infection with Newcastle disease virus with vaccines which comply only with the general criteria of Annex XV to Delegated Regulation (EU) 2020/692 is not prohibited, and the fresh meat has been obtained from poultry which:"},
]
export const animal_2_4_A_statements = [
    { group: "animal_2_4_A_statements", fieldname: "animal_2_4_A_statements", value: "1", key: "have remained in the zone referred to in point II.2.1 since hatching and until the date of slaughter;"},
    { group: "animal_2_4_A_statements", fieldname: "animal_2_4_A_statements", value: "2", key: "were imported into the zone referred to in point II.2.1 as day-old chicks, breeding poultry, productive poultry or poultry intended for slaughter, under animal health requirements that are at least as stringent as the relevant requirements of Regulation (EU) 2016/429 and Delegated Regulation (EU) 2020/692, from:"},
]
export const animal_2_4_A_a_statements = [
    { group: "animal_2_4_A_a_statements", fieldname: "animal_2_4_A_a_statements", value: "1", key: "a zone listed in Part 1 of Annex V to Implementing Regulation (EU) 2021/404 for entry into the Union of those categories of poultry;"},
    { group: "animal_2_4_A_a_statements", fieldname: "animal_2_4_A_a_statements", value: "2", key: "a Member State;"},
]
export const animal_2_4_B_statements = [
    { group: "animal_2_4_B_statements", fieldname: "animal_2_4_B_statements", value: "1", key: "have <strong><u>not been vaccinated against highly pathogenic</strong></u> avian influenza;"},
    { group: "animal_2_4_B_statements", fieldname: "animal_2_4_B_statements", value: "2", key: "have been vaccinated against highly pathogenic avian influenza in accordance with a vaccination programme which complies with the requirements set out in Annex XIII to Delegated Regulation (EU) 2020/692;"},
]
export const animal_2_4_C_statements = [
    { group: "animal_2_4_C_statements", fieldname: "animal_2_4_C_statements", value: "1", key: "have <strong><u>not been vaccinated against infection with Newcastle disease virus</strong></u> during the period of 30 days prior to the date of slaughter;"},
    { group: "animal_2_4_C_statements", fieldname: "animal_2_4_C_statements", value: "2", key: "have <strong><u>been vaccinated against infection with Newcastle disease virus</strong></u> in the period of 30 days prior to the date of slaughter, with vaccines that comply with both the general and specific criteria of Annex XV to Delegated Regulation (EU) 2020/692;"},
]
export const animal_2_4_F_statements = [
    { group: "animal_2_4_F_statements", fieldname: "animal_2_4_F_statements", value: "1", key: "did not pass through a zone not listed for entry into the Union of fresh meat of poultry other than ratites;"},
    { group: "animal_2_4_F_statements", fieldname: "animal_2_4_F_statements", value: "2", key: "passed through a zone/zones not listed for entry into the Union of fresh meat of poultry other than ratites provided, that conditions laid down in Article 124, point (e), of Delegated Regulation (EU) 2020/692 were complied with;"},
]
export const animal_2_8_statements = [
    { group: "animal_2_8_statements", fieldname: "animal_2_8_statements", value: "1", key: "it was packaged for further storage;", label: "has been strictly segregated from fresh meat not complying with the animal health requirements for the entry into the Union of fresh meat of poultry other than ratites throughout the operations of slaughter, cutting and until:"},
    { group: "animal_2_8_statements", fieldname: "animal_2_8_statements", value: "2", key: "its loading, as unpackaged fresh meat, to the means of transport for dispatch to the Union;"},
]
export const animal_2_10 = { 
    label: "is intended for a Member State or zone thereof which has been granted the status free from infection with Newcastle disease virus without vaccination in accordance with Commission Delegated Regulation (EU) 2020/689, and has been obtained from poultry which have not been vaccinated against infection with Newcastle disease virus with a live vaccine during the period of 30 days prior to the date of slaughter of the birds" 
}