import * as Yup from 'yup';
import moment from 'moment';
import formModel from './DailyAttestationModel';

const {
  formField: {
    requireManufacturer,
    birdFluCheck,
    manufacturer,
    manufacturerId,
    uniqueCode,
    productsSelected,
    dailyAttestationData,
    documentId,
    attestationId,
    batchNumberRange,
    batchNumbers,	    
    isDateCollectionProductionMulti = false, 
    dateCollectionProductionStart, 
    dateCollectionProductionEnd, 
    dateCollectionProductionSingle,
    signedRequestUrl,
    commercialNumber,
    numberOfPackages,
    deliveryDate,
    netWeight,
    grossWeight,
    abattoirApprovalNumbers,
    abattoirs,
    slaughterDates,
    slaughterDate,
    farmPost,
    validTo
  }
} = formModel;

// const today = new Date();
// const mockAttestationValidTo = Yup.ref(validTo); // Change this accordingly
// console.log(Yup.ref(validTo))

export default [
  Yup.object().shape({
  //First stage
    [requireManufacturer.name]: Yup.bool(),
    [manufacturer.name]: Yup.object().nullable()
        .when("requireManufacturer", {
                is: true,
                then: Yup.object().nullable().required(`${manufacturer.requiredErrorMsg}`),
            }),
    [deliveryDate.name]: Yup.string().required(`${deliveryDate.requiredErrorMsg}`),
    [commercialNumber.name]: Yup.string().nullable().required(`${commercialNumber.requiredErrorMsg}`),
    [productsSelected.name]: Yup.array().min(1,`${productsSelected.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    //Second stage
    [uniqueCode.name]: Yup.string().required(`${uniqueCode.requiredErrorMsg}`),
    [dailyAttestationData.name]: Yup.array().nullable()
    .of(
        Yup.object().shape({
          [attestationId.name]: Yup.string()
            .required(`${attestationId.requiredErrorMsg}`),
          [batchNumbers.name]: Yup.string()
            .when("hasBatches", {
                is: false,
                then: Yup.string().required(`${batchNumbers.requiredErrorMsg}`)
            }),
          [batchNumberRange.name]: Yup.array()
            .when("hasBatches", {
                is: true,
                then: Yup.array()
                    //.of(Yup.object().shape({"id": Yup.string().required(`${attestationId.requiredErrorMsg}`), "number": Yup.string().required(`${batchNumbers.requiredErrorMsg}`)}))
                    .min(1, `${batchNumbers.requiredErrorMsg}`),
            }),
          [isDateCollectionProductionMulti.name]: Yup.bool().required(isDateCollectionProductionMulti.requiredErrorMsg),
          [dateCollectionProductionSingle.name]: Yup.date()
          .when('isDateCollectionProductionMulti', {
            is: false,
            then: Yup.date()
              .test(
                'single-date-validation',
                function (value) {
                  const { validDates, validTo } = this.parent;
                  const today = moment().startOf('day'); // Today's date at the start of the day
        
                  // Parse validFrom and validTo using 'DD/MM/YYYY' format
                  const validFromDate = validDates ? moment(validDates, 'DD/MM/YYYY').startOf('day') : null;
                  const validToDate = validTo ? moment(validTo, 'DD/MM/YYYY').startOf('day') : null;
        
                  // Convert the value to a moment object for comparison
                  const valueDate = moment(value);
        
                  if(value && validFromDate && valueDate.isBefore(validFromDate)){
                    return this.createError({
                      message: `Date of production must not be earlier than valid from date (${validDates}).`
                    });
                  }
                  if(value && validToDate && valueDate.isAfter(validToDate)){
                    return this.createError({
                      message: `Date of production must not be later than valid to date (${validTo}).`
                    });
                  }
                  if(value && valueDate.isAfter(today)){
                    return this.createError({
                      message: `Date of production must not be later than today's date (${today.format('DD/MM/YYYY')}).`
                    });
                  }
                  return true;
                }
              )
              .required('Date of production is required.'),
          }),
          
          [dateCollectionProductionStart.name]: Yup.date()
            .when('isDateCollectionProductionMulti', {
              is: true,
              then: Yup.date()
                .test(
                  'start-date-validation',
                  function (value) {
                    const { dateCollectionProductionEnd, validDates, validTo } = this.parent;
          
                    const endDate = dateCollectionProductionEnd ? moment(dateCollectionProductionEnd, 'DD/MM/YYYY').startOf('day') : null;
                    const validFromDate = validDates ? moment(validDates, 'DD/MM/YYYY').startOf('day') : null;
                    const validToDate = validTo ? moment(validTo, 'DD/MM/YYYY').startOf('day') : null;
          
                    const valueDate = moment(value);
          
                    if(value && endDate && valueDate.isAfter(endDate)){
                      return this.createError({
                        message: `Start date must not be later than the end date.`
                      });
                    }
                    if(value && validFromDate && valueDate.isBefore(validFromDate)){
                      return this.createError({
                        message: `Start date must not be earlier than valid from date (${validDates}).`
                      });
                    }
                    if(value && validToDate && valueDate.isAfter(validToDate)){
                      return this.createError({
                        message: `Start date must not be later than valid to date (${validTo}).`
                      });
                    }
                    return true;
                  }
                )
                .required('Start date of collection/production is required.'),
            }),
          
          [dateCollectionProductionEnd.name]: Yup.date()
            .when('isDateCollectionProductionMulti', {
              is: true,
              then: Yup.date()
                .test(
                  'end-date-validation',
                  function (value) {
                    const { dateCollectionProductionStart, validDates, validTo } = this.parent;
          
                    const startDate = dateCollectionProductionStart ? moment(dateCollectionProductionStart, 'DD/MM/YYYY').startOf('day') : null;
                    const validFromDate = validDates ? moment(validDates, 'DD/MM/YYYY').startOf('day') : null;
                    const validToDate = validTo ? moment(validTo, 'DD/MM/YYYY').startOf('day') : null;
          
                    const valueDate = moment(value);
          
                    if(value && startDate && valueDate.isBefore(startDate)){
                      return this.createError({
                        message: `End date must not be earlier than the start date.`
                      });
                    }
                    if(value && validFromDate && valueDate.isBefore(validFromDate)){
                      return this.createError({
                        message: `End date must not be earlier than valid from date (${validDates}).`
                      });
                    }
                    if(value && validToDate && valueDate.isAfter(validToDate)){
                      return this.createError({
                        message: `End date must not be later than valid to date (${validTo}).`
                      });
                    }
                    return true;
                  }
                )
                .required('End date of production is required.'),
            }),
          
          [numberOfPackages.name]: Yup.number()
            .min(1,`${numberOfPackages.requiredErrorMsg}`)
            .required(`${numberOfPackages.requiredErrorMsg}`),
          [netWeight.name]: Yup.number()
            .typeError("Must enter a decimal")
            .required(`${netWeight.requiredErrorMsg}`)
            .min(0,"Must not be a negative number").test('is-decimal', netWeight.requiredErrorMsg,
            (value) => /^\d{1,30}(\.\d{0,3})?$/.test(value)),         
          [grossWeight.name]: Yup.number()
            .typeError("Must enter a decimal")
            .required(`${grossWeight.requiredErrorMsg}`)
            .min(0,"Must not be a negative number")
            .min(Yup
                .ref("netWeight"), 
                "Gross weight must not be less than net weight").test('is-decimal', grossWeight.requiredErrorMsg,
                (value) => /^\d{1,30}(\.\d{0,3})?$/.test(value)),         
          // [abattoirs.name]: Yup.string()
          //     .when("birdFluCheck", {
          //             is: true,
          //             then: Yup.string().required(`${abattoirs.requiredErrorMsg}`),
          //         }),
          [abattoirApprovalNumbers.name]: Yup.string()
              .when("birdFluCheck", {
                      is: true,
                      then: Yup.string().required(`${abattoirApprovalNumbers.requiredErrorMsg}`),
                  }),
          [farmPost.name]: Yup.string()
              .when("birdFluCheck", {
                      is: true,
                      then: Yup.string().required(`${farmPost.requiredErrorMsg}`),
                  }),
            [slaughterDates.name] : Yup.array().nullable()
            .when("birdFluCheck", {
                is: true,
                then:
                    Yup.array().of(
                        Yup.date().nullable().required(`${slaughterDate.requiredErrorMsg}`)
                    ).min(1,`${slaughterDate.requiredErrorMsg}`)
            })


      })).min(1,`${dailyAttestationData.requiredErrorMsg}`),
  }),

  Yup.object().shape({
  [dailyAttestationData.name]: Yup.array().required(`${dailyAttestationData.requiredErrorMsg}`),
  [signedRequestUrl.name]: Yup.string().required(`${signedRequestUrl.requiredErrorMsg}`),
  [documentId.name]: Yup.string().required(`${signedRequestUrl.requiredErrorMsg}`),

  })
];
