import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import Language files
import enTranslation from '../Data/Translations/en.json';
import frTranslation from '../Data/Translations/fr.json';
import bgTranslation from '../Data/Translations/bg.json';
import esTranslation from '../Data/Translations/es.json';
import csTranslation from '../Data/Translations/cs.json';
import daTranslation from '../Data/Translations/da.json';
import deTranslation from '../Data/Translations/de.json';
import etTranslation from '../Data/Translations/et.json';
import elTranslation from '../Data/Translations/el.json';
import gaTranslation from '../Data/Translations/ga.json';
import hrTranslation from '../Data/Translations/hr.json';
import itTranslation from '../Data/Translations/it.json';
import lvTranslation from '../Data/Translations/lv.json';
import ltTranslation from '../Data/Translations/lt.json';
import huTranslation from '../Data/Translations/hu.json';
import mtTranslation from '../Data/Translations/mt.json';
import nlTranslation from '../Data/Translations/nl.json';
import plTranslation from '../Data/Translations/pl.json';
import ptTranslation from '../Data/Translations/pt.json';
import slTranslation from '../Data/Translations/sl.json';
import roTranslation from '../Data/Translations/ro.json';
import fiTranslation from '../Data/Translations/fi.json';
import svTranslation from '../Data/Translations/sv.json';

//Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      //Add language files
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      bg: {
        translation: bgTranslation,
      },
      es: {
        translation: esTranslation,
      },
      cs: {
        translation: csTranslation,
      },
      da: {
        translation: daTranslation,
      },
      de: {
        translation: deTranslation,
      },
      et: {
        translation: etTranslation,
      },
      el: {
        translation: elTranslation,
      },
      ga: {
        translation: gaTranslation,
      },
      hr: {
        translation: hrTranslation,
      },
      it: {
        translation: itTranslation,
      },
      lv: {
        translation: lvTranslation,
      },
      lt: {
        translation: ltTranslation,
      },
      hu: {
        translation: huTranslation,
      },
      mt: {
        translation: mtTranslation,
      },
      nl: {
        translation: nlTranslation,
      },
      pl: {
        translation: plTranslation,
      },
      pt: {
        translation: ptTranslation,
      },
      sl: {
        translation: slTranslation,
      },
      ro: {
        translation: roTranslation,
      },
      fi: {
        translation: fiTranslation,
      },
      sv: {
        translation: svTranslation,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
