import React from 'react';
import FormController from "../../../common/FormController"

function MeatBseRisks(props) {
    const { readOnly, title, values, labels, formNumber } = props
    return (
        <>
            {/*/////// START - BSE Risks //////////////*/}
            <h4 className="mt-5">BSE risk category of country of origin {formNumber == 8384 || formNumber == 8390 || formNumber == 8396 || formNumber == 8383 ? '(The country in which the ' + title + ' was last processed)' : ''}</h4>
            <div>
                {values.BseRiskCategoryNegligible == 1 && (
                    <FormController
                        control="checkbox"
                        label="the country or region of origin is classified in accordance with Commission Decision 2007/453/EC as a country or region posing a <strong><u> negligible BSE risk</u></strong>, and"
                        name="BseRiskCategoryNegligible"
                        readOnly={readOnly}
                    />
                )}

                {//BSE Risk - Negligible
                    values.BseRiskCategoryNegligible && formNumber != 8368 && (
                        <div className="subform">
                            <FormController
                                control="checkboxClauses"
                                label=""
                                name="BseRiskCategoryNegligibleStatements"
                                options={labels.BseRiskCategoryNegligibleStatementsChoice}
                                readOnly={readOnly}
                            />
                        </div>
                    )}
                {//BSE Risk - Negligible
                    values.BseRiskCategoryNegligible && formNumber == 8368 && (
                        <div className="subform">
                            <FormController
                                control="radioClauses"
                                label=""
                                name="BseRiskCategoryNegligibleStatements"
                                options={labels.BseRiskCategoryNegligibleStatementsChoice}
                                readOnly={readOnly}
                            />
                        </div>
                    )}
                {values.BseRiskCategoryControlled == 1 && (
                    <FormController
                        control="checkbox"
                        label="the country or region of origin is classified in accordance with Decision 2007/453/EC as a country or region posing a <strong><u>controlled BSE risk</u></strong>,"
                        name="BseRiskCategoryControlled"
                        readOnly={readOnly}
                    />
                )}

                {//BSE Risk - Controlled
                    values.BseRiskCategoryControlled && (
                        <div className="subform">
                            {
                                values.BseRiskCategoryControlled && (formNumber == 8368 || formNumber == 8369) ?
                                    <FormController
                                        control="radioClauses"
                                        label=""
                                        name="BseRiskCategoryControlledStatements"
                                        options={labels.BseRiskCategoryControlledStatementsChoice}
                                        readOnly={readOnly}
                                    />
                                    :
                                    <FormController
                                        control="checkboxClauses"
                                        label=""
                                        name="BseRiskCategoryControlledStatements"
                                        options={labels.BseRiskCategoryControlledStatementsChoice}
                                        readOnly={readOnly}
                                    />
                            }
                            {//BSE Risk - Controlled
                                values.BseRiskCategoryControlled && formNumber == 8368 || formNumber == 8369 && (
                                    <FormController
                                        control="radioClauses"
                                        label=""
                                        name="BseControlledC"
                                        options={labels.BseRiskCategoryControlledChoice_C}
                                        readOnly={readOnly}
                                    />
                                )}
                        </div>
                    )}


                {values.BseRiskCategoryUndetermined == 1 && (
                    <FormController
                        control="checkbox"
                        label="the country or region of origin has not been classified in accordance with Decision 2007/453/EC or is classified as a country or region with an <strong><u> undetermined BSE risk</u></strong>"
                        name="BseRiskCategoryUndetermined"
                        readOnly={readOnly}
                    />
                )}

                {//BSE Risk - Undetermined
                    values.BseRiskCategoryUndetermined && (
                        <div className="subform">
                            <FormController
                                control="radioClauses"
                                label=""
                                name="BseRiskCategoryUndeterminedStatements"
                                options={labels.BseRiskCategoryUndeterminedStatementsChoice}
                                readOnly={readOnly}
                            />
                        </div>
                    )
                }
            </div>
            {/*/////// END - BSE Risks //////////////*/}
        </>
    );
};

export default MeatBseRisks;