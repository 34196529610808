import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row, Badge, Alert } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from 'react';
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes } from '../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import Preloader from "../../../components/Preloader";
import { Routes } from "../../../routes";
import AttestationService from '../../services/AttestationService';
import ManufacturerService from '../../services/ManufacturerService';
import PlatformService from '../../services/PlatformService';
import ProductDataService from "../../services/ProductService";
import SiteService from '../../services/SiteService';
import TemperatureService from "../../services/TemperatureService";
import ZoneService from '../../services/ZoneService';
import AttestationHistory from "../Attestations/AttestationHistory";
import CertificateFormPanels from "../CertificateForms/CertificateFormPanels";
import AttestationPanel from "./Components/AttestationPanel";
import ProductImages from './ProductImages';
import SupportingEvidence from "./SupportingEvidence";
import UnpermittedCertificateNumbersAlert from "./Components/UnpermittedCertificateNumbersAlert";
import ProductDetailsSummary from './Components/ProductDetailsSummary';
import ProductSummary from './Components/ProductSummary';
import StorageDeclarationHistory from '../StorageDeclaration/StorageDeclarationHistory';
import StorageDeclarationService from '../../services/StorageDeclarationService';
import StorageExporterDeclarationService from '../../services/StorageExporterDeclarationService';
import StorageDeclarationPanel from './Components/StorageDeclarationPanel';

const ProductPage  = (props) => {
    const [productId] = useState(props.match.params.id);
    const [visibility, setVisibility] = useState('');
    const [productDetail, setProductDetail] = useState([]);
    const [product, setProduct] = useState([]);
    const [isGEFS, setIsGEFS] = useState(false);
    const [isStorageDeclaration, setStorageDeclaration] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [productCountry, setProductCountry] = useState('');
    const [productZone, setProductZone] = useState('');
    const [siteName, setSiteName] = useState('');
    const [platform, setPlatform] = useState([]);
    const [temperature, setTemperature] = useState('');
    const [manufacturer, setManufacturerName] = useState([]);
    const [currentAttestation, SetCurrentAttestation] = useState('');
    const [currentStorageDeclaration, setCurrentStorageDeclaration] = useState('');
    const [attestations, setAttestations] = useState([]);
    // const [declarations, setDeclarations] = useState([]);
    const [exporterDeclarations, setExporterDeclarations] = useState([]);
    const [supplierDeclarations, setSupplierDeclarations] = useState([]);
    const [checkCompleted, setCheckCompleted] = useState(false);
    const [unpermittedCertificateNumbers, setUpermittedCertificateNumbers] = useState();

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product?.productName },
    };
  
    const [isComplete, setComplete] = useState(false);
    const productData = (childdata) => {
        setComplete(childdata);
    }
    
    const openProductEdit = () => {
        props.history.push(Routes.EditProduct.path.replace(":id", productId));
    };

    const openProductDetail = () => {
        props.history.push("/product/detail/" + productId);
    };

    
    
    useEffect(() => {
        getInitialData();
},[]);

function getInitialData(){
    const populateProductData = new Promise((resolve, reject) => {
        resolve(
            ProductDataService.getById(productId)
            .then(response => {
                 setProduct(response.data.data);
                 setIsGEFS(response.data.data.isGEFS);
                 setStorageDeclaration(response.data.data.isStorageDeclaration);
                     setVisibility(response.data.data.visibilityName);

                 var productResult = response.data.data;

                 ManufacturerService.getById(productResult.manufacturerId)
                    .then(response => {
                        var res = response.data;
                        setManufacturerName(res.manufacturerName);
                    })
                    .catch(e => {
                        console.log(e);
                    })
            })
            .catch((e) => {
                alert("Sorry there was an error");
                console.log(e);
            })
        );
    });

    const populateCurrentAttestationData = new Promise((resolve, reject) => {
        resolve(AttestationService.getCurrentByProductId(productId)
            .then(response => {
                var res = response.data;
                SetCurrentAttestation(res);
            })
            .catch(e => {
                console.log(e);
            })
        );
    });
    const populateCurrentStorageDeclarationData = new Promise((resolve, reject) => {
        resolve(StorageDeclarationService.getCurrentByProductId(productId)
            .then(response => {
                var res = response.data;
                setCurrentStorageDeclaration(res);
            })
            .catch(e => {
                console.log(e);
            })
        );
    });
    const populateAttestationsData = new Promise((resolve, reject) => {
        resolve(AttestationService.getAllByProduct(productId)
            .then(response => {
                var res = response.data;
                setAttestations(res);
            })
            .catch(e => {
                console.log(e);
            })
        );
    });
    const populateExporterDeclarationsData = new Promise((resolve, reject) => {
        resolve(StorageExporterDeclarationService.getAllByProduct(productId)
            .then(response => {
                var res = response.data;
                setExporterDeclarations(res);
            })
            .catch(e => {
                console.log(e);
            })
        );
    });
    const populateSupplierDeclarationsData = new Promise((resolve, reject) => {
        resolve(StorageDeclarationService.getAllByProduct(productId)
            .then(response => {
                var res = response.data;
                setSupplierDeclarations(res);
            })
            .catch(e => {
                console.log(e);
            })
        );
    });
    const populateProductDetailData = new Promise((resolve, reject) => {
        resolve(
            ProductDataService.getProductDetailByProductId(productId)
            .then(response => {
                var res = response.data;
                console.log(res);
                setProductDetail(res);
                if(res.countryOriginId!=null){
                    ZoneService.getById(res.countryOriginId)
                    .then(response => {
                        var res = response.data;
                        setProductCountry(res.countryName);
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }
                if(res.countryOriginId!=null){
                    ZoneService.getById(res.regionOriginId)
                    .then(response => {
                        var res = response.data;
                        setProductZone(res.isoCode);
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }

                //Get Platform Name
                if(res.platformId!=null){
                    PlatformService.getById(res.platformId)
                    .then(response => {
                        var res = response.data;
                        setPlatform(res.platformName);
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }


                //Get Site Name
                if(res.siteId!=null){
                    SiteService.getById(res.siteId)
                    .then(response => {
                        var res = response.data;
                        setSiteName(res.siteName);
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }
                //Get Temperature Name
                if(res.temperatureId!=null){
                    TemperatureService.getById(res.temperatureId)
                    .then(response => {
                        var res = response.data;
                        setTemperature(res.temperatureName);
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }
              
            })
            .catch(e => {
                console.log(e);
            })
        );
    });
    
Promise.all([ populateSupplierDeclarationsData, populateExporterDeclarationsData, populateProductData, populateCurrentAttestationData, populateCurrentStorageDeclarationData, populateAttestationsData, populateProductDetailData ]).then((response) => {
    setLoaded(true);
});
}


    return (
        <div>
            <Preloader component={false} show={!loaded} />
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{product?.productName}</h2>
            <>
                <Row>

                    {visibility!=="Delisted" &&
                        <Col md={3} xl={3} className="pb-4">
                            <ProductImages productId={productId} />
                        </Col>
                    }
                    
                    <Col md={5} xl={5} className="pb-4">
                        <ProductSummary
                            isGEFS={isGEFS}
                            isStorageDeclaration={isStorageDeclaration}
                            product={product}
                            manufacturer={manufacturer}
                            visibility={visibility}
                            openProductEdit={openProductEdit}
                        />
                    </Col>

                    {visibility!=="Delisted" &&
                        <Col md={4} className="pb-4">
                            <SupportingEvidence productId={productId} />
                        </Col>
                    }

                    
                    {visibility=="Delisted" &&
                        <Col md={7} xl={4} className="pb-4">
                            <Alert variant="warning">
                                <strong>Product is delisted</strong>
                                <p>
                                    This product is currently delisted and cannot be edited. If you would like to edit this product, you can change the visibility settings back to Active using the product Edit button.
                                </p>
                            </Alert>
                        </Col>
                    }
                </Row>

                {visibility!=="Delisted" && product !== null && (
                    <Row>
                        <Col md={12}>
                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Header>
                                    <Row className="align-items-center">
                                        <Col>
                                            {productDetail.length !== 0 && visibility =='Active' && (
                                                <>
                                                    <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.productdetail.update]}>
                                                        <Button onClick={openProductDetail} variant="light" size="sm" className="float-end text-end">
                                                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                                                            <span>Edit</span>
                                                        </Button>
                                                    </PermissionsCheck>
                                                    <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.productdetail.read]} scopesExcluded={[scopes.productdetail.update]}>
                                                        <Button onClick={openProductDetail} variant="light" size="sm" className="float-end text-end">
                                                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                                                            <span>View</span>
                                                        </Button>
                                                    </PermissionsCheck>
                                                </>
                                            )}
                                            <h4 className="mb-0 text-title">Product Details</h4>
                                        </Col>
                                    </Row>
                                </Card.Header>

                                <Card.Body>

                                {productDetail.length === 0?(
                                    <div className="d-flex flex-column align-items-center flex-0 justify-content-center mb-3">
                                        <h5 className="text-gray-600 d-block">No product details have been added just yet</h5>
                                        <br/>
                                        {visibility =="Active" &&
                                            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.productdetail.update]}>
                                                <Button onClick={openProductDetail} variant="secondary" size="md" className="d-block">
                                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                                    <span>Add Product Details</span>
                                                </Button>
                                            </PermissionsCheck>
                                        }
                                    </div>
                                )
                                :
                                (
                                   <ProductDetailsSummary
                                        productDetail={productDetail}
                                        platform={platform}
                                        siteName={siteName}
                                        temperature={temperature}
                                        productCountry={productCountry}
                                        productZone={productZone}
                                    />
                                )}
                                </Card.Body>
                            </Card>
                        </Col>

                        {productDetail != '' && (

                            <>
                            {unpermittedCertificateNumbers == null &&
                            <>
                            {product.isGEFS ?(
                            <>
                            {/* GEFS */}
                                <Col md={6} className="pb-4">
                                    <CertificateFormPanels 
                                        productDetail={productDetail}
                                        productId={productId}
                                        isGEFS={isGEFS}
                                        isStorageDeclaration={isStorageDeclaration}
                                        productData={productData}
                                        visibility={visibility}
                                    />
                                </Col>
                                <Col md={6} className="pb-4">
                                    {productDetail !== '' &&(
                                        <Card border="light" className="bg-white shadow-sm h-100">
                                            <Card.Body> 
                                                <AttestationPanel 
                                                    hasProductDetails={productDetail !== ""}
                                                    isCertFormComplete={isComplete}
                                                    currentAttestation={currentAttestation}
                                                    productId={productId}
                                                    isGEFS={true}
                                                    isStorageDeclaration={isStorageDeclaration}
                                                    visibility={visibility}
                                                />
                                            </Card.Body>
                                        </Card>
                                    )}
                                </Col>
                            </>
                            ):
                            isStorageDeclaration? (
                            <>
                              <Col md={6} className="pb-4">
                                    <CertificateFormPanels 
                                        visibility={visibility}
                                        productDetail={productDetail}
                                        productId={productId}
                                        isGEFS={false}
                                        isStorageDeclaration={isStorageDeclaration}
                                        productData={productData}
                                    />
                                </Col>
                                <Col md={6} className="pb-4">
                                    <Card border="light" className="bg-white shadow-sm h-100">
                                        <Card.Body>
                                            <StorageDeclarationPanel 
                                                hasProductDetails={productDetail !== ""}
                                                isCertFormComplete={isComplete}
                                                currentStorageDeclaration={currentStorageDeclaration}
                                                productId={productId}
                                                handleReload={getInitialData}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>)
                            :
                            (
                            <>
                            
                                {/* Non GEFS */}
                                <Col md={6} className="pb-4">
                                    <Card border="light" className="bg-white shadow-sm h-100">
                                        <Card.Body>
                                            <AttestationPanel 
                                                hasProductDetails={productDetail !== ""}
                                                isCertFormComplete={isComplete}
                                                currentAttestation={currentAttestation}
                                                productId={productId}
                                                isGEFS={false}
                                                isStorageDeclaration={isStorageDeclaration}
                                                visibility={visibility}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>
                            )
                            }
                            </>
                            }   
                        </>
                        )}
        
                    </Row>
                )}

                {isStorageDeclaration &&
                <Row className="">
                    <Col xs={12} md={12}>
                        <Card border="light" className="h-100 bg-white shadow-sm mb-">
                            <Card.Header>
                                <Row className="align-items-center">
                                    <Col>
                                        <h4 id="tabelLabel" className="text-title mb-0" >Storage declaration history</h4>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} className="mb-4">
                                        <StorageDeclarationHistory 
                                            supplierDeclarations={supplierDeclarations} 
                                            exporterDeclarations={exporterDeclarations}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                }
                {!isStorageDeclaration && visibility!=="Delisted" &&
                    <Row className="">
                        <Col xs={12} md={12}>
                            <Card border="light" className="h-100 bg-white shadow-sm mb-4">
                                <Card.Header>
                                    <Row className="align-items-center">
                                        <Col>
                                            <h4 id="tabelLabel" className="text-title" >Attestation history</h4>
                                        </Col>
                                        <Col className="text-end">
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} className="mb-4">
                                            <AttestationHistory attestations={attestations}/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
                <Row className="mt-3">
                    <Col>
                        <div className="btn-panel left">
                            <Button variant="outline-primary" href="/products">
                                Back to products
                            </Button>
                        </div>
                    </Col>
                </Row>
            </>
        </div>
    )
}

export default ProductPage;