export const BseRiskCategoryNegligibleStatementsChoice = [
    {group: "", fieldname: "meatData.BseNegligible1", value: "1", key: "the animals from which the meat products are derived were born, continuously reared and slaughtered in a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>negligible BSE risk in which there have been no BSE indigenous cases</u>" },
    {group: "", fieldname: "meatData.BseNegligible2", value: "2", key: "the animals from which the meat products are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>negligible BSE risk in which there has been at least one BSE indigenous case</u>, and the meat products do not contain and are not derived from mechanically separated meat obtained from bones of bovine, ovine and caprine animals" },
    {group: "", fieldname: "meatData.BseNegligible3", value: "3", key: "the animals from which the meat products are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>controlled BSE risk</u> and <ol className='list-clauses'><li>the meat products do not contain and are not derived from specified risk material as defined in point 1 of Annex V to Regulation(EC) No 999 / 2001 of the European Parliament and of the Council</li><li>the meat products do not contain and are not derived from mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li><li>the animals from which the meat products are derived were not slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod - shaped instrument introduced into the cranial cavity</li></ol>" },
    {group: "", fieldname: "meatData.BseNegligible4", value: "4", key: "the animals from which the meat products are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing an <u>undetermined BSE risk</u> and: <ol className='list-clauses'><li>the meat products do not contain and are not derived from specified risk material as defined in point 1 of Annex V to Regulation(EC) No 999 / 2001 </li> <li>the meat products do not contain and are not derived from mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li> <li>the animals from which the meat products are derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod - shaped instrument introduced into the cranial cavity</li> <li>the animals from which the meat products are derived have not been fed with meat - and - bone meal or greaves, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health</li> <li>the meat products were produced and handled in a manner which ensures that they do not contain and were not contaminated with nervous and lymphatic tissues exposed during the deboning process</li></ol>" },
]
export const BseRiskCategoryControlledStatementsChoice = [
    {group: "", fieldname: "meatData.BseRiskCategoryControlledStatements",  value: "1", disabled: "disabled", key: "the animals from which the meat products are derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod-shaped instrument introduced into the cranial cavity;" },
    {group: "", fieldname: "meatData.BseRiskCategoryControlledStatements",  value: "2", key: "the meat products do not contain and are not derived from<ol className='list-clauses'><li>specified risk material as defined in point 1 of Annex V to Regulation(EC) No 999/2001;</li><li>mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li>" },
    {group: "", fieldname: "meatData.BseRiskCategoryControlledStatements",  value: "3", key: "the meat products contain and are derived from treated intestines sourced from animals which were born, continuously reared and slaughtered in a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>negligible BSE risk in which there have been no BSE indigenous cases</u>" },
    {
        group: "",fieldname: "meatData.BseRiskCategoryControlledStatements", value: "4", key: "the meat products contain and are derived from treated intestines sourced from animals which originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>negligible BSE risk in which there has been at least one BSE indigenous case</u>, and",
        subclauseName: "meatData.BseRiskCategoryControlled4Statements",
        subclauses: [
            {group: "", fieldname: "meatData.BseRiskCategoryControlled4Statements",  value: "1", key: "the animals were born after the date from which the ban on the feeding of ruminants with meat-and-bone meal and greaves derived from ruminants has been enforced" },
            {group: "", fieldname: "meatData.BseRiskCategoryControlled4Statements",  value: "2", key: "the treated intestines of bovine, ovine and caprine animal origin do not contain and are not derived from specified risk material as defined in point 1 of Annex V to Regulation(EC) No 999/2001." },
        ]
    },
]
export const BseRiskCategoryControlledChoice_C = [
    {group: "", fieldname: "meatData.BseRiskCategoryControlledCStatements",  value: "1", key: "the animals from which the meat products are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>negligible or a controlled BSE risk</u>" },
    {group: "", fieldname: "meatData.BseRiskCategoryControlledCStatements",  value: "2", key: " the animals from which the meat products are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing an <u>undetermined BSE risk</u> and<ol type='i'><li>the animals from which the meat products are derived have not been fed with meat-and-bone meal or greaves, as defined in  the Terrestrial Animal Health Code of the World Organisation for Animal Health;</li><li>the meat products were produced and handled in a manner which ensures that they do not contain and were not contaminated  with nervous and lymphatic tissues exposed during the deboning process;</li>"},
]

export const BseRiskCategoryUndeterminedStatementsChoice = [
        {group: "", fieldname: "meatData.BseRiskCategoryUndeterminedStatements",  value: "1", disabled:"disabled", key: "the animals from which the meat products are derived have not been <ol className= 'list-clauses'><li>slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rodshaped instrument introduced into the cranial cavity;</li><li>fed meat-and-bone meal or greaves derived from ruminants, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health</li></ol>"},
        {group: "", fieldname: "meatData.BseRiskCategoryUndeterminedStatements",  value: "2", key: "the meat products do not contain and are not derived from<ol className='list-clauses'><li>specified risk material as defined in point 1 of Annex V to Regulation (EC) No 999/2001</li><li>mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li><li>nervous and lymphatic tissues exposed during the deboning process</li>" },
        {group: "", fieldname: "meatData.BseRiskCategoryUndeterminedStatements",  value: "3", key: "the meat products contain and are derived from treated intestines sourced from animals which were born, continuously reared and slaughtered in a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>negligible BSE risk in which there have been no BSE indigenous cases</u>" },
        {group: "", fieldname: "meatData.BseRiskCategoryUndeterminedStatements",  value: "4", key: "the meat products contain and are derived from treated intestines sourced from animals which originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>negligible BSE risk in which there has been at least one BSE indigenous case</u>, and",
        subclauseName: "meatData.BseRiskCategoryUndetermined4Statements",
        subclauses: [
            {group: "", fieldname: "meatData.BseRiskCategoryUndetermined4Statements",  value: "1", key: "the animals were born after the date from which the ban on the feeding of ruminants with meat-andbone meal and greaves derived from ruminants has been enforced" },
            {group: "", fieldname: "meatData.BseRiskCategoryUndetermined4Statements",  value: "2", key: "the treated intestines of bovine, ovine and caprine animal origin do not contain and are not derived from specified risk material as defined in point 1 of Annex V to Regulation(EC) No 999/2001." },
        ]
    },
]
export const meatOriginatesFromChoice = [
    { group: "meatData.meatOriginatesFrom", fieldname: "meatData.originatesFrom1",  value: "1", key: "the same country as the product country of origin" },
    { group: "meatData.meatOriginatesFrom", fieldname: "meatData.originatesFrom2",  value: "2", key: "a Member State" },
    { group: "meatData.meatOriginatesFrom", fieldname: "meatData.originatesFrom3",  value: "3", key: "a zone with code …… …………….. authorised for entry into the Union of meat products not required to undergo a specific risk-mitigating treatment as set out in Annex XV to Commission Implementing Regulation (EU) 2021/404 with assigned treatment A, and the zone where the composite product was produced is also authorised for entry into the Union of meat products with assigned treatment A." },
]
export const dairyAStatementsChoice = [
    { group: "dairyData.dairyA", fieldname: "dairyData.dairyA", value: "1", key: "in a country (selected below) as listed in Part 1 of Annex XVII to Implementing Regulation (EU) 2021/404. <span class='text-warning text-italic'>(Please list EU approval numbers below)</span>"},
    // which has been free from foot and mouth disease and infection with rinderpest virus for a period of at least 12 months prior to the date of milking and, during that period, <strong>no vaccination</strong> against those diseases has been carried out. " },
    { group: "dairyData.dairyA", fieldname: "dairyData.dairyA",  value: "2", key: "in the country (selected below) as listed in Part 1 of Annex XVIII to Implementing Regulation (EU) 2021/404 and the treatment applied is conform to the minimum treatment provided for in Article 157 and Annex XXVII to Delegated Regulation (EU) 2020/692. <span class='text-warning text-italic'>(Please list EU approval numbers below)</span> " },
    { group: "dairyData.dairyA", fieldname: "dairyData.dairyA",  value: "3", key: "a Member State. <span class='text-warning text-italic'>(EU approval numbers below)</span>" },

]
export const dairyBOriginatesInChoice = [
    { group: "dairyData.dairyB",   fieldname: "dairyData.dairyB",  value: "1", key: "the same country as the product country of origin" },
    { group: "dairyData.dairyB",   fieldname: "dairyData.dairyB",  value: "2", key: "a Member State" },
    { group: "dairyData.dairyB",   fieldname: "dairyData.dairyB",  value: "3", key: "a country with code …… …………….. authorised for entry into the Union of milk, colostrum, dairy products and colostrum based products in Annex XVII, Part 1, to Implementing Regulation (EU) 2021/404, where the country where the composite product was produced is also authorised, under the same conditions, for entry into the Union of milk, colostrum, dairy products and colostrum-based products and listed in Part 1 of that Annex;" },
]

export const dairyCMilkChoices = [
    { group: "dairyData.dairyCMilkType1", fieldname: "dairyData.dairyCMilkType1", value: 1, key: "Bos Taurus" },
    { group: "dairyData.dairyCMilkType2", fieldname: "dairyData.dairyCMilkType2",  value: 2, key: "Ovis aries" },
    { group: "dairyData.dairyCMilkType3", fieldname: "dairyData.dairyCMilkType3",  value: 3, key: "Capra hircus" },
    { group: "dairyData.dairyCMilkType4", fieldname: "dairyData.dairyCMilkType4",  value: 4, key: "Bubalus bubalis" },
    { group: "dairyData.dairyCMilkType5", fieldname: "dairyData.dairyCMilkType5",  value: 5, key: "Camelus dromedarius" },
]

export const dairyCStatementsChoice = [
        { 
            group: "", 
            fieldname: "dairyData.dairyCStatements", 
            value: 1,
            key: "Bos Taurus, Ovis aries, Capra hircus, Bubalus bubalis, Camelus dromedarius prior to dispatch to the Union have undergone or been produced from raw milk which has undergone",
            subclauseName: "dairyData.dairyC_1",
            subclauses: [
                { 
                    group: "", 
                    fieldname: "dairyData.dairyC_1", 
                    value: 1,
                    key: "at least a pasteurisation treatment involving a single heat treatment with a heating effect at least equivalent to that achieved by a pasteurisation process of at least 72°C for 15 seconds and where applicable, sufficient to ensure a negative reaction to an alkaline phosphatase test applied immediately after the heat treatment;"
                },
                {
                    group: "", 
                    fieldname: "dairyData.dairyC_1", 
                    value: 2,
                    key: "a sterilisation process, to achieve an <sub>F0</sub> value equal to or greater than 3;"
                },
                {
                    group: "", 
                    fieldname: "dairyData.dairyC_1", 
                    value: 3,
                    key: "an ultra high temperature (UHT) treatment at not less than 135°C in combination with a suitable holding time;"
                },
                {
                    group: "", 
                    fieldname: "dairyData.dairyC_1", 
                    value: 4,
                    key: "a high temperature short time (HTST) pasteurisation treatment at 72°C for 15 seconds applied twice to milk with a pH equal to or greater than 7,0 achieving, where applicable, a negative reaction to an alkaline phosphatase test, applied immediately after the heat treatment]"
                },
                {
                    group: "", 
                    fieldname: "dairyData.dairyC_1", 
                    value: 5,
                    key: "HTST pasteurisation treatment of milk with a pH below 7,0;"
                },
                {
                    group: "", 
                    fieldname: "dairyData.dairyC_1", 
                    value: 6,
                    key: "HTST pasteurisation treatment combined with another physical treatment by:",
                    subclauseName: "dairyData.dairyC_1_6",
                    subclauses: [
                        {
                            group: "", 
                            fieldname: "dairyData.dairyC_1_6", 
                            value: 1,
                            key: "lowering the pH below 6 for one hour;"
                        },
                        {
                            group: "", 
                            fieldname: "dairyData.dairyC_1_6", 
                            value: 2,
                            key: "additional heating equal to or greater than 72°C, combined with desiccation;"
                        },
                    ]
                }
            ]
        },
        {
            group: "", 
            fieldname: "dairyData.dairyCStatements", 
            value: 2,
            key: "animals other than Bos Taurus, Ovis aries, Capra hircus, Bubalus bubalis, Camelus dromedarius and prior to dispatch to the Union have undergone or been produced from raw milk which has undergone",
            subclauseName: "dairyData.dairyC_2",
            subclauses: [
                {
                    group: "", 
                    fieldname: "dairyData.dairyC_2", 
                    value: 1,
                    key: "a sterilisation process, to achieve an <sub>F0</sub> value equal to or greater than 3"
                },
                {
                    group: "", 
                    fieldname: "dairyData.dairyC_2", 
                    value: 2,
                    key: "an ultra high temperature (UHT) treatment at not less than 135°C in combination with a suitable holding time"
                }
            ]
        }
]

export const dairyD =
    { group: "dairyData.dairyD", fieldname: "dairyData.dairyD",  value: true, key: "are colostrum-based products and they come from a third country or territory listed in Part 1 of Annex XVII to Implementing Regulation (EU) 2021/404 for entry into the Union of raw milk, colostrum and colostrum-based products" };

export const eggOriginatesFromChoice = [
    { group: "eggData.eggOriginatesFrom", fieldname: "eggData.eggOriginatesFrom1",  value: "1", key: "A zone (selected below) which is listed in Annex XIX, Part 1 to Implementing Regulation (EU) 2021/404 for the entry into the Union of egg products and applies a disease surveillance programme for highly pathogenic avian influenza that complies with the requirements referred to in Article 160 of Delegated Regulation (EU) 2020/692" },
    { group: "eggData.eggOriginatesFrom", fieldname: "eggData.eggOriginatesFrom2",  value: "2", key: "a Member State" },
]
export const eggOriginatesFromChoiceForm = [
    { group: "eggData.eggOriginatesFrom", fieldname: "eggData.eggOriginatesFrom1",  value: "1", key: "A zone (selected below) which is listed in Annex XIX, Part 1 to Implementing Regulation (EU) 2021/404 for the entry into the Union of egg products" },
    { group: "eggData.eggOriginatesFrom", fieldname: "eggData.eggOriginatesFrom2",  value: "2", key: "a Member State" },
]
export const eggStatementsChoicesA = [
    { 
        group: "", 
        fieldname: "eggData.eggStatementsA", 
        value: 1,
        key: "No treatment to highly pathogenic avian influenza treatment",
       
    },
    {
        group: "", 
        fieldname: "eggData.eggStatementsA", 
        value: 2,
        key: "the egg products have undergone the following treatment against highly pathogenic avian infulenza:",
  
        subclauseName: "eggData.egg_A_2",
        subclauses: [
            { 
                group: "", 
                fieldname: "eggData.egg_A_2", 
                value: 1,
                key: "liquid egg white was treated:",
                subclauseName: "eggData.egg_A_2_1",
                subclauses: [
                    {
                        group: "", 
                        fieldname: "eggData.egg_A_2_1", 
                        value: 1,
                        key: "with 55.6 °C for 870 seconds."
                    },
                    {
                        group: "", 
                        fieldname: "eggData.egg_A_2_1", 
                        value: 2,
                        key: "with 56.7 °C for 232 seconds"
                    },
                ]
            },
            { 
                group: "", 
                fieldname: "eggData.egg_A_2", 
                value: 2,
                key: "10% salted yolk was treated with 62.2°C for 138 seconds",
            },
            { 
                group: "", 
                fieldname: "eggData.egg_A_2", 
                value: 3,
                key: "dried egg white was treated:",
                subclauseName: "eggData.egg_A_2_3",
                subclauses: [
                    {
                        group: "", 
                        fieldname: "eggData.egg_A_2_3", 
                        value: 1,
                        key: "with 67 °C for 20 hours"
                    },
                    {
                        group: "", 
                        fieldname: "eggData.egg_A_2_3", 
                        value: 2,
                        key: "with 54.4 °C for 50,4 hours"
                    },
                ]
            },
            { 
                group: "", 
                fieldname: "eggData.egg_A_2", 
                value: 4,
                key: "whole eggs were:",
                subclauseName: "eggData.egg_A_2_4",
                subclauses: [
                    {
                        group: "", 
                        fieldname: "eggData.egg_A_2_4", 
                        value: 1,
                        key: "treated with 60°C for 188 seconds"
                    },
                    {
                        group: "", 
                        fieldname: "eggData.egg_A_2_4", 
                        value: 2,
                        key: "completely cooked"
                    },
                ]
            },
            { 
                group: "", 
                fieldname: "eggData.egg_A_2", 
                value: 5,
                key: "whole egg blends were at least treated:",
                subclauseName: "eggData.egg_A_2_5",
                subclauses: [
                    {
                        group: "", 
                        fieldname: "eggData.egg_A_2_5", 
                        value: 1,
                        key: "with 60°C for 188 seconds"
                    },
                    {
                        group: "", 
                        fieldname: "eggData.egg_A_2_5", 
                        value: 2,
                        key: "with 61.1°C for 94 seconds"
                    },
                    {
                        group: "", 
                        fieldname: "eggData.egg_A_2_5", 
                        value: 3,
                        key: "completely cooked"
                    },
                ]
            },
        ],

    }
];



    export const eggStatementsChoicesB = [
    {
        group: "", 
        fieldname: "eggData.eggStatementsB", 
        value: 1,
        key: "No treatment to Newcastle Disease virus",               
    },
    {
        group: "", 
        fieldname: "eggData.eggStatementsB", 
        value: 2,
        key: "the egg products have undergone the following treatment against Newcastle virus:",    
        subclauseName: "eggData.egg_B_2",
        subclauses: [
            { 
                group: "", 
                fieldname: "eggData.egg_B_2", 
                value: 1,
                key: "liquid egg white was treated:",
                subclauseName: "eggData.egg_B_2_1",
                subclauses: [
                    {
                        group: "", 
                        fieldname: "eggData.egg_B_2_1", 
                        value: 1,
                        key: "with 55°C for 2 278 seconds"
                    },
                    {
                        group: "", 
                        fieldname: "eggData.egg_B_2_1", 
                        value: 2,
                        key: "with 57°C for 986 seconds"
                    },
                    {
                        group: "", 
                        fieldname: "eggData.egg_B_2_1", 
                        value: 3,
                        key: "with 59°C for 301 seconds"
                    },
                ]
            },
            { 
                group: "", 
                fieldname: "eggData.egg_B_2", 
                value: 2,
                key: "10% salted yolk was treated with 55°C for 176 seconds",
            },
            { 
                group: "", 
                fieldname: "eggData.egg_B_2", 
                value: 3,
                key: "dried egg white was treated with 57°C for 50,4 hours",
            },
            { 
                group: "", 
                fieldname: "eggData.egg_B_2", 
                value: 4,
                key: "whole eggs were:",
                subclauseName: "eggData.egg_B_2_4",
                subclauses: [
                    {
                        group: "", 
                        fieldname: "eggData.egg_B_2_4", 
                        value: 1,
                        key: "treated with 55°C for 2 521 seconds"
                    },
                    {
                        group: "", 
                        fieldname: "eggData.egg_B_2_4", 
                        value: 2,
                        key: "treated with 57°C for 1 596 seconds"
                    },
                    {
                        group: "", 
                        fieldname: "eggData.egg_B_2_4", 
                        value: 3,
                        key: "treated with 59°C for 674 seconds"
                    },
                    {
                        group: "", 
                        fieldname: "eggData.egg_B_2_4", 
                        value: 4,
                        key: "completely cooked"
                    },
                ]
            },
        ],
    },

]