import React from 'react';
import { useFormikContext } from 'formik';
import EstablishmentsService from '../../../pages/services/EstablishmentsService';
import PaginatedSelect from '../../../pages/components/common/PagintedSelect';

const EstablishmentSelect = (props) => {
  const {fetchOptions, selections, name, label, setEstablishmentSelected, selectedFieldName, isAddressRequired = false, isMulti, getOptionValue, getOptionLabel, isRequired, countries, species, certificateNumber, excludedApprovalNumbers,readOnly} = props;
  const formik = useFormikContext();

  const handleChange = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      var establishment = selections? selections : [];
      isMulti ?
      establishment = Array.isArray(newValue) ? newValue : [newValue]
      :
      establishment.push(newValue)
      
      // const mergeEstablishment = establishment.flat(1);
      setEstablishmentSelected(selectedFieldName, establishment);
      // formik.setFieldValue(name, establishment); // Use 'name' instead of 'formik.values.name'

      var operatorIds = establishment?.map(x => x?.operatorId);

      isAddressRequired &&
        EstablishmentsService.getEstablishmentsDetails(operatorIds)
          .then(response => {
            var data = response.data[0];
            formik.setFieldValue("siteName", data.name);
            formik.setFieldValue("address1", data.addressLine1);
            formik.setFieldValue("address2", data.addressLine2);
            formik.setFieldValue("townCity", data.city);
            formik.setFieldValue("postcode", data.postalCode);
            formik.setFieldValue("operatorId", data.operatorId);
            formik.setFieldValue("establishmentNumber", data.approvalNumber);
            formik.setFieldValue(name, establishment); // Use 'name' instead of 'formik.values.name'
          })
          .catch((e) => {
            console.log(e);
          })

    }
    // formik.setFieldValue(name, formik.values.establishmentChoice); // Use 'name' instead of 'formik.values[name]'
  };
  
  return (
    <>
    <PaginatedSelect
      countries={countries}
      species={species}
      certificateNumber={certificateNumber}
      name={name}
      label={label}
      handleChange={handleChange}
      onBlur={props.setFieldTouched}
      isMulti={isMulti}
      fetchOptions={fetchOptions}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      isClearable={true}
      touched={formik.touched.establishmentChoice} 
      error={formik.errors.establishmentChoice} 
      isRequired={isRequired}
      defaultValue=""
      placeholder="Start typing a name or approval number."
      customNoOptionsMessage="Start typing a name or approval number."
      customNoResultsMessage="No establishments found."
      backspaceRemovesValue={true}
      components={{ ClearIndicator: null }}
      excludedApprovalNumbers={excludedApprovalNumbers}
      readOnly={readOnly}
    />

    </>
  );
};

export default EstablishmentSelect;
