import React from 'react';
export const AttestationContent = (props) => {
    const { attestationData } = props;
    return (
        <>
            <h5>II. Health Information</h5>
            <>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned, declare that I am aware of the relevant requirements of Regulation (EC) No 178/2002 of the European Parliament and of the Council, Regulation (EC)
                    No 852/2004 of the European Parliament and of the Council, Regulation (EC) No 853/2004 of the European Parliament and of the Council, Regulation (EU) 2017/625 of
                    the European Parliament and of the Council and hereby certify that the products described in Section A.2 were produced in accordance with these requirements,
                    in particular that they:
                </p>
                <ol className="attestation-II-1">
                    <li>come from (an) establishment(s) implementing a programme based on the hazard analysis and critical control points (HACCP) principles in accordance with
                        Article 5 of Regulation (EC) No 852/2004 and regularly audited by the competent authority;</li>
                        
                    <li>have been handled and, where appropriate, prepared, packaged and stored in a hygienic manner in accordance with the requirements of Annex II to Regulation (EC)
                        No 852/2004;</li>

                    <li>fulfil the guarantees covering live animals and products thereof provided by the residue plans submitted in accordance with Article 29 of Council Directive
                        96/23/EC, and the concerned animals and products listed in Commission Decision 2011/163/EU for the concerned country of origin;</li>

                    <li>have been produced under conditions guaranteeing compliance with the maximum residue levels for pesticides laid down in Regulation (EC) No 396/2005 of the
                        European Parliament and of the Council, and the maximum levels for contaminants laid down in Commission Regulation (EC) No 1881/2006.</li>             
                </ol>
            </>
        </>
    )
}

