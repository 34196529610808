import React from "react"
import { Field, ErrorMessage } from "formik"
import {  Col, Row, Form } from "@themesberg/react-bootstrap";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

function CheckBoxes(props) {
    const { label, name, options, ...rest } = props
    return (
        <div>
            <Row className="">
                {label.length > 0 && (
                <Col sm={12}>
                    <label>{label}</label>

                </Col>
                )}
                <Col sm={8}>
                    <Field name={name}>
                        {formik => {
                            const { field } = formik
                            return options.map(option => {
                                return (
                                    <div key={option.key}>
                                        <Form.Group as={Row} className="mb-3" controlId="originates1">
                                            <Col sm={12}>
                                                <Form.Check
                                                    id={option.value}
                                                    {...field}
                                                    {...rest}
                                                    value={option.value}
                                                    checked={field.value.includes(option.value)}
                                                    label={ReactHtmlParser(option.key )}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                )
                            })
                        }}
                    </Field>
                    <ErrorMessage name={name} />

                </Col>
            </Row>
        </div>
    )
}

export default CheckBoxes