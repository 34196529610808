import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp,faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Card, Table } from '@themesberg/react-bootstrap';
import { useTable } from "react-table";
import Preloader from "../../components/Preloader";
import { NoResults} from "./NoResults";
import moment from "moment"
import { Link } from 'react-router-dom';   
export const ProductListTable = (props) => {
  const { products, headerGroups, columns, loaded } = props;
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups2,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: products,
  });

  const TableRow = (props) => {
    const {pageName, views, returnValue, bounceRate } = props;

    const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
    const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";
    return (
      <tr>
        <th scope="row">{pageName}</th>
        <td>{views}</td>
        <td>${returnValue}</td>
        <td>
          <FontAwesomeIcon icon={bounceIcon} className={`${bounceTxtColor} me-3`} />
          {Math.abs(bounceRate)}%
        </td>
      </tr>
    );
  };


  const getSAValidClass = (row) => {
    var classname = '';
    row.cells.map((cell, i) => {   
      if(cell.column.id==='attestationValidTo'){
        if (cell.value=== null) {
       // classname = "danger"
        }
      }
    });
    return classname;
}

function isValidSA(date){
  var attestationDate = moment(date);
  if (attestationDate === "Invalid date") {
      return "invalid";
  }
  if (moment().isAfter(attestationDate)) {
    return "expired";
  }
  var dateDiff = moment().diff(attestationDate, 'days');
  if(dateDiff < 0 && dateDiff >= -5){
    return "expiring";
  }
  if (moment().isBefore(attestationDate)) {
    return "valid";
  }
return "invalid";
}


function formattedDate(date){

  var newDate = moment(date).format("Do MMM YYYY");
  if (newDate === "Invalid date") {
      return "";
  }
// console.log(rowIdx);
return newDate;
}


  return (
    <>
      <Preloader component={true} show={!loaded} />
      <Table hidden={!loaded || products==0} hover responsive className="align-items-center table-flush"
     
        {...getTableProps() }
    >
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
            {products.map((row, i) => {
                prepareRow(row);
                return (
                    <tr className={getSAValidClass(row)} {...row.getRowProps()} >
                        {row.cells.map((cell) => {
                            return (
                                <td {...cell.getCellProps() }
                                
                                >
                                  
                                {(cell.column.id ==='attestationValidTo') ?(
                                  <>
                                  {isValidSA(cell.value)=="valid"&&(
                                    <>
                                    <FontAwesomeIcon icon={faCheckCircle} className="ms-1 text-success float-start mx-1"/>
                                    <span className="mx-1">Valid until</span>
                                    </>
                                  )}
                                  {isValidSA(cell.value)=="expiring"&&(
                                    <>
                                    <FontAwesomeIcon icon={faCheckCircle} className="ms-1 text-warning float-start mx-1"/>
                                    <span className="mx-1">Expiring</span>
                                    </>
                                  )}
                                  {isValidSA(cell.value)=="expired"&&(
                                    <>
                                    <FontAwesomeIcon icon={faTimesCircle} className="ms-1 text-gray-600 float-start mx-1"/>
                                    <span className="mx-1">Expired on</span>
                                    </>
                                  )}
                                  {isValidSA(cell.value)=="invalid"&&(
                                    <>
                                    <FontAwesomeIcon icon={faTimesCircle} className="ms-1 text-danger float-start mx-1"/>
                                    <span className="mx-1">No attestation</span>
                                    </>
                                  )
                                  } 
                                    <span>{formattedDate(cell.value)}</span>
                                  </>
                                )
                              :
                              (
                                cell.render("Cell")
                              )
                              }
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
      </Table>
      {loaded && products.length == 0 &&(
        <NoResults name="products"/>
      )}
    </>
  );
};
