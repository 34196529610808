
  export default {
    formId: 'attestationResponseForm',
    formField: {
      firstInspection: {
        name: 'firstInspection',
        label: 'Is this the first inspection?',
        requiredErrorMsg: 'First inspection is required'
      },
      changeStatement: {
        name: 'changeStatement',
        label: '',
        requiredErrorMsg: 'Changes option is required'
      },
      changesDescription: {
        name: 'changesDescription',
        label: 'Changes',
        requiredErrorMsg: 'Changes are required'
      },
      dateInspection: {
        name: 'dateInspection',
        label: 'Date of Inspection',
        requiredErrorMsg: 'dateInspection is required'
      },
      decision: {
        name: 'decision',
        label: 'Following the review of the attestation request, how would you like to proceed?',
        requiredErrorMsg: 'decision is required'
      },
      rejectReason: {
        name: 'rejectReason',
        label: 'Reason for rejection',
        requiredErrorMsg: 'reject reason required'
      },
      signedRequestUrl: {
        name: 'signedRequestUrl',
        label: 'Signed Request Url',
        requiredErrorMsg: ''
      },
      documentId: {
        name: 'documentId',
        label: 'Document ID',
        requiredErrorMsg: ''
      },
      isGEFS: {
        name: 'isGEFS',
        label: 'isGEFS',
        requiredErrorMsg: 'isGEFS required'
      },
      inspectionType: {
        name: 'inspectionType',
        label: 'Was this a physical or remote inspection?',
        requiredErrorMsg: 'Type of inspection required'
      },
    }
  };
  
