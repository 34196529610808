import React from "react"
import { Field } from "formik"
import {  Col, Form } from "@themesberg/react-bootstrap";

    const File = (props) => {  
        const { name, label, touched, errors, col=12,  ...rest } = props
    return (
        <Field name={props.name}>
            {({ field, meta }) => {
                    return (
                        <Col md={col}>
                            <Form.Group controlId={`validation${name}`}>
                            <Form.Label>{label}</Form.Label>
                                <Form.Control
                                type="file"
                                name={name}
                                {...field}
                                {...rest}
                                />
                            </Form.Group>
                        </Col>
                    )
            }} 
        </Field>
    )
}
export default File