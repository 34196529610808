import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row, Table } from '@themesberg/react-bootstrap';
import { Form, Formik } from 'formik';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import FormController from "../../pages/components/common/FormController";
import Paging from "../../pages/components/common/Paging";
import BlobService from "../../pages/services/BlobService";
import StorageDeclarationService from "../../pages/services/StorageDeclarationService";
import { Routes } from "../../routes";
import Preloader from "../Preloader";
import { NoResults } from "./NoResults";

export const StorageSupplierDeclarationListingTable = (props) => {

  const [supplierDeclarations, setSupplierDeclarations] = useState([]);
  const [values, setValues] = useState([]);
  const [count, setCount] = useState(0);
  const { totalPages = count, size = "md", withIcons = false, disablePrev = false, isSelectForm = false, formValues = null, setParentFormFieldValue } = props;
  const [totalItems, setTotalItems] = useState(0);
  const [activePage, setActivePage] = React.useState(1);
  const [loaded, setLoaded] = useState(false);
  const [pageSize, setPageSize] = useState(5);

  const initialValues = {
    search: ''
  }

  const retrieveExportDeclarations = (values) => {
    let params = '';
    if (values != null) {
      params = getFilterParams(values.search, activePage, pageSize);
    }
    else {
      params = getFilterParams(null, activePage, pageSize);
    }
    getExportDeclarationsData(params);
  };

  
  useEffect(() => {
    retrieveExportDeclarations(values);
  }, [activePage, pageSize]);


  
  useEffect(() => {
    retrieveExportDeclarations(values);
  }, []);

  const getFilterParams = (search, activePage, pageSize) => {
    let params = {};
    if (search) {
      params["search"] = search;
    }
    if (activePage) {
      params["page"] = activePage;
    }
    if (pageSize) {
      params["size"] = pageSize;
    }
    return params;
  };

  const getSADate = (date) => {
    var newDate = moment(date).format("DD/MM/YY");
    if (newDate === "Invalid date") {
      return "";
    }
    return newDate;
  }

  const handlePageSizeChange = (value) => {
    setPageSize(value.value);
    setActivePage(1);
  };

  const openBlobPdf = (filename) => {
    BlobService.openBlobPdf(filename);
  }

  function onSubmit(values, { setSubmitting, resetForm }) {
    setValues(values);
    setTimeout(() => {
      setActivePage(1);
      retrieveExportDeclarations(values);
      console.log(JSON.stringify(values, null, 2));
      setSubmitting(false);
    }, 1000);
  }

  const getExportDeclarationsData = (params) => {
    setLoaded(false);
    StorageDeclarationService.getAllPaged(params)
      .then((response) => {
        const { data, totalPages, totalRecords } = response.data;
        setTotalItems(totalRecords);
        setSupplierDeclarations(data);
        setCount(totalPages);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        console.log(e);
      });
  };


  
  //Table Row
  const TableRow = (props) => {
    const { id, uniqueCode, signedDocument, product, validTo, validFrom } = props;
  
    return (
      <tr>
        <td>
            <div className='mb-1'>{product?.productName}</div>
            <div className='mb-1'>{uniqueCode}</div>
        </td>
        <td>
          {getSADate(validFrom)}
        </td>
        <td>
          {getSADate(validTo)}
        </td>
        <td>
          <Button variant="light" size={"xs"} onClick={()=> openBlobPdf(signedDocument?.filename)} className="text-dark me-2" > 
          View document</Button>
        </td>
      </tr>
    );
  };


  //Start - Paging Functions
  const onPrevItem = () => {
    const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
    setActivePage(prevActiveItem);
  };

  const onPageNumberClick = (number) => {
    setActivePage(number);
  };
  const onNextItem = (totalPages) => {
    const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
    setActivePage(nextActiveItem);
  };
  //End - Paging Functions

  return (
    <>
      <Col xs={12} sm={6} lg={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {(props, isSubmitting) => (
            <Form>
              <Row className="mb-3">
                <Col xs={12} sm={8} lg={3}>
                  <FormController
                    control="input"
                    type="text"
                    placeholder="Search"
                    name="search"
                  />
                </Col>

                <Col xs={12} sm={4} lg={2} >
                  <div className="form-group">
                    <Button variant="light" type="submit" disabled={isSubmitting}>
                      <FontAwesomeIcon icon={faFilter} />{isSubmitting ? "Please wait..." : "Filter"}</Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik >
      </Col>
      <Preloader component={true} show={!loaded} />

      <Table hidden={!loaded || supplierDeclarations.length == 0} responsive hover className="align-items-center table-flush">
        <thead>
          <tr>
            <th className="border-bottom">Product</th>
            <th className="border-bottom">Valid From</th>
            <th className="border-bottom">Valid To</th>
            <th className="border-bottom"></th>
          </tr>
        </thead>
        <tbody>
          {supplierDeclarations?.map(t =>
            <TableRow
              key={`supplierDeclarations-${t.id}`}
              {...t}
            />)}
        </tbody>
      </Table>

      <Paging
        activePage={activePage}
        size={size}
        pageSize={pageSize}
        totalPages={totalPages}
        totalItems={totalItems}
        handleChange={handlePageSizeChange}
        onPrevItem={onPrevItem}
        onNextItem={onNextItem}
        onPageNumberClick={onPageNumberClick}
        withIcons={withIcons}
      />
      {loaded && supplierDeclarations?.length == 0 && (
        <NoResults name="supplier declarations" />
      )}
    </>
  );
};


export default StorageSupplierDeclarationListingTable;
