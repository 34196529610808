import *  as baseService  from "./BaseService";

const getByDocumentId = (documentId) => {
    return baseService.getApiData("/CertificateCompletedCompound/GetByDocumentId?id=" + documentId);
};
const getByConsignmentContentsId = (consignmentContentsId) => {
    return baseService.getApiData("/CertificateCompletedCompound/GetById?ConsignmentContentsId=" + consignmentContentsId);
};
const getByConsignmentId = (consignmentId) => {
    return baseService.getApiData("/CertificateCompletedCompound/GetByConsignmentId?ConsignmentContentsId=" + consignmentId);
};
const create = (data) => {
    return baseService.postApiData("/CertificateCompletedCompound/Create", data);
};
const CertificateCompletedCompoundService = {
    create,
    getByConsignmentId,
    getByConsignmentContentsId,
    getByDocumentId
};

export default CertificateCompletedCompoundService;
