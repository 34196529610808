import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { Col, Form } from '@themesberg/react-bootstrap';
import { Field } from 'formik';



function PaginatedSelect(props) {
  const {
    id,
    handleChange,
    name,
    label = null,
    sublabel = null,
    placeholder,
    value,
    col = 12,
    size,
    isMulti,
    isDisabled,
    readOnly,
    touched,
    defaultValues,
    error,
    isRequired,
    isClearable,
    getOptionValue,
    getOptionLabel,
    backspaceRemovesValue,
    service,
    className,
    fetchOptions,
    customNoOptionsMessage,
    customNoResultsMessage,
    countries,
    species,
    certificateNumber,
    excludedApprovalNumbers,
    ...rest
  } = props;

  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const debouncedLoadOptionsRef = useRef();

  useEffect(() => {
    debouncedLoadOptionsRef.current = debounce((text, pageNumber, pageSize, countries, certificateNumber, species, excludedApprovalNumbers) => {
      loadOptions(text, pageNumber, pageSize, countries, certificateNumber, species, excludedApprovalNumbers);
    }, 500);
  }, [fetchOptions]);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const loadOptions = async (text, pageNumber, pageSize, countries, certificateNumber, species, excludedApprovalNumbers) => {
      console.log("loadOptions  " + text + ", " +  pageNumber+ ", " +  size+ ", " +  countries+ ", " +  certificateNumber+ ", " +  species+ ", " +  excludedApprovalNumbers)
      
    setIsLoading(true);
    try {
      if (pageNumber > 0) {
        console.info('loadOptions');
        const data = await fetchOptions(text, pageNumber, pageSize, countries, certificateNumber, species, excludedApprovalNumbers);
        if (pageNumber === 1) {
          setOptions(data);
        } else if (pageNumber > 1) {
          setOptions((prevOptions) => [...prevOptions, ...data]);
        }
      }
    } catch (error) {
      console.error('Error loading options:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = (e) => {
    if (!isLoading) {
      const element = e.target;
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handleInputChange = (enteredValue) => {
    let minTextLength = 0;
    if (enteredValue.length > minTextLength) {
      setInput(enteredValue);
      setPage(1);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    const debouncedLoadOptions = debouncedLoadOptionsRef.current;
    debouncedLoadOptions(input, page, pageSize, countries, certificateNumber, species, excludedApprovalNumbers);
  }, [input, page]);

  const customFilter = () => true; 

  return (
    <>
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <>
            {label != null && (
              <Form.Label className={isRequired === true ? 'required' : ''} error={error} htmlFor={name}>
                {label}
              </Form.Label>
            )}
            {sublabel != null && (
              <Form.Text className="text-muted">
                &nbsp;&nbsp;
                {sublabel}
              </Form.Text>
            )}
            <Col md={col}>
              <Select
                {...field}
                {...rest}
                value={field.value}
                id={id}
                placeholder={placeholder}
                defaultValue={defaultValues}
                isSearchable={true}
                filterOption={customFilter} // Use the custom filter function
                name={name}
                isMulti={isMulti}
                options={options}
                isLoading={isLoading}
                onMenuScrollToBottom={handleScroll}
                onInputChange={handleInputChange}
                onChange={handleChange}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                noOptionsMessage={({ inputValue }) => (!inputValue ? customNoOptionsMessage : customNoResultsMessage)}
                isDisabled={isDisabled || readOnly}
                isInvalid={!!meta.error && meta.touched}
                backspaceRemovesValue={backspaceRemovesValue}
                components={{ ClearIndicator: null }}
                className={
                  meta.touched && meta.error
                    ? `is-invalid form-control-select-${size} form-control-select-container`
                    : `form-control-select-${size} form-control-select-container`
                }
                classNamePrefix="form-control-select"
              />
              {meta.touched && meta.error !== null ? <div className="invalid-feedback">{meta.error}</div> : null}
            </Col>
          </>
        );
      }}
    </Field>
    </>
  );
}

export default PaginatedSelect;
