import React, { useState, useEffect} from "react";
import { getUserTenantMemberships, getUserRoles} from "../auth/authorisation";
import { Routes } from "../routes";
import PermissionsCheck from '../auth/PermissionCheck';
import {scopes} from '../auth/PermissionsMap';
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTimes, faBars, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom'; 
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import { useMsal } from "@azure/msal-react";
import { useSelector } from 'react-redux';


function LoggedInUser() {
  const { accounts } = useMsal();
  const jobTitle  = accounts[0]?.idTokenClaims?.job_title;
  const name = accounts[0]?.idTokenClaims?.given_name + " " + accounts[0]?.idTokenClaims?.family_name;;
  
  return <><strong className="d-block fs-6">{name}</strong><small>{jobTitle}</small></>
}

export const SignOutButton = () => {
  function handleLogout(instance) {
      instance.logoutRedirect().catch(e => {
          console.error(e);
      });
    }
  const { instance } = useMsal();

  return (
      <Button variant="secondary" className="ml-auto" onClick={() => handleLogout(instance)}>Log out</Button>
  );
}

export default (props) => {
  const { sidebarCollapsed, onCollapse } = props;

  const logo = useSelector((state) => state.logo);
	const [tenants, setTenants] = useState({ set: false, items: [] });
	const [roles, setRoles] = useState([]);
  const location = useLocation();
  const { pathname } = location;
  const [userInfo, SetUserInfo] = useState([]);

  const showClass = sidebarCollapsed ? "collapsed" : "collapse";
  //const showClass = sidebarCollapsed ? "show" : "";

	const goToTenant = function(tenantName) {
    if(tenantName=="dev"){
      window.location = "https://ivetted-webapp-dev.azurewebsites.net/";
    }
    else{
      window.location = "https://" + tenantName + ".ivetted.com/";
    }
	}

	useEffect(() => {
		if (tenants.set === false) {
			getUserTenantMemberships()
				.then(x => {
					setTenants(x);
				});
		}
    setRoles(getUserRoles());
	}, []);
  

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <div className={`toggle-nav ${sidebarCollapsed ? 'collapsed' : ''}`}>
      
      <CSSTransition
          timeout={300}
          in={!sidebarCollapsed}
          classNames="sidebar-transition">
      <Navbar.Toggle as={Button} variant="none" aria-controls="main-navbar" className={`toggle-nav-button ${!sidebarCollapsed ? 'on' : 'off'}`} onClick={onCollapse}> 
        <FontAwesomeIcon icon={sidebarCollapsed? faChevronRight : faChevronLeft} />
      </Navbar.Toggle>
      </CSSTransition>
      {/* <Navbar expand={false} collapseOnSelect variant="dark" className={`navbar-theme-primary px-2 d-md-none ${sidebarCollapsed ? '' : 'show'}`}>
        <Navbar.Brand as={Link} to={Routes.DashboardOverview.path}>
          <Image src={logo} className="navbar-brand-light" />
        </Navbar.Brand>
      </Navbar> */}
{/*   <CSSTransition timeout={1000} in={showMenu} classNames="sidebar-transition"> */}
      <CSSTransition
          timeout={300}
          in={!sidebarCollapsed}
          classNames="sidebar-transition"
>
        <SimpleBar className={` ${sidebarCollapsed ? "collapsed" : ""} sidebar`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>
                    <LoggedInUser /> </h6>
                  {/* <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button> */}
                  <SignOutButton />
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none">
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
             
            <Navbar.Brand className="d-md-none" as={Link} to={Routes.DashboardOverview.path}>
            <Image src={logo} className="navbar-brand-dark" />
            </Navbar.Brand>
            <Navbar.Brand className="mb-5 mt-5 d-none  d-md-block" as={Link} to={Routes.DashboardOverview.path}>
            <Image src={logo} className="navbar-brand-dark" />
            </Navbar.Brand>
            
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.products.read]}>
              <NavItem title="Products" link={Routes.Products.path} />
            </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.manufacturers.read]}>
              <NavItem title="Manufacturers" link={Routes.ManufacturersListingPage.path} />
            </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.platforms.read]}>
                <NavItem title="Platforms" link={Routes.Platforms.path} />
              </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.sites.read]}>
              <NavItem title="Sites" link={Routes.Sites.path} />
              </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.vets.read]}>
              <NavItem title="Vets" link={Routes.Vets.path} />
              </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.platformcertifiers.read]}>
              <NavItem title="Platform Certifiers" link={Routes.PlatformCertifiersListingPage.path} />
              </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.dailyattestations.read]}>
              <NavItem title="Attestations" link={Routes.AttestationDashboard.path} />
              </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} requireRole={['certifyingofficer', 'vet']} scopes={[scopes.attestationresponses.create]}>
              <NavItem title="Support Attestations" link={Routes.SupportAttestations.path} />
            </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.storagedeclarations.read]}>
              <NavItem title="Storage Declarations" link={Routes.StorageDeclarationDashboard.path} />
            </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.consignments.read]}>
              <NavItem title="Consignments" link={Routes.ConsignmentListingPage.path} />
            </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.certificates.create]}>
              <NavItem title="Certificates" link={Routes.CertificateListingPage.path} />
            </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.reports.dashboard]}>
              <NavItem title="Reports" link={Routes.ReportsDashboard.path} />
            </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.storageresponses.create]}>
              <NavItem title="Supplier Declarations" link={Routes.StorageSupplierDeclarationListingPage.path} />
              <NavItem title="Exporter Declarations" link={Routes.StorageExporterDeclarationListingPage.path} />
            </PermissionsCheck>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.establishments.read]}>
              <NavItem title="Establishments" link={Routes.EstablishmentListingPage.path} />
            </PermissionsCheck>
            <NavItem title="User Guides" link={Routes.UserGuides.path} />
              

              {/* <Dropdown.Divider className="my-3 border-indigo" /> */}
              <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="px-0 mt-5">
                <div className="media d-flex align-items-center">
                  <Image src={Profile3} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-md-block">
                    <span className="mb-0 font-small fw-bold">
                    <LoggedInUser /> 
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right">
                
                  {tenants.length >= 0 && tenants.map(tenant => (
                    <Dropdown.Item className="fw-bold text-dark" key={tenant} onClick= {() => {goToTenant(tenant)}} >
                      {tenant}
                    </Dropdown.Item>
                  ))}

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> <SignOutButton />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
              </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </div>
  );
};
