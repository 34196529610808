import React, { useState } from 'react';
import { Button, Col, Dropdown, Row } from "@themesberg/react-bootstrap";
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PermissionCheck from '../../../../../auth/PermissionCheck';
import AddGroupModal from '../Modals/AddGroupModal';
import ViewDataModal from '../Modals/ViewDataModal';
import ViewConfigModal from '../Modals/ViewConfigModal';
import { exportData} from "../Functions/EditorFunctions";

const NavigationBar = ({
        isCompound = false,
        certificateId,
        values,
        pageNumber, 
        numPages, 
        goToPrevPage, 
        goToNextPage,
        data,
        certificateGroups,
        setData,
        formData,
        updating,
        onUpdated,
        isSubmitting,
        initialSavedData,
        config
    }) =>{
  
    const handleCloseModalViewConfig = () => setShowModalViewConfig(false);
    const handleCloseModalAdd = () => setShowModalAdd(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalViewData, setShowModalViewData] = useState(false);
    const handleCloseModalViewData = () => setShowModalViewData(false);
    const [showModalViewConfig, setShowModalViewConfig] = useState(false);

    const handleDataObjectUpdate = (data) => {
        setData(data);
    }
    

    //Paging functions

    return(
        <Row>
            <Col md={5}>
                <nav>
                    <Button
                        size="sm" 
                        variant="light" 
                        onClick={goToPrevPage} 
                        className="previous"
                    >
                        Prev
                    </Button>
                    <Button 
                        size="sm" 
                        variant="light" 
                        onClick={goToNextPage} 
                        className="mx-2 next"
                        >
                        Next
                    </Button>
                    <small className='d-inline mx-3'>
                        Page {pageNumber} of {numPages}
                    </small>
                </nav>
            </Col>
            <Col md={7} className="text-right">

                {values !== null &&
                    <Button  
                        size="sm"
                        variant="secondary"
                        className='d-inline mx-2'
                        disabled={updating}
                        onClick={() => {
                            isCompound? 
                                onUpdated(values) 
                                : 
                                onUpdated(values, data, certificateId)}}
                        >
                        {updating ? "Please wait..." : "Update Certificate"}
                    </Button>
                }

                {/* //Admin List */}
                <PermissionCheck errorProps={{ disabled: true }} requiresRole={['admin']}>
                    <Dropdown  className='d-inline'>
                        <Dropdown.Toggle as={Button} variant="light" size="sm" className="text-dark">
                            <FontAwesomeIcon icon={faCog} className=" me-2"/>
                            Admin
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-left mt-2">
                            <Dropdown.Item onClick={() => setShowModalViewData(true)} >
                                View Data
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowModalViewConfig(true)} >
                                View Config
                            </Dropdown.Item>
                            <Dropdown.Item onClick={()=> setShowModalAdd(true)}>
                                {isSubmitting ? "Please wait..." : "Add Group"}
                            </Dropdown.Item>
                            {/* {values !== null &&
                                <Dropdown.Item onClick={() => {exportData(values, data, certificateGroups, languageCode)}}>
                                    Export JSON
                                </Dropdown.Item>
                            } */}
                        </Dropdown.Menu>
                    </Dropdown>
                </PermissionCheck>


                {/* //Admin List Modals */}
                <ViewDataModal 
                    showModalViewData={showModalViewData} 
                    handleCloseModalViewData={handleCloseModalViewData}
                    data={initialSavedData}
                />
                <ViewConfigModal 
                    showModalViewConfig={showModalViewConfig} 
                    handleCloseModalViewConfig={handleCloseModalViewConfig}
                    data={config}
                />
                <AddGroupModal 
                    values={values}
                    totalPages={numPages}
                    data={data}
                    setData={setData}
                    showModalAdd={showModalAdd} 
                    handleCloseModalAdd={() => handleCloseModalAdd()}
                    handleDataObjectUpdate={handleDataObjectUpdate}
                    certificateGroups={certificateGroups}
                />
                

            </Col>
        </Row>
    )

}

export default NavigationBar;