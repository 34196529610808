import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Button, Alert, Col, Row, Form, Spinner } from "@themesberg/react-bootstrap";
import { faBullhorn, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../routes";
import BreadcrumbNav from "../../components/BreadcrumbNav";
import ProductDataService from "../services/ProductService";

const breadcrumbData = {
    item1 : {name:'Products', link: Routes.Products.path},
    item2 : {name: "Bulk Upload"},
};

export class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            uploading: false,
            errors:[],
            selectedFile: '',
            selected: false,
            complete: false,
            review: false,
            productCount: 0,
            selectedFileName: '',
            status: '',
            status2: '',
            csvResult: 0,
            progress: 0, 
            uploaded: false,
        }
        
    }
    

    selectFileHandler = (event) => {
        this.setState({
            errors: [],
            review: false,
            selectedFile: '',
            selectedFileName: '',
            selected: false
        });
        const fileTypes = ['text/comma-separated-values', 'text/x-comma-separated-values','text/csv','file/csv','text/x-csv', 'application/x-csv','application/csv','application/vnd.ms-excel'];
        let file = event.target.files;
        let errMessage = [];
        if (fileTypes.every(extension => file[0].type != extension)) {
            errMessage.push(`This file is not supported. Try uploading a .csv file`);
            this.setState({
                errors: errMessage,
                review: false,
                selected: false
            });
        }
        else{
            this.setState({
                errors: [],
                review: false,
                selected: true,
                selectedFile: file[0],
                selectedFileName: file[0].name
            });
        }
    };
    resetHandler = (event) => {
        this.setState({
            loading: true,
            errors:[],
            selectedFile: '',
            selected: false,
            complete: false,
            review: false,
            productCount: 0,
            selectedFileName: '',
            status: '',
            status2: '',
            csvResult: 0,
            progress: 0, 
            uploaded: false,
        });
    }

    confirmHandler = (event) => {

        this.setState({uploading: true});
        ProductDataService.bulkConfirm()
        .then((response) => {
            this.setState({ review: false, complete: true, uploading: false, status: `Upload success.  ${response.data}` });
        })
        .catch((error) => {
            console.info("Error: " + error.toString());
            this.setState({ review: false, complete: false, uploading: false, status:  `Upload failed. ${error.response.data}`});
        })
    }

    uploadHandler = (event) => {
        const formData = new FormData();
        formData.append('formFile', this.state.selectedFile);
        formData.append('fileName', this.state.selectedFileName);
        this.setState({uploading: true});

        ProductDataService.bulkUpload(formData)
        .then((response) => {
            const data = response.data;
            const count = response.data.count;
            this.setState({uploading: false, review: true, productCount: count, csvResult: data, status: `Upload success. ${response.data}` });
        })
        .catch((error) => {
            console.info();
            this.setState({uploading: false,  review: false, productCount: 0, csvResult: '',  status: `Upload failed. ${error.response.data}`});
            //this.setState({ review: false, productCount: 0, csvResult: '',  status: `Upload failed. ${error.response.data}` });
        })

    }


    render() {
        return (
            <div>

                {/* 1. Upload Stage */}

                <BreadcrumbNav data={breadcrumbData}/>
                <h2>Products: Bulk upload</h2>

                <Row className="">
                    <Col lg={12}>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                                <Row className="align-items-center">
                                    <Col>
                                        {/* <Button onClick={openProductEdit} variant="light" size="sm" className="float-end text-end"> 
                                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                                            <span>Edit</span>
                                        </Button>
                                        <h4 className="mb-0" >Summary</h4> */}
                                    </Col>
                                </Row>
                            </Card.Header>

                            <Card.Body>
                             <div>   
                                {!this.state.review && !this.state.complete && (
                                    <div>
                                        

                                        <h5>1. Download the template</h5>
                                        <ul>
                                            <li>Add your data to the downloaded template CSV file, underneath the headings:<br/><i>Product Name, ProductCode, EAN, SupplierCode, SupplierName, LengthTimeDelivered, IsActive, IsGEFS, IsStorageDeclaration</i></li>
                                            <li>Complete all fields and enter "Yes" or "No" for fields <i>IsActive, IsGEFS, IsStorageDeclaration</i></li>
                                        </ul>
                                        <Button href="https://ivettedstorage.blob.core.windows.net/public-files/ivetted_product_template.csv" variant="outline-primary" >
                                            Download Template
                                        </Button>




                                        <h5 className="mt-5">2. Add your CSV file of new data </h5>
                                        <p>Select the CSV file of the data to be uploaded from your computer. Files should be csv format.</p>
                                        <Form.Group controlId="formFile" md="4" onChange={this.selectFileHandler} className="mb-3" >
                                                <Row>
                                                    <Col md={7}>
                                                        <Form.Control type="file"/>
                                                        {this.state.errors.length>0&&(

                                                            <Alert
                                                            className="mt-2"
                                                            variant="warning"
                                                            >
                                                            <div className="d-flex justify-content-start">
                                                                <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                                {this.state.errors}
                                                            </div>
                                                            </Alert>
                                                        )}
                                                    </Col>
                                                </Row>
                                        </Form.Group>


                                        <h5 className="mt-5">3. Upload the data </h5>
                                        <p>Once you have selected the correct file, click "Upload" to review the data you are about to save.</p>
                                        <Button disabled={!this.state.selected} onClick={this.uploadHandler} variant="primary" type="submit">
                                            Upload
                                        </Button>

                                        
                                        {this.state.uploading &&(
                                            <>
                                                <Spinner animation="border" variant="primary"  size="sm" className="ms-3" />
                                                Please wait whilst we process the imported data...
                                            </>
                                        )}

                                        <br />
                                        {this.state.status!=='' && this.state.uploaded==false&&(
                                            <Alert
                                            className="mt-2"
                                            variant="warning"
                                            >
                                            <div className="d-flex justify-content-start">
                                                <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                {this.state.status}
                                            </div>
                                            </Alert>
                                        )}
                                    </div>
                                )}
                                </div>



                            {/* 2. Review Stage */}

                                <div>{this.state.review && !this.state.complete && (
                                    <div>
                                        <h5>Review CSV data</h5>
                                        <p>Take a look through your data to confirm everything looks correct before proceeding with uploading.</p>
                                        
                                        <h5 className="mt-5 text-title">Manufacturers to be uploaded ({this.state.csvResult.manufacturerCount})</h5>
                                        {this.state.csvResult.manufacturersToCreate.length > 0 &&(
                                        <div className="tableFixHead">

                                            <table className='table table-striped' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th>Manufacturer Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.csvResult.manufacturersToCreate.map(manufacturer =>
                                                        <tr key={manufacturer.manufacturerName}>
                                                            <td>{manufacturer.manufacturerName}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            </div>
                                        )}

                                        <h5 className="mt-5 text-title">Products to be uploaded ({this.state.csvResult.productCount}) </h5>

                                        <div className="tableFixHead">

                                        <table className='table table-striped table-responsive' aria-labelledby="tabelLabel">
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Product Code</th>
                                                    <th>EAN</th>
                                                    <th>Supplier Name</th>
                                                    <th>Supplier Code</th>
                                                    <th>Length of time delivered</th>
                                                    <th>Active</th>
                                                    <th>GEFS</th>
                                                    <th>Storage Declaration</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.csvResult.products.map(product =>
                                                    <tr key={product.ean}>
                                                        <td>{product.productName}</td>
                                                        <td>{product.productCode}</td>
                                                        <td>{product.ean}</td>
                                                        <td>{product.supplierName}</td>
                                                        <td>{product.supplierCode}</td>
                                                        <td>{product.lengthTimeDelivered}</td>
                                                        <td>{product.isActive}</td>
                                                        <td>{product.isGEFS}</td>
                                                        <td>{product.isStorageDeclaration}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        </div>

                                        <h5 className="mt-5 text-title">Errors - Excluded from this upload ({this.state.csvResult.errorCount}) </h5>

                                        {this.state.csvResult.errorCount > 0 &&(
                                            
                                        <ul className="">
                                            {this.state.csvResult.duplicateRowCount > 0 &&(
                                                <li>
                                                    <strong>{this.state.csvResult.duplicateRowCount}</strong> rows had duplicate products.
                                                </li>
                                            )}
                                            {this.state.csvResult.existingEanCount > 0 &&(
                                                <li>
                                                    <strong>{this.state.csvResult.existingEanCount}</strong> rows had a product which already exist in our database.
                                                </li>
                                            )}
                                            {this.state.csvResult.duplicateRowCount > 0 &&(
                                                <li>
                                                    <strong>{this.state.csvResult.excessiveTextCount}</strong> had data which exceeded the maximum characters allowed.
                                                </li>
                                                )}
                                        </ul>
                                        )}
                                        <br />
                                        {this.state.uploading &&(
                                            <>
                                                <Spinner animation="border" variant="primary"  size="sm" className="ms-3" />
                                                Please wait whilst we import the data, this make take a little while...
                                            </>
                                        )}
                                        <div className="btn-panel left mt-5">
                                            <Button variant="primary" onClick={this.confirmHandler}>Upload Products</Button>
                                            <Button variant="outline-danger" onClick={this.resetHandler}>Cancel</Button>
                                        </div>
                                    </div>
                                
                                    )}
                                </div>
                                    {/* 3. Complete Stage */}
                                    <div>{this.state.complete && (
                                        <div className="text-center">
                                            <h2>Upload Complete</h2>
                                            <i className="vtdcomplete">
                                                <FontAwesomeIcon icon={faCheckCircle} className="vtdcomplete2" />
                                            </i>
                                            <p>You have successfully uploaded and imported products.</p>
                                            {this.state.csvResult.productCount > 0 && (
                                                <small>{this.state.csvResult.productCount} new products.<br /></small>
                                            )}
                                            {this.state.csvResult.manufacturerCount > 0 && (
                                                <small>{this.state.csvResult.manufacturerCount} new manufacturers.<br /></small>
                                            )}
                                            <br />
                                            <div className="btn-panel left">
                                                <Button variant="outline-primary" href="/products">View Products</Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
