
import moment from "moment";
import React, { useEffect, useState, useRef } from 'react';
import PermissionsCheck from '../../../../auth/PermissionCheck';
import { scopes} from '../../../../auth/PermissionsMap';
import AttestationService from '../../../services/AttestationService';
import { Card, Button, Badge, Col, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPen, faWindowClose,faPlus, faInfoCircle, faExclamationTriangle, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../../../routes";
import { useHistory } from "react-router-dom";
import SupplierDeclarationButton from "../../StorageDeclaration/SupplierDeclaration/SupplierDeclarationButton";

const StorageDeclarationPanel = (props) => {
    const {hasProductDetails, currentStorageDeclaration, isCertFormComplete, productId, handleReload} = props;
    const history = useHistory();

    const getSADate = (date) => {
        var newDate = moment(date).format("Do MMM YYYY");
        if (newDate === "Invalid date") {
            return "";
        }
        return newDate;
    }

    function isValidSA(date){
        if (moment().isAfter(attestationDate)) {
          return "expired";
        }
        var attestationDate = moment(date);
        if (attestationDate === "Invalid date") {
            return "invalid";
        }
        var dateDiff = moment().diff(attestationDate, 'days');
        if(dateDiff < 0 && dateDiff >= -5){
          return "expiring";
        }
        if (moment().isBefore(attestationDate)) {
          return "valid";
        }
      return "invalid";
    }

    const newGEFSSupportAttestation = () => {
        history.push(Routes.RequestAttestationPage.path.replace(":id", productId));
    };

    const newNonGEFSSupportAttestation = () => {
        history.push(Routes.RequestAttestationNonGEFSPage.path.replace(":id", productId));
    };
    const newStorageDeclaration = () => {
        history.push(Routes.RequestStorageDeclarationPage.path.replace(":id", productId));
    };

    return(
        <>
            <Col className="text-center">
                <h4 className="text-title" id="tabelLabel" >
                    Storage declaration
                </h4>

                {/* //Error Check */}
                <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.products.read]} scopesExcluded={[scopes.storagerequests.create]}>
                    <p> 
                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2 text-danger" />
                        Your account type cannot request a storage declaration.
                    </p>
                </PermissionsCheck>
                {/* //Completed storage declaration */}
                {currentStorageDeclaration !== ""?(
                    <div>
                        <p>
                            {isValidSA(currentStorageDeclaration?.validTo)=="valid"&&(
                                <Badge bg="primary" className="rounded px-3 py-2 bg-success"> Expires {moment(currentStorageDeclaration.validTo).fromNow()}</Badge>
                            )}
                            {isValidSA(currentStorageDeclaration?.validTo)=="expiring"&&(
                                <Badge bg="warning" className="rounded px-3 py-2 bg-success"> Expires {moment(currentStorageDeclaration.validTo).fromNow()}</Badge>
                            )}
                            <small>&nbsp;Valid until: {getSADate(currentStorageDeclaration?.validTo)}</small>
                        </p>
                        <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.storagerequests.create]}>
                        <>
                            {!moment(currentStorageDeclaration.createdDate).isSame(moment(), "day")?
                            (
                                    <SupplierDeclarationButton
                                        handleReload={handleReload}
                                        hasCurrent={true}
                                        productId={productId}
                                        //onClick={newStorageDeclaration}
                                    />
                            ):
                            (
                                <p>
                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2 text-info" />
                                    Another attestation can be requested from tomorrow
                                </p>
                            )
                            }
                        </>
                        </PermissionsCheck>
                    </div>
                ):(
                    <>
                    {/* //New Storage Declaration */}
                        {(hasProductDetails && isCertFormComplete) ?(
                            <SupplierDeclarationButton
                                handleReload={handleReload}
                                hasCurrent={false}
                                productId={productId}
                            />
                        )
                        :
                        (
                        <>
                            <p>
                                <FontAwesomeIcon icon={faExclamationTriangle} className="me-2 text-danger" />
                                Certificate specific information is required before a storage declaration can be completed.
                            </p>
                        </>
                        )
                        }
                    </>
                )}
            </Col>
        </>
    )
}
 export default StorageDeclarationPanel;