//Get all parameters from URL
export async function getAllUrlParams(location){
    var searchParams = new URLSearchParams(location.search);
    var res = searchParams.entries();
    return res;
}
//Get specific parameter from URL by name
export async function getParamByName (location, name){
    var searchParams = new URLSearchParams(location.search);
    var res = searchParams.get(name);
    return res;
}

//Check if specific parameter in URL exists
export async function checkParamByName (location, name){
    var searchParams = new URLSearchParams(location.search);
    var res = searchParams.has(name);
    return res;
}

//Update URL 
export async function updateParam (location, name, value){
    var searchParams = new URLSearchParams(location.search);
    if(name != ""){
      searchParams.set(name, value);
    }
    return;
}

export async function deleteAllParams (location, history){
    var searchParams = new URLSearchParams(location.search);
    searchParams.forEach((value) => {
      searchParams.delete(value);
    });
    await history.push({
        pathname: location.pathname, 
        search: new URLSearchParams().toString()
    });
    return;
}
export async  function deleteParamByName (location, name){
    var searchParams = new URLSearchParams(location.search);
    searchParams.has(name)&&
        searchParams.delete(name);
    return searchParams.toString();
}

export async function deleteParamsByNameList (location, nameList){
    var params = "";
    nameList.forEach(async (name) => {
      params = await deleteParamByName(name)
    });
    return params;
}

//Clears all parameters in the URL
export async function clearParams (location, history){
    await history.push({
        pathname: location.pathname, 
        search: new URLSearchParams().toString()
    });
    return;
}
