import * as Gelatine8390Labels from "../Gelatine8390Labels"
import { findArrayElementByFieldName, findArrayElementByValue, findArrayElementByFieldNameAndValue } from "../../../Attestations/Common/AttestationFunctions";
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export const AttestationContent = (props) => {
    const { attestationData } = props;

    return (
        <>
            <h5>II. Health Information</h5>
            <>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned, declare that I am aware of the relevant requirements of Regulation (EC) No 178/2002 of the European Parliament and of the Council, Regulation
                    (EC)No 852/2004 of the European Parliament and of the Council, Regulation (EC) No 853/2004 of the European Parliament and of the Council, and Regulation
                    (EU) 2017/625 of the European Parliament and of the Council and hereby certify that the gelatine described in Section A.2 was produced in accordance with these
                    requirements, in particular that:
                </p>
                <ol className="attestation-II-1">
                    <li>it comes from (an) establishment(s) applying general hygiene requirements and implementing a programme based on the hazard analysis and critical
                        control points (HACCP) principles in accordance with Article 5 of Regulation (EC) No 852/2004, regularly audited by the competent authority, and
                        being listed as an EU approved establishment;</li>
                    <li>it has been produced from raw materials that met the requirements of Section XIV, Chapters I and II, of Annex III to Regulation (EC) No 853/2004;</li>

                    <li>it has been produced in compliance with the conditions set out in Section XIV, Chapter III, of Annex III to Regulation (EC) No 853/2004;</li>

                    <li>it satisfies the criteria of Section XIV, Chapter IV, of Annex III to Regulation (EC) No 853/2004 and of Commission Regulation (EC)
                        No 2073/2005;</li>
                    <li>it derives</li>
                    <ul>
                        {attestationData.health_1_5_statements > 0 &&
                            <li>
                                {findArrayElementByFieldNameAndValue(Gelatine8390Labels.health_1_5_statements, 'health_1_5_statements', attestationData.health_1_5_statements)}
                            </li>
                        }
                    </ul>
                    {attestationData.health_1_6 ? (
                        <li>{ReactHtmlParser(Gelatine8390Labels.health_1_6.label)}</li>

                    ) :
                        (
                            <li>N/A</li>
                        )}


                    {attestationData.bseRiskCategoryNegligible || attestationData.bseRiskCategoryControlled || attestationData.bseRiskCategoryUndetermined ? (
                        <ul>
                            {attestationData.bseRiskCategoryNegligible && (
                                <ul>
                                    <li>the country or region of origin is classified in accordance with Commission Decision
                                        2007/453/EC as a country or region posing a <u>negligible BSE risk</u>, and

                                        <ul>
                                            {attestationData.bseRiskCategoryNegligibleStatements.map(statement =>
                                                <li>
                                                    {findArrayElementByValue(Gelatine8390Labels.BseRiskCategoryNegligibleStatementsChoice, statement)}
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                </ul>
                            )}
                            {/* BSE Controlled */}
                            {attestationData.bseRiskCategoryControlled &&
                                <ul>
                                    <li>the country or region of origin is classified in accordance with Decision 2007/453/EC
                                        as a country or region posing a <u>controlled BSE risk</u> , and

                                        <ol type="a">
                                            <li>
                                                {findArrayElementByValue(
                                                    Gelatine8390Labels.BseRiskCategoryControlledStatementsChoice, 1)
                                                }
                                            </li>
                                            <li>
                                                {findArrayElementByValue(
                                                    Gelatine8390Labels.BseRiskCategoryControlledStatementsChoice, 2)
                                                }
                                            </li>
                                            {attestationData.bseRiskCategoryControlledStatements > 0 &&
                                                <li>
                                                    {findArrayElementByValue(
                                                        Gelatine8390Labels.BseRiskCategoryControlledStatementsChoice,
                                                        attestationData.bseRiskCategoryControlledStatements)
                                                    }


                                                </li>
                                            }
                                        </ol>
                                    </li>
                                </ul>
                            }
                            {/* BSE Undetermined */}
                            {attestationData.bseRiskCategoryUndetermined &&
                                <ul>
                                    <li>the country or region of origin has not been classified in accordance with Decision 2007/453/EC or is classified as a country or region with an <u>undetermined BSE risk</u>, and
                                        <ol type="a">
                                            <li>
                                                {findArrayElementByValue(Gelatine8390Labels.BseRiskCategoryUndeterminedStatementsChoice, 1)}
                                            </li>
                                            <li>
                                                {findArrayElementByValue(Gelatine8390Labels.BseRiskCategoryUndeterminedStatementsChoice, 2)}
                                            </li>
                                        </ol>
                                    </li>
                                </ul>
                            }
                        </ul>
                    ) :
                        (
                            <li>N/A</li>
                        )}

                </ol>
            </>
        </>
    )
}

