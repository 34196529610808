import React, { useState, useEffect } from 'react';
import * as Composite8350Labels from "../Composite8350Labels"
import ReactHtmlParser from 'react-html-parser';
import moment from "moment"
import {findArrayElementByFieldName,findArrayElementByFieldNameAndValue,findArrayElementByValue} from "../../../Attestations/Common/AttestationFunctions";

const Egg8350 = (props) => {
    const {attestationData} = props;
    return (
    <>
        {attestationData?.containsEgg?(
            <li>
                <strong><u>II.3.D Egg products</u></strong> 
                <ul>
                    <li>I.3.D.1 originate from the approved establishment No <u><strong>{attestationData?.eggData.eggEstablishments.map(establishment => establishment.approvalNumber).join(", ")}</strong></u> situated in:
                        <ul>
                            {attestationData.eggData.eggOriginatesFrom1 == "1" &&(
                                <li key={Composite8350Labels.eggOriginatesFromChoice[0].value}>
                                    {ReactHtmlParser(Composite8350Labels.eggOriginatesFromChoice[0].key.replace("(selected below)", "<strong><u>" + attestationData.eggData.eggCountryOrigin.map(x => x.isoCode.slice(0,-2)).join(", ") + "</u></strong>"))}
                                </li>
                            )}
                            {attestationData.eggData.eggOriginatesFrom2 == "1" &&(
                                <li key={Composite8350Labels.eggOriginatesFromChoice[1].value}>
                                    {ReactHtmlParser(Composite8350Labels.eggOriginatesFromChoice[1].key)}
                                </li>
                            )}
                        </ul>
                    </li>
                    <li>
                        I.3.D.2 were produced from eggs coming from an establishment which satisfies the requirements of Section X of Annex III to Regulation
                        (EC) No 853/2004 and:
                        
                        <ol type="a">
                            {attestationData.eggData.eggStatementsA == 2 ?(
                                <li>
                                    {ReactHtmlParser(Composite8350Labels.eggStatementsChoicesA[1].key)}
                                    <ul>
                                        <li>
                                            {findArrayElementByFieldNameAndValue(Composite8350Labels.eggStatementsChoicesA, 'eggData.egg_A_2', attestationData.eggData.egg_A_2)}
                                            {(attestationData.eggData.egg_A_2_1 > 0 ||attestationData.eggData.egg_A_2_3 > 0 ||attestationData.eggData.egg_A_2_4 > 0 || attestationData.eggData.egg_A_2_5 > 0)  &&
                                            <ul>
                                                <li>
                                                    {findArrayElementByFieldNameAndValue(Composite8350Labels.eggStatementsChoicesA, 'eggData.egg_A_2_1', attestationData.eggData.egg_A_2_1)}
                                                    {findArrayElementByFieldNameAndValue(Composite8350Labels.eggStatementsChoicesA, 'eggData.egg_A_2_3', attestationData.eggData.egg_A_2_3)}
                                                    {findArrayElementByFieldNameAndValue(Composite8350Labels.eggStatementsChoicesA, 'eggData.egg_A_2_4', attestationData.eggData.egg_A_2_4)}
                                                    {findArrayElementByFieldNameAndValue(Composite8350Labels.eggStatementsChoicesA, 'eggData.egg_A_2_5', attestationData.eggData.egg_A_2_5)}
                                                </li>
                                            </ul>
                                            }
                                        </li>
                                    </ul>
                                </li>
                            )
                            :
                            (
                                <li>{ReactHtmlParser(Composite8350Labels.eggStatementsChoicesA[0].key)}</li>
                            )}


                            {attestationData.eggData.eggStatementsB == 2 ?(
                                <li>
                                    {ReactHtmlParser(Composite8350Labels.eggStatementsChoicesB[1].key)}
                                    <ul>
                                        <li>
                                            {findArrayElementByFieldNameAndValue(Composite8350Labels.eggStatementsChoicesB, 'eggData.egg_B_2', attestationData.eggData.egg_B_2)}
                                            {(attestationData.eggData.egg_B_2_1 > 0 || attestationData.eggData.egg_B_2_4 > 0)  &&
                                        
                                            <ul>
                                                <li>
                                                    {findArrayElementByFieldNameAndValue(Composite8350Labels.eggStatementsChoicesB, 'eggData.egg_B_2_1', attestationData.eggData.egg_B_2_1)}
                                                    {findArrayElementByFieldNameAndValue(Composite8350Labels.eggStatementsChoicesB, 'eggData.egg_B_2_4', attestationData.eggData.egg_B_2_4)}
                                                </li>
                                            </ul>
                                            }
                                        </li>
                                    </ul>
                                </li>
                            ):
                            (
                                <li>{ReactHtmlParser(Composite8350Labels.eggStatementsChoicesB[0].key)}</li>
                            )}
                        </ol>
                    </li>
                </ul>
            </li>
        )
        :
        (
            <li>II.3.D Does not contain processed egg products.
            </li>
            )
        }
    </>
    )
};

export default Egg8350 