export const health_1_3 = { 
    label: "the minced meat has been produced in compliance with Section V of Annex III to Regulation (EC) No 853/2004 and frozen to an internal temperature of not more than - 18 °C;"
}

export const health_1_5_statements = [
    { group: "bovinehealth_1_5", fieldname: "health_1_5_statements", value: "1", key: "the carcase or parts of the carcase have been marked with a health mark in accordance with Article 48 of and Annex II to Implementing Regulation (EU) 2019/627;"},
    { group: "bovinehealth_1_5", fieldname: "health_1_5_statements", value: "2", key: "the packages of [meat] [minced meat] have been marked with an identification mark in accordance with Section I of Annex II to Regulation (EC) No 853/2004;"},
]

export const BseRiskCategoryNegligibleStatementsChoice = [
    {group: "", fieldname: "BseRiskCategoryNegligibleStatements", value:1, key: "the <strong>animals</strong> from which the meat or minced meat is derived were born, continuously reared and slaughtered in a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a negligible BSE risk;" },
    {
        group: "",fieldname: "BseRiskCategoryNegligibleStatements", value: 2, key: "the <strong>animals</strong> from which the meat or minced meat is derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a controlled BSE risk, and:",
        subclauseName: "BseNegligibleB",
        subclauses: [
            {group: "BseNegligibleB", fieldname: "BseNegligibleB",  value: 3, key: "the meat or minced meat does not contain and is not derived from specified risk material as defined in of Annex V, point 1(a), to Regulation (EC) No 999/2001;" },
            {group: "BseNegligibleB", fieldname: "BseNegligibleB",  value: 4, key: "the carcases, half carcases or half carcases cut into no more than three wholesale cuts, and quarters contain no specified risk material as defined in Annex V, point 1(a), to Regulation (EC) No 999/2001 other than the vertebral column, including dorsal root ganglia, and the carcases or wholesale cuts of carcases of <strong>animals</strong> aged over 30 months and containing vertebral column are identified by a clearly visible red stripe on the label referred to in Article 13 or 15 of Regulation (EC) No 1760/2000;" },
            {group: "BseNegligibleB", fieldname: "BseNegligibleB",  value: 5, disabled: "disabled", key: "the <strong>animals</strong> from which the meat or minced meat is derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod-shaped instrument introduced into the cranial cavity;" },

        ]
    },
    {
        group: "",fieldname: "BseRiskCategoryNegligibleStatements", value: 6, key: "the <strong>animals</strong> from which the meat or minced meat is derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing an undetermined BSE risk and:",
        subclauseName: "BseNegligibleC",
        subclauses: [
            {group: "BseNegligibleC", fieldname: "BseNegligibleC",  value: 7, key: "the meat or minced meat does not contain and is not derived from specified risk material as defined in point 1(b) of Annex V to Regulation (EC) No 999/2001;" },
            {group: "BseNegligibleC", fieldname: "BseNegligibleC",  value: 8, key: "the carcases, half carcases or half carcases cut into no more than three wholesale cuts, and quarters contain no specified risk material as defined in Annex V, point 1(a), to Regulation (EC) No 999/2001 other than the vertebral column, including dorsal root ganglia, and the carcases or wholesale cuts of carcases of <strong>animals</strong> aged over 30 months and containing vertebral column are identified by a clearly visible red stripe on the label referred to in Article 13 or 15 of Regulation (EC) No 1760/2000 of the European Parliament and of the Council;" },
            {group: "BseNegligibleC", fieldname: "BseNegligibleC",  value: 9, disabled: "disabled", key: "the <strong>animals</strong> from which the meat or minced meat is derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod-shaped instrument introduced into the cranial cavity;" },
            {group: "BseNegligibleC", fieldname: "BseNegligibleC",  value: 10, disabled: "disabled", key: "the <strong>animals</strong> from which the meat or minced meat is derived have not been fed with meat-and-bone meal or greaves, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health;" },
            {group: "BseNegligibleC", fieldname: "BseNegligibleC",  value: 11, disabled: "disabled", key: "the meat or minced meat was produced and handled in a manner which ensures that they do not contain and were not contaminated with nervous and lymphatic tissues exposed during the deboning process;" },
        ]
    },

]
export const BseRiskCategoryControlledStatementsChoice = [
    {group: "BseRiskCategoryControlledStatements", fieldname: "BseRiskCategoryControlledStatements", value: 1, disabled: "disabled", key: "the <strong>animals</strong> from which the meat or minced meat is derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod-shaped instrument introduced into the cranial cavity; and" },
    {group: "BseRiskCategoryControlledStatements", fieldname: "BseRiskCategoryControlledStatements", value: 2,  key: "the meat or minced meat does not contain and is not derived from specified risk material as defined in Annex V, point 1(a) to Regulation (EC) No 999/2001; and"},
    {group: "BseRiskCategoryControlledStatements", fieldname: "BseRiskCategoryControlledStatements", value: 3,  key: "the carcases, half carcases or half carcases cut into no more than three wholesale cuts, and quarters contain no specified risk material as defined in Annex V, point 1(a), to Regulation (EC) No 999/2001 other than the vertebral column, including dorsal root ganglia, and the carcases or wholesale cuts of carcases of <strong>animals</strong> aged over 30 months and containing vertebral column are identified by a clearly visible red stripe on the label referred to in Article 13 or 15 of Regulation (EC) No 1760/2000; and"},
]
export const BseRiskCategoryControlledChoice_C = [
    {group: "", fieldname: "BseControlledC",  value: 1, key: "the <strong>animals</strong> from which the meat or minced meat is derived originate from a country or region classified in accordance with Decision 2007/453/EC as a <strong><u>country or region posing a negligible or a controlled BSE risk</strong></u>;" },
    {group: "", fieldname: "BseControlledC",  value: 2, key: "the <strong>animals</strong> from which the meat or minced meat is derived originate from a country or region classified in accordance with Decision 2007/453/EC as a <strong><u>country or region posing an undetermined BSE risk and</strong></u><ol className= 'list-clauses'><li>the <strong>animals</strong> from which the meat or minced meat is derived have not been fed with meat-and-bone meal or greaves, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health;</li><li>the meat or minced meat was produced and handled in a manner which ensures that it does not contain and was not contaminated with nervous and lymphatic tissues exposed during the deboning process;</li>"},
]
export const BseRiskCategoryUndeterminedStatementsChoice = [
    {group: "", fieldname: "BseUndetermined", value: 1, disabled: "disabled", key: "the <strong>animals</strong> from which the meat or minced meat is derived have not been:<ol className= 'list-clauses'><li>slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod-shaped instrument introduced into the cranial cavity;</li><li>fed meat-and-bone meal or greaves derived from ruminants, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health;</li></ol>"},
    {group: "", fieldname: "BseUndetermined", value: 2,  key: "the meat or minced meat does not contain and is not derived from:<ol className= 'list-clauses'><li>specified risk material as defined in point 1(b) of Annex V to Regulation (EC) No 999/2001;</li><li>nervous and lymphatic tissues exposed during the deboning process;</li></ol>"},
    {group: "", fieldname: "BseUndetermined", value: 3,  key: "the carcases, half carcases or half carcases cut into no more than three wholesale cuts, and quarters contain no specified risk material as defined in Annex V, point 1(a), to Regulation (EC) No 999/2001 other than the vertebral column, including dorsal root ganglia, and the carcases or wholesale cuts of carcases of <strong>animals</strong> aged over 30 months and containing vertebral column are identified by a clearly visible red stripe on the label referred to in Article 13 or 15 of Regulation (EC) No 1760/2000;"},
    {group: "", fieldname: "BseUndetermined", value: 4, disabled: "disabled", key: "the meat or minced meat does not contain and is not derived from nervous and lymphatic tissues exposed during the deboning process."},

]
export const health_1_11 = { 
    label: "it fulfils the requirements of Commission Regulation (EC) No 1688/2005."
}
export const animal_2_1_B_statements = [
    { group: "bovineanimal_2_1_B", fieldname: "animal_2_1_B_statements", value: "1", key: "in which <strong><u>foot and mouth disease</u></strong> has not been reported for a period of 12 months before the date of slaughter of the <strong>animals</strong> from which the fresh meat was obtained, and during the same period vaccination against this disease has not been carried out."},
    { group: "bovineanimal_2_1_B", fieldname: "animal_2_1_B_statements", value: "2", key: "in which <strong><u>foot and mouth disease</u></strong> has not been reported since ___/___/____."},
    { group: "bovineanimal_2_1_B", fieldname: "animal_2_1_B_statements", value: "3", key: "in which <strong><u>foot and mouth disease</u></strong> has not been reported for a period of 12 months before the date of slaughter of the <strong>animals</strong> from which the fresh meat was obtained and a vaccination programme against <strong><u>foot and mouth disease</u></strong> is being carried out in kept bovine <strong>animals</strong> under the supervision of the competent authority of the third country or territory."},
    { group: "bovineanimal_2_1_B", fieldname: "animal_2_1_B_statements", value: "4", key: "in which <strong><u>foot and mouth disease</u></strong> has not been reported for a period of 12 months before the date of slaughter of the <strong>animals</strong> from which the fresh meat was obtained and a vaccination programme against <strong><u>foot and mouth disease</u></strong> is being carried out in kept bovine <strong>animals</strong> under the supervision of the competent authority of the third country or territory; this supervision includes the control of the efficacy of the vaccination programme through regular serological surveillance that indicates adequate antibody levels in the <strong>animals</strong> and demonstrates the absence of <strong><u>foot and mouth disease</u></strong> virus circulation in the zone."},
    { group: "bovineanimal_2_1_B", fieldname: "animal_2_1_B_statements", value: "5", key: "in which <strong><u>foot and mouth disease</u></strong> has not been reported for a period of 12 months before the date of slaughter of the <strong>animals</strong> from which the fresh meat was obtained, and during the same period vaccination against this disease has not been carried out and the absence of the disease is controlled by the competent authority of the third country or territory through a regular serological surveillance demonstrating the absence of <strong><u>foot and mouth disease</u></strong> virus circulation."},

]
export const animal_2_2_statements = [
    { group: "bovineanimal_2_2", fieldname: "animal_2_2_statements", value: "1", key: "have remained in the country of slaughter since birth, or for at least 3 months before slaughter." },
    { group: "bovineanimal_2_2", fieldname: "animal_2_2_statements", value: "2", key: "have been introduced on ___/___/____ in the country of slaughter, from the zone with code ___ - __ that at that date was authorised for the entry of fresh meat of bovine and caprine <strong>animals</strong> into the Union and where they have remained since birth, or for at least 3 months before slaughter."},
    { group: "bovineanimal_2_2", fieldname: "animal_2_2_statements", value: "3", key: "have been introduced on ___/___/____ in the country of slaughter, from the Member State with ISO code _____."},

]
export const animal_2_3_E_statements = [
    { group: "bovineanimal_2_3_E", fieldname: "animal_2_3_E_statements", value: "1", key: "in and around which, within an area of 10 km radius, including where appropriate the territory of a neighbouring country, <strong><u>foot and mouth disease</u></strong> and infection with <strong><u>rinderpest virus</u></strong> have not been reported during the 30-day period before the date of slaughter;" },
    { group: "bovineanimal_2_3_E", fieldname: "animal_2_3_E_statements", value: "2", key: "in and around which, in an area of 25 km radius, including where appropriate the territory of a neighbouring country, <strong><u>foot and mouth disease</u></strong> and infection with <strong><u>rinderpest virus</u></strong> has not been reported during the 60-day period before the date of slaughter;" },
    { group: "bovineanimal_2_3_E", fieldname: "animal_2_3_E_statements", value: "3", key: "in and around which, within an area of 10 km radius, including where appropriate the territory of a neighbouring country, <strong><u>foot and mouth disease</u></strong> and infection with <strong><u>rinderpest virus</u></strong> has not been reported during the 12 month period before the date of slaughter;" },

]
export const animal_2_3_F_A = {
    label: "in which the <strong>animals</strong> have remained for a period of at least 40 days before being directly dispatched to a slaughterhouse."
}
export const animal_2_3_F_B = {
    label: "in which the <strong>animals</strong> have remained for a period of at least 40 days before passing through one single assembly centre approved by the competent authority in accordance with Article 20(2), point (b), of Delegated Regulation (EU) 2020/692 without coming into contact with <strong>animals</strong> of a lower health status before being dispatched directly to a slaughterhouse."
}
export const animal_2_3_G = {
    label: "in which: no <strong>animals</strong> have been introduced during the last 3 months from zones not authorised to enter fresh meat of bovine <strong>animals</strong> into the Union; <strong>animals</strong> are identified and registered in the national System of Identification and Certification of Origin for bovine <strong>animals</strong>;"
}
export const animal_2_4_C = { 
    label: "have been slaughtered"
}
export const animal_2_4_E = { 
    label: "at the slaughterhouse have been kept completely separated from <strong>animals</strong> the meat of which is not intended for the Union prior to slaughter."
}
export const animal_2_6_statements = [
    { group: "bovineanimal_2_6", fieldname: "animal_2_6_statements", value: "1", key: "it was packaged for further storage." },
    { group: "bovineanimal_2_6", fieldname: "animal_2_6_statements", value: "2", key: "its loading, as unpackaged fresh meat, to the means of transport for dispatch to the Union" },
]
export const animal_2_7 = { 
    label: "is <strong>de-boned fresh meat, other than offal</strong>, obtained from carcases:"
}
export const animal_2_7_A = { 
    label: "(i) in which the main accessible lymph nodes have been removed; (ii) which have been submitted to maturation at a temperature above +2°C for at least 24 hours before the bones were removed; and (iii) in which the pH value of the meat was below 6.0 when tested electronically in the middle of the longissimus-dorsi muscle after maturation and before de-boning."
}
export const animal_2_7_B = { 
    label: "(i) in which the main accessible lymph nodes have been removed; and (ii) which have been submitted to maturation at a temperature above +2°C for at least 24 hours before the bones were removed."
}
