

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faEdit, faEye, faTrashAlt, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Table, Card, Pagination, Nav, Image, Dropdown, Button, ButtonGroup, Container } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { useTable } from "react-table";
import { NoResults} from "./NoResults";
import Preloader from "../../components/Preloader";
import { Routes } from "../../routes";

export const SiteTable = (props) => {
  const { sites, loaded = false} = props;
  const totalTransactions = sites.length;

  const TableRow = (props) => {
    const {id,  establishmentNumber, siteName, supplierCode, townCity, dueDate, status } = props;
    const statusVariant = status === "Paid" ? "success"
      : status === "Due" ? "warning"
        : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          {/* <Card.Link as={Link} to={Routes.SiteViewPage.path.replace(":id",id)} className="fw-normal text-black"> */}
          <Card.Link as={Link} to={Routes.SiteViewPage.path.replace(":id",id)} className="fw-normal text-black">
            {siteName}
          </Card.Link>
        </td>
        <td> 
          <span className="fw-normal">
            {establishmentNumber}
          </span>
        </td>
        <td> 
          <span className="fw-normal">
            {townCity}
          </span>
        </td>
        {/* <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td> */}
      </tr>
    );
  };

  return (
    <>
    <Preloader component={true} show={!loaded} />
      <Table hidden={!loaded || sites==0} hover className="user-table align-items-center">
        <thead>
          <tr>
            <th className="border-bottom">Site Name</th>
            <th className="border-bottom">Establishment Number</th>
            <th className="border-bottom">Town / city</th>
            {/* <th className="border-bottom">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {sites.map(t => <TableRow key={`transaction-${t.id}`} {...t} />)}
        </tbody>
      </Table>
      {loaded && sites.length == 0 &&(
        <NoResults name="sites"/>
      )}
    </>
  );
};
