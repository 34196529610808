
import { Col, Container, Image, ListGroup, Navbar, Row } from '@themesberg/react-bootstrap';
import Cookies from 'js-cookie';
import React, { useState } from "react";
import ThemeToggle from './ToggleSwitch';

export default (props) => {
  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }
  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 ">
      <Container fluid className="px-0">
        <div className="theme-button-container">
          <ThemeToggle />
        </div>
      </Container>
    </Navbar>
  );
};
