import React, {useEffect} from 'react';
import * as AttestationContent from "../../../Common/AttestationContent";
import * as Composite8350AttestationLabels from "../../../../Products/Composite/CompositeAttestation/Composite8350AttestationLabels";
import {  useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract} from '@fortawesome/free-solid-svg-icons';
import {  Button,Card, Col, Row } from "@themesberg/react-bootstrap";

export default function ResponseAttestation(props) {
  const { values: formValues } = useFormikContext();
  const {
    formField: {
        firstInspection,
        changes, 
        changeStatement,
        changesDescription,
        dateInspection,
        inspectionType,
        isGEFS
    },
    setFieldValue,
    initialInspectionDate,
    uniqueRef,
    product, 
    manufacturer,
    site, 
    user,
    productDetail,
    speciesList, 
    attestationData,
    attestationRequestData,
    certificateNumber
  } = props;
  
  const inspectionTypeChoice = [
    { group: "inspectionTypeChoice",   fieldname: "inspectionTypeChoice1",  value: 1, key: "Physical inspection" },
    { group: "inspectionTypeChoice",   fieldname: "inspectionTypeChoice2",  value: 2, key: "Remote inspection"},
  ];

  useEffect(() => {
    {formValues.dateInspection===""&&  setFieldValue("dateInspection",initialInspectionDate)}
  },[initialInspectionDate,]);


  return (
    <Row className="justify-content-left mt-4">
      <Col xl={11}>
        <Card border="light" className="bg-white shadow-sm mb-4 p-3">
          <Card.Body>
              <Button 
                  variant="light" size="md" className="d-print-none"
                  href={attestationRequestData.signedDocument?.filename}
                  target="_blank"> 
                  <FontAwesomeIcon icon={faFileContract} className="me-2" />
                  <span>View Supplier Declaration</span>
              </Button>

              <hr className="my-3 d-print-none"/>
              <AttestationContent.VetDeclaration
                uniqueRef={uniqueRef}
                firstInspection={firstInspection}
                changes={changes}
                changeStatement={changeStatement}
                changesDescription={changesDescription}
                site={site}
              />
              
              <Row className="mt-3">
          
                <Col>
                  <AttestationContent.AttestationResponseVetInspection
                    dateInspection={dateInspection}
                    inspectionType={inspectionType}
                    inspectionTypeChoice={inspectionTypeChoice}
                    initialInspectionDate={initialInspectionDate}
                    site={site}
                  />
                </Col>
              </Row>

              <h4>Vet</h4>
              <ul className="list-unstyled">
                <li>Signed name: {user.fullName}</li>
                <li>RCVS number: {user.rcvs}</li>
                <li>Practice name: {user.practiceName}</li>
                <li>Email address: {user.email}</li>
                <li>Address: {user.address}</li>
              </ul>

          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
