import { useCallback } from 'react';
import ReportService from "../../../services/ReportService";

const useReportGenerator = (setSubmittingState) => {
    const generateReport = useCallback((reportType) => {
        // Set the submitting state for the specific report
        setSubmittingState(prevState => ({ ...prevState, [reportType]: true }));

        // Define the service method based on the report type
        const serviceMethod = reportType === "GEFS" ? 
                              ReportService.generateRetailerGEFSReport : 
                              ReportService.generateRetailerNonGEFSReport;

        // Call the service method
        serviceMethod({
            responseType: 'blob' // This ensures you get the response in a Blob format
        })
        .then((response) => {
            if (response.status === 200) {
                var url = response.data;
                window.open(url, '_blank');
            }
        }).catch((error) => {
            console.error(`Error generating ${reportType} report:`, error);
        }).finally(() => {
            // Reset the submitting state regardless of the outcome
            setSubmittingState(prevState => ({ ...prevState, [reportType]: false }));
        });
    }, [setSubmittingState]);

    return { generateReport };
};

export default useReportGenerator;
