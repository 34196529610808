import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Formik, Form, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import ProductHighlyRefinedService from "../../../services/ProductHighlyRefinedService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ProductService from "../../../services/ProductService";
import * as HighlyRefined8399Labels from "./HighlyRefined8399Labels"
import { MultiSelectField } from "../../common/MultiSelect";
import { Button, Card, Alert } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import SiteService from '../../../services/SiteService';
import EstablishmentsService from '../../../services/EstablishmentsService';
import SiteSelect from '../../../../components/EstablishmentSelect/SiteSelect';
import ActivityService from '../../../services/ActivityService';
const HighlyRefined8399AddPage = (props) => {
    const certificateNumber = 8399;
    const [isSubmitting,  setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [formVersion] = useState(GetCertificateFormVersion(certificateNumber));
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [specieslist, SetSpecies] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [apiValues, SetApiValues] = useState([]);
    const [siteEstablishment, setSiteEstablishment] = useState([]);
    const [manufacturingPlantEstab, setManufacturingPlantEstab] = useState([]);

    let title = (editing == true ? "Edit " : "New") + " Highly Refined Information (#8399-For Highly Refined products only)"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Highly Refined 8399', link: Routes.HighlyRefined8399AddPage.path },
    };
    const initialValues = {
        productId: props.match.params.id,
        health_1: apiValues?.health_1 ?? false,
        health_1_statements: apiValues?.health_1_statements ?? [],
        health_2: apiValues?.health_2 ?? false,
        health_2_statements: apiValues?.health_2_statements ?? 0,
        health_3: apiValues?.health_3 ?? false,
        manufacturingPlant: apiValues?.manufacturingPlant ?? '',
        identificationMark: apiValues?.identificationMark ?? '',
        identificationMarkEstablishment: apiValues?.identificationMarkEstablishment ?? siteEstablishment[0],
        manufacturingPlantEstablishments: apiValues?.manufacturingPlantEstablishments ?? [],
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";


    //Validation
    const validationSchema = Yup.object().shape({
        health_1_statements: Yup.array().nullable()
            .when("health_1", {
                is: true,
                then: Yup.array().min(1, validationMsgAtLeastOne)
            }),
        health_2_statements: Yup.number().when("health_2", {
            is: true,
            then: Yup.number().min(1, validationMsgAtLeastOne)
        }).min(1, validationMsgAtLeastOne),
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        identificationMarkEstablishment: Yup.object().nullable().required(validationMsgRequired),
        manufacturingPlantEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
    });

    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;
            console.log(submitData);

            if (apiValues.length === 0) {
                ProductHighlyRefinedService.create(submitData)
                    .then(async response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Highly Refined 8399',
                            8399,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            else {
                ProductHighlyRefinedService.update(productId, values)
                    .then(async response => {
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Highly Refined 8399',
                            8399,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            console.log(JSON.stringify(values, null, 2));
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);



    useEffect(() => {
        //Get site estab
        ProductService.getProductDetailByProductId(productId)
            .then(response4 => {
                //Get site operatorId
                SiteService.getById(response4.data.siteId)
                    .then(response2 => {

                        //Get establishment data from operatorid
                        EstablishmentsService.getEstablishmentsDetails([response2.data.tracesId])
                            .then(response3 => {
                                //set parent site for this product
                                setSiteEstablishment(response3.data)
                            }, [])
                            .catch(e => {
                                console.log(e);
                            });
                    }, [])
                    .catch(e => {
                        console.log(e);
                    });
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductHighlyRefinedService.getByProductId(productId)
            .then(response => {
                var res = response.data;
                setManufacturingPlantEstab(res.manufacturingPlantEstablishments)
                if (response.data == "") {
                    return;
                }
                SetApiValues(response.data);
                setEditing(true);
                setReady(true);
                console.log("init values:" + apiValues);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductService.getById(productId)
            .then(response => {
                SetProduct(response.data.data);
                setIsGEFS(response.data.data.isGEFS);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductScientificSpeciesDataService
            .getOnlyHighlyRefined()
            .then((_speciesResponse) => {
                SetSpecies(_speciesResponse.data);
            })
            .finally(() => {
            });
    }, []);

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);

    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();
        useEffect(() => {
            setManufacturingPlantEstab(values?.manufacturingPlantEstablishments)
        }, [values]);

        return null;
    };


    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <>
                    <SubmissionPage
                        productId={productId}
                        attestationId={attestationId}
                        isGEFS={isGEFS}
                        errors={errors}
                        title={(editing ? "Edited" : "Completed") + " Highly Refined 8399 Form"}
                        message={`Highly Refined information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                    />

                </>

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        {/* <PrettyPrintJson data={props.values} /> */}
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        <div className="">

                                            <h4 className="mt-5">Health Information</h4>

                                            <p>The product that falls under Export health certificate EHC 8399 meets the criteria
                                            </p>
                                            <MultiSelectField
                                                id='productScientificSpecies'
                                                name='productScientificSpecies'
                                                label="Species"
                                                placeholder="Select fields"
                                                options={specieslist}
                                                defaultValues={props.values?.productScientificSpecies}
                                                getOptionValue={option => option.id}
                                                getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                                value={props.values.productScientificSpecies}
                                                isMulti={true}
                                                onChange={props.setFieldValue}
                                                onBlur={props.setFieldTouched}
                                                touched={props.touched.productScientificSpecies}
                                                error={props.errors.productScientificSpecies}
                                                isClearable={true}
                                                isrequired="true"
                                                backspaceRemovesValue={true}
                                                col={4}
                                            />
                                            <br></br>
                                            <FormController
                                                control="checkbox"
                                                label={HighlyRefined8399Labels.health_1.label}
                                                name="health_1"
                                                readOnly={readOnly}
                                            />

                                            {props.values.health_1 && (
                                                <div className=" subform">
                                                    <FormController
                                                        control="checkboxClauses"
                                                        label=""
                                                        name="health_1_statements"
                                                        options={HighlyRefined8399Labels.health_1_statements}
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                            )}
                                            <FormController
                                                control="checkbox"
                                                label={HighlyRefined8399Labels.health_2.label}
                                                name="health_2"
                                                readOnly={readOnly}
                                            />

                                            {props.values.health_2 && (
                                                <div className=" subform">
                                                    <FormController
                                                        control="radio"
                                                        label=""
                                                        name="health_2_statements"
                                                        options={HighlyRefined8399Labels.health_2_statements}
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                            )}
                                            <FormController
                                                control="checkbox"
                                                label={HighlyRefined8399Labels.health_3.label}
                                                name="health_3"
                                                readOnly={readOnly}
                                            />
                                            <SiteSelect
                                                name="manufacturingPlantEstablishments"
                                                selectedFieldName="manufacturingPlantEstablishments"
                                                setEstablishmentSelected={props.setFieldValue}
                                                selections={props.values.manufacturingPlantEstablishments}
                                                label="Manufacturing Plant"
                                                isMulti={true}
                                                countries={["GB"]}
                                                certificateNumber={certificateNumber}
                                                getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                isRequired={true}
                                                readOnly={readOnly}
                                            />
                                            <MultiSelectField
                                                id='identificationMarkEstablishment'
                                                name='identificationMarkEstablishment'
                                                label="Identification mark"
                                                placeholder="Select fields"
                                                options={Array.from(new Map([...(siteEstablishment || []), ...(manufacturingPlantEstab || [])].map(item => [item["approvalNumber"], item])).values())}
                                                getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                isMulti={false}
                                                onChange={props.setFieldValue}
                                                onBlur={props.setFieldTouched}
                                                touched={props.touched.identificationMarkEstablishment}
                                                error={props.errors.identificationMarkEstablishment}
                                                isClearable={true}
                                                isrequired="true"
                                                backspaceRemovesValue={true}
                                                readOnly={readOnly}
                                                col={6}
                                            />


                                        </div>

                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>
                                        <ChangeFormValues />

                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default HighlyRefined8399AddPage;