import React from 'react';
import { Button, Card, Col, Row, Badge } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import PermissionsCheck from '../../../../auth/PermissionCheck';
import { scopes } from "../../../../auth/PermissionsMap";
import moment from "moment-timezone";

export const ProductSummary = (props) =>{
   const {isStorageDeclaration, product, manufacturer, visibility, isGEFS, timeLengthDelivered, openProductEdit} = props;

return(
   <Card border="light" className="h-100 bg-white shadow-sm">
      <Card.Header>
         <Row className="">
            <Col>
                  <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.products.update]}>
                     <Button onClick={openProductEdit} variant="light" size="sm" className="float-end text-end">
                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                        <span>Edit</span>
                     </Button>
                  </PermissionsCheck>
                  <h4 className="mb-0 text-title" >Summary</h4>
            </Col>
         </Row>
      </Card.Header>
      <Card.Body>
         <Col>
            <Row>
                  <Col sm={12} lg={6}><small>Product Code:</small> <h6>{product?.productCode === null ? "N/A" : product?.productCode}</h6></Col>
                  <Col sm={12} lg={6}><small>EAN:</small> <h6>{(product?.ean == null || product?.ean === "") ? "N/A" : product?.ean}</h6></Col>
                  <Col sm={12} lg={6}><small>Manufacturer:</small> <h6>{(manufacturer === null || manufacturer === "") ? "N/A" : manufacturer}</h6></Col>
                  <Col sm={12} lg={6}><small>Visibility:</small> 
                     {visibility=="Active" &&
                        <h6 className="text-success">Active</h6>
                     }
                     {visibility=="Suspended" &&
                        <h6 className="text-warning">Suspended</h6>
                     }
                     {visibility=="Delisted" && <h6>Delisted</h6>}
                     </Col>
                  <Col sm={12} lg={6}><small>Length of time delivered:</small> <h6>{product?.timeLengthDelivered === null ? "N/A"  : moment(product?.timeLengthDelivered).format("DD/MM/YYYY")}</h6></Col>
                  <Col sm={12} lg={6}> 
                     <div className='fs-5'>
                     
                     {isStorageDeclaration ?
                           <Badge className="rounded ms-1 my-3 px-3 py-2 bg-blue text-white d-inline"> Storage</Badge>
                           :
                     isGEFS? 
                           <Badge className="rounded my-3 px-3 py-2 bg-success"> GEFS</Badge>
                           : 
                           <Badge className="rounded my-3 px-3 py-2 bg-warning d-inline"> Non-GEFS</Badge>
                     }  
                     </div>
                  </Col>
                  
            </Row>
         </Col>
      </Card.Body>
   </Card>
)

}

export default ProductSummary;