import React from "react";
import { ProgressBar } from '@themesberg/react-bootstrap';

export default (props) => {
  const { label, variant, value, type = "label", size = "md" } = props;
  const finalValue = value ? Math.round(value) : 0; // generates a random number between 20 and 100
  const textColor = type === "label" ? variant : "white";
  const bgColorClass = type === "tooltip" ? `bg-${variant}` : "";
  const height = "h25px";

  return (
    <div className="progress-wrapper">
      <div className="progress-info">
        <div className={`progress-${type} text-${textColor} ${bgColorClass} `}>
          {label}
        </div>
        <div className="progress-percentage">
          <span>{finalValue}%</span>
        </div>
      </div>
      <ProgressBar striped className={`progress-${size}"`} height="20px" variant={variant} animated now={finalValue} min={0} max={100} />
    </div>
  );
};
