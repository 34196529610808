import { Col, Row} from "@themesberg/react-bootstrap";
import moment from 'moment-timezone';
import {  useFormikContext } from 'formik';
import FormController from "../../../common/FormController";

export const ExporterIntro = (props) =>{
    const {uniqueRef, user, platform, organisation} = props;
    return(
        <>
            <div className="attestation-title text-center">
                <h2>Exporter Declaration - Re-export of EU manufactured goods</h2>
                <label>UNIQUE DOCUMENT REFERENCE NUMBER:</label>
                <h4>{uniqueRef}</h4>
            </div>
            <Row className="mt-4 attestation-statement">
                <Col md={12}>
                    <div>
                        <h4 className="mb-3">I. Exporter Declaration</h4>
                        <p>
                            I, being <strong>{user ? user.fullName : ''}</strong>, of <strong>{platform?.platformName}, {platform?.fullAddress} </strong> 
                            have authority and responsibility to sign this declaration on behalf of this exporting company. 
                            I confirm that all information given below is accurate, correct, and complete.                          
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    )
} 
export const IntroStatement = (props) =>{
    const {organisation} = props;
    return(
        <>
            <p>
                I confirm that all information given below is accurate, correct, and complete. This document is <strong>valid for 6
                months</strong> from the date of signature, if there will be any changes regarding the information below I will
                immediately notify the official veterinarian.
            </p>
            <p>
                I understand that supplying false or misleading declarations that will be relied upon by the Official
                Veterinarian in respect of the verifications provided in the relevant export health certificate - <i>Re-export of
                Products of Animal Origin of European Union or Northern Ireland origin back to the European Union or
                Northern Ireland after storage in Great Britain: certificate 8461</i> - is an offence and may result in rejection of the
                exported product, as well as risk of liability for costs incurred.</p>
            <p>
                The product/s listed in Section I.A in the below consignment have been manufactured in the European
                Union and fulfill the attestations listed in Section I.B. The products are supplied to <strong>{organisation.name}, ({organisation.fullAddress})</strong> (name of the exporter) to be re-exported into the European Union. I will ensure the exporter is immediately
                informed if any changes are made that affect the validity of this document and/or if I leave the employment of
                the supplying company detailed above. I understand that in such cases this declaration will immediately become
                null and void.
            </p>
        </>
    )
}
export const CertificateFormDetails = (props) => {
    const { site, coldStores =[] } = props;
    return (
        <>
        <h4>B. Health Information</h4>
        <p>The consignment of products of animal origin or goods described above fulfil the next requirements:</p>
        <strong>products of animal origin or goods</strong> described in
        <ul>
            <li>originate from and has been produced in the Union and was eligible for placing on the market in the Union, and</li>
            <li>was packed in the Union and, for products of animal origin, marked in the Union in accordance with Section I of Annex II to Regulation (EC) No 853/2004, and</li>
            <li>is destined to the Union, and</li>
            <li>has not been tampered and did not undergo and other handling than unloading, storage, re-loading, and transporting in <strong>GB-0</strong> and for 
                products of animal origin has been stored and transported in accordance with the relevant requirements of Annex III to Regulation (EC) No 853/2004 of the European Parliament and of the Council.
            </li>
        </ul>
        <p>has been stored in {site?.isApproved ? "an approved" : "a registered"} establishment(s); {site?.establishmentNumber}{coldStores.length > 0 ? ", " + coldStores.map(x => x.approvalNumber).join(", ") : ""}</p>
        </>
    )
};
export const ConsignmentDetails = (props) => {
    const { manufacturer, batches, product, productDetail, site, certificateFormData, platform, temperature, finalConsumer = null  } = props;
    return(
        <>
        <h4>A. Details of Consignment</h4>
        <h6>1. Origin and Destination</h6>
        <p>
            a) Address and, if available, {platform?.establishmentNumberTypeName?.toLowerCase()} of the establishment(s) from which the consignment will be dispatched (e.g. supplier):<br />
            <strong>{site?.siteName}, {site?.fullAddress}. {site?.establishmentNumberTypeName}: {site?.establishmentNumber}</strong>
        </p>
        <p>
            b) Address and, if available, {site?.establishmentNumberTypeName?.toLowerCase()} of the establishment(s) to which the consignment will be dispatched (e.g. exporting depots):<br />
            <strong>{platform?.platformName}, {platform?.fullAddress}. {platform?.establishmentNumberTypeName}: {platform?.establishmentNumber}</strong>
        </p>
        
        <h6 className="mb-3">2. Description of the product(s)</h6>
        <p>
            Product specific details of all products to which this Support Attestation relates (this may be attached as a
            schedule ).
        </p>

        <table border="0" cellspacing="6" cellpadding="6">
            <tbody>
                <tr>
                    <td width="40%">
                        Name of Product:&nbsp;
                    </td>
                    <td>
                        <strong>{product.productName}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        EAN:&nbsp;
                    </td>
                    <td>
                        <strong>{product.ean}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Supplier name:&nbsp;
                    </td>
                    <td>
                        <strong>{manufacturer?.manufacturerName}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Supplier code:&nbsp;
                    </td>
                    <td>
                        <strong>{manufacturer?.supplierCode}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Country of origin:&nbsp;
                    </td>
                    <td>
                        <strong>{productDetail?.countryOrigin?.isoCode}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Cold store:&nbsp;
                    </td>
                    <td>
                        <strong>{certificateFormData?.coldStoreEstablishments?.map(x => x.approvalNumber).join(", ")}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Temperature:&nbsp;
                    </td>
                    <td>
                        <strong>{temperature.temperatureName}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Commodity code:&nbsp;
                    </td>
                    <td>
                        <strong>{productDetail.commodityHscode}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Commodity nature:&nbsp;
                    </td>
                    <td>
                        <strong>{productDetail?.commodityNature}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Identification mark:&nbsp;
                    </td>
                    <td>
                        <strong>{certificateFormData.identificationMarkEstablishment?.approvalNumber}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Species:&nbsp;
                    </td>
                    <td>
                        <strong>{certificateFormData?.productScientificSpecies?.map(x => x.speciesName).join(", ")}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Type of packaging:&nbsp;
                    </td>
                    <td>
                        <strong>
                            Shelf packaging:&nbsp;{productDetail.packagingTypeShelf}
                        </strong>
                        <br/>
                        <strong>
                            Outer packaging:&nbsp;{productDetail.packagingTypeOuter}
                        </strong>
                        <br/>
                        <strong>
                            Retail packaging:&nbsp; {productDetail.packagingTypeRetail}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Final consumer:&nbsp;
                    </td>
                    <td>
                        <strong>{finalConsumer ? "Yes" : "No"}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Manufacturing plant:&nbsp;
                    </td>
                    <td>
                        <strong>{site?.siteName +" , " + site?.establishmentNumber}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Batches:&nbsp;
                    </td>
                    <td>
                        <strong>{batches}</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Net weight
                    </td>
                    <td>
                        <strong>{productDetail?.netWeight}kg</strong>
                    </td>
                </tr>
                <tr>
                    <td width="40%">
                        Gross weight
                    </td>
                    <td>
                        <strong>{productDetail?.grossWeight}kg</strong>
                    </td>
                </tr>
            </tbody>
        </table>
        </>
    )
}
export const SupplierSignature = (props) => {
    const { manufacturer, user, date } = props;
    return(
        <>
            <h5 className="authorised-by">&nbsp;</h5>
            <ul className="list-unstyled">
                <li><h1 className="d-none d-print-block signature-placeholder">[sig|req|signer1]</h1></li>
                <li><strong>Name: {user.fullName}</strong></li>
                <li><strong>Email address: {user.email}</strong></li>
                <li><strong>Job title: {user?.jobTitle}</strong></li>
                <li><strong>Date: {moment(date).format("Do MMMM YYYY")}</strong></li>
            </ul>
        </>
    )

}
export const NumberPackages = (props) => {
    const { numberPackages, isReadOnly } = props;
    return (
        <>
            {isReadOnly ? (
                <>
                    <p className="mt-3 mb-0">{numberPackages.label}</p>
                    <strong> {numberPackages}</strong>
                </>
            )
            :
            (
                <Row className="">
                    <Col md={6}>
                        <FormController
                            control="input"
                            isrequired="true"
                            type="number"
                            className="mb-3"
                            col={2}
                            label={numberPackages.label}
                            name={numberPackages.name}
                        />
                    </Col>
                </Row>
            )}
        </>
    )
};
export const FinalConsumer = (props) => {
    const { finalConsumer, isReadOnly } = props;
    return (
        <>
        {isReadOnly ? (
        <>
            <p className="mt-3 mb-0">
                Final Consumer: <strong>{finalConsumer ? "Yes" : "No"}</strong>
            </p>
        </>
        ):
        <FormController
            control="checkbox"
            isrequired={"true"}
            className="mt-3"
            label={finalConsumer.label}
            name={finalConsumer.name}
        />
        }
        </>
    )
};
export const InvoiceNumber = (props) => {
    const { invoiceNumber, isReadOnly } = props;
    return (
        <>
        {isReadOnly ? (
        <>
            <p className="mt-3 mb-0">
                {invoiceNumber.label}: <strong>{invoiceNumber.name}</strong>
            </p>
        </>
        ):
        <FormController
            control="input"
            isrequired={"true"}
            className="mt-3"
            label={invoiceNumber.label}
            name={invoiceNumber.name}
        />
        }
        </>
    )
};
export const Batches = (props) => {
    const { batches, isReadOnly } = props;
    return (
        <>
            {isReadOnly ? (
                <>
                    <p className="mt-3 mb-0">The batch that will be covered under this Storage Declaration</p>
                    <strong>Batch number: {batches}</strong>
                </>
            )
            :
            (
                <Row className="">
                    <Col md={12}>
                        <FormController
                            control="input"
                            label={batches.label}
                            isrequired={"true"}
                            name={batches.name}
                            col={3}
                            sublabel="The batches that will be covered under this Storage Declaration"
                        />
                    </Col>
                </Row>
            )}
        </>
    )
};
export const Weights = (props) => {
    const { netWeight, grossWeight, isReadOnly } = props;
    return (
        <>
            {isReadOnly ? (
                <>
                    <strong>{netWeight.label}: {netWeight.name}</strong>
                    <br/>
                    <strong>{grossWeight.label}: {grossWeight.name}</strong>
                </>
            )
            :
            (
                <Row className="">
                    <Col md={3}>
                        <FormController
                            control="input"
                            type="number"
                            label={netWeight.label}
                            name={netWeight.name}
                            isrequired="true"
                        />
                    </Col>
                    <Col md={3}>
                        <FormController
                            control="input"
                            type="number"
                            label={grossWeight.label}
                            name={grossWeight.name}
                            isrequired="true"
                        />

                    </Col>
                </Row>
            )}
        </>
    )
};
export const ValidDate = (props) => {
    const { site } = props;
    return (
        <>
            <label className="mt-3">Current Date of Expiry</label><br />
            {moment().add(6, 'months').format("D MMMM, YYYY")}
            <p className="mt-3">This Storage Declaration is valid only for 6 months from the date of this declaration.</p>
        </>
    )
}