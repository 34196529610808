import *  as baseService  from "./BaseService";

const getByProductId = (id) => {
    return baseService.getApiData("/productgelatine/getByProductId?id=" + id);
};

const create = (data) => {
    return baseService.postApiData("/productgelatine/Create", data);
};

const update = (id, data) => {
    return baseService.putApiData("/productgelatine/update?id=" + id, data);
};

const ProductGelatineService = {
    create,
    getByProductId,
    update
};

export default ProductGelatineService;