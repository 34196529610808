
import * as yup from 'yup';

const validationMsgRequired = "This field is required";
const validationMsgAtLeastOne = "Select at least one of these options";
const validationMsgDecimal = "Enter a decimal to a maximum of 3 decimal places (e.g 10.000)";

export const validationSchema = yup.object().shape({
    platform: yup.object().nullable().required(validationMsgRequired),
    // countryOriginId: yup.object().shape({
    //     label: yup.string().required(),
    //     value: yup.string().required()
    // }).typeError(validationMsgRequired),
    regionOfOrigin: yup.object().nullable()
        .when("zonesVaildationRequired", {
            is: true,
            then: yup.object().shape({
                label: yup.string().required(),
                value: yup.string().required()
            }).typeError(validationMsgRequired)
        }),
    site: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required()
    }).typeError(validationMsgRequired)
    ,
    certificateNumber: yup.array().of(yup.string())
        .min(1, validationMsgRequired),
    description: yup.string().max(150).trim().required(validationMsgRequired),
    commodityHscode: yup.object().nullable()
        .when("certificateNumber", (cert) => {
            if (cert.length === 1 &!cert.includes("8461") &!cert.includes("8384") & !cert.includes("8392") & !cert.includes("8393"))
                return yup.object().nullable().shape({
                    label: yup.string().required(validationMsgRequired),
                    value: yup.string().required(validationMsgRequired)
                }).required(validationMsgRequired)
        }),
    weightType: yup.string().notOneOf(["0"], validationMsgRequired).required(validationMsgRequired),
    commodityHscodeFreeText: yup.string()
        .when("certificateNumber", (cert) => {
            if (cert.includes("8384") || cert.includes("8461") || cert.includes("8392") || cert.includes("8393") || cert.length > 1)
                return yup.string().required(validationMsgRequired)
        }),
    natureOfCommodity: yup.string().max(110),
    natureOfCommodityMultiSelect: yup.array()
        .when("certificateNumber", (cert) => {
            if (cert.length == 1 && cert.includes("8350") || cert.includes("8461") )
                return yup.array().min(1, validationMsgAtLeastOne)
                    .required(validationMsgRequired)
        }),
    packagingTypeOuter: yup.string().max(100).trim().required(validationMsgRequired),
    packagingTypeShelf: yup.string().max(100).trim().required(validationMsgRequired),
    packagingTypeRetail: yup.string().max(100).trim().required(validationMsgRequired),
    netWeight: yup
        .number().typeError("Must enter a decimal")
        .required(validationMsgRequired)
        .min(0, "Must not be a negative number").test('is-decimal', validationMsgDecimal,
        (value) => /^\d{1,30}(\.\d{0,3})?$/.test(value)
    ),
    temperature: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required()
    }).typeError(validationMsgRequired)
    ,
    grossWeight: yup
        .number().typeError("Must enter a decimal")
        .required(validationMsgRequired)
        .min(0, "Must not be a negative number")
        .min(yup.ref("netWeight"), "Gross weight must not be less than net weight").test('is-decimal', validationMsgDecimal,
        (value) => /^\d{1,30}(\.\d{0,3})?$/.test(value)
    ),
});
