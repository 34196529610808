import React from "react"
import Input from "./Input"
import TextArea from "./TextArea"
import Select from "./Select"
import RadioButtons from "./RadioButton"
import CheckBox from "./Checkbox"
import CheckBoxes from "./Checkboxes"
import CheckBoxClauses from "./CheckBoxClauses"
import RadioButtonClauses from "./RadioButtonClauses"
import MultipleFileUpload from "./MultipleFileUpload"
import {MultiSelectField} from "./MultiSelect"
import DateInput from "./DateInput"
import RadioButtonSingle from './RadioButtonSingle';
import RadioButtonCard from './RadioButtonCard';
import File from "./File"


function FormController(props) {
    const { control, ...rest } = props
    switch (control) {
        case "file":
            return <File {...rest} />
        case "input":
            return <Input {...rest} />
        case "textarea":
            return <TextArea {...rest} />
        case "select":
            return <Select {...rest} />
        case "multiselect":
            return <MultiSelectField {...rest} />
        case "radio":
            return <RadioButtons {...rest} />
        case "radioSingle":
            return <RadioButtonSingle {...rest} />
        case "radioClauses":
            return <RadioButtonClauses {...rest} />
        case "radioButtonCards":
            return <RadioButtonCard {...rest} />
        case "checkbox":
            return <CheckBox {...rest} />
        case "checkboxes":
            return <CheckBoxes {...rest} />
        case "dateInput":
            return <DateInput {...rest} />
        case "checkboxClauses":
            return <CheckBoxClauses {...rest} />
        case "multipleFileUpload":
            return <MultipleFileUpload {...rest} />
        default:
            return null
    }
}
export default FormController