import *  as baseService  from "./BaseService";

const getAllByConsignmentId = (consignmentId) => {
    return baseService.getApiData("/ConsignmentContents/GetAllByConsignmentId?consignmentId=" + consignmentId);
};

const getAllWithAttestationsByConsignmentId = (consignmentId) => {
    return baseService.getApiData("/ConsignmentContents/GetAllWithAttestationsByConsignmentId?consignmentId=" + consignmentId);
};
const updateDetails = (data) => {
    return baseService.putApiData("/ConsignmentContents/updateDetails", data);
};
const create = (data) => {
    return baseService.postApiData("/ConsignmentContents/create", data);
};
const remove = (id) => {
    return baseService.deleteApiData("/ConsignmentContents/delete?id=" + id);
};


const ConsignmentContentsService = {
    getAllWithAttestationsByConsignmentId,
    getAllByConsignmentId,
    create,
    updateDetails,
    remove
};

export default ConsignmentContentsService;