import *  as baseService  from "./BaseService";
const getAllPaged = (params) => {
    return baseService.getApiDataWithParams("/product/GetAllPaged", params);
};

const getById = (id) => {
    return baseService.getApiData("/product/GetById?id=" + id);
};

const hasMatchingEan = (ean) => {
    return baseService.getApiData("/product/HasMatchingEan?ean=" + ean);
};

const getWithDetailsById = (id) => {
    return baseService.getApiData("/product/GetWithDetailsById?id=" + id);
};
const getProductDetailByProductId = (id) => {
    return baseService.getApiData("/productDetail/GetByProductId?id=" + id);
};
const create = (data) => {
    return baseService.postApiData("/product/Createwithfile", data);
};
const createDetail = (data) => {
    //console.log(JSON.stringify(data));
    return baseService.postApiData("/productDetail/create", data);
};
const updateDetail = (id, data) => {
    return baseService.putApiData("/productDetail/update?id=" + id, data);
};

const update = (id, data) => {
    return baseService.putApiData("/product/update?id=" + id, data);
};
const bulkConfirm = (data) => {
    //console.log(JSON.stringify(data));
    return baseService.postApiData("/product/createbulk");
};

const bulkUpload = (data) => {
    //console.log(JSON.stringify(data));
    return baseService.postApiData("/product/UploadBulk", data);
};
const ProductService = {
    hasMatchingEan,
    getAllPaged,
    getProductDetailByProductId,
    getWithDetailsById,
    create,
    createDetail,
    updateDetail,
    update,
    getById,
    bulkUpload,
    bulkConfirm
};

export default ProductService;