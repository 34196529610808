import * as Egg8360Labels from "../Egg8360Labels"
import { findArrayElementByFieldNameAndValue, findArrayElementByValue } from "../../../Attestations/Common/AttestationFunctions";
import moment from "moment-timezone";
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
export const AttestationContent = (props) => {
    const { attestationData } = props;
    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);
    return (
        <>
            <h5>II. Health Attestation</h5>
            <>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned official veterinarian declare that I am aware of the relevant requirements of Regulation (EU) 2019/6 of the European Parliament
                    and of the Council and Commission Delegated Regulation (EU) 2023/905 and hereby certify that the egg products described in Part I have been obtained
                    in accordance with these requirements, and in particular, that the flocks of laying hens from which the eggs have been obtained have not been administered
                    antimicrobial medicinal products for growth promotion or yield increase or antimicrobial medicinal products containing an antimicrobial that is included
                    in the list of antimicrobials reserved for the treatment of certain infections in humans laid down in Commission Implementing Regulation (EU) 2022/1255
                    as set out in Article 3 of Delegated Regulation (EU) 2023/905 and originate from a third country or region thereof listed in accordance with Article 5(2)
                    of Delegated Regulation (EU) 2023/905.
                </p>
                <ol className="attestation-II-1">
                    <li>
                        they come from (an) establishment(s) applying general hygiene requirements and implementing a programme based on the hazard analysis and
                        critical control points (HACCP) principles in accordance with Article 5 of Regulation (EC) No 852/2004, regularly audited by the competent
                        authorities, and being listed as an EU approved establishment;
                    </li>
                    <li>
                        they have been produced from raw materials which meets the requirements of Section X, Chapter II (II), of Annex III to Regulation (EC) No
                        853/2004;
                    </li>
                    <li>
                        they have been produced in compliance with the hygiene requirements laid down in Section X, Chapters II (I) and (III), of Annex III to
                        Regulation (EC) No 853/2004;
                    </li>
                    <li>
                        they satisfy the analytical specifications in Section X, Chapter II (IV), of Annex III to Regulation (EC) No 853/2004 and the relevant criteria
                        laid down in Commission Regulation (EC) No 2073/2005;
                    </li>
                    <li>
                        they have been marked with an identification mark in accordance with Section I of Annex II and Section X, Chapter II (V), of Annex III to
                        Regulation (EC) No 853/2004;
                    </li>
                    <li>
                        the guarantees covering live animals and products thereof provided by the residue plans submitted in accordance with Article 29 of Council
                        Directive 96/23/EC, are fulfilled and eggs are listed in Commission Decision 2011/163/EU for the concerned country of origin;
                    </li>
                    <li>
                        they have been produced under conditions guaranteeing compliance with the maximum residue levels for pesticides laid down in Regulation
                        (EC) No 396/2005 of the European Parliament and of the Council, and the maximum levels for contaminants laid down in Commission
                        Regulation (EC) No 1881/2006.
                    </li>
                </ol>
                <h6>II.2 Animal health attestation</h6>
                <p>I, the undersigned, hereby certify that the <strong>egg products</strong> described in this certificate:</p>
                <ol type="1" className="attestation-II-2">
                    <li>
                        <strong><u></u></strong>
                        come from the <strong>zone</strong> with code <strong><u>{attestationData.animal_2_1_zone?.map(x => x.isoCode).join(", ")}</u></strong> which, at the date of issue of this support attestation:
                        <ul>
                            <li>
                                is authorised and listed in Part 1 of Annex XIX to Commission Implementing Regulation (EU) 2021/404 for entry into the Union of egg
                                products;
                            </li>
                            <li>
                                carries out a disease surveillance programme for highly pathogenic avian influenza in accordance with Article 160 of Commission Delegated
                                Regulation (EU) 2020/692;
                            </li>
                        </ul>
                    </li>
                    <li> have been prepared from eggs obtained from animals kept in establishments:
                        <ul>
                            <li>
                                which are registered by and are under the control of the competent authority of the country or territory of origin and have a system in place
                                to maintain and to keep records in accordance with Article 8 of Commission Delegated Regulation (EU) 2020/692;
                            </li>
                            <li>
                                which receive regular animal health visits from a veterinarian for the purpose of the detection of, and information on, signs indicative of the
                                occurrence of diseases, including the relevant listed diseases referred to in Annex I to Delegated Regulation (EU) 2020/692 and emerging
                                diseases;
                            </li>
                            <li>
                                which, at the time of collection of the eggs, were not subject to national restriction measures for animal health reasons, including the relevant
                                listed diseases referred to in Annex I to Delegated Regulation (EU) 2020/692 and emerging diseases;
                            </li>
                        </ul>
                    </li>
                    <li>
                        have been prepared from eggs obtained from animals kept in establishments in which during the period of 30 days prior to the date of collection of the
                        eggs and until the issue of this certificate, no outbreak of highly pathogenic avian influenza or infection with Newcastle disease virus occurred and:
                        <ol type="a">
                            {attestationData.animal_2_3_A_statements == 2 ? (
                                <li>
                                    {ReactHtmlParser(Egg8360Labels.animal_2_3_A_statements[1].key)}
                                    <ul>
                                        <li>
                                            {findArrayElementByFieldNameAndValue(Egg8360Labels.animal_2_3_A_statements, 'animal_2_3_A', attestationData.animal_2_3_A)}
                                            {(attestationData.animal_2_3_A > 0) &&
                                                <ul>
                                                    <li>
                                                        {findArrayElementByFieldNameAndValue(Egg8360Labels.animal_2_3_A_statements, 'animal_2_3_A_1', attestationData.animal_2_3_A_1)}
                                                        {findArrayElementByFieldNameAndValue(Egg8360Labels.animal_2_3_A_statements, 'animal_2_3_A_3', attestationData.animal_2_3_A_3)}
                                                        {findArrayElementByFieldNameAndValue(Egg8360Labels.animal_2_3_A_statements, 'animal_2_3_A_4', attestationData.animal_2_3_A_4)}
                                                        {findArrayElementByFieldNameAndValue(Egg8360Labels.animal_2_3_A_statements, 'animal_2_3_A_5', attestationData.animal_2_3_A_5)}
                                                    </li>
                                                </ul>
                                            }
                                        </li>
                                    </ul>
                                </li>
                            )
                                :
                                (
                                    <li>{ReactHtmlParser(Egg8360Labels.animal_2_3_A_statements[0].key)}</li>
                                )}


                            {attestationData.animal_2_3_B_statements == 2 ? (
                                <li>
                                    {ReactHtmlParser(Egg8360Labels.animal_2_3_B_statements[1].key)}
                                    <ul>
                                        <li>
                                            {findArrayElementByFieldNameAndValue(Egg8360Labels.animal_2_3_B_statements, 'animal_2_3_B', attestationData.animal_2_3_B)}
                                            {(attestationData.animal_2_3_B > 0) &&
                                                <ul>
                                                    <li>
                                                        {findArrayElementByFieldNameAndValue(Egg8360Labels.animal_2_3_B_statements, 'animal_2_3_B_1', attestationData.animal_2_3_B_1)}
                                                        {findArrayElementByFieldNameAndValue(Egg8360Labels.animal_2_3_B_statements, 'animal_2_3_B_4', attestationData.animal_2_3_B_4)}

                                                    </li>
                                                </ul>
                                            }
                                        </li>
                                    </ul>
                                </li>
                            ) :
                                (
                                    <li>{ReactHtmlParser(Egg8360Labels.animal_2_3_B_statements[0].key)}</li>
                                )}
                        </ol>
                    </li>
                    <li>
                        were products from eggs obtained from animals which did not show symptoms of transmissible diseases at the time of the collection of the eggs;
                    </li>
                    {attestationData.animal_2_5_A ? (
                        <li>
                            were produced on {moment(attestationData?.animal_2_5_A).format("DD/MM/YYYY")};
                        </li>
                    ) :
                        (
                            <li>
                                were produced between {moment(attestationData.animal_2_5_B).format("DD/MM/YYYY")} and {moment(attestationData.animal_2_5_C).format("DD/MM/YYYY")};
                            </li>
                        )}
                    <li>
                        are dispatched to the Union:
                        <ul>
                            <li>
                                in a means of transport designed, constructed and maintained in such condition that the health status of the egg products will not be
                                jeopardised during the transport from their place of origin to the Union;
                            </li>
                            <li>
                                separated from animals and products of animal origin not complying with the relevant animal health requirements for entry into the Union
                                provided for in Delegated Regulation (EU) 2020/692.
                            </li>
                        </ul>
                    </li>
                </ol>
                {/* <PrettyPrintJson data={attestationData} >  </PrettyPrintJson> */}
                {/* <PrettyPrintJson data={compositeInfo} >  </PrettyPrintJson>
            <PrettyPrintJson data={speciesList} >  </PrettyPrintJson>  */}
            </>
        </>
    )
}

