import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, Row } from "@themesberg/react-bootstrap";
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { getUserInfo } from "../../../../auth/authorisation";
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import Stepper from "../../../../components/Stepper";
import { Routes } from "../../../../routes";
import StorageDeclarationService from "../../../services/StorageDeclarationService";
import CertificateNumberService from "../../../services/CertificateNumberService";
import ManufacturerService from '../../../services/ManufacturerService';
import OrganisationService from '../../../services/OrganisationService';
import PlatformService from '../../../services/PlatformService';
import ProductService from '../../../services/ProductService';
import SiteService from '../../../services/SiteService';
import { GetCertificateFormData } from '../../CertificateForms/CertificateFormFunctions';
import FormSubmission from "../../common/FormSubmission";
import ExporterDeclarationForm from "./Forms/ExporterDeclarationForm";
import ExporterDeclarationReview from "./Forms/ExporterDeclarationReview";
import formModel from './Models/FormModel';
import formInitialValues from './Models/FormValues';
import validationSchema from './Models/FormValidation';
import TemperatureService from '../../../services/TemperatureService';
import StorageExporterDeclarationService from '../../../services/StorageExporterDeclarationService';

//Steps for the form
const steps = ['Storage Details', 'Review & Sign'];
const { formId, formField } = formModel;

//Main function to render steps
function _renderStepContent(
      storageDeclarationId, 
      step, 
      setFieldValue, 
      setFieldTouched, 
      touched, 
      errors, 
      productId, 
      user, 
      values, 
      childRef, 
      isSubmitting,
      uniqueRef,
      product, 
      manufacturer, 
      platform, 
      vets, 
      organisation, 
      site, 
      temperature,
      productDetail, 
      attestationData,
      storageRequestData,
      generatedDocument, 
      certificateNumber,
      validToDate
      ) 
    {
    switch (step) {
      //Step 1 - Form
      case 0:
        return <ExporterDeclarationForm 
            formField={formField}
            setFieldValue={setFieldValue} 
            setFieldTouched={setFieldTouched} 
            touched={touched} 
            errors={errors} 
            productId={productId} 
            user={user}
            values={values}
            uniqueRef={uniqueRef}
            product={product}
            manufacturer={manufacturer}
            platform={platform}
            vets={vets}
            organisation={organisation}
            site={site}
            temperature={temperature}
            productDetail={productDetail}
            attestationData={attestationData}
            storageRequestData={storageRequestData}
            certificateNumber={certificateNumber}
            validToDate={validToDate}
          />;
        //Step 2 - Review / Sign
        case 1:
          return <ExporterDeclarationReview 
          values={values}
          forwardedRef={childRef}
          isSubmitting={isSubmitting}
          uniqueRef={uniqueRef}
          formField={formField}
          setFieldValue={setFieldValue} 
          setFieldTouched={setFieldTouched} 
          touched={touched} 
          errors={errors} 
          productId={productId} 
          user={user}
          product={product}
          manufacturer={manufacturer}
          platform={platform}
          vets={vets}
          organisation={organisation}
          site={site}
          temperature={temperature}
          productDetail={productDetail}
          attestationData={attestationData}
          storageRequestData={storageRequestData}
          generatedDocument={generatedDocument}
          certificateNumber={certificateNumber}
          validToDate={validToDate}
          />
      default:
        return <div>Not Found</div>;
    }
  }

const ExporterDeclarationPage = (props) => {
  
  const [ready, setReady] = useState(false);
  const [errors, setErrors] = useState(null);
  const childRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0); 
  const isLastStep = activeStep === steps.length - 1;
  const [user, setUser] = useState('');
  const [validToDate, setValidToDate] = useState('');
  const [productJson, setProductJson] = useState(null); 
  const [successButtonInfo, setSuccessButtonInfo] = useState('');
  const currentValidationSchema = validationSchema[activeStep]; 
  const [storageDeclarationId] = useState(props.match.params.id);
  const [attestationData, setAttestationData] = useState({});
  const [productId, setProductId] = useState('');
  const [storageRequestData, setStorageRequestData] = useState('');
  const [productUrl, setProductUrl] = useState('');
  const [uniqueRef, setUniqueRef] = useState('');
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [product, setProduct] = useState({});
  const [manufacturer, setManufacturer] = useState({});
  const [platform, setPlatform] = useState([]);;
  const [vets, setVets] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [site, setSite] = useState({});
  const [productDetail, setProductDetail] = useState({});
  const [generatedDocument, setGeneratedDocument] = useState('');
  const [certificateNumber, setCertificateNumber] = useState([]);
  const [selectedCertificateNumbers, setSelectedCertificateNumbers] = useState([]);
  const [temperature, setTemperature] = useState({});

  //Page initialisation
  useEffect(() => {
    async function fetchData() {
      try {
        //Get user data
        getUserInfo()
          .then(x => {
            setUser(x);
        });


        //Get Storage declaration data
        const storageDecResponse = await StorageDeclarationService.getById(storageDeclarationId);
        const storageDec = await storageDecResponse.data;

        //Set values from storage declaration
        const productId = storageDec.productId;
        const validTo = storageDec.validTo;
        const productUrl = Routes.Product.path.replace(":id", "") + productId;
        const supplierUniqueCode = storageDec.uniqueCode;
        const storageDecRequest = storageDec;
        setValidToDate(validTo)
        //Set state values from storage declaration
        setProductId(productId)
        _handleGenerateUrn(productId);
        setProductUrl(productUrl);
        setSuccessButtonInfo({ url: Routes.SupportAttestations.path, name: "Back to Support Attestations" });

        //Get / Set product data
        const productResponse = await ProductService.getWithDetailsById(productId);
        const product = await productResponse.data;
        const productDetail = product.productDetails;
        const manufacturerId = product.manufacturerId;
        const siteId = product.productDetails.siteId;
        const platformId = product.productDetails.platformId;
        const temperatureId = product.productDetails.temperatureId;
        setProduct(product);
        setProductDetail(productDetail);
        setStorageRequestData(storageDecRequest);
        
        //Get / Set certificate number data
        const certNumber = await productDetail.certificateNumber;
        const certList = await productDetail.certificateNumberList;
        setCertificateNumber(certNumber);
    
        //Set Selected certificate numbers
        const certificateNumbersResponse = await CertificateNumberService.getAll();
        let selectedCertificateNumbers = "";
        if (Array.isArray(certList)) {
          selectedCertificateNumbers = certificateNumbersResponse.data.filter(x => certList.includes(x.number.toString()));
        } else {
          selectedCertificateNumbers = certificateNumbersResponse.data.filter(x => certList == x.number.toString());
        }
        setSelectedCertificateNumbers(selectedCertificateNumbers);
    
        //Set attestationData property with data for certificate number
        const attestationData = await fetchCertFormData(selectedCertificateNumbers, productId);
        setAttestationData(attestationData);
        
        //Get / Set site, manufacturer, platform, organisation data
        const siteResponse = await SiteService.getWithEstablishmentNumberType(siteId);
        const site = await siteResponse.data
        const manufacturerResponse = await ManufacturerService.getById(manufacturerId);
        const manufacturer = await manufacturerResponse.data;
        const platformResponse = await PlatformService.getWithEstablishmentNumberType(platformId);
        const platform = await platformResponse.data;
        const organisationResponse = await OrganisationService.getFirst();
        const organisation = await organisationResponse.data;
        const temperatureResponse = await TemperatureService.getById(temperatureId);
        const temperature = await temperatureResponse.data;

        setSite(site);
        setManufacturer(manufacturer);
        setPlatform(platform);
        setOrganisation(organisation);
        setTemperature(temperature);
    
        // Construct productJson object
        const json = {
          product: product,
          productDetail: productDetail,
          platform: platform,
          organisation: organisation,
          manufacturer: manufacturer,
          site: site,
          attestationData: attestationData
        };
        //Set productJson state value
        setProductJson(json);

      } catch (e) {
        console.error(e);
      } finally{
        setReady(true);
    }
    }
    fetchData();
  }, []); 


  //Function - Get Gertificate form data from product id and the certificate numbers required on this product
  function fetchCertFormData(selectedCertificateNumbers, id) {
    return GetCertificateFormData(id, selectedCertificateNumbers, false)
    .then(x=> {
      return x
    });
  }

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // Submit form handler
  function _handleSubmit(values, actions) {
    setSubmitAttempted(false);
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
  
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }

    if(activeStep==0){
      //Create document after first step
      StorageDeclarationService.createDocument(childRef.current.outerHTML)
      .then((response) => {
          setGeneratedDocument(response.data);
        });
    }  
  }
  function _handleBack() {
    setActiveStep(activeStep - 1);
  }


    //Function - Generate a URN for this Attestation
    function _handleGenerateUrn(productId) {
      StorageExporterDeclarationService.getUniqueReference(productId)
      .then((response) => {
          setUniqueRef(response.data);
          })
      .catch((e) => {console.log(e);});
  }
  
  

  //Submit form
  async function _submitForm(values, actions) {
    await _sleep(1000);
    //Configure data to be submitted
    let submitData = values;
    const attestationData = await fetchCertFormData(selectedCertificateNumbers, productId);
    productJson.attestationData = attestationData;
    submitData.productJson = JSON.stringify(productJson).toString();
    submitData.uniqueCode = uniqueRef;
    submitData.storageDeclarationId = storageDeclarationId;
    submitData.filename = values.signedRequestUrl;
    submitData.documentSigningId = values.documentId;
    submitData.decision = values.decision;

    //Submit data
    StorageDeclarationService.createResponse(submitData)
    .then((response) => {
     })
     .catch((e) => {
       setErrors(e.response.data);
       console.log(e);}
     );

    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  }

  //Page labelling
  const title = "Exporter Declaration";
  const breadcrumbData = {
      item1 : {name:'Exporter Declarations', link: Routes.StorageExporterDeclarationListingPage.path},
      item2 : {name: product.productName, link: Routes.Product.path.replace(":id",productId)},
      item3 : {name:'Exporter Declaration', link: '#'},
  };
  
  //Render page
  return (
    <div className="row">
        <BreadcrumbNav data={breadcrumbData}/>
        <h2>{title}</h2>
        {activeStep !== steps.length &&(
          <Row>
            <Col xl={10}>
              <Stepper steps={steps} activeStep={activeStep} />
            </Col>
        </Row>
        )}
        {activeStep === steps.length ? (
          <FormSubmission
            errors={errors}
            title ={"Successfully signed"}
            message = {"The exporter storage declaration has been signed and completed and is now valid."}
            successButtonUrl = {Routes.StorageSupplierDeclarationListingPage.path}
            successButtonName = {"Back to storage declarations"}
          />
        ) 
        :
        (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ isSubmitting, setFieldValue,  setFieldTouched, touched, errors, values}) => (
              <Form id={formId}>
              {/* Render Step - when data is ready  */}
                {ready &&
                _renderStepContent(
                  storageDeclarationId, 
                  activeStep, 
                  setFieldValue, 
                  setFieldTouched, 
                  touched, 
                  errors, 
                  productId, 
                  user, 
                  values, 
                  childRef, 
                  isSubmitting, 
                  uniqueRef, 
                  product, 
                  manufacturer,
                  platform, 
                  vets, 
                  organisation,
                  site, 
                  temperature,
                  productDetail,
                  attestationData,
                  storageRequestData,
                  generatedDocument,
                  certificateNumber,
                  validToDate
                  )
                }
                  

                {/* Validation / Errors */}
                {(Object.keys(errors).length > 0 && submitAttempted) &&
                  <Alert
                    className="mt-2"
                    variant="danger"
                    col="sm"
                    >
                    <div className="d-flex justify-content-start">
                      <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                      Some errors were found, check the form above for any missing or incomplete information.
                    </div>
                  </Alert>
                }
                <div className={"d-inline"}>

                  {/* Back button  */}
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} variant="light" className="mx-3">
                      Back
                    </Button>
                  )}

                  {/* Submit button  */}
                  <div className="d-inline">
                    <Button
                      onClick={() => { setSubmitAttempted(true) }}
                      disabled={isSubmitting}
                      type="submit"
                      variant="primary"
                      className={"btn-md"}
                      >                      
                      {activeStep== 0 ? 'Continue' :''}
                      {activeStep== 1 ? 'Complete Declaration' :'' }
                
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        </div>
    );
};

export default ExporterDeclarationPage;