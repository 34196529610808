import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Formik, Form, FieldArray, useFormikContext, getIn } from 'formik';
import FormController from "../../common/FormController"
import ProductMeatService from "../../../services/ProductMeatService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ZoneService from "../../../services/ZoneService";
import CountryService from "../../../services/CountryService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import * as Meat8384Labels from "./Meat8384Labels"
import FormSubmission from "../../common/FormSubmission";
import { Button, Card, Alert } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SpeciesTable from "../Components/SpeciesTable";
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import MeatBseRisks from './Components/MeatBseRisks';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import CuttingPlantSelect from '../../../../components/EstablishmentSelect/CuttingPlantSelect';
import ColdStoreSelect from '../../../../components/EstablishmentSelect/ColdStoreSelect';
import SlaughterhouseSelect from '../../../../components/EstablishmentSelect/SlaughterhouseSelect';
import SiteService from '../../../services/SiteService';
import EstablishmentsService from '../../../services/EstablishmentsService';
import ActivityService from '../../../services/ActivityService';

const Meat8384AddPage = (props) => {
    const certificateNumber = 8384;
    const [isSubmitting, setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [formVersion] = useState(GetCertificateFormVersion(certificateNumber));
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [product, SetProduct] = useState([]);
    const [speciesNotGame, SetSpeciesNotGame] = useState(false);
    const [speciesIsGame, SetSpeciesIsGame] = useState(false);
    const [speciesIsUngulates, SetSpeciesIsUngulates] = useState(false);
    const [speciesNotUngulates, SetSpeciesNotUngulates] = useState(false);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [countrys, SetCountrys] = useState([]);
    const [country, SetCountry] = useState([]);
    const [specieslist, SetSpecies] = useState([]);
    const [siteEstablishment, setSiteEstablishment] = useState([]);
    const [coldStoreEstab, setColdStoreEstab] = useState([]);
    const [memberStates, SetMemberStates] = useState([]);

    let title = (editing == true ? "Edit " : "New") + " Meat (#8384) Information"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Meat 8384', link: Routes.Meat8384AddPage.path },
    };
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [controlledRisk, SetControlledRisk] = useState([]);
    const [negligbleRisk, SetNegligbleRisk] = useState([]);
    const [apiValues, SetApiValues] = useState([]);

    const groupingsArr = [
        { zoneGroupName: '8350_MEAT_PRODUCTS_BOV_A', speciesGroupName: 'BOV', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: '8350_MEAT_PRODUCTS_OVI_A', speciesGroupName: 'OVI', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: '8350_MEAT_PRODUCTS_POR_A', speciesGroupName: 'POR', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: '8350_MEAT_PRODUCTS_RUF_A', speciesGroupName: 'RUF', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: '8350_MEAT_PRODUCTS_RUW_A', speciesGroupName: 'RUW', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: '8350_MEAT_PRODUCTS_SUF_A', speciesGroupName: 'SUF', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: '8350_MEAT_PRODUCTS_SUW_A', speciesGroupName: 'SUW', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: '8350_MEAT_PRODUCTS_POU_A', speciesGroupName: 'POU', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: '8350_MEAT_PRODUCTS_RAT_A', speciesGroupName: 'RAT', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: '8350_MEAT_PRODUCTS_GB_A', speciesGroupName: 'GB', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_BOV', speciesGroupName: 'BOV', treatmentType: 'B', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_OVI', speciesGroupName: 'OVI', treatmentType: 'B', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_POR', speciesGroupName: 'POR', treatmentType: 'B', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_RUF', speciesGroupName: 'RUF', treatmentType: 'B', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_RUW', speciesGroupName: 'RUW', treatmentType: 'B', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_SUF', speciesGroupName: 'SUF', treatmentType: 'B', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_SUW', speciesGroupName: 'SUW', treatmentType: 'B', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_POULTRY_GAME_POU', speciesGroupName: 'POU', treatmentType: 'B', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_POULTRY_GAME_RAT', speciesGroupName: 'RAT', treatmentType: 'B', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_POULTRY_GAME_GB', speciesGroupName: 'GB', treatmentType: 'B', zonesArr: [] },
    ];

    var treatments = [
        { value: "A" },
        { value: "B" },
    ]

    const initialValues = {
        productId: props.match.params.id,
        coldStore: apiValues?.coldStore ?? '',
        cuttingPlant: apiValues?.cuttingPlant ?? '',
        abattoir: apiValues?.abattoir ?? '',
        cuttingPlantEstablishments: apiValues?.cuttingPlantEstablishments ?? [],
        coldStoreEstablishments: apiValues?.coldStoreEstablishments ?? [],
        abattoirEstablishments: apiValues?.abattoirEstablishments ?? [],
        identificationMark: apiValues?.identificationMark ?? '',
        identificationMarkEstablishments: apiValues?.identificationMarkEstablishments ?? siteEstablishment[0],
        health_1_2_1: apiValues?.health_1_2_1 ?? false,
        health_1_2_2: apiValues?.health_1_2_2 ?? false,
        health_1_4_1: apiValues?.health_1_4_1 ?? false,
        health_1_4_1_1: apiValues?.health_1_4_1_1 ?? false,
        health_1_4_1_2: apiValues?.health_1_4_1_2 ?? false,
        health_1_4_1_3: apiValues?.health_1_4_1_3 ?? false,
        health_1_4_2: apiValues?.health_1_4_2 ?? false,
        health_1_4_3: apiValues?.health_1_4_3 ?? false,
        health_1_4_4: apiValues?.health_1_4_4 ?? false,
        health_1_12: apiValues?.health_1_12 ?? false,
        health_1_12_1: apiValues?.health_1_12_1 ?? false,
        health_1_12_2: apiValues?.health_1_12_2 ?? false,
        health_1_13: apiValues?.health_1_13 ?? false,
        health_1_14: apiValues?.health_1_14 ?? false,
        animal_2_1_zone: apiValues?.animal_2_1_zone ?? [],
        animal_2_1_a_1: apiValues?.animal_2_1_a_1 ?? false,
        animal_2_1_a_2: apiValues?.animal_2_1_a_2 ?? false,
        animal_2_4_1: apiValues?.animal_2_4_1 ?? false,
        animal_2_4_2: apiValues?.animal_2_4_2 ?? false,
        animal_2_4_2_1: apiValues?.animal_2_4_2_1 ?? false,
        animal_2_4_2_2: apiValues?.animal_2_4_2_2 ?? false,
        animal_2_4_2_zone: apiValues?.animal_2_4_2_zone ?? [],
        animal_2_4_3: apiValues?.animal_2_4_3 ?? false,
        animal_2_5_1: apiValues?.animal_2_5_1 ?? false,
        animal_2_5_2: apiValues?.animal_2_5_2 ?? false,
        animal_2_7: apiValues?.animal_2_7 ?? false,
        BseControlled: apiValues?.bseControlled ?? 0,
        BseRiskCategoryNegligible: apiValues?.bseRiskCategoryNegligible ?? false,
        BseRiskCategoryControlled: apiValues?.bseRiskCategoryControlled ?? false,
        BseRiskCategoryUndetermined: apiValues?.bseRiskCategoryUndetermined ?? false,
        BseControlled4: apiValues?.bseControlled4 ?? 0,
        BseRiskCategoryNegligibleStatements: apiValues?.bseRiskCategoryNegligibleStatements ?? null,
        BseRiskCategoryControlledStatements: apiValues?.bseRiskCategoryControlledStatements ?? null,
        speciesList: apiValues?.speciesList ?? [],
        countryOriginId: apiValues?.countryOriginId ?? null,
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
        containsBOV: false,
        containsOVI: false,
        containsPOR: false,
        containsRUF: false,
        containsRUW: false,
        containsSUF: false,
        containsSUW: false,
        containsPOU: false,
        containsRAT: false,
        containsGB: false,
        zones1box: null,
        zones2box: null,
        nonfilteredzones1box: null,
        nonfilteredzones2box: null,
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";
    const meatGroups = [
        { name: 'BOV' },
        { name: 'OVI' },
        { name: 'POR' },
        { name: 'RUF' },
        { name: 'RUW' },
        { name: 'SUF' },
        { name: 'SUW' },
        { name: 'POU' },
        { name: 'RAT' },
        { name: 'GB' },
    ]

    //Validation
    const validationSchema = Yup.object().shape({
        identificationMarkEstablishments: Yup.object().nullable().required(validationMsgRequired),
        // coldStoreEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
        //     .required(validationMsgRequired),
        abattoirEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        speciesList: Yup.array().nullable().of(
            Yup.object().shape({
                speciesGroupName: Yup.string()
                    .required(validationMsgRequired)
            })
        ).min(1, validationMsgAtLeastOne),
        health_1_2_1: Yup.bool()
            .test('health_1_2_1', validationMsgRequired, function (x) {
                return (speciesNotGame === true && x === false)
                    ? false : true;
            }),
        health_1_2_2: Yup.bool()
            .test('health_1_2_2', validationMsgRequired, function (x) {
                return (speciesIsGame === true && x === false)
                    ? false : true;
            }),
        health_1_2: Yup.number().integer().min(1, validationMsgRequired),
        health_1_4_1_1: Yup.bool()
            .test('health_1_4_1_1', validationMsgAtLeastOne, function (x) {
                return (this.parent.health_1_4_1 && !this.parent.health_1_4_1_1 && !this.parent.health_1_4_1_2 && !this.parent.health_1_4_1_3)
                    ? false : true;
            }),
        health_1_4_1_2: Yup.bool()
            .test('health_1_4_1_2', validationMsgAtLeastOne, function (x) {
                return (this.parent.health_1_4_1 && !this.parent.health_1_4_1_1 && !this.parent.health_1_4_1_2 && !this.parent.health_1_4_1_3)
                    ? false : true;
            }),
        health_1_4_1_3: Yup.bool()
            .test('health_1_4_1_3', validationMsgAtLeastOne, function (x) {
                return (this.parent.health_1_4_1 && !this.parent.health_1_4_1_1 && !this.parent.health_1_4_1_2 && !this.parent.health_1_4_1_3)
                    ? false : true;
            }),
        health_1_12_1: Yup.bool()
            .test('health_1_12_1', validationMsgAtLeastOne, function (x) {
                return (this.parent.health_1_12 && !this.parent.health_1_12_1 && !this.parent.health_1_12_2)
                    ? false : true;
            }),
        health_1_12_2: Yup.bool()
            .test('health_1_12_2', validationMsgAtLeastOne, function (x) {
                return (this.parent.health_1_12 && !this.parent.health_1_12_1 && !this.parent.health_1_12_2)
                    ? false : true;
            }),
        BseRiskCategoryNegligible: Yup.bool()
            .test('BseRiskCategoryNegligible', validationMsgAtLeastOne, function (x) {
                return (this.parent.containsBOV || this.parent.containsOVI || this.parent.containsRUW) ? (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined) : true;
            }),
        BseRiskCategoryControlled: Yup.bool()
            .test('BseRiskCategoryControlled', validationMsgAtLeastOne, function (x) {
                return (this.parent.containsBOV || this.parent.containsOVI || this.parent.containsRUW) ? (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined) : true;
            }),
        BseRiskCategoryUndetermined: Yup.bool()
            .test('BseRiskCategoryUndetermined', validationMsgAtLeastOne, function (x) {
                return (this.parent.containsBOV || this.parent.containsOVI || this.parent.containsRUW) ? (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined) : true;
            }),
        BseRiskCategoryNegligibleStatements: Yup.array().nullable()
            .when("BseRiskCategoryNegligible", {
                is: true,
                then: Yup.array().min(1, validationMsgAtLeastOne)
            }),
        BseRiskCategoryControlledStatements: Yup.array().nullable()
            .when("BseRiskCategoryControlled", {
                is: true,
                then: Yup.array().min(1, validationMsgAtLeastOne)
            }),
        // BseControlled4: Yup.number()
        //     .when("BseRiskCategoryControlledStatements",{
        //     is: 4,
        //     then: Yup.number().integer().min(1, validationMsgAtLeastOne)
        // }),

        // animal_2_1_a: Yup.number().integer().min(1, validationMsgRequired),
        animal_2_1_zone: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),

        animal_2_1_a_1: Yup.bool()
            .test('animal_2_1_a_1', validationMsgRequired, function (x) {
                return (speciesIsUngulates === true && x === false)
                    ? false : true;
            }),
        animal_2_1_a_2: Yup.bool()
            .test('animal_2_1_a_2', validationMsgRequired, function (x) {
                return (speciesNotUngulates === true && x === false)
                    ? false : true;
            }),

        animal_2_4_1: Yup.bool()
            .test('animal_2_4_1', validationMsgAtLeastOne, function (x) {
                return (!this.parent.animal_2_4_1 && !this.parent.animal_2_4_2 && !this.parent.animal_2_4_3)
                    ? false : true;
            }),
        animal_2_4_2: Yup.bool()
            .test('animal_2_4_2', validationMsgAtLeastOne, function (x) {
                return (!this.parent.animal_2_4_1 && !this.parent.animal_2_4_2 && !this.parent.animal_2_4_3)
                    ? false : true;
            }),
        animal_2_4_2_zone: Yup.array()
            .when("animal_2_4_2", {
                is: true,
                then: Yup.array().min(1, validationMsgAtLeastOne)
                    .required(validationMsgRequired),
            }),


        animal_2_4_2_1: Yup.bool()
            .test('animal_2_4_2_1', validationMsgRequired, function (x) {
                return (speciesIsUngulates === true && x === false && this.parent.animal_2_4_2 == true)
                    ? false : true;
            }),
        animal_2_4_2_2: Yup.bool()
            .test('animal_2_4_2_2', validationMsgRequired, function (x) {
                return (speciesNotUngulates === true && x === false && this.parent.animal_2_4_2 == true)
                    ? false : true;
            }),


        animal_2_4_3: Yup.bool()
            .test('animal_2_4_3', validationMsgAtLeastOne, function (x) {
                return (!this.parent.animal_2_4_1 && !this.parent.animal_2_4_2 && !this.parent.animal_2_4_3)
                    ? false : true;
            }),
        animal_2_5_1: Yup.bool()
            .test('animal_2_5_1', validationMsgRequired, function (x) {
                return (speciesNotGame === true && x === false)
                    ? false : true;
            }),
        animal_2_5_2: Yup.bool()
            .test('animal_2_5_2', validationMsgRequired, function (x) {
                return (speciesIsGame === true && x === false)
                    ? false : true;
            }),
    });


    function FilterZones(speciesGroupName, values, treatment, exclude) {
        //This will be a service to get from a JSON array stored locally
        var getGrouparr = groupingsArr;
        var zonesArrFound123 = getGrouparr.find(x => x.speciesGroupName === speciesGroupName && x.treatmentType === treatment);
        var distinct = null;
        if (zonesArrFound123?.zonesArr.length != 0) {
            const zonesArrFound = groupingsArr.findIndex(x => x.speciesGroupName === speciesGroupName && x.treatmentType === treatment);
            groupingsArr[zonesArrFound].zonesArr = zonesArrFound123.zonesArr;
            if (treatment === 'A') {
                if (values.zones1box != undefined || values.zones1box != null) {
                    if (values.nonfilteredzones1box != undefined || values.nonfilteredzones1box != null) {
                        var test123 = values.nonfilteredzones1box.filter(({ id: id1 }) => !exclude.some(({ id: id2 }) => id2 === id1));
                        values.zones1box = test123;
                    }
                }

            }
            else if (treatment === 'B') {
                if (values.zones2box != undefined || values.zones2box != null) {
                    if (values.nonfilteredzones2box != undefined || values.nonfilteredzones2box != null) {
                        var test1234 = values.nonfilteredzones2box.filter(({ id: id1 }) => !exclude.some(({ id: id2 }) => id2 === id1));
                        values.zones2box = test1234;
                    }
                }

            }
        }
        else {

            const speciesGroupNameFound = getGrouparr.find(x => x.speciesGroupName === speciesGroupName && x.treatmentType === treatment);
            if (speciesGroupNameFound != undefined && speciesGroupNameFound != null) {
                try {
                    ZoneService
                        .getAllByGroup(speciesGroupNameFound.zoneGroupName)
                        .then(response => {
                            const options = response.data.map(d => ({
                                "id": d.id,
                                "displayName": d.displayName,
                                "countryName": d.countryName,
                                "regionName": d.regionName,
                                "isoCode": d.isoCode,
                            })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                            var filteredItems = options.filter(function (item) {
                                return item.isoCode !== 'GB-2'
                            })
                            const zonesArrFound = groupingsArr.findIndex(x => x.speciesGroupName === speciesGroupName && x.treatmentType === treatment);
                            groupingsArr[zonesArrFound].zonesArr = filteredItems;

                            var groupingsArrs = groupingsArr.filter(x => x.treatmentType === treatment);

                            var result = [...new Set([].concat(...groupingsArrs.map((o) => o.zonesArr)))]
                            distinct = Array.from(new Set(result.map(JSON.stringify))).map(JSON.parse);
                            distinct.sort((a, b) => a.displayName.localeCompare(b.displayName));

                            if (treatment === 'A') {
                                values.nonfilteredzones1box = values.zones1box;
                                values.zones1box = distinct;
                            }
                            else if (treatment === 'B') {
                                values.nonfilteredzones2box = values.zones2box;
                                values.zones2box = distinct;
                            }
                        }, [])
                        .catch(e => {
                            console.log(e);
                        })
                } catch (e) {

                    console.error(e);
                }
            }

        }
        return distinct
    }



    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;
            console.log(submitData);
            if (values.countryOriginId != null) {
                submitData.countryOriginId = values.countryOriginId.id;
            }

            if (apiValues.length === 0) {
                ProductMeatService.create(submitData)
                    .then(async response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Meat 8384',
                            8384,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                        console.log(e);
                    });
            }
            else {
                ProductMeatService.update(productId, values)
                    .then(async response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Meat 8384',
                            8384,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                        console.log(e);
                    });
            }
            setFormSubmitted(false);
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();
        useEffect(() => {
            values.speciesList.forEach((speciesGroup) => {
                var groupName = speciesGroup.speciesGroupName;
                setFieldValue(`contains${groupName}`, true);
            });

            if (ready) {

                const keys = [
                    'bov', 'ovi', 'por', 'ruf', 'ruw', 'suf', 'suw', 'pou', 'rat', 'gb'
                ];

                keys.forEach(key => {
                    const containsKey = `contains${key.toUpperCase()}`;
                    if (values.productScientificSpecies?.length > 0) {
                        if (!getIn(values, containsKey) && values.productScientificSpecies.some(obj => obj[key] === true)) {
                            setFieldValue(containsKey, true);
                            let single_sData = {
                                "productId": productId,
                                "speciesGroupName": key.toUpperCase()
                            }
                            values.speciesList.push(single_sData);
                        }
                    } else if (getIn(values, containsKey) && values.speciesList?.some(obj => obj[key] === true)) {
                        values.speciesList = values.speciesList?.filter(item => item.speciesGroupName !== key);
                        setFieldValue(containsKey, false);
                    }
                });

                if (country !== "" && countrys !== "") {
                    const selectedCountry = countrys.find(x => x.id === country);
                    values.countryOriginId = selectedCountry;
                    SetCountry("");
                }

                if (values.countryOriginId != undefined && values.countryOriginId != null) {
                    if (controlledRisk.find(f => f.countryName === values.countryOriginId.countryName)) {
                        values.BseRiskCategoryControlled = true;
                        values.BseRiskCategoryNegligible = false;
                        values.BseRiskCategoryUndetermined = false;
                    }

                    if (negligbleRisk.find(f => f.countryName === values.countryOriginId.countryName)) {
                        values.BseRiskCategoryNegligible = true;
                        values.BseRiskCategoryControlled = false;
                        values.BseRiskCategoryUndetermined = false;
                    }

                    if (!negligbleRisk.find(f => f.countryName === values.countryOriginId.countryName) && !controlledRisk.find(f => f.countryName === values.countryOriginId.countryName)) {
                        values.BseRiskCategoryUndetermined = true;
                        values.BseRiskCategoryControlled = false;
                        values.BseRiskCategoryNegligible = false;

                    }
                }
                else {
                    values.BseRiskCategoryUndetermined = false;
                    values.BseRiskCategoryControlled = false;
                    values.BseRiskCategoryNegligible = false;

                }
                if (values.containsBOV || values.containsOVI || values.containsPOR || values.containsRUF || values.containsSUF || values.containsPOU || values.containsRAT) {
                    SetSpeciesNotGame(true);
                    setFieldValue('animal_2_5_1', true);
                    setFieldValue('health_1_2_1', true);
                }
                else {
                    SetSpeciesNotGame(false);
                    setFieldValue('animal_2_5_1', false);
                    setFieldValue('health_1_2_1', false);
                }

                if (values.containsRUW || values.containsSUW || values.containsGB) {
                    // console.log("species= " + JSON.stringify(values));
                    // console.log("isgame");
                    SetSpeciesIsGame(true);
                    setFieldValue('animal_2_5_2', true);
                    setFieldValue('health_1_2_2', true);
                }
                else {
                    SetSpeciesIsGame(false);
                    setFieldValue('animal_2_5_2', false);
                    setFieldValue('health_1_2_2', false);
                }

                const isUngulate = values.containsBOV || values.containsOVI || values.containsPOR || values.containsRUF || values.containsRUW || values.containsSUF || values.containsSUW;

                SetSpeciesIsUngulates(isUngulate);
                setFieldValue('animal_2_1_a_1', isUngulate);
                setFieldValue('animal_2_4_2_1', isUngulate && values.animal_2_4_2);


                const isNotUngulate = values.containsPOU || values.containsGB || values.containsRAT;
                SetSpeciesNotUngulates(isNotUngulate);
                setFieldValue('animal_2_1_a_2', isNotUngulate);
                setFieldValue('animal_2_4_2_2', isNotUngulate && values.animal_2_4_2);

                if (!values.containsPOR) {
                    setFieldValue('health_1_4_1', false);
                }

                // if (values?.health_1_12 != true) {
                //     values.health_1_12_statements = [];
                // }

                if (values?.containsRUF !== true) {
                    values.health_1_13 = false;
                }
                if (values?.containsRUW !== true) {
                    values.health_1_14 = false;
                }

                if (values?.animal_2_4_2 != true) {
                    values.animal_2_4_2_zone = [];
                }
                // if (values?.health_1_4_1 != true) {
                //     values.health_1_4_1_statements = 0;
                // }

                if (!values.containsPOR && !values.containsSUF && !values.containsSUW) {
                    values.health_1_4_2 = false;
                }

                if (!values.containsPOU && !values.containsGB) {
                    values.animal_2_7 = false;
                }
                if (!values.containsBOV && !values.containsOVI && !values.containsRUW) {
                    values.BseRiskCategoryNegligible = false;
                    values.BseRiskCategoryControlled = false;
                    values.BseRiskCategoryUndetermined = false;
                }
                if (!values.BseRiskCategoryNegligible) {
                    setFieldValue('BseRiskCategoryNegligibleStatements', null);
                }
                if (!values.BseRiskCategoryControlled) {
                    setFieldValue('BseRiskCategoryControlledStatements', null);
                }
                let exclude = values.animal_2_1_zone.concat(values.animal_2_4_2_zone);
                // if(values.zones1box != undefined)
                // {
                //     var test123 = values.zones1box.filter(({ id: id1 }) => !test.some(({ id: id2 }) => id2 === id1));
                //     values.zones1box = test123;
                // }
                // if(values.zones2box != undefined)
                // {
                //     var test1234 = values.zones2box.filter(({ id: id1 }) => !test.some(({ id: id2 }) => id2 === id1));
                //     values.zones2box = test1234;
                // }
                var distinct = null;
                treatments.forEach((treatment) => {
                    values.speciesList.forEach((speciesGroup) => {
                        var groupName = speciesGroup.speciesGroupName;
                        distinct = FilterZones(groupName, values, treatment.value, exclude);
                    });
                });

                //

                //     if(values.zones1box != undefined)
                //     {
                //         if (!test.every(elem => values.animal_2_1_zone.includes(elem)))
                //  {
                //     values.zones1box.concat(values.animal_2_1_zone);
                //     console.log(values.zones1box);
                //     console.log("values.zones1box");
                //  }

                //     }
                //     if(values.zones2box != undefined)
                //     {
                //         if (!test.every(elem => values.animal_2_4_2_zone.includes(elem)))
                //  {
                //     values.zones2box.concat(values.animal_2_4_2_zone);
                //     console.log(values.zones2box);
                //     console.log("values.zones2box");
                //     console.log(values.animal_2_4_2_zone);
                //  }

                // }
                setColdStoreEstab(values?.coldStoreEstablishments)
            }



        }, [values]);
        return null;
    };
    const extractIsoCodes = (values, memberStates) => {
        const isoCodes = [];

        if (values?.animal_2_4_1) isoCodes.push(...values.animal_2_1_zone.map(x => x.isoCode));
        if (values?.animal_2_4_2) isoCodes.push(...values.animal_2_4_2_zone.map(x => x.isoCode));
        if (values?.animal_2_4_3) isoCodes.push(...memberStates.map(x => x.isoCode));
        return isoCodes.map(str => str.replace(/-(0|1|2|3|4|5)/g, ''));
    };

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);

    useEffect(() => {
        async function fetchListData() {
            if (!ready) {
                try {
                    const getCountryData = new Promise((resolve, reject) => {
                        resolve(CountryService.getAll()
                            .then((response) => {
                                const options = response.data.map(d => ({
                                    "id": d.id,
                                    "countryName": d.countryName,
                                    "isoCode": d.isoCode
                                }))
                                SetCountrys(options);
                            })
                            .catch((e) => {
                                console.log(e);
                            })
                        );
                    });
                    const getProductScientificSpeciesData = new Promise((resolve, reject) => {
                        resolve(ProductScientificSpeciesDataService.getOnlyMeat()
                            .then((_speciesResponse) => {
                                SetSpecies(_speciesResponse.data);
                            })
                            .catch((e) => {
                                console.log(e);
                            })
                        );
                    });

                    const getProductData = new Promise((resolve, reject) => {
                        resolve(
                            ProductService.getById(productId)
                                .then(response => {
                                    SetProduct(response.data.data);
                                    setIsGEFS(response.data.data.isGEFS);
                                }, [])
                                .catch(e => {
                                    console.log(e);
                                })
                        )
                    });

                    const getBSEControlledRiskData = new Promise((resolve, reject) => {
                        resolve(
                            ZoneService
                                .getAllByGroup("ALL_BSE_CONTROLLED_RISK")
                                .then((response) => {
                                    const options = response.data.map(d => ({
                                        "id": d.id,
                                        "displayName": d.displayName,
                                        "countryName": d.countryName,
                                        "regionName": d.regionName,
                                        "isoCode": d.isoCode,
                                    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                                    SetControlledRisk(options);
                                })
                        );
                    });
                    const getBSENegligbleRiskData = new Promise((resolve, reject) => {
                        resolve(
                            ZoneService
                                .getAllByGroup("ALL_BSE_NEGLIGBLE_RISK")
                                .then((response) => {
                                    const options = response.data.map(d => ({
                                        "id": d.id,
                                        "displayName": d.displayName,
                                        "countryName": d.countryName,
                                        "regionName": d.regionName,
                                        "isoCode": d.isoCode,
                                    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                                    SetNegligbleRisk(options);
                                })
                        );
                    });
                    const getMemberStatesData = new Promise((resolve, reject) => {
                        resolve(
                            ZoneService
                                .getAllByGroup("ALL_MEMBER_STATES")
                                .then((_countryResponse) => {
                                    const options = _countryResponse.data.map(d => ({
                                        "id": d.id,
                                        "displayName": d.displayName,
                                        "countryName": d.countryName,
                                        "regionName": d.regionName,
                                        "isoCode": d.isoCode,
                                    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                                    const firstItem = "GB";
                                    options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                                    SetMemberStates(options);
                                }).catch((e) => {
                                    console.log(e);
                                })
                        );
                    });
                    const getProductDetailData = new Promise((resolve, reject) => {
                        resolve(
                            //Get site estab
                            ProductService.getProductDetailByProductId(productId)
                                .then(response4 => {
                                    //Get site operatorId
                                    SiteService.getById(response4.data.siteId)
                                        .then(response2 => {
                                            //Get establishment data from operatorid
                                            EstablishmentsService.getEstablishmentsDetails([response2.data.tracesId])
                                                .then(response3 => {
                                                    //set parent site for this product
                                                    setSiteEstablishment(response3.data)
                                                }, [])
                                                .catch(e => {
                                                    console.log(e);
                                                });
                                        }, [])
                                        .catch(e => {
                                            console.log(e);
                                        });
                                }, [])
                        );
                    });
                    const getProductMeatData = new Promise((resolve, reject) => {
                        resolve(
                            ProductMeatService.getByProductId(productId)
                                .then(response => {
                                    var res = response.data;
                                    setColdStoreEstab(res.coldStoreEstablishments)
                                    if (response.data == "") {
                                        return;
                                    }
                                    SetCountry(res.countryOriginId);
                                    SetApiValues(res);
                                    if (res !== null) {
                                        setEditing(true);
                                    }
                                }), [])
                            .catch(e => {
                                console.log(e);
                            })
                    });
                    Promise.all([getCountryData, getProductScientificSpeciesData, getProductData, getBSEControlledRiskData, getBSENegligbleRiskData, getMemberStatesData, getProductDetailData, getProductMeatData]).then((responses) => {
                        setReady(true);
                    });
                } catch (e) {
                    console.error(e);
                }
            };
        }
        fetchListData();
    }, [ready]);

    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <SubmissionPage
                    productId={productId}
                    attestationId={attestationId}
                    isGEFS={isGEFS}
                    errors={errors}
                    title={(editing ? "Edited" : "Completed") + " Meat 8384 Form"}
                    message={`Meat information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                />
            )
                :
                (

                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        {/* <PrettyPrintJson data={props.values}></PrettyPrintJson> */}

                                        <div className="">
                                            <ColdStoreSelect
                                                name="coldStoreEstablishments"
                                                selectedFieldName="coldStoreEstablishments"
                                                setEstablishmentSelected={props.setFieldValue}
                                                selections={props.values.coldStoreEstablishments}
                                                label="Cold Stores"
                                                isMulti={true}
                                                countries={["GB"]}
                                                certificateNumber={[certificateNumber]}
                                                getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                isRequired={false}
                                                readOnly={readOnly}
                                            />

                                            <MultiSelectField
                                                id='productScientificSpecies'
                                                name='productScientificSpecies'
                                                label="Species"
                                                placeholder="Select fields"
                                                options={specieslist}
                                                defaultValues={props.values?.productScientificSpecies}
                                                getOptionValue={option => option.id}
                                                getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                                value={props.values.productScientificSpecies}
                                                isMulti={true}
                                                onChange={props.setFieldValue}
                                                onBlur={props.setFieldTouched}
                                                touched={props.touched.productScientificSpecies}
                                                error={props.errors.productScientificSpecies}
                                                isClearable={true}
                                                isrequired="true"
                                                backspaceRemovesValue={true}
                                                col={4}
                                            />

                                            <FieldArray name="speciesList">
                                                {arrayHelpers => {
                                                    return (
                                                        <SpeciesTable
                                                            prefix={null}
                                                            productType="meat"
                                                            speciesArrayHelpers={arrayHelpers}
                                                            meatGroups={meatGroups}
                                                            requireSpeciesDetail={false}
                                                            requireTreatments={false}
                                                            requireCountryOfOrigin={false}
                                                            requireEstablishmentCodes={false}
                                                            errors={props.errors}
                                                            readOnly={readOnly}
                                                            productId={productId}
                                                            certificateNumber={certificateNumber}
                                                        />
                                                    );
                                                }}
                                            </FieldArray>



                                            <h4 className="mt-5">Health Attestation</h4>

                                            <p>The <strong>meat products</strong>, including rendered animal fats and greaves, meat extracts and treated stomachs, bladders and intestines others than casings, described in the list above were produced in accordance with these requirements, in particular that:
                                            </p>
                                            <ul className="list-unstyled list-clauses" >
                                                {(speciesNotGame) && (
                                                    <li>
                                                        <FormController
                                                            control="checkbox"
                                                            label={Meat8384Labels.health_1_2[0].key}
                                                            name="health_1_2_1"
                                                            isrequired="true"
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}
                                                {(speciesIsGame) && (
                                                    <li className="mt-4">
                                                        <FormController
                                                            control="checkbox"
                                                            label={Meat8384Labels.health_1_2[1].key}
                                                            name="health_1_2_2"
                                                            isrequired="true"
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}


                                                {(props.values.containsPOR) && (
                                                    <li className="mt-4">
                                                        <FormController
                                                            control="checkbox"
                                                            className="clause-container"
                                                            label={Meat8384Labels.health_1_4[0].key}
                                                            name="health_1_4_1"
                                                            readOnly={readOnly}
                                                        />
                                                        {//BSE Risk - Negligible
                                                            props.values.health_1_4_1 && (
                                                                <div className=" subform">
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={Meat8384Labels.health_1_4_1[0].key}
                                                                        name="health_1_4_1_1"
                                                                        readOnly={readOnly}
                                                                    />
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={Meat8384Labels.health_1_4_1[1].key}
                                                                        name="health_1_4_1_2"
                                                                        readOnly={readOnly}
                                                                    />
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={Meat8384Labels.health_1_4_1[2].key}
                                                                        name="health_1_4_1_3"
                                                                        readOnly={readOnly}
                                                                    />
                                                                </div>
                                                            )}
                                                    </li>
                                                )}
                                                {(props.values.containsPOR || props.values.containsSUF || props.values.containsSUW) && (
                                                    <li className="mt-4">
                                                        <FormController
                                                            control="checkbox"
                                                            className="clause-container"
                                                            label={Meat8384Labels.health_1_4[1].key}
                                                            name="health_1_4_2"
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}
                                                <li className="mt-4">
                                                    <FormController
                                                        control="checkbox"
                                                        className="clause-container"
                                                        label={Meat8384Labels.health_1_4[2].key}
                                                        name="health_1_4_3"
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                                <li className="mt-4">
                                                    <FormController
                                                        control="checkbox"
                                                        className="clause-container"
                                                        label={Meat8384Labels.health_1_4[3].key}
                                                        name="health_1_4_4"
                                                        readOnly={readOnly}
                                                    />

                                                </li>
                                                <li className="mt-4 ">
                                                    <FormController
                                                        control="checkbox"
                                                        label={Meat8384Labels.health_1_12.label}
                                                        name="health_1_12"
                                                        readOnly={readOnly}
                                                    />

                                                    {props.values.health_1_12 && (
                                                        <div className=" subform">
                                                            <FormController
                                                                control="checkbox"
                                                                label={Meat8384Labels.health_1_12_A[0].key}
                                                                name="health_1_12_1"
                                                                readOnly={readOnly}
                                                            />
                                                            <FormController
                                                                control="checkbox"
                                                                label={Meat8384Labels.health_1_12_A[1].key}
                                                                name="health_1_12_2"
                                                                readOnly={readOnly}
                                                            />
                                                        </div>
                                                    )}

                                                </li>
                                                {props.values.containsRUF && (
                                                    <li className="mt-4 ">
                                                        <FormController
                                                            control="checkbox"
                                                            label={Meat8384Labels.health_1_13.label}
                                                            name="health_1_13"
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}
                                                {props.values.containsRUW && (
                                                    <li className="mt-4 ">
                                                        <FormController
                                                            control="checkbox"
                                                            label={Meat8384Labels.health_1_14.label}
                                                            name="health_1_14"
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}
                                            </ul>

                                            {(props.values.containsBOV || props.values.containsOVI || props.values.containsRUW) && (
                                                <ul className='list-unstyled' >
                                                    <li>
                                                        <FormController
                                                            id="countryOriginId"
                                                            name="countryOriginId"
                                                            label="Country of origin of meat product"
                                                            placeholder="Select a country of origin"
                                                            control="multiselect"
                                                            options={countrys}
                                                            isMulti={false}
                                                            isClearable={true}
                                                            onBlur={props.setFieldTouched}
                                                            onChange={props.setFieldValue}
                                                            touched={props.touched.countryOriginId}
                                                            errors={props.errors.countryOriginId}
                                                            getOptionValue={option => option.id}
                                                            getOptionLabel={option => option.countryName}
                                                            // defaultValues={props.values.countryOriginId}
                                                            isrequired="true"
                                                            backspaceRemovesValue={true}
                                                            col={4}
                                                            readOnly={readOnly}
                                                        />

                                                        <MeatBseRisks
                                                            title="meat products"
                                                            formNumber="8384"
                                                            values={props.values}
                                                            labels={Meat8384Labels}
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                </ul>
                                            )}


                                            <h4 className="mt-5">Animal Health Attestation</h4>
                                            <p>The <strong>meat product</strong>, including rendered animal fats and greaves, meat extracts and treated stomachs, bladders and intestines others than casings, described in list above.</p>


                                            <ul className='list-unstyled' >

                                                <li>
                                                    <span className='required'>Has been processed in and dispatched from the following zone(s):</span>

                                                    <MultiSelectField
                                                        id='meat'
                                                        name='animal_2_1_zone'
                                                        placeholder="Select fields"
                                                        options={props?.values?.zones1box}
                                                        defaultValues={props.values.animal_2_1_zone}
                                                        getOptionValue={option => option.id}
                                                        getOptionLabel={option => option.displayName}
                                                        value={props.values.animal_2_1_zone}
                                                        isMulti={true}
                                                        onChange={props.setFieldValue}
                                                        onBlur={props.setFieldTouched}
                                                        touched={props.touched.animal_2_1_zone}
                                                        error={props.errors.animal_2_1_zone}
                                                        isClearable={true}
                                                        col={6}
                                                        isrequired="true"
                                                        backspaceRemovesValue={true}
                                                        readOnly={readOnly}
                                                    />

                                                </li>
                                                <li className="mt-4">
                                                    <span className="mt-2 required">Currently are authorised:</span>
                                                    <ol type="a">
                                                        <li>for entry into the Union of fresh meat of the species of animals from which the meat product described in Part I has been processed and listed in.
                                                            {(speciesIsUngulates) && (
                                                                <FormController
                                                                    control="checkbox"
                                                                    label={Meat8384Labels.animal_2_1_a[0].key}
                                                                    name="animal_2_1_a_1"
                                                                    isrequired="true"
                                                                    readOnly={readOnly}
                                                                />
                                                            )}
                                                            {(speciesNotUngulates) && (
                                                                <FormController
                                                                    control="checkbox"
                                                                    label={Meat8384Labels.animal_2_1_a[1].key}
                                                                    name="animal_2_1_a_2"
                                                                    isrequired="true"
                                                                    readOnly={readOnly}
                                                                />
                                                            )}

                                                        </li>
                                                        <li>and listed in Part 1 of Annex XV to Implementing Regulation (EU) 2021/404 for entry into the Union of the meat products described in Part I under the non-specific treatment "A";</li>
                                                    </ol>
                                                </li>
                                                <li className="mt-4">
                                                    <span className="required">Has been processed from <strong><u>fresh meat</u></strong> that complied with all the relevant requirements for entry into the Union of fresh meat laid down in Commission
                                                        Delegated Regulation (EU) 2020/692 and, therefore, was eligible for entry into the Union as such and was obtained from animals that complied with the
                                                        residency period in an establishment located in:</span>


                                                    <div className="ms-4">

                                                        <FormController
                                                            control="checkbox"
                                                            label={Meat8384Labels.animal_2_4[0].key}
                                                            name="animal_2_4_1"
                                                            readOnly={readOnly}
                                                        />

                                                        <FormController
                                                            control="checkbox"
                                                            label={Meat8384Labels.animal_2_4[1].key}
                                                            name="animal_2_4_2"
                                                            readOnly={readOnly}
                                                        />
                                                        {props.values.animal_2_4_2 && (
                                                            <div className="mb-4 ms-4">
                                                                <MultiSelectField
                                                                    id='animal_2_4_2_zone'
                                                                    name='animal_2_4_2_zone'
                                                                    label="Select zone"
                                                                    placeholder="Select fields"
                                                                    options={props?.values?.zones2box}
                                                                    getOptionValue={option => option.id}
                                                                    getOptionLabel={option => option.displayName}
                                                                    value={props.values.animal_2_4_2_zone}
                                                                    isMulti={true}
                                                                    onChange={props.setFieldValue}
                                                                    onBlur={props.setFieldTouched}
                                                                    touched={props.touched.animal_2_4_2_zone}
                                                                    error={props.errors.animal_2_4_2_zone}
                                                                    isClearable={true}
                                                                    col={6}
                                                                    isrequired="true"
                                                                    backspaceRemovesValue={true}
                                                                    readOnly={readOnly}
                                                                />
                                                                <br />

                                                                {(speciesIsUngulates) && (
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={Meat8384Labels.animal_2_4_2[0].key}
                                                                        name="animal_2_4_2_1"
                                                                        isrequired="true"
                                                                        readOnly={readOnly}
                                                                    />
                                                                )}

                                                                {(speciesNotUngulates) && (
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={Meat8384Labels.animal_2_4_2[1].key}
                                                                        name="animal_2_4_2_2"
                                                                        isrequired="true"
                                                                        readOnly={readOnly}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                        <FormController
                                                            control="checkbox"
                                                            label={Meat8384Labels.animal_2_4[2].key}
                                                            name="animal_2_4_3"
                                                            readOnly={readOnly}

                                                        />
                                                    </div>
                                                </li>

                                                {(speciesNotGame) && (
                                                    <li>
                                                        <FormController
                                                            control="checkbox"
                                                            label={Meat8384Labels.animal_2_5[0].key}
                                                            name="animal_2_5_1"
                                                            isrequired="true"
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}
                                                {(speciesIsGame) && (
                                                    <li className="mt-4">
                                                        <FormController
                                                            control="checkbox"
                                                            label={Meat8384Labels.animal_2_5[1].key}
                                                            name="animal_2_5_2"
                                                            isrequired="true"
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                )}


                                                <SlaughterhouseSelect
                                                    name="abattoirEstablishments"
                                                    selectedFieldName="abattoirEstablishments"
                                                    setEstablishmentSelected={props.setFieldValue}
                                                    selections={props.values.abattoirEstablishments}
                                                    label="Slaughterhouse"
                                                    isMulti={true}
                                                    species={
                                                        props.values?.speciesList
                                                            .map(x => x?.speciesGroupName)
                                                    }
                                                    countries={extractIsoCodes(props.values, memberStates)}
                                                    certificateNumber={[certificateNumber]}
                                                    getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                    getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                    isRequired={true}
                                                    readOnly={readOnly}
                                                />

                                                {/* <CuttingPlantSelect
                                                    name="cuttingPlantEstablishments"
                                                    selectedFieldName="cuttingPlantEstablishments"
                                                    setEstablishmentSelected={props.setFieldValue}
                                                    selections={props.values.cuttingPlantEstablishments}
                                                    label="Cutting plants"
                                                    isMulti={true}
                                                    species={
                                                        props.values?.speciesList
                                                            .map(x => x?.speciesGroupName)
                                                    }
                                                    countries={extractIsoCodes(props.values, memberStates)}
                                                    certificateNumber={[certificateNumber]}
                                                    getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                    getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                    isRequired={false}
                                                    readOnly={readOnly}
                                                /> */}

                                                <MultiSelectField
                                                    id='identificationMarkEstablishments'
                                                    name='identificationMarkEstablishments'
                                                    label="Identification mark"
                                                    placeholder="Select fields"
                                                    options={Array.from(new Map([...(siteEstablishment || []), ...(coldStoreEstab || [])].map(item => [item["approvalNumber"], item])).values())}
                                                    getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                    getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                    isMulti={false}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.identificationMarkEstablishments}
                                                    error={props.errors.identificationMarkEstablishments}
                                                    isClearable={true}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                    col={6}
                                                />

                                                {(props.values.containsPOU || props.values.containsGB) && (
                                                    <li className="mt-4">
                                                        <label><span className='text-danger'>Select this option below if the product is to be exported to Sweden or Finland</span></label>
                                                        <FormController
                                                            control="checkbox"
                                                            label={Meat8384Labels.animal_2_7.label}
                                                            name="animal_2_7"
                                                            readOnly={readOnly}
                                                        />
                                                        <label><span className='text-danger text-italic'>We require a supplementary declaration from the farmer and/or the registered vet responsible for holding (please ensure they include the post code of the holding in the statement) that confirm they have not vaccinated the birds against Newcastle disease with a live vaccine during the period of 30 days prior to the date of slaughter this supplementary declaration needs to be uploaded at supporting evidence</span> </label>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        {/* <PrettyPrintJson data={props.values} /> */}
                                        {/* <PrettyPrintJson data={props.errors} />  */}
                                        <div className="form-group">
                                            <hr />
                                            {(Object.keys(props.errors).length > 0 && submitAttempted) &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" onClick={() => setSubmitAttempted(true)} disabled={isSubmitting || readOnly}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />
                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >

                )
            }
        </div>
    );
};

export default Meat8384AddPage;