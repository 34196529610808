///////////////////////////////////////////////////////////////////////////////////
// Converts filter data to URL search parameters object 
///////////////////////////////////////////////////////////////////////////////////
export const convertFiltersToURLParams = (values, activePage, pageSize, orderBy, searchText) => {
    
  if( values==null){
      return null;
  }

  //Build paramaters opject
  var params = new URLSearchParams({});

  if(values.length >= 0){
  //Get default filter list from values for the specific page or component
    const defaultFilterValues = Array.from(new Set(values?.map(item => item.group)));
    //Build - Filter lists parameters - optional
    defaultFilterValues?.forEach((group, i) => { 
        var matches = values?.filter(x => x.group === group) //
        if(matches !== null){
            var values = matches?.map(x => x.value).join(",") // multiple params
            params.set(group, values);
        }
    });
  }

  //Build - Mandatory search parameters
  params = addMandatoryParams(params, activePage, pageSize, orderBy, searchText);
  
return params;
}
    
///////////////////////////////////////////////////////////////////////////////////
//Convert selections to Parameters
///////////////////////////////////////////////////////////////////////////////////

export function convertSelectionsToUrlParams(selections, activePage, pageSize, orderBy, searchText){
  if(selections==undefined){
    return;
  }
  var params = new URLSearchParams({});
  var groups = selections?.map(x=>x.group);
  groups.forEach((group, i) => { 
    var matches = selections.filter(x => x.group === group)
    if(matches !== null){
        var uniqueValuesSet = new Set(matches.map(x => x.value));
        var uniqueValues = [...uniqueValuesSet];
        var values = uniqueValues.join(",");
        params.set(group, values);
    }
  });
  
  //Build - Mandatory search parameters
  params = addMandatoryParams(params, activePage, pageSize, orderBy, searchText);
  
  return params;
}



///////////////////////////////////////////////////////////////////////////////////
//Convert Parameters to selections
///////////////////////////////////////////////////////////////////////////////////

export function convertURLParamsToSelections(location, defaultFilterValues){
  var res = [];
  const searchParams = new URLSearchParams(location.search);
  
  //Loop through each parameter in the URL
  for (const [group, values] of searchParams.entries()) {
    const valuesArray = values.split(',');

    //Integrated filter components
    if(group=="search" ){
      res.push(
        {
          group: "search", 
          label: values, 
          value: values, 
          title: "Search", 
          isFilter: true 
        }
      );
    }
    
    //Check filter exists in default filter list
    const matchedGroup = defaultFilterValues?.find(x=>x.name === group);
    // if(matchedGroup == undefined){
    //   continue;
    // }

    //Generic filter components
    if(matchedGroup?.isFilter){
      //Multiple values
      if(values.includes(",")){
          valuesArray.forEach((value) => {
            res.push({
              group: group, 
              value: value, 
              label: getLabel(value, group, defaultFilterValues), 
              title: getTitle(group, defaultFilterValues),
              isFilter: true 
            });
          });
      }
      //Single value
      else{
        res.push({
            group: group, 
            value: values, 
            label: getLabel(values, group, defaultFilterValues), 
            title: getTitle(group, defaultFilterValues), 
            isFilter: true 
        });
      }
    }
  }
  return res;
}



///////////////////////////////////////////////////////////////////////////////////
//Convert Selections to Service Parameters (API)
///////////////////////////////////////////////////////////////////////////////////

export function convertURLParamsToServiceParams(location, defaultFilterValues, activePage, pageSize, orderBy ){
  var serviceParams = {};
  //Filter list paramaters
  var searchParams = new URLSearchParams(location.search);
  for (const [group, values] of searchParams.entries()) {
    var match = defaultFilterValues?.find(x=>x.name === group && x.value !== null);
    if(match != undefined){
      serviceParams[match.name] = values;
    } 
    if(group === "search"){
      serviceParams["search"] = values;
    }
  }
  //Mandatory parameters
  serviceParams["page"] = activePage;
  serviceParams["size"] = pageSize;
  serviceParams["orderBy"] = orderBy;
  return serviceParams;
}


///////////////////////////////////////////////////////////////////////////////////
//Helper functions
///////////////////////////////////////////////////////////////////////////////////

//Get title property from selections list
function getTitle(group, defaultFilterValues){
  var title = defaultFilterValues.find(x=>x.name === group)?.title;
  return title;
}

//Get label property from selections list
function getLabel(value, group, defaultFilterValues){
  var list = defaultFilterValues?.find(x=>x.name === group)?.data;
  const item = list?.find(x=>x.value == value);
  return item?.label;
}


export function addMandatoryParams(params, activePage, pageSize, orderBy, searchText){
  
  //Build - Search parameters - optional
  if(searchText !== null && searchText !== "" && searchText !== undefined){
    params.set("search", searchText); //get from input if available
  }
  else if(params.get("search") !== null){
      params.set("search", params.get("search")); //get from param if available
  }

  //Build - orderBy parameters - optional
  if(orderBy !== null && orderBy !== ""){
    params.set("orderBy", orderBy); //get from input if available
  }
  else if(params.get("orderBy") !== null){
      params.set("orderBy", params.get("orderBy")); //get from param if available
  }

  //Build - Paging paramaters - mandatory
  params.set("page", params.get("page") ? params.get("page") : activePage);
  params.set("size", params.get("size")? params.get("size") : pageSize);

  return params;
}
