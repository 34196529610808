import { Badge, Card, Table } from '@themesberg/react-bootstrap';
import moment from "moment";
import React from "react";
import { Link, useHistory } from 'react-router-dom';
import Preloader from "../Preloader";
import { NoResults } from "./NoResults";
import { Routes } from "../../routes";

export const SupportAttestationTable = (props) => {
  const { attestationRequests: attestationRequests, loaded=false } = props;


  const TableRow = (props) => {
    const {id, isGEFS, productName, attestationRequest, attestationDetails } = props;
    
    const getSADate = (date) => {
      var newDate = moment(date).format("Do MMM YYYY");
      if (newDate === "Invalid date") {
          return "";
      }
      return newDate;
    }
    return (
      <tr>
        <td>
          <Card.Link 
            as={Link} 
            to={isGEFS? Routes.ResponseAttestationPage.path.replace(":id",id) : Routes.ResponseAttestationNonGEFSPage.path.replace(":id",id)}
            className="fw-normal text-black">
              {productName}
          </Card.Link>
        </td>
        <td> 
          <span className="fw-normal">
          {getSADate(attestationRequest.createdDate)}
          </span>
        </td>
        <td> 
          <span className="fw-normal">
            Awaiting Response 
          </span>
        </td>
        <td> 
          <span className="fw-normal">
            {isGEFS? 
              <Badge className="rounded px-3 py-2 bg-success"> GEFS</Badge>
            : 
              <Badge className="rounded px-3 py-2 bg-warning"> Non-GEFS</Badge>
            }     
          </span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Preloader component={true} show={!loaded} />

      <Table hidden={!loaded || attestationRequests==0} hover className="user-table align-items-center">
        <thead>
          <tr>
            <th className="border-bottom">Product name</th>
            <th className="border-bottom">Date requested</th>
            <th className="border-bottom">Status</th>
            <th className="border-bottom">Type</th>
          </tr>
        </thead>
        <tbody>
            {attestationRequests.map(t => <TableRow key={`transaction-${t.id}`} {...t} />)}

          
        </tbody>
      </Table>
        {loaded && attestationRequests.length == 0 &&(
          <NoResults name="attestation requests"/>
        )}
      </>
  );
};

export default function Td({ children, to, id }) {
  // Conditionally wrapping content into a link
  const ContentTag = to ? Link : 'div';
  
  const history = useHistory();
  const _viewSupportAttestation = (filename) => {
    history.push(Routes.Product.path.replace(":id", id));
  };
  return (
    <td style={{ cursor: "pointer", color:"text-dark" }} onClick={()=>_viewSupportAttestation(id)}>
      <ContentTag className="text-dark"  to={to}>{children}</ContentTag>
    </td>
  );
}