import *  as baseService  from "./BaseService";

const getAll = () => {
    return baseService.getApiData("/certificatenumber/getall");
};
const getById = (id) => {
    return baseService.getApiData("/certificatenumber/GetById?id=" + id);
};
const getByProductId = (productId) => {
    return baseService.getApiData("/certificatenumber/GetByProductId?productId=" + productId);
};
const getByCertificateNumber = (number) => {
    return baseService.getApiData("/certificatenumber/GetByCertificateNumber?number=" + number);
};

const CertificateNumberService = {
    getAll,
    getById,
    getByProductId,
    getByCertificateNumber
};

export default CertificateNumberService;