
import { faCheck, faCheckCircle, faFileContract, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Spinner } from "@themesberg/react-bootstrap";
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import PermissionsCheck from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import { getUserInfo } from "../../../../auth/authorisation";
import Progress from "../../../../components/Progress";
import SigningComponent from '../../../../components/SigningComponent';
import BlobService from '../../../services/BlobService';
import CertificateNumberService from '../../../services/CertificateNumberService';
import ManufacturerService from '../../../services/ManufacturerService';
import OrganisationService from '../../../services/OrganisationService';
import PlatformService from '../../../services/PlatformService';
import ProductDataService from "../../../services/ProductService";
import SiteDataService from "../../../services/SiteService";
import StorageDeclarationService from '../../../services/StorageDeclarationService';
import TemperatureService from '../../../services/TemperatureService';
import { GetCertificateFormData, GetCertificateVersions } from '../../CertificateForms/CertificateFormFunctions';
import FormController from '../../common/FormController';
import SupplierDeclarationTemplate from './SupplierDeclarationTemplate';
import languages from './Data/languages.json';

export const SupplierDeclarationButton = (props) => {
    const {productId,  hasCurrent, handleReload } = props;
    const [progressMessage, setProgressMessage] = useState('');
    const [showModalGenerating, setShowModalGenerating] = useState(false);
    const [generatingPercentage, setGeneratingPercentage] = useState(null);
    const [generateTime, setGenerateTime] = useState('');
    const [running, setRunning] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [versionErrorMessage, setVersionErrorMessage] = useState([]);;
    const [ generatedDocument, setGeneratedDocument ] = useState('');
    const [ isProcessing, setProcessing ] = useState(false);
    const [ isSuccess, setSuccess ] = useState(false);
    const [manufacturer, setManufacturer] = useState(null);
    const [platform, setPlatform] = useState(null);
    const [manufacturerId, setManufacturerId] = useState(null);
    const [ isGenerated, setGenerated ] = useState(false);
    const [ user, setUser ] = useState([]);
    const [ uniqueRef, setUniqueRef ] = useState([]);
    const [ product, setProduct ] = useState([]);
    const [ productDetail, setProductDetail ] = useState([]);
    const [ temperature, setTemperature ] = useState(null);
    const [ certificateFormData, setCertificateFormData ] = useState([]);
    const [ certificateNumber, setCertificateNumber ] = useState([]);
    const [ site, setSite ] = useState([]);
    const [ organisation, setOrganisation ] = useState([]);
    const [certificateNumberList, setCertificateNumberList] = useState([]);
    const [isVersionOutdated, setVersionOutdated] = useState(false); 
    const [activeStep, SetActiveStep] = useState(1); 
    const [validTo, setValidTo] = useState(null);

    const childRef = useRef(null);

    const initialValues = {
        documentId: '',
        documentSigningId: '',
        signedRequestUrl: '',
        languageCode: ''
    }

    const openBlobPdf = (filename) => {
        BlobService.openBlobPdf(filename);
    }
    const validationMsgRequired = "This field is required";
    const validationSchema = yup.object().shape({
        languageCode: yup.string().required(validationMsgRequired)
    });
    
    useEffect(()=>{ 
        //Get User Data / Get New URN 
        getUserInfo().then(x => {
            setUser(x);
        });
        _handleGenerateUrn();
        
        //Get Product Data
        function fetchProductDetail() {

            var dateTo = moment().add(6, 'months').format("D MMMM, YYYY");
            setValidTo(dateTo);

            ProductDataService.getWithDetailsById(productId)
            .then(response => {
                var manufacturerId = response.data.manufacturerId;
                var siteId = response.data.productDetails.siteId;
                var platformId = response.data.productDetails.platformId;
                var temperatureId = response.data.productDetails.temperatureId;
                let certList = response.data.productDetails.certificateNumberList;
                setProduct(response.data);
                setProductDetail(response.data.productDetails);
                setCertificateNumber(response.data.productDetails.certificateNumber);
                setManufacturerId(manufacturerId);
                setCertificateNumberList(certList);

                //Get Certificate Data
                CertificateNumberService.getAll()
                .then((response) => {

                    let selectedCertificateNumbers = response.data.filter(x => certList.includes(x.number.toString()));
                    let selectedCertificateVersions = GetCertificateVersions();
                    selectedCertificateVersions.filter(x => certList.includes(x.number.toString()))

                    fetchCertFormData(selectedCertificateNumbers)
                        .then(certFormData=> {  
                        
                        //Set certificateFormData for each Form
                        setCertificateFormData(certFormData);

                        //Find selected form versions from certificate config
                        var outDatedList =  [];
                        selectedCertificateVersions.forEach(x => { 
                            var certVersionNumber = certFormData[x.number]?.version;
                            if(certVersionNumber != undefined){
                                //Check if version is outdated
                                if(certVersionNumber < x.version){
                                setVersionOutdated(true);
                                outDatedList.push(x.number)
                                }
                            }
                        });
                        setVersionErrorMessage(outDatedList)

                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    
                })
                .catch((e) => {console.log(e);})
                    
                //Get Associated Data
                ManufacturerService.getById(manufacturerId)
                .then((response) => {
                    setManufacturer(response.data);
                })
                .catch((e) => {console.log(e);});

                SiteDataService.getWithEstablishmentNumberType(siteId)
                .then((response) => {
                    setSite(response.data);
                })
                .catch((e) => {console.log(e);});

                TemperatureService.getById(temperatureId)
                .then((response) => {
                    setTemperature(response.data);
                })
                .catch((e) => {console.log(e);});

                PlatformService.getWithEstablishmentNumberType(platformId)
                    .then((response) => {
                        setPlatform(response.data);
                    })
                    .catch((e) => {console.log(e);});
            })
            .catch(e => {console.log(e);});
            
            //Get Associated Data
            // PlatformCertifiersService.getAll()
            //     .then((response) => {
            //         setPlatformCertifiers(response.data);
            //     })
            //     .catch((e) => {console.log(e);});
                
            OrganisationService.getFirst()
                .then((response) => {
                    setOrganisation(response.data);
                })
                .catch((e) => {console.log(e);});

            }
            fetchProductDetail();
        },[]);
    
    
    //Function - Get Gertificate form data from product id and the certificate numbers required on this product
    function fetchCertFormData(selectedCertificateNumbers) {
        return GetCertificateFormData(productId, selectedCertificateNumbers, false)
        .then(x=> {
            return x
        })
        .catch((e) => {console.log(e);});
    }
      
    //Function - Generate a URN for this Attestation
    function _handleGenerateUrn() {
        StorageDeclarationService.getUniqueReference(productId)
        .then((response) => {
            setUniqueRef(response.data);
            })
        .catch((e) => {console.log(e);});
    }
    
    
    //Function - Generate a document for this Storage Declaration
    function _handleGenerateDocument(){
        setProcessing(true);
        StorageDeclarationService.createDocument(childRef.current.outerHTML)
        .then((response) => {
            setGeneratedDocument(response.data);
            setProcessing(false);
          });

    }
    

    
    //Submit request
    function onSubmit(values, { setSubmitting, resetForm }) {
        setGeneratingPercentage(0);
        setRunning(true);
        let submitData = values;
        submitData.productId = productId;
        submitData.manufacturerId = manufacturerId;
        submitData.uniqueCode = uniqueRef;
        submitData.certificateNumber = certificateNumber[0];
        submitData.documentSigningId = values.documentId;
        submitData.filename = values.signedRequestUrl;
        submitData.productJson = JSON.stringify({
            product: product, 
            productDetail: productDetail, 
            platform: platform, 
            organisation: organisation,  
            manufacturer: manufacturer, 
            site: site, 
            attestationData: certificateFormData, 
        }
    );

    //Submit request
    StorageDeclarationService.create(submitData)
    .then((response) => {
        setSuccess(true);
        setRunning(false);
        
    })
    .catch((e) => {
        setSuccess(false);
        setRunning(false);
      console.log(e);}
    )
    .finally(() => {
        setSubmitting(false);
        setRunning(false);
        //handleReload();
    });
    }


    //Check is PDF is being generated in background, ready for signing
    useEffect(()=>{ 
        if(generatedDocument!==undefined && generatedDocument!==null && generatedDocument!=='' ){
            
            setGeneratingPercentage(100);
            setTimeout(() => {
                //handleCloseModal();
                setRunning(false);
            }, 2000);
            setGenerated(true);
            setProcessing(false);
        }
        else{
            setGenerated(false);
            setProcessing(false);
        }
    },[generatedDocument]);

    //Effect - Run when uploading is occuring
    useEffect(() => {
        if(isProcessing){
            var seconds = (15);
            var duration = "";
            if(seconds>59){
                var m = moment.utc(moment.duration(seconds, "seconds").asMilliseconds());
                var roundUp = m.second() || m.millisecond() ? m.add(1, 'minute').startOf('minute') : m.startOf('minute');
                duration = roundUp.format("m") + " minutes"
            }
            else{
                duration = moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("s")+ " seconds"
              }
            var increment = (0.05);
            setGenerateTime(duration);
            const timeout = setTimeout(() => {
                setGeneratingPercentage((prev) => prev + increment);
            }, 1);
            if (generatingPercentage >1 && generatingPercentage <= 80) {
                setProgressMessage("Generating the declaration document...");
            }
            if (generatingPercentage >80 && generatingPercentage <= 100) {
                setProgressMessage("Finishing up...");
            }
            if (generatingPercentage >= 99) {
              clearTimeout(timeout);
            }
            return () => (clearTimeout(timeout));
        }
      }, [isProcessing, generatingPercentage]);

    const _handleCancel = () => {
        SetActiveStep(0);
        setShowModal(false);
        setProcessing(false);
        setRunning(false);
        setGeneratedDocument(null);
        setGenerated(false);
        setGeneratingPercentage(0);
    }

    //Modal - Close
    const handleCloseModal = () => {
        SetActiveStep(0);
        setRunning(false);
        setShowModal(false);
        handleReload();
    }
    //Modal - Open
    const handleOpenModal = () => {
        SetActiveStep(2);
        setShowModal(true);
        setRunning(false);
    }


    
    useEffect(()=>{ 
        !isGenerated && showModal && SetActiveStep(2);
        isProcessing && !isGenerated && SetActiveStep(3);
        isGenerated && !isSuccess && SetActiveStep(4);
        isGenerated && isSuccess && SetActiveStep(5);
    },[isGenerated,isProcessing, isSuccess]);



    return(
        <>
        
        {!isGenerated && !hasCurrent &&
            <p className="mt-1 mb-3">No current valid storage declaration has been found for this product.</p>
        }

        <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.storagerequests.create]}>

            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={true}
                validateOnBlur={true}
                enableReinitialize="true"
            >
                {(props, isSubmitting) => (
                <Form>

                    {activeStep<= 1 &&
                    <>
                        {/* 1 - Create new / open modal */}
                        <Button onClick={handleOpenModal} variant="light" size="md" className="d-block mx-auto"> 
                        {isProcessing ? 
                            <Spinner animation="border" size="sm" className="me-2" />
                        :
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                        }

                        <span>Create a Storage Declaration</span>
                        </Button>
                    </>
                    }
                
                    {/* //Modal - Start*/}
                    {activeStep > 1 &&
                    <>
                        <Modal size="lg" as={Modal.Dialog} centered show={showModal}>
                            <Modal.Body>
                                <div className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
                                    <div className="container">
                                        <div className="d-flex align-items-center justify-content-center col-12">
                                            <div className='text-center p-4 p-lg-5 w-100 fmxw-500'>
                                                {/* 1 - Confirm language */}
                                                {activeStep == 2 &&
                                                <>
                                                    <h2 className="mb-5">Select Additional Language</h2>
                                                    <p>The main document will be produced in English, select your local language to add translations throughout the document.</p>
                                                    <FormController 
                                                        name="languageCode"  
                                                        control="select" 
                                                        options={languages} 
                                                    />
                                                    <Button onClick={_handleCancel} variant="light" size="md" className="d-inline mx-auto my-3"> 
                                                        <span>Cancel</span>
                                                    </Button>
                                                    <Button onClick={_handleGenerateDocument} disabled={props.values?.languageCode==""} variant="primary" size="md" className="d-inline my-3 mx-2"> 
                                                        {isProcessing ? 
                                                            <Spinner animation="border" size="sm" className="me-2" />
                                                        :
                                                            <FontAwesomeIcon icon={faCheck} className="me-2" />
                                                        }

                                                        <span>Confirm</span>
                                                    </Button>
                                                </>
                                                }

                        

                                                {/* 2 - Generating */}
                                                {activeStep == 3 &&
                                                <>
                                                    <h2 className="mb-5">Creating document</h2>
                                                    <div className="generateprogress"><Progress value={generatingPercentage} variant="success" label="" size="xl" />
                                                        <label>{progressMessage}</label>
                                                    </div>
                                                    <Button onClick={_handleCancel} variant="light" size="md" className="d-block mx-auto my-3 d-inline "> 
                                                        <span>Cancel</span>
                                                    </Button>
                                                </>
                                                }


                                                {/* 3 - Sign */}
                                                {activeStep == 4 &&
                                                    <>
                                                        {/* Sign new storage declaration */}
                                                         <h2 className="mb-5">Review and sign</h2>
                                                        <p>The document has been produced and ready for signing.</p>
                                                        <Button onClick={_handleCancel} variant="light" size="md" className="my-3 me-3 d-inline "> 
                                                            <span>Cancel</span>
                                                        </Button>
                                                        <SigningComponent
                                                            document={generatedDocument}
                                                            ready={!isGenerated}
                                                            signingWaitText = "Sigining document..."
                                                            buttonText = "Sign Storage Declaration" 
                                                            className="my-3 d-inline "
                                                        />
                                                    </>
                                                }

                                                {/* 4 - Success */}
                                                {activeStep == 5 &&
                                                    <>
                                                        {/*Success page */}
                                                         <h2 className="mb-5">
                                                            <FontAwesomeIcon icon={faCheckCircle} className="ms-1 text-success mx-1"/>
                                                            Successfully signed
                                                         </h2>
                                                        <p>
                                                            The supplier storage declaration document has now been signed and completed and is valid for <strong>6 months</strong>.
                                                        </p>
                                                        <Button 
                                                            variant="light" size="md" className="d-print-none"
                                                            onClick={()=>{openBlobPdf(props.values.signedRequestUrl)}}>
                                                            <FontAwesomeIcon icon={faFileContract} className="me-2" />
                                                            <span>Signed Declaration</span>
                                                        </Button>
                                                        <Button onClick={handleCloseModal}  variant="primary" size="md" className="d-block mx-auto my-3"> 
                                                            <span>Back to product</span>
                                                        </Button>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                 {/* Hidden - Template for Pdf */}
                                <SupplierDeclarationTemplate 
                                    user={user}
                                    childRef={childRef}
                                    uniqueRef={uniqueRef}
                                    product={product}
                                    manufacturer={manufacturer}
                                    platform={platform}
                                    organisation={organisation}
                                    site={site}
                                    productDetail={productDetail}
                                    certificateFormData={certificateFormData[8461]}
                                    certificateNumber={certificateNumber}
                                    temperature={temperature}
                                    languageCode={props.values.languageCode}
                                    validTo={validTo}
                                />
                            </Modal.Body>
                        </Modal>
                    </>
                    }
                </Form>
                )}
            </Formik>
        </PermissionsCheck>
        </>
    )
}

export default SupplierDeclarationButton;