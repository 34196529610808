import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  useFormikContext } from 'formik';
import {  Button,Card, Col, Row } from "@themesberg/react-bootstrap";
import { faFileContract} from '@fortawesome/free-solid-svg-icons';
import { Batches, ValidDate, NumberPackages, FinalConsumer, InvoiceNumber, Weights } from '../Components/TemplateContent';
import BlobService from '../../../../services/BlobService';
export default function ExporterDeclarationForm(props) {
  const { values: formValues } = useFormikContext();
  const {
    formField: {
      batches,
      finalConsumer,
      netWeight,
      grossWeight,
      invoiceNumber,
      numberPackages
    },
    setFieldValue,
    initialInspectionDate,
    site, 
    user,
    storageRequestData,
    productDetail,
    validToDate
  } = props;
  


  useEffect(() => {
    {
      const productNetWeight = productDetail?.netWeight;
      const productGrossWeight = productDetail?.grossWeight;
      setFieldValue("netWeight",productNetWeight)
      setFieldValue("grossWeight",productGrossWeight)
    }
  },[]);

  const openBlobPdf = (filename) => {
    BlobService.openBlobPdf(filename);
  }

  return (
    <Row className="justify-content-left mt-4">
      <Col xl={11}>
        <Card border="light" className="bg-white shadow-sm mb-4 p-3">
          <Card.Body>
            <Button 
                variant="light" size="md" className="d-print-none"
                onClick={()=> openBlobPdf(storageRequestData?.signedDocument?.filename)}
                target="_blank"> 
                <FontAwesomeIcon icon={faFileContract} className="me-2" />
                <span>View Supplier Storage Declaration</span>
            </Button>

            <hr className="my-3 d-print-none"/>
            <ValidDate
              site={site}
            />

            <Row className="mt-3">
                <Col md={12}>
                    <NumberPackages
                        numberPackages={numberPackages}
                        isReadOnly={false}  
                    />
                    <FinalConsumer
                        finalConsumer={finalConsumer}
                        isReadOnly={false}  
                    />
                    <Batches
                        batches={batches}
                        isReadOnly={false}   
                    />

                    <Weights 
                        netWeight={netWeight}
                        grossWeight={grossWeight}
                        isReadOnly={false}  
                    />
                    <InvoiceNumber
                        invoiceNumber={invoiceNumber}
                        isReadOnly={false}   
                    />
                </Col>
            </Row>
          
          <Row className="mt-3">
            <hr  className='my-4'/>
              <h5>Certifier</h5>
              <ul className="list-unstyled">
                <li>Signed name: {user.fullName}</li>
                <li>Job Title: {user.jobTitle}</li>
                <li>Email address: {user.email}</li>
              </ul>
          </Row>

          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
