import *  as baseService  from "./BaseService";


const getAll = (params) => {
    return baseService.getApiData("/EstablishmentNumberType/GetAll");
};

const EstablishmentTypeService = {
    getAll
};

export default EstablishmentTypeService;