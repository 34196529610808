import *  as baseService  from "./BaseService";

const getByCertificateId = (certificateId) => {
    return baseService.getApiData("/CertificateAssignedCompound/GetByCertificateId?certificateId=" + certificateId);
};
const getByConsignmentContentsId = (consignmentContentsId) => {
    return baseService.getApiData("/CertificateAssignedCompound/GetByConsignmentContentsId?consignmentContentsId=" + consignmentContentsId);
};
const removeByConsignmentContentsId = (consignmentContentsId) => {
    return baseService.deleteApiData("/CertificateAssignedCompound/DeleteByConsignmentContentsId?consignmentContentsId=" + consignmentContentsId);
};

const CertificateAssignedCompoundService = {
    getByCertificateId,
    getByConsignmentContentsId,
    removeByConsignmentContentsId
};

export default CertificateAssignedCompoundService;