import * as HighlyRefined8399Labels from "../HighlyRefined8399Labels"
import { findArrayElementByFieldNameAndValue, findArrayElementByValue } from "../../../Attestations/Common/AttestationFunctions";
import React from 'react';

export const AttestationContent = (props) => {
    const { attestationData } = props;
    return (
        attestationData !== undefined &&
        <>
            <>
                <h5>II. Health Information</h5>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned, declare that I am aware of the relevant requirements of Regulation (EC) No 178/2002 of the European Parliament and of the Council,
                    Regulation (EC) No 852/2004 of the European Parliament and of the Council, Regulation (EC) No 853/2004 of the European Parliament and of the Council and
                    Regulation (EU) 2017/625 of the European Parliament and of the Council, and hereby certify that the highly refined products described in Section A.2 were produced in
                    accordance with these requirements, in particular that they:
                </p>
                <ol type='a'>
                    <li>come from (an) establishment(s) that has(ve) been registered and implement(s) a programme based on the hazard analysis and critical control points (HACCP)
                        principles in accordance with Article 5 of Regulation (EC) No 852/2004 and regularly audited by the competent authority;
                    </li>
                    <li>
                        have been handled and, where appropriate, prepared, packaged and stored in a hygienic manner in accordance with the requirements of Annex II to Regulation
                        (EC) No 852/2004; </li>
                    <li> comply with the requirements of Annex III, Section XVI, to Regulation (EC) No 853/2004; and</li>
                    {attestationData.health_1 ? (
                        <li>
                            are amino acids:
                            <ol type='i'>
                                <li>    {attestationData.health_1_statements.includes("1") &&
                                    findArrayElementByValue(HighlyRefined8399Labels.health_1_statements, "1")}
                                    {attestationData.health_1_statements.includes("1") == false &&
                                        <p>N/A</p>
                                    }
                                </li>
                                <li>    {attestationData.health_1_statements.includes("2") &&
                                    findArrayElementByValue(HighlyRefined8399Labels.health_1_statements, "2")}
                                    {attestationData.health_1_statements.includes("2") == false &&
                                        <p>N/A</p>
                                    }
                                </li>
                            </ol>
                        </li>
                    ) :
                        (
                            <li>N/A</li>
                        )}
                    {attestationData.health_2 ? (
                        <li>
                            are fat derivatives, submitted to:
                            <ol type='i'>
                                {/* <li>    {attestationData.health_2_statements.includes("1") &&
                                    findArrayElementByValue(HighlyRefined8399Labels.health_2_statements, "1")}
                                    {attestationData.health_2_statements.includes("1") == false &&
                                        <p>N/A</p>
                                    }
                                </li>
                                <li>    {attestationData.health_2_statements.includes("2") &&
                                    findArrayElementByValue(HighlyRefined8399Labels.health_2_statements, "2")}
                                    {attestationData.health_2_statements.includes("2") == false &&
                                        <p>N/A</p>
                                    }
                                </li>
                                <li>    {attestationData.health_2_statements.includes("3") &&
                                    findArrayElementByValue(HighlyRefined8399Labels.health_2_statements, "3")}
                                    {attestationData.health_2_statements.includes("3") == false &&
                                        <p>N/A</p>
                                    }
                                </li> */}
                                {attestationData.health_2_statements > 0 &&
                                    <ul>
                                        <li>
                                            {findArrayElementByFieldNameAndValue(HighlyRefined8399Labels.health_2_statements, 'health_2_statements', attestationData.health_2_statements)}
                                        </li>
                                    </ul>
                                }
                            </ol>
                        </li>
                    ) :
                        (
                            <li>N/A</li>
                        )}
                    {attestationData.health_3 ?(
                        <li>
                            are <strong>food flavourings</strong>, authorised in accordance with Regulation (EC) No 1334/2008 of the European Parliment and of the Council.
                        </li>
                    ):
                        (
                            <li>N/A</li>
                        )
                    }
                </ol>
            </>
        </>
    )
}