import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Button, Card, Badge } from "@themesberg/react-bootstrap";
import SelectionRowContent from "./SelectionRow/SelectionRowContent";
import SelectionRowCombined from "./SelectionRow/SelectionRowCombined";
import SelectionRowMenu from "./SelectionRow/SelectionRowMenu";

const ConsignmentContentsTableRow = (props) => {
  const {
    combineUniqueSelections,
    selection,
    isGEFS,
    isStorageDeclaration,
    isCompound,
    compound,
    isCombineMode,
    selections,
    combineCertificateNumber,
    isApproved,
    combineMasterId,
    combinationsMax,
    combineSelections,
    handleCombineCertificateMode,
    handleRemoveProduct,
    handleRemoveGroup,
    removeCombineGroupSelectItem,
    removeCombineSelectItem,
    handleCombineSelectItem,
    commodityCode,
    netWeightTotal,
    grossWeightTotal,
    numberOfCasesUnits,
    setFormSubmitted,
  } = props;
  const [certificateNumberList, setCertificateNumberList] = useState([]);
  const [numberOfPackages, setNumberOfPackages] = useState(0);
  const [productName, setProductName] = useState("");
  const [uniqueCode, setUniqueCode] = useState("");
  const [batchNumbers, setBatchNumbers] = useState("");
  const [dateCollectionProduction, setDateCollectionProduction] = useState([]);
  const [certificates, setCertificates] = useState([]);

  //Alert for Save Changes update
  useEffect(() => {
    if (Array.isArray(selection.certificates)) {
      var distinctSelection = [];

      for (const certificate of selection.certificates) {
        if (!distinctSelection.map((x) => x.id)?.includes(certificate.id)) {
          distinctSelection?.push(certificate);
        }
      }
      setCertificates(distinctSelection);
    } else {
      setCertificates(selection.certificates);
    }

    if (isStorageDeclaration) {
      setCertificateNumberList(
        selection.certificateNumbers?.length > 0
          ? selection.certificateNumbers?.map((x) => x).join(", ")
          : selection.certificateNumbers
      );
      setProductName(selection.productName);
      setUniqueCode(selection.uniqueCode);
      setBatchNumbers(
        selection.batchNumbers ?? selection.dailyAttestationItems?.batches
      );
      setDateCollectionProduction(null);
      setNumberOfPackages(
        numberOfCasesUnits != null
          ? numberOfCasesUnits
          : selection.numberOfPackages ??
              selection.dailyAttestationItems.numberPackages
      );
    } else {
      if (isGEFS) {
        setCertificateNumberList(
          selection.certificateNumbers?.map((x) => x).join(", ")
        );
        setProductName(selection.dailyAttestationItems.productName);
        setUniqueCode(selection.dailyAttestationItems.uniqueCode);
        setBatchNumbers(
          selection.batchNumbers ??
            selection.dailyAttestationItems.batchNumbersDisplay
        );
        setDateCollectionProduction(
          selection?.attestation?.attestationDetails?.dateCollectionProduction
        );
        setNumberOfPackages(
          numberOfCasesUnits != null
            ? numberOfCasesUnits
            : selection.dailyAttestationItems.numberOfPackages ??
                selection.numberOfPackages
        );
      } else {
        setCertificateNumberList(
          selection.certificateNumbers?.map((x) => x).join(", ")
        );
        setProductName(selection.productName);
        setUniqueCode(selection.attestation?.attestationDetails?.uniqueCode);
        setBatchNumbers(selection.attestation?.batchNumbers);
        setDateCollectionProduction(
          selection.attestation?.attestationDetails?.dateCollectionProduction
        );
        setNumberOfPackages(
          numberOfCasesUnits != null
            ? numberOfCasesUnits
            : selection.numberOfPackages ??
                selection.attestation?.attestationResponse
                  ?.attestationResponseNonGEFS?.numberProductsCases
        );
      }
    }
  }, []);

  const PrettyPrintJson = ({ data }) => (
    <div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );

  return (
    <tr
      className={
        isCombineMode &&
        ((!combineUniqueSelections?.includes(
          selection?.consignmentContentsId
        ) &&
          selection?.consignmentContentsId !== combineMasterId) ||
          selection?.combinations?.length > 0)
          ? "table-row-select-disable"
          : ""
      }
    >
      {/* //</tr><tr className={isCombineMode && (certificateNumberList != combineCertificateNumber || selection.combinations?.length > 0) ? "table-row-select-disable" : ""}>   */}

      <td colSpan={1}>
        <span className="fw-normal">
          {isStorageDeclaration ? (
            <>
              {selection.combinations?.length > 0 ? (
                <>
                  {selections
                    .filter(
                      (z) =>
                        z.combinations[0]?.consignmentContentsMasterId ==
                        selection.combinations[0]?.consignmentContentsMasterId
                    )
                    .reduce(
                      (a, v) =>
                        (a =
                          a + v.numberOfPackages ??
                          v.storgeExporterDeclaration?.numberPackages),
                      0
                    )}
                </>
              ) : (
                <>
                  {numberOfCasesUnits != null
                    ? numberOfCasesUnits
                    : selection.dailyAttestationItems?.numberPackages}
                </>
              )}
            </>
          ) : isGEFS ? (
            <>
              {selection.combinations?.length > 0 ? (
                <>
                  {selections
                    .filter(
                      (z) =>
                        z.combinations[0]?.consignmentContentsMasterId ==
                        selection.combinations[0]?.consignmentContentsMasterId
                    )
                    .reduce(
                      (a, v) =>
                        (a =
                          a + v.numberOfPackages ??
                          v.dailyAttestationItems?.numberOfPackages),
                      0
                    )}
                </>
              ) : (
                <>
                  {numberOfCasesUnits != null
                    ? numberOfCasesUnits
                    : selection.dailyAttestationItems?.numberOfPackages}
                </>
              )}
            </>
          ) : (
            <>
              {selection.combinations?.length > 0 ? (
                <>
                  {selections
                    .filter(
                      (z) =>
                        z.combinations[0]?.consignmentContentsMasterId ==
                        selection.combinations[0]?.consignmentContentsMasterId
                    )
                    .reduce(
                      (a, v) =>
                        (a =
                          a + v.numberOfPackages ??
                          v.attestation?.attestationResponse
                            ?.attestationResponseNonGEFS?.numberProductsCases),
                      0
                    )}
                </>
              ) : (
                <>
                  {numberOfCasesUnits != null
                    ? numberOfCasesUnits
                    : selection.attestation?.attestationResponse
                        ?.attestationResponseNonGEFS?.numberProductsCases}
                </>
              )}
            </>
          )}
        </span>
      </td>
      <td className="mt-4 " colSpan={5}>
        <div className="fw-normal">
          {/* <h5>certificateNumberList {JSON.stringify(certificateNumberList)}</h5>
                <h5>combineCertificateNumber {JSON.stringify(combineCertificateNumber)}</h5>
                <h5>selection.combinations?.length {JSON.stringify(selection.combinations?.length)}</h5> */}
          {selection.combinations == null ||
          selection.combinations?.length <= 0 ? (
            <SelectionRowContent
              isCombined={false}
              isStorageDeclaration={isStorageDeclaration}
              isGEFS={isGEFS}
              isCompound={isCompound}
              productName={productName}
              uniqueCode={uniqueCode}
              batchNumbers={batchNumbers}
              dateCollectionProduction={dateCollectionProduction}
              certificateNumbers={certificateNumberList}
              numberOfPackages={numberOfPackages}
              commodityCode={commodityCode}
            />
          ) : (
            selection.combinations[0]?.isPrimary && (
              <>
                <h5 className="mt-n1 zindex-fixed position-absolute pt-1">
                  <Badge
                    bg="primary"
                    className=" rounded px-4 py-3 text-dark bg-gray-300 mx-0"
                  >
                    Combined products{" "}
                    <small className="text-italic text-gray-700">
                      (maximum of five per certificate)
                    </small>
                  </Badge>
                </h5>
                <Card
                  border="gray-300"
                  className="border-3 bg-white shadow-sm mt-2 w-100"
                >
                  <Card.Body className=" px-0 mx-0">
                    <div className="mt-5 w-100">
                      <Table
                        hover
                        className="align-items-center table-flush table-ellipsis"
                      >
                        <tbody>
                          {selections
                            // .sort((a, b) => (a.combinations[0]?.isPrimary + b.combinations[0]?.isPrimary)).reverse()
                            .filter(
                              (x) =>
                                x.combinations[0]
                                  ?.consignmentContentsMasterId ==
                                selection.consignmentContentsId
                            )
                            .map((combinedSelection, i) => (
                              <>
                                {/* {JSON.stringify(combinedSelection)} */}
                                <SelectionRowCombined
                                  key={`selectionRowCombined-${i}`}
                                  className="mt-5"
                                  isGEFS={isGEFS}
                                  isStorageDeclaration={isStorageDeclaration}
                                  isCompound={isCompound}
                                  certificateNumbers={certificateNumberList}
                                  commodityCode={
                                    combinedSelection.commodityCode != null
                                      ? combinedSelection.commodityCode
                                      : null
                                  }
                                  numberOfPackages={
                                    combinedSelection.numberOfPackages != null
                                      ? combinedSelection.numberOfPackages
                                      : combinedSelection.isStorageDeclaration
                                      ? combinedSelection.dailyAttestationItems
                                          .numberPackages
                                      : combinedSelection.dailyAttestationItems
                                          .numberOfPackages
                                  }
                                  dateCollectionProduction={
                                    combinedSelection.dailyAttestationItems
                                      .dateCollectionProduction
                                  }
                                  isCombinedMaster={combinedSelection.combinations?.find(
                                    (y) => y.isPrimary == true
                                  )}
                                  isCombinedSecondary={combinedSelection.combinations?.find(
                                    (y) => y.isPrimary == false
                                  )}
                                  productName={combinedSelection.productName}
                                  uniqueCode={
                                    combinedSelection.dailyAttestationItems
                                      .uniqueCode ??
                                    combinedSelection.attestation
                                      .attestationDetails.uniqueCode
                                  }
                                  batchNumbers={
                                    combinedSelection.isStorageDeclaration
                                      ? combinedSelection.dailyAttestationItems
                                          .batches
                                      : combinedSelection.dailyAttestationItems
                                          .batchNumbers
                                  }
                                />
                              </>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              </>
            )
          )}
        </div>
      </td>
      <td colSpan={2}>
        <div className="text-truncate">
          {selection.certificateFilename !== null &&
          selection.certificateFilename !== undefined &&
          selection.certificates !== null &&
          selection.certificates !== undefined &&
          selection.certificates.length > 0 ? (
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faFileInvoice}
                className="text-success me-2 certifiate-icon "
                size="2x"
              />
              <div>
                {selection.certificates !== null &&
                  selection.certificates !== undefined && (
                    <>
                      {selection.certificates?.length > 1 ? (
                        <>
                          <label className="d-block">Compound</label>
                          <label className="d-block">
                            {selection.certificates
                              .map((x) => {
                                return x.certificateNumber;
                              })
                              .join(", ")}
                          </label>
                          <small className="d-block">
                            {selection.certificates.map((x) => {
                              return (
                                <div className="d-block">
                                  {x.certificateName}
                                </div>
                              );
                            })}
                          </small>
                          <small className="d-block">
                            {selection.certificates[0]?.certificateLanguageText}
                          </small>
                        </>
                      ) : (
                        <>
                          <label className="d-block">
                            {selection.certificates[0]?.certificateNumber}{" "}
                            certificate
                          </label>
                          <small className="d-block">
                            {selection.certificates[0]?.certificateName}
                          </small>
                          <small className="d-block">
                            {selection.certificates[0]?.certificateLanguageText}
                          </small>
                        </>
                      )}
                    </>
                  )}
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faFileInvoice}
                className="text-disabled me-2"
                size="2x"
              />
              <label>No certificate(s)</label>
            </div>
          )}
        </div>
      </td>
      <td colSpan={1}>
        <div className="d-inline ms-3">
          <SelectionRowMenu
            selections={selections}
            combinations={combineSelections.filter(
              (x) => x.consignmentContentsMasterId == selection.id
            )}
            setFormSubmitted={setFormSubmitted}
            certificateFilename={selection.certificateFilename}
            isGEFS={isGEFS}
            isStorageDeclaration={isStorageDeclaration}
            isCompound={isCompound}
            compound={compound}
            selection={selection}
            isApproved={isApproved}
            _handleCombineCertificateMode={handleCombineCertificateMode}
            removeCombineGroupSelectItem={removeCombineGroupSelectItem}
            isCombineGroup={
              selection.combinations?.filter(
                (x) => x.consignmentContentsId == selection?.id
              ).length > 0
                ? true
                : false
            }
          />
        </div>

        {selection.combinations?.length <= 0 &&
        isCombineMode &&
        (selection.dailyAttestationItems?.attestation?.certificateNumberList ==
          combineCertificateNumber ||
          selection.attestation?.certificateNumberList ==
            combineCertificateNumber ||
          selection.certificateNumbers[0] == combineCertificateNumber) ? (
          <div className="d-inline ms-3 ">
            {combineMasterId != selection.consignmentContentsId ? (
              <>
                {combineSelections.find(
                  (y) =>
                    y.consignmentContentsId == selection.consignmentContentsId
                ) ? (
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() =>
                      removeCombineSelectItem(
                        selection.consignmentContentsId,
                        combineSelections
                      )
                    }
                  >
                    Selected
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    size="sm"
                    disabled={
                      combineSelections.filter(
                        (x) => x.consignmentContentsMasterId == combineMasterId
                      ).length >= combinationsMax
                    }
                    onClick={() => handleCombineSelectItem(selection)}
                  >
                    Combine
                  </Button>
                )}
              </>
            ) : (
              <Button variant="success" size="sm" disabled={true}>
                Selected
              </Button>
            )}
          </div>
        ) : (
          !isApproved && (
            <div className="d-inline  ms-3">
              <Button
                variant="link"
                size="md"
                onClick={() => {
                  if (selection?.combinations.length > 0) {
                    selection?.combinations.map((x) => {
                      handleRemoveGroup(x.consignmentContentsMasterId);
                    });
                  } else {
                    var id = selection?.consignmentContentsId;
                    handleRemoveProduct(id);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faMinusSquare}
                  className="me-2 text-danger"
                />
              </Button>
            </div>
          )
        )}
      </td>
    </tr>
  );
};

export default ConsignmentContentsTableRow;
