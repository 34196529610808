
export const health_1_2 = [
    { group: "health_1_2", fieldname: "health_1_2", value: "1", key: "the animals from which the meat products were derived have passed ante-mortem and post-mortem inspections;" },
    { group: "health_1_2", fieldname: "health_1_2",  value: "2", key: "the wild game from which the meat products were derived have passed post-mortem inspection;" }
]

export const health_1_4 = [
    { group: "health_1_4", fieldname: "health_1_4", value: "1", key: "obtained from meat of <strong><u>domestic porcine animals</u></strong>, this meat fulfills the requirements of Commission Implementing Regulation (EU) 2015/1375, and in particular:" },
    { group: "health_1_4", fieldname: "health_1_4",  value: "2", key: "if obtained from meat of <strong><u>solipeds or wild boar</u></strong>, this meat fulfils the requirements of Implementing Regulation (EU) 2015/1375, and in particular, has been subject to an examination by a digestion method for Trichinella with negative results;" },
    { group: "health_1_4", fieldname: "health_1_4",  value: "3", key: "the treated stomachs, bladders and intestines and meat extracts have been produced in accordance with Section XIII of Annex III to Regulation (EC) No 853/2004." },
    { group: "health_1_4", fieldname: "health_1_4",  value: "4", key: "the rendered animal fats and greaves have been produced in accordance with Section XII of Annex III to Regulation (EC) No 853/2004."},    
]

export const health_1_4_1 = [
    { group: "health_1_4_1", fieldname: "health_1_4_1_1", value: "1",key: "has been subjected to an examination by a digestion method for Trichinella with negative results;" },
    { group: "health_1_4_1", fieldname: "health_1_4_1_2",  value: "2", key: "has been subjected to a freezing treatment in accordance with Annex II to Implementing Regulation (EU) 2015/1375;" },
    { group: "health_1_4_1", fieldname: "health_1_4_1_3", value: "3", key: "in the case of meat from domestic porcine animals kept solely for fattening and slaughter, comes from a holding or category of holdings that has been officially recognized by the competent authorities as free from <i>Trichinella</i> in accordance with Annex IV to Implementing Regulation (EU) 2015/1375;" }
]
export const health_1_12 = { 
     label: "If containing material from <strong><u>domestic solipeds</u></strong>, the fresh meat used in the preparation of the meat products" 
}

export const health_1_13 = { 
     label: "If containing material from <strong><u>farmed cervidae</u></strong>, the product contains or is derived exclusively from meat excluding offal and spinal cord, of farmed carvid animals which have been examined for chronic wasting disease by histopathology, immunohistochemistry or other diagnostic method recognised by the competent authorities with negative results and is not derived from animals coming from a herd where chrome wasting disease has been confirmed or is officially suspected."
}

export const health_1_14 = { 
     label: "If containing material from <strong><u>wild cervidae</u></strong>, the product contains or is derived exclusively from meat excluding offal and spinal cord, of farmed carvid animals which have been examined for chronic wasting disease by histopathology, immunohistochemistry or other diagnostic method recognised by the competent authorities with negative results and is not derived from animals coming from a region where chronic wasting disease has been confirmed in the last three years or is officially suspected."
}

export const health_1_12_A= [
    { group: "meatB11Choices", fieldname: "health_1_12_A", value: "1", key: "<p>was obtained from domestic solipeds which immediately prior to slaughter had been kept for at least six months or since birth, if slaughtered at an age of less than six months, or since importation as food producing domestic solipeds from a Member State of the European Union, if imported less than six months prior to slaughter, in a third country:</p><ol type='a'><li>in which the administration to domestic solipeds:</li><ol type='i'><li>of thyrostatic substances, stilbenes, stilbene derivatives, their salts and esters, oestradiol 17β and its ester-like derivatives is prohibited</li><li>of other substances having oestrogenic, androgenic or gestagenic action and of beta-agonists is only allowed for</li><ol type='i'><li>therapeutic treatment as defined in Article 1(2), point (b), of Council Directive 96/22/ECL, where applied in conformity with Article 4(2) of that Directive, or</li><li>zootechnical treatment as defined in Article 1(2), point (c), of Directive 96/22/EC, where applied in conformity with Article 5 of that Directive; and</li></ol><li>which has had, at least during the six months prior to slaughter of the animals, a plan for the monitoring of the groups of residues and substances referred to in Annex I to Directive 96/23/EC which covers domestic solipeds born in and imported into the third country and was approved in accordance with Article 29(1), fourth subparagraph, of Directive 96/23/EC.</li></ol></ol>" },
    { group: "meatB11Choices", fieldname: "health_1_12_A",  value: "2", key: "was imported from a Member State of the European Union." }
]

export const animal_2_1_a = [
    { group: "II2Choice", fieldname: "animal_2_1_a", value: 1, key: "Part 1 of Annex XIII to Commission Implementing Regulation (EU) 2021/404, in case of fresh meat of ungulates" },
    { group: "II2Choice", fieldname: "animal_2_1_a",  value: 2, key: "Part 1 of Annex XIV to Commission Implementing Regulation (EU) 2021/404, in case of fresh meat of poultry and game birds" }
]

export const BseRiskCategoryNegligibleStatementsChoice = [
    {group: "", fieldname: "BseNegligible1", value: "1", key: "the animals from which the meat products are derived were born, continuously reared and slaughtered in a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>negligible BSE risk</u> in which there have been no BSE indigenous cases" },
    {group: "", fieldname: "BseNegligible2", value: "2", key: "the animals from which the meat products are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>negligible BSE risk</u> in which there has been at least one BSE indigenous case, and the meat products do not contain and are not derived from mechanically separated meat obtained from bones of bovine, ovine and caprine animals" },
    {group: "", fieldname: "BseNegligible3", value: "3", key: "the animals from which the meat products are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>controlled BSE risk</u> and <ol className='list-clauses'><li>the meat products do not contain and are not derived from specified risk material as defined in point 1 of Annex V to Regulation(EC) No 999 / 2001 of the European Parliament and of the Council</li><li>the meat products do not contain and are not derived from mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li><li>the animals from which the meat products are derived were not slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod - shaped instrument introduced into the cranial cavity</li></ol>" },
    {group: "", fieldname: "BseNegligible4", value: "4", key: "the animals from which the meat products are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing an <u>undetermined BSE risk</u> and: <ol className='list-clauses'><li>the meat products do not contain and are not derived from specified risk material as defined in point 1 of Annex V to Regulation(EC) No 999 / 2001 </li> <li>the meat products do not contain and are not derived from mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li> <li>the animals from which the meat products are derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod - shaped instrument introduced into the cranial cavity</li> <li>the animals from which the meat products are derived have not been fed with meat - and - bone meal or greaves, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health</li> <li>the meat products were produced and handled in a manner which ensures that they do not contain and were not contaminated with nervous and lymphatic tissues exposed during the deboning process</li></ol>" },
]
export const BseRiskCategoryControlledStatementsChoice = [
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: "1", disabled: "disabled", key: "the animals from which the meat products are derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod- shaped instrument introduced into the cranial cavity;" },
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: "2", disabled: "disabled", key: "the meat products do not contain and are not derived from<ol className='list-clauses'><li>specified risk material as defined in point 1 of Annex V to Regulation(EC) No 999/2001;</li><li>mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li>" },
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: "3", key: "the animals from which the meat products are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a negligible or a controlled risk" },
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: "4", key: "the animals from which the meat products are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing an undetermined BSE risk and<ol className='list-clauses'><li>the animals from which the meat products are derived have not been fed with meat-and-bone meals or greaves, as defined in the Terrestrial Animal Health Code of World Organisation for Animal Health</li><li>the meat products were produced and handled in a manner which ensures that they do not contain and were not contaminated with nervous and lymphatic tissues exposed during the deboning process</li></ol>"}
]
export const BseRiskCategoryUndeterminedStatementsChoice = [
    {group: "", fieldname: "BseUndetermined",  value: 1, disabled:"disabled", key: "the animals from which the meat products are derived have not been <ol className= 'list-clauses'><li>slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rodshaped instrument introduced into the cranial cavity;</li><li>fed meat-and-bone meal or greaves derived from ruminants, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health</li></ol>"},
    {group: "", fieldname: "BseUndetermined",  value: 2,  disabled:"disabled", key: "the meat products do not contain and are not derived from<ol className='list-clauses'><li>specified risk material as defined in point 1 of Annex V to Regulation (EC) No 999/2001</li><li>mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li><li>nervous and lymphatic tissues exposed during the deboning process</li>" },
]
export const animal_2_4 = [
    { group: "animal_2_4", fieldname: "animal_2_4", value: "1", key: "the zone referred to (in previous question)" },
    { group: "animal_2_4", fieldname: "animal_2_4",  value: "2", key: "the zone with codes (selected below) which, currently is/are authorised for the entry into the Union of fresh meat of the species from which the meat product has been processed and listed in" },
    { group: "animal_2_4", fieldname: "animal_2_4",  value: "3", key: "a Member State" }
]
export const animal_2_4_2 = [
    { group: "animal_2_4_2", fieldname: "animal_2_4_2", value: "1", key: "Part 1 of Annex XIII to Commission Implementing Regulation (EU) 2021/404" },
    { group: "animal_2_4_2", fieldname: "animal_2_4_2",  value: "2", key: "Part 1 of Annex XIV to Commission Implementing Regulation (EU) 2021/404" }
]
export const animal_2_5 = [
    { group: "animal_2_5", fieldname: "animal_2_5", value: "1", key: "animals kept in an establishment that was not subject to national restriction measures for animal health reasons, including the listed diseases referred to in Annex I to Delegated Regulation (EU) 2020/692 relevant for the species of origin of the meat products and emerging diseases at the date of dispatch of the animals to the slaughterhouse and in and around the establishment, in an area of 10 km radius, including where appropriate the territory of a neighbouring country, such diseases have not been reported during the last 30 days prior to the date of slaughter of the animals" },
    { group: "animal_2_5", fieldname: "animal_2_5", value: "2", key: "wild animals which originate from a place in and round which none of the listed diseases relevant for the species of origin of the meat products in accordance with Annex I to Delegated Regulation (EU) 2020/692, has been reported during the last 30 days prior to the date of killing of the animals"}
]
export const animal_2_7 = {
    label: 'is intended for a Member State which has been granted the status free from infection with Newcastle disease virus without vaccination in accordance with Commission Delegated Regulation (EU) 2020/689, and has been obtained from poultry which have not been vaccinated against infection with Newcastle disease virus with a live vaccine during the period of 30 days prior to the date of slaughter'
}