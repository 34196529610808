
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from 'react';
import SiteCertificateNumbersService from "../../../services/SiteCertificateNumbersService";

const UnpermittedCertificateNumbersAlert = (props) => {
    const {loaded, isEnabled = false, setCompleted, titleText, siteId, productCertificateNumbers, siteName, setUnpermittedCertificateNumbers, unpermittedCertificateNumbers } = props;
    const [isCheckComplete, setCheckComplete] = useState(false);
    const [enableMessage, setEnableMessage] = useState(false);
    
    useEffect (() => {
        if (loaded && isEnabled === true && isCheckComplete === false && productCertificateNumbers?.length > 0 && siteId !==undefined && siteId !== null && siteId !=='') {
            setCheckComplete(true);
            let params = {};
            params["siteId"] = siteId;
            params["certificateNumbers"] = productCertificateNumbers;
            SiteCertificateNumbersService.checkSiteAllowsCertificateNumbers(params).then(response => {
                var responseData =  response.data;
                if(responseData !== null){
                    if(!responseData.matchesAll){
                        setEnableMessage(true);
                       
                        setUnpermittedCertificateNumbers(responseData.unMatchedCertificateNumbers);
                    }
                }
                setCompleted(true);
            });
        }
    }, [productCertificateNumbers, siteId]);

    useEffect(() => {
        if(isEnabled == false){
            setCompleted(true);
        }
    }, [isEnabled]);

    return (
      <div>
        {enableMessage === true && isEnabled &&
            <Alert
                className="mt-2"
                variant="info"
                size="sm"
            >
                <div className="d-flex justify-content-start">
                    <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                    <span>
                        {titleText != null && <strong>{titleText}<br /></strong>} 
                        The current selected certificate number 
                        ({unpermittedCertificateNumbers?.length > 0 && unpermittedCertificateNumbers.join(",")}) 
                        does not match what is permitted for {siteName}. 
                        Select a different certificate number, or contact us if this site 
                        should be permitted to use this certificate number.
                    </span>
                </div>
            </Alert>
        }
      </div>
    );
};

export default UnpermittedCertificateNumbersAlert;