import React, { useEffect, useState, useRef } from 'react';
import PlatformDataService from "../../services/PlatformService";
import moment from "moment";
import { Card, Button, Col, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit} from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../../routes";
import BreadcrumbNav from "../../../components/BreadcrumbNav";

const PlatformViewPage  = (props) => {
    
    const [platformId] = useState(props.match.params.id);
    const [platform, setPlatform] = useState([]);

    async function populatePlatformData() {
        PlatformDataService.getById(platformId)
        .then(response => {
            setPlatform(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    }

    const openPlatform = () => {
        props.history.push("/platform/edit/" + platformId);
    };

    useEffect(() => {
        populatePlatformData();
    },[]);
    
    return (
        <div>
            <BreadcrumbNav 
                data={{
                    item1 : {name:'Platforms', link: Routes.Platforms.path},
                    item2 : {name: "Viewing " + platform.platformName}}
                }
            />
            <Row>
                <Col >
                    <h2 id="tabelLabel" >{platform.platformName}</h2>
                </Col>
            </Row>
            <Row className="">
                <Col md={8} xl={8}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col>
                                    <h4 className="mb-0">Platform Details</h4>
                                </Col>
                                <Col className="text-end" md={2}>
                                    <Button onClick={openPlatform} variant="light" size="sm"> 
                                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                                        <span>Edit</span>
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <ul className="list-unstyled">
                                <li>
                                    <p>
                                        <small className="d-block">Platform Name:</small>
                                        <label>{platform.platformName!==null && platform.platformName!==""?(platform.platformName):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Address Line 1:</small>
                                        <label>{platform.address1!==null && platform.address1!==""? (platform.address1):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Address Line 2:</small>
                                        <label>{platform.address2!==null && platform.address2!==""?(platform.address2):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Town / City:</small>
                                        <label>{platform.townCity!==null && platform.townCity!==""?(platform.townCity):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Postcode:</small>
                                        <label>{platform.postcode!==null && platform.postcode!==""?(platform.postcode):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">{platform.establishmentNumberTypeName}</small>
                                        <label>{platform.establishmentNumber!==null && platform.establishmentNumber!==""?(platform.establishmentNumber):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="text-gray-600 text-italic">Created on {moment(platform.createdDate).format("Do MMM YYYY")}</small>
                                    </p>
                                </li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <div className="btn-panel left">
                        <Button variant="light" href="/platforms">
                            Back to Platforms
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )

}
export default PlatformViewPage;