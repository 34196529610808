import React, { useState } from 'react';
import { Button, Col, Row, Modal } from "@themesberg/react-bootstrap";
import ReactJson from 'react-json-view'

const ViewDataModal =(props) =>{

    const { data, className, handleCloseModalViewData, showModalViewData} = props;
    const [selectedDataPath, setSelectedDataPath] = useState(null);

    const handleSelect = (namespace, name) => {
        
        var  namespaces = namespace.toString().split(',');
        var dataPath = `$.${namespaces[0]}[${namespaces[1]}]`
        namespaces.forEach((x, i)=>{
            if(i>=2){
                dataPath = dataPath + "." + x
            }
        });
        dataPath = dataPath +"."+ name
        setSelectedDataPath(dataPath);
    }
    
    const PrettyPrintJson = ({data}) => (<div><pre>{
        JSON.stringify(data, null, 2) }</pre></div>
    );

    return(
        <div className={className + " " + (showModalViewData? "d-block" : "d-none")}>
                    
    
        {/* //Modal - Start*/}
        <Modal size="xl" as={Modal.Dialog} centered show={showModalViewData} onHide={()=> handleCloseModalViewData()}>
            <Modal.Header>
                <Modal.Title className="h5">View Data</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={()=> handleCloseModalViewData()} />
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3 justify-content-md-center">
                    <Col md={12}>
                        <div className="my-3 h5">
                            <label>Selected path:&nbsp;</label> 
                            {selectedDataPath !==null &&
                                <>
                                    {selectedDataPath}
                                </>
                            }
                        </div>
                        
                        <ReactJson 
                            src={data}
                            collapsed={true}    
                            onSelect={e => {handleSelect(e.namespace, e.name)}} 
                        />
                        {/* <PrettyPrintJson data={data}/> */}
                    </Col>
                </Row>

            </Modal.Body>
        </Modal>
        {/* //Modal - End */}
    </div>                            
    );
}

export default ViewDataModal;