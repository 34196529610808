import React, { useRef, useState } from "react";
import { Routes } from "../../../routes";
import ManufacturerDataService from "../../services/ManufacturerService";
// import authService from './api-authorization/AuthorizeService'
import { faBoxOpen, faFileCsv, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Dropdown, Row } from '@themesberg/react-bootstrap';
import BreadcrumbNav from "../../../components/BreadcrumbNav";
// import { ManufacturerListTable } from "../../../components/Tables/ManufacturerTable";
import { ManufacturersTable } from "../../../components/Tables/ManufacturerTable";
import FilterBar from "../../filters/FilterBar";
import Paging from "../common/Paging";
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes } from '../../../auth/PermissionsMap';

const ManufacturerListingPage = (props) => {
    const [manufacturers, setManufacturers] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [ready] = useState(true);
    const [products, setProducts] = useState([]);
    const [count, setCount] = useState(0);
    const [searchTitle, setSearchTitle] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const { totalPages = count, size = "md", withIcons = false, disablePrev = false } = props;
    const manufacturerRef = useRef();
    const defaultFilterValues = null;
    const orderByOptions = ["Last updated", "A-Z", "Z-A"]

    const breadcrumbData = {
        item1 : {name:'Manufacturers', link: Routes.ManufacturersListingPage.path},
        item2 : {name:'View all', link: 'View all'},
    };
    const pageSizes = [
        { value: '10', label: 10 },
        { value: '25', label: 25 },
        { value: '100', label: 100 }
    ]
    const initialValues = {
        search: ''
    }

    manufacturerRef.current = products;

    //Start - Paging Functions
    const onPrevItem = () => {
        const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
        setActivePage(prevActiveItem);
      };

      const onPageNumberClick = (number) => {
        setActivePage(number);
    };
    const onNextItem = (totalPages) => {
      const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
      setActivePage(nextActiveItem);
    };
    const handlePageSizeChange = (value) => {
        setPageSize(value.value);
    };
    //End - Paging Functions

    
    const getManufacturers = (params) => {
        setLoaded(false);
        ManufacturerDataService.getAllPaged(params)
            .then((response) => {
                const { data, totalPages, totalRecords} = response.data;
                setTotalItems(totalRecords);
                setManufacturers(data);
                setCount(totalPages);
                setLoaded(true);
            })
            .catch((e) => {
                setLoaded(true);
                console.log(e);
            });
    };

    const openManufacturer = (rowIndex) => {
        const id = manufacturerRef.current[rowIndex].id;
        props.history.push("/manufacturers/" + id);
    };

    

    //Submit
    const handleFilterSubmit= (params) => {
        setSubmitting(true);
        getManufacturers(params)
        setSubmitting(false);
    }

    const handleFilterChange = (values) => {
        setSubmitting(true);
        setSubmitting(false);
    }
    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            
            <Row className='row d-flex align-items-center'>
                <Col xs={12} sm={6} lg={6} className="text-start">
                    <h2 id="tabelLabel" >Manufacturers</h2>
                </Col>
                <Col xs={12} sm={6} lg={6} className="text-end">
                    <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.manufacturers.create]}>
                        <Dropdown>
                            <Dropdown.Toggle as={Button} variant="secondary" size={"sm"} className="text-dark">
                                <FontAwesomeIcon icon={faPlus} className=" me-2"/>
                                <span>Add</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
                                <Dropdown.Item href={Routes.ManufacturerAddPage.path}>
                                <FontAwesomeIcon icon={faBoxOpen} className="me-2" /> Single Manufacturer
                                </Dropdown.Item>
                                <Dropdown.Item href={Routes.FileUpload.path}>
                                <FontAwesomeIcon icon={faFileCsv} className="me-2" /> Upload
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </PermissionsCheck>
                </Col>
            </Row>
            <div className="col-md-12 list">
                <Row className="mt-1">
                    <Col>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <FilterBar
                                            setLoaded={setLoaded} 
                                            loaded={loaded} 
                                            ready={ready} 
                                            hasSearch={true}
                                            activePage={activePage}
                                            pageSize={pageSize}
                                            orderByOptions={orderByOptions}
                                            setActivePage={setActivePage}
                                            handleChange={handleFilterChange}
                                            handleSubmit={handleFilterSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <ManufacturersTable loaded={loaded} manufacturers={manufacturers} />
                                <Paging
                                        activePage={activePage}
                                        col={size}
                                        pageSize={pageSize}
                                        totalPages={totalPages}
                                        totalItems={totalItems}
                                        handleChange={handlePageSizeChange}
                                        onPrevItem={onPrevItem}
                                        onNextItem={onNextItem}
                                        onPageNumberClick={onPageNumberClick}
                                        withIcons={withIcons}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default ManufacturerListingPage;