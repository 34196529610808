import { faUserPlus, faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Modal, Row, Card } from '@themesberg/react-bootstrap';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo, getUserRoles, getUserTenantMemberships, isInUserRole } from "../auth/authorisation";
import FormController from '../pages/components/common/FormController';
import OrganisationService from '../pages/services/OrganisationService';
import * as yup from 'yup';
import InvitationService from '../pages/services/InvitationService';
import ManufacturerService from '../pages/services/ManufacturerService';
import PermissionsCheck, {isPermitted} from '../auth/PermissionCheck';
import { scopes } from '../auth/PermissionsMap';
export default () => {

    const [tenants, setTenants] = useState({ set: false, items: [] });
    const [roles, setRoles] = useState([]);
    const [isManufacturer, setIsManufacturer] = useState(false);
    const [organisation, setOrganisation] = useState('');
    const [isManufacturerAdmin, setIsManufacturerAdmin] = useState(false);
    const [userInfo, setUserInfo] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const handleCloseModalInvite = () => setShowModalInvite(false);
    const [showModalInvite, setShowModalInvite] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [sentEmail, setSentEmail] = useState(false);
    const [error, setError] = useState(null);
    const [selectable, setSelectable] = useState(false);
    const [sentSuccess, setSentSuccess] = useState(false);
    const [manufacturerId, setManufacturerId] = useState(false);
    const FoodBg = useSelector((state) => state.foodBg);
    const initialValues = {
      selectable: selectable,
      inviteEmail: '', 
      role: '', 
      itemId: '',
      selectedManufacturerId: [],
      defaultManufacturerId: manufacturerId ?? '',
    }

    //Get all permitted manufacturers
    async function getManufacturerData(){
      ManufacturerService.getAll()
        .then((response) => {
            const options = response.data.map(d => ({
                "value": d.id,
                "label": d.manufacturerName
            })).sort((a, b) => a.label.localeCompare(b.label));
            setManufacturers(options);
        })
        .catch((e) => {
            console.log(e);
        });
    }

    //Close invite
    function closeInvite() {
      setSentEmail(false);
      setSubmitted(false);
      setError(null);
      setShowModalInvite(false);
    }
  
    useEffect(() => {
      //Get all manufacturers for multiselect
      getManufacturerData();

      //Get organisation data
      OrganisationService.getFirst()
        .then(res => {
          setOrganisation(res.data);
      });
        
      //User role checks
      isInUserRole("manufacturer")
      .then(x => {
        setIsManufacturer(x);
      });
      isInUserRole("manufactureradmin")
      .then(x => {
        setIsManufacturerAdmin(x);
      });

      //All invite type buttons
      const radioButtonOptions = [ 
        { icon: faUserPlus, permission: scopes.invite.retailers, value: 'Retailer', title: 'Retailer', description: 'Users who can add and manage products, manufacturers, platforms and certifiers' },
        { icon: faUserPlus, permission: scopes.invite.vets, value: 'Vet', title: 'Vet', description: 'Users who can sign respond to and sign support attestations' },
        { icon: faUserPlus, permission: scopes.invite.manufactureradmins, value: 'ManufacturerAdmin', title: 'Manufacturer Admin', description: 'Users who can provide product detail and prepare certificate information' },
        { icon: faUserPlus, permission: scopes.invite.manufacturers, value: 'Manufacturer', title: 'Manufacturer Signer', description: 'Users who can sign attestations, provide product detail and certificate information' },
        { icon: faUserPlus, permission: scopes.invite.certifyingofficer, value: 'CertifyingOfficer', title: 'Certifying officer', description: 'Users who can sign attestations, create consignments and sign export health certificates' },
        { icon: faUserPlus, permission: scopes.invite.platformcertifiers, value: 'PlatformCertifier', title: 'Platform certifier', description: 'Users who can view supplier declarations and create exporter storage declarations' }
      ];

      //Filter invite types for only those in permitted scopes
      const filteredOptions = radioButtonOptions.filter(option => {
        return isPermitted([option.permission]);
      });
      setRoleOptions(filteredOptions);

      //Setup intercom
      getUserInfo()
        .then(x => {
          setUserInfo(x);
          const intercomConfig = {
            app_id: 'r1yczguo',
            name: x.fullName,
            email: x.email
          };
        window.Intercom('boot', intercomConfig);
      });
    }, []);

    //Manufacturer id for user checks
    useEffect(() => {
      if(userInfo !=null){
        //Set manufacturer id if user is a manufacturer or manufacturer admin
        var manufacturerId= (isManufacturer || isManufacturerAdmin) ? 
          userInfo.manufacturerId 
          : null;
        setManufacturerId(manufacturerId)

        //Enable multiselect if no manufacturer id is found or a single manufacturer id is found
        if(manufacturerId?.length > 0 || manufacturerId == undefined || manufacturerId == null || manufacturerId == ""){
          setSelectable(true);
      }
    }
    }, [isManufacturer, isManufacturerAdmin, userInfo]);

    return (

      // User profile
      <div className="card shadow border-0 text-center p-0">
      <div className="profile-cover rounded-top" style={{backgroundImage: `url(${FoodBg})`}}></div>
      <div className="card-body">
        <img src={organisation.logoUrl} class="user-avatar large-avatar rounded-circle mx-auto mt-n6 mb-3 shadow " alt="Retailer"/>
        
        <div className="fw-normal fs-7 mb-2" >{organisation.name} ({organisation.membershipNumber})</div>
        <div className="fw-bold fs-5">{userInfo.fullName}</div>
        <div className="fw-normal fs-6">{userInfo.jobTitle}</div>
        {/* <div className="fw-normal fs-7 text-gray">{roles}</div> */}
      </div> 
      <div className="card-footer pt-0">
        <div className=' m-2'>
          <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "manufacturer",'manufactureradmin']}>
            <Button type="submit" variant="outline-dark" size="sm" className="mx-2 px-3 py-2" onClick={() => setShowModalInvite(true)}> 
                <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                <span>Invite a user</span>
            </Button>
          </PermissionsCheck>
              
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={yup.object({
            selectedManufacturerId: yup.object().nullable()
                .when("selected",{
                is: true,
                then: yup.object().nullable().required("Must choose a manufacturer")
            }),
            inviteEmail: yup.string().email("Invalid email address").required("Must enter an email address "),
        })}
        //On submit
        onSubmit={(values, { resetForm }) => {
            setSubmitting(true);
            setTimeout(() => {
                let submitData = values;

                //ItemId is the selected manufacturer id
                if(selectable){
                  submitData["itemId"] = values.selectedManufacturerId.value;
                }
                else{
                  //Users  manufacturer id / blank
                  submitData["itemId"] =  values.defaultManufacturerId;
                }

                //Send invite
                InvitationService.invite(submitData)
                .then(response => {
                  setSentSuccess(true);
                  setSentEmail(submitData.inviteEmail);
                    console.log(response.data);
                })
                .catch(e => {
                  setSentSuccess(false);
                  setSentEmail(submitData.inviteEmail);
                  setError("Sorry there was a problem");
                  resetForm({});
                    console.log(e.data);
                    console.log(e);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }, 1000);
      }}
      >
      {(formikProps) => (
          <Modal size="lg" as={Modal.Dialog} centered show={showModalInvite} onHide={() => closeInvite(formikProps)}>
          <Modal.Header>
              <Modal.Title className="h3 fw-bold text-title mx-3 mt-2">Invite a user
                <p className="mb-0 text-italic text-gray">Add a new user or upgrade permissions of an existing user</p>
              </Modal.Title>
              <Button variant="close" aria-label="Close" className="mt-n5" onClick={() => closeInvite(formikProps)} />
          </Modal.Header>
          <Modal.Body className="m-3 mt-0">
            <Form>
              <Row className="">
                <Col md={12}>
                  {/* Submission */}
                  {sentEmail &&
                    <Row className="">
                      <Col lg={12}>
                        <Card border="light" className="bg-white shadow-sm mb-4 p-3">
                          <Card.Body>
                            <div className="text-center">
                              
                              {sentSuccess == false && error !== null?(
                                <div className="p-5">
                                  <h2>
                                    <i className="vtdcomplete">
                                      <FontAwesomeIcon icon={faExclamationTriangle} className="mx-2 text-danger" />
                                    </i>
                                    Sorry, there was a problem
                                  </h2>
                                  <p>The invite has not been sent.</p>
                                </div>
                              )
                              :
                              (
                                <div className="p-5">
                                  <h2>
                                    <i className="vtdcomplete">
                                      <FontAwesomeIcon icon={faCheckCircle} className="mx-2 text-success" />
                                    </i>
                                      Invite Sent
                                  </h2>
                                  <br />
                                  <p>An invite has been successfully sent to {formikProps.values.inviteEmail}</p>

                                </div>
                              )}

                              <Button variant="outline-dark" size="md" className="mx-2 " onClick={() => closeInvite(formikProps)}>
                                <span>Return to dashboard</span>
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  }
                  {/* Main form */}
                  {!sentEmail &&
                  <>
                    <label className="mb-0">Type of permission to assign to this user:</label>
                    <Row>
                      <Col>
                        <FormController
                          control="radioButtonCards"
                          options={roleOptions}
                          name="role"
                          disabled={submitting}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormController
                          control="input"
                          label="Email address"
                          name="inviteEmail"
                          disabled={submitting}
                          col={6}
                        />
                      </Col>
                    </Row>
                    <Row className={selectable === true && manufacturers.length > 0 && formikProps.values.role === 'ManufacturerAdmin' || selectable === true && formikProps.values.role === 'Manufacturer' ? "" : "d-none"}>
                      <Col className="mb-2">
                        <FormController
                            id="selectedManufacturerId"
                            name="selectedManufacturerId"
                            placeholder="Select a manufacturer"
                            label="Manufacturer to assign this user to:"
                            control="multiselect"
                            options={manufacturers}
                            isMulti={false}
                            isClearable={true}
                            onBlur={formikProps.setFieldTouched}
                            onChange={formikProps.setFieldValue}
                            touched={formikProps.touched.selectedManufacturerId}
                            errors={formikProps.errors.selectedManufacturerId}
                            isrequired="true"
                            disabled={submitting}
                            col={12}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="form-group m-3 text-center">
                          <Button variant="primary" type="submit" className="d-inline-block btn-panel" disabled={submitting}>
                              {submitting ? "Please wait..." : "Invite user"}
                          </Button>
                        </div> 
                    </Col>
                    </Row>
                  </>
                  }
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      </Formik>
    </div>
    );
  };