import * as Dairy8468Labels from "../Dairy8468Labels"
import { findArrayElementByFieldName, findArrayElementByFieldNameAndValue } from "../../../Attestations/Common/AttestationFunctions";
import React from 'react';

export const AttestationContent = (props) => {
    const { attestationData } = props;

    const speciesLabels = [];
    for (let i = 1; i <= 5; i++) {
        const typeKey = `dairySpecies${i}`;
        if (attestationData[typeKey]) {
            speciesLabels.push(findArrayElementByFieldName(Dairy8468Labels.dairySpecies, typeKey));
        }
    }
    const species = speciesLabels.join(", ");

    const rawMilkLabels = [];
    for (let i = 1; i <= 5; i++) {
        const typeKey = `dairyCMilkType${i}`;
        if (attestationData[typeKey]) {
            rawMilkLabels.push(findArrayElementByFieldName(Dairy8468Labels.dairyCMilkChoices, typeKey));
        }
    }
    const rawMilkSpecies = rawMilkLabels.join(", ");

    const dairyLabels = [];
    for (let i = 1; i <= 5; i++) {
        const typeKey = `dairyC2MilkType${i}`;
        if (attestationData[typeKey]) {
            dairyLabels.push(findArrayElementByFieldName(Dairy8468Labels.dairyCMilkChoices2, typeKey));
        }
    }
    const dairySpecies = dairyLabels.join(", ");

    return (
        <>
            <h5>II. Health Attestation</h5>
            <>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned, declare that I am aware of the relevant requirements of Regulation (EC) No 178/2002 of the European Parliament and of the Council,
                    Regulation (EC) No 852/2004 of the European Parliament and of the Council, Regulation (EC) No 853/2004 of the European Parliament and of the Council and
                    Regulation (EU) 2017/625 of the European Parliament and of the Council and Commission Implementing Regulation (EU) 2019/627 and hereby certify that the
                    dairy product described in Section A.2 was produced in accordance with these requirements, in particular that:
                </p>
                <ol type='a'>
                    <li>it was produced from raw milk:
                        <ol type="i">
                            <li>which comes from holdings registered in accordance with Regulation (EC) No 852/2004 and checked in accordance with Articles 49 and 50 of Implementing Regulation (EU) 2019/627;</li>
                            <li>which was produced, collected, cooled, stored and transported in accordance with the hygiene conditions laid down in Chapter I of Section IX of Annex III to Regulation (EC) No 853/2004;</li>
                            <li>which meets the plate and somatic cell count criteria laid down in Chapter I of Section IX of Annex III to Regulation (EC) No 853/2004;</li>
                            <li>which complies with the guarantees covering live animals and products thereof provided by the control plan submitted in accordance with Article 6(2) of Commission Delegated Regulation (EU) 2022/2292 and the third country or region thereof of its origin is listed in Annex -I to Commission Implementing Regulation (EU) 2021/405 with an entry ‘X’ for milk;</li>
                            <li>which, pursuant to testing for antibiotic residues carried out by the food business operator in accordance with the requirements of Annex III, Section IX, Chapter I, Part III, point 4 of Regulation (EC) No 853/2004, complies with the maximum residue limits for residues of antibacterial veterinary medicinal products laid down in the Annex to Commission Regulation (EU) No 37/2010;</li>
                            <li>has not been obtained from animals showing a positive reaction to the test for tuberculosis or brucellosis;</li>
                        </ol>
                    </li>
                    <li>
                        it comes from (an) establishment(s) applying general hygiene requirements and implementing a programme based on the hazard analysis and
                        critical control points (HACCP) principles in accordance with Article 5 of Regulation (EC) No 852/2004, regularly audited by the competent
                        authorities, and being listed as an EU approved establishment</li>
                    <li>it has been processed, stored, wrapped, packaged and transported in accordance with the relevant hygiene conditions laid down in Annex II to
                        Regulation (EC) No 852/2004 and Chapter II of Section IX of Annex III to Regulation (EC) No 853/2004;</li>
                    <li>it meets the relevant criteria laid down in Chapter II of Section IX of Annex III to Regulation (EC) No 853/2004 and the relevant microbiological
                        criteria laid down in Commission Regulation (EC) No 2073/2005;
                    </li>
                    <li>
                        it has undergone or been produced from raw milk which has been submitted to a treatment involving a single heat treatment with a heating effect at
                        least equivalent to that achieved by a pasteurization process of at least 72°C for 15 seconds and, where applicable, sufficient to ensure a negative
                        reaction to an alkaline phosphatase test immediately after the heat treatment;
                    </li>
                    <li>
                        it has been produced under conditions guaranteeing compliance with the maximum residue levels for pesticides laid down in Regulation (EC) No
                        396/2005, and the maximum levels for contaminants laid down in Regulation (EC) No 1881/2006.
                    </li>
                </ol>
            </>
            <h6>II.2 Animal Health Attestation</h6>
            <ul className="list-unstyled ps-3">
                <li>the <strong>dairy products</strong> described in Section A.2:
                    <ol className="attestation-II-2">
                        <li>
                            originate from the zone(s) with code: <strong><u>{attestationData.dairyACountryOrigin?.map(x => x.isoCode).join(", ")}</u></strong> which, is authorised for the entry into
                            the Union of milk and listed in Part I of Annex XVII to Commission Implementing Regulation (EU) 2021/404, and in which foot and mouth
                            disease and infection with rinderpest virus have not been reported for the last 12 months prior to the date of milking, and vaccination against these diseases has not been carried out during that period;
                        </li>
                        <li>have been processed from: </li>
                        <ol className="attestation-II-2-2-1">
                            {attestationData.dairyP1 &&
                                <li>
                                    <strong>raw milk</strong> originating from:


                                    <ul className="unstyled-list">
                                        {attestationData.dairyB_1 &&
                                            <li>the zone(s) referred to in point II.2.1. and obtained from <strong>animals</strong> of the species&nbsp;
                                                {rawMilkSpecies}
                                                &nbsp;that:
                                                <ol type="a">
                                                    <li key="1">

                                                        <ul className="ml-3">
                                                            {attestationData.dairyCStatements?.map(value =>
                                                                <li>
                                                                    {findArrayElementByFieldNameAndValue(Dairy8468Labels.dairyCStatementsChoice, 'dairyCStatements', value)}

                                                                    {attestationData.dairyC2Statements != null && value === "2" &&
                                                                        <ul>
                                                                            {attestationData.dairyC2Statements.map(value =>
                                                                                <li>
                                                                                    {findArrayElementByFieldNameAndValue(Dairy8468Labels.dairyC2StatementsChoice, 'dairyC2Statements', value)}
                                                                                </li>
                                                                            )}
                                                                        </ul>
                                                                    }
                                                                </li>
                                                            )}
                                                        </ul>

                                                    </li>
                                                    <li key="2">
                                                        have been kept in <strong>establishments</strong>:
                                                        <ol type="i">
                                                            <li>registered by and under the control of the competent authority of the third country or territory and have a system in place to maintain and to keep records in accordance with Article 8 of Commission Delegated Regulation (EU) 2020/692</li>
                                                            <li>which receive regular animal health visits from a veterinarian for the purpose of the detection of, and information on, signs indicative of the occurrence of diseases, including the relevant listed diseases referred to in Annex I to Commission Delegated Regulation (EU) 2020/692 and emerging diseases</li>
                                                            {/* <li>which were not subject to national restriction measures for animal health reasons, including the relevant listed diseases referred to in Annex I to Delegated Regulation (EU) 2020/692 and emerging diseases, at the time of milking</li> */}
                                                        </ol>
                                                    </li>
                                                </ol>
                                            </li>
                                        }
                                        {attestationData.dairyB_2 &&
                                            <li>in the zone(s) with code(s): <strong><u>{attestationData.dairyBCountryOrigin?.map(x => x.isoCode).join(", ")}</u></strong> which, at the date of issue of this animal health/official certificate is/are listed in Part 1 of Annex XVII to
                                                Implementing Regulation (EU) 2021/404 for the entry into the Union of milk, and that raw milk complied with all the relevant requirements for the
                                                entry into the Union of raw milk laid down in Commission Delegated Regulation (EU) 2020/692 and, therefore, was eligible for the entry into the
                                                Union as such upon arrival in those zone(s) referred to under point II.2.1.
                                            </li>
                                        }
                                        {attestationData.dairyB_3 &&
                                            <li>in a Member State.</li>
                                        }
                                    </ul>
                                </li>
                            }
                            {attestationData.dairyP2 &&
                                <ol className="attestation-II-2-2-1" style={{ paddingLeft: '0px' }}>
                                    <li><strong>dairy products:</strong>
                                        <ol type="a">
                                            <li>produced in:</li>

                                            <ul className="unstyled-list">
                                                {attestationData.dairyProduct1 &&
                                                    <li>the zone(s) referred to in point II.2.1. (above).</li>
                                                }
                                                {attestationData.dairyProduct2 &&
                                                    <li>
                                                        the zone(s) with code(s): <strong><u>{attestationData.dairyProductCountryOrigin?.map(x => x.isoCode).join(", ")}</u></strong> which, at the date of issue of this animal health/official certificate is/are authorised for the entry into the Union of milk and listed in Part 1 of Annex XVII to Implementing Regulation (EU) 2021/404, and that raw milk complied with all the relevant requirements for the entry into the Union of raw milk laid down in Delegated Regulation (EU) 2020/692 and, therefore, was eligible for the entry into the Union as such upon arrival in the zone referred to under point II.2.1.
                                                    </li>
                                                }
                                                {attestationData.dairyProduct3 &&
                                                    <li>in a Member State.</li>
                                                }
                                            </ul>
                                            <li> obtained <strong>from raw milk</strong> originating from:    </li>

                                            <ul className="unstyled-list">
                                                {attestationData.dairyB2_1 &&
                                                    <li>
                                                        the zone(s) referred to in point II.2.1. and obtained from <strong>animals</strong> of the species&nbsp;
                                                        {dairySpecies}
                                                        &nbsp;that:
                                                        <ol type="i">
                                                            <li key="1">

                                                                <ul className="ml-3">
                                                                    {attestationData.dairyCStatements2?.map(value =>
                                                                        <li>
                                                                            {findArrayElementByFieldNameAndValue(Dairy8468Labels.dairyCStatementsChoice2, 'dairyCStatements2', value)}

                                                                            {attestationData.dairyC2Statements2 != null && value === "2" &&
                                                                                <ul>
                                                                                    {attestationData.dairyC2Statements2.map(value =>
                                                                                        <li>
                                                                                            {findArrayElementByFieldNameAndValue(Dairy8468Labels.dairyC2StatementsChoice2, 'dairyC2Statements2', value)}
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                            }
                                                                        </li>
                                                                    )}
                                                                </ul>

                                                            </li>

                                                        </ol>
                                                    </li>
                                                }
                                                <ol type="i" start="2">
                                                    <li>have been kept in <strong>establishments</strong>:</li>
                                                    <ol type="i" start="1">
                                                        <li>registered by and under the control of the competent authority of the third country or territory
                                                            and have a system in place to maintain and to keep records in accordance with Article 8 of
                                                            Delegated Regulation (EU) 2020/692;</li>
                                                        <li>which receive regular animal health visits from a veterinarian for the purpose of the detection
                                                            of, and information on, signs indicative of the occurrence of diseases, including the listed
                                                            diseases referred to in Annex I to Delegated Regulation (EU) 2020/692 relevant for the
                                                            species and emerging diseases;</li>
                                                        <li>which were not subject to national restriction measures for animal health reasons, including
                                                            the listed diseases referred to in Annex I to Delegated Regulation (EU) 2020/692 relevant for
                                                            the species and emerging diseases, at the date of milking.</li>
                                                    </ol>
                                                </ol>
                                                {attestationData.dairyB2_2 &&
                                                    <li>in the zone(s) with code(s): <strong><u>{attestationData.dairyB2CountryOrigin?.map(x => x.isoCode).join(", ")}</u></strong> which, at the date of issue of this animal health/official certificate is/are listed in Part 1 of Annex XVII to
                                                        Implementing Regulation (EU) 2021/404 for the entry into the Union of milk, and that raw milk complied with all the relevant requirements for the
                                                        entry into the Union of raw milk laid down in Commission Delegated Regulation (EU) 2020/692 and, therefore, was eligible for the entry into the
                                                        Union as such upon arrival in those zone(s) referred to under point II.2.1.
                                                    </li>
                                                }
                                                {attestationData.dairyB2_3 &&
                                                    <li>in a Member State.</li>
                                                }</ul>
                                        </ol>
                                    </li>
                                </ol>
                            }

                        </ol>

                    </ol>
                </li>
            </ul>
            <p>Product obtained from animals of species: <strong>{species}</strong></p>
        </>
    )
}

