import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React from "react";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import StorageExporterDeclarationListingTable from "../../../components/Tables/StorageExporterDeclarationListingTable";
import StorageSupplierDeclarationListingTable from "../../../components/Tables/StorageSupplierDeclarationListingTable";
import { Routes } from "../../../routes";

const StorageDeclarationDashboard = ( props) => {
    
    const breadcrumbData = {
        item1 : {name:'Storage Declarations', link: Routes.Platforms.path},
        item2 : {name:'View all', link: 'View all'}
    };
    
    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            
            <h2 id="tabelLabel" >Storage Declarations</h2>
            
            <Row className="mt-3">
                <Col lg={12} xxl={11}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Header>
                            <Row>
                                <Col md={12}>
                                    <h4 className="mb-3 text-title">Exporter Declarations</h4>
                                </Col>
                            </Row>
                        </Card.Header>
                            <Card.Body>
                                <StorageExporterDeclarationListingTable />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={12} xxl={11}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Header>
                            <Row>
                                <Col md={12} sm={12} lg={9}>
                                    <h4 className="mb-3 text-title">Supplier Declarations </h4>
                                </Col> 
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <StorageSupplierDeclarationListingTable />
                        </Card.Body>
                    </Card>
                </Col>
                
            </Row>
        </div>
    );
};
export default StorageDeclarationDashboard;