import * as Yup from 'yup';
import formModel from './GEFSFormModel';

const {
  formField: {
    firstInspection,
    changeStatement,
    changesDescription,
    dateInspection,
    decision,
rejectReason,
signedRequestUrl,
documentId,inspectionType

  }
} = formModel;

const today = new Date();

export default [
  //STAGE 1  
  Yup.object().shape({
    [inspectionType.name]: Yup.number().required(`${inspectionType.requiredErrorMsg}`),
     [dateInspection.name]: Yup.date().max(today, 'Date of inspection cannot be in the future').required(`${dateInspection.requiredErrorMsg}`),
    [changeStatement.name]: Yup.number().when([firstInspection.name],{
      is: true,
      then: Yup.number().required(changeStatement.requiredErrorMsg),
    }),
    [changesDescription.name]: Yup.string().when([changeStatement.name],{
      is: 2,
      then: Yup.string().required(changesDescription.requiredErrorMsg)
    }),
  }),

  //STAGE 2  
  Yup.object().shape({
    [decision.name]: Yup.number().required(`${decision.requiredErrorMsg}`),
    [rejectReason.name]: Yup.string().when([decision.name],{
      is: 1,
      then: Yup.string().required(rejectReason.requiredErrorMsg),
    }),
    [signedRequestUrl.name]: Yup.string().when([decision.name],{
      is: 2,
      then: Yup.string().required(signedRequestUrl.requiredErrorMsg),
    }),
    [documentId.name]: Yup.string().when([decision.name],{
      is: 2,
      then: Yup.string().required(documentId.requiredErrorMsg),
    }),
 })
];
