export const Routes = {
    
    //admin
    EstablishmentScraperPage :{path: '/admin/establishmentScraper'},

    // dasboard
    DashboardOverview: { path: "/" },
    Dashboard: { path: "/" },

    // attestations
    AttestationDashboard :{path: '/attestations'},
    DailyAttestationViewPage :{path: '/attestations/dailyattestation/view/:id'},
    DailyAttestationClonePage :{path: '/attestations/dailyattestation/clone/:id'},
    DailyAttestationAddPage :{path: '/attestations/dailyattestation/add/'},
    RequestAttestationPage :{path: '/attestations/requestattestation/gefs/:id'},
    RequestAttestationNonGEFSPage :{path: '/attestations/requestattestation/nongefs/:id'},
    ResponseAttestationPage :{path: '/attestations/responseattestation/gefs/:id'},
    ResponseAttestationNonGEFSPage :{path: '/attestations/responseattestation/nongefs/:id'},
    SupportAttestations:{path: '/supportattestations'},
    SupplierDeclarationPage :{path: '/attestations/storagedeclaration/supplier/:id'},
    ExporterDeclarationPage :{path: '/attestations/storagedeclaration/exporter/:id'},
    StorageDeclarationDashboard :{path: '/storagedeclarations/'},

    // products
    Products:{path:'/products'},
    Product:{path: '/product/:id'},
    AddProduct:{path: '/products/add'},
    EditProduct:{path: '/products/edit/:id'},
    ProductDetailAddPage :{path: '/product/detail/:id'},
    FileUpload :{path: '/products/fileupload'},

    
    // attestations
    CertificateEditor :{path: '/certificates/editor/:consignmentContentsId'},
    CertificateUpload :{path: '/certificates/upload'},
    CertificateViewPage :{path: '/certificate/view/:id'},
    CertificateListingPage :{path: '/certificates/'},

    ConsignmentClonePage :{path: '/consignment/clone/:id'},
    ConsignmentAddPage :{path: '/consignment/add/'},
    ConsignmentEditPage :{path: '/consignment/edit/:id'},
    ConsignmentContentsPage :{path: '/consignment/contents/:id'},
    ConsignmentListingPage :{path: '/consignments/'},
    // forms
    Composite8350:{path: '/product/composite/8350/:id'},
    Composite8350NonGEFS:{path: '/product/composite/8350/:id/:attestationId'},
    Dairy8468AddPage:{path: '/product/dairy/8468/:id'},
    Dairy8468AddPageNonGEFS:{path: '/product/dairy/8354/:id/:attestationId'},
    MeatPreparation8383AddPage:{path: '/product/meatpreparation/8383/:id'},
    MeatPreparation8383AddPageNonGEFS:{path: '/product/meatpreparation/8383/:id/:attestationId'},
    Meat8384AddPage:{path: '/product/meat/8384/:id'},
    Meat8384AddPageNonGEFS:{path: '/product/meat/8384/:id/:attestationId'},
    Egg8360AddPage:{path: '/product/egg/8360/:id'},
    Egg8360AddPageNonGEFS:{path: '/product/egg/8360/:id/:attestationId'},
    Fishery8361AddPage:{path: '/product/fishery/8361/:id'},
    Fishery8361AddPageNonGEFS:{path: '/product/fishery/8361/:id/:attestationId'},
    HighlyRefined8399AddPage:{path: '/product/highlyrefined/8399/:id'},
    HighlyRefined8399AddPageNonGEFS:{path: '/product/highlyrefined/8399/:id/:attestationId'},
    WildGame8367AddPage:{path: '/product/wildgame/8367/:id'},
    WildGame8367AddPageNonGEFS:{path: '/product/wildgame/8367/:id/:attestationId'},
    Porcine8370AddPage:{path: '/product/porcine/8370/:id'},
    Porcine8370AddPageNonGEFS:{path: '/product/porcine/8370/:id/:attestationId'},
    Poultry8371AddPage:{path: '/product/poultry/8371/:id'},
    Poultry8371AddPageNonGEFS:{path: '/product/poultry/8371/:id/:attestationId'},
    Ovine8369AddPage:{path: '/product/ovine/8369/:id'},
    Ovine8369AddPageNonGEFS:{path: '/product/ovine/8369/:id/:attestationId'},
    Bovine8368AddPage:{path: '/product/bovine/8368/:id'},
    Bovine8368AddPageNonGEFS:{path: '/product/bovine/8368/:id/:attestationId'},
    DogChews8325AddPage:{path: '/product/dogchews/8325/:id'},
    DogChews8325AddPageNonGEFS:{path: '/product/dogchews/8325/:id/:attestationId'},
    Casing8393AddPage:{path: '/product/casing/8393/:id'},
    Casing8393AddPageNonGEFS:{path: '/product/casing/8393/:id/:attestationId'},
    Gelatine8390AddPage:{path: '/product/gelatine/8390/:id'},
    Gelatine8390AddPageNonGEFS:{path: '/product/gelatine/8390/:id/:attestationId'},
    Collagen8396AddPage:{path: '/product/collagen/8396/:id'},
    Collagen8396AddPageNonGEFS:{path: '/product/collagen/8396/:id/:attestationId'},
    Honey8391AddPage:{path: '/product/honey/8391/:id'},
    Honey8391AddPageNonGEFS:{path: '/product/honey/8391/:id/:attestationId'},
    VitaminD8392AddPage:{path: '/product/vitamind/8392/:id'},
    VitaminD8392AddPageNonGEFS:{path: '/product/vitamind/8392/:id/:attestationId'},
    Storage8461AddPage:{path: '/product/storage/8461/:id'},
    StorageSupplierDeclarationListingPage:{path: '/storagedeclarations/supplier'},
    StorageExporterDeclarationListingPage:{path: '/storagedeclarations/exporter'},
    
    // manufacturers
    ManufacturersListingPage:{path:'/manufacturers'},
    ManufacturerViewPage :{path: '/manufacturer/view/:id'},
    ManufacturerEdit :{path: '/manufacturer/edit/:id'},
    ManufacturerAddPage :{path: '/manufacturer/add'},
    
    // platforms
    Platforms:{path: '/platforms'},
    PlatformViewPage :{path: '/platform/view/:id'},
    PlatformEdit :{path: '/platform/edit/:id'},
    PlatformAdd :{path: '/platforms/add'},
    
    // platform certifiers
    PlatformCertifiersListingPage :{path: '/platformcertifiers'},

    // sites
    Sites:{path: '/sites'},
    AddSite:{path: '/sites/addsite'},
    SiteViewPage :{path: '/site/view/:id'},
    SiteEdit :{path: '/site/edit/:id'},

    EstablishmentListingPage:{path: '/establishments/'},
    // vets
    Vets :{path: '/vets'},
    AddVet :{path: '/vets/addvet'},

    // users
    UserGuides: {path: '/userguides'},
    UserAdminPage :{path: '/admin/user'},
    NoticeboardAdmin :{path: '/admin/noticeboard'},
    AIAdmin :{path: '/admin/aiadmin'},
    
    //reports
    ReportsDashboard :{path: '/reports'},

    // errors
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },

};