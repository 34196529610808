import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faEllipsisH, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Table, Button, ButtonGroup, Dropdown,Card } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import moment from "moment";
import { Routes } from "../../routes";
import Preloader from "../Preloader";
import { NoResults } from "./NoResults";

export const CertificatesTable = (props) => {
  const { certificates, loaded = false, onRemove } = props;
  const totalTransactions = certificates.length;

  const history = useHistory();

  const TableRow = (props) => {
    const { id, certificateName, platform, certificateNumber, language, destinationCountry, active, createdDate } = props;

    const getDate = (date) => {
      var newDate = moment(date).format("DD/MM/YY");
      if (newDate === "Invalid date") {
          return "";
      }
      return newDate;
    };

    const _viewCertificate = (id) => {
      history.push(Routes.CertificateViewPage.path.replace(":id", id));
    };

    return (
      <tr>
        <td>
          <Card.Link as={Link} to={Routes.CertificateViewPage.path.replace(":id", id)} className="fw-normal text-black">
            {certificateName}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">
            {certificateNumber}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {active ?
              <>
                <FontAwesomeIcon icon={faCheckCircle} className="ms-1 text-success float-start mx-1" />
                <span className="mx-1">Assigned</span>
              </>
              :
              <>
                <FontAwesomeIcon icon={faTimesCircle} className="ms-1 text-gray-600 float-start mx-1" />
                <span className="mx-1">Unassigned</span>
              </>
            }
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {language}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {destinationCountry?.countryName}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {platform?.platformName}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {getDate(createdDate)}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="position-absolute">
              <Dropdown.Item onClick={() => _viewCertificate(id)}>
                <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" />
                View Details
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onRemove(id, certificateName)}>
                <FontAwesomeIcon icon={faTrash} className="dropdown-item-icon" />
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Preloader component={true} show={!loaded} />
      <Table hidden={!loaded || certificates == 0} hover className="user-table align-items-center">
        <thead>
          <tr>
            <th className="border-bottom">Reference</th>
            <th className="border-bottom">Number</th>
            <th className="border-bottom">Availability</th>
            <th className="border-bottom">Language</th>
            <th className="border-bottom">Destination</th>
            <th className="border-bottom">Platform</th>
            <th className="border-bottom">Date Uploaded</th>
            <th className="border-bottom">Action</th>
          </tr>
        </thead>
        <tbody>
          {certificates.map(t => <TableRow key={`transaction-${t.id}`} {...t} onRemove={onRemove} />)}
        </tbody>
      </Table>
      {loaded && certificates.length === 0 && (
        <NoResults name="certificates" />
      )}
    </>
  );
};
