import React from 'react';
import {Tab, Row, Col, Nav} from '@themesberg/react-bootstrap';
import {DailyAttestationSelectTable} from "../../../../components/Tables/ConsignmentTables/DailyAttestationSelectTable"
import AttestationNonGEFSTable from '../../../../components/Tables/ConsignmentTables/AttestationNonGEFSTable';
import StorageDeclarationSelectTable from '../../../../components/Tables/ConsignmentTables/StorageDeclarationSelectTable';

const ConsignmentContentsSelectModal = (props) =>{
    const {
        setShowStorageDeclaration,
        setShowNonGEFS,
        enableCompound=false,
        onChange,
        showExtraOptions= false,
        contentsSelected,
        setFormChanged
    } = props;

    return (
        
        <Tab.Container defaultActiveKey="first" className="mt-0">
        {/* Tabbed Menu */}
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="px-2">
              <Nav.Item>
                <Nav.Link eventKey="first">GEFS - Daily Attestations</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Non-GEFS Attestations</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Storage Declarations</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <DailyAttestationSelectTable
                    enableCompound={enableCompound}
                    setShowNonGEFS={setShowNonGEFS}
                    onChange={onChange}
                    showExtraOptions={showExtraOptions}
                    contentsSelected={contentsSelected}
                    setFormChanged={setFormChanged}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <AttestationNonGEFSTable
                    enableCompound={enableCompound}
                    setShowNonGEFS={true}
                    onChange={onChange}
                    showExtraOptions={showExtraOptions}
                    contentsSelected={contentsSelected}
                    setFormChanged={setFormChanged}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <StorageDeclarationSelectTable 
                    enableCompound={enableCompound}
                    setShowStorageDeclaration={setShowStorageDeclaration}
                    onChange={onChange}
                    showExtraOptions={showExtraOptions}
                    contentsSelected={contentsSelected}
                    setFormChanged={setFormChanged}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
};

export default ConsignmentContentsSelectModal;
