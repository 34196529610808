import { getIn } from 'formik';

const yesFieldString = "Final consumer YES";
const noFieldString = "Final consumer NO";


export const clearFieldValue = ( x, i, data ) => {
    data.fields[i].value= "";
}


export const setFieldValues = ( x, i, values, data ) => {
    
    //Skip these fields
    if(x.fieldName.includes(yesFieldString) || x.fieldName.includes(noFieldString)){
        return false;
    }
    //Check for any custom overrides needed, to skip any further field logic
    var isOverrideRequired = overrideFieldLogic( x, i, values, data );

    //Regular fields
    if(isOverrideRequired== false){    

        var found =  getIn(values.fields, x.fieldName?.replaceAll(" ",""));

        //Checkboxes
        if(x.fieldType==="checkbox"){
            if(found == "On" || found == true || found == "TRUE" || found == "ON" || found == "True"){
                data.fields[i].value= "On";
            }
            else{
                data.fields[i].value= "Off";
            }
        }
        else{

            data.fields[i].defaultValue= found; //override default value with form value
            data.fields[i].value= found;
        }

        if(x.linkedFieldName !== undefined && x.linkedFieldName !== null){
            found = getIn(values.fields, x.linkedFieldName?.replaceAll(" ",""));
        }
    }
}


//Custom logic for problem fields - Overrides any other logic for field
const overrideFieldLogic = ( configField, index, values, data ) => {

    var fieldList=[
        "Final consumer 1",
        "Final consumer 2",
        "Final consumer 3",
        "Final consumer 4",
        "Final consumer 5",
    ];

    //Final Consumer
    if(fieldList.includes(configField.fieldName)){
        //Get form field for this config field
        var number = configField.fieldName.slice(-1);
        var formField =  getIn(values.fields, configField.fieldName?.replaceAll(" ",""));

        //Find yes / no fields in config
        var configYesIndex =  data.fields.findIndex(x=>x.fieldName == yesFieldString + number);
        var configNoIndex =  data.fields.findIndex(x=>x.fieldName == noFieldString + number);

        //Switch field data values from provided form checkbox
        if(formField===true){
            data.fields[index].value= true;
            if(configYesIndex >= 0){
                data.fields[configYesIndex].value= "True";
                data.fields[configNoIndex].value= "False";
            }
        }
        else{
            data.fields[index].value= false;
            if(configYesIndex >= 0){
                data.fields[configYesIndex].value= "False";
                data.fields[configNoIndex].value= "True";
            }
        }
        return true;
    }
    return false;
}

