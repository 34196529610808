import React from "react"
import { Field } from "formik"
import { Form } from "@themesberg/react-bootstrap";

    const Textarea = (props) => {  
        const { name, label = null, sublabel = null, isrequired, touched, col=12, fieldheight, errors, rows, ...rest } = props
    return (
        <Field name={props.name}
        >
       {({ field, meta }) => {
            return (
                <Form.Group controlId={`validation${name}`}>
                    {label!==null&&(
                    <Form.Label className={(isrequired=="true"?"required":"")}>{label}</Form.Label>
                    )}
                    {sublabel!==null&&(
                                <Form.Text className="text-muted">&nbsp;&nbsp;
                                {sublabel}
                                </Form.Text>
                            )}
                    <Form.Control
                        as="textarea"
                        name={name}
                        style={{ height: fieldheight }}
                        isInvalid={!!meta.error && meta.touched}
                        {...field}
                        {...rest}
                        />
                        <Form.Control.Feedback type="invalid">
                        {meta.error}
                        </Form.Control.Feedback>
                </Form.Group>
                )
            }} 
        </Field>
    )
}
export default Textarea