export const SupplierStorageFilterConfig = (manufacturers, sites, countries) => {
return [
    {
        hasMenu: true,
        title: "Manufacturers",
        name: "manufacturers", 
        data: manufacturers,
        hasSearch: true,
        isFilter: true
    },
    {
        hasMenu: true,
        title: "Sites",
        name: "sites", 
        data: sites,
        hasSearch: true,
        isFilter: true
    },
    {
        hasMenu: true,
        title: "Countries of origin",
        name: "country",
        data: countries,
        hasSearch: true,
        isFilter: true
    }
  ] 
};