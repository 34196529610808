import *  as baseService  from "./BaseService";

const getByCertificateId = (certificateId) => {
    return baseService.getApiData("/CertificateAssigned/GetByCertificateId?certificateId=" + certificateId);
};
const getByConsignmentContentsId = (consignmentContentsId) => {
    return baseService.getApiData("/CertificateAssigned/GetByConsignmentContentsId?consignmentContentsId=" + consignmentContentsId);
};
const removeByConsignmentContentsId = (consignmentContentsId) => {
    return baseService.deleteApiData("/CertificateAssigned/DeleteByConsignmentContentsId?consignmentContentsId=" + consignmentContentsId);
};

const CertificateAssignedService = {
    getByCertificateId,
    getByConsignmentContentsId,
    removeByConsignmentContentsId
};

export default CertificateAssignedService;