import ReactHtmlParser from 'react-html-parser';

export function findArrayElementByFieldName(array, fieldName) {
     var el = array.find((obj) => obj.fieldname === fieldName)
     if(el!=null){
         return ReactHtmlParser(el.key);
     }
}
 
export function findArrayElementByValue(array, valueToFind){
    var res = "";
    array.forEach((element) => {
        if(element.value == valueToFind){
            res = ReactHtmlParser(element.key);
        }
        if(element.subclauses != null){
            element.subclauses.forEach((subclause) => {
                if(subclause.value == valueToFind){
                    res = ReactHtmlParser(subclause.key);
                }
                if(subclause.subclauses != null){
                    subclause.subclauses.forEach((subclause2) => {
                        if(subclause2.value == valueToFind){
                            res = ReactHtmlParser(subclause2.key);
                        }
                    });
                }

            });
        }
    });
    return res;
}

export function findArrayElementByFieldNameAndValue(array, fieldNameToFind, valueToFind){
    var res = "";
    array.forEach((element) => {
        if(element.fieldname == fieldNameToFind && element.value == valueToFind){
            res = ReactHtmlParser(element.key);
        }
        if(element.subclauses != null){
            element.subclauses.forEach((subclause) => {
                if(subclause.fieldname === fieldNameToFind && subclause.value == valueToFind){
                    res = ReactHtmlParser(subclause.key);
                }
                if(subclause.subclauses != null){
                    subclause.subclauses.forEach((subclause2) => {
                        if(subclause2.fieldname === fieldNameToFind && subclause2.value == valueToFind){
                            res = ReactHtmlParser(subclause2.key);
                        }
                    });
                }

            });
        }
    });
 return res;
}

