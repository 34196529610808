export const casingSpeciesChoices = [
    { group: "casing_2_1_SpeciesType1", fieldname: "casing_2_1_SpeciesType1", value: 1, key: "bovine" },
    { group: "casing_2_1_SpeciesType2", fieldname: "casing_2_1_SpeciesType2",  value: 2, key: "ovine" },
    { group: "casing_2_1_SpeciesType3", fieldname: "casing_2_1_SpeciesType3",  value: 3, key: "caprine" },
    { group: "casing_2_1_SpeciesType4", fieldname: "casing_2_1_SpeciesType4",  value: 4, key: "kept porcine animals" },
]
export const casingSpeciesChoices2 = [
    { group: "casing_2_2_SpeciesType1", fieldname: "casing_2_2_SpeciesType1", value: 1, key: "bovine" },
    { group: "casing_2_2_SpeciesType2", fieldname: "casing_2_2_SpeciesType2",  value: 2, key: "ovine" },
    { group: "casing_2_2_SpeciesType3", fieldname: "casing_2_2_SpeciesType3",  value: 3, key: "caprine" },
    { group: "casing_2_2_SpeciesType4", fieldname: "casing_2_2_SpeciesType4",  value: 4, key: "kept porcine animals" },
]

export const BseRiskCategoryNegligibleStatementsChoice = [
    {group: "", fieldname: "BseNegligible1", value: 1, disabled: "disabled", key: "the animals from which the casings are derived were born, continuously reared and slaughtered in a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a negligible BSE risk;" },
    {group: "", fieldname: "BseNegligible2", value: 2, disabled: "disabled",key: "the animals from which the casings are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a controlled BSE risk and:<ol className='list-clauses'><li>if derived from bovine animals, the casings do not contain and are not derived from specified risk material as defined in Annex V, point 1(a)(iii), to Regulation (EC) No 999/2001;</li><li>the animals from which the casings are derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod-shaped instrument introduced into the cranial cavity;</li></ol>" },
    {group: "", fieldname: "BseNegligible3", value: 3, disabled: "disabled",key: "the animals from which the casings are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing an undetermined BSE risk and:<ol className='list-clauses'><li>if derived from bovine animals, the casings do not contain and are not derived from specified risk material as defined in Annex V, point 1(a)(iii), to Regulation (EC) No 999/2001;</li><li>the animals from which the casings are derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod-shaped instrument introduced into the cranial cavity;</li><li>the animals from which the casings are derived have not been fed with meat-and-bone meal or greaves, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health</li></ol>" },
]
export const BseRiskCategoryControlledStatementsChoice = [
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: 1, disabled: "disabled", key: "the animals from which the casings are derived were born, continuously reared and slaughtered in a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a negligible BSE risk in which there have been no BSE indigenous cases;" },
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: 2, disabled: "disabled", key: "the animals from which the casings are derived were born, continuously reared and slaughtered in a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a negligible BSE risk in which there has  been at least one BSE indigenous case and, if the casings derived from bovine animals:<ol className= 'list-clauses'><li>the animals were born after the date from which the ban on the feeding of ruminants with meat-and-bone meal and greaves derived from ruminants has been enforced,</li><li>or the casings do not contain and are not derived from specified risk material as defined in Annex V, point 1(a)(iii), to Regulation (EC) No 999/2001.</li></ol>"},
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: 3, key: "the animals from which the casings are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a controlled BSE risk and:<ol className= 'list-clauses'><li>the animals from which the casings are derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervoustissue by means of an elongated rod-shaped instrument introduced into the cranial cavity,</li><li>if derived from bovine animals, the casings do not contain and are not derived from specified risk material asdefined in Annex V, point 1(a)(iii), to Regulation (EC) No 999/2001;</li></ol>"},
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: 4, key: "the animals from which the casings are derived originate from a country or region classified in accordance with Decision2007/453/EC as a country or region posing an undetermined BSE risk and<ol className= 'list-clauses'><li>the animals from which the casings are derived have not been slaughtered after stunning by means of gas injectedinto the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod-shaped instrument introduced into the cranial cavity,</li><li>the animals from which the casings are derived have not been fed with meat-and-bone meal or greaves, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health,</li><li>if derived from bovine animals, the casings do not contain and are not derived from specified risk material as defined in Annex V, point 1(a)(iii), to Regulation (EC) No 999/2001;</li></ol>"},
]
export const BseRiskCategoryUndeterminedStatementsChoice = [
    {group: "", fieldname: "BseUndetermined",  value: 1, disabled:"disabled", key: "the casings and the animals from which the casings are derived comply with the following requirements:<ol className= 'list-clauses'><li>the animals from which the casings are derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod-shaped instrument introduced into the cranial cavity;</li><li>the animals from which the casings are derived have not been fed meat-and-bone meal or greaves derived from ruminants, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health;</li><li>if derived from bovine animals, the casings do not contain and are not derived from specified risk material as defined in Annex V, point 1(a)(iii), to Regulation (EC) No 999/2001;</li></ol>"},
    {group: "", fieldname: "BseUndetermined",  value: 2,  disabled:"disabled", key: "the animals from which the casings are derived were born, continuously reared and slaughtered in a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a negligible BSE risk in which there have been no BSE indigenous cases;"},
    {group: "", fieldname: "BseUndetermined",  value: 3,  disabled:"disabled", key: "the animals from which the casings are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a negligible BSE risk in which there has been at least one BSE indigenous case and, if the casings derived from bovine animals:<ol className='list-clauses'><li>the animals were born after the date from which the ban on the feeding of ruminants with meat-and-bone meal and greaves derived from ruminants has been enforced,</li><li>or the casings do not contain and are not derived from specified risk material as defined in Annex V, point 1(a)(iii), to Regulation (EC) No 999/2001.</li>"},
]

export const animal_2_2_statements = [
    { group: "animal_2_2_statements", fieldname: "animal_2_2_statements", value: 1, key: "have been processed from bladders and/or intestines obtained from:" },
    { group: "animal_2_2_statements", fieldname: "animal_2_2_statements", value: 2, key: "have been processed from bladders and/or intestines obtained from bovine, ovine and/or caprine, kept porcine animals and during their processing have been:" },
    { group: "animal_2_2_statements", fieldname: "animal_2_2_statements", value: 3, key: "have been processed from bladders and/or intestines obtained from animals <strong>other than</strong> bovine, ovine, caprine and/or porcine animals and during their processing have been:" },
]


export const animal_2_2_A_statements = [
    { group: "animal_2_2_A_statements", fieldname: "animal_2_2_A_statements", value: 1, key: "salted with sodium chloride (NaCl), either dry or as saturated brine (aw 0,80), for a continuous period of 30 days or longer, attemperature of 20oC or above;" },
    { group: "animal_2_2_A_statements", fieldname: "animal_2_2_A_statements", value: 2, key: "salted with phosphate supplemented salt containing 86,5% NaCl, 10,7% Na2HPO4 and 2,8% Na3PO4 (weight/weight/weight), either dry or as saturated brine (aw 0,80), for a continuous period of 30 days or longer, at a temperature of 20oC or above;" },
]

export const animal_2_2_B_statements = [
    { group: "animal_2_2_B_statements", fieldname: "animal_2_2_B_statements", value: 1, key: "salted with sodium chloride (NaCl) for 30 days;" },
    { group: "animal_2_2_B_statements", fieldname: "animal_2_2_B_statements", value: 2, key: "bleached;" },
    { group: "animal_2_2_B_statements", fieldname: "animal_2_2_B_statements", value: 3, key: "dried after scraping;" },
]

