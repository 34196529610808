import React from "react"
import { Field } from "formik"
import {  Col, Form } from "@themesberg/react-bootstrap";

    const Input = (props) => {  
        const { type="text", name, label, counterMax, showCounter=false, sublabel = null, readOnly, min=null, max=null, isrequired, col = 12, touched, errors, ...rest } = props
    return (
        <Field  name={props.name}>
            {({ field, meta }) => {
                    return (
                        <Form.Group controlId={`validation${name}`}>
                            {label!=null&&(
                                <Form.Label className={(isrequired=="true"?"required":"")}>{label}</Form.Label>
                            )}
                            {sublabel!==null&&(
                                <Form.Text className="text-muted">&nbsp;&nbsp;
                                {sublabel}
                                </Form.Text>
                            )}
                            <Col md={col}>
                                <div className={showCounter>0?"input-charlimit-group": ""}>
                                    <Form.Control
                                        type={type}
                                        step="any" 
                                        name={name}
                                        disabled={readOnly}
                                        col="sm"
                                        min={min}
                                        maxLength={max}
                                        isInvalid={!!meta.error && meta.touched}
                                        {...field}
                                        {...rest}
                                    />
                                    {showCounter>0 &&
                                        <div className="input-charlimit-group-append">
                                            <small className="input-charlimit-group-text">
                                                {`${field?.value ? field.value.length : 0}/${counterMax}`}
                                            </small>
                                        </div>
                                    }
                                    <Form.Control.Feedback type="invalid">
                                    {meta.error}
                                    </Form.Control.Feedback>
                                </div>
                            </Col>
                        </Form.Group>
                    )
            }} 
        </Field>
    )
}
export default Input