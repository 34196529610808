import React, { useState, useEffect, useRef } from 'react';
import AddStampModal from '../Modals/AddStampModal';
import MouseTooltip from 'react-sticky-mouse-tooltip';

const DocumentAdminOverlay = ({documentPath, setData, data, pageNumber, totalPages, ctx, canvas}) =>{

    const [isMouseTooltipVisible, setMouseTooltipVisible] = useState(false);
    const [isDragging, setDragging] = useState(false);
    const [coords, setCoords] = useState('');
    
    let scale = 0.316;

    const handleCloseModalAdd = () => setShowModalAdd(false);
    const [rect, setRect] = useState({});
    const [showModalAdd, setShowModalAdd] = useState(false);

    function handleMouseDown(e) {
        const canvasEle = canvas.current;
        canvasEle.width = canvasEle.clientWidth;
        canvasEle.height = canvasEle.clientHeight;

        // get context of the canvas
        ctx = canvasEle.getContext("2d");

        var test = rect;   
        var cRect = e.currentTarget.getBoundingClientRect();              // Gets the CSS positions along with width/height
        var canvasX = Math.round((e.clientX - cRect.left) * scale);        // Subtract the 'left' of the canvas from the X/Y
        var canvasY = Math.round((e.clientY - cRect.top) * scale);         // positions to get make (0,0) the top left of the 
        test.startX = canvasX;
        test.startY = canvasY;
        setRect(test);
        setDragging(true); 
    }

    function handleMouseUp(e) { 
        const canvasEle = canvas.current;
        canvasEle.width = canvasEle.clientWidth;
        canvasEle.height = canvasEle.clientHeight;

        // get context of the canvas
        ctx = canvasEle.getContext("2d");
        setDragging(false);
        setShowModalAdd(true);
        console.log(rect);
    }

    function handleCanvasMouseMove(e) {
        // dynamically assign the width and height to canvas
        const canvasEle = canvas?.current;
        if(canvasEle !== undefined){
            canvasEle.width = canvasEle.clientWidth;
            canvasEle.height = canvasEle.clientHeight;
    
            // get context of the canvas
            ctx = canvasEle.getContext("2d");
        }
        else{
            return null;
        }
        if(isDragging === true){
            // canvas
            var cRect = e.currentTarget.getBoundingClientRect(); 
            var newRect  = rect;   
            var canvasX = Math.round((e.clientX - cRect.left) * scale);        // Subtract the 'left' of the canvas from the X/Y
            var canvasY = Math.round((e.clientY - cRect.top) * scale);         // positions to get make (0,0) the top left of the 
        
            newRect.w = canvasX  - rect.startX;
            newRect.h = canvasY- rect.startY ;
            ctx.clearRect(0,0,canvas.width,canvas.height);

            setRect(newRect);
            //ctx.clearRect(0,0,canvasEle.width,canvasEle.height);
            ctx.setLineDash([6]);
            ctx.strokeStyle = "#000";
            ctx.strokeRect(rect.startX * 3.17, rect.startY * 3.16, rect.w *3.16, rect.h * 3.16);
        }
  
        else{
            //ctx.clearRect(0, 0, canvas.width, canvas.height);        // canvas
            var cRect = e.currentTarget.getBoundingClientRect();              // Gets the CSS positions along with width/height
            var canvasX = Math.round((e.clientX - cRect.left) * scale);        // Subtract the 'left' of the canvas from the X/Y
            var canvasY = Math.round((e.clientY - cRect.top) * scale);         // positions to get make (0,0) the top left of the 
      
            var coordsText = "X: "+canvasX+", Y: "+canvasY;
            ctx.fillText(coordsText, 10, 20);
            setCoords(coordsText);
            setMouseTooltipVisible(true);
      
        }

    };


    const handleDataObjectAdd = (obj) => {
        setData(obj);
    }
    return(
        <>
                {/* //Add Stamp Modal */}
                {showModalAdd == true &&
                    <AddStampModal 
                        totalPages={totalPages}
                        showModalAdd={showModalAdd} 
                        handleCloseModalAdd={() => handleCloseModalAdd()}
                        rect={rect}
                        pageNumber={pageNumber}
                        handleDataObjectAdd={handleDataObjectAdd}
                        data={data}
                        scale={3.16}
                    />
                }
                {/* //Mouse Hover */}
                <MouseTooltip
                    visible={isMouseTooltipVisible}
                    offsetX={15}
                    offsetY={10}
                    className="mouseCoordsTootlTip">
                    <span>{coords}</span>
                </MouseTooltip>
        
            {/* //Overlay Canvas for drawings */}
            <canvas ref={canvas}
                id="reactPdfPreview" 
                className="react-pdf-preview position-absolute" 
                dir="ltr" 
                width={818}  
                onMouseMove={handleCanvasMouseMove}
                onMouseEnter={()=>setMouseTooltipVisible(true)}
                onMouseOut={()=>setMouseTooltipVisible(false)}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                height={1157} >
            </canvas>
        </>
    )
}

export default DocumentAdminOverlay;
