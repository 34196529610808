import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faFileContract, faFilter, faEllipsisH, faEye, faEdit, faTrashAlt, faClone } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Table, Button, ButtonGroup, Card, Badge, Dropdown } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { useTable } from "react-table";
import { NoResults } from "./NoResults";
import { Routes } from "../../routes";
import Preloader from "../Preloader";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { scopes } from '../../auth/PermissionsMap';
import PermissionsCheck from '../../auth/PermissionCheck';

const getDate = (date) => {
  var newDate = moment(date).format("DD/MM/YY");
  if (newDate === "Invalid date") {
    return "";
  }
  return newDate;
}


export const ConsignmentsTable = (props) => {
  const { consignments, loaded = false } = props;
  const totalTransactions = consignments.length;

  const history = useHistory();

  const TableRow = (props) => {
    const { id, destinationCountry, createdDate, consignmentName, platform, approved, status, departureDate } = props;
    const statusVariant = status === "Paid" ? "success"
      : status === "Due" ? "warning"
        : status === "Canceled" ? "danger" : "primary";

    //Open View
    const _viewConsignment = (id) => {
      history.push(Routes.ConsignmentContentsPage.path.replace(":id", id));
    };
    const _cloneConsignment = (id) => {
      window.open(Routes.ConsignmentClonePage.path.replace(":id", id), "_self");
    };

    return (
      <tr>
        <td>
          <Card.Link as={Link} to={Routes.ConsignmentContentsPage.path.replace(":id", id)} className="fw-normal text-black">
            {/* {consignmentName? consignmentName : "Untitled Consignment"} */}
            {consignmentName}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">
            {destinationCountry.countryName}
          </span>
        </td>
        {/* <td>
          <span className="fw-normal">
            {platform.platformName}
          </span>
        </td> */}
        <td className="text-center">
          <span className="fw-normal text-center">
            {approved ? (
              <FontAwesomeIcon icon={faCheckCircle} className="ms-1 text-success mx-1" />
            )
              :
              (
                <FontAwesomeIcon icon={faTimesCircle} className="ms-1 text-danger mx-1" />
              )}
          </span>
        </td>
        <td className="text-center">
          <span className="fw-normal">
            {createdDate ? (
              <>
                {getDate(createdDate)}
              </>
            ) : (
              <>
              </>
            )}
          </span>
        </td>
        <td className="text-center">
          <span className="fw-normal">
            {departureDate ? (
              <>
                {getDate(departureDate)}
              </>
            ) : (
              <>
              </>
            )}
          </span>
        </td>
        <td> <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="position-absolute">
            <Dropdown.Item onClick={() => _viewConsignment(id)}>
              <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" />
              View Details
            </Dropdown.Item>
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.consignments.create]}>
              <Dropdown.Item onClick={() => _cloneConsignment(id)}>
                <FontAwesomeIcon icon={faClone} className="dropdown-item-icon" />
                Clone
              </Dropdown.Item>
            </PermissionsCheck>

          </Dropdown.Menu>
        </Dropdown></td>

        {/* 
        <td>
          <span className={`fw-normal text-${statusVariant}`}>
            {status}
          </span>
        </td> */}
        {/* <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td> */}
        <td></td>
      </tr>
    );
  };

  return (
    <>
      <Preloader component={true} show={!loaded} />
      <Table hidden={!loaded || consignments == 0} hover className="user-table align-items-center">
        <thead>
          <tr>
            <th className="border-bottom">Consignment Name</th>
            <th className="border-bottom">Destination Country</th>
            {/* <th className="border-bottom">Platform name</th> */}
            <th className="border-bottom text-center">Completed</th>
            <th className="border-bottom text-center">Date created</th>
            <th className="border-bottom text-center">Date of export</th>
            <th className="border-bottom">Action</th>
          </tr>
        </thead>
        <tbody>
          {consignments.map(t => <TableRow key={`transaction-${t.id}`} {...t} />)}
        </tbody>
      </Table>
      {loaded && consignments.length == 0 && (
        <NoResults name="consignments" />
      )}
    </>

  );
};
