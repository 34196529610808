import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useRef, useState } from "react";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { EstablishmentTable } from "../../../components/Tables/EstablishmentTable";
import { Routes } from "../../../routes";
import FilterBar from '../../filters/FilterBar';
import EstablishmentsService from "../../services/EstablishmentsService";
import Paging from "../common/Paging";
import { EstablishmentFilterConfig } from '../../filters/configs/EstablishmentFilterConfig';

const EstablishmentListingPage = (props) => {
    const [totalItems, setTotalItems] = useState(0);
    const [establishments, setEstablishments] = useState([]);
    const [activePage, setActivePage] = React.useState(1);
    const [loaded, setLoaded] = useState(false);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [isSubmitting, setSubmitting] = useState(false);
    const [ready] = useState(true);
    const orderByOptions = ["A-Z", "Z-A"]
    const { totalPages = count, size = "md", withIcons = false, disablePrev = false } = props;

    const establishmentRef = useRef();
    const breadcrumbData = {
        item1 : {name:'Establishments', link: Routes.EstablishmentListingPage.path},
        item2 : {name:'View all', link: 'View all'},
    };
    establishmentRef.current = establishments;

    //Start - Paging Functions
    const onPrevItem = () => {
        const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
        setActivePage(prevActiveItem);
      };
      const onPageNumberClick = (number) => {
        setActivePage(number);
    };

    const onNextItem = (totalPages) => {
      const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
      setActivePage(nextActiveItem);
    };
    //End - Paging Functions

    
    
    const getEstablishments = (params) => {
        setLoaded(false);
        EstablishmentsService.getAllPaged(params)
            .then((response) => {
                const { data, totalPages, totalRecords} = response.data;
                setTotalItems(totalRecords);
                setEstablishments(data);
                setCount(totalPages);
                setLoaded(true);
            })
            .catch((e) => {
                setLoaded(true);
                console.log(e);
            });
    };

    const handlePageSizeChange = (value) => {
        setPageSize(value.value);
    };
    

    //Submit
    const handleFilterSubmit= (params) => {
        setSubmitting(true);
        getEstablishments(params)
        setSubmitting(false);
    }

    const handleFilterChange = (values) => {
        setSubmitting(true);
        setSubmitting(false);
    }
    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            
            <Row className='row d-flex align-items-center'>
                <Col xs={12} sm={6} lg={6} className="text-start">
                    <h2 id="tabelLabel" >Establishments</h2>
                    <div className="text-muted">All establishments available on iVetted from from TRACES</div>
                </Col>
            </Row>

            <div className="col-md-12 list">
                <Row className="mt-1">
                    <Col>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header>
                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <FilterBar
                                            defaultFilterValues={EstablishmentFilterConfig()}
                                            setLoaded={setLoaded} 
                                            loaded={loaded} 
                                            ready={ready} 
                                            hasSearch={true}
                                            activePage={activePage}
                                            pageSize={pageSize}
                                            orderByOptions={orderByOptions}
                                            setActivePage={setActivePage}
                                            handleChange={handleFilterChange}
                                            handleSubmit={handleFilterSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>

                                <EstablishmentTable loaded={loaded} establishments={establishments} />
                                <Paging
                                        activePage={activePage}
                                        size={size}
                                        pageSize={pageSize}
                                        totalPages={totalPages}
                                        totalItems={totalItems}
                                        handleChange={handlePageSizeChange}
                                        onPrevItem={onPrevItem}
                                        onNextItem={onNextItem}
                                        onPageNumberClick={onPageNumberClick}
                                        withIcons={withIcons}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default EstablishmentListingPage;