
import { HsCodes8371Choices, HsCodes8325Choices, HsCodes8350Choices, HsCodes8360Choices, HsCodes8361Choices, HsCodes8367Choices, HsCodes8368Choices, HsCodes8369Choices, HsCodes8370Choices, HsCodes8383Choices, HsCodes8390Choices, HsCodes8391Choices, HsCodes8396Choices, HsCodes8399Choices, HsCodes8468Choices } from '../Data/HsCodes';


export const handleCertificateSelection = (e, setFieldValue, certList, certificateNumbers, val) => {

    const found = certificateNumbers.find(x => x.number.toString() === e.target.value);
    if (found == undefined && found == null) {
        return;
    }

    var array = certList.slice();
    if (e.target.checked) {
        array.push(e.target.value);
        //setFieldValue("certificateNumber", val);
    }
    else {
        array.splice(array.indexOf(e.target.value), 1);
       // setFieldValue("certificateNumber", val);
    }
}

export function handleCommodityCodeOptions (certificateNumber){

    if(certificateNumber== undefined){
        return null;
    }
    return certificateNumber == "8350" ?
                HsCodes8350Choices :
            certificateNumber == "8468" ?
                HsCodes8468Choices :
            certificateNumber == "8361" ?
                HsCodes8361Choices :
            certificateNumber == "8399" ?
                HsCodes8399Choices :
            certificateNumber == "8390" ?
                HsCodes8390Choices :
            certificateNumber == "8391" ?
                HsCodes8391Choices :
            certificateNumber == "8396" ?
                HsCodes8396Choices :
            certificateNumber == "8367" ?
                HsCodes8367Choices :
            certificateNumber == "8325" ?
                HsCodes8325Choices :
            certificateNumber == "8370" ?
                HsCodes8370Choices :
            certificateNumber == "8369" ?
                HsCodes8369Choices :
            certificateNumber == "8368" ?
                HsCodes8368Choices :
            certificateNumber == "8360" ?
                HsCodes8360Choices :
            certificateNumber == "8371" ?
                HsCodes8371Choices :
            certificateNumber == "8383" &&
            HsCodes8383Choices;
}