import CertificateNumberService from "../../services/CertificateNumberService";
import {certificateData, certificateNumbers, certificateServices, certificateVersions, certificateGenerationExclusions} from "./CertificateFormMap"

export function GetCertificateNumbers() {
    return certificateNumbers;
}

export function GetCertificateVersions() {
    return certificateVersions;
}

export function GetCertificateGenerationExclusions() {
    return certificateGenerationExclusions;
}

export function GetValidSelections(certificateNumberList) {
    CertificateNumberService.getAll()
        .then((response) => {
            return response.data.filter(x => certificateNumberList.includes(x.number.toString()));
        })
        .catch((e) => {
            return null;
            console.log(e);
        })
}

export async function GetCertificateFormData(productId, selectedCertificateNumbers, isCheckOnly = false){
    if(selectedCertificateNumbers ==null){
        return;
    }
    var res;
    //Map each cert in list with data
    await Promise.all(selectedCertificateNumbers.map(async (cert) => {
        var certificateService = certificateServices.find(item => item.number === cert.number)
        await getCert(cert, certificateService.service, productId);
      }));


    if(isCheckOnly){
        return certificateNumbers;
    }

    return certificateData;
}

export function GetCertificateFormVersion(certNumber){
    if(certNumber ==null){
        return;
    };
    var found = GetCertificateVersions().find(x=>x.number == certNumber);
    if (found !==null && found !==undefined){
        return found.version;
    }
}

async function getCert(cert, service, productId){
    await service.getByProductId(productId)
    .then(response => {
        if(response.data !== null && response.data !== ''){
            certificateData[cert.number] = response.data;
            certificateNumbers[cert.number] =  true;
            // certificateData = test;
            // certificateNumbers = test2;

            return response.data;
        }
    })
}