import *  as baseService  from "./BaseService";

const getAll = (params) => {
    return baseService.getApiData("/site/GetAll");
};

const getAllPaged = (params) => {
    return baseService.getApiDataWithParams("/site/GetAllPaged", params);
};
const getById = (id) => {
    return baseService.getApiData("/site/GetById?id=" + id);
};
const getWithEstablishmentNumberType = (id) => {
    return baseService.getApiData("/site/GetByIdWithEstablishmentType?id=" + id);
};
async function create (data) {
    return await baseService.postApiData("/site/create", data);
};
const update = (id, data) => {
    return baseService.putApiData("/site/update?id=" + id, data);
};
const SiteService = {
    getAll,
    getAllPaged,
    getById,
    getWithEstablishmentNumberType,
    create,
    update
};

export default SiteService;