import React, {useEffect, useState} from 'react';
import {  useFormikContext } from 'formik';
import {  Button,Card, Col, Row } from "@themesberg/react-bootstrap";
import CertificateFormPanels from '../../../../CertificateForms/CertificateFormPanels';

export default function ResponseAttestationNonGEFSProductInfo(props) {
  const { values: formValues } = useFormikContext();
  const {
    formField: {
      formsComplete
    },
    attestationId,
    productId,
    attestationData,
    setFieldValue,
    initialInspectionDate,
    productDetail,
  } = props;
  
  const [isComplete, setComplete] = useState(false);
  const productData = (childdata) => {
      setComplete(childdata);
      if(childdata==true){
        setFieldValue("formsComplete",true)}
      }
  

  useEffect(() => {
    {formValues.dateInspection===""&&  setFieldValue("dateInspection",initialInspectionDate)}
  },[initialInspectionDate]);

  return (
    <Row className="justify-content-left mt-4">
      <Col xl={11}>
        <Card border="light" className="bg-white shadow-sm mb-4 p-3">
          <Card.Body>
            <h6>
              To proceed in issuing a Non GEFS support attestation, please complete the required forms below. <br /> Once these have been completed you can continue to the next section. 
            </h6>
            <hr />
            <CertificateFormPanels 
              col={12}
              productData={productData}
              attestationId={attestationId}
              isGEFS={false}
              isStorageDeclaration={false}
              productDetail={productDetail}
              productId={productId}
              visibility={"Active"}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
