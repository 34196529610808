export const HsCodes8350Choices = [
    { value: "15.17", label: "15.17" },
    { value: "15.18", label: "15.18" },
    { value: "16.01", label: "16.01" },
    { value: "16.01 00", label: "16.01 00" },
    { value: "16.02", label: "16.02" },
    { value: "16.03", label: "16.03" },
    { value: "16.03 00", label: "16.03 00" },
    { value: "16.04", label: "16.04" },
    { value: "16.05", label: "16.05" },
    { value: "17.02", label: "17.02" },
    { value: "17.04", label: "17.04" },
    { value: "18.06", label: "18.06" },
    { value: "19.01", label: "19.01" },
    { value: "19.02", label: "19.02" },
    { value: "19.04", label: "19.04" },
    { value: "19.05", label: "19.05" },
    { value: "20.01", label: "20.01" },
    { value: "20.04", label: "20.04" },
    { value: "20.05", label: "20.05" },
    { value: "21.01", label: "21.01" },
    { value: "21.03", label: "21.03" },
    { value: "21.04", label: "21.04" },
    { value: "21.05", label: "21.05" },
    { value: "21.05 00", label: "21.05 00" },
    { value: "21.06", label: "21.06" },
    { value: "22.02", label: "22.02" },
    { value: "22.08", label: "22.08" }
];


export const HsCodes8468Choices = [
    { value: "04.01", label: "04.01" },
    { value: "04.02", label: "04.02" },
    { value: "04.03", label: "04.03" },
    { value: "04.04", label: "04.04" },
    { value: "04.05", label: "04.05" },
    { value: "04.06", label: "04.06" },
    { value: "15.17", label: "15.17" },
    { value: "17.02", label: "17.02" },
    { value: "19.01", label: "19.01" },
    { value: "21.05", label: "21.05" },
    { value: "21.06", label: "21.06" },
    { value: "22.02", label: "22.02" },
    { value: "28.35", label: "28.35" },
    { value: "35.01", label: "35.01" },
    { value: "35.02", label: "35.02" },
    { value: "35.04", label: "35.04" }
];

export const HsCodes8361Choices = [
    { value: "0301", label: "0301" },
    { value: "0302", label: "0302" },
    { value: "0303", label: "0303" },
    { value: "0304", label: "0304" },
    { value: "0305", label: "0305" },
    { value: "0306", label: "0306" },
    { value: "0307", label: "0307" },
    { value: "0308", label: "0308" },
    { value: "0511", label: "0511" },
    { value: "1504", label: "1504" },
    { value: "1516", label: "1516" },
    { value: "1518", label: "1518" },
    { value: "1603", label: "1603" },
    { value: "1604", label: "1604" },
    { value: "1605", label: "1605" },
    { value: "2106", label: "2106" },
];
export const HsCodes8383Choices = [
    { value: "02.07", label: "02.07" },
    { value: "02.10", label: "02.10" },
    { value: "16.01", label: "16.01" },
    { value: "16.02", label: "16.02" },
];
export const HsCodes8399Choices = [
    { value: "2106", label: "2106" },
    { value: "2906", label: "2906" },
    { value: "2907", label: "2907" },
    { value: "2922", label: "2922" },
    { value: "2930", label: "2930" },
    { value: "2932", label: "2932" },
    { value: "2936", label: "2936" },
    { value: "3503", label: "3503" },
    { value: "3507", label: "3507" },
    { value: "3913", label: "3913" },
];

export const HsCodes8390Choices = [
    { value: "3503", label: "3503" },
];
export const HsCodes8396Choices = [
    { value: "3504", label: "3504" },
    { value: "3917", label: "3917" },
];
export const HsCodes8391Choices = [
    { value: "0409", label: "0409" },
    { value: "0410", label: "0410" },
    { value: "0510", label: "0510" },
    { value: "1212", label: "1212" },
    { value: "1521", label: "1521" },
    { value: "1702", label: "1702" },
    { value: "2106", label: "2106" },
];
export const HsCodes8367Choices = [
    { value: "02.01", label: "02.01" },
    { value: "02.02", label: "02.02" },
    { value: "02.04", label: "02.04" },
    { value: "02.06", label: "02.06" },
    { value: "02.08.90", label: "02.08.90" },
    { value: "05.04", label: "05.04" },
];
export const HsCodes8325Choices = [
    { value: "05.11", label: "05.11" },
    { value: "23.09", label: "23.09" },
    { value: "41.01", label: "41.01" },
    { value: "42.05", label: "42.05" },
];
export const HsCodes8370Choices = [
    { value: "02.03", label: "02.03" },
    { value: "02.06", label: "02.06" },
    { value: "02.09", label: "02.09" },
    { value: "05.04", label: "05.04" },
    { value: "15.01", label: "15.01" },
];
export const HsCodes8369Choices = [
    { value: "02.04", label: "02.04" },
    { value: "02.06", label: "02.06" },
    { value: "05.04", label: "05.04" },
    { value: "15.02", label: "15.02" },
];
export const HsCodes8368Choices = [
    { value: "02.01", label: "02.01" },
    { value: "02.02", label: "02.02" },
    { value: "02.06", label: "02.06" },
    { value: "05.04", label: "05.04" },
    { value: "15.02", label: "15.02" },
];
export const HsCodes8360Choices = [
    { value: "04.07", label: "04.07" },
    { value: "04.08", label: "04.08" },
    { value: "21.06", label: "21.06" },
    { value: "35.02", label: "35.02" },
    { value: "35.07", label: "35.07" },
];
export const HsCodes8371Choices = [
    { value: "02.07", label: "02.07" },
    { value: "02.08", label: "02.08" },
    { value: "05.04", label: "05.04" },
];