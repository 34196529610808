import { Routes } from '../../../routes';
import { Card, Col, Row, Button, Spinner, Modal } from "@themesberg/react-bootstrap";
import { Formik, Form } from "formik";
import FormController from "../../../pages/components/common/FormController";
import React, { useState } from 'react';
import BreadcrumbNav from '../../../components/BreadcrumbNav';
import AIService from '../../services/AIService';

const AIAdmin  = (props) => {
    
    const title = "AI"
    const breadcrumbData = {
        item1 : {name:'Admin', link: Routes.AIAdmin.path},
        item2 : {name:'AI', link: '#'},
    }
    const [result, setResult] = useState('');
    const [loading, setLoading] = useState(false);
    const handleOpenModalAdd = () => setShowModalAdd(true);
    const handleCloseModalAdd = () => setShowModalAdd(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    
    //Get all scrape all filtered links
    const getTestTextResult = (params = {}) => {
        setResult("");
        setLoading(true);
        AIService.getTestTextResult()
            .then((response) => {
                setResult(response.data);
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    //Get all scrape all filtered links
    const getTestTextParamsResult = () => {
        setResult("");
        setLoading(true);
        AIService.getTestTextParamsResult(["ai"])
            .then((response) => {
                setResult(response.data);
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    const initialValues = {
        imageFile: ''
    }
    function onSubmit(values, { setSubmitting, resetForm } ) {
        resetForm({})
        setLoading(true);
        setResult("");
        handleCloseModalAdd();
        setTimeout(() => {
            const formData = new FormData();
            for (let i = 0; i <= values.imageFile.length; i++) {
                formData.append(`imageFile`, values.imageFile[i]);
            }

            AIService.getTestImageResult(formData)
                .then(response => {
                    setResult(response.data);
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    setLoading(false);
                });
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    }

    return (
        <div className="">
            <BreadcrumbNav data={breadcrumbData}/>
            <div className="">
                <Row className='row'>
                    <Col xs={12} sm={12} lg={12} className="text-start">
                        <h2 id="tabelLabel" >{title}</h2>
                    </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12} sm={12} lg={12} className="text-start">


                            <Button variant="primary" onClick={getTestTextResult}>Test (Text)</Button>
                            <Button variant="primary" className="ms-2" onClick={getTestTextParamsResult}>Test (Text with Params)</Button>
                            <Button variant="primary" className="ms-2" onClick={handleOpenModalAdd}>Test (Image)</Button>




                            <Modal size="md" as={Modal.Dialog} centered show={showModalAdd} onHide={handleOpenModalAdd}>
                                <Modal.Header>
                                    <Modal.Title className="h6">Upload Image</Modal.Title>
                                    <Button variant="close" aria-label="Close" onClick={handleCloseModalAdd} />
                                </Modal.Header>
                                <Modal.Body>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={onSubmit}
                                    >
                                    {(props, isSubmitting) => (
                                        <Form>
                                            <Row className="mt-0">
                                                <Col md={12}>
                                                    <FormController
                                                        control="multipleFileUpload"
                                                        allowedFileTypes = "image/*"
                                                        label="Select image to upload"
                                                        name="imageFile"
                                                        className="mb-3"
                                                        multiple={false}
                                                    />
                                                    {JSON.stringify(props.values)}
                                                    <div className="form-group mt-3">
                                                        <Button className="btn-block" type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Upload"}</Button>
                                                    </div>  
                                                </Col>
                                            </Row>
                                        </Form>
                                        )}
                                    </Formik >
                                </Modal.Body>
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                    <Col xs={12} sm={12} lg={12} className="text-start">
                        <Card >
                            <Card.Header>
                                <Row>
                                    <Col xs={12} sm={12} lg={12} className="text-start">
                                        <h5>AI Result</h5>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} sm={12} lg={12} className="text-start">
                                            {loading &&
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Sending AI Request...</span>
                                                </Spinner>
                                            }

                                            {!loading && 
                                                result == ''?
                                                    <p>No Results</p>
                                                :
                                                <p>{result}</p>
                                            }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AIAdmin;