export const health_1_5_statements = [
    { group: "gelatine15Choices", fieldname: "health_1_5_statements", value: "1", key: "from <strong><u>animals</strong></u> which have been found fit for human consumption following ante-mortem and post-mortem inspections;" },
    { group: "gelatine15Choices", fieldname: "health_1_5_statements",  value: "2", key: "from <strong><u>wild game</strong></u> which has been found fit for human consumption following post-mortem inspection;" },
    { group: "gelatine15Choices", fieldname: "health_1_5_statements",  value: "3", key: "from <strong><u>fishery products</strong></u> that comply with Section VIII of Annex III to Regulation (EC) No 853/2004;" }
]

export const health_1_6 = { 
    label: "in the case of gelatine of bovine, ovine and caprine <strong><u>animal origin</strong></u>, and except for gelatine derived from hides and skins," 
}
export const BseRiskCategoryNegligibleStatementsChoice = [
    {group: "", fieldname: "BseNegligible1", value: "1", key: "the animals from which the gelatine are derived were born, continuously reared and slaughtered in a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>negligible BSE risk</u> in which there have been no BSE indigenous cases" },
    {group: "", fieldname: "BseNegligible2", value: "2", key: "the animals from which the gelatine are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>negligible BSE risk</u> in which there has been at least one BSE indigenous case, and the gelatine do not contain and are not derived from mechanically separated meat obtained from bones of bovine, ovine and caprine animals" },
    {group: "", fieldname: "BseNegligible3", value: "3", key: "the animals from which the gelatine are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a <u>controlled BSE risk</u> and <ol className='list-clauses'><li>the gelatine do not contain and are not derived from specified risk material as defined in point 1 of Annex V to Regulation(EC) No 999 / 2001 of the European Parliament and of the Council</li><li>the gelatine do not contain and are not derived from mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li><li>the animals from which the gelatine are derived were not slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod - shaped instrument introduced into the cranial cavity</li></ol>" },
    {group: "", fieldname: "BseNegligible4", value: "4", key: "the animals from which the gelatine are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing an <u>undetermined BSE risk</u> and: <ol className='list-clauses'><li>the gelatine do not contain and are not derived from specified risk material as defined in point 1 of Annex V to Regulation(EC) No 999 / 2001 </li> <li>the gelatine do not contain and are not derived from mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li> <li>the animals from which the gelatine are derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod - shaped instrument introduced into the cranial cavity</li> <li>the animals from which the gelatine are derived have not been fed with meat - and - bone meal or greaves, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health</li> <li>the gelatine were produced and handled in a manner which ensures that they do not contain and were not contaminated with nervous and lymphatic tissues exposed during the deboning process</li></ol>" },
]
export const BseRiskCategoryControlledStatementsChoice = [
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: "1", disabled: "disabled", key: "the animals from which the gelatine are derived have not been slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rod- shaped instrument introduced into the cranial cavity;" },
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: "2", disabled: "disabled", key: "the gelatine do not contain and are not derived from<ol className='list-clauses'><li>specified risk material as defined in point 1 of Annex V to Regulation(EC) No 999/2001;</li><li>mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li>" },
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: "3", key: "the animals from which the gelatine are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing a negligible or a controlled risk" },
    {group: "", fieldname: "BseRiskCategoryControlledStatements",  value: "4", key: "the animals from which the gelatine are derived originate from a country or region classified in accordance with Decision 2007/453/EC as a country or region posing an undetermined BSE risk and<ol className='list-clauses'><li>the animals from which the gelatine are derived have not been fed with meat-and-bone meals or greaves, as defined in the Terrestrial Animal Health Code of World Organisation for Animal Health</li><li>the gelatine were produced and handled in a manner which ensures that they do not contain and were not contaminated with nervous and lymphatic tissues exposed during the deboning process</li></ol>"}
]
export const BseRiskCategoryUndeterminedStatementsChoice = [
    {group: "", fieldname: "BseUndetermined",  value: 1, disabled:"disabled", key: "the animals from which the gelatine are derived have not been <ol className= 'list-clauses'><li>slaughtered after stunning by means of gas injected into the cranial cavity or killed by the same method or slaughtered by laceration after stunning of central nervous tissue by means of an elongated rodshaped instrument introduced into the cranial cavity;</li><li>fed meat-and-bone meal or greaves derived from ruminants, as defined in the Terrestrial Animal Health Code of the World Organisation for Animal Health</li></ol>"},
    {group: "", fieldname: "BseUndetermined",  value: 2,  disabled:"disabled", key: "the gelatine do not contain and are not derived from<ol className='list-clauses'><li>specified risk material as defined in point 1 of Annex V to Regulation (EC) No 999/2001</li><li>mechanically separated meat obtained from bones of bovine, ovine and caprine animals</li><li>nervous and lymphatic tissues exposed during the deboning process</li>" },
]

