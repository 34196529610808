import React from 'react';
import EstablishmentsService from '../../pages/services/EstablishmentsService';
import EstablishmentSelect from './Base/EstablishmentSelect';

const EggEstablishmentSelect = (props) => {
  const { 
    selections, 
    name, 
    label, 
    setEstablishmentSelected, 
    selectedFieldName, 
    isAddressRequired = false, 
    isMulti, 
    getOptionValue, 
    getOptionLabel, 
    isRequired, 
    countries, 
    species, 
    certificateNumber,
    readOnly
  } = props;

   const fetchOptions = async (text, page, size, countries, certificateNumber, species) => {
    var request = EstablishmentsService.buildRequest(text, page, size, countries, certificateNumber, species);
      const response = await EstablishmentsService.getEggEstablishments(request);
      return await response.data;
  };

  return (
    <>
    <EstablishmentSelect
      setEstablishmentSelected={setEstablishmentSelected}
      selectedFieldName={selectedFieldName}
      isAddressRequired={isAddressRequired}
      selections={selections}
      countries={countries}
      species={species}
      certificateNumber={certificateNumber}
      name={name}
      label={label}
      onBlur={props.setFieldTouched}
      isMulti={isMulti}
      fetchOptions={fetchOptions}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      isClearable={true} 
      isRequired={isRequired}
      defaultValue=""
      customNoOptionsMessage="Start typing a name or approval number."
      customNoResultsMessage="No establishments found."
      backspaceRemovesValue={true}
      components={{ ClearIndicator: null }}
      readOnly={readOnly}
    />

    </>
  );
};

export default EggEstablishmentSelect;
