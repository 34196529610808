
export const dairyParentStatement = [
    { group: "dairyBOriginatesIn", fieldname: "dairyBOriginatesIn", value: "1", key: "Is your Dairy Product processed from <strong><u>Raw milk</strong></u> at your establishment? <span class=\"text-muted\">(Select this option if you are producing a dairy product at your site from raw milk)</span>" },
    { group: "dairyBOriginatesIn", fieldname: "dairyBOriginatesIn", value: "2", key: "Do you add <strong><u>processed</strong></u> Dairy Product in addition to the Dairy Product you process at your site? <span class=\"text-muted\">(Select this option if you are bringing in processed dairy products from another site and processing them at your site e.g. blending received cheeses processed by extrusion)</span>" },

]

export const dairyProductStatementsChoice = [
    { group: "dairyProduct", fieldname: "dairyProduct", value: "1", key: "the zone referred to in point II.2.1. (above)" },
    { group: "dairyProduct", fieldname: "dairyProduct", value: "2", key: "<strong><u>the zone(s) (selected below)</strong></u> which, at the date of issue of this animal health/official certificate is/are authorised for the entry into the Union of milk and listed in Part 1 of Annex XVII to Implementing Regulation (EU) 2021/404, and that raw milk complied with all the relevant requirements for the entry into the Union of raw milk laid down in Delegated Regulation (EU) 2020/692 and, therefore, was eligible for the entry into the Union as such upon arrival in the zone referred to under point II.2.1." },
    { group: "dairyProduct", fieldname: "dairyProduct", value: "3", key: "<strong><u>a Member State</strong></u>" },

]


export const dairySpecies = [
    { group: "dairySpecies1", fieldname: "dairySpecies1", value: 1, key: "Bos Taurus" },
    { group: "dairySpecies2", fieldname: "dairySpecies2", value: 2, key: "Ovis aries" },
    { group: "dairySpecies3", fieldname: "dairySpecies3", value: 3, key: "Capra hircus" },
    { group: "dairySpecies4", fieldname: "dairySpecies4", value: 4, key: "Bubalus bubalis" },
    { group: "dairySpecies5", fieldname: "dairySpecies5", value: 5, key: "Camelus dromedarius" },
]
export const dairyCMilkChoices = [
    { group: "dairyCMilkType1", fieldname: "dairyCMilkType1", value: 1, key: "Bos Taurus" },
    { group: "dairyCMilkType2", fieldname: "dairyCMilkType2", value: 2, key: "Ovis aries" },
    { group: "dairyCMilkType3", fieldname: "dairyCMilkType3", value: 3, key: "Capra hircus" },
    { group: "dairyCMilkType4", fieldname: "dairyCMilkType4", value: 4, key: "Bubalus bubalis" },
    { group: "dairyCMilkType5", fieldname: "dairyCMilkType5", value: 5, key: "Camelus dromedarius" },
]
export const dairyCMilkChoices2 = [
    { group: "dairyC2MilkType1", fieldname: "dairyC2MilkType1", value: 1, key: "Bos Taurus" },
    { group: "dairyC2MilkType2", fieldname: "dairyC2MilkType2", value: 2, key: "Ovis aries" },
    { group: "dairyC2MilkType3", fieldname: "dairyC2MilkType3", value: 3, key: "Capra hircus" },
    { group: "dairyC2MilkType4", fieldname: "dairyC2MilkType4", value: 4, key: "Bubalus bubalis" },
    { group: "dairyC2MilkType5", fieldname: "dairyC2MilkType5", value: 5, key: "Camelus dromedarius" },
]

export const dairyBStatementsChoice = [
    { group: "dairyBOriginatesIn", fieldname: "dairyBOriginatesIn", value: "1", key: "the same zone as the <strong><u>zone referred to above</strong></u>" },
    { group: "dairyBOriginatesIn", fieldname: "dairyBOriginatesIn", value: "2", key: "<strong><u>the zone(s) (selected below)</strong></u> which, at the date of issue of this animal health/official certificate is/are listed in Part 1 of Annex XVII to Implementing Regulation (EU) 2021/404 for the entry into the Union of milk, and that raw milk complied with all the relevant requirements for the entry into the Union of raw milk laid down in Commission Delegated Regulation (EU) 2020/692 and, therefore, was eligible for the entry into the Union as such upon arrival in the zone, referred to in the above field (the dairy product originates from)." },
    { group: "dairyBOriginatesIn", fieldname: "dairyBOriginatesIn", value: "3", key: "<strong><u>a Member State</strong></u>" },

]

export const dairyCStatementsChoice = [
    {
        group: "dairyCStatements",
        fieldname: "dairyCStatements",
        value: "1",
        key: "have <u>remained</u> in the zone(s) referred to above in Zone since birth, or for the period of at least 3 months prior to the date of milking",
    },
    {
        group: "dairyCStatements",
        fieldname: "dairyCStatements",
        value: "2",
        key: "were <u>introduced</u> in the zone(s) referred to above in Zone from:"
    }
]

export const dairyC2StatementsChoice = [
    {
        group: "dairyC2Statements",
        fieldname: "dairyC2Statements",
        value: "1",
        key: "another third zone or territory, or zone thereof which is listed for entry into the Union of raw milk, colostrum or colostrum-based products and the animals remained there for the period of at least 3 months prior to the date of milking"
    },
    {
        group: "dairyC2Statements",
        fieldname: "dairyC2Statements",
        value: "2",
        key: "a Member State"
    }
]
export const dairyBStatementsChoice2 = [
    { group: "dairyBOriginatesIn", fieldname: "dairyBOriginatesIn", value: "1", key: "the same zone as the <strong><u>zone referred to above</strong></u>" },
    { group: "dairyBOriginatesIn", fieldname: "dairyBOriginatesIn", value: "2", key: "<strong><u>the zone(s) (selected below)</strong></u> which, at the date of issue of this animal health/official certificate is/are listed in Part 1 of Annex XVII to Implementing Regulation (EU) 2021/404 for the entry into the Union of milk, and that raw milk complied with all the relevant requirements for the entry into the Union of raw milk laid down in Commission Delegated Regulation (EU) 2020/692 and, therefore, was eligible for the entry into the Union as such upon arrival in the zone, referred to in the above field (the dairy product originates from)." },
    { group: "dairyBOriginatesIn", fieldname: "dairyBOriginatesIn", value: "3", key: "<strong><u>a Member State</strong></u>" },

]

export const dairyCStatementsChoice2 = [
    {
        group: "dairyCStatements2",
        fieldname: "dairyCStatements2",
        value: "1",
        key: "have <u>remained</u> in the zone(s) referred to above in Zone since birth, or for the period of at least 3 months prior to the date of milking",
    },
    {
        group: "dairyCStatements2",
        fieldname: "dairyCStatements2",
        value: "2",
        key: "were <u>introduced</u> in the zone(s) referred to above in Zone from:"
    }
]

export const dairyC2StatementsChoice2 = [
    {
        group: "dairyC2Statements2",
        fieldname: "dairyC2Statements2",
        value: "1",
        key: "another third zone or territory, or zone thereof which is listed for entry into the Union of raw milk, colostrum or colostrum-based products and the animals remained there for the period of at least 3 months prior to the date of milking"
    },
    {
        group: "dairyC2Statements2",
        fieldname: "dairyC2Statements2",
        value: "2",
        key: "a Member State"
    }
]