import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faEdit, faEllipsisH, faEye} from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Dropdown} from '@themesberg/react-bootstrap';
import BlobService from "../../../../services/BlobService"
import { Routes } from "../../../../../routes";
import EhcGenerateService from '../../../../services/EhcGenerateService';
import SelectionEditModal from './SelectionEditModal';

const SelectionRowMenu = (props) => {
    const {
        isGEFS,
        isStorageDeclaration,
        certificateFilename,
        isCompound,
        compound,
        disableCombine,
        selection,
        selections,
        combinations,
        isApproved,
        _handleCombineCertificateMode,
        isCombineGroup = false,
        removeCombineGroupSelectItem,
        setFormSubmitted
    } = props;

    const [showModal, setShowModal] = useState(false);

    //Open Pdf from Blob Storage
    const openBlobPdf = (filename) => {
        BlobService.openBlobPdf(filename);
    }

    //Edit details
    const openDetailEditor = (consignmentContentsId) => {
        setShowModal(true);
    };
    //Open View Attestation
     const _viewDailyAttestation = (id) => {
        window.open(Routes.DailyAttestationViewPage.path.replace(":id", id));
    };

    //Open Certificate Editor
    const _openCertificateEditor = (consignmentContentsId) => {
        window.open(Routes.CertificateEditor.path.replace(":consignmentContentsId", consignmentContentsId));
    };

    //Open View Attestation
    const _viewProduct = (id) => {
        window.open(Routes.Product.path.replace(":id", id));
    };
    
    // const test =async (consignmentContentsId)  =>{
    //     const response = await EhcGenerateService.generateCompoundCertificate(consignmentContentsId, 0);
    //     return await response.data;
    // }
    // <Button onClick={()=>{}}></Button>

    return(
        <>
            <Dropdown as={ButtonGroup} >
                <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm">
                    <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className=" dropdown-menu-ontop ">
                    
                    {!isApproved &&
                    <>
                        <Dropdown.Item onClick={()=>openDetailEditor(selection.consignmentContentsId)}>
                            <FontAwesomeIcon icon={faEdit} className="dropdown-item-icon" /> 
                            Edit Product Details 
                        </Dropdown.Item> 

                        <SelectionEditModal
                            combinations={combinations}
                            selection={selection}
                            selections={selections}
                            showModal={showModal} 
                            setShowModal={setShowModal} 
                            isGEFS={isGEFS}
                            isStorageDeclaration={isStorageDeclaration}
                            setFormSubmitted={setFormSubmitted}
                        />
                    </>
                    }

                    {isGEFS ?
                        <>
                            <Dropdown.Item onClick={()=> _viewDailyAttestation(selection.dailyAttestationItems?.dailyAttestationId)}>
                                <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" /> 
                                View Daily Attestation
                            </Dropdown.Item>
                            <Dropdown.Item onClick={()=> _viewProduct(selection.attestation?.productId)}>
                                <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" /> 
                                View Product
                            </Dropdown.Item>
                        </>
                    :
                        <>
                            <Dropdown.Item onClick={()=> _viewProduct(isStorageDeclaration? selection.dailyAttestationItems.productId : selection.attestation?.productId)}>
                                <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" /> 
                                View Product
                            </Dropdown.Item>
                            {isStorageDeclaration ?
                                <Dropdown.Item onClick={()=> {openBlobPdf(selection.dailyAttestationItems.storageSupplierDeclaration.signedDocument.filename)}} >
                                    <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" /> 
                                    View Supplier declaration
                                </Dropdown.Item>
                            :
                                <Dropdown.Item onClick={()=> {openBlobPdf(selection.attestation.attestationResponse.signedDocument.filename)}} >
                                    <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" /> 
                                    View Attestation
                                </Dropdown.Item>
                            }
                        </>
                    }
                    {!isApproved && !isCompound &&
                    <>
                        {isCombineGroup ?
                            <Dropdown.Item onClick={()=>{
                                var masterid = selection.combinations[0]?.consignmentContentsMasterId
                                if(masterid!== undefined){
                                    removeCombineGroupSelectItem(masterid)
                                }
                            }
                            }
                            >
                                <FontAwesomeIcon icon={faClone} className="dropdown-item-icon" /> 
                                Uncombine certificates
                            </Dropdown.Item>
                        :
                            <Dropdown.Item 
                                className={disableCombine?"disabled" : ""} 
                                onClick={()=> _handleCombineCertificateMode(selection)}
                            >
                               
                               <FontAwesomeIcon icon={faClone} className="dropdown-item-icon" /> 
                                Combine certificate 
                            </Dropdown.Item>
                        }
                        </>
                    }
            
                    {selection.certificateFilename !== null && selection.certificateFilename !== undefined &&
                        <div>
                            <Dropdown.Item onClick={()=>openBlobPdf(certificateFilename)}>
                                <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" />
                                View Certificate
                            </Dropdown.Item>
                            {!isApproved && (
                                selection.combinations?.find(y=>y.consignmentContentsId == selection.consignmentContentsId) ? (
                                    <>
                                        <Dropdown.Item onClick={()=>_openCertificateEditor(selection.combinations?.find(y=>y.consignmentContentsId == selection.consignmentContentsId).consignmentContentsMasterId)}>
                                            <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" /> 
                                            Edit Certificate 
                                        </Dropdown.Item> 
                                    </>
                                ):(
                                    <>
                                        <Dropdown.Item onClick={()=>_openCertificateEditor(selection.consignmentContentsId)}>
                                            <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" /> 
                                            Edit Certificate 
                                            {/* ({x.certificates.filter(w=>w.documentId == y?.id)[0].certificateNumber})  */}
                                        </Dropdown.Item> 
                                    </>
                                ))
                            }
                        </div>
                    }
                </Dropdown.Menu>
                </Dropdown>
        </>
    )
};

export default SelectionRowMenu
