import * as DogChews8325Labels from "../DogChews8325Labels"
import { findArrayElementByValue } from "../../../Attestations/Common/AttestationFunctions";
import React from 'react';
export const AttestationContent = (props) => {
    const { attestationData } = props;
    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);
    return (
        <>
            <h5>II. Health Attestation</h5>
            <>
                <p>
                    I, the undersigned, declare that I have read and understood Regulation (EC) No 1069/2009 of
                    the European Parliament and of the Council, and in particular Article 10 of that Regulation, and Commission
                    Regulation (EU) No 142/2011, and in particular Chapter Il of Annex Xlll and Chapter Il of Annex XIV thereto,
                    and certify that the dogchews described above:
                </p>
                <ol className="attestation-II_1">
                    <li>
                        have been prepared exclusivley with the following animal by-products:
                        <ul>
                            {attestationData.health_1_1 && (
                                <p>
                                    {findArrayElementByValue(DogChews8325Labels.health_1_StatementsChoice, 1)}
                                </p>
                            )}
                            {attestationData.health_1_2 && (
                                <p>
                                    {findArrayElementByValue(DogChews8325Labels.health_1_StatementsChoice, 2)}
                                    {attestationData.health_1_2 && (
                                        <ul>
                                            <ul>
                                                {attestationData.health_1_2_A && (
                                                    <p>
                                                        {findArrayElementByValue(DogChews8325Labels.health_1_2_StatementsChoice, 1)}
                                                    </p>
                                                )}
                                                {attestationData.health_1_2_B && (
                                                    <p>
                                                        {findArrayElementByValue(DogChews8325Labels.health_1_2_StatementsChoice, 2)}
                                                    </p>
                                                )}
                                                {attestationData.health_1_2_C && (
                                                    <p>
                                                        {findArrayElementByValue(DogChews8325Labels.health_1_2_StatementsChoice, 3)}
                                                    </p>
                                                )}
                                                {attestationData.health_1_2_D && (
                                                    <p>
                                                        {findArrayElementByValue(DogChews8325Labels.health_1_2_StatementsChoice, 4)}
                                                    </p>
                                                )}
                                                {attestationData.health_1_2_E && (
                                                    <p>
                                                        {findArrayElementByValue(DogChews8325Labels.health_1_2_StatementsChoice, 5)}
                                                    </p>
                                                )}
                                            </ul>

                                        </ul>
                                    )}
                                </p>
                            )}
                            {attestationData.health_1_3 && (
                                <p>
                                    {findArrayElementByValue(DogChews8325Labels.health_1_StatementsChoice, 3)}
                                </p>
                            )}
                            {attestationData.health_1_4 && (
                                <p>
                                    {findArrayElementByValue(DogChews8325Labels.health_1_StatementsChoice, 4)}
                                </p>
                            )}
                            {attestationData.health_1_5 && (
                                <p>
                                    {findArrayElementByValue(DogChews8325Labels.health_1_StatementsChoice, 5)}
                                </p>
                            )}
                            {attestationData.health_1_6 && (
                                <p>
                                    {findArrayElementByValue(DogChews8325Labels.health_1_StatementsChoice, 6)}
                                </p>
                            )}
                            {attestationData.health_1_7 && (
                                <p>
                                    {findArrayElementByValue(DogChews8325Labels.health_1_StatementsChoice, 7)}
                                </p>
                            )}
                        </ul>
                    </li>
                </ol>
                <ol className="attestation-II_2">
                    <li>
                        have been subjected
                        <ul>
                            {attestationData.health_2_1 && (
                                <p>
                                    {findArrayElementByValue(DogChews8325Labels.health_2_StatementsChoice, 1)}
                                </p>
                            )}
                            {attestationData.health_2_2 && (
                                <p>
                                    {findArrayElementByValue(DogChews8325Labels.health_2_StatementsChoice, 2)}
                                </p>
                            )}
                        </ul>

                    </li>
                </ol>
                <ol className="attestation-II_3">
                    <li>
                        were examined by random sampling of at least five samples from each processed batch taken during or after
                        storage at the processing plant and complies with the following standards:<br></br><br></br>
                        Salmonella:   absence in 25g: n = 5, c = O, m = O, M = O,<br></br>
                        Enterobacteriaceae:  n = 5, c = 2, m= 10, M= 300 in 1 gramme;
                    </li>
                </ol>
                <ol className="attestation-II_4">
                    <li>
                        have undergone all precautions to avoid contamination with pathogenic agents after treatment;
                    </li>
                </ol>
                <ol className="attestation-II_5">
                    <li>
                        were packed in new packaging;
                    </li>
                </ol>
                {(attestationData.health_6_1_statements.includes("1") || attestationData.health_6_1_statements.includes("2")) && (
                    <ol className="attestation-II_6">
                        <li>
                            the dogchews described above
                            <ul>
                                {attestationData.health_6_1_statements.map(statement =>
                                    <p>
                                        {findArrayElementByValue(DogChews8325Labels.health_6_1_statementsChoice, statement)}
                                    </p>
                                )}
                                {attestationData.health_6_2_statements.map(statement =>
                                    <p>
                                        {findArrayElementByValue(DogChews8325Labels.health_6_2_statementsChoice, statement)}
                                    </p>
                                )}
                            </ul>
                        </li>
                    </ol>
                )}

                {/* <PrettyPrintJson data={attestationData} >  </PrettyPrintJson> */}
                {/* <PrettyPrintJson data={compositeInfo} >  </PrettyPrintJson>
            <PrettyPrintJson data={speciesList} >  </PrettyPrintJson>  */}
            </>
        </>
    )
}

