import React, { useState, useEffect} from 'react';
import { Formik, Form, useFormikContext} from 'formik';
import FormController from "../../../../components/common/FormController"
import { Button, Col, Row, Modal, CloseButton } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress, faFilter, faTextHeight, fa} from '@fortawesome/free-solid-svg-icons';

const AddStampModal =(props) =>{
    const {totalPages, pageNumber,rect, show, handleDataObjectAdd, showModalAdd, handleCloseModalAdd, scale, className} = props;
    const { values: formValues, setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
    const [data, setData] = useState(props.data);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        setData(data); //Pass value from parent if it has one
      }, []);


    //   useEffect(() => {

    //     var pageList = [];
    //     for(var i=0; i < totalPages; i++){
    //         var pageNumber = i + 1;
    //         pageList.push(parseInt(pageNumber));
    //     }
    //     setPages(pageList);
    //   }, [totalPages]);

    var initialValues = ({
        fieldName: '',
        dataPath: '',
        equalsValue: '',
        defaultValue: '',
        left: rect.startX ?? 0,
        top: rect.startY ?? 0,
        width: rect.w ?? 0,
        height: Math.round(rect.h * 3.82) ?? 0,
        marginTop: 0,
        groupId: '',
        page: pageNumber,

    })

    function onSubmit(values, { setSubmitting, resetForm } ) {
        setTimeout(() => {
            var lineStamps = formValues?.lineStamps;
            if(lineStamps !== undefined && lineStamps !== null){
                var obj = 
                {
                    fieldName: values?.fieldName?.replaceAll(' ', ''),
                    page: pageNumber,
                    dataPath: values?.dataPath,
                    equalsValue: values?.equalsValue,
                    height: rect?.h,
                    marginTop: values?.marginTop,
                    width: rect?.w,
                    top: rect.startY,
                    left: rect.startX,
                    value: "",
                    groupId: values?.groupId,
                    defaultValue: values?.defaultValue
                }

                lineStamps[values.fieldName?.replaceAll(' ', '')] = true;
                lineStamps[values.fieldName?.replaceAll(' ', '') + "Top"] = values.top;
                lineStamps[values.fieldName?.replaceAll(' ', '') + "Width"] = values.width;
                lineStamps[values.fieldName?.replaceAll(' ', '') + "Height"] = values.height;
                lineStamps[values.fieldName?.replaceAll(' ', '') + "Left"] = values.left;
                lineStamps[values.fieldName?.replaceAll(' ', '') + "MarginTop"] = values.marginTop;
                lineStamps[values.fieldName?.replaceAll(' ', '') + "EqualsValue"]= values.equalsValue;
                lineStamps[values.fieldName?.replaceAll(' ', '') + "DefaultValue"]= values.defaultValue;
                lineStamps[values.fieldName?.replaceAll(' ', '') + "DataPath"]= values.dataPath;
                lineStamps[values.fieldName?.replaceAll(' ', '') + "GroupId"] = values.groupId;
                setFieldValue("lineStamps", lineStamps);
                
                data.lines.push(obj);
                handleDataObjectAdd(data);
                handleCloseModalAdd();
            }
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    }
    return(
        <div className={className + " " + (show? "d-block" : "d-none")}>
       
       {JSON.stringify(rect)}
    
        {/* //Modal - Start*/}
        <Modal size="lg" as={Modal.Dialog} centered show={showModalAdd} onHide={handleCloseModalAdd}>
            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
            >
                {(formik, isSubmitting) => (
                    <Form>
                        <Modal.Header>
                            <Modal.Title className="h5">Add New Stamp</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={handleCloseModalAdd} />
                        </Modal.Header>
                        <Modal.Body>

                            <Row className="mb-3 justify-content-md-center">
                                
                                <Col md={12}>
                                    <h6 className="my-2">Data</h6>
                                    <Row>
                                        <Col md={6}>
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Field name"
                                                placeholder=""
                                                isrequired={"true"}
                                                name={`fieldName`}
                                                col={12}
                                                size="sm"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Default value (optional)"
                                                placeholder=""
                                                name={`defaultValue`}
                                                col={12}
                                                size="sm"
                                            />
                                        </Col>
                                    </Row>
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Data path"
                                        sublabel="JSONPath variable to find data from the object compiled from the database"
                                        placeholder=""
                                        isrequired={"true"}
                                        name={`dataPath`}
                                        size="sm"
                                        col={12}
                                    />
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Equals data value  (optional)"
                                        sublabel="If this stamp should only show when the data returned is equal to a certain value, type it here"
                                        placeholder=""
                                        name={`equalsValue`}
                                        size="sm"
                                        col={4}
                                    />
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Group Id"
                                        sublabel="If this stamp should only show when the data returned is equal to a certain value, type it here"
                                        placeholder=""
                                        name={`groupId`}
                                        isrequired={"true"}
                                        size="sm"
                                        col={4}
                                    />
                                    
                                </Col>

                                <Col md={12}>
                                    <hr />
                                </Col>

                                <Col md={6}>
                                    <h6 className="my-2">Position</h6>
                                    <FormController
                                        control="input"
                                        type="number"
                                        label="Distance from top"
                                        placeholder=""
                                        name={`top`}
                                        size="sm"
                                        col={4}
                                    />
                                    <FormController
                                        control="input"
                                        type="number"
                                        label="Distance from left"
                                        placeholder="Left"
                                        name={`left`}
                                        size="sm"
                                        col={4}
                                    />
                                    <FormController
                                        control="input"
                                        type="number"
                                        label="Added top padding"
                                        placeholder=""
                                        name={`marginTop`}
                                        size="sm"
                                        col={4}
                                    />
                                </Col>
                                <Col md={6}>
                                    <h6 className="my-2">Size</h6>
                                    <FormController
                                        control="input"
                                        type="text"
                                        label="Width"
                                        placeholder=""
                                        name={`width`}
                                        size="sm"
                                        col={4}
                                    />
                                    <FormController
                                        control="input"
                                        type="number"
                                        label="Height"
                                        placeholder="Height"
                                        name={`height`}
                                        size="sm"
                                        col={4}
                                    />
                                </Col>
                            </Row>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                {/* <FontAwesomeIcon icon={faFilter} /> */}
                                {isSubmitting ? "Please wait..." : "Save Stamp"}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik > 
        </Modal>
        {/* //Modal - End */}
    </div>                            
    );
}

export default AddStampModal;