import React, { useState, useEffect } from 'react';
import PositionModal from '../Modals/PositionModal'
import FormController from "../../../common/FormController"
import { Col, Row } from "@themesberg/react-bootstrap";


const LineStampRow = (props) => {
    const {
        stamp, 
        canvas, 
        parentDisabled, 
        setFieldValue
    } = props;

    const drawFillRect = (info, style = {}) => {
        const {x, y, w, h} = info;
        const { backgroundColor = 'black' } = style;
    // dynamically assign the width and height to canvas
    const canvasEle = canvas.current;
    canvasEle.width = canvasEle.clientWidth;
    canvasEle.height = canvasEle.clientHeight;


    // get context of the canvas
    var ctx = canvasEle.getContext("2d");
        ctx.beginPath();
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(x, y, w, h);
    }

    const [isHovering, setHovering] = useState(false);
    const handleMouseOver = () => {
        setHovering(true);
        if(stamp.left > 0 && stamp.top > 0  && stamp.width > 0  && stamp.height > 0 ){

            
            var x = Math.round(stamp.left * 3.17);
            var y = Math.round(stamp.top * 3.16);
            var w = Math.round(stamp.width *3.16);
            var h = Math.round(stamp.height * 0.84);
            //Add larger block for lines
            if(h <=1){
                h = 6;
                y= (y-4);
            }
            //Increase block size
            x = (x -3);
            y = (y - 6);
            h = (h + 12);
            w = (w + 6);

            drawFillRect({ x: x, y: y, w: w, h: h }, 
                { backgroundColor: 'rgba(212, 255, 0, 0.3)' });
        }
    };

    const handleMouseOut = () => {
    //  ctx.clearRect(0, 0, canvas.width, canvas.height);  
    setHovering(false);
    };
    
    useEffect(() => {
        // set the value of textC, based on textA and textB
        var fieldName = stamp.linkedFieldName !== null? 
        `lineStamps.${stamp.linkedFieldName?.replaceAll(" ","")}` 
        : 
        `lineStamps.${stamp.fieldName?.replaceAll(" ", "")}`;
        
        parentDisabled === true &&
          setFieldValue(fieldName, false);
      }, [parentDisabled]);
    
    return(
        <Row onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <Col md={10}>
                <FormController
                    control="checkbox"
                    label={stamp.fieldName}
                    name={stamp.linkedFieldName !== null? `lineStamps.${stamp.linkedFieldName?.replaceAll(" ","")}` : `lineStamps.${stamp.fieldName?.replaceAll(" ", "")}`}
                    className="my-3"
                    disabled={parentDisabled}
                    setFalse={parentDisabled}
                    size="sm"
                />
            </Col>
            <Col md={2}>
                <PositionModal 
                    show={isHovering}
                    namePrefix={`lineStamps.${stamp.fieldName?.replaceAll(" ", "")}`}
                    stamp={stamp}
                    className="mt-1"
                />
            </Col>
        </Row>
    )
}

export default LineStampRow ;