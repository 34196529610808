import * as Yup from 'yup';
import moment from 'moment';
import formModel from './GEFSModel';

const {
  formField: {
    traceability,
    batchNumbers,
    batchRangeFrom,
    batchRangeTo,
    isGEFS,
    containsDairy,
    customUniqueRef,
    productionDate,
    productionDateMultipleStart,
    productionDateMultipleEnd,
    productionDateSingle,
    batchFormat,
    vetId,
    test,
    decision,
    documentId,
    signedRequestUrl
  }
} = formModel;


export default [
  Yup.object().shape({
      [traceability.name]: Yup.string().required(`${traceability.requiredErrorMsg}`),
      [vetId.name]: Yup.object().required(`${vetId.requiredErrorMsg}`),
      [batchRangeFrom.name]: Yup.string().required(`${batchRangeFrom.requiredErrorMsg}`),
      [batchRangeTo.name]: Yup.string().required(`${batchRangeFrom.requiredErrorMsg}`),
      [batchRangeFrom.name]: Yup.string().required(`${batchRangeTo.requiredErrorMsg}`),
      
      // [batchRangeFrom.name]: Yup.string().when([batchFormat.name], (batchFormat) => {
      //   return getFormatValidation(batchFormat?.value);
      // }),
      // [batchRangeTo.name]: Yup.string().when([batchFormat.name, batchRangeFrom.name], (batchFormat, batchFrom) => {
      //   return getFormatValidation(batchFormat?.value, true, batchFrom);
      // }),
      // [batchFormat.name]: Yup.object().shape({
      //   label: Yup.string().required(),
      //   value: Yup.string().required()
      // }),
    }),
    
    // .test('date-range-validation', 'Invalid date range', function (values) {
    //   const { batchFormat, batchRangeFrom, batchRangeTo } = values;
    //   if (batchFormat?.value && ['DateDDMMYY', 'DateDDMMYYYY', 'DateBBEMMDDYY', 'DateDDMMM', 'DateDDDMMMMYYYY'].includes(batchFormat.value)) {
    //     return validateDateRange(batchFormat.value, batchRangeFrom, batchRangeTo);
    //   } else if (batchFormat?.value === 'numeric') {
    //     return parseInt(batchRangeTo, 10) > parseInt(batchRangeFrom, 10);
    //   }
    //   return true;
    // }),
  

  Yup.object().shape({
  [signedRequestUrl.name]: Yup.string().required(`${decision.requiredErrorMsg}`),
  [documentId.name]: Yup.string().required(`${decision.requiredErrorMsg}`),

  })
];

function validateDateRange(format, from, to) {
  const fromDate = moment(from, format);
  const toDate = moment(to, format);

  return fromDate.isValid() && toDate.isValid() && fromDate.isBefore(toDate);
}

function getFormatValidation(format, isToField = false, otherField = '') {
  const validationDateAfter = 'Batch to date should be later than batch from date';
  switch (format) {
    case 'DateDDMMYY':
      return Yup.string().matches(/^\d{2}\/\d{2}\/\d{2}$/, 'Invalid format')
      .test('batch-to-is-later', validationDateAfter, 
        value => isToField ? moment(value, "DD/MM/YY").isAfter(moment(otherField, "DD/MM/YY")) : true
      );
    case 'DateDDMMYYYY':
      return Yup.string().matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Invalid format')
      .test('batch-to-is-later', validationDateAfter, 
        value => isToField ? moment(value, "DD/MM/YY").isAfter(moment(otherField, "DD/MM/YY")) : true
     );
    case 'DateBBEMMDDYY':
      return Yup.string().matches(/^BBE\s[A-Za-z]{3}\d{2}$/, 'Invalid format')
      .test('batch-to-is-later', validationDateAfter, function (value) {
      const datePart = value.slice(2);
      return isToField ? moment(datePart, "DD/MM/YYYY").isAfter(moment(datePart, "DD/MM/YYYY")) : true
    });
    case 'DateDDMMM':
      return Yup.string().matches(/^\d{2}\s[A-Za-z]{3}$/, 'Invalid format');
    case 'DateDDDMMMMYYYY':
      return Yup.string().matches(/^\d{2}(st|nd|rd|th)\s[A-Za-z]{3,}\s\d{4}$/, 'Invalid format');
    case 'julian':
      return Yup.string().matches(/^\d{2}(0[1-9]|[1-9][0-9]|[1-2][0-9][0-9]|3[0-5][0-9]|36[0-6])$/, 'Invalid format')
      .test('is-appropriate', 'Value is not appropriate', 
        value => isToField ? parseInt(value, 10) > parseInt(otherField, 10) : true
      );;
    case 'YYjulian':
      return Yup.string().matches(/^\d{2}\/(0[1-9]|[1-9][0-9]|[1-2][0-9][0-9]|3[0-5][0-9]|36[0-6])$/, 'Invalid Julian date format YY/DDD')
      .test('is-appropriate', 'Value is not appropriate', 
        value => isToField ? parseInt(value, 10) > parseInt(otherField, 10) : true
      );;
    case 'numeric':
      return Yup.number()
    case 'custom':
      return Yup.string().required('Value is required');
    default:
      return Yup.string();
  }

}