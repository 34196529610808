import React, { useEffect, useState, useRef } from 'react';
import SiteDataService from "../../services/SiteService";
import moment from "moment";
import { Card, Button, Col, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit} from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../../routes";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import ManufacturerService from '../../services/ManufacturerService';
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes} from '../../../auth/PermissionsMap';

const SiteViewPage  = (props) => {
    
    const [siteId] = useState(props.match.params.id);
    const [site, setSite] = useState([]);
    const [manufacturerName, setManufacturerName] = useState([]);
   
    useEffect(()=>{ 
        SiteDataService.getWithEstablishmentNumberType(siteId)
        .then(response => {
            setSite(response.data);
            let manufacturerId = response.data.manufacturerId;
            ManufacturerService.getById(manufacturerId)
            .then(response => {
                setManufacturerName(response.data.manufacturerName);
            })
            .catch(e => {
                console.log(e);
            });
            
        })
        .catch(e => {
            console.log(e);
        });
      },[]);

    const openSite = () => {
        props.history.push("/site/edit/" + siteId);
    };

    

    return (
        <div>
            
            <BreadcrumbNav 
                data={{
                    item1 : {name:'Sites', link: Routes.Sites.path},
                    item2 : {name: "Viewing " + site.siteName}}
                }
            />
            
            <>
            <Row>
                <Col >
                    <h2 id="tabelLabel" >{site.siteName}</h2>
                </Col>
            </Row>
            <Row className="">
                <Col md={8} xl={8}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col>
                                    <h4 className="mb-0">Site Details</h4>
                                </Col>
                                <Col className="text-end" md={2}>
                                    {site.tracesId <= 0 &&
                                        <Button onClick={openSite} variant="light" size="sm"> 
                                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                                            <span>Edit</span>
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <ul className="list-unstyled">
                                <li>
                                    <p>
                                        <small className="d-block">Site Name:</small>
                                        <label>{site.siteName!==null && site.siteName!==""?(site.siteName):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Manufacturer Name:</small>
                                        <label>{manufacturerName!==null && site.manufacturerName!==""?(manufacturerName):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Address Line 1:</small>
                                        <label>{site.address1!==null && site.address1!==""?(site.address1):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Address Line 2:</small>
                                        <label>{site.address2!==null && site.address2!==""?(site.address2):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Town / City:</small>
                                        <label>{site.townCity!==null && site.townCity!==""?(site.townCity):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">Postcode:</small>
                                        <label>{site.postcode!==null && site.postcode!==""?(site.postcode):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="d-block">{site.establishmentNumberTypeName}</small>
                                        <label>{site.supplierCode!==null && site.supplierCode!==""?(site.establishmentNumber):("N/A")}</label>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <small className="text-gray-600 text-italic">Created on {moment(site.createdDate).format("Do MMM YYYY")}</small>
                                    </p>
                                </li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <div className="btn-panel left">
                        <Button variant="light" href="/sites">
                            Back to Sites
                        </Button>
                    </div>
                </Col>
            </Row>
            </>
            
        </div>
    )

}
export default SiteViewPage;