
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { Col, Row, Image, Button, Container } from '@themesberg/react-bootstrap';


export default () => {
  const history = useHistory();
  return (
    <main>
      <section className="d-flex align-items-start justify-content-start">
          <Row className="align-items-start">
            <Col xs={12} lg={8} className="order-2 order-lg-1 text-left text-lg-left px-5">
              <h1 className="text-primary mt-5">
                You are <strong>not authorised</strong> to access this page
                </h1>
                    <p className="lead my-4">
                      Get in touch if you believe you should be able access this area.
                </p>
                <Button onClick={history.goBack} variant="primary" className="animate-hover">
                  <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                  Go back
                </Button>
            </Col>
          </Row>
      </section>
    </main>
  );
};
