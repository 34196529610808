import React, { useEffect, useRef, useState } from 'react';
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes} from '../../../auth/PermissionsMap';
import { GetCertificateNumbers, GetCertificateFormData} from './CertificateFormFunctions';
import { certificateRoutes} from "./CertificateFormMap"
import { Card, Button, Col, Row } from "@themesberg/react-bootstrap";
import CertificateNumberService from "../../services/CertificateNumberService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen,faPlus, faCheckCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";

const CertificateFormPanel = ({col = 12, isStorageDeclaration, completedForms, name, number, isGEFS, productId, attestationId}) => {

    //Function - Get Navigation
    const history = useHistory();
    const openForm = (certNumber) => {
        var certRoute = certificateRoutes.find(x=>x.number == certNumber);
        {isGEFS ?
            history.push(certRoute.GEFS.replace(":id", productId))
            :
            history.push(certRoute.NonGEFS.replace(":id", productId).replace(":attestationId", attestationId))
        }
    };

    return(
        <Col lg={col} className="text-center">
            {completedForms[number] === false ?(
                // New forms
                <>
                    <p className="mb-3">
                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2 text-danger" />
                        A {name} form is required to complete a support attestation.
                    </p> 
                    <PermissionsCheck errorProps={{ disabled: true }} scopes={isGEFS || isStorageDeclaration? [scopes.productdetail.create] : [scopes.productnongefs.create]}>
                        <Button onClick={()=> openForm(number)} variant="secondary" size="md"> 
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            <span>Complete a {name} form</span>
                        </Button>
                    </PermissionsCheck>
                </>
            )
            :
            (
                <>
                    <p className="mb-3">
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
                        {name} form has been completed.
                    </p>
                
                    <PermissionsCheck errorProps={{ disabled: true }} scopes={isStorageDeclaration? [scopes.productdetail.update] : isGEFS? [scopes.productdetail.update] : [scopes.productnongefs.update]}>
                        <Button onClick={()=> openForm(number)} variant="secondary" size="md"> 
                            <FontAwesomeIcon icon={faPen} className="me-2" />
                            <span>Edit {name} form</span>
                        </Button>
                    </PermissionsCheck>
                    
                    <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.productdetail.read]} scopesExcluded={isStorageDeclaration? [scopes.productdetail.update] : isGEFS? [scopes.productdetail.update] : [scopes.productnongefs.update]}>
                        <Button onClick={()=> openForm(number)} variant="secondary" size="md"> 
                            <FontAwesomeIcon icon={faPen} className="me-2" />
                            <span>View {name} form</span>
                        </Button>
                    </PermissionsCheck>
                </>
            )
        }
        </Col>
    )

};

const CertificateFormPanels = (props) => {
    const {isStorageDeclaration, productData, isGEFS = false, col, attestationId = null, productDetail = null, productId, visibility} = props;
    const [completedForms, setCompletedForms] = useState();
	const completedFormsRef = useRef(completedForms);
    const [selectedCertificateNumbers, setSelectedCertificateNumbers] = useState(null);
    const [ready, setReady] = useState(false);
    const [certsReady, setCertsReady] = useState(false);

    const history = useHistory();
    

	function setCompleted(newState) {
		completedFormsRef.current = newState;
		setCompletedForms(newState);
	}
    useEffect(() => {
        var numbers = GetCertificateNumbers();

        Object.keys(numbers).forEach(function(key) {
            numbers[key] = false;
        });

        setCompletedForms(numbers);
    }, []);
    
    //Function - Get all certificate numbers and specific certificate numbers required for this product
    useEffect(() => {
        let certList = productDetail.certificateNumberList;
        if(certList!=undefined){
            CertificateNumberService.getAll()
                .then((response) => {
                    //if(Array.isArray(certList)){
                    if(certList?.length > 0){
                        setSelectedCertificateNumbers(response.data.filter(x => certList.includes(x.number.toString())));
                    }
                    else{
                        setSelectedCertificateNumbers(response.data.filter(x => certList.includes(x.number.toString())));
                    }
                    setCertsReady(true);
                })
                .catch((e) => {
                    console.log(e);
                })
        }
    }, [productDetail.certificateNumberList]);
        

    //Function - Get specific certificate form data for each certificate on this product
    useEffect(() => {
        async function fetchCertFormData() {
            if(certsReady && (selectedCertificateNumbers !==null && selectedCertificateNumbers !==undefined)){
                await GetCertificateFormData(productId, selectedCertificateNumbers, true)
                .then(x=>{
                    setCompleted(x);
                    setReady(true)
                })
            }    
        };
        fetchCertFormData();
    }, [certsReady, selectedCertificateNumbers]);


    //Callback - Parent Component - Check all forms are complete
    useEffect(() => {
        if(ready){
            let count = 0;
            let isComplete = false;
            Object.keys(completedForms).forEach(function(key) {
                if(completedForms[key] === true){
                    count++;
                }
            });
            if(count > 0 && count== selectedCertificateNumbers.length){
                isComplete = true;
            }
            productData(isComplete)
        }
    }, [ready]);


    const openProductDetail = () => {
        history.push("/product/detail/" + productId);
    };

        

    return(
        ready && (
            <Card border="light" className="bg-white shadow-sm h-100">
                <Card.Body className="text-center">
                    <h4 className="text-title" id="tabelLabel" >Certificate Specific Information</h4>

                    {visibility =="Active" ?
                    <Row className="text-center">
                        {selectedCertificateNumbers.map((cert, index) =>{
                            let rowCount = productDetail.certificateNumberList.length;
                            return (
                                <div key={`cert-panel-${index}`}> 
                                    <CertificateFormPanel 
                                        col={col}
                                        ready={ready}
                                        completedForms={completedForms}
                                        name={cert.name} 
                                        number={cert.number}
                                        isGEFS={isGEFS}
                                        isStorageDeclaration={isStorageDeclaration}
                                        productId={productId}
                                        attestationId={attestationId}
                                    />
                                    {rowCount> 1 && rowCount !== index + 1 &&
                                        <hr className="mt-3" />
                                    }
                                </div>
                                )
                            })
                        }
                        
                        {/* Replaced certificate notices */}
                        {!Array.isArray(productDetail.certificateNumber) &&
                            productDetail.certificateNumber === "8354" && (
                                <Col className="text-center">
                                    <p className="mb-2">This form is no longer available due to it been replaced by a newer version.</p>
                                    <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.productdetail.update]}>
                                        <p className="mb-2">Select a new certificate number in 'Product Details' and provide updated information to proceed.</p>
                                        <Button onClick={()=> openProductDetail()} variant="secondary" size="md">
                                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                                            <span>Update Product Details</span>
                                        </Button>
                                    </PermissionsCheck>
                                </Col>
                                        
                            )
                        }

                        {/* Replaced certificate notices */}
                        {Array.isArray(productDetail.certificateNumber) &&
                            productDetail.certificateNumber.includes("8354") && (
                                <Col className="text-center">
                                    <p className="mb-2">This form is no longer available due to it been replaced by a newer version.</p>
                                    <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.productdetail.update]}>
                                        <p className="mb-2">Select a new certificate number in 'Product Details' and provide updated information to proceed.</p>
                                        <Button onClick={()=> openProductDetail()} variant="secondary" size="md">
                                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                                            <span>Update Product Details</span>
                                        </Button>
                                    </PermissionsCheck>
                                </Col>
                                        
                            )
                        }
                       
                        
                    </Row>
                    :
                    <>Details are unavailable. The product is {visibility}.</>
                    }
                </Card.Body>
            </Card>
        )
    )
}

 export default CertificateFormPanels;