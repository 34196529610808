import React, {useState, useEffect} from "react";
import { faFilter, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Col, Row, Table } from '@themesberg/react-bootstrap';
import { Form, Formik } from 'formik';
import moment from "moment";
import { Link, useHistory } from 'react-router-dom';
import Preloader from "../../components/Preloader";
import FormController from "../../pages/components/common/FormController";
import Paging from "../../pages/components/common/Paging";
import AttestationService from '../../pages/services/AttestationService';
import { NoResults } from "./NoResults";
import { Routes } from "../../routes";
import PermissionsCheck from '../../auth/PermissionCheck';
import { scopes} from '../../auth/PermissionsMap';


export const SupportAttestationHistoryTable = (props) => {
    const [attestations, setAttestations] = useState([]);
    const [count, setCount] = useState(0);
    const {totalPages = count, size = "md", withIcons = false, disablePrev = false} = props;
    const [totalItems, setTotalItems] = useState(0);
    const [activePage, setActivePage] = React.useState(1);
    const [loaded, setLoaded] = useState(false);
    const [searchTitle, setSearchTitle] = useState("");
    const [pageSize, setPageSize] = useState(5);
    const history = useHistory();
    const initialValues = {
      search: ''
  }
  
  function onSubmit(values, { setSubmitting, resetForm } ) {
      setTimeout(() => {
          setActivePage(1);
          retrieveAttestations(values);
          console.log(JSON.stringify(values, null, 2));
          setSubmitting(false);
      }, 1000);
  }
  
    const retrieveAttestations = (values) => {
      let params =  '';
      if(values != null){
          params = getFilterParams(searchTitle, activePage, pageSize);
      }
      else{
          params = getFilterParams(searchTitle, activePage, pageSize);
      }
      getAttestationsData(params);
  };
  
    useEffect(() => {
        retrieveAttestations();
    },[activePage, pageSize]);
  
   
    const getFilterParams = ( searchTitle,activePage, pageSize) => {
        let params = {};
        if (searchTitle) {
            params["searchTerms"] = searchTitle;
        }
        if (activePage) {
            params["page"] = activePage;
        }
        if (pageSize) {
            params["size"] = pageSize;
        }
        return params;
    };
  
    
    function onSubmit(values, { setSubmitting, resetForm } ) {
        setTimeout(() => {
            setActivePage(1);
            retrieveAttestations(values);
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    }
  
  
    const getAttestationsData = (params) => {
  
      setLoaded(false);
      AttestationService.getAllPagedByUserId(params)
          .then((response) => {
              const { data, totalPages, totalRecords} = response.data;
              setTotalItems(totalRecords);
              setAttestations(data);
              setCount(totalPages);
              setLoaded(true);
          })
          .catch((e) => {
              setLoaded(true);
              console.log(e);
          });
  };
//   const cancelAttestationRequest = (productId) => {
//       AttestationService.cancelAttestationRequest(productId)
//       .then(response => {
//           history.go(0);
//       })
//       .catch(e => {
//           console.log(e);
//       });
//   };
  
    const TableRow = (props) => {
      const {productId, productName, isGEFS, attestationDetails, attestationRequest, attestationResponse, isComplete, active, status } = props;
      const getSADate = (date) => {
        var newDate = moment(date).format("DD/MM/YY");
        if (newDate === "Invalid date") {
            return "";
        }
        return newDate;
      }
      return (
        <tr>
        <Td id={productId}> 
          <span className="fw-normal">
          {getSADate(attestationRequest.createdDate)}
          </span>
        </Td>
        <Td id={productId}> 
            <span className="fw-normal">
              {isComplete? "Approved" : !active? "Cancelled" : (attestationResponse?.isRejected? "Rejected" : "Awaiting response")}
            </span>
          </Td>
        <Td id={productId} > 
            <Card.Link 
              as={Link} 
              // to={{ pathname: attestationRequest.signedDocument?.filename}}
              to={Routes.Product.path.replace(":id",productId)}
               target="_blank" 
              className="fw-normal text-black">
              {productName}
              
            </Card.Link>
{/*             
            <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.attestationrequests.create]}>
                    <Button onClick={()=> cancelAttestationRequest(JSON.parse(attestationDetails.productJson).product?.id)} variant="danger" size="md"> 
                        <FontAwesomeIcon icon={faWindowClose} className="me-2" />
                        <span>Cancel Attestation Request</span>
                    </Button>
                </PermissionsCheck> */}
          </Td>
        <Td id={productId}> 
            <span className="fw-normal">
              {isGEFS? 
              <Badge className="rounded px-3 py-2 bg-success"> GEFS</Badge>
                : 
              <Badge className="rounded px-3 py-2 bg-warning"> Non-GEFS</Badge>
             }            
            </span>
          </Td>
        </tr>
      );
    };
     //Start - Paging Functions
     const onPrevItem = () => {
      const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
      setActivePage(prevActiveItem);
    };
    const onPageNumberClick = (number) => {
      setActivePage(number);
  };
  
  const onNextItem = (totalPages) => {
    const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
    setActivePage(nextActiveItem);
  };
      
  const handlePageSizeChange = (value) => {
    setPageSize(value.value);
    setActivePage(1);
  };
  //End - Paging Functions
  
    return (
      <>
        <Col xs={12} sm={6} lg={10}>
          <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              >
              {(props, isSubmitting) => (
                  <Form>     
                      <Row className="mb-3">
                          <Col xs={12} sm={8} lg={8}>
                              <FormController
                                  control="input"
                                  type="text"
                                  onChange={(e) => {
                                      props.setFieldValue('search',e.target.value);
                                      setSearchTitle(e.target.value);
                                  }}
                                  placeholder="Search by product name / unique code"
                                  name="search"
                              />
                          </Col>
                          <Col xs={12} sm={4} lg={4} >
                              <div className="form-group">
                                  <Button variant="light" type="submit" disabled={isSubmitting}>
                                  <FontAwesomeIcon icon={faFilter} />{isSubmitting ? "Please wait..." : "Filter"}</Button>
                              </div>
                          </Col>
                      </Row>
                  </Form>
              )}
          </Formik >
        </Col>
        <Preloader component={true} show={!loaded} />
  
        <Table hidden={!loaded || attestations==0} hover responsive className="align-items-center table-flush">
          <thead>
            <tr>
              <th className="border-bottom">Date</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Product name</th>
              <th className="border-bottom">Type</th>
            </tr>
          </thead>
          <tbody>
              {attestations?.map(t => <TableRow key={`transaction-${t.id}`} {...t} />)}
          </tbody>
        </Table>
  
        
        <Paging
          activePage={activePage}
          size={size}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          handleChange={handlePageSizeChange}
          onPrevItem={onPrevItem}
          onNextItem={onNextItem}
          onPageNumberClick={onPageNumberClick}
          withIcons={withIcons}
        />
        {loaded && attestations?.length == 0 &&(
          <NoResults name="attestation requests"/>
        )}
      </>
    );
  };
  
export default function Td({ children, to, id }) {
    // Conditionally wrapping content into a link
    const ContentTag = to ? Link : 'div';
    
    const history = useHistory();
    const _viewSupportAttestation = (filename) => {
      history.push(Routes.Product.path.replace(":id", id));
    };
    return (
      <td style={{ cursor: "pointer", color:"text-dark" }} onClick={()=>_viewSupportAttestation(id)}>
        <ContentTag className="text-dark"  to={to}>{children}</ContentTag>
      </td>
    );
  }