import *  as baseService  from "./BaseService";

const getByContentsId = (consignmentContentsId) => {
    return baseService.getApiData("/ConsignmentContentsCombinations/GetByConsignmentContentsId?consignmentContentsId=" + consignmentContentsId);
};

const getByMasterId = (masterId) => {
    return baseService.getApiData("/ConsignmentContentsCombinations/GetByConsignmentContentMasterId?consignmentContentMasterId=" + masterId);
};

const create = (data) => {
    return baseService.postApiData("/ConsignmentContentsCombinations/create", data);
};
const update = (data) => {
    return baseService.putApiData("/ConsignmentContentsCombinations/update", data);
};

const removeGroup = (consignmentContentsId) => {
    return baseService.deleteApiData("/ConsignmentContentsCombinations/deleteGroup?consignmentContentsId=" + consignmentContentsId);
};

const removeByConsignmentContentsId = (consignmentContentsId) => {
    return baseService.deleteApiData("/ConsignmentContentsCombinations/deleteByConsignmentContentsId?consignmentContentsId=" + consignmentContentsId);
};

const ConsignmentContentsCombinationsService = {
    getByContentsId,
    getByMasterId,
    create,
    update,
    removeGroup,
    removeByConsignmentContentsId
};

export default ConsignmentContentsCombinationsService;