import React from "react";
import { Nav } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faCircle, faSun, faUserCircle } from '@fortawesome/free-regular-svg-icons';

export default (props) => {
  const { steps, activeStep, size = "md" } = props;
//   const bgColorClass = type === "tooltip" ? `bg-${variant}` : "";

  return (
    <Nav fill variant="pills" className="flex-column flex-md-row nav-stepper">
    {steps.map(
      (step, index) =>
      <React.Fragment key={`${index + 1}step`} >
        <Nav.Link key={index + 1} eventKey={index + 1} href={`${index + 1}`} disabled className={`px-0 border-0 mb-sm-3 mb-md-0 ${(activeStep===(index)?"active": "")}`}>
          <Nav.Item className="mb-sm-3 mb-md-0">
            <div className="steps fa-layers fa-fw fa-1x">
              <FontAwesomeIcon fill icon={faCircle} size='lg' /><div>{index + 1}</div>
            </div>
            {(step)}
          </Nav.Item>
        </Nav.Link >
        
        {(index + 1) !== steps.length && 
        (
            <Nav.Link key={`${index + 1}link`} disabled className={`border-0 mb-sm-3 mb-md-0 px-0`}>
              <Nav.Item className="mb-sm-3 mb-md-0 px-0">
                <hr />
              </Nav.Item>
            </Nav.Link>
          )
        }
      </React.Fragment>
      )
    }
  </Nav>
  );
};
