import { Badge, Button, Spinner, Table } from "@themesberg/react-bootstrap";
import moment from "moment";
import React, { useState } from "react";
import Preloader from "../Preloader";
import { NoResults } from "./NoResults";
import { ListGroupItem, ListGroup } from "react-bootstrap";

export const EstablishmentTable = (props) => {
  const { establishments, loaded = false } = props;
  const totalTransactions = establishments?.length;

  const getDate = (date) => {
    var newDate = moment(date).format("DD/MM/YY HH:mm");
    if (newDate === "Invalid date" || newDate === "01/01/01 00:00") {
      return "N/A";
    }
    return newDate;
  };

  const TableRow = (props) => {
    const {
      country,
      establishmentTypes,
      classifications,
      countryName,
      name,
      approvalNumber,
      address,
    } = props;
    const [isProcessing, setProcessing] = useState(false);

    return (
      <tr className={`bg-${isProcessing ? "gray-900" : "white"}`}>
        <td>
          <div className="pt-2">
            <strong className="text-title fs-6">{name}</strong>
          </div>
          <div className="pt-2">
            <span>
              <strong>
                Traces number: {country?.isoCode}
                {approvalNumber}
              </strong>
            </span>
          </div>
          <div className="pt-2">
            <span>Address: {address}</span>
          </div>
        </td>
        <td className="text-start">
          {establishmentTypes != undefined && (
            <>
              <small>
                <strong>Establishment Types</strong>
              </small>
              <ListGroup variant="flush" className="mb-2">
                {establishmentTypes?.map((e) => (
                  <ListGroupItem>
                    <div className="small text-ellipsis">{e}</div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </>
          )}
          {establishmentTypes != undefined && (
            <>
              <small>
                <strong>Food Groups</strong>
              </small>
              <ListGroup variant="flush" className="mb-2">
                {classifications?.map((c) => (
                  <ListGroupItem>
                    <div className="small">{c}</div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </>
          )}
        </td>
        <td>
          <Spinner
            className="p-2"
            hidden={!isProcessing}
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </td>
        <td>
          <span className="fw-normal"></span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Preloader component={true} show={!loaded} />
      <Table
        hidden={!loaded || establishments == 0}
        hover
        className="user-table align-items-top text-wrap"
      >
        <thead>
          <tr>
            <th className="border-bottom text-start">Establishment</th>
            <th colSpan={2} className="border-bottom">
              Activites
            </th>
            <th className="border-bottom"></th>
          </tr>
        </thead>
        <tbody>
          {establishments?.map((t) => (
            <TableRow key={`establishments-${t.id}`} {...t} />
          ))}
        </tbody>
      </Table>
      {loaded && establishments?.length == 0 && (
        <NoResults name="establishments" />
      )}
    </>
  );
};

export default EstablishmentTable;
