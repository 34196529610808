import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Formik, Form, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import ZoneService from "../../../services/ZoneService";
import ProductCasingService from "../../../services/ProductCasingService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import * as Casing8393Labels from "./Casing8393Labels"
import FormSubmission from "../../common/FormSubmission";
import { Button, Card, Alert, Col, Row } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import MeatBseRisks from '../Meat/Components/MeatBseRisks';
import SiteService from '../../../services/SiteService';
import EstablishmentsService from '../../../services/EstablishmentsService';
import SiteSelect from '../../../../components/EstablishmentSelect/SiteSelect';
import RewrappingEstablishment from '../../../../components/EstablishmentSelect/RewrappingEstablishment';

const Casing8393AddPage = (props) => {
    const [isSubmitting,  setSubmitting] = useState(false);
    const certificateNumber = 8393;
    const { id, attestationId } = useParams();
    const [formVersion] = useState(GetCertificateFormVersion(certificateNumber));
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [specieslist, SetSpecies] = useState([]);
    const [siteEstablishment, setSiteEstablishment] = useState([]);
    const [manufacturingPlantEstab, setManufacturingPlantEstab] = useState([]);
    const [isManufacturingPlantEstabChanging, setManufacturingPlantEstabChanging] = useState(false);

    let title = (editing == true ? "Edit " : "New") + " Casing (#8393) Information"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Casing 8393', link: Routes.Casing8393AddPage.path },
    };
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [zones, SetZones] = useState([]);
    const [countrys, SetCountrys] = useState([]);
    const [controlledRisk, SetControlledRisk] = useState([]);
    const [negligbleRisk, SetNegligbleRisk] = useState([]);
    const [productDetailCountry, SetCountry] = useState([]);
    const [apiValues, SetApiValues] = useState([]);

    const initialValues = {
        productId: props.match.params.id,
        identificationMark: apiValues?.identificationMark ?? '',
        manufacturingPlant: apiValues?.manufacturingPlant ?? '',
        countryOriginId: apiValues?.countryOriginId ?? [],

        casing_2_1_SpeciesType1: apiValues?.casing_2_1_SpeciesType1 ?? false,
        casing_2_1_SpeciesType2: apiValues?.casing_2_1_SpeciesType2 ?? false,
        casing_2_1_SpeciesType3: apiValues?.casing_2_1_SpeciesType3 ?? false,
        casing_2_1_SpeciesType4: apiValues?.casing_2_1_SpeciesType4 ?? false,

        casing_2_2_SpeciesType1: apiValues?.casing_2_2_SpeciesType1 ?? false,
        casing_2_2_SpeciesType2: apiValues?.casing_2_2_SpeciesType2 ?? false,
        casing_2_2_SpeciesType3: apiValues?.casing_2_2_SpeciesType3 ?? false,
        casing_2_2_SpeciesType4: apiValues?.casing_2_2_SpeciesType4 ?? false,
        animal_2_2_statements: apiValues?.animal_2_2_statements ?? 0,
        animal_2_2_A_statements: apiValues?.animal_2_2_A_statements ?? 0,
        animal_2_2_B_statements: apiValues?.animal_2_2_B_statements ?? 0,
        animal_2_1_zone: apiValues?.animal_2_1_zone ?? [],
        animal_2_2_zone: apiValues?.animal_2_2_zone ?? [],
        BseControlled: apiValues?.bseControlled ?? 0,
        BseRiskCategoryNegligible: apiValues?.bseRiskCategoryNegligible ?? false,
        BseRiskCategoryControlled: apiValues?.bseRiskCategoryControlled ?? false,
        BseRiskCategoryUndetermined: apiValues?.bseRiskCategoryUndetermined ?? false,
        BseRiskCategoryNegligibleStatements: apiValues?.bseRiskCategoryNegligibleStatements ?? null,
        BseRiskCategoryControlledStatements: apiValues?.bseRiskCategoryControlledStatements ?? 0,
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
        identificationMarkEstablishment: apiValues?.identificationMarkEstablishment ?? siteEstablishment[0],
        manufacturingPlantEstablishments: apiValues?.manufacturingPlantEstablishments ?? [],
        rewrappingEstablishments: apiValues?.rewrappingEstablishments ?? [],
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";

    //Validation
    const validationSchema = Yup.object().shape({
        // countryOriginId: Yup.object().shape({
        //     countryName: Yup.string().required(),
        //     id: Yup.string().required()
        // }).typeError(validationMsgRequired),
        // identificationMark: Yup.string().trim()
        //     .required(validationMsgRequired),
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        BseRiskCategoryNegligibleStatements: Yup.array().nullable()
            .when("BseRiskCategoryNegligible", {
                is: true,
                then: Yup.array().min(1, validationMsgAtLeastOne)
            }),
        BseRiskCategoryControlledStatements: Yup.number()
            .when("BseRiskCategoryControlled", {
                is: true,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        animal_2_1_zone: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        animal_2_2_B_statements: Yup.number().integer()
            .test('animal_2_2_B_statements', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 3 && (!this.parent.animal_2_2_B_statements == 1 && !this.parent.animal_2_2_B_statements == 1 && !this.parent.animal_2_2_B_statements == 1))
                    ? false : true;
            }),
        casing_2_1_SpeciesType1: Yup.bool()
            .test('casing_2_1_SpeciesType1', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 1 && this.parent.animal_2_2_zone != "" && (!this.parent.casing_2_1_SpeciesType1 && !this.parent.casing_2_1_SpeciesType2 && !this.parent.casing_2_1_SpeciesType3 && !this.parent.casing_2_1_SpeciesType4))
                    ? false : true;
            }),
        casing_2_1_SpeciesType2: Yup.bool()
            .test('casing_2_1_SpeciesType2', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 1 && this.parent.animal_2_2_zone != "" && (!this.parent.casing_2_1_SpeciesType1 && !this.parent.casing_2_1_SpeciesType2 && !this.parent.casing_2_1_SpeciesType3 && !this.parent.casing_2_1_SpeciesType4))
                    ? false : true;
            }),
        casing_2_1_SpeciesType3: Yup.bool()
            .test('casing_2_1_SpeciesType3', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 1 && this.parent.animal_2_2_zone != "" && (!this.parent.casing_2_1_SpeciesType1 && !this.parent.casing_2_1_SpeciesType2 && !this.parent.casing_2_1_SpeciesType3 && !this.parent.casing_2_1_SpeciesType4))
                    ? false : true;
            }),
        casing_2_1_SpeciesType4: Yup.bool()
            .test('casing_2_1_SpeciesType4', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 1 && this.parent.animal_2_2_zone != "" && (!this.parent.casing_2_1_SpeciesType1 && !this.parent.casing_2_1_SpeciesType2 && !this.parent.casing_2_1_SpeciesType3 && !this.parent.casing_2_1_SpeciesType4))
                    ? false : true;
            }),
        animal_2_2_zone: Yup.array()
            .test('animal_2_2_zone', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 1 && x.length === 0)
                    ? false : true;
            }),

        animal_2_2_A_statements: Yup.number().integer()
            .test('animal_2_2_A_statements', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 2 && (!this.parent.casing_2_2_SpeciesType1 && !this.parent.casing_2_2_SpeciesType2 && !this.parent.casing_2_2_SpeciesType3 && !this.parent.casing_2_2_SpeciesType4))
                    ? false : true;
            }),
        casing_2_2_SpeciesType1: Yup.bool()
            .test('casing_2_2_SpeciesType1', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 2 && (!this.parent.animal_2_2_A_statements == 1 && !this.parent.animal_2_2_A_statements == 2) && (!this.parent.casing_2_2_SpeciesType1 && !this.parent.casing_2_2_SpeciesType2 && !this.parent.casing_2_2_SpeciesType3 && !this.parent.casing_2_2_SpeciesType4))
                    ? false : true;
            }),
        casing_2_2_SpeciesType2: Yup.bool()
            .test('casing_2_2_SpeciesType2', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_statements == 2 && (!this.parent.animal_2_2_A_statements == 1 && !this.parent.animal_2_2_A_statements == 2) && (!this.parent.casing_2_2_SpeciesType1 && !this.parent.casing_2_2_SpeciesType2 && !this.parent.casing_2_2_SpeciesType3 && !this.parent.casing_2_2_SpeciesType4))
                    ? false : true;
            }),
        casing_2_2_SpeciesType3: Yup.bool()
            .test('casing_2_2_SpeciesType3', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_A_statements == 2 && (!this.parent.animal_2_2_A_statements == 1 && !this.parent.animal_2_2_A_statements == 2) && (!this.parent.casing_2_2_SpeciesType1 && !this.parent.casing_2_2_SpeciesType2 && !this.parent.casing_2_2_SpeciesType3 && !this.parent.casing_2_2_SpeciesType4))
                    ? false : true;
            }),
        casing_2_2_SpeciesType4: Yup.bool()
            .test('casing_2_2_SpeciesType4', validationMsgAtLeastOne, function (x) {
                return (this.parent.animal_2_2_A_statements == 2 && (!this.parent.animal_2_2_A_statements == 1 && !this.parent.animal_2_2_A_statements == 2) && (!this.parent.casing_2_2_SpeciesType1 && !this.parent.casing_2_2_SpeciesType2 && !this.parent.casing_2_2_SpeciesType3 && !this.parent.casing_2_2_SpeciesType4))
                    ? false : true;
            }),
        identificationMarkEstablishment: Yup.object().nullable().required(validationMsgRequired),
        manufacturingPlantEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
    });


    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;
            console.log(submitData);
            // if (!values.countryOriginId !== null) {
            //     submitData.countryOriginId = values.countryOriginId.id;
            // }

            if (apiValues.length === 0) {
                ProductCasingService.create(submitData)
                    .then(response => {
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            else {
                ProductCasingService.update(productId, values)
                    .then(response => {
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            setFormSubmitted(true);
        }, 1000);
    }

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();
        useEffect(() => {
            if (ready) {
                if (values.animal_2_1_zone?.length === 0 || values.animal_2_1_zone == null) {
                    values.BseRiskCategoryNegligible = false;
                    values.BseRiskCategoryControlled = false;
                    values.BseRiskCategoryUndetermined = false;
                } else {
                    let hasControlledRiskCountry = false;
                    let hasNegligibleRiskCountry = false;
                    let hasUndeterminedRiskCountry = false;

                    values.animal_2_1_zone?.forEach((country) => {
                        if (negligbleRisk.find(f => f.countryName === country.countryName)) {
                            hasNegligibleRiskCountry = true;
                        }
                        if (controlledRisk.some(f => f.countryName === country.countryName)) {
                            hasControlledRiskCountry = true;
                        }

                        if (!negligbleRisk.find(f => f.countryName === country.countryName) && !controlledRisk.find(f => f.countryName === country.countryName)) {
                            hasUndeterminedRiskCountry = true;
                        }
                    });

                    if (hasNegligibleRiskCountry && hasControlledRiskCountry && hasUndeterminedRiskCountry) {
                        values.BseRiskCategoryNegligible = true;
                        values.BseRiskCategoryControlled = true;
                        values.BseRiskCategoryUndetermined = true;
                    }
                    else if (hasControlledRiskCountry && hasNegligibleRiskCountry) {
                        values.BseRiskCategoryNegligible = true;
                        values.BseRiskCategoryControlled = true;
                        values.BseRiskCategoryUndetermined = false;
                    } else if (hasControlledRiskCountry && hasUndeterminedRiskCountry) {
                        values.BseRiskCategoryNegligible = false;
                        values.BseRiskCategoryControlled = true;
                        values.BseRiskCategoryUndetermined = true;
                    } else if (hasUndeterminedRiskCountry && hasNegligibleRiskCountry) {
                        values.BseRiskCategoryNegligible = true;
                        values.BseRiskCategoryControlled = false;
                        values.BseRiskCategoryUndetermined = true;
                    } else {
                        if (hasControlledRiskCountry) {
                            values.BseRiskCategoryControlled = true;
                            values.BseRiskCategoryNegligible = false;
                            values.BseRiskCategoryUndetermined = false;
                        } else if (hasNegligibleRiskCountry) {
                            values.BseRiskCategoryNegligible = true;
                            values.BseRiskCategoryControlled = false;
                            values.BseRiskCategoryUndetermined = false;
                        } else if (hasUndeterminedRiskCountry) {
                            values.BseRiskCategoryControlled = false;
                            values.BseRiskCategoryNegligible = false;
                            values.BseRiskCategoryUndetermined = true;
                        } else {
                            values.BseRiskCategoryControlled = false;
                            values.BseRiskCategoryNegligible = false;
                            values.BseRiskCategoryUndetermined = false;
                        }
                    }

                }
            }
            if (isManufacturingPlantEstabChanging) {
                setManufacturingPlantEstab(values.manufacturingPlantEstablishments)
            }
            setManufacturingPlantEstabChanging(false);
            if (!values.BseRiskCategoryNegligible) {
                setFieldValue('BseRiskCategoryNegligibleStatements', null);
            }
            if (!values.BseRiskCategoryControlled) {
                setFieldValue('BseRiskCategoryControlledStatements', 0);
            }
            if (values.animal_2_2_statements != 1) {
                values.casing_2_1_SpeciesType1 = false;
                values.casing_2_1_SpeciesType2 = false;
                values.casing_2_1_SpeciesType3 = false;
                values.casing_2_1_SpeciesType4 = false;
                values.animal_2_2_zone = [];
            }
            if (values.animal_2_2_statements != 2) {
                values.animal_2_2_A_statements = 0;
                values.casing_2_2_SpeciesType1 = false;
                values.casing_2_2_SpeciesType2 = false;
                values.casing_2_2_SpeciesType3 = false;
                values.casing_2_2_SpeciesType4 = false;
            }
            if (values.animal_2_2_statements != 3) {
                values.animal_2_2_B_statements = 0;
            }

            const isBovine = values.productScientificSpecies.some(
                obj => obj.isBovine === true
            );
            const isOvine = values.productScientificSpecies.some(
                obj => obj.isOvine === true
            );
            const isCaprine = values.productScientificSpecies.some(
                obj => obj.isCaprine === true
            );
            const isPorcine = values.productScientificSpecies.some(
                obj => obj.isPorcine === true
            );

            if (isBovine) {
                values.casing_2_1_SpeciesType1 = true;
                values.casing_2_2_SpeciesType1 = true;
            }
            else {
                values.casing_2_1_SpeciesType1 = false;
                values.casing_2_2_SpeciesType1 = false;
            }
            if (isOvine) {
                values.casing_2_1_SpeciesType2 = true;
                values.casing_2_2_SpeciesType2 = true;
            }
            else {
                values.casing_2_1_SpeciesType2 = false;
                values.casing_2_2_SpeciesType2 = false;
            }
            if (isCaprine) {
                values.casing_2_1_SpeciesType3 = true;
                values.casing_2_2_SpeciesType3 = true;
            }
            else {
                values.casing_2_1_SpeciesType3 = false;
                values.casing_2_2_SpeciesType3 = false;
            }
            if (isPorcine) {
                values.casing_2_1_SpeciesType4 = true;
                values.casing_2_2_SpeciesType4 = true;
            }
            else {
                values.casing_2_1_SpeciesType4 = false;
                values.casing_2_2_SpeciesType4 = false;
            }
        }, [values]
        );
        return null;
    };

    useEffect(() => {
        if (ready) {
            //Get site estab
            ProductService.getProductDetailByProductId(productId)
                .then(response4 => {
                    //Get site operatorId
                    SiteService.getById(response4.data.siteId)
                        .then(response2 => {

                            //Get establishment data from operatorid
                            EstablishmentsService.getEstablishmentsDetails([response2.data.tracesId])
                                .then(response3 => {
                                    //set parent site for this product
                                    setSiteEstablishment(response3.data)
                                }, [])
                                .catch(e => {
                                    console.log(e);
                                });
                        }, [])
                        .catch(e => {
                            console.log(e);
                        });
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductCasingService.getByProductId(productId)
                .then(response => {
                    var res = response.data;
                    setManufacturingPlantEstab(res.manufacturingPlantEstablishments)
                    if (response.data == "") {
                        return;
                    }
                    // if (res.countryOriginId !== "" && countrys !== "") {
                    //     const selectedCountry = countrys.find(x => x.id === res.countryOriginId);
                    //     res.countryOriginId = selectedCountry;
                    // }
                    SetApiValues(response.data);
                    setEditing(true);
                    setReady(true);
                    console.log("init values:" + apiValues);
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductService.getById(productId)
                .then(response => {
                    SetProduct(response.data.data);
                    setIsGEFS(response.data.data.isGEFS);
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductScientificSpeciesDataService
                .getOnlyCasing()
                .then((_speciesResponse) => {
                    const speciesOptions = _speciesResponse.data.map(d => ({
                        "id": d.id,
                        "speciesName": d.speciesName,
                        "alternativeName": d.alternativeName,
                        "isBovine": d.isBovine,
                        "isOvine": d.isOvine,
                        "isPorcine": d.isPorcine,
                    })).sort((a, b) => a.speciesName.localeCompare(b.speciesName));
                    SetSpecies(speciesOptions);
                })
                .finally(() => {
                });
            ZoneService
                .getAllByGroup("8393_CASING")
                .then((_zoneResponse) => {
                    const options = _zoneResponse.data.map(d => ({
                        "id": d.id,
                        "displayName": d.displayName,
                        "countryName": d.countryName,
                        "regionName": d.regionName,
                        "isoCode": d.isoCode,
                    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                    const firstItem = "GB-0";
                    options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                    SetZones(options);
                }).catch((e) => {
                    console.log(e);
                })
        }
    }, [ready]);

    useEffect(() => {
        async function fetchListData() {
            try {
                const getCountryData = new Promise((resolve, reject) => {
                    resolve(ZoneService.getAllCountrys()
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "id": d.id,
                                "countryName": d.countryName
                            }))
                            SetCountrys(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getBSEControlledRiskData = new Promise((resolve, reject) => {
                    resolve(ZoneService.getAllByGroup("ALL_BSE_CONTROLLED_RISK")
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "id": d.id,
                                "displayName": d.displayName,
                                "countryName": d.countryName,
                                "regionName": d.regionName,
                                "isoCode": d.isoCode,
                            })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                            SetControlledRisk(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                const getBSENegligbleRiskData = new Promise((resolve, reject) => {
                    resolve(ZoneService.getAllByGroup("ALL_BSE_NEGLIGBLE_RISK")
                        .then((response) => {
                            const options = response.data.map(d => ({
                                "id": d.id,
                                "displayName": d.displayName,
                                "countryName": d.countryName,
                                "regionName": d.regionName,
                                "isoCode": d.isoCode,
                            })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                            SetNegligbleRisk(options);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                    );
                });
                Promise.all([getCountryData, getBSEControlledRiskData, getBSENegligbleRiskData]).then((responses) => {
                    setReady(true);
                });
            } catch (e) {
                console.error(e);
            }
        };
        fetchListData();
    }, []);


    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <SubmissionPage
                    productId={productId}
                    attestationId={attestationId}
                    isGEFS={isGEFS}
                    errors={errors}
                    title={(editing ? "Edited" : "Completed") + " Casing 8393 Form"}
                    message={`Dairy information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                />
            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        {/* <PrettyPrintJson data={props.values} /> */}
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        <div className="">
                                            <ul className="list-unstyled">
                                                <h4 className="mt-5">Animal Health Attestation</h4>
                                                <li>
                                                    <div className="pb-4">
                                                        <MultiSelectField
                                                            id='casing'
                                                            name='animal_2_1_zone'
                                                            label="processed in and dispatched from the zone/s with code/s:"
                                                            placeholder="Select fields"
                                                            options={zones}
                                                            defaultValues={props.values?.animal_2_1_zone}
                                                            getOptionValue={option => option.id}
                                                            getOptionLabel={option => option.displayName}
                                                            value={props.values.animal_2_1_zone}
                                                            isMulti={true}
                                                            onChange={props.setFieldValue}
                                                            onBlur={props.setFieldTouched}
                                                            touched={props.touched.animal_2_1_zone}
                                                            error={props.errors.animal_2_1_zone}
                                                            isClearable={true}
                                                            isrequired="true"
                                                            backspaceRemovesValue={true}
                                                            readOnly={readOnly}
                                                            col={6}

                                                        />
                                                    </div>
                                                </li>
                                                <li>
                                                    <MultiSelectField
                                                        id='productScientificSpecies'
                                                        name='productScientificSpecies'
                                                        label="Species"
                                                        placeholder="Select fields"
                                                        options={specieslist}
                                                        defaultValues={props.values?.productScientificSpecies}
                                                        getOptionValue={option => option.id}
                                                        getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                                        value={props.values.productScientificSpecies}
                                                        isMulti={true}
                                                        onChange={props.setFieldValue}
                                                        onBlur={props.setFieldTouched}
                                                        touched={props.touched.productScientificSpecies}
                                                        error={props.errors.productScientificSpecies}
                                                        isClearable={true}
                                                        isrequired="true"
                                                        backspaceRemovesValue={true}
                                                        col={4}
                                                    />
                                                </li>


                                                <FormController
                                                    control="radio"
                                                    label=""
                                                    name="animal_2_2_statements"
                                                    options={Casing8393Labels.animal_2_2_statements}
                                                    readOnly={readOnly}
                                                />
                                                {props.values.animal_2_2_statements == 1 &&
                                                    <div className="border-left pl-3 subform">
                                                        <>
                                                            <li className="mt-3">
                                                                processed from bladder and/or intestines obtained from:<span className="required" />
                                                                <Row>
                                                                    <Col sm={6} lg={4} xxl={2} className="button">
                                                                        <FormController
                                                                            control="checkbox"
                                                                            className="border-chk"
                                                                            label={Casing8393Labels.casingSpeciesChoices[0].key}
                                                                            name={Casing8393Labels.casingSpeciesChoices[0].fieldname}
                                                                            readOnly={readOnly}
                                                                        />
                                                                    </Col>
                                                                    <Col sm={6} lg={4} xxl={2} className="button">
                                                                        <FormController
                                                                            control="checkbox"
                                                                            className="border-chk"
                                                                            label={Casing8393Labels.casingSpeciesChoices[1].key}
                                                                            name={Casing8393Labels.casingSpeciesChoices[1].fieldname}
                                                                            readOnly={readOnly}
                                                                        />
                                                                    </Col>
                                                                    <Col sm={6} lg={4} xxl={2} className="button">
                                                                        <FormController
                                                                            control="checkbox"
                                                                            className="border-chk"
                                                                            label={Casing8393Labels.casingSpeciesChoices[2].key}
                                                                            name={Casing8393Labels.casingSpeciesChoices[2].fieldname}
                                                                            readOnly={readOnly}
                                                                        />
                                                                    </Col>
                                                                    <Col sm={6} lg={4} xxl={2} className="button">
                                                                        <FormController
                                                                            control="checkbox"
                                                                            className="border-chk"
                                                                            label={Casing8393Labels.casingSpeciesChoices[3].key}
                                                                            name={Casing8393Labels.casingSpeciesChoices[3].fieldname}
                                                                            readOnly={readOnly}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </li>

                                                            <li>
                                                                <div className="pb-4">
                                                                    <MultiSelectField
                                                                        id='casing2'
                                                                        name='animal_2_2_zone'
                                                                        label="processed in and dispatched from the zone/s with code/s:"
                                                                        placeholder="Select fields"
                                                                        options={zones}
                                                                        defaultValues={props.values?.animal_2_2_zone}
                                                                        getOptionValue={option => option.id}
                                                                        getOptionLabel={option => option.displayName}
                                                                        value={props.values.animal_2_2_zone}
                                                                        isMulti={true}
                                                                        onChange={props.setFieldValue}
                                                                        onBlur={props.setFieldTouched}
                                                                        touched={props.touched.animal_2_2_zone}
                                                                        error={props.errors.animal_2_2_zone}
                                                                        isClearable={true}
                                                                        isrequired="true"
                                                                        backspaceRemovesValue={true}
                                                                        readOnly={readOnly}
                                                                        col={6}

                                                                    />
                                                                </div>
                                                            </li>

                                                        </>
                                                        <hr />
                                                    </div>
                                                }


                                                {(props.values.animal_2_2_statements == 2) && (

                                                    <div className="border-left pl-3 subform">
                                                        <>
                                                            <li className="mt-3">
                                                                have been processed from bladders and/or intestines obtained from:<span className="required" />
                                                                <Row>
                                                                    <Col sm={6} lg={4} xxl={2} className="button">
                                                                        <FormController
                                                                            control="checkbox"
                                                                            className="border-chk"
                                                                            label={Casing8393Labels.casingSpeciesChoices2[0].key}
                                                                            name={Casing8393Labels.casingSpeciesChoices2[0].fieldname}
                                                                            readOnly={readOnly}
                                                                        />
                                                                    </Col>
                                                                    <Col sm={6} lg={4} xxl={2} className="button">
                                                                        <FormController
                                                                            control="checkbox"
                                                                            className="border-chk"
                                                                            label={Casing8393Labels.casingSpeciesChoices2[1].key}
                                                                            name={Casing8393Labels.casingSpeciesChoices2[1].fieldname}
                                                                            readOnly={readOnly}
                                                                        />
                                                                    </Col>
                                                                    <Col sm={6} lg={4} xxl={2} className="button">
                                                                        <FormController
                                                                            control="checkbox"
                                                                            className="border-chk"
                                                                            label={Casing8393Labels.casingSpeciesChoices2[2].key}
                                                                            name={Casing8393Labels.casingSpeciesChoices2[2].fieldname}
                                                                            readOnly={readOnly}
                                                                        />
                                                                    </Col>
                                                                    <Col sm={6} lg={4} xxl={2} className="button">
                                                                        <FormController
                                                                            control="checkbox"
                                                                            className="border-chk"
                                                                            label={Casing8393Labels.casingSpeciesChoices2[3].key}
                                                                            name={Casing8393Labels.casingSpeciesChoices2[3].fieldname}
                                                                            readOnly={readOnly}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <hr />
                                                            <li>
                                                                <FormController
                                                                    control="radio"
                                                                    label=""
                                                                    name="animal_2_2_A_statements"
                                                                    options={Casing8393Labels.animal_2_2_A_statements}
                                                                    readOnly={readOnly}
                                                                />
                                                            </li>
                                                        </>
                                                    </div>
                                                )}



                                                {(props.values.animal_2_2_statements == 3) && (
                                                    <div className="border-left pl-3 subform">
                                                        <li>
                                                            <FormController
                                                                control="radio"
                                                                label=""
                                                                name="animal_2_2_B_statements"
                                                                options={Casing8393Labels.animal_2_2_B_statements}
                                                                readOnly={readOnly}
                                                            />
                                                        </li>
                                                    </div>
                                                )}
                                                {/* <li>
                                                    <FormController
                                                        id="countryOriginId"
                                                        name="countryOriginId"
                                                        label="Country of origin"
                                                        placeholder="Select a country of origin"
                                                        control="multiselect"
                                                        options={countrys}
                                                        getOptionValue={option => option.id}
                                                        getOptionLabel={option => option.countryName}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        onBlur={props.setFieldTouched}
                                                        onChange={props.setFieldValue}
                                                        touched={props.touched.countryOriginId}
                                                        errors={props.errors.countryOriginId}
                                                        isrequired="true"
                                                        backspaceRemovesValue={true}
                                                        col={4}
                                                    />
                                                </li> */}
                                                <MeatBseRisks
                                                    title="casing products"
                                                    formNumber="8393"
                                                    values={props.values}
                                                    labels={Casing8393Labels}
                                                    readOnly={readOnly}
                                                />

                                                <li>
                                                    <SiteSelect
                                                        name="manufacturingPlantEstablishments"
                                                        selectedFieldName="manufacturingPlantEstablishments"
                                                        setEstablishmentSelected={props.setFieldValue}
                                                        selections={props.values.manufacturingPlantEstablishments}
                                                        label="Manufacturing Plant"
                                                        isMulti={true}
                                                        countries={props.values.animal_2_1_zone.map(x => x.isoCode).map(str => str.replace(/-(0|1|2|3|4|5)/g, ''))}
                                                        certificateNumber={certificateNumber}
                                                        getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                        getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                        isRequired={true}
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                                <li>
                                                    <RewrappingEstablishment
                                                        name="rewrappingEstablishments"
                                                        selectedFieldName="rewrappingEstablishments"
                                                        setEstablishmentSelected={props.setFieldValue}
                                                        selections={props.values.rewrappingEstablishments}
                                                        label="Re-wrapping and re-packaging establishment"
                                                        isMulti={true}
                                                        countries={props.values.animal_2_1_zone.map(x => x.isoCode).map(str => str.replace(/-(0|1|2|3|4|5)/g, ''))}
                                                        certificateNumber={certificateNumber}
                                                        getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                        getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                        isRequired={false}
                                                        readOnly={readOnly}
                                                    />
                                                </li>
                                                <li>
                                                    <MultiSelectField
                                                        id='identificationMarkEstablishment'
                                                        name='identificationMarkEstablishment'
                                                        label="Identification mark"
                                                        placeholder="Select fields"
                                                        options={Array.from(new Map([...(siteEstablishment || []), ...(manufacturingPlantEstab || [])].map(item => [item["approvalNumber"], item])).values())}
                                                        getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                        getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                        isMulti={false}
                                                        onChange={(name, value) => {
                                                            props.setFieldValue("identificationMarkEstablishment", value);
                                                            setManufacturingPlantEstabChanging(true);
                                                        }}
                                                        onBlur={props.setFieldTouched}
                                                        touched={props.touched.identificationMarkEstablishment}
                                                        error={props.errors.identificationMarkEstablishment}
                                                        isClearable={true}
                                                        isrequired="true"
                                                        backspaceRemovesValue={true}
                                                        readOnly={readOnly}
                                                        col={6}
                                                    />
                                                </li>


                                            </ul>
                                        </div>
                                        {/* <PrettyPrintJson data={props.errors} /> */}
                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting || readOnly}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />
                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default Casing8393AddPage;