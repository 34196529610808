import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import FormController from "../common/FormController"
import InvitationService from "../../services/InvitationService"
import { Card, Button } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';

const ManufacturerDataAdminInvite = (props) => {
    const {manufacturerName, manufacturerId} = props;
	const [submitted, setSubmitted] = useState(false);
	const [sentEmail, setSentEmail] = useState(false);

    return (

        
        <Formik
            initialValues={{  
                inviteEmail: '', 
                role: 'ManufacturerAdmin', 
                itemId: manufacturerId
            }}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    if(typeof manufacturerId === 'string') {
                        values.itemId = manufacturerId;
                    }
                    else{
                        values.itemId = manufacturerId[0];
                    }
                    InvitationService.invite(values)
                    .then(response => {
                        console.log(response.data);
                      //  openToast();
                    })
                    .catch(e => {
                        console.log(e);
                    });
                    console.log(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                    setSubmitted(true);
                    setSentEmail(values.inviteEmail);
                }, 1000);
            }}
            validationSchema={yup.object({
                inviteEmail: yup.string().email("Invalid email address").required("Must enter an email address "),
            })}
        >
        {(formik, isSubmitting) => (
            <Card border="light" className="bg-lightpink shadow-sm mb-4">
                <Card.Header>
                    <h4 className="mb-0" >Invite a Data Admin</h4>
                </Card.Header>
                <Card.Body>
                {!submitted?(
                    <Form className="text-align" noValidate>
                        <p>Invite a manufacturer by email to administer products on {manufacturerName}</p>
                        <FormController
                            control="input"
                            type="email"
                            label={null}
                            name="inviteEmail"
                            placeholder="Email Address"
                            errors={formik.errors.inviteEmail}
                            touched={formik.touched.inviteEmail}
                        />
                        <div className="d-grid gap-2">
                            <Button type="submit" variant="outline-dark width-100" size="md" className="mt-2"> 
                                <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                                <span>Invite</span>
                            </Button>
                        </div>
                    </Form>
                        ):
                        (
                            <p>
                                <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> Invitation successfully sent to {sentEmail}
                            </p>
                        )
                    }
                </Card.Body>
            </Card>
        )
        }
        </Formik >
    );
};

export default ManufacturerDataAdminInvite;