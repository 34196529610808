import *  as baseService  from "./BaseService";

const getUpcoming = () => {
    return baseService.getApiData("/Upcoming/GetUpcomingDates");
};


const UpcomingService = {
    getUpcoming
};

export default UpcomingService;