import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  moment from 'moment';
import { faPlus, faCheckCircle, faTimesCircle, faBullhorn, faEdit, faFileInvoice,faEllipsisH, faEye, faClone, faDownload} from '@fortawesome/free-solid-svg-icons';
import { Col,Row, Table, Button, Alert, ButtonGroup, Card, Modal, Dropdown} from '@themesberg/react-bootstrap';
import { Routes } from "../../../routes";

import { useHistory } from "react-router-dom";
const ConsignmentSummary = (props) =>{
    const { 
        consignmentId, 
        consignment,
        isApproved
    } = props;

    const _editConsignment = () => {
        window.location = Routes.ConsignmentEditPage.path.replace(":id",consignmentId);
    };

    return(
    <Card border="light" className="bg-white shadow-sm mb-4 w-100">
        <Card.Header>
            <Row className="align-items-center">
                <Col>
                    <h4 className="text-title mb-0">Consignment Details</h4>
                </Col>
                <Col className="text-end" md={4}>
                    {!isApproved &&
                        <Button onClick={_editConsignment} variant="light" size="sm"> 
                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                            <span>Edit</span>
                        </Button>
                    }
                </Col>
            </Row>
        </Card.Header>

        <Card.Body className='d-flex flex-column'>
            <Row className="py-2">
                <Col md={6}>
                    <label>Departure Date</label>
                </Col>
                <Col md={4}>
                    {moment(consignment.departureDate).format("Do MMM YYYY")}
                </Col>
            </Row>
            <Row className="py-2">
                <Col md={6}>
                    <label>Destination Country</label>
                </Col>
                <Col md={4}>
                    {consignment.destinationCountry?.countryName}
                </Col>
            </Row>
            <Row className="py-2">
                <Col md={6}>
                    <label>Platform</label>
                </Col>
                <Col md={4}>
                    {consignment.platform?.platformName}
                </Col>
            </Row>
            <Row className="py-2">
                <Col md={6}>
                    <label>Operator</label>
                </Col>
                <Col md={4}>
                    {consignment.operatorName}
                </Col>
            </Row>
            <Row className="py-2">
                <Col md={6}>
                    <label>Form Completion Status</label>
                </Col>
                <Col md={4}>
                    {consignment.approved ? (
                        <>
                            {/* <FontAwesomeIcon icon={faCheckCircle} className="mt-1 text-success float-start mx-1"/> */}
                            Completed
                        </>
                    )
                    :
                    (
                        <>
                            {/* <FontAwesomeIcon icon={faTimesCircle} className="mt-1 text-gray-600 float-start mx-1"/> */}
                            <span>In progress</span>
                        </>
                    )}
                </Col>
            </Row>
        </Card.Body>
    </Card>
    )
}


export default ConsignmentSummary;
