import *  as baseService  from "./BaseService";

const openBlobPdf = (filename) => {
    baseService.getApiBlob("/Document/DownloadPdfFromBlobStorage?filename=" + filename)
    .then(blob => {
    var file = new Blob([blob.data], { type: 'application/pdf' });            
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    });
}
const downloadBlobZip = (filename) => {
    baseService.getApiBlob("/Document/DownloadPdfFromBlobStorage?filename=" + filename)
    .then(blob => {
    var file = new Blob([blob.data], { type: 'application/zip' });            
        var fileURL = URL.createObjectURL(file);
        window.location.href =fileURL;
    });
}
const BlobService = {
    openBlobPdf,
    downloadBlobZip
};

export default BlobService;