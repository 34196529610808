import React from 'react';
import { Card, Col, Row} from "@themesberg/react-bootstrap";
 import * as AttestationContent from "../../Common/AttestationContent";
import { useFormikContext } from 'formik';
import { CheckForCertificateNumber, CheckForEgg } from './FormComponents/DailyAttestationFunctions';

import moment from "moment";

export default function DailyAttestationTemplate(props) {
    const { values: formValues, setFieldValue } = useFormikContext();
  const {
    birdFluCheck,
    trackingRef,
    uniqueCode,
    batchNumbers,
    batchNumberRange,
    commercialNumber,
    deliveryDate,
    numberOfUnits,
    numberOfCases,
    numberOfPackages,
    abattoirs,
    farmPost,
    farmPostEgg,
    slaughterDates,
    poultryCuttingPlantApprovalNumbers,
    cuttingPlantApprovalNumbers,
    abattoirApprovalNumbers,
    poultryMeatArrivalDate,
    dairyEstablishments,
    grossWeight,
    netWeight,
    manufacturerName,
    dailyAttestationData,
    forwardedRef,
    netWeightTotal,
    grossWeightTotal,
    user,
    uniqueRef,
    originAnimal,
    dateCollectionProduction,
    notes,
    establishmentsSlaughterhouses,
    establishmentsCuttingPlants,
    establishmentsDairy,
  } = props;

  return (
      
        <Card ref={forwardedRef} border="light" className="d-none d-print-block bg-white shadow-sm mb-4 p-3">
            {/* //d-none */}
            <Card.Body>
                <div className="attestation-title text-center">
                    <h2>Daily Attestation - Supplier Declaration</h2>
                    <h4>{trackingRef.label}: {uniqueRef}</h4>
                    <br/><br/>
                </div>

                <h6 className="d-block">
                    {deliveryDate.label}:&nbsp;
                    {moment(formValues.deliveryDate).format("DD/MM/YY")}
                </h6>

                <h6 className="d-block">
                    {commercialNumber.label}:&nbsp;
                    {formValues.commercialNumber}
                </h6>


                <Row className="mt-3">
                    <Col md={12}>
                        <p>
                            {dailyAttestationData?.sort((a, b) => a.order - b.order)?.map((x,i) => (
                            <>
                                <h5 className="text-title">
                                    Product {x.order} - {x.productName} (EAN: - {x.ean})
                                </h5>
                                
                                <div className="d-block">
                                    <strong>{uniqueCode.label}: </strong>
                                    {x.uniqueCode}
                                </div>

                                {x.hasBatches === true?
                                    <div className="d-block">
                                        <strong>{batchNumberRange.label}: </strong>
                                        {x.batchNumberRange?.map(x=>x.number).join(", ")}
                                    </div>
                                :
                                    <div className="d-block">
                                        <strong>{batchNumbers.label}: </strong>
                                        {x.batchNumbers}
                                    </div>
                                }

                                {CheckForCertificateNumber(x,[8383,8384])&&
                                    <div className="d-block">
                                        <strong>{abattoirs.label}: </strong>
                                        {x.establishmentsSlaughterhouses?.length>0 ? 
                                            x.establishmentsSlaughterhouses.map(x=>x.approvalNumber).join(", ")
                                        : 
                                            x.abattoirs !== null && x.abattoirs !=="" ? 
                                                x.abattoirs 
                                            :
                                                "N/A"
                                        }
                                    </div>
                                }

                                {/* <div className="d-block">
                                    <strong>{eggEstablishments.label}: </strong>
                                    {x.eggEstablishments !== null? x.eggEstablishments : "N/A"}
                                </div> */}

                                {CheckForCertificateNumber(x,[8350])&&
                                    <div className="d-block">
                                        <strong>{dairyEstablishments.label}: </strong>
                                        {x.establishmentsDairy?.length>0 ? 
                                            x.establishmentsDairy.map(x=>x.approvalNumber).join(", ")
                                        : 
                                            x.dairyEstablishments !== null && x.dairyEstablishments !=="" ? 
                                                x.dairyEstablishments 
                                            :
                                                "N/A"
                                        }
                                    </div>
                                }
                                {/* {CheckForCertificateNumber(x,[8383,8350,8384])&&
                                    <div className="d-block">
                                        <strong>{cuttingPlantApprovalNumbers.label}: </strong>
                                        {x.establishmentsCuttingPlants?.length>0 ? 
                                            x.establishmentsCuttingPlants.map(x=>x.approvalNumber).join(", ")
                                        : 
                                            x.cuttingPlantApprovalNumbers !== null && x.cuttingPlantApprovalNumbers !=="" ? 
                                                x.cuttingPlantApprovalNumbers 
                                            :
                                                "N/A"
                                        }
                                    </div>
                                } */}

                                <div className="d-block">
                                    <strong>{dateCollectionProduction.label}: </strong>
                                    <span>{x.dateCollectionProduction}</span> 
                                </div>
                                {CheckForCertificateNumber(x,[8390,8383,8350,8384,8396])&&
                                    <div className="d-block">
                                        <strong>{originAnimal.label}: </strong>
                                    {x.originAnimal}
                                    </div>
                                }
                                <div className="d-block">
                                    <strong>{notes.label}: </strong>
                                    {x.notes}
                                </div>

                                <div className="d-block">
                                    <strong>{x.weightType == 1? numberOfUnits.label : numberOfCases.label }: </strong>
                                    {x.numberOfPackages}
                                </div>
                                <div className="d-block">
                                    <strong>{netWeight.label}: </strong>
                                    {x.netWeight.toFixed(3)}
                                </div>

                                <div className="d-block">
                                    <strong>{grossWeight.label}: </strong>
                                    {x.grossWeight.toFixed(3)}
                                </div>

                                <div className="d-block">
                                    <strong>{netWeightTotal.label}: </strong>
                                    {(x.netWeight * x.numberOfPackages).toFixed(3)}
                                </div>

                                <div className="d-block">
                                    <strong>{grossWeightTotal.label}: </strong>
                                    {(x.grossWeight * x.numberOfPackages).toFixed(3)}
                                </div>

                                {CheckForEgg(x.attestationData) &&
                                <div className="d-block">
                                    <strong>{farmPostEgg.label}: </strong>
                                    {x.farmPost}
                                </div>
                                }


                                {x.birdFluCheck &&
                                <>
                                    <h5>Additional information (product contains poultry)</h5>
                                    <div className="d-block">
                                        <strong>{farmPost.label}: </strong>
                                        {x.farmPost !== null? x.farmPost : "N/A"}
                                    </div>

                                    <div className="d-block">
                                        <strong>{slaughterDates.label}: </strong>
                                        {x.slaughterDates?.length>0? 
                                            x.slaughterDates?.map((dateItem,index) =>(
                                                <>
                                                    <span>{index > 0 && ',  '}</span>
                                                    {moment(dateItem).isValid() &&(
                                                        <span>{moment(dateItem).format("DD/MM/YYYY")}</span>
                                                    )}
                                                </>
                                            ))
                                            : "N/A"}
                                    </div>
                                    <div className="d-block">
                                        <strong>{poultryCuttingPlantApprovalNumbers.label}: </strong>
                                        {x.poultryCuttingPlantApprovalNumbers !== null? x.poultryCuttingPlantApprovalNumbers : "N/A"}
                                    </div>

                                    <div className="d-block">
                                        <strong>{abattoirApprovalNumbers.label}: </strong>
                                        {x.abattoirApprovalNumbers !== null? x.abattoirApprovalNumbers : "N/A"}
                                    </div>

                                    <div className="d-block">
                                        <strong>{poultryMeatArrivalDate.label}: </strong>
                                        {moment(x.poultryMeatArrivalDate).isValid() ?
                                        <span>{moment(x.poultryMeatArrivalDate).format("DD/MM/YYYY")}</span> 
                                        : "N/A"}
                                    </div>
                                </>
                                }

                                {/* 
                                <div className="d-block">
                                    <strong>{netWeightPerQty.label}: </strong>
                                {x.netWeightPerQty}
                                </div>

                                <div className="d-block">
                                    <strong>{grossWeightPerQty.label}: </strong>
                                {x.grossWeightPerQty}
                                </div> */}

                                <br />
                                <hr />
                            </>
                           ))}
                        </p>
                        <p>
                            The evidence required to facilitate export of the products in this consignment have 
                            been provided in the support attestation(s) with the unique document reference of the support attestation listed above.
                        </p>								
                        <p>
                            No changes have been made that affect the validity of the information provided in this attestation.
                        </p>
                    </Col>
                </Row>
                
                <AttestationContent.SignedBy user={user}  manufacturerName={manufacturerName}  hasSignature={true}/>

            </Card.Body>
        </Card>
  );
}
