import { Routes } from "../../routes";
import React, { useState, useEffect, useRef } from "react";
import DailyAttestationService from "../../pages/services/DailyAttestationService";
import ManufacturerService from "../../pages/services/ManufacturerService";
import BlobService from "../../pages/services/BlobService"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract, faFilter, faEllipsisH, faEye, faCheck, faPlus, faTrashAlt, faClone } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Table, Button, ButtonGroup, Card, Badge, Dropdown } from '@themesberg/react-bootstrap';
import { Formik, Form, useFormikContext } from 'formik';
import Paging from "../../pages/components/common/Paging"
import FormController from "../../pages/components/common/FormController"
import { NoResults } from "./NoResults";
import Preloader from "../Preloader";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import PermissionsCheck from '../../auth/PermissionCheck';
import { scopes } from '../../auth/PermissionsMap';
import EllipsisTooltip from "../EllipsisTooltip";
import moment from "moment";

export default function Td({ children, to, id, className, colSpan }) {
  // Conditionally wrapping content into a link
  const ContentTag = to ? Link : 'div';
  const history = useHistory();
  const _viewDailyAttestation = (id) => {
    history.push(Routes.DailyAttestationViewPage.path.replace(":id", id));
  };
  return (
    <td colSpan={colSpan} style={{ cursor: "pointer", color: "text-dark" }} className={className} onClick={() => _viewDailyAttestation(id)}>
      <ContentTag className="text-dark" to={to}>{children}</ContentTag>
    </td>
  );
}

export const DailyAttestationListingTable = (props) => {

  const [attestations, setAttestations] = useState([]);
  const [values, setValues] = useState([]);
  const [count, setCount] = useState(0);
  const { totalPages = count, size = "md", withIcons = false, disablePrev = false, isSelectForm = false, formValues = null, setParentFormFieldValue } = props;
  const [totalItems, setTotalItems] = useState(0);
  const [activePage, setActivePage] = React.useState(1);
  const [loaded, setLoaded] = useState(false);
  const [manufacturers, setManufacturers] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const history = useHistory();

  const initialValues = {
    searchTerms: '',
    manufacturerId: '',
    deliveryDate: '',
    batchNumber: ''
  }


  useEffect(() => {
    if (isSelectForm) {
      //alert("contentsSelected: " + formValues.contentsSelected);
    }
    getManufacturerData();
  }, [isSelectForm]);



  async function getManufacturerData() {
    ManufacturerService.getAll()
      .then((response) => {
        const options = response.data.map(d => ({
          "value": d.id,
          "label": d.manufacturerName
        })).sort((a, b) => a.label.localeCompare(b.label));
        setManufacturers(options);
      })
      .catch((e) => {
        console.log(e);
      });
  }


  const retrieveAttestations = (values) => {
    let params = '';
    if (values != null) {
      params = getFilterParams(values.searchTerms, values.manufacturerId, values.batchNumber, values.deliveryDate, activePage, pageSize);
    }
    else {
      params = getFilterParams(null, null, null, null, activePage, pageSize);
    }
    getAttestationsData(params);
  };

  useEffect(() => {
    retrieveAttestations(values);
  }, [activePage, pageSize]);


  const getFilterParams = (searchTerms, manufacturerId, batchNumber, deliveryDate, activePage, pageSize) => {
    let params = {};
    if (searchTerms) {
      params["searchTerms"] = searchTerms;
    }
    if (manufacturerId) {
      params["manufacturerId"] = manufacturerId;
    }
    if (batchNumber) {
      params["batchNumber"] = batchNumber;
    }
    if (deliveryDate) {
      params["deliveryDate"] = deliveryDate;
    }
    if (activePage) {
      params["page"] = activePage;
    }
    if (pageSize) {
      params["size"] = pageSize;
    }
    return params;
  };


  const handlePageSizeChange = (value) => {
    setPageSize(value.value);
    setActivePage(1);
  };

  const openBlobPdf = (filename) => {
    BlobService.openBlobPdf(filename);
  }

  function onSubmit(values, { setSubmitting, resetForm }) {
    setValues(values);
    setTimeout(() => {
      setActivePage(1);
      retrieveAttestations(values);
      console.log(JSON.stringify(values, null, 2));
      setSubmitting(false);
    }, 1000);
  }

  const getAttestationsData = (params) => {
    setLoaded(false);
    DailyAttestationService.getAllPagedByUserId(params)
      .then((response) => {
        const { data, totalPages, totalRecords } = response.data;
        setTotalItems(totalRecords);
        setAttestations(data);
        setCount(totalPages);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        console.log(e);
      });
  };


  const _cloneDailyAttestation = (id) => {
    window.open(Routes.DailyAttestationClonePage.path.replace(":id", id), "_self");
  };


  //Table Row
  const TableRow = (props) => {
    const { id, uniqueCode, manufacturerName, deliveryDate, dailyAttestationItems, signedDocument, commercialNumber, selections } = props;
    const [selected, setSelected] = useState(false);

    useEffect(() => {
      if (isSelected()) {
        setSelected(true)
      }
      else {
        setSelected(false)
      }
    }, []);

    const isSelected = () => {
      if (selections !== undefined) {
        const found = selections.find(x => x.id == id);
        if (found !== undefined && found !== null) {
          return true;
        }
      }
      return false;
    }

    const getSADate = (date) => {
      var newDate = moment(date).format("DD/MM/YY");
      if (newDate === "Invalid date") {
        return "";
      }
      return newDate;
    }



    const selectItem = (id, uniqueCode, manufacturerName, deliveryDate, dailyAttestationItems, signedDocument) => {
      //var res = formValues.contentsSelected;
      dailyAttestationItems.forEach((item, index) => {
        var item = { id: id, uniqueCode: uniqueCode, manufacturerName: manufacturerName, deliveryDate: deliveryDate, dailyAttestationItems: item, signedDocument: signedDocument };
        if (!isSelected()) {
          setSelected(true);
          formValues.contentsSelected.push(item);
        }
        else {
          formValues.contentsSelected.pop(item);
          setSelected(false);
        }
      });
      //  formValues.contentsSelected = res;
      //alert(JSON.stringify(res));

    }


    return (
      <tr>
        {/* <Td id={id}>
          {uniqueCode}
        </Td> */}
        <Td id={id} colSpan={2} className="text-truncate">
          <Card.Link
            as={Link}
            to={Routes.DailyAttestationViewPage.path.replace(":id", id)}
            className="fw-normal text-black">
            <EllipsisTooltip text={uniqueCode}/>
          </Card.Link>
        </Td>
        <Td id={id} colSpan={2} className="text-truncate">
          <Card.Link
            as={Link}
            to={Routes.DailyAttestationViewPage.path.replace(":id", id)}
            className="fw-normal text-black">
            <EllipsisTooltip text={commercialNumber}/>
          </Card.Link>
        </Td>
        <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
          <Td colSpan={2} id={id} className="text-truncate">
            <span className="fw-normal">
              <Card.Link
                as={Link}
                to={Routes.DailyAttestationViewPage.path.replace(":id", id)}
                className="fw-normal text-black">
                <EllipsisTooltip text={manufacturerName}/>
              </Card.Link>
            </span>
          </Td>
        </PermissionsCheck>
        <Td colSpan={2} id={id} className="text-truncate">
          <Card.Link
            as={Link}
            to={Routes.DailyAttestationViewPage.path.replace(":id", id)}
            className="fw-normal text-black">
             <EllipsisTooltip text=
             {
                dailyAttestationItems.length == 1 ?
                  dailyAttestationItems.map(item => item.batchNumbersDisplay).join(', ')
                :
                  dailyAttestationItems.map((item, i) => (
                    <span className="d-block">Item {i + 1}: {item.batchNumbersDisplay}</span>
                  )
                  )
              }
            />
          </Card.Link>
        </Td>
        <Td colSpan={1} id={id} className="text-truncate">
          <span className="fw-normal">
            <Card.Link
              as={Link}
              to={Routes.DailyAttestationViewPage.path.replace(":id", id)}
              className="fw-normal text-black">
              <EllipsisTooltip text={getSADate(deliveryDate)}/>
            </Card.Link>
          </span>
        </Td>
        <td colSpan={1} className="text-right">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="position-absolute">
              <Dropdown.Item as="a" href={`/attestations/dailyattestation/view/${id}`} target="_blank" onClick={(e) => {
                e.preventDefault();
                _viewDailyAttestation(id);
              }}>
                <FontAwesomeIcon icon={faEye} className="dropdown-item-icon" />
                View Details
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { openBlobPdf(signedDocument?.filename) }}>
                <FontAwesomeIcon icon={faFileContract} className="dropdown-item-icon" />
                Signed Declaration
              </Dropdown.Item>
              {!isSelectForm &&
                <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.dailyattestations.create]}>
                  <Dropdown.Item onClick={() => _cloneDailyAttestation(id)} >
                    <FontAwesomeIcon icon={faClone} className="dropdown-item-icon" />
                    Clone
                  </Dropdown.Item>
                </PermissionsCheck>
              }
            </Dropdown.Menu>
          </Dropdown>
        </td>
        {isSelectForm &&
          <td className="text-right px-4" >
            {selected ?
              (
                <Button variant="primary" size="sm" className="text-center"
                  onClick={() => selectItem(id, uniqueCode, manufacturerName, deliveryDate, dailyAttestationItems, signedDocument)}>
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              )
              :
              (
                <Button variant="light" size="sm" className="text-center"
                  onClick={() => selectItem(id, uniqueCode, manufacturerName, deliveryDate, dailyAttestationItems, signedDocument)}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              )
            }
          </td>
        }
      </tr>
    );
  };


  //Start - Paging Functions
  const onPrevItem = () => {
    const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
    setActivePage(prevActiveItem);
  };

  const onPageNumberClick = (number) => {
    setActivePage(number);
  };
  const onNextItem = (totalPages) => {
    const nextActiveItem = activePage === totalPages ? activePage : activePage + 1;
    setActivePage(nextActiveItem);
  };
  //End - Paging Functions

  //Open View Attestation
  const _viewDailyAttestation = (id) => {
    if (isSelectForm) {
      window.open(Routes.DailyAttestationViewPage.path.replace(":id", id));
    }
    else {
      history.push(Routes.DailyAttestationViewPage.path.replace(":id", id));
    }
  };

  const handleRightClick = (e, record) => {
    e.preventDefault();
    // You can open a context menu or do something else here.
    // For example, log the clicked record:
    alert("greg")
    console.log("Right-clicked on:", record);
  };

  return (
    <>
      <Col xs={12} sm={6} lg={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {(props, isSubmitting) => (
            <Form>
              <Row className="mb-3">
                <Col xs={12} sm={8} lg={3}>
                  <FormController
                    control="input"
                    type="text"
                    placeholder="PO / tracking number"
                    name="searchTerms"
                    size="md"
                  />
                </Col>
                <Col xs={12} sm={8} lg={2}>
                  <FormController
                    control="input"
                    type="text"
                    placeholder="Batch Number"
                    name="batchNumber"
                    size="md"
                  />
                </Col>

                <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
                  <Col xs={12} sm={8} lg={3}>
                    <FormController
                      id="manufacturer"
                      name="manufacturer"
                      placeholder="Manufacturer"
                      control="multiselect"
                      options={manufacturers}
                      isMulti={false}
                      isClearable={true}
                      onBlur={props.setFieldTouched}
                      onChange={(name, value) => {
                        props.setFieldValue('manufacturer', value);
                        props.setFieldValue('manufacturerId', value?.value);

                      }}
                      touched={props.touched.manufacturer}
                      errors={props.errors.manufacturer}
                      isrequired="true"
                      col={12}
                      size="md"
                    />
                  </Col>
                </PermissionsCheck>


                <Col xs={12} sm={8} lg={2}>
                  <FormController
                    control="dateInput"
                    type="text"
                    placeholderText="Delivery date"
                    name="deliveryDate"
                    size="md"
                  />
                </Col>
                <Col xs={12} sm={4} lg={2} >
                  <div className="form-group text-right">
                    <Button variant="light" type="submit" disabled={isSubmitting}>
                      <FontAwesomeIcon icon={faFilter} />{isSubmitting ? "Please wait..." : "Filter"}</Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik >
      </Col>
      <Preloader component={true} show={!loaded} />

      <Table hidden={!loaded || attestations == 0} hover className=" table-flush table-ellipsis">
        <thead>
          <tr>
            <th colSpan={2} className="border-bottom">Tracking Document Number</th>
            <th colSpan={2} className="border-bottom">Invoice/PO/Delivery Number </th>
            <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
              <th colSpan={2} className="border-bottom">Manufacturer</th>
            </PermissionsCheck>

            <th colSpan={2} className="border-bottom">Batch numbers</th>
            <th colSpan={1} className="border-bottom">Delivery Date</th>
            <th colSpan={1} className="border-bottom"></th>
          </tr>
        </thead>
        <tbody>
          {attestations?.map(t =>
            <TableRow
              key={`dailyattestation-${t.id}`}
              selections={formValues?.contentsSelected}
              {...t}
              onContextMenu={(e) => handleRightClick(e, t)}
            />)}
        </tbody>
      </Table>

      <Paging
        activePage={activePage}
        size={size}
        pageSize={pageSize}
        totalPages={totalPages}
        totalItems={totalItems}
        handleChange={handlePageSizeChange}
        onPrevItem={onPrevItem}
        onNextItem={onNextItem}
        onPageNumberClick={onPageNumberClick}
        withIcons={withIcons}
      />
      {loaded && attestations?.length == 0 && (
        <NoResults name="daily attestations" />
      )}
    </>
  );
};
