import * as yup from "yup";
import { parse, isAfter, isValid } from 'date-fns';

//Numeric
export const isNumeric = (value) => {
    return /^\d+$/.test(value);
};

//Numeric with prefix
export const isNumericWithPrefix = (value) => {
    // Check if the value has a non-empty prefix followed by more than three digits
    const match = value.match(/^(\D+)(\d{4,})$/);

    if (!match) {
        return false;
    }

    // Extract the prefix and numeric part
    const prefix = match[1];
    const numericPart = match[2];

    // Parse the numeric part to an integer
    const number = parseInt(numericPart, 10);

    // Additional checks can be added here if needed, for example:
    // Check if the number is within a specific range

    return !isNaN(number); // Returns true if the number is a valid integer
};



//Julian - JJJ
export const isThreeDigitJulianDay = (value) => {
    if(value.length != 3){
        return false;
    }
    // Check if the value is a 3-digit number
    const isThreeDigits = /^\d{3}$/.test(value);

    // Parse the value to an integer
    const number = parseInt(value, 10);

    // Check if the number is within the range 1 to 366
    const isValidDay = number >= 1 && number <= 366;

    return isThreeDigits && isValidDay;
};

//Julian **JJJ
export const isJulianCodeWithPrefix = (value) => {
    // Updated regex to ensure it matches only a prefix followed by exactly three digits and nothing more
    const match = value.match(/^(\D*)(\d{3})$/);

    // Check if the pattern matches
    if (!match) {
        return false;
    }

    // Extract the numeric part (3 digits)
    const numericPart = match[2];

    // Parse the numeric part to an integer
    const number = parseInt(numericPart, 10);

    // Check if the number is a valid Julian day
    return !isNaN(number) && number >= 1 && number <= 366;
};
//Julian YY/JJJ
export const isValidYearAndJulianDay = (value) => {
    // Split the value by '/'
    const parts = value.split('/');

    // Check if there are exactly two parts: year and Julian day
    if (parts.length !== 2) {
        return false;
    }

    const [yearPart, julianDayPart] = parts;

    // Validate the year part (assuming a 2-digit year)
    // Adjust the logic here based on your year format requirements
    const isTwoDigitYear = /^\d{2}$/.test(yearPart);

    // Validate the Julian day part
    const isThreeDigits = /^\d{3}$/.test(julianDayPart);
    const julianDayNumber = parseInt(julianDayPart, 10);
    const isValidJulianDay = julianDayNumber >= 1 && julianDayNumber <= 365;

    return isTwoDigitYear && isThreeDigits && isValidJulianDay;
};

export const checkYearLength = (input, expectedLength) => {
    // Extract the year part from the input based on expected length
    const yearRegex = expectedLength === 2 ? /(\d{2})$/ : /(\d{4})$/;
    const match = input.match(yearRegex);
    var res =  match && match[0]?.length === expectedLength;
   return res;
};

export const permittedDateFormats = ['d/M/yy','dd/MM/yy','d-M-yy','d-M-yyyy','dd-MM-yyyy','dd.MM.yy','d.M.yy','do MMM yyyy','do MMM yy','do MMMM yyyy','do MM yyyy','do MM yy', 'dd.MM.yyyy', 'dd/MM/yyyy'];

export function getFormatValidation(format, isToField = false, otherField = '') {
    const validationDateAfter = 'Batch to date should be later than batch from date';

    const parseDate = (value, dateFormat) => {
        return parse(value, dateFormat, new Date());
    };

    switch (format) {
        case 'Date':
            return yup.date().required('Date is required');
        case 'julian':
            return yup.number().required('Value is not appropriate');
        // case 'YYjulian':
        //     return yup.string().matches(/^\d{2}\/(0[1-9]|[1-9][0-9]|[1-2][0-9][0-9]|3[0-5][0-9]|36[0-6])$/, 'Invalid Julian date format YY/DDD')
        //         .test('is-appropriate', 'Value is not appropriate', 
        //             value => isToField ? parseInt(value, 10) > parseInt(otherField, 10) : true
        //         );
        default:
            return yup.string();
    }
}


export const parseDate = (value) => {
    if (typeof value !== 'string' || value.trim() === '') {
        return null;
    }
    const parsed = parse(value, 'yyyy-MM-dd', new Date());
    return isValid(parsed) ? parsed : null;
};
