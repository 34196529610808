import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import ProductDataService from "../../../services/ProductService";
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import moment from "moment-timezone";
import { Formik, Form, FieldArray, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import ProductEggService from "../../../services/ProductEggService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import ZoneService from "../../../services/ZoneService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import * as Egg8360Labels from "./Egg8360Labels"
import { Col, Row, Button, Card, Alert } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faLeaf, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import ColdStoreSelect from '../../../../components/EstablishmentSelect/ColdStoreSelect';
import SiteService from '../../../services/SiteService';
import EstablishmentsService from '../../../services/EstablishmentsService';
import ActivityService from '../../../services/ActivityService';

const Egg8360AddPage = (props) => {
    const certificateNumber = 8360;
    const [isSubmitting,  setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [formVersion] = useState(GetCertificateFormVersion(certificateNumber));
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [zones, SetZones] = useState([]);
    const [specieslist, SetSpecies] = useState([]);
    const [apiValues, SetApiValues] = useState([]);
    const [siteEstablishment, setSiteEstablishment] = useState([]);
    const [coldStoreEstab, setColdStoreEstab] = useState([]);


    let title = (editing == true ? "Edit " : "New") + " Egg Information (#8360-For Egg only)"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Egg 8360', link: Routes.Egg8360AddPage.path },
    };
    const initialValues = {
        productId: props.match.params.id,
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
        animal_2_1_zone: apiValues?.animal_2_1_zone ?? [],
        animal_2_3_A_statements: apiValues?.animal_2_3_A_statements ?? 0,
        animal_2_3_A: apiValues?.animal_2_3_A ?? 0,
        animal_2_3_A_1: apiValues?.animal_2_3_A_1 ?? 0,
        animal_2_3_A_3: apiValues?.animal_2_3_A_3 ?? 0,
        animal_2_3_A_4: apiValues?.animal_2_3_A_4 ?? 0,
        animal_2_3_A_5: apiValues?.animal_2_3_A_5 ?? 0,
        animal_2_3_B_statements: apiValues?.animal_2_3_B_statements ?? 0,
        animal_2_3_B: apiValues?.animal_2_3_B ?? 0,
        animal_2_3_B_1: apiValues?.animal_2_3_B_1 ?? 0,
        animal_2_3_B_4: apiValues?.animal_2_3_B_4 ?? 0,
        animal_2_5_A: apiValues?.animal_2_5_A ?? null,
        animal_2_5_B: apiValues?.animal_2_5_B ?? null,
        animal_2_5_C: apiValues?.animal_2_5_C ?? null,
        idenitificationMarks: apiValues?.idenitificationMarks ?? '',
        dateOfProduction: apiValues?.dateOfProduction ?? '',
        coldstore: apiValues?.coldstore ?? '',
        coldStoreEstablishments: apiValues?.coldStoreEstablishments ?? [],
        identificationMarkEstablishment: apiValues?.identificationMarkEstablishment ?? siteEstablishment[0],
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";
    //Validation
    const validationSchema = Yup.object().shape({
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        animal_2_1_zone: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        animal_2_3_A_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_3_A: Yup.number()
            .when("animal_2_3_A_statements", {
                is: 2,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        animal_2_3_A_1: Yup.number()
            .when("animal_2_3_A", {
                is: 1,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        animal_2_3_A_3: Yup.number()
            .when("animal_2_3_A", {
                is: 3,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        animal_2_3_A_4: Yup.number()
            .when("animal_2_3_A", {
                is: 4,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        animal_2_3_A_5: Yup.number()
            .when("animal_2_3_A", {
                is: 5,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        animal_2_3_B_statements: Yup.number().min(1, validationMsgAtLeastOne),
        animal_2_3_B: Yup.number()
            .when("animal_2_3_B_statements", {
                is: 2,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        animal_2_3_B_1: Yup.number()
            .when("animal_2_3_B", {
                is: 1,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        animal_2_3_B_4: Yup.number()
            .when("animal_2_3_B", {
                is: 4,
                then: Yup.number().integer().min(1, validationMsgAtLeastOne)
            }),
        animal_2_5_A: Yup.string().nullable()
            .test('animal_2_5_A', validationMsgRequired, function (x) {
                return (this.parent.animal_2_5_B == null && this.parent.animal_2_5_C == null && x === null)
                    ? false : true;
            }),
        animal_2_5_B: Yup.string().nullable()
            .test('animal_2_5_B', validationMsgRequired, function (x) {
                return (this.parent.animal_2_5_C != null && x === null)
                    ? false : true;
            }),
        animal_2_5_C: Yup.string().nullable()
            .test('animal_2_5_C', validationMsgRequired, function (x) {
                return (this.parent.animal_2_5_B != null && x === null)
                    ? false : true;
            }),
        identificationMarkEstablishment: Yup.object().nullable().required(validationMsgRequired),
        coldStoreEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
    });

    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;

            if (apiValues.length === 0) {
                ProductEggService.create(submitData)
                    .then(async response => {
                        console.log(response.data);
                        setFormSubmitted(true);
                        //Create Activity Log
                        await ActivityService.logCertForm(
                            'Egg 8360',
                            8360,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            else {
                ProductEggService.update(productId, values)
                    .then(async response => {
                        setFormSubmitted(true);
                        await ActivityService.logCertForm(
                            'Egg 8360',
                            8360,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setFormSubmitted(false);
                        setErrors(e.response?.data);
                    });
            }
            console.log(JSON.stringify(values, null, 2));
        }, 1000);
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();

        useEffect(() => {
            if (ready) {
                if (values.animal_2_3_A_statements != 2) {
                    setFieldValue('animal_2_3_A', 0);
                    setFieldValue('animal_2_3_A_1', 0);
                }
                if (values.animal_2_3_A != 1) {
                    setFieldValue('animal_2_3_A_1', 0);
                }
                if (values.animal_2_3_A != 3) {
                    setFieldValue('animal_2_3_A_3', 0);
                }
                if (values.animal_2_3_A != 4) {
                    setFieldValue('animal_2_3_A_4', 0);
                }
                if (values.animal_2_3_A != 5) {
                    setFieldValue('animal_2_3_A_5', 0);
                }
                if (values.animal_2_3_B_statements != 2) {
                    setFieldValue('animal_2_3_B', 0);
                    setFieldValue('animal_2_3_B_1', 0);
                }
                if (values.animal_2_3_B != 1) {
                    setFieldValue('animal_2_3_B_1', 0);
                }
                if (values.animal_2_3_B != 4) {
                    setFieldValue('animal_2_3_B_4', 0);
                }
                if (values.animal_2_5_A != null) {
                    setFieldValue('animal_2_5_B', null);
                    setFieldValue('animal_2_5_C', null);
                }
                if (values.animal_2_5_B != null && values.animal_2_5_C != null) {
                    setFieldValue('animal_2_5_A', null);
                }
                setColdStoreEstab(values?.coldStoreEstablishments)
            }
        }, [values]);

        return null;
    };
    useEffect(() => {
        async function fetchListData() {

            try {
                const populateProductDetailData = new Promise((resolve, reject) => {
                    resolve(
                        ProductDataService.getProductDetailByProductId(productId)
                            .then(response => {
                                var res = response.data;
                            })
                            .catch(e => {
                                console.log(e);
                            })
                    );
                });
                Promise.all([populateProductDetailData]).then((responses) => {
                    setReady(true);
                });
            } catch (e) {
                console.error(e);
            }
        };
        fetchListData();
    }, []);

    useEffect(() => {
        if (ready) {
            //Get site estab
            ProductService.getProductDetailByProductId(productId)
                .then(response4 => {
                    //Get site operatorId
                    SiteService.getById(response4.data.siteId)
                        .then(response2 => {

                            //Get establishment data from operatorid
                            EstablishmentsService.getEstablishmentsDetails([response2.data.tracesId])
                                .then(response3 => {
                                    //set parent site for this product
                                    setSiteEstablishment(response3.data)
                                }, [])
                                .catch(e => {
                                    console.log(e);
                                });
                        }, [])
                        .catch(e => {
                            console.log(e);
                        });
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductEggService.getByProductId(productId)
                .then(response => {
                    var res = response.data;
                    setColdStoreEstab(res.coldStoreEstablishments)
                    if (response.data == "") {
                        return;
                    }
                    SetApiValues(response.data);
                    setEditing(true);
                    setReady(true);
                    console.log("init values:" + apiValues);
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductService.getById(productId)
                .then(response => {
                    SetProduct(response.data.data);
                    setIsGEFS(response.data.data.isGEFS);
                }, [])
                .catch(e => {
                    console.log(e);
                });
            ProductScientificSpeciesDataService
                .getOnlyEgg()
                .then((_speciesResponse) => {
                    SetSpecies(_speciesResponse.data);
                })
                .finally(() => {
                });
            ZoneService.getAllByGroup("ALL_EGG_PRODUCT")
                .then((_zonesResponse) => {
                    const options = _zonesResponse.data.map(d => ({
                        "id": d.id,
                        "displayName": d.displayName,
                        "countryName": d.countryName,
                        "regionName": d.regionName,
                        "isoCode": d.isoCode,
                    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                    const firstItem = "GB-0";
                    options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                    SetZones(options);
                }).catch((e) => {
                    console.log(e);
                });
        }
    }, [ready]);

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);


    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <>
                    <SubmissionPage
                        productId={productId}
                        attestationId={attestationId}
                        isGEFS={isGEFS}
                        errors={errors}
                        title={(editing ? "Edited" : "Completed") + " Egg 8360 Form"}
                        message={`Egg information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                    />

                </>

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        <h4 className="mt-5">Animal Health Attestation</h4>
                                        <ul className="list-unstyled list-clauses" >
                                            <li>
                                                <span className='required'>Come from the <strong>zone(s)</strong> with code(s):</span>
                                                <MultiSelectField
                                                    id='meat'
                                                    name='animal_2_1_zone'
                                                    placeholder="Select fields"
                                                    options={zones}
                                                    defaultValues={props.values.animal_2_1_zone}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.displayName}
                                                    value={props.values.animal_2_1_zone}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.animal_2_1_zone}
                                                    error={props.errors.animal_2_1_zone}
                                                    isClearable={true}
                                                    col={6}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <li className="mt-4">
                                                <FormController
                                                    control="radioClauses"
                                                    label=""
                                                    name="animal_2_3_A_statements"
                                                    options={Egg8360Labels.animal_2_3_A_statements}
                                                    readOnly={readOnly}
                                                />
                                                <FormController
                                                    control="radioClauses"
                                                    label=""
                                                    name="animal_2_3_B_statements"
                                                    options={Egg8360Labels.animal_2_3_B_statements}
                                                    readOnly={readOnly}
                                                />
                                            </li>
                                            <br></br>
                                            <label className="mt-3 mb-3 required">were produced:</label>
                                            {!props.values.animal_2_5_B && !props.values.animal_2_5_C && (
                                                <FormController
                                                    label="on"
                                                    control="dateInput"
                                                    name="animal_2_5_A"
                                                    value={props.values?.animal_2_5_A ? moment(props.values?.animal_2_5_A).format("DD/MM/YYYY") : ""}
                                                    col={2}
                                                />

                                            )}
                                            <br></br>
                                            {!props.values.animal_2_5_A && !props.values.animal_2_5_B && !props.values.animal_2_5_C && (
                                                <p>or:</p>
                                            )}
                                            {!props.values.animal_2_5_A && (

                                                <Row>
                                                    <Col sm={6} lg={5} xxl={2} >
                                                        <FormController
                                                            label="between"
                                                            control="dateInput"
                                                            name="animal_2_5_B"
                                                            value={props.values?.animal_2_5_B ? moment(props.values?.animal_2_5_B).format("DD/MM/YYYY") : ""}
                                                            col={11}
                                                        />
                                                    </Col>

                                                    <Col sm={6} lg={5} xxl={2}>
                                                        <FormController
                                                            label="and"
                                                            control="dateInput"
                                                            name="animal_2_5_C"
                                                            value={props.values?.animal_2_5_C ? moment(props.values?.animal_2_5_C).format("DD/MM/YYYY") : ""}
                                                            col={10}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                            <li>
                                                <MultiSelectField
                                                    id='productScientificSpecies'
                                                    name='productScientificSpecies'
                                                    label="Species"
                                                    placeholder="Select fields"
                                                    options={specieslist}
                                                    defaultValues={props.values?.productScientificSpecies}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                                    value={props.values.productScientificSpecies}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.productScientificSpecies}
                                                    error={props.errors.productScientificSpecies}
                                                    isClearable={true}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    col={4}
                                                />
                                            </li>
                                            <FormController
                                                control="input"
                                                type="text"
                                                label="Date of production"
                                                name="dateOfProduction"
                                                col={3}
                                            />

                                            <ColdStoreSelect
                                                name="coldStoreEstablishments"
                                                selectedFieldName="coldStoreEstablishments"
                                                setEstablishmentSelected={props.setFieldValue}
                                                selections={props.values.coldStoreEstablishments}
                                                label="Cold Store(s)"
                                                isMulti={true}
                                                countries={props.values.animal_2_1_zone.map(x => x.isoCode).map((str) => str.replace(/-(0|1|2|3|4|5)/g, ''))}
                                                certificateNumber={certificateNumber}
                                                getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                isRequired={true}
                                            />
                                            <MultiSelectField
                                                id='identificationMarkEstablishment'
                                                name='identificationMarkEstablishment'
                                                label="Identification mark"
                                                placeholder="Select fields"
                                                options={Array.from(new Map([...(siteEstablishment || []), ...(coldStoreEstab || [])].map(item => [item["approvalNumber"], item])).values())}
                                                getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                isMulti={false}
                                                onChange={props.setFieldValue}
                                                onBlur={props.setFieldTouched}
                                                touched={props.touched.identificationMarkEstablishment}
                                                error={props.errors.identificationMarkEstablishment}
                                                isClearable={true}
                                                isrequired="true"
                                                backspaceRemovesValue={true}
                                                readOnly={readOnly}
                                                col={6}
                                            />
                                        </ul>
                                        {/* <PrettyPrintJson data={props.values} />   */}
                                        {/* <PrettyPrintJson data={props.errors} />   */}
                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default Egg8360AddPage;