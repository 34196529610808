import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import FormController from "../common/FormController"
import { Card, Button} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import InvitationService from "../../services/InvitationService"
import * as yup from 'yup';
import OrganisationService from "../../services/OrganisationService"

const PlatformCertifierInvite = (props) => {
	const [submitted, setSubmitted] = useState(false);
	const [sentEmail, setSentEmail] = useState(false);
    const [organisation, setOrganisation] = useState([]);
    useEffect(() => {   

    OrganisationService.getFirst()
    .then((response) => {
        setOrganisation(response.data);
    })
    .catch((e) => {console.log(e);});
  },[]);

    return (
        <Formik
            initialValues={{  inviteEmail: '', role: 'PlatformCertifier', itemId:'68f1a3fc-5395-4933-82ad-36d906f65c24' }}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    InvitationService.invite(values)
                        .then(response => {
                            console.log(response.data);
                        })
                        .catch(e => {
                            console.log(e.message);
                        });
                    console.log(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                    setSubmitted(true);
                    setSentEmail(values.inviteEmail);
                }, 1000);
            }}
            validationSchema={yup.object({
                inviteEmail: yup.string().email("Invalid email address").required("Must enter an email address "),
            })}
        >
            {(formik) => (
                
            <Card border="light" className="bg-lightblue shadow-sm mb-4">
                <Card.Header>
                    <h4 className="mb-0" >Invite a Platform Certifier</h4>
                </Card.Header>
                <Card.Body>
                {!submitted?(
                    <Form className="text-align" noValidate>
                    <p>Invite a platform certifier to sign up by email:</p>
                    <FormController
                            control="input"
                            type="email"
                            name="inviteEmail"
                            placeholder="Email Address"
                            errors={formik.errors.inviteEmail}
                            touched={formik.touched.inviteEmail}
                        />
                        <div className="d-grid gap-2">
                        <Button type="submit" variant="outline-dark width-100" size="md" className="mt-2"> 
                            <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                            <span>Invite</span>
                        </Button>
                        </div>
                    </Form>
                        ):
                        (
                            <p>
                                <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> Invitation successfully sent to {sentEmail}
                            </p>
                        )
                        }
                </Card.Body>
            </Card>
            )
            }
        </Formik >
    );
};

export default PlatformCertifierInvite;